const GridSet = [
        {
            "id": 113,
            "categorie": "europe",
            "country": "Andorre",
            "flag": "AD",
            "title": "Poulet à la cannelle",
            "subTitle": "Poulet mariné dans une sauce à la cannelle et citron",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet (ou 6 pilons)",
                "2 cuillères à soupe de cannelle moulue",
                "1 citron (jus et zeste)",
                "3 cuillères à soupe d'huile d'olive",
                "2 gousses d'ail hachées",
                "1 cuillère à soupe de miel",
                "Sel et poivre",
                "1 cuillère à soupe de paprika doux",
                "1 branche de thym frais"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "cannelle",
                "citron",
                "huile-d'olive",
                "ail",
                "miel",
                "paprika",
                "sel",
                "poivre",
                "thym"
            ],
            "preparation": [
                "Dans un bol, mélanger la cannelle, le jus et le zeste de citron, l'ail, le miel, le paprika, l'huile d'olive, le sel et le poivre.",
                "Badigeonner les morceaux de poulet avec cette marinade.",
                "Couvrir et laisser mariner au réfrigérateur pendant 2 heures, idéalement toute une nuit pour plus de saveurs."
            ],
            "cuisson": [
                "Préchauffer le four à 180°C.",
                "Placer les morceaux de poulet dans un plat allant au four.",
                "Arroser avec la marinade restante et ajouter le thym.",
                "Cuire pendant 45 à 50 minutes, jusqu'à ce que le poulet soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec du riz basmati ou une salade verte.",
            "shortStory": "Le **Poulet à la cannelle** est un plat parfumé aux saveurs sucrées et épicées, typique de la cuisine méditerranéenne et inspiré des influences culinaires de la région pyrénéenne. La combinaison de la cannelle et du citron donne au poulet une touche à la fois chaleureuse et rafraîchissante. Ce plat est parfait pour un repas familial ou un dîner d’occasion, et il évoque les traditions simples et savoureuses d'Andorre, où la cuisine reflète les influences voisines de la France et de l'Espagne.",
            "detailURL": "/fiche-recette/113/poulet-cannelle-citron/poulet-cannelle.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "50mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), avec des ingrédients simples mais parfumés tels que la cannelle et le miel."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette n'est pas épicée, mais offre un arôme chaleureux de cannelle et de citron."
            },
            "calories": "310 kcal",
            "allergens": [
                "miel",
                "cannelle"
            ],
            "difficulty": {
                "level": 2,
                "description": "Une recette facile à préparer, nécessitant un peu de temps pour la marinade afin de maximiser les saveurs."
            },
            "equipment_needed": [
                "four",
                "plat-de-cuisson",
                "bol"
            ]
        },
        {
            "id": 114,
            "categorie": "europe",
            "country": "Andorre",
            "flag": "AD",
            "title": "Escudella de Poulet",
            "subTitle": "Poulet mariné dans des herbes, intégré dans un ragoût",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet (ou 6 pilons)",
                "2 branches de thym frais",
                "1 branche de romarin frais",
                "2 feuilles de laurier",
                "3 gousses d'ail hachées",
                "1 oignon haché",
                "2 carottes coupées en rondelles",
                "1 pomme de terre coupée en dés",
                "1 litre de bouillon de poulet",
                "1 cuillère à soupe d'huile d'olive",
                "Sel et poivre",
                "1 cuillère à café de paprika"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "thym",
                "romarin",
                "laurier",
                "ail",
                "oignon",
                "carottes",
                "pomme-de-terre",
                "bouillon-de-poulet",
                "huile-d'olive",
                "sel",
                "poivre",
                "paprika"
            ],
            "preparation": [
                "Dans un bol, mélanger l'huile d'olive, les herbes fraîches, l'ail, le sel, le poivre et le paprika.",
                "Badigeonner les morceaux de poulet avec cette marinade et laisser mariner pendant 1 heure.",
                "Faire revenir l'oignon dans une grande casserole avec un peu d'huile d'olive jusqu'à ce qu'il soit doré.",
                "Ajouter les carottes et la pomme de terre, puis verser le bouillon de poulet.",
                "Ajouter les morceaux de poulet et les herbes, et laisser mijoter pendant environ 1 heure à feu moyen-doux."
            ],
            "cuisson": [
                "Laisser mijoter le tout à feu doux jusqu'à ce que le poulet soit tendre et bien cuit.",
                "Si nécessaire, ajouter un peu d'eau ou de bouillon pendant la cuisson pour ajuster la consistance."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du pain de campagne croustillant ou des pâtes fraîches, pour absorber le bouillon savoureux.",
            "shortStory": "L'**Escudella de Poulet** est un délicieux **ragoût andorran** à base de **poulet mariné** et d'herbes fraîches. Ce plat mijoté longuement révèle des **saveurs réconfortantes** parfaites pour les journées froides. Riche en légumes et servi avec un bouillon parfumé, il est idéal pour un **repas familial chaleureux** inspiré des traditions montagnardes d'Andorre.",
            "detailURL": "/fiche-recette/114/escudella-de-poulet-ragout/escudella-poulet.html",
            "time_prepare": "20mn",
            "time_maceration": "1h",
            "time_cooking": "1h",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), avec des ingrédients frais comme les légumes et le bouillon de poulet."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette est douce et réconfortante, sans épices fortes mais avec des saveurs délicates de légumes et d'herbes."
            },
            "calories": "250 kcal",
            "allergens": [
                "bouillon-de-poulet (possible présence de gluten dans le bouillon commercial)"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette facile à réaliser, mais qui nécessite un peu de temps pour laisser mijoter les ingrédients et libérer toutes leurs saveurs."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche-à-découper"
            ]
        },
        {
            "id": 42,
            "categorie": "asiatique",
            "country": "Émirats arabes unis",
            "flag": "AE",
            "title": "Poulet Machbous",
            "subTitle": "Poulet mariné avec citron séché, cannelle, curcuma et cardamome, cuit avec du riz.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (cuisses ou blancs)",
                "1 citron séché (ou du zeste de citron)",
                "1 cuillère à café de cannelle",
                "1 cuillère à café de curcuma",
                "1 cuillère à café de cardamome en poudre",
                "1 oignon émincé",
                "200g de riz basmati",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre",
                "1 litre d'eau ou de bouillon de poulet"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "citron-seche",
                "cannelle",
                "curcuma",
                "cardamome",
                "oignon",
                "riz-basmati",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le citron séché, la cannelle, le curcuma, la cardamome, le sel et le poivre.",
                "Enduire les morceaux de poulet avec la marinade et laisser reposer pendant 30 minutes.",
                "Dans une grande casserole, chauffer l'huile et faire revenir l'oignon jusqu'à ce qu'il devienne translucide."
            ],
            "cuisson": [
                "Ajouter les morceaux de poulet dans la casserole et les faire dorer de tous côtés.",
                "Ajouter le riz basmati et mélanger pour bien l'enrober des épices.",
                "Verser l'eau ou le bouillon, porter à ébullition, puis réduire le feu et laisser mijoter à couvert pendant environ 30 minutes, ou jusqu'à ce que le riz soit cuit et le poulet tendre.",
                "Servir chaud avec des légumes grillés ou une sauce tomate épicée."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "mijote",
                "cocotte"
            ],
            "accompagnement": "Servir avec une sauce tomate épicée ou des légumes grillés.",
            "shortStory": "**Poulet Machbous** est un plat traditionnel des Émirats où le poulet est mariné avec des épices comme le curcuma et la cardamome, puis mijoté avec du riz pour une expérience savoureuse et parfumée.",
            "detailURL": "/fiche-recette/42/cannelle-curcuma-cardamome/poulet-machbous.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat d'une certaine complexité avec une cuisson lente."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Modérément épicé grâce au curcuma et à la cardamome."
            },
            "calories": "350 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Plat de difficulté modérée, nécessite un temps de cuisson à feu doux."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper",
                "bol"
            ]
        },
        {
            "id": 43,
            "categorie": "asiatique",
            "country": "Émirats arabes unis",
            "flag": "AE",
            "title": "Poulet Shish Taouk",
            "subTitle": "Poulet mariné au yaourt, ail, citron et épices, grillé sur brochettes.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (blancs ou cuisses)",
                "200g de yaourt nature",
                "4 gousses d'ail écrasées",
                "1 citron (jus uniquement)",
                "1 cuillère à soupe de paprika",
                "1 cuillère à café de cumin",
                "1 cuillère à café de coriandre en poudre",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre",
                "brochettes en bois ou métal"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "yaourt",
                "ail",
                "citron",
                "paprika",
                "cumin",
                "coriandre",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le yaourt, l'ail écrasé, le jus de citron, le paprika, le cumin, la coriandre, l'huile d'olive, le sel et le poivre.",
                "Ajouter les morceaux de poulet dans la marinade et laisser mariner au réfrigérateur pendant au moins 2 heures (idéalement toute la nuit).",
                "Préchauffer le gril ou la poêle."
            ],
            "cuisson": [
                "Enfiler les morceaux de poulet marinés sur les brochettes.",
                "Faire griller le poulet sur le gril ou à la poêle à feu moyen-élevé pendant environ 10-12 minutes de chaque côté, jusqu'à ce qu'il soit bien cuit et doré.",
                "Servir avec du riz, des légumes grillés ou une sauce au yaourt."
            ],
            "cooking_methods": {
                "best": "grillade",
                "barbecue": true,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "grillade",
                "poele"
            ],
            "accompagnement": "Servir avec du riz basmati, des légumes grillés ou une sauce au yaourt à l'ail.",
            "shortStory": "**Poulet Shish Taouk** est un plat savoureux des Émirats, où le poulet est mariné au yaourt, citron et épices, puis grillé sur brochettes pour un goût tendre et épicé.",
            "detailURL": "/fiche-recette/43/yaourt-citron-epices/poulet-shish-taouk.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "25mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat simple à réaliser avec une marinade rapide."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Modérément épicé grâce au paprika et au cumin."
            },
            "calories": "300 kcal",
            "allergens": [
                "lactose"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite une marinade et une cuisson sur le gril ou à la poêle."
            },
            "equipment_needed": [
                "grill",
                "brochettes",
                "poele",
                "couteau",
                "planche à découper",
                "bol"
            ]
        },
        {
            "id": 103,
            "categorie": "moyen-orient",
            "country": "Afghanistan",
            "flag": "AF",
            "title": "Murgh Korma",
            "subTitle": "Poulet tendre mijoté dans une sauce crémeuse aux épices",
            "chicken_piece": [
                "cuisse",
                "blanc",
                "entier"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 morceaux de poulet (cuisses ou blancs)",
                "2 oignons moyens",
                "3 gousses d'ail",
                "1 morceau de gingembre frais (2 cm)",
                "1/2 tasse de yaourt nature",
                "1/4 tasse de crème fraîche",
                "3 cuillères à soupe d'huile ou de ghee",
                "2 cuillères à soupe de poudre d'amandes",
                "4 gousses de cardamome",
                "1 bâton de cannelle",
                "1 cuillère à café de cumin",
                "1 cuillère à café de curcuma",
                "1 cuillère à café de paprika doux",
                "1/2 cuillère à café de garam masala",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "oignons",
                "ail",
                "gingembre",
                "yaourt",
                "crème",
                "amandes",
                "huile",
                "cardamome",
                "cannelle",
                "cumin",
                "curcuma",
                "paprika",
                "garam-masala",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Émincer les oignons, hacher l'ail et râper le gingembre.",
                "Faire revenir les oignons dans l'huile jusqu'à ce qu'ils soient dorés.",
                "Ajouter l'ail, le gingembre et les épices (cardamome, cannelle, cumin, curcuma, paprika) et cuire pendant 2 minutes en mélangeant bien.",
                "Incorporer les morceaux de poulet et les faire dorer légèrement.",
                "Ajouter le yaourt nature et mélanger pour bien enrober le poulet."
            ],
            "cuisson": [
                "Ajouter un verre d'eau, couvrir et laisser mijoter à feu doux pendant 30 minutes.",
                "Incorporer la crème fraîche, la poudre d'amandes et le garam masala en fin de cuisson.",
                "Rectifier l'assaisonnement en sel et poivre avant de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Ce plat s'accompagne parfaitement de riz basmati parfumé ou de naans chauds, pour un repas complet et savoureux.",
            "shortStory": "Le Murgh Korma est un plat aux origines royales, né de l'influence moghole en Asie du Sud. Bien qu'il trouve ses racines dans la cuisine afghane, il a évolué en Inde sous la dynastie moghole, où il est devenu un symbole de raffinement. Ce plat combine des techniques afghanes de cuisson lente avec des saveurs et des ingrédients locaux, comme le yaourt et les épices. Aujourd'hui, il reste un exemple emblématique de la richesse partagée entre les traditions culinaires d'Afghanistan et d'Inde.",
            "detailURL": "/fiche-recette/103/poulet-oignons-yaourt-amandes/murgh-korma.html",
            "time_prepare": "15mn",
            "time_maceration": "0mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Ce plat a un coût élevé, en raison de l'utilisation d'ingrédients comme la poudre d'amandes, le yaourt et les épices spécifiques."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Ce plat offre une chaleur modérée grâce à l'équilibre des épices, parfait pour les amateurs de saveurs riches et épicées sans être trop piquant."
            },
            "calories": "420 kcal",
            "allergens": [
                "amandes",
                "produits-laitiers"
            ],
            "difficulty": {
                "level": 3,
                "description": "Recette de difficulté élevée nécessitant une bonne gestion des étapes pour équilibrer les saveurs et éviter de brûler les épices."
            },
            "equipment_needed": [
                "cocotte",
                "poêle",
                "râpe",
                "spatule"
            ]
        },
        {
            "id": 715,
            "categorie": "moyen-orient",
            "country": "Afghanistan",
            "flag": "AF",
            "title": "Poulet Kabuli Pulao",
            "subTitle": "Riz parfumé avec poulet tendre, raisins secs et carottes",
            "chicken_piece": [
                "entier",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier ou 4 morceaux (cuisse ou blanc)",
                "2 tasses de riz basmati",
                "2 carottes râpées",
                "1 oignon moyen",
                "1/2 tasse de raisins secs",
                "1/2 tasse d'amandes effilées",
                "2 cuillères à soupe d'huile végétale ou de ghee",
                "4 gousses de cardamome",
                "1 bâton de cannelle",
                "1 cuillère à soupe de cumin",
                "1 cuillère à café de curcuma",
                "2 cuillères à soupe de sucre",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "riz-basmati",
                "carottes",
                "oignon",
                "raisins-secs",
                "amandes",
                "huile",
                "cardamome",
                "cannelle",
                "cumin",
                "curcuma",
                "sucre",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Faire revenir les morceaux de poulet dans une poêle avec un peu d'huile jusqu'à ce qu'ils soient dorés, puis réserver.",
                "Dans la même poêle, faire revenir les oignons émincés jusqu'à ce qu'ils soient translucides.",
                "Ajouter les épices (cardamome, cannelle, cumin, curcuma) et bien mélanger.",
                "Ajouter le riz basmati rincé, les raisins secs et les carottes râpées, puis mélanger doucement.",
                "Ajouter 4 tasses d'eau bouillante, le sucre, le sel et le poivre, et porter à ébullition."
            ],
            "cuisson": [
                "Disposer les morceaux de poulet sur le riz, couvrir et laisser cuire à feu doux pendant environ 30 minutes jusqu'à ce que le riz soit tendre et le poulet bien cuit.",
                "Servir garni d'amandes grillées."
            ],
            "cooking_methods": {
                "best": "poêle",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": true,
                "cocotte": true
            },
            "mode_cuisson": [
                "poêle",
                "cocotte"
            ],
            "accompagnement": "Accompagner d'une salade verte ou de yaourt nature.",
            "shortStory": "Le **Poulet Kabuli Pulao** est un plat emblématique d'Afghanistan, combinant harmonieusement les saveurs **sucrées et épicées**. Il est souvent servi lors d'occasions spéciales et symbolise hospitalité et tradition.",
            "detailURL": "/fiche-recette/715/poulet-riz-basmati-carottes-oignons/poulet-kabuli-pulao.html",
            "time_prepare": "20mn",
            "time_maceration": "0mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Le coût de cette recette est légèrement élevé en raison des ingrédients utilisés, notamment les épices, les raisins secs et les amandes, qui ajoutent une richesse à ce plat."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Ce plat est parfumé et modérément épicé, idéal pour des amateurs de cuisine raffinée mais accessible à tous."
            },
            "calories": "475 kcal",
            "allergens": [
                "amandes",
                "raisins"
            ],
            "difficulty": {
                "level": 3,
                "description": "Recette de difficulté modérée, nécessitant une bonne coordination pour équilibrer les cuissons du riz et du poulet."
            },
            "equipment_needed": [
                "poêle",
                "casserole avec couvercle",
                "râpe"
            ]
        },
        {
            "id": 117,
            "categorie": "caraibes",
            "country": "Antigua-et-Barbuda",
            "flag": "AG",
            "title": "Poulet Jerk",
            "subTitle": "Poulet mariné avec un mélange d’épices jamaïcaines (piment, cannelle, clou de girofle, thym)",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet (ou 6 pilons)",
                "2 piments Scotch Bonnet (ou habaneros)",
                "1 oignon haché",
                "3 gousses d’ail hachées",
                "1 cuillère à soupe de gingembre frais râpé",
                "2 cuillères à soupe de jus de citron vert",
                "1 cuillère à soupe de vinaigre blanc",
                "1 cuillère à soupe de cannelle en poudre",
                "1 cuillère à soupe de thym séché",
                "1 cuillère à café de clou de girofle moulu",
                "1 cuillère à soupe de sucre roux",
                "2 cuillères à soupe d’huile végétale",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "piment",
                "oignon",
                "ail",
                "gingembre",
                "citron-vert",
                "vinaigre",
                "cannelle",
                "thym",
                "clou-de-girofle",
                "sucre",
                "huile-végétale",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un mixeur, mélanger les piments, l’oignon, l’ail, le gingembre, le jus de citron vert, le vinaigre, la cannelle, le thym, le clou de girofle, le sucre, l’huile, le sel et le poivre.",
                "Mixer jusqu’à obtenir une pâte lisse.",
                "Badigeonner généreusement les cuisses de poulet avec cette marinade et laisser mariner pendant au moins 4 heures, de préférence toute une nuit, au réfrigérateur.",
                "Préchauffer un barbecue ou un grill à feu moyen.",
                "Cuire le poulet pendant environ 30 à 40 minutes, en le retournant toutes les 10 minutes, jusqu’à ce qu’il soit bien doré et cuit à cœur."
            ],
            "cuisson": [
                "Cuire le poulet sur un barbecue ou un grill à feu moyen, en le retournant régulièrement pour éviter qu’il ne brûle.",
                "S'assurer que le poulet atteint une température interne de 75°C pour être parfaitement cuit."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": false,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "barbecue",
                "grill"
            ],
            "accompagnement": "Servir avec du riz à la noix de coco ou une salade tropicale.",
            "shortStory": "**Le Poulet Jerk** est un plat emblématique des Caraïbes, connu pour son mélange unique d'épices. Bien que ses origines soient jamaïcaines, il est également populaire à Antigua-et-Barbuda, où il est souvent cuit sur des barbecues traditionnels. La **marinade épicée** offre un parfait équilibre entre le piquant et les saveurs chaleureuses des épices.",
            "detailURL": "/fiche-recette/117/cuisses-de-poulet-piment-cannelle-thym/poulet-jerk.html",
            "time_prepare": "20mn",
            "time_maceration": "4h",
            "time_cooking": "40mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), avec des ingrédients exotiques comme les piments Scotch Bonnet et les épices, mais les morceaux de poulet restent abordables."
            },
            "images": [],
            "hot": {
                "level": 4,
                "description": "Ce plat est très épicé, principalement en raison des piments Scotch Bonnet. Parfait pour les amateurs de sensations fortes."
            },
            "calories": "320 kcal",
            "allergens": [
                "piment",
                "cannelle"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté modérée, nécessitant une marinade longue et une surveillance constante pendant la cuisson pour éviter de brûler les épices."
            },
            "equipment_needed": [
                "grill",
                "barbecue",
                "mixeur"
            ]
        },
        {
            "id": 119,
            "categorie": "caraibes",
            "country": "Antigua-et-Barbuda",
            "flag": "AG",
            "title": "Poulet Pepperpot",
            "subTitle": "Poulet mijoté dans une sauce épicée et parfumée des Caraïbes",
            "chicken_piece": [
                "entier",
                "pilon",
                "cuisse"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier (ou 6 morceaux au choix)",
                "1 oignon haché",
                "3 gousses d’ail émincées",
                "1 cuillère à soupe de gingembre râpé",
                "2 piments Scotch Bonnet (ou piments forts au choix)",
                "1 cuillère à café de cannelle en poudre",
                "1 cuillère à café de clou de girofle moulu",
                "2 cuillères à soupe de sucre brun",
                "1 cuillère à soupe de vinaigre blanc",
                "2 cuillères à soupe d’huile végétale",
                "400 ml de bouillon de poulet",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "oignon",
                "ail",
                "gingembre",
                "piment",
                "cannelle",
                "clou-de-girofle",
                "sucre",
                "vinaigre",
                "huile-végétale",
                "bouillon-de-poulet",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Découper le poulet en morceaux si nécessaire.",
                "Dans un bol, mélanger l’oignon, l’ail, le gingembre, les piments, la cannelle, le clou de girofle, le sucre, le vinaigre, l’huile, le sel et le poivre pour préparer une marinade.",
                "Badigeonner les morceaux de poulet avec cette marinade en veillant à bien les enrober. Couvrir et laisser reposer au moins 2 heures au réfrigérateur pour que les saveurs s’imprègnent.",
                "Préparer une cocotte en la chauffant à feu moyen avec un filet d’huile végétale."
            ],
            "cuisson": [
                "Saisir les morceaux de poulet dans la cocotte chaude jusqu’à ce qu’ils soient dorés sur toutes les faces.",
                "Ajouter le reste de la marinade et le bouillon de poulet. Mélanger légèrement pour répartir la sauce.",
                "Réduire le feu, couvrir et laisser mijoter à feu doux pendant environ 1h30. Remuer de temps en temps pour éviter que les épices n’accrochent au fond.",
                "Vérifier que le poulet est tendre et que la sauce a épaissi avant de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir ce plat avec du riz blanc parfumé, des bananes plantains frites, ou encore du pain caribéen pour équilibrer les saveurs épicées et sucrées. Vous pouvez également ajouter des légumes racines comme de la patate douce ou du manioc pour un repas plus consistant.",
            "shortStory": "**Le poulet Pepperpot** est une recette emblématique des Caraïbes, particulièrement populaire à Antigua-et-Barbuda. Ce plat mijoté, riche en épices et en piments, reflète parfaitement la cuisine chaleureuse et vibrante de la région. Parfait pour découvrir les saveurs authentiques de cette île, il séduira les amateurs de plats épicés et parfumés.",
            "detailURL": "/fiche-recette/119/poulet-entier-cannelle-piment/poulet-pepperpot.html",
            "time_prepare": "25mn",
            "time_maceration": "2h",
            "time_cooking": "1h30",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), grâce à des ingrédients courants, bien que les épices puissent être légèrement coûteuses selon leur origine."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Cette recette est moyennement épicée grâce aux piments Scotch Bonnet. Idéale pour ceux qui apprécient une touche de chaleur sans excès."
            },
            "calories": "360 kcal",
            "allergens": [
                "piment"
            ],
            "difficulty": {
                "level": 2,
                "description": "Ce plat nécessite une gestion attentive de la marinade et une cuisson lente, ce qui en fait une recette de difficulté modérée."
            },
            "equipment_needed": [
                "cocotte",
                "bol",
                "cuillère en bois",
                "couteau"
            ]
        },
        {
            "id": 106,
            "categorie": "europe",
            "country": "Albanie",
            "flag": "AL",
            "title": "Poulet au Lait",
            "subTitle": "Poulet mariné dans du lait, citron et ail, rôti au four",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon",
                "blanc"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": true,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux ou 6 morceaux (cuisse, pilon, blanc)",
                "500 ml de lait",
                "2 gousses d'ail hachées",
                "1 citron (jus et zeste)",
                "2 cuillères à soupe d'huile d'olive",
                "1 cuillère à café de paprika",
                "1/2 cuillère à café de thym",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "lait",
                "ail",
                "citron",
                "huile-d'olive",
                "paprika",
                "thym",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le lait, l'ail, le jus et le zeste de citron, l'huile d'olive, le paprika, le thym, le sel et le poivre.",
                "Badigeonner les morceaux de poulet avec la marinade et laisser mariner au réfrigérateur pendant au moins 2 heures, idéalement toute une nuit."
            ],
            "cuisson": [
                "Préchauffer le four à 180°C.",
                "Placer les morceaux de poulet dans un plat de cuisson et verser la marinade dessus.",
                "Rôtir au four pendant 45 minutes, en arrosant le poulet toutes les 15 minutes avec la marinade pour une cuisson uniforme et dorée."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec des légumes rôtis ou du riz basmati. Une salade verte légère est également une bonne option pour équilibrer le plat.",
            "shortStory": "Le **Poulet au Lait** (Pulë me Qumësht) est un plat traditionnel **albanais** qui met en valeur l'usage simple mais savoureux du lait pour attendrir la viande. Mariné avec du **citron** et de l'**ail**, ce plat est non seulement délicieux mais aussi une véritable tradition culinaire d'Albanie, où le poulet rôti est un incontournable lors des repas familiaux et des fêtes.",
            "detailURL": "/fiche-recette/106/poulet-lait-citron-ail/pule-me-qumesht.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "45mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), grâce aux ingrédients simples mais de qualité comme le lait et le citron. L'utilisation du **poulet entier** peut augmenter légèrement le coût par rapport à d'autres morceaux de viande."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Ce plat est légèrement épicé, grâce au **paprika**, mais il n'est pas très piquant. Le lait et le citron apportent une douceur agréable au poulet."
            },
            "calories": "350 kcal",
            "allergens": [
                "lait"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette assez simple, mais nécessite un bon contrôle de la cuisson pour obtenir un poulet bien rôti et tendre, grâce à la marinade au lait."
            },
            "equipment_needed": [
                "four",
                "plat-de-cuisson",
                "bol"
            ]
        },
        {
            "id": 107,
            "categorie": "europe",
            "country": "Albanie",
            "flag": "AL",
            "title": "Poulet Rôti au Citron",
            "subTitle": "Poulet mariné avec du citron, ail et huile d'olive, cuit au four",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon",
                "blanc"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": true,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux ou 6 morceaux (cuisse, pilon, blanc)",
                "2 citrons (jus et zeste)",
                "4 gousses d'ail hachées",
                "3 cuillères à soupe d'huile d'olive",
                "1 cuillère à café de thym",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "citron",
                "ail",
                "huile-d'olive",
                "thym",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le jus et le zeste des citrons, l'ail, l'huile d'olive, le thym, le sel et le poivre.",
                "Badigeonner les morceaux de poulet avec la marinade et laisser mariner au réfrigérateur pendant au moins 2 heures, idéalement toute une nuit."
            ],
            "cuisson": [
                "Préchauffer le four à 180°C.",
                "Placer les morceaux de poulet dans un plat de cuisson et verser la marinade dessus.",
                "Rôtir au four pendant 45 à 50 minutes, en arrosant le poulet toutes les 15 minutes avec la marinade pour une cuisson uniforme et dorée."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec des pommes de terre rôties ou **des légumes grillés** pour une alternative plus légère.",
            "shortStory": "Le **Poulet Rôti au Citron** est une recette simple mais pleine de saveurs, typique de la cuisine albanaise. Mariné avec du **citron** et de l'**ail**, ce plat est non seulement délicieux mais aussi très facile à préparer. Le poulet rôti au four devient tendre et juteux grâce à la marinade, ce qui en fait un plat parfait pour un repas en famille ou entre amis. Servez-le avec des **herbes** fraîches pour un goût encore plus parfumé.",
            "detailURL": "/fiche-recette/107/poulet-roti-citron-ail-thym/pule-e-pjekur-me-limona.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "50mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), grâce à des ingrédients simples tels que les citrons, l'ail et l'huile d'olive, mais avec un poulet entier qui peut légèrement augmenter le prix."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Ce plat n'est pas très épicé, mais le citron apporte une fraîcheur agréable et l'ail ajoute une touche savoureuse sans être trop intense."
            },
            "calories": "400 kcal",
            "allergens": [
                "lait",
                "huile-d'olive"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette simple à préparer, mais nécessite un bon contrôle de la cuisson pour un poulet bien doré et tendre."
            },
            "equipment_needed": [
                "four",
                "plat-de-cuisson",
                "bol"
            ]
        },
        {
            "id": 123,
            "categorie": "europe",
            "country": "Arménie",
            "flag": "AM",
            "title": "Poulet Khorovats",
            "subTitle": "Poulet mariné au citron, ail et paprika, grillé au barbecue",
            "chicken_piece": [
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "1 poulet entier",
                "1 citron",
                "5 gousses d’ail",
                "2 cuillères à soupe de paprika",
                "100 ml d’huile d’olive",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "citron",
                "ail",
                "paprika",
                "huile-d'olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Presser le citron et mélanger son jus avec l'ail émincé, le paprika, l’huile d’olive, le sel et le poivre.",
                "Badigeonner le poulet avec cette marinade et laisser reposer au réfrigérateur pendant au moins 1 heure."
            ],
            "cuisson": [
                "Faire griller le poulet sur le barbecue, à feu moyen, pendant environ 1h, en le retournant régulièrement jusqu’à ce qu'il soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": false,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Servir avec une salade fraîche, du pain pita et **des légumes grillés** pour un repas plus complet.",
            "shortStory": "Le **Khorovats** est un plat emblématique d'Arménie, où le **poulet** est mariné dans un mélange de saveurs méditerranéennes comme le **citron**, l'**ail** et le **paprika**, puis grillé au **barbecue** pour une expérience savoureuse et parfumée. Ce plat est parfait pour les repas en plein air, apportant des saveurs fraîches et subtiles.",
            "detailURL": "/fiche-recette/123/poulet-citron-ail-paprika/barbecue/poulet-khorovats.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "1h",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Coût modéré (niveau 2), nécessitant un poulet entier et des épices simples comme le paprika et l'ail."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Le paprika et le citron apportent des saveurs subtiles et légères, sans piquant."
            },
            "calories": "380 kcal",
            "allergens": [
                "huile-d'olive"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette facile à préparer, mais nécessite une cuisson attentive au barbecue pour une cuisson parfaite."
            },
            "equipment_needed": [
                "barbecue",
                "bol"
            ]
        },
        {
            "id": 124,
            "categorie": "europe",
            "country": "Arménie",
            "flag": "AM",
            "title": "Poulet au Sumac",
            "subTitle": "Poulet mariné au sumac, yaourt et ail",
            "chicken_piece": [
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "1 poulet entier",
                "2 cuillères à soupe de sumac",
                "1 yaourt nature",
                "5 gousses d’ail",
                "2 cuillères à soupe de jus de citron",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "sumac",
                "yaourt",
                "ail",
                "citron",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le yaourt, le sumac, l'ail émincé, le jus de citron, le sel et le poivre.",
                "Badigeonner le poulet entier avec cette marinade et laisser mariner pendant 1 à 2 heures au réfrigérateur."
            ],
            "cuisson": [
                "Faire cuire le poulet au barbecue ou au four, à 180°C pendant environ 1h, jusqu’à ce qu’il soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "barbecue",
                "four"
            ],
            "accompagnement": "Servir avec du riz pilaf ou une salade de légumes grillés.",
            "shortStory": "Le Poulet au Sumac est un plat d’Arménie où le sumac, avec son goût légèrement acide, se marie parfaitement avec le yaourt et l’ail pour créer une marinade savoureuse et originale.",
            "detailURL": "/fiche-recette/124/sumac-yaourt-ail-citron/poulet-au-sumac.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "1h",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Coût modéré (niveau 2), avec des ingrédients simples et courants."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "La marinade au sumac donne un goût acidulé subtil, sans piquant."
            },
            "calories": "320 kcal",
            "allergens": [
                "yaourt",
                "citron"
            ],
            "difficulty": {
                "level": 2,
                "description": "Facile à préparer, mais nécessite une attention à la cuisson pour que le poulet soit bien cuit."
            },
            "equipment_needed": [
                "barbecue",
                "four",
                "bol"
            ]
        },
        {
            "id": 115,
            "categorie": "afrique",
            "country": "Angola",
            "flag": "AO",
            "title": "Moamba de Galinha",
            "subTitle": "Poulet mariné avec ail, citron, et pâte de palme",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet (ou 6 pilons)",
                "3 gousses d'ail hachées",
                "1 citron (jus et zeste)",
                "3 cuillères à soupe de pâte de palme",
                "1 oignon haché",
                "2 tomates concassées",
                "2 cuillères à soupe d'huile d'olive",
                "1 piment frais (facultatif)",
                "Sel et poivre",
                "1 cuillère à soupe de gingembre râpé",
                "1 cuillère à café de paprika"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "ail",
                "citron",
                "pâte-de-palme",
                "oignon",
                "tomates",
                "huile-d'olive",
                "piment",
                "sel",
                "poivre",
                "gingembre",
                "paprika"
            ],
            "preparation": [
                "Dans un bol, mélanger l'ail haché, le jus et le zeste de citron, la pâte de palme, le gingembre, le paprika, le sel et le poivre.",
                "Badigeonner les morceaux de poulet avec cette marinade et laisser mariner pendant 2 heures.",
                "Faire chauffer l'huile d'olive dans une grande casserole et y faire revenir l'oignon et les tomates jusqu'à ce qu'ils soient tendres.",
                "Ajouter les morceaux de poulet et la marinade dans la casserole, puis couvrir avec de l'eau.",
                "Ajouter le piment frais (si utilisé) et laisser mijoter à feu doux pendant environ 1 heure, jusqu'à ce que le poulet soit tendre."
            ],
            "cuisson": [
                "Laisser mijoter le tout jusqu'à ce que le poulet soit bien cuit et tendre.",
                "Si nécessaire, ajouter un peu d'eau pour obtenir une sauce onctueuse."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc ou du funge (pâte de maïs).",
            "shortStory": "La **Moamba de Galinha** est l'un des plats les plus emblématiques de la cuisine angolaise. Ce ragoût de poulet est préparé avec des ingrédients typiques de la région, dont la pâte de palme, qui lui donne une saveur riche et unique. Ce plat est souvent préparé pour des occasions spéciales et reflète l'héritage culinaire d'Angola, alliant des saveurs épicées et exotiques.",
            "detailURL": "/fiche-recette/115/tomate-ail-citron-pate-de-palme/moamba-de-galinha.html",
            "time_prepare": "20mn",
            "time_maceration": "2h",
            "time_cooking": "1h",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Le coût de cette recette est un peu plus élevé (niveau 3) en raison de la pâte de palme et des autres ingrédients frais utilisés, bien que l'essentiel reste abordable."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette a une légère touche épicée, mais n'est pas trop forte. Le piment frais peut être ajusté selon les préférences."
            },
            "calories": "380 kcal",
            "allergens": [
                "pâte-de-palme (vérifier si non contaminée par des allergènes)",
                "gingembre"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté modérée, nécessitant un temps de cuisson raisonnable et une gestion correcte de la marinade pour que le poulet soit bien infusé de saveurs."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche-à-découper"
            ]
        },
        {
            "id": 116,
            "categorie": "afrique",
            "country": "Angola",
            "flag": "AO",
            "title": "Chicken Piri-Piri - variante angolaise",
            "subTitle": "Variante angolaise du poulet piri-piri mariné au piment, citron et huile",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet (ou 6 pilons)",
                "2 piments frais",
                "1 citron (jus et zeste)",
                "3 gousses d'ail hachées",
                "2 cuillères à soupe d'huile d'olive",
                "1 cuillère à café de paprika",
                "1 cuillère à café de cumin moulu",
                "Sel et poivre",
                "1 cuillère à soupe de vinaigre blanc"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "piment",
                "citron",
                "ail",
                "huile-d'olive",
                "paprika",
                "cumin",
                "sel",
                "poivre",
                "vinaigre"
            ],
            "preparation": [
                "Dans un mixeur, mélanger les piments, l'ail, le jus et le zeste de citron, le paprika, le cumin, l'huile d'olive, le vinaigre, le sel et le poivre.",
                "Mixer jusqu'à obtenir une pâte lisse.",
                "Badigeonner les cuisses de poulet avec la marinade et laisser mariner au moins 2 heures au réfrigérateur.",
                "Préchauffer le grill ou la poêle à feu moyen-élevé.",
                "Cuire les cuisses de poulet pendant environ 25 à 30 minutes, en les retournant régulièrement pour une cuisson uniforme."
            ],
            "cuisson": [
                "Cuire le poulet jusqu'à ce qu'il soit bien doré et cuit à cœur, en le retournant toutes les 5 à 7 minutes pour éviter qu'il ne brûle.",
                "L'idéal est de le griller ou de le cuire à la poêle."
            ],
            "cooking_methods": {
                "best": "grill",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": true,
                "cocotte": false
            },
            "mode_cuisson": [
                "grill",
                "poêle"
            ],
            "accompagnement": "Servir avec du riz basmati ou des légumes grillés.",
            "shortStory": "Le **Chicken Piri-Piri** est une variante angolaise du célèbre poulet piri-piri, un plat épicé et savoureux. La marinade au piment, citron, ail et épices apporte une explosion de saveurs qui se marie parfaitement avec un riz basmati ou des légumes grillés. Ce plat est parfait pour les amateurs de cuisine épicée et de grillades.",
            "detailURL": "/fiche-recette/116/cuisses-de-poulet-piment-citron-huile/chicken-piri-piri.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "30mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), principalement à cause des piments frais et des épices. Cependant, les ingrédients de base restent abordables."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Cette recette est épicée avec une bonne dose de piment, parfait pour les amateurs de sensations fortes."
            },
            "calories": "280 kcal",
            "allergens": [
                "huile-d'olive",
                "piment"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté modérée, nécessitant un temps de marinade suffisant pour que les saveurs s'imprègnent dans le poulet."
            },
            "equipment_needed": [
                "grill",
                "poêle",
                "mixeur"
            ]
        },
        {
            "id": 58,
            "categorie": "amerique-sud",
            "country": "Argentine",
            "flag": "AR",
            "title": "Poulet au Chimichurri",
            "subTitle": "Poulet grillé avec une sauce chimichurri fraîche",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "1 bouquet de persil",
                "2 gousses d'ail",
                "1 cuillère à café de piment rouge",
                "2 cuillères à soupe de vinaigre",
                "4 cuillères à soupe d'huile d'olive"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "persil",
                "ail",
                "piment-rouge",
                "vinaigre",
                "huile-d-olive"
            ],
            "preparation": [
                "Mixer le persil, l'ail, le piment, le vinaigre et l'huile d'olive pour faire le chimichurri.",
                "Badigeonner les blancs de poulet avec la sauce et laisser mariner 30 minutes."
            ],
            "cuisson": [
                "Griller les blancs de poulet jusqu'à ce qu'ils soient bien cuits."
            ],
            "cooking_methods": {
                "best": "grill",
                "plancha": true,
                "four": false,
                "friture": false
            },
            "mode_cuisson": [
                "grill",
                "plancha"
            ],
            "accompagnement": "Servir avec une salade fraîche.",
            "shortStory": "Le poulet au chimichurri est un plat emblématique de la cuisine argentine. La marinade à base de persil frais, d'ail et de vinaigre apporte une explosion de saveurs qui rehausse le goût du poulet grillé.",
            "detailURL": "/fiche-recette/58/persil-ail-piment-rouge-vinaigre-huile-d-olive/poulet-chimichurri.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "15mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Ingrédients frais et accessibles"
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Un petit goût piquant grâce au piment"
            },
            "calories": "250 Kcal",
            "allergens": [
                "huile d'olive",
                "ail"
            ],
            "difficulty": {
                "level": 1,
                "description": "Recette facile et rapide, idéale pour les débutants"
            },
            "equipment_needed": [
                "grill",
                "mixeur"
            ]
        },
        {
            "id": 122,
            "categorie": "amerique-sud",
            "country": "Argentine",
            "flag": "AR",
            "title": "Poulet al Asador",
            "subTitle": "Poulet mariné avec du chimichurri, grillé pour des saveurs authentiques",
            "chicken_piece": [
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "1 poulet entier",
                "1 bouquet de persil frais",
                "5 gousses d’ail",
                "100 ml de vinaigre de vin rouge",
                "150 ml d’huile d’olive",
                "1 cuillère à soupe d’origan séché",
                "1 cuillère à café de piment rouge moulu (facultatif)",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "persil",
                "ail",
                "vinaigre",
                "huile-d'olive",
                "origan",
                "piment-rouge",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Préparer le chimichurri : dans un mixeur, combiner le persil, l’ail, le vinaigre, l’huile d’olive, l’origan, le piment rouge, le sel et le poivre. Mixer jusqu’à obtenir une sauce homogène.",
                "Badigeonner généreusement le poulet entier avec le chimichurri, à l’intérieur et à l’extérieur.",
                "Laisser mariner au réfrigérateur pendant au moins 2 heures, idéalement une nuit."
            ],
            "cuisson": [
                "Faire griller le poulet à feu moyen, soit sur une broche ou sur une grille, jusqu’à ce qu’il soit bien doré et cuit à cœur (environ 1h15).",
                "Griller lentement sur une broche ou une grille au barbecue, en badigeonnant régulièrement avec du chimichurri."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": false,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Servir avec des légumes grillés ou des pommes de terre sautées.",
            "shortStory": "Le **Pollo al Asador** est un incontournable des asados argentins, où le poulet est sublimé par le chimichurri. Ce plat reflète l’art de la grillade en Argentine, où chaque cuisson au barbecue devient une célébration.",
            "detailURL": "/fiche-recette/122/poulet-entier-persil-ail/poulet-pollo-al-asador.html",
            "time_prepare": "20mn",
            "time_maceration": "2h",
            "time_cooking": "1h15",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), avec des ingrédients simples mais un poulet entier de qualité est nécessaire."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Le chimichurri offre un goût savoureux et légèrement piquant grâce au piment rouge facultatif."
            },
            "calories": "450 kcal",
            "allergens": [
                "vinaigre"
            ],
            "difficulty": {
                "level": 2,
                "description": "La recette nécessite une surveillance continue lors de la cuisson pour garantir une cuisson uniforme."
            },
            "equipment_needed": [
                "broche",
                "barbecue",
                "bol"
            ]
        },
        {
            "id": 7829,
            "categorie": "amerique-sud",
            "country": "Argentine",
            "flag": "AR",
            "title": "Poulet a la Parilla",
            "subTitle": "Poulet mariné avec du jus d’orange, paprika et ail, grillé à la perfection",
            "chicken_piece": [
                "cuisse",
                "pilon",
                "entier"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier découpé ou 8 morceaux (cuisse, pilon)",
                "100 ml de jus d’orange frais",
                "1 cuillère à soupe de paprika doux",
                "3 gousses d’ail hachées",
                "1 cuillère à soupe d’huile d’olive",
                "1 cuillère à café de thym séché",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "jus-d'orange",
                "paprika",
                "ail",
                "huile-d'olive",
                "thym",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le jus d’orange, le paprika, l’ail, l’huile d’olive, le thym, le sel et le poivre.",
                "Badigeonner les morceaux de poulet avec la marinade et laisser reposer au réfrigérateur pendant au moins 1 heure."
            ],
            "cuisson": [
                "Préchauffer un barbecue ou une grille.",
                "Faire griller les morceaux de poulet à feu moyen jusqu’à ce qu’ils soient bien dorés et cuits à cœur, en les retournant régulièrement (environ 25 minutes).",
                "Griller au barbecue ou sur une grille pour une cuisson homogène et une saveur légèrement fumée."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": false,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "barbecue",
                "grill"
            ],
            "accompagnement": "Servir avec une salade fraîche ou du riz.",
            "shortStory": "Le **Pollo a la Parilla** est un favori des grillades en Argentine, où le jus d’orange et les épices apportent une touche unique au poulet grillé. Ce plat est une ode à la simplicité et aux saveurs naturelles.",
            "detailURL": "/fiche-recette/7829/poulet-entier-jus-orange-paprika/poulet-pollo-a-la-parilla.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "25mn",
            "crispys": true,
            "cost": {
                "level": 1,
                "description": "Le coût de cette recette est économique (niveau 1), avec des ingrédients simples et accessibles."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Les saveurs douces du jus d’orange équilibrent parfaitement la légère chaleur du paprika."
            },
            "calories": "380 kcal",
            "allergens": [
                "jus d'orange"
            ],
            "difficulty": {
                "level": 1,
                "description": "La recette est simple et facile à réaliser, idéale pour les repas conviviaux."
            },
            "equipment_needed": [
                "grill",
                "bol",
                "pince de cuisine"
            ]
        },
        {
            "id": 127,
            "categorie": "europe",
            "country": "Autriche",
            "flag": "AT",
            "title": "Poulet au Paprika à l’ail et au yaourt",
            "subTitle": "Poulet mariné au paprika fumé, à l’ail et au yaourt",
            "chicken_piece": [
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "1 poulet entier",
                "2 cuillères à soupe de paprika fumé",
                "3 gousses d'ail",
                "150 g de yaourt nature",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "paprika-fume",
                "ail",
                "yaourt-nature",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger le paprika fumé, l'ail émincé, le yaourt, le sel et le poivre pour obtenir une marinade.",
                "Badigeonner le poulet avec cette marinade et laisser mariner au réfrigérateur pendant au moins 2 heures."
            ],
            "cuisson": [
                "Faire cuire le poulet au four à 180°C pendant environ 1h, jusqu’à ce qu'il soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec du riz basmati ou une salade verte.",
            "shortStory": "Le **paprika fumé** est un ingrédient clé de la cuisine autrichienne, apportant une saveur unique et fumée au poulet, enrichie par l'**ail et le yaourt** pour une **marinade savoureuse**.",
            "detailURL": "/fiche-recette/127/paprika-fume-ail-yaourt/poulet-au-paprika.html",
            "time_prepare": "10mn",
            "time_maceration": "2h",
            "time_cooking": "1h",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Coût modéré (niveau 2) principalement pour le poulet et le paprika fumé."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Une marinade douce et légèrement fumée qui donne au poulet une texture juteuse et pleine de saveurs."
            },
            "calories": "350 kcal",
            "allergens": [
                "lait"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette relativement simple, mais nécessite de laisser mariner le poulet pour une saveur optimale."
            },
            "equipment_needed": [
                "four",
                "bol"
            ]
        },
        {
            "id": 128,
            "categorie": "europe",
            "country": "Autriche",
            "flag": "AT",
            "title": "Backhendl (Poulet pané)",
            "subTitle": "Poulet mariné au citron et aux épices, pané et frit",
            "chicken_piece": [
                "entier",
                "blanc",
                "pilon"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "1 citron",
                "2 cuillères à soupe de paprika",
                "1 gousse d'ail",
                "100 g de farine",
                "2 œufs",
                "100 g de chapelure",
                "Huile pour la friture",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "citron",
                "paprika",
                "ail",
                "farine",
                "œufs",
                "chapelure",
                "huile",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Presser le citron et mélanger son jus avec le paprika, l'ail émincé, le sel et le poivre pour préparer la marinade.",
                "Badigeonner les blancs de poulet avec cette marinade et laisser mariner pendant 30 minutes.",
                "Préparer un station de panage avec la farine, les œufs battus et la chapelure."
            ],
            "cuisson": [
                "Passer les blancs de poulet mariné dans la farine, puis dans l'œuf battu et enfin dans la chapelure.",
                "Faire frire le poulet dans de l'huile chaude jusqu'à ce qu'il soit doré et croustillant, environ 10-12 minutes."
            ],
            "cooking_methods": {
                "best": "friture",
                "grill": false,
                "plancha": false,
                "friture": true,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir avec des pommes de terre sautées ou une salade fraîche.",
            "shortStory": "Le Backhendl est un plat classique autrichien, souvent préparé lors de repas festifs, avec une **panure dorée et croustillante** qui enveloppe un poulet tendre et savoureux.",
            "detailURL": "/fiche-recette/128/citron-paprika-panure/backhendl-poulet-pane.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "12mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Coût modéré (niveau 2) pour les ingrédients comme la chapelure et le poulet."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Un plat classique, avec une panure légère et croustillante, parfait pour les repas conviviaux."
            },
            "calories": "450 kcal",
            "allergens": [
                "gluten",
                "œufs"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette facile à réaliser mais nécessitant une bonne gestion du processus de panage et de friture."
            },
            "equipment_needed": [
                "friteuse",
                "bol",
                "assiette"
            ]
        },
        {
            "id": 125,
            "categorie": "oceanie",
            "country": "Australie",
            "flag": "AU",
            "title": "Poulet au Citron Myrte",
            "subTitle": "Poulet mariné avec citron myrte, ail et huile d’olive",
            "chicken_piece": [
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "1 poulet entier",
                "2 cuillères à soupe de citron myrte",
                "5 gousses d'ail",
                "100 ml d'huile d'olive",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "citron-myrte",
                "ail",
                "huile-d'olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Presser le citron myrte et mélanger son jus avec l'ail émincé, l'huile d'olive, le sel et le poivre.",
                "Badigeonner le poulet avec cette marinade et laisser mariner au réfrigérateur pendant 1 heure."
            ],
            "cuisson": [
                "Faire cuire le poulet entier au four à 180°C pendant environ 1h, jusqu’à ce qu'il soit doré et bien cuit."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec des légumes grillés ou une salade de quinoa.",
            "shortStory": "Le **citron myrte** est un ingrédient typique de l'Australie, avec son goût acidulé et rafraîchissant, parfait pour mariner le poulet et lui donner une saveur unique.",
            "detailURL": "/fiche-recette/125/citron-myrte-ail/poulet-au-citron-myrte.html",
            "time_prepare": "10mn",
            "time_maceration": "1h",
            "time_cooking": "1h",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Coût modéré (niveau 2), principalement pour le poulet et le citron myrte."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Une marinade fraîche et légère, avec des saveurs de citron et d'ail."
            },
            "calories": "300 kcal",
            "allergens": [
                "huile-d'olive"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette simple mais nécessite de bien gérer la cuisson pour obtenir un poulet juteux."
            },
            "equipment_needed": [
                "four",
                "bol"
            ]
        },
        {
            "id": 126,
            "categorie": "oceanie",
            "country": "Australie",
            "flag": "AU",
            "title": "Poulet Barbecue Australien",
            "subTitle": "Poulet mariné avec une sauce sucrée-salée au tamarin et miel",
            "chicken_piece": [
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "1 poulet entier",
                "2 cuillères à soupe de tamarin",
                "2 cuillères à soupe de miel",
                "1 cuillère à soupe de sauce soja",
                "1 gousse d'ail",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "tamarind",
                "miel",
                "sauce-soja",
                "ail",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger le tamarin, le miel, la sauce soja, l'ail émincé, le sel et le poivre pour obtenir une marinade.",
                "Badigeonner le poulet avec cette marinade et laisser mariner pendant au moins 2 heures."
            ],
            "cuisson": [
                "Faire cuire le poulet au barbecue pendant environ 1h, en le retournant régulièrement et en l'arrosant avec la marinade."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": false,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Servir avec une salade de légumes frais ou des pommes de terre grillées.",
            "shortStory": "Le **Poulet Barbecue** Australien est un plat typique des barbecues australiens, avec une marinade **sucrée-salée** qui apporte une saveur unique au **poulet grillé**.",
            "detailURL": "/fiche-recette/126/tamarind-miel/poulet-barbecue-australien.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "1h",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Coût modéré (niveau 2), principalement pour le poulet et les ingrédients pour la marinade."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Une marinade sucrée-salée avec du tamarin et du miel qui apporte des saveurs douces, sans épices fortes."
            },
            "calories": "350 kcal",
            "allergens": [
                "soja",
                "miel"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette facile, mais il faut veiller à la cuisson sur le barbecue pour obtenir un poulet bien grillé."
            },
            "equipment_needed": [
                "barbecue",
                "bol"
            ]
        },
        {
            "id": 129,
            "categorie": "asiatique",
            "country": "Azerbaïdjan",
            "flag": "AZ",
            "title": "Poulet Lavangi",
            "subTitle": "Poulet mariné dans une farce de noix, ail et épices",
            "chicken_piece": [
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "1 poulet entier",
                "100 g de noix hachées",
                "3 gousses d'ail",
                "2 cuillères à soupe de curcuma",
                "1 cuillère à soupe de paprika",
                "Sel et poivre",
                "2 cuillères à soupe d'huile d'olive"
            ],
            "ingredient_liste": [
                "poulet",
                "noix-hachees",
                "ail",
                "curcuma",
                "paprika",
                "sel",
                "poivre",
                "huile-d-olive"
            ],
            "preparation": [
                "Mélanger les noix hachées, l'ail écrasé, le curcuma, le paprika, le sel et le poivre dans un bol.",
                "Badigeonner le poulet avec l'huile d'olive et farcir le poulet avec ce mélange d'épices et de noix.",
                "Laisser mariner pendant 1 à 2 heures."
            ],
            "cuisson": [
                "Cuire le poulet au four à 180°C pendant environ 1 heure, jusqu'à ce qu'il soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec du riz pilaf ou des légumes grillés.",
            "shortStory": "Le **poulet Lavangi** est une recette traditionnelle azérie où le **poulet est farci** d'une délicieuse farce aux noix et aux épices, offrant des saveurs riches et profondes.",
            "detailURL": "/fiche-recette/129/noix-ail-curcuma/lavangi-poulet.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "1h",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Le coût de cette recette est moyen à élevé en raison des noix et des épices utilisées."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Un plat délicat, aux saveurs subtiles et parfumées, parfait pour un repas raffiné."
            },
            "calories": "380 kcal",
            "allergens": [
                "noix",
                "gluten (éventuellement dans le mélange d'épices)"
            ],
            "difficulty": {
                "level": 3,
                "description": "Cette recette nécessite un peu plus de préparation et de soin pour la farce et la cuisson du poulet."
            },
            "equipment_needed": [
                "four",
                "bol"
            ]
        },
        {
            "id": 130,
            "categorie": "asiatique",
            "country": "Azerbaïdjan",
            "flag": "AZ",
            "title": "Chicken Plov",
            "subTitle": "Poulet mariné dans des épices comme le cumin et le curcuma, intégré au pilaf",
            "chicken_piece": [
                "blanc",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 morceaux de poulet (blancs, cuisses ou pilons)",
                "2 cuillères à soupe de cumin",
                "1 cuillère à soupe de curcuma",
                "300 g de riz basmati",
                "2 oignons",
                "2 tomates",
                "Sel et poivre",
                "2 cuillères à soupe d'huile d'olive",
                "1 gousse d'ail"
            ],
            "ingredient_liste": [
                "poulet",
                "cumin",
                "curcuma",
                "riz-basmati",
                "oignons",
                "tomates",
                "sel",
                "poivre",
                "huile-d-olive",
                "ail"
            ],
            "preparation": [
                "Dans une grande poêle, faire revenir les morceaux de poulet avec l'ail, l'oignon haché et les épices (cumin, curcuma).",
                "Ajouter les tomates coupées et laisser cuire pendant 10 minutes.",
                "Ajouter le riz basmati et bien mélanger pour enrober le riz des épices.",
                "Ajouter de l'eau (environ 600 ml) et laisser cuire à feu doux jusqu'à ce que le riz soit tendre."
            ],
            "cuisson": [
                "Laisser mijoter pendant environ 30 minutes jusqu'à ce que le riz soit cuit et le poulet tendre."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec une salade de légumes frais ou un yaourt nature.",
            "shortStory": "Le **poulet Plov** est un plat typique d'Azerbaïdjan, combinant le riz parfumé aux épices et le **poulet tendre**, une recette pleine de saveurs et de couleurs, souvent préparée pour des repas festifs.",
            "detailURL": "/fiche-recette/130/cumin-curcuma-pilaf/poulet-plov.html",
            "time_prepare": "10mn",
            "time_maceration": "0mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Coût moyen (niveau 2) pour les épices et le riz basmati."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Un plat parfumé et épicé, équilibré et délicieux, parfait pour les repas en famille ou entre amis."
            },
            "calories": "420 kcal",
            "allergens": [
                "gluten (éventuellement dans les épices)"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette simple à réaliser mais nécessite un bon contrôle de la cuisson du riz et du poulet."
            },
            "equipment_needed": [
                "cocotte",
                "poêle",
                "bol"
            ]
        },
        {
            "id": 139,
            "categorie": "europe",
            "country": "Bosnie-Herzégovine",
            "flag": "BA",
            "title": "Poulet Sogan-Dolma",
            "subTitle": "Poulet mariné avec citron, ail et mélange d'épices (cumin, coriandre), intégré à un ragoût d’oignons farcis.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "1 citron",
                "4 gousses d'ail",
                "1 cuillère à café de paprika",
                "1 oignon",
                "1 cuillère à café de cumin",
                "1 cuillère à café de coriandre",
                "1 tasse de bouillon de volaille"
            ],
            "ingredient_liste": [
                "citron",
                "ail",
                "paprika",
                "oignon",
                "cumin",
                "coriandre",
                "bouillon-de-volaille"
            ],
            "preparation": [
                "Mélanger le jus de citron, l'ail écrasé, le paprika, le cumin et la coriandre pour mariner le poulet pendant 1 heure."
            ],
            "cuisson": [
                "Faire revenir l'oignon dans une cocotte avec un peu d'huile jusqu'à ce qu'il soit doré.",
                "Ajouter les cuisses de poulet mariné et faire dorer des deux côtés.",
                "Verser le bouillon et cuire pendant 40 minutes."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz ou des légumes de saison.",
            "shortStory": "**Poulet Sogan-Dolma** est un plat traditionnel bosnien où le **poulet** et les **épices** (cumin, coriandre) s’harmonisent parfaitement avec les oignons farcis, créant un ragoût riche et savoureux.",
            "detailURL": "/fiche-recette/139/citron-ail-cumin-coriandre/poulet-sogan-dolma.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat savoureux nécessitant un peu de temps pour la préparation."
            },
            "images": [],
            "hot": 1,
            "hot_description": "Légèrement épicé grâce au cumin et à la coriandre.",
            "calories": "350 kcal",
            "allergens": [
                "ail"
            ],
            "difficulty": {
                "level": 2,
                "description": "Modéré, nécessite une attention particulière à la cuisson."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "cuillère en bois"
            ]
        },
        {
            "id": 140,
            "categorie": "europe",
            "country": "Bosnie-Herzégovine",
            "flag": "BA",
            "title": "Poulet Grillé aux Herbes",
            "subTitle": "Poulet mariné avec persil, ail et paprika.",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "2 cuillères à soupe de persil frais haché",
                "4 gousses d'ail",
                "1 cuillère à soupe de paprika",
                "Sel et poivre au goût",
                "Huile d'olive"
            ],
            "ingredient_liste": [
                "persil",
                "ail",
                "paprika",
                "sel",
                "poivre",
                "huile-d-olive"
            ],
            "preparation": [
                "Hacher le persil et l'ail, puis mélanger avec le paprika, l'huile d'olive, le sel et le poivre.",
                "Mariner les morceaux de poulet pendant 30 minutes."
            ],
            "cuisson": [
                "Préchauffer le grill.",
                "Faire cuire les morceaux de poulet sur le grill pendant environ 25 minutes, en les retournant régulièrement pour qu'ils soient dorés et cuits uniformément."
            ],
            "cooking_methods": {
                "best": "grill",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "grill"
            ],
            "accompagnement": "Servir avec des légumes grillés ou une salade verte.",
            "shortStory": "**Poulet Grillé aux Herbes** est un plat simple mais délicieux, où le **poulet** prend toute sa saveur grâce à la marinade au persil, à l'ail et au **paprika**.",
            "detailURL": "/fiche-recette/140/persil-ail-paprika/poulet-grille-aux-herbes.html",
            "time_prepare": "10mn",
            "time_maceration": "30mn",
            "time_cooking": "25mn",
            "crispys": true,
            "cost": {
                "level": 1,
                "description": "Facile et rapide à préparer avec des ingrédients simples."
            },
            "images": [],
            "hot": 1,
            "hot_description": "Légèrement épicé grâce au paprika.",
            "calories": "400 kcal",
            "allergens": [
                "ail"
            ],
            "difficulty": {
                "level": 1,
                "description": "Très facile à réaliser."
            },
            "equipment_needed": [
                "grill",
                "couteau",
                "bol"
            ]
        },
        {
            "id": 1455,
            "categorie": "caraibes",
            "country": "Barbade",
            "flag": "BB",
            "title": "Poulet Rôti Bajan",
            "subTitle": "Poulet mariné avec ail, thym, vinaigre et piment, rôti ou grillé",
            "chicken_piece": [
                "entier",
                "blanc",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier",
                "4 gousses d'ail écrasées",
                "1 bouquet de thym frais",
                "2 cuillères à soupe de vinaigre",
                "1 piment Scotch Bonnet haché",
                "1 cuillère à soupe de moutarde",
                "Sel et poivre au goût",
                "4 cuillères à soupe d'huile d'olive"
            ],
            "ingredient_liste": [
                "poulet",
                "ail",
                "thym",
                "vinaigre",
                "piment-scotch-bonnet",
                "moutarde",
                "sel",
                "poivre",
                "huile-d-olive"
            ],
            "preparation": [
                "Mélanger l'ail, le thym, le vinaigre, le piment, la moutarde, le sel et le poivre pour créer la marinade.",
                "Badigeonner le poulet entier avec cette marinade et laisser reposer pendant 2 heures."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C ou allumer le barbecue.",
                "Rôtir ou griller le poulet pendant environ 1 heure, en le retournant à mi-cuisson pour qu'il soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four",
                "grill"
            ],
            "accompagnement": "Servir avec une salade fraîche ou des légumes grillés.",
            "shortStory": "**Poulet Rôti Bajan** est un classique de la Barbade, où la chaleur du piment Scotch Bonnet se mêle à la fraîcheur du thym et de l'ail, créant un poulet tendre et savoureux, idéal pour les repas en plein air.",
            "detailURL": "/fiche-recette/1455/ail-thym-vinaigre-piment/poulet-roti-bajan.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "1h",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Plat abordable avec des ingrédients faciles à trouver."
            },
            "images": [],
            "hot": {
                "level": 4,
                "description": "Le plat est assez épicé, grâce au piment Scotch Bonnet."
            },
            "calories": "400 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Facile à réaliser, parfait pour une cuisson en extérieur."
            },
            "equipment_needed": [
                "four",
                "grill",
                "bol",
                "couteau"
            ]
        },
        {
            "id": 8189,
            "categorie": "caraibes",
            "country": "Barbade",
            "flag": "BB",
            "title": "Poulet Cou-Cou",
            "subTitle": "Poulet mariné avec un mélange d'épices (cumin, paprika, curcuma) et accompagné de polenta au gombo.",
            "chicken_piece": [
                "entier",
                "blanc",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 cuillères à soupe de mélange d'épices (cumin, paprika, curcuma)",
                "2 gousses d'ail écrasées",
                "1 oignon",
                "1 piment Scotch Bonnet haché",
                "1 tasse de polenta",
                "1/2 tasse de gombo haché",
                "1 cuillère à soupe d'huile",
                "1 tasse de bouillon de poulet",
                "Sel et poivre au goût"
            ],
            "ingredient_liste": [
                "poulet",
                "cumin",
                "paprika",
                "curcuma",
                "ail",
                "oignon",
                "piment-scotch-bonnet",
                "polenta",
                "gombo",
                "huile",
                "bouillon-poulet",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger le cumin, le paprika, le curcuma, l'ail, le piment, le sel et le poivre. Badigeonner les cuisses de poulet avec cette marinade et laisser reposer pendant 1 heure.",
                "Préparer la polenta en la cuisant dans le bouillon de poulet. Ajouter le gombo et bien mélanger."
            ],
            "cuisson": [
                "Faire cuire les cuisses de poulet dans une poêle avec l'huile pendant 25-30 minutes, jusqu'à ce qu'elles soient bien dorées et cuites à cœur.",
                "Servir le poulet avec la polenta au gombo, en l'accompagnant du bouillon restant."
            ],
            "cooking_methods": {
                "best": "poêle",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": true,
                "cocotte": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Accompagner ce plat de légumes grillés ou d'une sauce piquante.",
            "shortStory": "**Le poulet Cou-Cou** est un plat traditionnel de la Barbade, où des épices comme le **cumin**, le **paprika** et le **curcuma** donnent au poulet une saveur profonde, tandis que la polenta au gombo apporte une touche de douceur et de crémeux à chaque bouchée.",
            "detailURL": "/fiche-recette/8189/cumin-paprika-curcuma-ail-piment/poulet-cou-cou.html",
            "time_prepare": "20mn",
            "time_maceration": "1h",
            "time_cooking": "30mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Plat relativement économique avec des ingrédients simples."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Le plat est épicé grâce au piment Scotch Bonnet."
            },
            "calories": "350 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Facile à réaliser, idéale pour un repas copieux."
            },
            "equipment_needed": [
                "poêle",
                "casserole",
                "bol",
                "couteau"
            ]
        },
        {
            "id": 4818,
            "categorie": "asiatique",
            "country": "Bangladesh",
            "flag": "BD",
            "title": "Chicken Tikka",
            "subTitle": "Poulet mariné au yaourt, gingembre, ail et piment, grillé au four",
            "chicken_piece": [
                "entier",
                "blanc",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "1 tasse de yaourt nature",
                "1 cuillère à soupe de gingembre frais râpé",
                "4 gousses d'ail écrasées",
                "1 cuillère à soupe de piment en poudre",
                "1 cuillère à soupe de garam masala",
                "1 cuillère à soupe de curcuma",
                "Sel et poivre au goût",
                "4 cuillères à soupe d'huile d'olive"
            ],
            "ingredient_liste": [
                "yaourt",
                "gingembre",
                "ail",
                "piment-en-poudre",
                "garam-masala",
                "curcuma",
                "sel",
                "poivre",
                "huile-d-olive"
            ],
            "preparation": [
                "Mélanger le yaourt avec les épices (gingembre, ail, piment, garam masala, curcuma), le sel et l'huile.",
                "Découper les blancs de poulet en morceaux et les enrober de marinade. Laisser reposer pendant 2 heures."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C.",
                "Disposer les morceaux de poulet sur une plaque de cuisson et cuire au four pendant 25 à 30 minutes, jusqu'à ce que le poulet soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec une sauce au yaourt et une salade fraîche.",
            "shortStory": "**Chicken Tikka** est un classique de la cuisine bangladaise, où le poulet, mariné dans un **mélange d'épices et de yaourt**, prend une texture **tendre** et savoureuse, parfait pour être dégusté avec du riz ou du naan.",
            "detailURL": "/fiche-recette/4818/yaourt-gingembre-ail/piment-chicken-tikka.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "30mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Le coût est abordable grâce à des ingrédients simples et courants."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Ce plat est épicé, avec une chaleur agréable apportée par le piment."
            },
            "calories": "350 kcal",
            "allergens": [
                "lactose (yaourt)"
            ],
            "difficulty": {
                "level": 2,
                "description": "Facile à préparer, parfait pour une recette rapide et savoureuse."
            },
            "equipment_needed": [
                "four",
                "bol",
                "couteau"
            ]
        },
        {
            "id": 7793,
            "categorie": "asiatique",
            "country": "Bangladesh",
            "flag": "BD",
            "title": "Chicken Rezala",
            "subTitle": "Poulet mariné dans du yaourt, cardamome, cannelle et noix de cajou, mijoté",
            "chicken_piece": [
                "entier",
                "blanc",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier ou morceaux",
                "1 tasse de yaourt nature",
                "1 cuillère à soupe de cardamome moulue",
                "1 cuillère à soupe de cannelle moulue",
                "1/2 tasse de noix de cajou",
                "2 oignons",
                "4 gousses d'ail",
                "1 cuillère à soupe de gingembre frais râpé",
                "Sel et poivre au goût",
                "4 cuillères à soupe d'huile"
            ],
            "ingredient_liste": [
                "yaourt",
                "cardamome",
                "cannelle",
                "noix-de-cajou",
                "oignons",
                "ail",
                "gingembre",
                "sel",
                "poivre",
                "huile"
            ],
            "preparation": [
                "Mélanger le yaourt avec les épices (cardamome, cannelle, gingembre), les noix de cajou broyées et l'ail écrasé.",
                "Badigeonner le poulet avec cette marinade et laisser reposer au réfrigérateur pendant 2 heures.",
                "Faire revenir les oignons dans l'huile jusqu'à ce qu'ils soient dorés."
            ],
            "cuisson": [
                "Ajouter le poulet mariné aux oignons, puis cuire à feu doux pendant 45 minutes, jusqu'à ce que le poulet soit tendre.",
                "Incorporer un peu d'eau si nécessaire pour éviter que le mélange n'attache."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz basmati parfumé ou du pain naan.",
            "shortStory": "**Poulet Rezala** est un plat traditionnel du Bangladesh, où la douceur du **yaourt** et des **noix de cajou** se marie parfaitement avec les épices chaudes, créant un **ragoût riche et savoureux**.",
            "detailURL": "/fiche-recette/7793/yaourt-cardamome-cannelle/noix-cajou/Poulet-rezala.html",
            "time_prepare": "20mn",
            "time_maceration": "2h",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Le coût est moyen, principalement en raison des noix de cajou et des épices."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Ce plat est légèrement épicé, mais la douceur du yaourt équilibre les saveurs."
            },
            "calories": "420 kcal",
            "allergens": [
                "noix-de-cajou"
            ],
            "difficulty": {
                "level": 3,
                "description": "La recette demande un peu de temps de préparation, mais elle reste accessible."
            },
            "equipment_needed": [
                "cocotte",
                "bol",
                "couteau"
            ]
        },
        {
            "id": 6105,
            "categorie": "europe",
            "country": "Belgique",
            "flag": "BE",
            "title": "Poulet à la Bière",
            "subTitle": "Poulet mariné dans de la bière blonde, moutarde et herbes.",
            "chicken_piece": [
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "cuisse": false,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier",
                "250 ml de bière blonde",
                "2 cuillères à soupe de moutarde",
                "1 branche de thym",
                "1 feuille de laurier",
                "1 cuillère à soupe de sucre",
                "Sel et poivre au goût"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "bière-blonde",
                "moutarde",
                "thym",
                "laurier",
                "sucre",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger la bière blonde, la moutarde, le thym, le laurier et le sucre.",
                "Badigeonner le poulet entier avec cette marinade et laisser mariner pendant au moins 1 heure, idéalement 4 heures.",
                "Saler et poivrer le poulet."
            ],
            "cuisson": [
                "Préchauffer le four à 180°C.",
                "Placer le poulet mariné dans un plat à rôtir et cuire au four pendant environ 1h30, en l'arrosant régulièrement avec le jus de la marinade."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": false,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec des frites ou de la purée de pommes de terre.",
            "shortStory": "Le **Poulet à la Bière** est un plat belge classique, où le poulet est mariné dans une bière blonde, puis rôti doucement pour obtenir une peau dorée et savoureuse. Ce plat réconfortant est parfait pour une soirée entre amis ou en famille.",
            "detailURL": "/fiche-recette/6105/poulet-biere/poulet-biere.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "1h30",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré grâce à l'utilisation de bière blonde et de moutarde, des ingrédients simples mais délicieux."
            },
            "images": [],
            "hot": 0,
            "hot_description": "Un plat savoureux, mais pas particulièrement épicé.",
            "calories": "400 kcal",
            "allergens": [
                "gluten (dans la bière)"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette facile, mais nécessite un peu de patience pour mariner le poulet et bien le cuire."
            },
            "equipment_needed": [
                "mixeur",
                "four",
                "plat à rôtir",
                "cuillère"
            ]
        },
        {
            "id": 8458,
            "categorie": "europe",
            "country": "Belgique",
            "flag": "BE",
            "title": "Waterzooi de Poulet",
            "subTitle": "Poulet mariné avec des herbes et citron, mijoté dans une sauce crémeuse.",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "2 cuisses de poulet",
                "1 oignon",
                "2 carottes",
                "1 branche de céleri",
                "2 pommes de terre",
                "500 ml de bouillon de volaille",
                "250 ml de crème épaisse",
                "1 cuillère à soupe de beurre",
                "Le jus de 1 citron",
                "1 cuillère à soupe de persil frais haché",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "cuisses-de-poulet",
                "oignon",
                "carottes",
                "celeri",
                "pommes-de-terre",
                "bouillon-de-volaille",
                "creme-epaisse",
                "beurre",
                "citron",
                "persil",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans une grande casserole, faire fondre le beurre et faire revenir l'oignon, les carottes et le céleri coupés en morceaux.",
                "Ajouter les pommes de terre coupées en dés, le bouillon de volaille et porter à ébullition.",
                "Réduire la chaleur et ajouter le poulet. Laisser mijoter pendant 30 minutes ou jusqu'à ce que le poulet soit cuit.",
                "Retirer le poulet et le désosser. Remettre la viande dans la casserole."
            ],
            "cuisson": [
                "Ajouter la crème épaisse, le jus de citron, le persil, le sel et le poivre. Laisser mijoter pendant 10 minutes supplémentaires pour bien mélanger les saveurs.",
                "Servir chaud, garni de persil frais."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Accompagner de pain frais ou de pâtes.",
            "shortStory": "Le Waterzooi de Poulet est un plat traditionnel belge, réconfortant et **crémeux**. Ce ragoût de **poulet mijoté** avec des légumes et une sauce crémeuse à base de bouillon et de crème est parfait pour les journées fraîches.",
            "detailURL": "/fiche-recette/8458/waterzooi-poulet/waterzooi-poulet.html",
            "time_prepare": "20mn",
            "time_maceration": "0",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût est modéré grâce aux ingrédients simples tels que les légumes, le bouillon et la crème. Le poulet constitue l'élément principal de ce plat copieux."
            },
            "images": [],
            "hot": 0,
            "hot_description": "Recette douce et crémeuse, idéale pour un repas familial.",
            "calories": "420 kcal",
            "allergens": [
                "lactose",
                "gluten (éventuellement dans le pain)"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette assez simple à réaliser, mais qui nécessite un temps de cuisson pour bien mijoter et développer les saveurs."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "cuillère en bois"
            ]
        },
        {
            "id": 153,
            "categorie": "afrique",
            "country": "Burkina Faso",
            "flag": "BF",
            "title": "Poulet à la Sauce Arachide",
            "subTitle": "Poulet mijoté dans une sauce riche à base de pâte d'arachide et d'épices.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "1kg de morceaux de poulet",
                "200g de pâte d'arachide",
                "3 tomates, hachées",
                "2 oignons, émincés",
                "2 gousses d'ail, écrasées",
                "1 litre de bouillon de volaille",
                "2 cuillères à soupe d'huile d'arachide",
                "1 piment frais (optionnel)",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "pate-d-arachide",
                "tomates",
                "oignons",
                "ail",
                "bouillon",
                "huile-d-arachide",
                "piment",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Faire revenir les morceaux de poulet dans une cocotte avec l'huile jusqu'à ce qu'ils soient dorés. Retirer et réserver.",
                "Dans la même cocotte, faire revenir les oignons, l'ail et les tomates jusqu'à ce qu'ils soient tendres.",
                "Ajouter la pâte d'arachide et mélanger jusqu'à obtention d'une consistance lisse."
            ],
            "cuisson": [
                "Remettre le poulet dans la cocotte et ajouter le bouillon. Mélanger bien.",
                "Laisser mijoter à feu doux pendant 45 minutes, en remuant de temps en temps.",
                "Servir chaud avec du riz blanc ou de l'igname."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc ou des tubercules comme l'igname ou le manioc.",
            "shortStory": "Cette recette emblématique du Burkina Faso est riche et savoureuse grâce à la pâte d'arachide, un ingrédient de base dans la cuisine africaine.",
            "detailURL": "/fiche-recette/153/pate-d-arachide/poulet-sauce-arachide.html",
            "time_prepare": "15mn",
            "time_maceration": "0mn",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Ingrédients abordables et faciles à trouver."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Peut être relevé en fonction du piment utilisé."
            },
            "calories": "420 kcal",
            "allergens": [
                "arachides"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile à préparer, nécessite une surveillance lors du mijotage."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 154,
            "categorie": "afrique",
            "country": "Burkina Faso",
            "flag": "BF",
            "title": "Poulet Yassa",
            "subTitle": "Poulet mariné au citron et à l'oignon, mijoté pour une saveur acidulée.",
            "chicken_piece": [
                "entier",
                "morceaux",
                "cuisse"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "1kg de morceaux de poulet",
                "4 gros oignons, émincés",
                "2 citrons (jus)",
                "2 cuillères à soupe de moutarde",
                "2 gousses d'ail, écrasées",
                "1 piment frais (optionnel)",
                "2 cuillères à soupe d'huile",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "oignons",
                "citron",
                "moutarde",
                "ail",
                "piment",
                "huile",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger les morceaux de poulet avec le jus de citron, la moutarde, l'ail, du sel et du poivre. Laisser mariner pendant 2 heures.",
                "Faire revenir les oignons dans une grande poêle avec de l'huile jusqu'à ce qu'ils soient légèrement dorés."
            ],
            "cuisson": [
                "Ajouter le poulet mariné aux oignons et cuire à feu moyen pendant 15 minutes.",
                "Ajouter un peu d'eau pour obtenir une sauce et laisser mijoter à feu doux pendant 30 minutes.",
                "Servir avec du riz blanc ou de l'attiéké."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte",
                "poele"
            ],
            "accompagnement": "Un plat souvent servi avec du riz blanc ou de l'attiéké pour une expérience complète.",
            "shortStory": "Originaire de l'Afrique de l'Ouest, le **Poulet Yassa** est très **apprécié pour sa marinade acidulée et ses oignons fondants**.",
            "detailURL": "/fiche-recette/154/citron-oignons/burkina-poulet-yassa.html",
            "time_prepare": "20mn",
            "time_maceration": "2h",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Ingrédients simples et accessibles."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Légèrement épicé si vous ajoutez du piment."
            },
            "calories": "380 kcal",
            "allergens": [
                "moutarde"
            ],
            "difficulty": {
                "level": 1,
                "description": "Simple à cuisiner, parfait pour les repas familiaux."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 150,
            "categorie": "europe",
            "country": "Bulgarie",
            "flag": "BG",
            "title": "Poulet Kavarma",
            "subTitle": "Poulet mariné avec oignons, poivrons, tomates et vin, mijoté.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (cuisse ou blanc)",
                "2 oignons moyens, émincés",
                "1 poivron rouge, coupé en lanières",
                "2 tomates, hachées",
                "1 verre de vin blanc sec",
                "3 gousses d'ail, écrasées",
                "2 cuillères à soupe d'huile d'olive",
                "1 bouquet de persil haché",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "oignons",
                "poivron",
                "tomates",
                "vin-blanc",
                "ail",
                "huile-d-olive",
                "persil",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger les morceaux de poulet avec l'ail écrasé, du sel, du poivre, et un filet d'huile d'olive. Laisser mariner pendant 1 heure au réfrigérateur.",
                "Chauffer une poêle avec de l'huile d'olive et faire revenir les oignons et le poivron jusqu'à ce qu'ils soient tendres.",
                "Ajouter les tomates hachées et laisser mijoter pendant 5 minutes."
            ],
            "cuisson": [
                "Dans une cocotte, ajouter les morceaux de poulet marinés aux légumes. Verser le vin blanc et mélanger.",
                "Couvrir et laisser mijoter à feu doux pendant 40 minutes, jusqu'à ce que le poulet soit tendre et la sauce réduite.",
                "Servir chaud avec du persil haché en garniture."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz ou du pain frais. Une salade de concombre et yaourt en accompagnement est également idéale.",
            "shortStory": "**Poulet Kavarma** est une spécialité bulgare mijotée où le poulet s'imprègne de **saveurs riches de vin, tomates et poivrons**, pour un repas réconfortant et savoureux.",
            "detailURL": "/fiche-recette/150/oignons-poivrons-vin/poulet-kavarma.html",
            "time_prepare": "20mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Ingrédients abordables mais temps de cuisson prolongé."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Aucun piquant, saveurs douces et équilibrées."
            },
            "calories": "320 kcal",
            "allergens": [
                "vin"
            ],
            "difficulty": {
                "level": 2,
                "description": "Niveau modéré, nécessite de la patience pour mijoter correctement."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 151,
            "categorie": "europe",
            "country": "Bulgarie",
            "flag": "BG",
            "title": "Poulet Grillé Bulgare",
            "subTitle": "Poulet mariné au yaourt, ail et paprika.",
            "chicken_piece": [
                "blanc",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "4 blancs de poulet ou cuisses (désossées)",
                "200g de yaourt nature",
                "3 gousses d'ail, écrasées",
                "1 cuillère à soupe de paprika doux",
                "1 cuillère à café de cumin moulu",
                "1 cuillère à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "blanc-de-poulet",
                "yaourt",
                "ail",
                "paprika",
                "cumin",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger le yaourt avec l'ail écrasé, le paprika, le cumin, l'huile d'olive, du sel et du poivre dans un bol.",
                "Enrober les morceaux de poulet avec la marinade. Couvrir et laisser mariner au réfrigérateur pendant 2 heures."
            ],
            "cuisson": [
                "Préchauffer un gril ou une poêle à feu moyen-élevé.",
                "Cuire les morceaux de poulet marinés pendant environ 6-8 minutes de chaque côté, jusqu'à ce qu'ils soient bien dorés et cuits à cœur.",
                "Laisser reposer quelques minutes avant de servir."
            ],
            "cooking_methods": {
                "best": "grill",
                "barbecue": true,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": true
            },
            "mode_cuisson": [
                "grill",
                "plancha"
            ],
            "accompagnement": "Accompagnez ce plat d'une salade fraîche ou de légumes grillés. Une sauce tzatziki est parfaite pour compléter les saveurs.",
            "shortStory": "**Poulet Grillé Bulgare** combine une marinade crémeuse au yaourt, rehaussée de paprika et d'ail, pour un plat simple mais riche en saveurs.",
            "detailURL": "/fiche-recette/151/yaourt-paprika/poulet-grille-bulgare.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "15mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Ingrédients accessibles et préparation facile."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Léger piquant avec le paprika."
            },
            "calories": "290 kcal",
            "allergens": [
                "lait",
                "ail"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, parfait pour les débutants."
            },
            "equipment_needed": [
                "grill",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 548,
            "categorie": "asiatique",
            "country": "Bahreïn",
            "flag": "BH",
            "title": "Poulet au Baharat",
            "subTitle": "Poulet mariné avec des épices baharat, yaourt et citron",
            "chicken_piece": [
                "entier",
                "blanc",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier ou morceaux",
                "2 cuillères à soupe de mélange d'épices baharat",
                "1 tasse de yaourt nature",
                "1 citron",
                "3 gousses d'ail",
                "Sel et poivre au goût",
                "2 cuillères à soupe d'huile d'olive"
            ],
            "ingredient_liste": [
                "baharat",
                "yaourt",
                "citron",
                "ail",
                "sel",
                "poivre",
                "huile-d-olive"
            ],
            "preparation": [
                "Mélanger les épices baharat avec le yaourt, le jus de citron, l'ail haché, le sel et le poivre.",
                "Enrober le poulet de cette marinade et laisser reposer au réfrigérateur pendant 2 heures.",
                "Préchauffer le four à 200°C."
            ],
            "cuisson": [
                "Disposer les morceaux de poulet marinés sur une plaque de cuisson.",
                "Rôtir au four pendant 45 minutes à 1 heure, jusqu'à ce que le poulet soit doré et bien cuit."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec un pain pita chaud ou du riz parfumé.",
            "shortStory": "Le **Poulet au Baharat** est une recette traditionnelle du Bahreïn, où les épices baharat apportent des saveurs complexes et chaudes à ce plat simple mais délicieux.",
            "detailURL": "/fiche-recette/548/baharat-yaourt-citron/poulet-baharat.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "1h",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût est abordable, les ingrédients sont faciles à trouver et économiques."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Ce plat est peu épicé, avec une douceur apportée par le yaourt et des saveurs équilibrées."
            },
            "calories": "370 kcal",
            "allergens": [
                "lactose (yaourt)"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette facile à préparer, idéale pour les débutants en cuisine exotique."
            },
            "equipment_needed": [
                "four",
                "bol",
                "couteau"
            ]
        },
        {
            "id": 6831,
            "categorie": "asiatique",
            "country": "Bahreïn",
            "flag": "BH",
            "title": "Machboos Dajaj",
            "subTitle": "Poulet mariné avec un mélange d’épices bahreïniennes (cardamome, curcuma, cannelle), cuit avec du riz",
            "chicken_piece": [
                "entier",
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier ou morceaux",
                "1 cuillère à soupe de cardamome moulue",
                "1 cuillère à soupe de curcuma",
                "1 cuillère à soupe de cannelle moulue",
                "2 oignons",
                "4 gousses d'ail",
                "2 tomates",
                "3 tasses de riz basmati",
                "4 cuillères à soupe d'huile d'olive",
                "Sel et poivre au goût"
            ],
            "ingredient_liste": [
                "cardamome",
                "curcuma",
                "cannelle",
                "oignons",
                "ail",
                "tomates",
                "riz-basmati",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger les épices (cardamome, curcuma, cannelle) avec l'huile d'olive, l'ail écrasé et le sel.",
                "Badigeonner le poulet avec cette marinade et laisser reposer pendant 1 heure.",
                "Faire revenir les oignons émincés dans une grande cocotte jusqu'à ce qu'ils soient dorés.",
                "Ajouter les tomates en dés et cuire jusqu'à obtention d'une sauce homogène."
            ],
            "cuisson": [
                "Ajouter le poulet mariné dans la cocotte et le faire dorer de tous les côtés.",
                "Incorporer le riz basmati rincé, couvrir d'eau ou de bouillon, et cuire à feu doux pendant 30 minutes, jusqu'à ce que le riz soit tendre et le poulet bien cuit."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec une salade de concombre et un yaourt nature.",
            "shortStory": "**Machboos Dajaj** est un plat emblématique du Bahreïn, combinant des **épices locales** riches et parfumées avec du riz basmati, pour une explosion de saveurs.",
            "detailURL": "/fiche-recette/6831/cardamome-curcuma-cannelle/machboos-dajaj.html",
            "time_prepare": "20mn",
            "time_maceration": "1h",
            "time_cooking": "1h",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Le coût est moyen à élevé, en raison des épices et du riz de qualité nécessaires pour ce plat."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Légèrement épicé, avec des saveurs riches et chaleureuses grâce à la cardamome et à la cannelle."
            },
            "calories": "450 kcal",
            "allergens": [
                "cannelle"
            ],
            "difficulty": {
                "level": 3,
                "description": "Nécessite une bonne maîtrise des temps de cuisson du riz et du poulet pour garantir un plat équilibré."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "bol"
            ]
        },
        {
            "id": 155,
            "categorie": "afrique",
            "country": "Burundi",
            "flag": "BI",
            "title": "Poulet à la Sauce Rouge",
            "subTitle": "Poulet mariné avec tomates, ail, oignons et piment, mijoté.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "1kg de morceaux de poulet",
                "3 tomates, hachées",
                "2 oignons, émincés",
                "3 gousses d'ail, écrasées",
                "1 piment rouge (optionnel)",
                "2 cuillères à soupe d'huile d'arachide",
                "1 cuillère à soupe de concentré de tomate",
                "1 cube de bouillon (optionnel)",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "tomates",
                "oignons",
                "ail",
                "piment",
                "huile-d-arachide",
                "concentre-de-tomate",
                "cube-bouillon",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger les morceaux de poulet avec l'ail écrasé, le sel, le poivre, et un filet d'huile. Laisser mariner 30 minutes.",
                "Faire chauffer l'huile dans une cocotte et faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter les tomates, le concentré de tomate, et le piment (si utilisé). Laisser mijoter 10 minutes."
            ],
            "cuisson": [
                "Ajouter les morceaux de poulet à la cocotte et les faire dorer légèrement.",
                "Ajouter un verre d'eau ou de bouillon, couvrir et laisser mijoter à feu doux pendant 40 minutes.",
                "Servir chaud avec du riz ou des bananes plantains."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte",
                "poele"
            ],
            "accompagnement": "Ce plat s'accompagne souvent de riz ou de bananes plantains frites.",
            "shortStory": "Le **Poulet à la Sauce Rouge** est un plat mijoté emblématique du Burundi, mettant en valeur la richesse des tomates et des épices locales.",
            "detailURL": "/fiche-recette/155/tomates-piment/poulet-sauce-rouge.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Ingrédients économiques et faciles à trouver."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Piquant modéré selon le type de piment utilisé."
            },
            "calories": "360 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Facile à réaliser, idéal pour un repas savoureux et rapide."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 156,
            "categorie": "afrique",
            "country": "Burundi",
            "flag": "BI",
            "title": "Poulet Nyama Choma",
            "subTitle": "Poulet mariné avec citron et herbes, grillé.",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier ou cuisses",
                "2 citrons (jus et zestes)",
                "4 gousses d'ail, écrasées",
                "2 cuillères à soupe de coriandre hachée",
                "1 cuillère à soupe de thym frais",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "citron",
                "ail",
                "coriandre",
                "thym",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger le jus de citron, les zestes, l'ail, la coriandre, le thym, l'huile, du sel et du poivre dans un bol pour préparer la marinade.",
                "Enrober généreusement le poulet avec la marinade et laisser reposer au réfrigérateur pendant au moins 2 heures."
            ],
            "cuisson": [
                "Préchauffer un barbecue ou un grill à feu moyen.",
                "Cuire le poulet mariné pendant environ 25-30 minutes, en le retournant régulièrement et en badigeonnant avec le reste de la marinade.",
                "Servir chaud, accompagné de légumes grillés ou d'une salade fraîche."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "barbecue": true,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": false,
                "plancha": true
            },
            "mode_cuisson": [
                "barbecue",
                "plancha"
            ],
            "accompagnement": "Ce plat est parfait avec des légumes grillés, une salade fraîche, ou de l'ugali (polenta africaine).",
            "shortStory": "Le **Poulet Nyama Choma**, littéralement \"viande grillée\" en swahili, est un classique est-africain qui célèbre la simplicité et les saveurs naturelles.",
            "detailURL": "/fiche-recette/156/citron-herbes/poulet-nyama-choma.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Ingrédients simples et préparation rapide."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Saveurs équilibrées et douces."
            },
            "calories": "320 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Facile à préparer, parfait pour les grillades estivales."
            },
            "equipment_needed": [
                "grill",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 131,
            "categorie": "afrique",
            "country": "Bénin",
            "flag": "BJ",
            "title": "Poulet Yassa",
            "subTitle": "Poulet mariné au citron, moutarde et oignons.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 blancs de poulet",
                "3 oignons émincés",
                "Le jus de 2 citrons",
                "2 cuillères à soupe de moutarde",
                "2 gousses d'ail hachées",
                "1 cuillère à soupe de vinaigre",
                "1 cuillère à soupe de sucre",
                "Sel et poivre au goût",
                "3 cuillères à soupe d'huile"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "blancs-de-poulet",
                "oignons",
                "citron",
                "moutarde",
                "ail",
                "vinaigre",
                "sucre",
                "sel",
                "poivre",
                "huile"
            ],
            "preparation": [
                "Mélanger le jus de citron, la moutarde, l'ail haché, le vinaigre et le sucre pour former la marinade.",
                "Faire mariner le poulet dans ce mélange pendant au moins 1 heure."
            ],
            "cuisson": [
                "Faire chauffer l'huile dans une grande poêle et faire revenir les oignons émincés jusqu'à ce qu'ils soient translucides.",
                "Ajouter le poulet mariné et cuire jusqu'à ce qu'il soit doré et bien cuit, en arrosant avec la marinade.",
                "Laisser mijoter jusqu'à ce que la sauce réduise et devienne sirupeuse."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec du riz basmati ou du couscous.",
            "shortStory": "Le **Poulet Yassa** est un plat populaire de l'**Afrique de l'Ouest**, particulièrement au Bénin, où le poulet est mariné dans une **sauce au citron**, **moutarde** et **oignons** pour un goût savoureux et parfumé.",
            "detailURL": "/fiche-recette/131/citron-moutarde-oignons/benin-poulet-yassa.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Recette facile et économique, avec des ingrédients de base souvent disponibles."
            },
            "images": [],
            "hot": 0,
            "hot_description": "Légèrement épicé grâce à l'ail et la moutarde, mais globalement doux.",
            "calories": "400 kcal",
            "allergens": [
                "moutarde"
            ],
            "difficulty": {
                "level": 2,
                "description": "Facile à préparer avec des étapes simples."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "cuillère en bois"
            ]
        },
        {
            "id": 3904,
            "categorie": "afrique",
            "country": "Bénin",
            "flag": "BJ",
            "title": "Poulet à la Sauce Gombo",
            "subTitle": "Poulet mariné avec ail, gingembre, et citron, servi avec une sauce gombo.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 blancs de poulet",
                "3 gousses d'ail hachées",
                "1 morceau de gingembre frais râpé",
                "Le jus de 2 citrons",
                "400 g de gombo frais",
                "1 oignon haché",
                "2 tomates pelées et concassées",
                "1 cuillère à soupe d'huile",
                "Sel et poivre au goût"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "blancs-de-poulet",
                "ail",
                "gingembre",
                "citron",
                "gombo",
                "oignon",
                "tomates",
                "huile",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail, le gingembre et le jus de citron pour mariner le poulet pendant 1 heure.",
                "Dans une grande poêle, faire revenir l'oignon haché et les tomates concassées dans l'huile chaude jusqu'à ce qu'ils soient tendres.",
                "Ajouter le poulet mariné et cuire jusqu'à ce qu'il soit doré."
            ],
            "cuisson": [
                "Ajouter le gombo et cuire à feu doux pendant 30 à 40 minutes jusqu'à ce que la sauce soit bien réduite et le poulet bien cuit.",
                "Assaisonner avec du sel et du poivre selon le goût."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec du riz blanc ou des ignames bouillies.",
            "shortStory": "Le Poulet à la Sauce Gombo est une spécialité du Bénin, où le poulet tendre se marie à une sauce gombo savoureuse, parfumée à l'ail, au gingembre et au citron.",
            "detailURL": "/fiche-recette/3904/ail-gingembre-citron-gombo/poulet-sauce-gombo.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Recette très économique et facile à réaliser avec des ingrédients locaux."
            },
            "images": [],
            "hot": 0,
            "hot_description": "Plutôt doux, avec une note subtilement épicée provenant du gingembre.",
            "calories": "350 kcal",
            "allergens": [
                "aucun"
            ],
            "difficulty": {
                "level": 2,
                "description": "Assez facile, avec une cuisson simple à suivre."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "cuillère en bois"
            ]
        },
        {
            "id": 145,
            "categorie": "asiatique",
            "country": "Brunei",
            "flag": "BN",
            "title": "Ayam Kurma",
            "subTitle": "Poulet mariné dans une sauce au curcuma, gingembre et lait de coco.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "1 cuillère à soupe de curcuma",
                "2 cm de gingembre frais",
                "1 tasse de lait de coco",
                "2 gousses d'ail",
                "1 cuillère à soupe d'huile d'olive",
                "1 citron (utiliser le jus uniquement)",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "curcuma",
                "gingembre",
                "lait-de-coco",
                "ail",
                "huile-d-olive",
                "citron",
                "sel-et-poivre"
            ],
            "preparation": [
                "Mélanger le curcuma, le gingembre râpé, l'ail écrasé, le lait de coco et le jus de citron pour mariner les cuisses de poulet pendant 1 heure. Retourner les cuisses à mi-parcours pour une marinade plus uniforme."
            ],
            "cuisson": [
                "Faire revenir les cuisses de poulet marinées dans une poêle avec un peu d'huile jusqu'à ce qu'elles soient dorées des deux côtés.",
                "Ajouter environ 100 ml d'eau et cuire à feu doux pendant 30 minutes, en ajoutant du sel et du poivre au goût."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec du riz basmati ou des légumes vapeur. Vous pouvez également essayer des légumes sautés au wok pour une touche authentique d'Asie du Sud-Est.",
            "shortStory": "**Ayam Kurma** est un plat savoureux où les épices comme le **curcuma** et le **gingembre** s'harmonisent parfaitement avec le lait de coco, créant une marinade riche et parfumée pour le **poulet**.",
            "detailURL": "/fiche-recette/145/curcuma-gingembre-lait-de-coco/ayam-kurma.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Recette relativement simple mais avec une cuisson lente."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Légèrement épicé avec du gingembre et du curcuma."
            },
            "difficulty": {
                "level": 2,
                "description": "Niveau intermédiaire : nécessite une marinade, une cuisson à la poêle et une surveillance pour une cuisson lente."
            },
            "calories": "400 kcal",
            "allergens": [],
            "equipment_needed": []
        },
        {
            "id": 146,
            "categorie": "asiatique",
            "country": "Brunei",
            "flag": "BN",
            "title": "Poulet au Satay",
            "subTitle": "Poulet mariné avec curcuma, cumin, citronnelle et sauce aux cacahuètes.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 blancs de poulet (ou morceaux coupés pour un satay)",
                "2 cuillères à soupe de curcuma",
                "1 cuillère à soupe de cumin",
                "2 tiges de citronnelle finement hachées",
                "2 cuillères à soupe de sauce soja",
                "1/4 tasse de cacahuètes grillées non salées",
                "1 cuillère à soupe de sucre brun",
                "1 cuillère à soupe d'huile d'olive",
                "1 cuillère à soupe de jus de citron"
            ],
            "ingredient_liste": [
                "curcuma",
                "cumin",
                "citronnelle",
                "sauce-soja",
                "cacahuètes",
                "sucre-brun",
                "huile-d-olive",
                "jus-de-citron"
            ],
            "preparation": [
                "Mixer le curcuma, le cumin, la citronnelle (hachée), la sauce soja, les cacahuètes (non salées), le sucre brun, l'huile d'olive et le jus de citron jusqu'à l'obtention d'une pâte lisse.",
                "Couper les blancs de poulet en morceaux pour une cuisson uniforme. Les mariner dans ce mélange pendant 1 heure."
            ],
            "cuisson": [
                "Faire chauffer une poêle avec un peu d'huile.",
                "Griller les morceaux de poulet mariné à feu moyen, en les retournant régulièrement pour qu'ils soient dorés et cuits à cœur.",
                "Option : cuire sur un barbecue pour une saveur fumée."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": true,
                "friture": false,
                "four": false,
                "poele": true,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle",
                "barbecue"
            ],
            "accompagnement": "Servir avec du riz au jasmin ou une salade de légumes frais. Pour un goût authentique, accompagnez avec une sauce satay aux cacahuètes.",
            "shortStory": "**Poulet au Satay** est un plat aromatique et savoureux où les épices comme le **curcuma** et le **cumin** se combinent parfaitement avec la douceur des cacahuètes pour enrober le **poulet** d'une marinade exotique.",
            "detailURL": "/fiche-recette/146/curcuma-cumin-citronnelle-sauce-aux-cacahuetes/poulet-au-satay.html",
            "time_prepare": "20mn",
            "time_maceration": "1h",
            "time_cooking": "15mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat simple mais avec une combinaison de saveurs riches."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Légèrement épicé avec du cumin et de la citronnelle."
            },
            "difficulty": {
                "level": 2,
                "description": "Niveau intermédiaire : nécessite de mixer les ingrédients pour la marinade et une cuisson précise à la poêle."
            },
            "calories": "350 Kcal",
            "allergens": [],
            "equipment_needed": []
        },
        {
            "id": 137,
            "categorie": "amerique-sud",
            "country": "Bolivie",
            "flag": "BO",
            "title": "Poulet a la Broaster",
            "subTitle": "Poulet mariné avec cumin, ail, citron et paprika, frit croustillant.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "1 cuillère à café de cumin",
                "2 gousses d'ail hachées",
                "Le jus de 1 citron",
                "1 cuillère à soupe de paprika",
                "Huile pour la friture",
                "Sel et poivre au goût"
            ],
            "ingredient_liste": [
                "cumin",
                "ail",
                "citron",
                "paprika",
                "huile",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger le cumin, l'ail, le citron, le paprika, le sel et le poivre pour mariner les cuisses de poulet pendant 1 heure."
            ],
            "cuisson": [
                "Chauffer l'huile dans une friteuse ou une poêle.",
                "Faire frire les cuisses de poulet jusqu'à ce qu'elles soient dorées et croustillantes.",
                "Egoutter sur du papier absorbant."
            ],
            "cooking_methods": {
                "best": "friture",
                "barbecue": false,
                "friture": true,
                "four": false,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir avec des frites ou une salade de légumes frais.",
            "shortStory": "Le poulet a la Broaster est une recette traditionnelle bolivienne, offrant un poulet **frit croustillant** et parfumé aux épices.",
            "detailURL": "/fiche-recette/137/cumin-ail-citron-paprika/pollo-a-la-broaster.html",
            "time_prepare": "10mn",
            "time_maceration": "1h",
            "time_cooking": "20mn",
            "crispys": true,
            "cost": {
                "level": 1,
                "description": "Facile à préparer avec des ingrédients accessibles."
            },
            "images": [],
            "hot": 2,
            "hot_description": "Modérément épicé grâce au paprika et au cumin.",
            "calories": "500 kcal",
            "allergens": [
                "huile"
            ],
            "difficulty": {
                "level": 2,
                "description": "Facile mais nécessite un peu d'attention lors de la friture."
            },
            "equipment_needed": [
                "friteuse",
                "couteau",
                "bol pour marinade"
            ]
        },
        {
            "id": 138,
            "categorie": "amerique-sud",
            "country": "Bolivie",
            "flag": "BO",
            "title": "Pollo Picante",
            "subTitle": "Poulet mariné dans une sauce au piment rouge et ail, mijoté.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 cuillères à soupe de pâte de piment rouge",
                "4 gousses d'ail hachées",
                "1 cuillère à soupe de vinaigre",
                "1 oignon émincé",
                "Sel et poivre au goût",
                "500 ml de bouillon de volaille"
            ],
            "ingredient_liste": [
                "piment-rouge",
                "ail",
                "vinaigre",
                "oignon",
                "sel",
                "poivre",
                "bouillon-de-volaille"
            ],
            "preparation": [
                "Mélanger la pâte de piment, l'ail, le vinaigre et le sel pour mariner le poulet pendant 1 heure."
            ],
            "cuisson": [
                "Faire revenir l'oignon dans une poêle avec un peu d'huile jusqu'à ce qu'il soit doré.",
                "Ajouter le poulet mariné et cuire jusqu'à ce qu'il soit doré de tous les côtés.",
                "Incorporer le bouillon de volaille et laisser mijoter pendant 40 minutes."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz ou des légumes sautés.",
            "shortStory": "Le Pollo Picante est **une recette bolivienne** aux saveurs piquantes et profondes, offrant un **poulet mijoté** dans une sauce riche et parfumée.",
            "detailURL": "/fiche-recette/138/piment-rouge-ail-vinaigre/pollo-picante.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Simple à préparer avec des ingrédients de base."
            },
            "images": [],
            "hot": 3,
            "hot_description": "Assez épicé grâce à la pâte de piment rouge.",
            "calories": "420 kcal",
            "allergens": [
                "vinaigre"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette facile mais nécessite un mijotage lent."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "cuillère en bois"
            ]
        },
        {
            "id": 12,
            "categorie": "amerique-sud",
            "country": "Brésil",
            "flag": "BR",
            "title": "Poulet à la Brésilienne",
            "subTitle": "Marinade exotique aux saveurs tropicales",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "1/4 tasse de jus de citron vert",
                "1/4 tasse de sauce soja",
                "2 cuillères à soupe d'huile d'olive",
                "2 gousses d'ail émincées",
                "2 cuillères à café de paprika",
                "1 cuillère à café de cumin moulu",
                "1 cuillère à café de coriandre moulue",
                "1 cuillère à café de piment de Cayenne (facultatif pour un goût plus épicé)",
                "Sel et poivre, au goût"
            ],
            "ingredient_liste": [
                "cuisse-de-poulet",
                "jus-de-citron-vert",
                "sauce-soja",
                "huile-d-olive",
                "ail-eminces",
                "paprika",
                "cumin-moulu",
                "coriandre-moulue",
                "piment-de-cayenne",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un grand bol, mélanger le jus de citron vert, la sauce soja, l'huile d'olive, l'ail émincé, le paprika, le cumin moulu, la coriandre moulue et le piment de Cayenne (si utilisé).",
                "Ajouter les cuisses de poulet dans le bol et bien les enrober de marinade.",
                "Couvrir le bol et laisser mariner au réfrigérateur pendant au moins 2 heures, de préférence toute une nuit."
            ],
            "cuisson": [
                "Préchauffer le grill à feu moyen-élevé.",
                "Retirer les cuisses de poulet de la marinade et jeter l'excès de marinade.",
                "Griller les cuisses de poulet sur le barbecue pendant environ 20 à 25 minutes, en les retournant de temps en temps, jusqu'à ce qu'elles soient bien cuites et dorées.",
                "Servir chaud avec des quartiers de citron vert et des tranches d'ananas grillées pour une touche tropicale supplémentaire."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "plancha": false,
                "four": false,
                "grill": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Le poulet à la Brésilienne est délicieux accompagné de riz blanc, de haricots noirs et d'une salade fraîche de tomates et de concombres.",
            "shortStory": "Le **poulet à la Brésilienne** est une explosion de saveurs tropicales, parfaite pour les grillades estivales ou les fêtes en plein air. La marinade exotique à base de **jus de citron vert**, de sauce soja et d'épices brésiliennes traditionnelles donne au poulet une saveur incroyablement savoureuse et un peu piquante. Un plat incontournable pour une expérience culinaire authentique du Brésil !",
            "detailURL": "/fiche-recette/12/jus-citron-vert-sauce-soja-poulet-bresilienne-cuisse/poulet-bresilienne.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "25mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est relativement moyen (niveau 2), car elle utilise des ingrédients comme des épices, de la sauce soja et du citron vert."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Ce plat est légèrement épicé grâce au piment de Cayenne, mais pas trop fort. Vous pouvez ajuster l'intensité de la chaleur selon vos préférences."
            },
            "calories": "320 Kcal",
            "allergens": [
                "soja"
            ],
            "difficulty": {
                "level": 2,
                "description": "Cette recette est de difficulté moyenne (niveau 2), car elle nécessite une marinade de longue durée, mais la cuisson est assez simple."
            },
            "equipment_needed": [
                "bol",
                "grill",
                "pince"
            ]
        },
        {
            "id": 143,
            "categorie": "amerique-sud",
            "country": "Brésil",
            "flag": "BR",
            "title": "Frango à Passarinho",
            "subTitle": "Petites pièces de poulet marinées avec ail, citron et persil, frites.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de petites pièces de poulet (ailes, cuisses ou morceaux désossés)",
                "4 gousses d'ail écrasées",
                "1 citron (jus uniquement)",
                "1 bouquet de persil haché",
                "1 tasse de farine de blé",
                "1 litre d'huile pour friture",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "petites-pieces-de-poulet",
                "ail",
                "citron",
                "persil",
                "farine",
                "huile",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Sécher les morceaux de poulet avec du papier absorbant.",
                "Mélanger l'ail écrasé, le jus de citron, le persil haché, le sel et le poivre pour mariner les morceaux de poulet pendant 30 minutes."
            ],
            "cuisson": [
                "Chauffer l'huile dans une friteuse ou une casserole profonde à environ 180°C.",
                "Enrober les morceaux de poulet marinés de farine en les secouant légèrement pour retirer l'excès.",
                "Plonger les morceaux dans l'huile chaude et les frire jusqu'à ce qu'ils soient dorés et croustillants.",
                "Égoutter sur du papier absorbant avant de servir."
            ],
            "cooking_methods": {
                "best": "friture",
                "barbecue": false,
                "friture": true,
                "four": false,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir avec du riz ou une salade fraîche. Ajoutez une sauce piquante en accompagnement pour une touche supplémentaire.",
            "shortStory": "**Frango à Passarinho** est un plat brésilien classique où de petites pièces de poulet sont marinées avec de l'ail, du citron et du persil avant d'être frites pour un résultat croustillant et savoureux.",
            "detailURL": "/fiche-recette/143/ail-citron-persil/frango-a-passarinho.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "15mn",
            "crispys": true,
            "cost": {
                "level": 1,
                "description": "Plat simple et rapide à réaliser."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Légèrement épicé avec de l'ail."
            },
            "calories": "250 kcal",
            "allergens": [
                "gluten",
                "ail"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite une friture avec attention à la température de l'huile."
            },
            "equipment_needed": [
                "friteuse ou casserole profonde",
                "couteau",
                "planche à découper",
                "papier absorbant"
            ]
        },
        {
            "id": 144,
            "categorie": "amerique-sud",
            "country": "Brésil",
            "flag": "BR",
            "title": "Galinhada",
            "subTitle": "Poulet mariné avec curcuma, ail, oignon et tomates, cuit avec du riz.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "1 oignon",
                "4 gousses d'ail",
                "2 tomates",
                "1 cuillère à café de curcuma",
                "2 tasses de riz",
                "1 tasse de bouillon de volaille",
                "1 cuillère à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "oignon",
                "ail",
                "tomates",
                "curcuma",
                "riz",
                "bouillon-de-volaille",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Faire revenir l'oignon haché et l'ail dans l'huile d'olive jusqu'à ce qu'ils soient dorés.",
                "Ajouter les tomates coupées en dés, le curcuma, le sel et le poivre, puis mélanger.",
                "Mariner les cuisses de poulet dans ce mélange pendant 30 minutes."
            ],
            "cuisson": [
                "Ajouter les cuisses de poulet marinées dans une grande poêle avec le riz, verser le bouillon de volaille et cuire à feu doux pendant 40 minutes, jusqu'à ce que le riz soit cuit et le poulet tendre."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec une salade verte ou des légumes grillés.",
            "shortStory": "**Galinhada** est un plat brésilien traditionnel où le poulet est **mariné avec des épices** comme le **curcuma** et l'**ail**, puis **mijoté** avec du riz et des **tomates** pour créer un plat réconfortant et plein de saveurs.",
            "detailURL": "/fiche-recette/144/curcuma-ail-oignon-tomates/galinhada.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat savoureux avec une cuisson complète."
            },
            "images": [],
            "hot": 1,
            "hot_description": "Légèrement épicé grâce au curcuma et à l'ail.",
            "calories": "350 kcal",
            "allergens": [
                "gluten",
                "ail"
            ],
            "difficulty": {
                "level": 2,
                "description": "Modéré, nécessite un peu de temps de cuisson."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 523,
            "categorie": "caraibes",
            "country": "Bahamas",
            "flag": "BS",
            "title": "Poulet à la Goombay",
            "subTitle": "Poulet mariné avec citron vert, ail, piment Scotch Bonnet et herbes caribéennes, grillé ou rôti",
            "chicken_piece": [
                "entier",
                "cuisse",
                "blanc",
                "pilon"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier ou morceaux",
                "1 citron vert",
                "3 gousses d'ail",
                "1 piment Scotch Bonnet",
                "2 cuillères à soupe d'herbes caribéennes (thym, persil)",
                "1 cuillère à soupe de sel",
                "1 cuillère à soupe de poivre",
                "2 cuillères à soupe d'huile d'olive"
            ],
            "ingredient_liste": [
                "poulet",
                "citron-vert",
                "ail",
                "piment-scotch-bonnet",
                "herbes-caribéennes",
                "sel",
                "poivre",
                "huile-d-olive"
            ],
            "preparation": [
                "Presser le citron vert et mélanger avec l'ail haché, le piment Scotch Bonnet émincé, les herbes caribéennes, le sel et le poivre.",
                "Badigeonner le poulet avec cette marinade et laisser mariner au réfrigérateur pendant 2 heures.",
                "Préchauffer le grill ou le four."
            ],
            "cuisson": [
                "Griller ou rôtir le poulet à 180°C pendant 45 minutes à 1 heure, en fonction de la taille du poulet, jusqu'à ce qu'il soit doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "grill",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "grill",
                "four"
            ],
            "accompagnement": "Servir avec du riz, des légumes ou une salade fraîche.",
            "shortStory": "Le Poulet à la Goombay est une recette classique des Bahamas, mariné avec des **ingrédients caribéens** intenses, offrant un plat à la fois épicé et savoureux. Ce poulet grillé ou rôti se marie parfaitement avec des accompagnements frais et légers.",
            "detailURL": "/fiche-recette/523/citron-vert-ail-piment/poulet-goombay.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est moyen grâce aux ingrédients frais et épicés, mais reste accessible."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Cette recette présente des saveurs épicées et exotiques, idéales pour les amateurs de cuisine relevée."
            },
            "calories": "350 kcal",
            "allergens": [
                "piment"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette facile à préparer mais nécessite un bon contrôle de la marinade et de la cuisson pour obtenir un poulet bien cuit et savoureux."
            },
            "equipment_needed": [
                "grill",
                "four",
                "bol"
            ]
        },
        {
            "id": 5806,
            "categorie": "caraibes",
            "country": "Bahamas",
            "flag": "BS",
            "title": "Poulet Mijoté",
            "subTitle": "Poulet mariné avec épices locales, tomate, oignon et ail, mijoté",
            "chicken_piece": [
                "blanc",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 morceaux de poulet (blancs, cuisses ou pilons)",
                "2 tomates",
                "1 oignon",
                "3 gousses d'ail",
                "1 cuillère à soupe de cumin",
                "1 cuillère à soupe de paprika",
                "1 cuillère à soupe de curry",
                "Sel et poivre",
                "2 cuillères à soupe d'huile d'olive",
                "1/2 tasse d'eau"
            ],
            "ingredient_liste": [
                "poulet",
                "tomates",
                "oignon",
                "ail",
                "cumin",
                "paprika",
                "curry",
                "sel",
                "poivre",
                "huile-d-olive",
                "eau"
            ],
            "preparation": [
                "Faire revenir l'oignon et l'ail hachés dans l'huile d'olive jusqu'à ce qu'ils soient tendres.",
                "Ajouter les épices (cumin, paprika, curry) et cuire encore 2 minutes pour libérer les arômes.",
                "Ajouter les tomates coupées en dés et cuire jusqu'à ce qu'elles se transforment en sauce."
            ],
            "cuisson": [
                "Ajouter les morceaux de poulet, saler et poivrer, puis ajouter l'eau.",
                "Laisser mijoter à feu doux pendant 40 minutes à 1 heure, jusqu'à ce que le poulet soit tendre et la sauce épaissie."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc ou du pain de maïs pour absorber la sauce riche.",
            "shortStory": "Le Poulet Mijoté est un plat réconfortant et savoureux des Bahamas, où le poulet est mariné dans un mélange d'épices locales, puis mijoté avec des légumes et des herbes pour un résultat tendre et délicieux.",
            "detailURL": "/fiche-recette/5806/cumin-paprika-curry/poulet-mijote.html",
            "time_prepare": "10mn",
            "time_maceration": "0mn",
            "time_cooking": "1h",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût est modéré, principalement pour les épices et légumes frais utilisés."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Ce plat mijoté est légèrement épicé, avec une sauce riche et savoureuse, idéale pour les repas en famille."
            },
            "calories": "400 kcal",
            "allergens": [
                "gluten (éventuellement dans les épices)",
                "curry"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette simple à réaliser, nécessitant un peu de patience pour le mijotage afin d'obtenir un poulet tendre."
            },
            "equipment_needed": [
                "cocotte",
                "poêle",
                "bol"
            ]
        },
        {
            "id": 132,
            "categorie": "asiatique",
            "country": "Bhoutan",
            "flag": "BT",
            "title": "Poulet au Chili Ema Datshi",
            "subTitle": "Poulet mariné avec ail, gingembre et piment, cuit dans une sauce fromagère.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 blancs de poulet",
                "4 gousses d'ail hachées",
                "1 morceau de gingembre frais râpé",
                "2 piments rouges frais",
                "200 g de fromage blanc ou fromage frais",
                "1 oignon haché",
                "1 cuillère à soupe de sauce soja",
                "Sel et poivre au goût"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "blancs-de-poulet",
                "ail",
                "gingembre",
                "piment",
                "fromage-blanc",
                "oignon",
                "sauce-soja",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail, le gingembre et le piment avec la sauce soja pour mariner le poulet pendant 1 heure.",
                "Faire revenir l'oignon haché dans un peu d'huile jusqu'à ce qu'il soit translucide."
            ],
            "cuisson": [
                "Ajouter le poulet mariné et cuire jusqu'à ce qu'il soit bien doré.",
                "Ajouter le fromage blanc et laisser mijoter jusqu'à ce que la sauce soit bien crémeuse.",
                "Assaisonner avec du sel et du poivre au goût."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec du riz basmati ou des légumes sautés.",
            "shortStory": "Le Poulet au Chili Ema Datshi est un plat traditionnel bhoutanais où le poulet est mariné dans une **sauce épicée au gingembre et au piment**, puis cuit avec une **sauce fromagère crémeuse**.",
            "detailURL": "/fiche-recette/132/ail-gingembre-piment/fromage-blanc/poulet-ema-datshi.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Recette relativement économique, nécessitant quelques ingrédients spécifiques."
            },
            "images": [],
            "hot": 2,
            "hot_description": "Assez épicé grâce au piment rouge.",
            "calories": "400 kcal",
            "allergens": [
                "fromage"
            ],
            "difficulty": {
                "level": 2,
                "description": "Facile à préparer mais nécessite de bien maîtriser les épices."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "cuillère en bois"
            ]
        },
        {
            "id": 141,
            "categorie": "afrique",
            "country": "Botswana",
            "flag": "BW",
            "title": "Seswaa Chicken",
            "subTitle": "Poulet mariné avec ail, sel et poivre, mijoté lentement.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "4 gousses d'ail",
                "1 cuillère à café de sel",
                "1 cuillère à café de poivre",
                "1 tasse de bouillon de volaille"
            ],
            "ingredient_liste": [
                "ail",
                "sel",
                "poivre",
                "bouillon-de-volaille"
            ],
            "preparation": [
                "Mélanger l'ail haché, le sel et le poivre pour mariner les cuisses de poulet pendant 1 heure."
            ],
            "cuisson": [
                "Mijoter les cuisses de poulet avec le bouillon de volaille à feu doux pendant 1h30."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz ou des légumes de saison.",
            "shortStory": "**Seswaa Chicken** est un plat traditionnel botswanais où le poulet est **mariné avec de l'ail**, du sel et du poivre, puis mijoté lentement dans un bouillon savoureux.",
            "detailURL": "/fiche-recette/141/ail-sel-poivre/seswaa-chicken.html",
            "time_prepare": "10mn",
            "time_maceration": "1h",
            "time_cooking": "1h30",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat simple et savoureux, nécessitant du temps de cuisson."
            },
            "images": [],
            "hot": 1,
            "hot_description": "Légèrement épicé avec l'ail et le poivre.",
            "calories": "300 kcal",
            "allergens": [
                "ail"
            ],
            "difficulty": {
                "level": 2,
                "description": "Modéré, avec une cuisson lente."
            },
            "equipment_needed": [
                "cocotte"
            ]
        },
        {
            "id": 142,
            "categorie": "afrique",
            "country": "Botswana",
            "flag": "BW",
            "title": "Poulet Grillé au Peri-Peri (variante Botswana)",
            "subTitle": "Poulet mariné au piment, citron et ail, grillé.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 piments rouges",
                "1 citron",
                "4 gousses d'ail",
                "1 cuillère à soupe d'huile d'olive"
            ],
            "ingredient_liste": [
                "piments-rouges",
                "citron",
                "ail",
                "huile-d-olive"
            ],
            "preparation": [
                "Mixer les piments, le citron, l'ail et l'huile d'olive pour préparer la marinade.",
                "Faire mariner les cuisses de poulet dans cette préparation pendant 2 heures."
            ],
            "cuisson": [
                "Griller le poulet sur le barbecue ou dans une poêle pendant environ 20-25 minutes."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "barbecue": true,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Servir avec des frites ou une salade fraîche.",
            "shortStory": "**Poulet Grillé au Peri-Peri (variante Botswana)** est une version locale du classique peri-peri où le poulet est mariné avec un **mélange de piment, de citron et d'ail, puis grillé à la perfection**.",
            "detailURL": "/fiche-recette/142/piment-citron-ail/poulet-grille-au-peri-peri-botswana.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "25mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Plat légèrement épicé avec une préparation simple."
            },
            "images": [],
            "hot": 2,
            "hot_description": "Épicé grâce au piment.",
            "calories": "350 kcal",
            "allergens": [
                "ail"
            ],
            "difficulty": {
                "level": 2,
                "description": "Modéré, nécessite un barbecue ou une poêle."
            },
            "equipment_needed": [
                "grill",
                "mixeur",
                "cuillère en bois"
            ]
        },
        {
            "id": 133,
            "categorie": "europe",
            "country": "Biélorussie",
            "flag": "BY",
            "title": "Poulet à la Crème Aigre",
            "subTitle": "Poulet mariné dans de la crème aigre, ail et aneth, cuit au four.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 blancs de poulet",
                "200 g de crème aigre",
                "3 gousses d'ail hachées",
                "1 bouquet d'aneth frais",
                "1 cuillère à soupe de moutarde",
                "1 oignon haché",
                "Sel et poivre au goût",
                "1 cuillère à soupe d'huile"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "blancs-de-poulet",
                "creme-aigre",
                "ail",
                "aneth",
                "moutarde",
                "oignon",
                "sel",
                "poivre",
                "huile"
            ],
            "preparation": [
                "Mélanger la crème aigre, l'ail, la moutarde et l'aneth pour mariner le poulet pendant 1 heure.",
                "Faire revenir l'oignon haché dans l'huile chaude jusqu'à ce qu'il soit tendre."
            ],
            "cuisson": [
                "Disposer le poulet mariné dans un plat allant au four et cuire à 180°C pendant 35 à 40 minutes.",
                "Assaisonner avec du sel et du poivre, et ajouter un peu d'aneth frais avant de servir."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": false,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec des pommes de terre bouillies ou du riz pilaf.",
            "shortStory": "Le Poulet à la Crème Aigre est un plat traditionnel de Biélorussie, où le poulet est **mariné dans une sauce à base de crème aigre**, **d'ail et d'aneth**, puis cuit au four pour une texture fondante et savoureuse.",
            "detailURL": "/fiche-recette/133/creme-aigre-ail-aneth/poulet-a-la-creme-aigre.html",
            "time_prepare": "10mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Recette abordable avec des ingrédients courants."
            },
            "images": [],
            "hot": 0,
            "hot_description": "Pas épicé, mais légèrement relevé avec l'ail et la moutarde.",
            "calories": "350 kcal",
            "allergens": [
                "lactose",
                "moutarde"
            ],
            "difficulty": {
                "level": 2,
                "description": "Facile, nécessite une cuisson au four."
            },
            "equipment_needed": [
                "four",
                "couteau",
                "plat de cuisson"
            ]
        },
        {
            "id": 134,
            "categorie": "europe",
            "country": "Biélorussie",
            "flag": "BY",
            "title": "Poulet Kletski",
            "subTitle": "Poulet mariné avec oignon et herbes, accompagné de boulettes.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 blancs de poulet",
                "1 oignon haché",
                "1 bouquet d'herbes fraîches (thym, laurier, romarin)",
                "300 g de farine",
                "1 œuf",
                "100 g de beurre",
                "Sel et poivre au goût"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "blancs-de-poulet",
                "oignon",
                "herbes",
                "farine",
                "oeuf",
                "beurre",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Faire mariner le poulet avec l'oignon haché et les herbes pendant 1 heure.",
                "Préparer la pâte à boulettes en mélangeant la farine, l'œuf, le beurre, du sel et du poivre."
            ],
            "cuisson": [
                "Faire cuire le poulet mariné dans une poêle avec un peu de beurre.",
                "Former des boulettes avec la pâte préparée et les faire cuire dans de l'eau bouillante salée.",
                "Servir le poulet avec les boulettes de farine."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec des pommes de terre ou du chou sauté.",
            "shortStory": "Le **Poulet Kletski** est un **plat traditionnel biélorusse** où le poulet mariné est accompagné de délicieuses boulettes de farine, offrant une combinaison de saveurs simples mais savoureuses.",
            "detailURL": "/fiche-recette/134/oignon-herbes-boulettes/poulet-kletski.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat simple et économique."
            },
            "images": [],
            "hot": 0,
            "hot_description": "Pas épicé, une saveur douce et herbacée.",
            "calories": "400 kcal",
            "allergens": [
                "gluten",
                "lactose"
            ],
            "difficulty": {
                "level": 2,
                "description": "Facile, mais nécessite un peu de préparation pour les boulettes."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "cuillère en bois",
                "marmite"
            ]
        },
        {
            "id": 2794,
            "categorie": "amerique-centrale",
            "country": "Belize",
            "flag": "BZ",
            "title": "Poulet au Roucou",
            "subTitle": "Poulet mariné avec graines de roucou (achiote), ail, citron vert et épices.",
            "chicken_piece": [
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "cuisse": false,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier",
                "2 cuillères à soupe de graines de roucou (achiote)",
                "2 gousses d'ail hachées",
                "Le jus de 1 citron vert",
                "1 cuillère à café de cumin",
                "1 cuillère à soupe de thym",
                "Sel et poivre au goût"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "graines-de-roucou",
                "ail",
                "citron-vert",
                "cumin",
                "thym",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Écraser les graines de roucou avec de l'ail pour former une pâte.",
                "Ajouter le jus de citron vert, le cumin, le thym, le sel et le poivre pour assaisonner la pâte.",
                "Badigeonner le poulet entier avec cette marinade et laisser reposer au moins 1 heure."
            ],
            "cuisson": [
                "Cuire le poulet sur un barbecue ou dans un four à 180°C pendant 1h30, en l'arrosant avec la marinade pendant la cuisson."
            ],
            "cooking_methods": {
                "best": "grill",
                "barbecue": true,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "grill",
                "four"
            ],
            "accompagnement": "Accompagner de riz, légumes grillés ou de plantains frits.",
            "shortStory": "Le **Poulet au Roucou** est un plat typique du Belize, mettant en valeur les **graines de roucou**, qui donnent au poulet une belle couleur dorée et une saveur délicatement épicée. Un plat parfait pour une **expérience culinaire caribéenne**.",
            "detailURL": "/fiche-recette/2794/roucou-ail-cumin-thym/poulet-roucou.html",
            "time_prepare": "20mn",
            "time_maceration": "1h",
            "time_cooking": "1h30",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Recette abordable grâce aux ingrédients simples mais exotiques comme le roucou et les épices."
            },
            "images": [],
            "hot": 0,
            "hot_description": "Plutôt doux, avec une légère note épicée grâce au roucou.",
            "calories": "350 kcal",
            "allergens": [
                "aucun"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette simple, mais qui demande un peu de temps pour la marinade et la cuisson."
            },
            "equipment_needed": [
                "mixeur",
                "grill",
                "four",
                "plat à rôtir"
            ]
        },
        {
            "id": 9754,
            "categorie": "amerique-centrale",
            "country": "Belize",
            "flag": "BZ",
            "title": "Stewed Chicken",
            "subTitle": "Poulet mariné avec un mélange d’épices beliziennes (ail, thym, cumin), mijoté.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 blancs de poulet",
                "3 gousses d'ail hachées",
                "1 branche de thym",
                "1 cuillère à café de cumin",
                "1 oignon haché",
                "1 poivron vert",
                "2 tomates",
                "500 ml de bouillon de volaille",
                "1 cuillère à soupe de pâte de tomate",
                "1 cuillère à soupe de sucre",
                "Sel et poivre au goût"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "blancs-de-poulet",
                "ail",
                "thym",
                "cumin",
                "oignon",
                "poivron-vert",
                "tomates",
                "bouillon-de-volaille",
                "pate-de-tomate",
                "sucre",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Faire revenir l'ail, l'oignon et le poivron dans une casserole avec un peu d'huile jusqu'à ce que l'oignon devienne translucide.",
                "Ajouter les tomates hachées, le cumin, le thym, le sucre et le sel. Laisser cuire 5 minutes.",
                "Ajouter les cuisses et blancs de poulet dans la casserole avec le bouillon et la pâte de tomate. Laisser mijoter pendant 40 minutes."
            ],
            "cuisson": [
                "Laisser mijoter à feu doux jusqu'à ce que le poulet soit tendre et bien cuit, en ajustant le sel et le poivre au goût."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc, des haricots rouges ou du pain maison.",
            "shortStory": "Le Stewed Chicken est un plat savoureux du Belize où le **poulet est mijoté lentement** dans une sauce épicée à base de tomates, **thym** et **cumin**, créant une viande **tendre** et une sauce riche et parfumée.",
            "detailURL": "/fiche-recette/9754/ail-thym-cumin-tomates/poulet-belize-stewe.html",
            "time_prepare": "15mn",
            "time_maceration": "0",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Recette très abordable, nécessitant des ingrédients simples que l'on trouve facilement dans la plupart des cuisines."
            },
            "images": [],
            "hot": 0,
            "hot_description": "Légèrement épicé, mais très doux comparé à d'autres recettes caribéennes.",
            "calories": "400 kcal",
            "allergens": [
                "aucun"
            ],
            "difficulty": {
                "level": 2,
                "description": "Facile à préparer, mais nécessite un peu de patience pour laisser mijoter et mélanger les saveurs."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "cuillère en bois"
            ]
        },
        {
            "id": 163,
            "categorie": "amerique-nord",
            "country": "Canada",
            "flag": "CA",
            "title": "Poulet à l'Érable",
            "subTitle": "Poulet mariné avec sirop d’érable, moutarde et ail, rôti.",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier ou coupé en morceaux",
                "100ml de sirop d'érable",
                "2 cuillères à soupe de moutarde de Dijon",
                "3 gousses d'ail, écrasées",
                "2 cuillères à soupe d'huile d'olive",
                "1 cuillère à soupe de vinaigre de cidre",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "sirop-d-erable",
                "moutarde",
                "ail",
                "huile-d-olive",
                "vinaigre-de-cidre",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger le sirop d'érable, la moutarde, l'ail, l'huile d'olive et le vinaigre de cidre. Saler et poivrer.",
                "Badigeonner généreusement le poulet avec la marinade. Laisser mariner au réfrigérateur pendant 1 heure."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C (390°F).",
                "Placer le poulet dans un plat allant au four et verser le reste de la marinade dessus.",
                "Rôtir pendant 45-60 minutes, en arrosant le poulet avec son jus toutes les 15 minutes, jusqu'à ce qu'il soit bien doré.",
                "Servir chaud avec des légumes rôtis ou une salade verte."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": true,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "four",
                "barbecue"
            ],
            "accompagnement": "Idéal avec des légumes rôtis, du riz ou une purée.",
            "shortStory": "Le **Poulet à l'Érable** est un classique canadien, mêlant la douceur emblématique du sirop d'érable à des saveurs relevées.",
            "detailURL": "/fiche-recette/163/erable-moutarde/poulet-a-l-erable.html",
            "time_prepare": "20mn",
            "time_maceration": "1h",
            "time_cooking": "1h",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Ingrédients simples, préparation facile."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Douceur sucrée avec des notes acidulées."
            },
            "calories": "400 kcal",
            "allergens": [
                "moutarde"
            ],
            "difficulty": {
                "level": 2,
                "description": "Préparation simple, cuisson surveillée."
            },
            "equipment_needed": [
                "four",
                "plat allant au four",
                "pinceau de cuisine"
            ]
        },
        {
            "id": 164,
            "categorie": "amerique-nord",
            "country": "Canada",
            "flag": "CA",
            "title": "Poulet BBQ Canadien",
            "subTitle": "Poulet mariné avec une sauce barbecue à base de tomate, sucre et vinaigre.",
            "chicken_piece": [
                "morceaux",
                "aile",
                "pilon"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": true,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": true
            },
            "ingredient": [
                "1 kg de morceaux de poulet",
                "200ml de ketchup",
                "2 cuillères à soupe de cassonade",
                "2 cuillères à soupe de vinaigre de cidre",
                "1 cuillère à soupe de sauce Worcestershire",
                "1 cuillère à café de paprika fumé",
                "1 cuillère à soupe de moutarde",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "ketchup",
                "cassonade",
                "vinaigre-de-cidre",
                "sauce-worcestershire",
                "paprika",
                "moutarde",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger le ketchup, la cassonade, le vinaigre de cidre, la sauce Worcestershire, le paprika fumé et la moutarde. Saler et poivrer.",
                "Enrober les morceaux de poulet avec cette sauce et laisser mariner pendant au moins 30 minutes."
            ],
            "cuisson": [
                "Préchauffer le barbecue ou le gril du four.",
                "Faire cuire les morceaux de poulet sur le barbecue ou sous le gril, en les retournant régulièrement et en les badigeonnant avec le reste de la marinade, jusqu'à ce qu'ils soient bien caramélisés et cuits à cœur.",
                "Servir chaud avec une salade de chou ou des pommes de terre au four."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "barbecue": true,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": true
            },
            "mode_cuisson": [
                "barbecue",
                "four",
                "plancha"
            ],
            "accompagnement": "Parfait avec une salade de chou ou des pommes de terre rôties.",
            "shortStory": "Le **Poulet BBQ Canadien** est une interprétation locale du barbecue classique, offrant une sauce riche en saveurs sucrées et fumées.",
            "detailURL": "/fiche-recette/164/bbq-sauce-canadienne/poulet-bbq-canadien.html",
            "time_prepare": "20mn",
            "time_maceration": "30mn",
            "time_cooking": "40mn",
            "crispys": true,
            "cost": {
                "level": 1,
                "description": "Ingrédients de base, abordable."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Saveur douce avec une légère acidité."
            },
            "calories": "430 kcal",
            "allergens": [
                "moutarde"
            ],
            "difficulty": {
                "level": 2,
                "description": "Préparation simple avec un suivi lors de la cuisson."
            },
            "equipment_needed": [
                "barbecue ou gril",
                "pinceau de cuisine"
            ]
        },
        {
            "id": 3572,
            "categorie": "amerique-nord",
            "country": "Canada",
            "flag": "CA",
            "title": "Poulet mariné sucré-salé au sirop d'érable",
            "special_marker": {
                "type": "noel",
                "icon": "pere-noel",
                "months": ["01", "12"],
                "coordinates": {
                    "latitude": 100,
                    "longitude": -0
                }
            },
            "subTitle": "Une marinade équilibrée et festive alliant douceur et salinité.",
            "chicken_piece": [
                "entier",
                "blanc",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "1 kg de morceaux de poulet (cuisses ou blancs)",
                "3 cuillères à soupe de sirop d'érable",
                "2 cuillères à soupe de sauce soja",
                "2 gousses d'ail hachées",
                "1 cuillère à soupe de vinaigre de cidre",
                "1 cuillère à café de moutarde à l'ancienne",
                "2 cuillères à soupe d'huile d'olive",
                "1 cuillère à café de thym séché",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "sirop-derable",
                "sauce-soja",
                "ail",
                "vinaigre",
                "moutarde",
                "huile",
                "thym",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le sirop d'érable, la sauce soja, l'ail, le vinaigre de cidre, la moutarde, l'huile d'olive, le thym, le sel et le poivre.",
                "Enrober les morceaux de poulet avec la marinade, couvrir et laisser mariner pendant 2 heures ou toute une nuit au réfrigérateur."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C.",
                "Disposer le poulet dans un plat allant au four et cuire pendant 40 à 50 minutes en badigeonnant régulièrement avec la marinade pour qu'il reste tendre et savoureux."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": true,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": true
            },
            "mode_cuisson": [
                "four",
                "barbecue",
                "plancha"
            ],
            "accompagnement": "Servir avec une purée de patates douces ou des légumes grillés pour compléter l'équilibre sucré-salé.",
            "shortStory": "Originaire du Canada, cette recette célèbre l'un des ingrédients les plus emblématiques du pays : le **sirop d'érable**. Associé à la **sauce soja** et aux **épices**, il crée une harmonie parfaite de saveurs, **idéale pour un dîner de Noël qui surprendra vos invités**.",
            "detailURL": "/fiche-recette/3572/sirop-erable-sucre-sale/poulet-marine-sirop-erable.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "50mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Une recette simple avec une touche de raffinement pour les occasions spéciales."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Douceur sucrée avec un léger goût salé."
            },
            "calories": "370 kcal",
            "allergens": [
                "moutarde",
                "sauce-soja"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile à réaliser, parfaite pour impressionner sans trop d'effort."
            },
            "equipment_needed": [
                "bol",
                "plat allant au four",
                "four"
            ]
        },
        {
            "id": 174,
            "categorie": "afrique",
            "country": "République du Congo",
            "flag": "CD",
            "title": "Poulet Moambe",
            "subTitle": "Poulet mariné avec ail, citron, et pâte d’arachide.",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de blanc ou cuisse de poulet",
                "4 gousses d'ail écrasées",
                "1 citron (jus uniquement)",
                "200g de pâte d'arachide",
                "1 oignon émincé",
                "1 cuillère à soupe de tomate concentrée",
                "1 litre d'eau",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "blanc-de-poulet",
                "ail",
                "citron",
                "pate-d-arachide",
                "oignon",
                "tomate-concentree",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail écrasé, le jus de citron, le sel et le poivre pour mariner le poulet pendant 30 minutes.",
                "Émincer l'oignon et préparer la pâte d'arachide en la diluant avec un peu d'eau pour obtenir une consistance crémeuse."
            ],
            "cuisson": [
                "Faire chauffer un peu d'huile dans une grande poêle.",
                "Faire dorer les morceaux de poulet sur toutes les faces.",
                "Ajouter l'oignon émincé et cuire jusqu'à ce qu'il soit tendre.",
                "Ajouter la pâte d'arachide, la tomate concentrée et l'eau.",
                "Laisser mijoter pendant 30 minutes jusqu'à ce que la sauce soit épaisse et que le poulet soit bien cuit."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz, du manioc ou des plantains frits.",
            "shortStory": "**Poulet Moambe** est un **plat traditionnel de la République Démocratique du Congo**, où le poulet est mariné avec de l'ail et du citron, puis mijoté dans une sauce à base de pâte d’arachide pour un goût savoureux et crémeux.",
            "detailURL": "/fiche-recette/174/ail-citron-arachide/poulet-moambe.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat à coût modéré, avec des ingrédients comme la pâte d'arachide et le poulet."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Non épicé, mais riche en saveurs grâce à la pâte d'arachide."
            },
            "calories": "400 kcal",
            "allergens": [
                "arachide"
            ],
            "difficulty": {
                "level": 2,
                "description": "Modéré, avec une cuisson lente pour obtenir une sauce crémeuse."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 175,
            "categorie": "afrique",
            "country": "République Démocratique du Congo",
            "flag": "CD",
            "title": "Poulet à la sauce tomate",
            "subTitle": "Poulet mariné avec ail, oignon et tomate, mijoté.",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de blanc ou cuisse de poulet",
                "4 gousses d'ail écrasées",
                "1 oignon émincé",
                "4 tomates fraîches hachées",
                "1 cuillère à soupe de concentré de tomate",
                "1 cuillère à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "blanc-de-poulet",
                "ail",
                "oignon",
                "tomates",
                "concentre-de-tomate",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail écrasé, l'oignon émincé, le sel et le poivre pour mariner le poulet pendant 30 minutes.",
                "Hacher les tomates fraîches et préparer le concentré de tomate."
            ],
            "cuisson": [
                "Faire chauffer l'huile d'olive dans une poêle à feu moyen.",
                "Faire dorer les morceaux de poulet sur toutes les faces.",
                "Ajouter les tomates fraîches, le concentré de tomate, et laisser mijoter pendant 30 minutes jusqu'à ce que la sauce épaississe."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec du riz ou du foufou pour compléter ce plat traditionnel.",
            "shortStory": "**Poulet à la sauce tomate** est un plat savoureux de la République Démocratique du Congo, où le **poulet est mijoté avec des tomates fraîches**, de l'ail et de l'oignon pour une sauce riche et parfumée.",
            "detailURL": "/fiche-recette/175/ail-oignon-tomate/poulet-a-la-sauce-tomate.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat à coût abordable, avec des ingrédients simples comme les tomates et le poulet."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Non épicé."
            },
            "calories": "350 kcal",
            "allergens": [
                "gluten"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite une cuisson à la poêle."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 176,
            "categorie": "afrique",
            "country": "République du Congo",
            "flag": "CG",
            "title": "Poulet aux feuilles de manioc",
            "subTitle": "Poulet mariné avec ail, citron et épices, accompagné de feuilles de manioc.",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de blanc ou cuisse de poulet",
                "4 gousses d'ail écrasées",
                "1 citron (jus uniquement)",
                "500g de feuilles de manioc fraîches",
                "1 oignon émincé",
                "1 cuillère à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "blanc-de-poulet",
                "ail",
                "citron",
                "feuilles-de-manioc",
                "oignon",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail écrasé, le jus de citron, le sel et le poivre pour mariner le poulet pendant 30 minutes.",
                "Hacher les feuilles de manioc et émincer l'oignon."
            ],
            "cuisson": [
                "Faire chauffer l'huile d'olive dans une grande poêle.",
                "Faire dorer les morceaux de poulet sur toutes les faces.",
                "Ajouter les feuilles de manioc, l'oignon, et laisser mijoter pendant 25 à 30 minutes jusqu'à ce que le poulet soit cuit et les feuilles tendres."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec du riz ou du manioc cuit pour compléter le repas.",
            "shortStory": "**Poulet aux feuilles de manioc** est un plat typique du Congo, où le poulet est mijoté avec des feuilles de manioc, de l'ail et du citron pour un goût riche et authentique.",
            "detailURL": "/fiche-recette/176/ail-citron-manioc/poulet-aux-feuilles-de-manioc.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat à coût abordable, avec des ingrédients locaux comme les feuilles de manioc."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Non épicé."
            },
            "calories": "350 kcal",
            "allergens": [
                "manioc"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite une cuisson à la poêle."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 177,
            "categorie": "afrique",
            "country": "République du Congo",
            "flag": "CG",
            "title": "Poulet Pimenté",
            "subTitle": "Poulet mariné avec du piment, ail et citron.",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de blanc ou cuisse de poulet",
                "4 gousses d'ail écrasées",
                "1 citron (jus uniquement)",
                "2 piments frais",
                "1 cuillère à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "blanc-de-poulet",
                "ail",
                "citron",
                "piment",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail écrasé, le jus de citron, les piments hachés, le sel et le poivre pour mariner le poulet pendant 30 minutes."
            ],
            "cuisson": [
                "Faire chauffer l'huile d'olive dans une poêle.",
                "Faire dorer les morceaux de poulet sur toutes les faces.",
                "Ajouter les piments et laisser cuire jusqu'à ce que le poulet soit bien cuit et imprégné de la marinade."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec du riz basmati ou du foufou.",
            "shortStory": "**Poulet Pimenté** est un plat épicé de la République du Congo, où le poulet est mariné avec de l'ail, du citron et des piments frais pour un goût piquant et savoureux.",
            "detailURL": "/fiche-recette/177/ail-citron-piment/poulet-pimente.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat à coût abordable, avec des ingrédients simples comme les piments et le poulet."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Plat épicé grâce aux piments frais."
            },
            "calories": "300 kcal",
            "allergens": [
                "piment"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite une cuisson à la poêle."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 56,
            "categorie": "afrique",
            "country": "Côte d'Ivoire",
            "flag": "CI",
            "title": "Poulet au Coco",
            "subTitle": "Poulet mijoté dans du lait de coco et des épices",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "400 ml de lait de coco",
                "1 oignon",
                "1 poivron rouge",
                "2 cuillères à soupe d'huile",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "lait-de-coco",
                "oignon",
                "poivron-rouge",
                "huile",
                "sel-et-poivre"
            ],
            "preparation": [
                "Faire revenir l'oignon et le poivron dans l'huile.",
                "Ajouter les blancs de poulet et faire dorer.",
                "Incorporer le lait de coco, le sel et le poivre, puis laisser mijoter pendant 30 minutes."
            ],
            "cuisson": [
                "Faire revenir l'oignon et le poivron dans l'huile.",
                "Ajouter les blancs de poulet et faire dorer.",
                "Incorporer le lait de coco, le sel et le poivre, puis laisser mijoter pendant 30 minutes."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "plancha": false,
                "friture": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz basmati.",
            "shortStory": "Le **poulet au coco** est un plat savoureux et réconfortant, apprécié pour sa sauce riche et crémeuse, mettant en valeur les saveurs tropicales de la Côte d'Ivoire.",
            "detailURL": "/fiche-recette/56/oignon-poivron-rouge-lait-de-coco/poulet-coco.html",
            "time_prepare": "15mn",
            "time_cooking": "30mn",
            "time_maceration": "0mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette a un coût modéré (niveau 2), grâce à l'utilisation d'ingrédients courants comme le poulet, le lait de coco et les légumes frais."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Plat doux, sans épices fortes."
            },
            "calories": "350 Kcal",
            "allergens": [
                "lait-de-coco"
            ],
            "difficulty": {
                "level": 1,
                "description": "Recette facile, adaptée aux débutants."
            },
            "equipment_needed": [
                "cocotte",
                "cuillère en bois"
            ]
        },
        {
            "id": 183,
            "categorie": "afrique",
            "country": "Côte d’Ivoire",
            "flag": "CI",
            "title": "Poulet Kedjenou",
            "subTitle": "Poulet mariné avec piment, tomate, gingembre et herbes, cuit à l'étouffée.",
            "chicken_piece": [
                "entier",
                "cuisse"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier découpé",
                "3 tomates mûres",
                "2 gousses d'ail écrasées",
                "1 piment entier",
                "1 oignon émincé",
                "1 cuillère à soupe de gingembre frais râpé",
                "1 bouquet d'herbes fraîches (persil, basilic)",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "tomates",
                "ail",
                "piment",
                "oignon",
                "gingembre",
                "herbes",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail, le gingembre, le sel et le poivre pour mariner le poulet pendant 30 minutes.",
                "Préparer les légumes (oignon émincé, tomates coupées)."
            ],
            "cuisson": [
                "Dans une cocotte ou marmite étanche, disposer le poulet mariné et les légumes.",
                "Couvrir hermétiquement et cuire à feu doux pendant 45 minutes sans ouvrir.",
                "Secouer la marmite de temps en temps pour mélanger les ingrédients."
            ],
            "cooking_methods": {
                "best": "etouffee",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec de l'attiéké ou du riz.",
            "shortStory": "**Poulet Kedjenou** est un plat emblématique de la Côte d’Ivoire où le poulet cuit à l'étouffée dans une marmite hermétique, offrant des saveurs intenses et une viande tendre.",
            "detailURL": "/fiche-recette/183/etouffee-poulet/poulet-kedjenou.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Accessible, mais nécessite des herbes fraîches."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Épicé grâce au piment."
            },
            "calories": "380 kcal",
            "allergens": [
                "aucun"
            ],
            "difficulty": {
                "level": 2,
                "description": "Nécessite de maintenir une cuisson à l'étouffée."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 184,
            "categorie": "afrique",
            "country": "Côte d’Ivoire",
            "flag": "CI",
            "title": "Poulet Braisé",
            "subTitle": "Poulet mariné avec ail, gingembre, citron et épices, grillé.",
            "chicken_piece": [
                "entier",
                "cuisse"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier découpé",
                "3 gousses d'ail écrasées",
                "1 cuillère à soupe de gingembre frais râpé",
                "1 citron (jus)",
                "1 cuillère à soupe d'épices, poivre et paprika",
                "sel"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "ail",
                "gingembre",
                "citron",
                "epices",
                "sel"
            ],
            "preparation": [
                "Mélanger l'ail, le gingembre, le jus de citron, les épices et le sel pour faire la marinade.",
                "Frotter généreusement le poulet avec la marinade et laisser reposer pendant 1 heure."
            ],
            "cuisson": [
                "Préchauffer un grill ou un barbecue à feu moyen-élevé.",
                "Griller les morceaux de poulet pendant 30 minutes en les retournant régulièrement jusqu'à ce qu'ils soient bien cuits et dorés."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "barbecue": true,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": false,
                "plancha": true
            },
            "mode_cuisson": [
                "grill"
            ],
            "accompagnement": "Servir avec de l'attiéké, du riz ou des bananes plantains grillées.",
            "shortStory": "**Poulet Braisé** est un incontournable ivoirien où le poulet mariné est grillé à la perfection pour une saveur riche et épicée.",
            "detailURL": "/fiche-recette/184/gingembre-frais-citron-paprika-poivre/poulet-braise.html",
            "time_prepare": "10mn",
            "time_maceration": "1h",
            "time_cooking": "30mn",
            "crispys": true,
            "cost": {
                "level": 1,
                "description": "Peu coûteux et très populaire."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Modérément épicé avec une touche de gingembre."
            },
            "calories": "400 kcal",
            "allergens": [
                "aucun"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, idéal pour les grillades."
            },
            "equipment_needed": [
                "grill ou barbecue",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 165,
            "categorie": "amerique-sud",
            "country": "Chili",
            "flag": "CL",
            "title": "Pollo al Pil-Pil",
            "subTitle": "Poulet mariné avec ail, piment rouge et huile d’olive.",
            "chicken_piece": [
                "morceaux",
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "morceaux",
                "entier": true,
                "aile": true,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "1 kg de morceaux de poulet",
                "4 gousses d'ail, finement hachées",
                "2 piments rouges, émincés",
                "100 ml d'huile d'olive",
                "1 cuillère à soupe de paprika doux",
                "sel et poivre",
                "persil frais pour la garniture"
            ],
            "ingredient_liste": [
                "poulet",
                "ail",
                "piment-rouge",
                "huile-d-olive",
                "paprika",
                "sel",
                "poivre",
                "persil"
            ],
            "preparation": [
                "Mélanger l'ail, les piments rouges, l'huile d'olive et le paprika. Saler et poivrer.",
                "Badigeonner les morceaux de poulet avec la marinade et laisser reposer au réfrigérateur pendant 1 heure."
            ],
            "cuisson": [
                "Chauffer une grande poêle ou un plat en terre cuite sur feu moyen.",
                "Ajouter le poulet et sa marinade, puis cuire à feu moyen-doux, en remuant de temps en temps, jusqu'à ce que le poulet soit bien doré et cuit à cœur (environ 30 minutes).",
                "Servir chaud, garni de persil frais."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle",
                "cocotte"
            ],
            "accompagnement": "Délicieux avec du riz blanc ou des pommes de terre sautées.",
            "shortStory": "Le **Pollo al Pil-Pil** est une spécialité chilienne, célébrée pour sa simplicité et ses saveurs intenses d'ail et de piment.",
            "detailURL": "/fiche-recette/165/pil-pil/pollo-al-pil-pil.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Rapide et économique."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Saveurs relevées grâce au piment rouge."
            },
            "calories": "350 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Préparation facile et cuisson simple."
            },
            "equipment_needed": [
                "poêle ou cocotte",
                "cuillère en bois"
            ]
        },
        {
            "id": 166,
            "categorie": "amerique-sud",
            "country": "Chili",
            "flag": "CL",
            "title": "Pollo Asado Chileno",
            "subTitle": "Poulet mariné avec du vin blanc, citron et paprika, grillé.",
            "chicken_piece": [
                "entier",
                "morceaux",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": true,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": true
            },
            "ingredient": [
                "1 poulet entier ou coupé en morceaux",
                "200 ml de vin blanc sec",
                "le jus d'un citron",
                "2 cuillères à soupe de paprika",
                "2 gousses d'ail, écrasées",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "vin-blanc",
                "citron",
                "paprika",
                "ail",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le vin blanc, le jus de citron, le paprika, l'ail et l'huile d'olive. Saler et poivrer.",
                "Badigeonner généreusement le poulet avec cette marinade et laisser reposer au réfrigérateur pendant 2 heures."
            ],
            "cuisson": [
                "Préchauffer le barbecue ou le four à 200°C (390°F).",
                "Cuire le poulet sur le barbecue ou dans un plat au four, en arrosant régulièrement avec la marinade, jusqu'à ce qu'il soit bien doré et cuit à cœur (environ 45 minutes).",
                "Servir chaud avec des légumes grillés ou une salade verte."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "barbecue": true,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": true
            },
            "mode_cuisson": [
                "barbecue",
                "four",
                "plancha"
            ],
            "accompagnement": "Parfait avec des légumes grillés ou du riz.",
            "shortStory": "Le **Pollo Asado Chileno** est une tradition festive au Chili, mettant en avant des saveurs fumées et citronnées.",
            "detailURL": "/fiche-recette/166/vin-blanc-citron-paprika/pollo-asado-chileno.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "45mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Utilise des ingrédients simples mais nécessite un bon vin blanc."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Notes épicées équilibrées par le citron."
            },
            "calories": "400 kcal",
            "allergens": [
                "vin"
            ],
            "difficulty": {
                "level": 2,
                "description": "Simple à préparer, demande une surveillance pendant la cuisson."
            },
            "equipment_needed": [
                "barbecue ou four",
                "pinceau de cuisine"
            ]
        },
        {
            "id": 161,
            "categorie": "afrique",
            "country": "Cameroun",
            "flag": "CM",
            "title": "Poulet DG",
            "subTitle": "Poulet mariné avec ail, gingembre et herbes, mijoté avec bananes plantains.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "1 poulet coupé en morceaux",
                "4 bananes plantains mûres",
                "3 gousses d'ail, écrasées",
                "1 cuillère à soupe de gingembre râpé",
                "2 oignons, émincés",
                "3 tomates, hachées",
                "1 poivron rouge, coupé en lanières",
                "1 carotte, coupée en rondelles",
                "1 bouquet de persil haché",
                "2 cuillères à soupe d'huile d'arachide",
                "1 verre de bouillon de volaille",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "bananes-plantains",
                "ail",
                "gingembre",
                "oignons",
                "tomates",
                "poivron",
                "carottes",
                "persil",
                "huile-d-arachide",
                "bouillon",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger le poulet avec l'ail, le gingembre, le sel et le poivre. Laisser mariner pendant 1 heure.",
                "Peler les bananes plantains et les couper en morceaux. Les faire frire légèrement dans une poêle avec de l'huile d'arachide."
            ],
            "cuisson": [
                "Faire revenir les morceaux de poulet dans une cocotte avec un peu d'huile d'arachide jusqu'à ce qu'ils soient dorés.",
                "Ajouter les oignons, les tomates, le poivron et la carotte. Laisser mijoter pendant 10 minutes.",
                "Verser le bouillon de volaille, couvrir et laisser cuire à feu doux pendant 30 minutes.",
                "Incorporer les bananes plantains frites et mélanger délicatement. Laisser mijoter encore 10 minutes.",
                "Servir chaud, garni de persil haché."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": true,
                "four": false,
                "poele": true,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Accompagner d'un riz parfumé ou d'une salade fraîche.",
            "shortStory": "**Poulet DG** est un plat phare du Cameroun, combinant les saveurs riches du poulet, des épices et des bananes plantains pour une explosion de goûts.",
            "detailURL": "/fiche-recette/161/bananes-plantains-gingembre/poulet-dg.html",
            "time_prepare": "30mn",
            "time_maceration": "1h",
            "time_cooking": "50mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Ingrédients accessibles mais plusieurs étapes de préparation."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Léger avec des saveurs équilibrées."
            },
            "calories": "450 kcal",
            "allergens": [
                "arachides"
            ],
            "difficulty": {
                "level": 3,
                "description": "Nécessite de la patience et plusieurs étapes de cuisson."
            },
            "equipment_needed": [
                "cocotte",
                "poêle",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 162,
            "categorie": "afrique",
            "country": "Cameroun",
            "flag": "CM",
            "title": "Poulet Ndolé",
            "subTitle": "Poulet mariné avec ail et citron, accompagné de feuilles de ndolé en sauce.",
            "chicken_piece": [
                "morceaux",
                "entier",
                "cuisse"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier ou en morceaux",
                "4 gousses d'ail, écrasées",
                "1 citron (jus uniquement)",
                "500g de feuilles de ndolé (frais ou surgelé)",
                "200g de pâte d'arachide",
                "2 oignons, émincés",
                "3 tomates, hachées",
                "1 cube de bouillon",
                "3 cuillères à soupe d'huile d'arachide",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "ail",
                "citron",
                "feuilles-de-ndole",
                "pate-d-arachide",
                "oignons",
                "tomates",
                "bouillon",
                "huile-d-arachide",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mariner le poulet avec l'ail, le jus de citron, du sel et du poivre pendant 1 heure.",
                "Faire blanchir les feuilles de ndolé dans de l'eau bouillante pour retirer leur amertume. Égoutter et réserver.",
                "Diluer la pâte d'arachide avec un peu d'eau chaude pour obtenir une consistance crémeuse."
            ],
            "cuisson": [
                "Faire revenir le poulet dans une cocotte avec l'huile d'arachide jusqu'à ce qu'il soit doré. Réserver.",
                "Dans la même cocotte, faire revenir les oignons et les tomates. Ajouter le cube de bouillon et mélanger.",
                "Incorporer la pâte d'arachide diluée et les feuilles de ndolé. Laisser mijoter pendant 10 minutes.",
                "Ajouter le poulet doré à la préparation. Couvrir et laisser cuire à feu doux pendant 20-30 minutes.",
                "Servir chaud avec du riz ou du manioc pilé."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Accompagner de riz, manioc pilé ou plantains bouillis.",
            "shortStory": "Le **Poulet Ndolé** est un symbole de la gastronomie camerounaise, mettant en valeur les feuilles de ndolé et des saveurs d'arachide riches et authentiques.",
            "detailURL": "/fiche-recette/162/ndole-arachide/poulet-ndole.html",
            "time_prepare": "30mn",
            "time_maceration": "1h",
            "time_cooking": "50mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Nécessite des ingrédients spécifiques mais facilement trouvables."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Douces saveurs, non épicé."
            },
            "calories": "420 kcal",
            "allergens": [
                "arachides"
            ],
            "difficulty": {
                "level": 3,
                "description": "Plutôt complexe, nécessite une bonne maîtrise des cuissons."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 2,
            "categorie": "asiatique",
            "country": "Chine",
            "flag": "CN",
            "title": "Poulet général Tao",
            "subTitle": "Poulet croustillant sauce aigre-douce",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "blanc": true,
                "cuisse": false,
                "aile": false,
                "pilon": false
            },
            "ingredient": [
                "4 filets de poulet",
                "1/2 tasse de sauce soja",
                "1/4 tasse de miel",
                "2 cuillères à soupe de vinaigre de riz",
                "2 gousses d'ail émincées",
                "1 cuillère à café de gingembre frais râpé",
                "2 cuillères à soupe de fécule de maïs",
                "1/4 tasse d'eau",
                "2 cuillères à soupe d'huile végétale",
                "2 cuillères à soupe d'huile de sésame",
                "1 cuillère à soupe de graines de sésame (pour la garniture)",
                "Tranches de piment rouge (pour la garniture)",
                "Tranches de cébette (pour la garniture)"
            ],
            "ingredient_liste": [
                "filets-de-poulet",
                "sauce-soja",
                "miel",
                "vinaigre-de-riz",
                "ail-eminces",
                "gingembre-frais",
                "fecule-de-mais",
                "eau",
                "huile-vegetale",
                "huile-de-sesame",
                "graines-de-sesame",
                "piment-rouge",
                "cebettes"
            ],
            "preparation": [
                "Dans un bol, mélanger la sauce soja, le miel, le vinaigre de riz, l'ail et le gingembre pour faire la sauce.",
                "Couper les filets de poulet en morceaux de taille moyenne et les enrober de fécule de maïs."
            ],
            "cuisson": [
                "Chauffer l'huile végétale dans une poêle à feu moyen-élevé. Faire frire les morceaux de poulet jusqu'à ce qu'ils soient dorés et croustillants. Retirer et égoutter sur du papier absorbant.",
                "Dans une autre poêle, chauffer l'huile de sésame à feu moyen.",
                "Ajouter la sauce préparée et l'eau. Laisser mijoter jusqu'à ce que la sauce épaississe légèrement.",
                "Ajouter les morceaux de poulet frits dans la poêle avec la sauce et mélanger pour les enrober uniformément."
            ],
            "cooking_methods": {
                "best": "friture",
                "plancha": false,
                "four": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir le poulet général Tao chaud, saupoudré de graines de sésame et garni de tranches de piment rouge et de cébette. Le poulet général Tao est délicieux servi avec du riz cuit à la vapeur et des légumes sautés à l'ail et au gingembre.",
            "shortStory": "Le **poulet général Tao** est un plat populaire de la cuisine chinoise, apprécié pour son **poulet croustillant** enrobé d'une **sauce aigre-douce** savoureuse. Cette recette maison vous permet de savourer ce plat délicieux et authentique dans le confort de votre foyer.",
            "detailURL": "/fiche-recette/2/sauce-soja-miel-vinaigre-de-riz-gingembre-fecule-de-mais/poulet-general-tao.html",
            "time_prepare": "20mn",
            "time_cooking": "20mn",
            "time_maceration": "0mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est moyen (niveau 2) en raison des ingrédients comme la sauce soja, le miel et l'huile de sésame, qui peuvent être légèrement plus coûteux que des ingrédients plus basiques. Cependant, ces produits sont souvent utilisés en petites quantités, ce qui rend la recette accessible sans être excessive."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Le plat présente une épice légère mais agréable, apportée par les tranches de piment rouge utilisées comme garniture. L'épice est présente mais pas dominante, parfait pour ceux qui préfèrent un peu de piquant sans que cela soit trop fort. Ce niveau d'épices 2 est idéal pour ceux qui aiment une touche de chaleur sans brûler les papilles."
            },
            "allergens": [
                "gluten (éventuellement dans la sauce soja)",
                "sésame"
            ],
            "storage_instructions": "Conserver dans un récipient hermétique au réfrigérateur pendant 2 à 3 jours. Réchauffer à la poêle pour retrouver la texture croustillante.",
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté modérée (niveau 2), nécessitant de bien gérer la friture pour obtenir un poulet parfaitement croustillant et d'équilibrer les saveurs de la sauce."
            },
            "equipment_needed": [
                "poêle",
                "bol",
                "cuillère en bois",
                "papier absorbant"
            ],
            "calories": "400 Kcal"
        },
        {
            "id": 30,
            "categorie": "asiatique",
            "country": "Chine",
            "flag": "CN",
            "title": "Poulet sauce soja et gingembre",
            "subTitle": "Poulet mariné au gingembre et à la sauce soja",
            "chicken_piece": [
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "blanc": false,
                "cuisse": true,
                "aile": false,
                "pilon": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "3 cuillères à soupe de sauce soja",
                "2 cuillères à soupe de miel",
                "1 morceau de gingembre frais râpé",
                "2 gousses d'ail émincées",
                "1 cuillère à soupe d'huile de sésame",
                "1 cuillère à soupe de vinaigre de riz",
                "Pincée de poivre",
                "Graines de sésame (pour garnir)"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "sauce-soja",
                "miel",
                "gingembre-rape",
                "ail-eminces",
                "huile-de-sesame",
                "vinaigre-de-riz",
                "poivre",
                "graines-de-sesame"
            ],
            "preparation": [
                "Dans un bol, mélanger la sauce soja, le miel, le gingembre râpé, l'ail, l'huile de sésame, le vinaigre de riz et le poivre pour faire la marinade.",
                "Placer les cuisses et pilons de poulet dans la marinade, bien enrober. Couvrir et laisser mariner au réfrigérateur pendant 2 à 4 heures.",
                "Sortir le poulet de la marinade et égoutter légèrement avant de cuire."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C. Cuire les cuisses de poulet dans un plat allant au four pendant 35 à 40 minutes, en les arrosant régulièrement avec la marinade.",
                "Servir chaud, garni de graines de sésame et accompagné de légumes sautés ou de riz."
            ],
            "cooking_methods": {
                "best": "four",
                "plancha": false,
                "friture": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec des légumes sautés, du riz ou des nouilles pour un repas équilibré et délicieux.",
            "shortStory": "Cette recette de poulet **mariné au gingembre** et à la **sauce soja** est inspirée de la cuisine chinoise. Elle combine des **saveurs sucrées, salées et épicées** pour un plat réconfortant et savoureux. Parfait pour un dîner facile et gourmand.",
            "detailURL": "/fiche-recette/30/poulet-sauce-soja-gingembre/poulet-sauce-soja-gingembre.html",
            "time_prepare": "20mn",
            "time_maceration": "2h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est moyen (niveau 2), en raison des ingrédients comme la sauce soja, le miel et l'huile de sésame qui peuvent être légèrement plus chers que des produits de base."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Le plat a une légère note de piquant, mais il n'est pas très épicé. Ce niveau est idéal pour ceux qui préfèrent une touche subtile de chaleur."
            },
            "calories": "450 kcal",
            "allergens": [
                "gluten (éventuellement dans la sauce soja)",
                "sésame"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté modérée, idéale pour ceux qui ont un peu d'expérience en cuisine."
            },
            "equipment_needed": [
                "plat allant au four",
                "bol",
                "cuillère en bois"
            ]
        },
        {
            "id": 35,
            "categorie": "asiatique",
            "country": "Chine",
            "flag": "CN",
            "title": "Poulet au citron et au gingembre",
            "subTitle": "Poulet mariné au gingembre frais",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "2 cuillères à soupe de gingembre râpé",
                "1 citron",
                "2 cuillères à soupe de sauce soja",
                "1 gousse d'ail"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "gingembre",
                "citron",
                "sauce-soja",
                "ail"
            ],
            "preparation": [
                "Mélanger le gingembre, le jus de citron, la sauce soja et l'ail.",
                "Mariner les blancs de poulet pendant 30 minutes."
            ],
            "cuisson": [
                "Cuire sur la plancha jusqu'à ce que le poulet soit bien cuit et doré."
            ],
            "cooking_methods": {
                "best": "plancha",
                "barbecue": false,
                "friture": false,
                "four": false
            },
            "mode_cuisson": [
                "plancha"
            ],
            "accompagnement": "Servir avec des légumes sautés.",
            "shortStory": "Le **Poulet au citron et au gingembre** est une recette rafraîchissante et pleine de saveurs.",
            "detailURL": "/fiche-recette/35/sauce-soja-citron-gingembre-ail/poulet-citron-gingembre.html",
            "time_prepare": "10mn",
            "time_maceration": "30mn",
            "time_cooking": "10mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Recette abordable avec des ingrédients simples comme le poulet, le gingembre et le citron."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Le plat est doux, sans épices fortes."
            },
            "calories": "300 kcal",
            "allergens": [
                "gluten (éventuellement dans la sauce soja)"
            ],
            "difficulty": {
                "level": 1,
                "description": "Recette facile, idéale pour les débutants."
            },
            "equipment_needed": [
                "plancha",
                "bol",
                "cuillère en bois"
            ]
        },
        {
            "id": 167,
            "categorie": "asiatique",
            "country": "Chine",
            "flag": "CN",
            "title": "Poulet Kung Pao",
            "subTitle": "Poulet mariné avec sauce soja, vinaigre de riz, ail et gingembre, sauté avec cacahuètes.",
            "chicken_piece": [
                "dés",
                "morceaux"
            ],
            "piece": {
                "best": "dés",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de poulet coupé en dés",
                "2 cuillères à soupe de sauce soja",
                "1 cuillère à soupe de vinaigre de riz",
                "1 cuillère à soupe de maïzena",
                "3 gousses d'ail émincées",
                "1 morceau de gingembre (2 cm), râpé",
                "1 poivron rouge, coupé en dés",
                "1 poignée de cacahuètes grillées",
                "2 piments rouges séchés (facultatif)",
                "2 cuillères à soupe d'huile végétale",
                "1 oignon vert, émincé",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "sauce-soja",
                "vinaigre-de-riz",
                "maïzena",
                "ail",
                "gingembre",
                "poivron",
                "cacahuètes",
                "piments",
                "huile",
                "oignon-vert",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger les dés de poulet avec la sauce soja, le vinaigre de riz, la maïzena, l'ail et le gingembre dans un bol. Laisser mariner pendant 30 minutes.",
                "Préparer les légumes : couper le poivron et émincer les oignons verts."
            ],
            "cuisson": [
                "Chauffer l'huile dans un wok ou une grande poêle. Ajouter les piments rouges séchés et les faire revenir brièvement.",
                "Ajouter les morceaux de poulet marinés et les faire dorer à feu vif pendant 5 minutes.",
                "Incorporer les poivrons et les cacahuètes, puis cuire encore 3-4 minutes.",
                "Servir chaud, parsemé d'oignons verts émincés."
            ],
            "cooking_methods": {
                "best": "poele",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "wok",
                "poêle"
            ],
            "accompagnement": "Servir avec du riz blanc ou des nouilles sautées.",
            "shortStory": "**Poulet Kung Pao** est un plat chinois célèbre, alliant le croquant des cacahuètes aux saveurs intenses de la marinade à base de sauce soja et gingembre.",
            "detailURL": "/fiche-recette/167/sauce-soja-cacahuetes/poulet-kung-pao.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "10mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Ingrédients simples mais spécifiques pour une saveur authentique."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Légèrement épicé grâce aux piments rouges."
            },
            "calories": "320 kcal",
            "allergens": [
                "soja",
                "cacahuètes"
            ],
            "difficulty": {
                "level": 2,
                "description": "Niveau modéré, nécessite de la rapidité pour une cuisson parfaite au wok."
            },
            "equipment_needed": [
                "wok ou poêle",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 727,
            "categorie": "asiatique",
            "country": "Chine",
            "flag": "CN",
            "title": "Poulet au Cinq Épices",
            "subTitle": "Poulet mariné avec un mélange d’épices chinoises, sauce soja et miel, rôti ou sauté.",
            "chicken_piece": [
                "entier",
                "morceaux",
                "cuisse"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": true,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier ou 6 morceaux de poulet (cuisses, ailes ou blanc)",
                "1 cuillère à soupe de poudre de cinq épices chinoises",
                "2 cuillères à soupe de sauce soja",
                "1 cuillère à soupe de miel",
                "1 cuillère à soupe d'huile de sésame",
                "3 gousses d'ail écrasées",
                "1 morceau de gingembre (2 cm), râpé",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "cinq-épices",
                "sauce-soja",
                "miel",
                "huile-de-sésame",
                "ail",
                "gingembre",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger la poudre de cinq épices, la sauce soja, le miel, l'huile de sésame, l'ail et le gingembre.",
                "Frotter le poulet avec la marinade, en s'assurant qu'il soit bien enrobé. Laisser mariner au réfrigérateur pendant au moins 1 heure."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C.",
                "Placer le poulet sur une plaque de cuisson recouverte de papier sulfurisé et rôtir pendant 40 minutes (ou jusqu'à ce qu'il soit doré).",
                "Pour une cuisson à la poêle, chauffer un filet d'huile dans une grande poêle et cuire les morceaux de poulet à feu moyen, environ 15 minutes de chaque côté, jusqu'à ce qu'ils soient bien dorés."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": false,
                "friture": false,
                "four": true,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "four",
                "poêle"
            ],
            "accompagnement": "Accompagner de riz nature ou de légumes sautés au wok.",
            "shortStory": "**Poulet au Cinq Épices** combine la douceur du miel et l'intensité des épices chinoises pour un plat parfaitement équilibré et parfumé.",
            "detailURL": "/fiche-recette/727/cinq-epices-miel/poulet-au-cinq-epices.html",
            "time_prepare": "20mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Ingrédients simples avec des épices typiques de la cuisine chinoise."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Saveurs douces avec une légère chaleur des épices."
            },
            "calories": "280 kcal",
            "allergens": [
                "soja",
                "sésame"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette polyvalente, adaptée pour une cuisson au four ou à la poêle."
            },
            "equipment_needed": [
                "four ou poêle",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 170,
            "categorie": "amerique-sud",
            "country": "Colombie",
            "flag": "CO",
            "title": "Pollo Sudado (Poulet Mijoté)",
            "subTitle": "Poulet mariné avec ail, coriandre et épices, mijoté avec des légumes.",
            "chicken_piece": [
                "morceaux",
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 gousses d’ail écrasées",
                "1 cuillère à soupe de coriandre fraîche ciselée",
                "1 tomate coupée en dés",
                "1 oignon haché",
                "1 poivron rouge en lamelles",
                "2 pommes de terre coupées en morceaux",
                "1 carotte en rondelles",
                "1 cuillère à café de paprika",
                "1 cuillère à soupe d’huile d’olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "ail",
                "coriandre",
                "tomate",
                "oignon",
                "poivron",
                "pomme-de-terre",
                "carotte",
                "paprika",
                "huile-d'olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger l’ail, la coriandre, le paprika, le sel et le poivre avec l’huile d’olive.",
                "Frotter les cuisses de poulet avec cette marinade. Laisser reposer au réfrigérateur pendant 30mn.",
                "Couper les légumes (tomate, oignon, poivron, pommes de terre, carotte) en morceaux pour la cuisson."
            ],
            "cuisson": [
                "Dans une cocotte, chauffer un filet d’huile d’olive à feu moyen.",
                "Faire revenir les morceaux de poulet jusqu’à ce qu’ils soient dorés, puis retirer et réserver.",
                "Dans la même cocotte, ajouter l’oignon, la tomate et le poivron, et les faire revenir pendant 5mn.",
                "Remettre le poulet dans la cocotte, ajouter les pommes de terre, la carotte et un peu d’eau pour couvrir à moitié.",
                "Couvrir et laisser mijoter à feu doux pendant 30mn, jusqu’à ce que le poulet soit tendre et les légumes bien cuits."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc et des bananes plantains frites.",
            "shortStory": "**Pollo Sudado**, un plat colombien classique, combine des saveurs riches et des légumes mijotés pour un repas réconfortant.",
            "detailURL": "/fiche-recette/170/coriandre-epices/pollo-sudado.html",
            "time_prepare": "20mn",
            "time_maceration": "30mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Un plat simple et économique, à base d’ingrédients de tous les jours."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Une douceur épicée avec des notes herbacées."
            },
            "calories": "350 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Facile à préparer, même pour les cuisiniers débutants."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper",
                "bol"
            ]
        },
        {
            "id": 171,
            "categorie": "amerique-sud",
            "country": "Colombie",
            "flag": "CO",
            "title": "Ajiaco (Soupe de Poulet Colombienne)",
            "subTitle": "Poulet mariné et mijoté avec des pommes de terre, servi dans une soupe.",
            "chicken_piece": [
                "morceaux",
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": true,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux",
                "3 types de pommes de terre (douce, jaune et classique)",
                "2 épis de maïs coupés en tronçons",
                "1 bouquet garni (coriandre, persil, oignons verts)",
                "3 gousses d’ail écrasées",
                "2 litres de bouillon de volaille",
                "1 avocat mûr (pour servir)",
                "1 pot de crème fraîche",
                "câpres (facultatif)",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "pomme-de-terre",
                "maïs",
                "bouquet-garni",
                "ail",
                "bouillon",
                "avocat",
                "crème-fraiche",
                "câpres",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Préparer le poulet en morceaux. Assaisonner avec sel, poivre et ail.",
                "Éplucher et couper les pommes de terre en morceaux.",
                "Attacher les herbes pour former un bouquet garni."
            ],
            "cuisson": [
                "Dans une grande marmite, porter le bouillon de volaille à ébullition.",
                "Ajouter les morceaux de poulet, les pommes de terre, les tronçons de maïs et le bouquet garni.",
                "Laisser mijoter à feu doux pendant 1h, jusqu’à ce que le poulet soit tendre et les pommes de terre bien cuites.",
                "Retirer le bouquet garni et ajuster l’assaisonnement.",
                "Servir chaud avec une cuillerée de crème fraîche, des tranches d’avocat et des câpres si désiré."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": false,
                "plancha": false,
                "marmite": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec des tranches de pain frais et un verre de jus de fruit tropical.",
            "shortStory": "**Ajiaco**, une soupe colombienne réconfortante, célèbre pour sa **combinaison unique de trois types de pommes de terre** et son riche bouillon au poulet.",
            "detailURL": "/fiche-recette/171/soupe-colombienne/ajiaco.html",
            "time_prepare": "30mn",
            "time_maceration": "null",
            "time_cooking": "1h",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Ingrédients abordables mais plusieurs types de pommes de terre nécessaires."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Aucune chaleur épicée, juste une chaleur réconfortante."
            },
            "calories": "450 kcal",
            "allergens": [
                "crème-fraiche"
            ],
            "difficulty": {
                "level": 2,
                "description": "Demande un peu de temps et d'organisation pour la préparation."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 181,
            "categorie": "amerique-centrale",
            "country": "Costa Rica",
            "flag": "CR",
            "title": "Poulet en Sauce Caribéenne (Pollo en Salsa Caribeña)",
            "subTitle": "Poulet mariné avec ail, coriandre, citron vert et épices, mijoté.",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de cuisses ou blancs de poulet",
                "3 gousses d'ail écrasées",
                "1 bouquet de coriandre hachée",
                "1 citron vert (jus)",
                "1 cuillère à soupe d'épices (paprika, cumin)",
                "2 tasses de lait de coco",
                "1 oignon émincé",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "ail",
                "coriandre",
                "citron-vert",
                "epices",
                "lait-de-coco",
                "oignon",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail, la coriandre, le jus de citron vert, les épices, le sel et le poivre pour faire mariner le poulet pendant 30 minutes.",
                "Faire revenir l'oignon émincé dans une casserole avec un peu d'huile jusqu'à ce qu'il soit translucide."
            ],
            "cuisson": [
                "Ajouter le poulet mariné dans la casserole et le faire dorer légèrement.",
                "Verser le lait de coco et laisser mijoter à feu doux pendant 25 à 30 minutes jusqu'à ce que le poulet soit tendre.",
                "Servir chaud avec du riz ou des bananes plantains."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc ou des bananes plantains.",
            "shortStory": "**Pollo en Salsa Caribeña** est une spécialité du Costa Rica où le poulet mijote dans une sauce riche à base de lait de coco, d'ail et de coriandre, offrant une explosion de saveurs tropicales.",
            "detailURL": "/fiche-recette/181/lait-de-coco-poulet/pollo-en-salsa-caribena.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Ingrédients abordables, sauf le lait de coco."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Légèrement épicé."
            },
            "calories": "400 kcal",
            "allergens": [
                "coco"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite une cuisson lente."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 182,
            "categorie": "amerique-centrale",
            "country": "Costa Rica",
            "flag": "CR",
            "title": "Poulet à la cocotte (Pollo al Caldero)",
            "subTitle": "Poulet mariné avec des épices locales, mijoté dans une sauce tomate.",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de cuisses de poulet",
                "3 gousses d'ail écrasées",
                "2 tomates mûres râpées",
                "1 oignon émincé",
                "1 poivron rouge en lanières",
                "1 cuillère à soupe d'épices locales (paprika, cumin, origan)",
                "1 tasse de bouillon de volaille",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "ail",
                "tomates",
                "oignon",
                "poivron",
                "epices-locales",
                "bouillon-de-volaille",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail, les épices, le sel et le poivre pour mariner le poulet pendant 20 minutes.",
                "Faire revenir l'oignon et le poivron dans une cocotte avec un peu d'huile."
            ],
            "cuisson": [
                "Ajouter les tomates râpées et laisser mijoter quelques minutes.",
                "Incorporer les morceaux de poulet et le bouillon de volaille.",
                "Laisser mijoter à feu doux pendant 30 minutes en remuant de temps en temps."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz ou des légumes vapeur.",
            "shortStory": "**Pollo al Caldero** est une recette traditionnelle du Costa Rica où le poulet mijote dans une sauce tomate parfumée avec des épices locales, **cumin, coriandre, paprika, ail, oignon**, créant un plat réconfortant et savoureux.",
            "detailURL": "/fiche-recette/182/sauce-tomate-poulet/pollo-al-caldero.html",
            "time_prepare": "10mn",
            "time_maceration": "20mn",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Peu coûteux avec des ingrédients de base."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Légèrement épicé selon les épices locales."
            },
            "calories": "320 kcal",
            "allergens": [
                "aucun"
            ],
            "difficulty": {
                "level": 1,
                "description": "Simple à préparer, idéal pour les débutants."
            },
            "equipment_needed": [
                "cocotte ou casserole",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 186,
            "categorie": "caraibes",
            "country": "Cuba",
            "flag": "CU",
            "title": "Pollo Mojo",
            "subTitle": "Poulet mariné avec ail, jus d'orange, citron vert et origan, grillé ou rôti.",
            "chicken_piece": [
                "blanc",
                "cuisses"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier (ou morceaux de poulet)",
                "4 gousses d'ail écrasées",
                "1 orange (jus)",
                "1 citron vert (jus)",
                "1 cuillère à soupe d'origan séché",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "ail",
                "orange",
                "citron-vert",
                "origan",
                "huile-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail écrasé, le jus d'orange, le jus de citron vert, l'origan, l'huile d'olive, le sel et le poivre.",
                "Enduire les morceaux de poulet de cette marinade et laisser reposer pendant au moins 1 heure."
            ],
            "cuisson": [
                "Préchauffer le grill ou le four à température moyenne.",
                "Griller ou rôtir le poulet pendant 40 à 50 minutes, en le retournant pour qu'il soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "barbecue": true,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "barbecue",
                "rôti"
            ],
            "accompagnement": "Servir avec du riz, des haricots noirs ou une salade fraîche.",
            "shortStory": "**Pollo Mojo** est une recette cubaine où le poulet est **mariné avec un mélange d'ail, de jus d'orange, de citron vert et d'origan** avant d'être **grillé ou rôti** pour un goût acidulé et savoureux.",
            "detailURL": "/fiche-recette/186/orange-citron-vert-origan/pollo-mojo-cuba.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat simple et rapide à réaliser."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Non épicé."
            },
            "calories": "280 kcal",
            "allergens": [
                "huile d'olive"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, cuisson au grill ou au four."
            },
            "equipment_needed": [
                "grill ou four",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 187,
            "categorie": "caraibes",
            "country": "Cuba",
            "flag": "CU",
            "title": "Arroz con Pollo (riz au poulet)",
            "subTitle": "Poulet mariné avec ail, citron et épices, cuit avec du riz et des légumes.",
            "chicken_piece": [
                "blanc",
                "cuisses"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier (ou morceaux de poulet)",
                "4 gousses d'ail écrasées",
                "1 citron (jus)",
                "1 cuillère à café de cumin",
                "1 cuillère à café de paprika",
                "1 tasse de riz",
                "2 tasses de bouillon de poulet",
                "1 poivron rouge coupé en dés",
                "1 oignon coupé en dés",
                "1 tasse de petits pois",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "ail",
                "citron",
                "cumin",
                "paprika",
                "riz",
                "bouillon-poulet",
                "poivron",
                "oignon",
                "petits-pois",
                "huile-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail écrasé, le jus de citron, le cumin, le paprika, le sel et le poivre.",
                "Enduire les morceaux de poulet de cette marinade et laisser reposer pendant 30 minutes."
            ],
            "cuisson": [
                "Faire chauffer l'huile d'olive dans une grande poêle ou une cocotte.",
                "Faire dorer le poulet de chaque côté, puis ajouter les oignons, le poivron et les petits pois.",
                "Ajouter le riz et le bouillon de poulet, couvrir et laisser mijoter pendant environ 30 minutes jusqu'à ce que le riz soit cuit."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec une salade fraîche ou des légumes sautés.",
            "shortStory": "**Arroz con Pollo** est un plat cubain où le poulet est mariné avec de l'ail, du citron et des épices, puis **cuit avec du riz et des légumes pour un repas complet et savoureux**.",
            "detailURL": "/fiche-recette/187/paprika-cumin-poivron-citron/arroz-con-pollo-cuba.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat simple et économique."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Non épicé."
            },
            "calories": "350 kcal",
            "allergens": [
                "huile d'olive"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite une cuisson à la poêle ou en cocotte."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 157,
            "categorie": "afrique",
            "country": "Cabo Verde",
            "flag": "CV",
            "title": "Galinha Grelhada",
            "subTitle": "Poulet mariné avec ail, citron, huile d'olive et paprika, grillé.",
            "chicken_piece": [
                "entier",
                "cuisse",
                "blanc",
                "pilon"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier ou morceaux",
                "4 gousses d'ail, écrasées",
                "2 citrons (jus uniquement)",
                "2 cuillères à soupe d'huile d'olive",
                "1 cuillère à soupe de paprika",
                "1 cuillère à café de sel",
                "poivre au goût"
            ],
            "ingredient_liste": [
                "poulet",
                "ail",
                "citron",
                "huile-d-olive",
                "paprika",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail écrasé, le jus de citron, l'huile d'olive, le paprika, le sel et le poivre dans un bol.",
                "Enrober le poulet ou les morceaux avec cette marinade. Couvrir et laisser mariner au réfrigérateur pendant 2 heures."
            ],
            "cuisson": [
                "Préchauffer un barbecue ou une plancha à feu moyen.",
                "Griller le poulet pendant environ 25-30 minutes, en le retournant régulièrement pour une cuisson uniforme.",
                "Servir chaud avec une salade ou des pommes de terre rôties."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "barbecue": true,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": false,
                "plancha": true
            },
            "mode_cuisson": [
                "barbecue",
                "plancha"
            ],
            "accompagnement": "Accompagnez ce plat de légumes grillés, de riz ou d'une salade de tomates et oignons.",
            "shortStory": "La **Galinha Grelhada** est une spécialité du Cap-Vert, idéale pour les grillades. Sa marinade au citron et paprika donne un goût unique et savoureux.",
            "detailURL": "/fiche-recette/157/citron-paprika/galinha-grelhada.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Ingrédients simples et accessibles."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Saveur douce avec une légère note citronnée."
            },
            "calories": "310 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Facile, parfait pour les repas en famille ou entre amis."
            },
            "equipment_needed": [
                "grill",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 158,
            "categorie": "afrique",
            "country": "Cabo Verde",
            "flag": "CV",
            "title": "Canja de Galinha",
            "subTitle": "Poulet mariné avec du gingembre, ail et herbes, intégré dans une soupe de riz.",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "pilon",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (cuisse ou pilons)",
                "2 gousses d'ail, écrasées",
                "1 cuillère à soupe de gingembre râpé",
                "1 bouquet de persil ou coriandre haché",
                "1 verre de riz blanc",
                "1 oignon, émincé",
                "2 carottes, coupées en rondelles",
                "1,5L de bouillon de volaille",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "ail",
                "gingembre",
                "persil",
                "riz",
                "oignon",
                "carottes",
                "bouillon-volaille",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mariner les morceaux de poulet avec l'ail écrasé, le gingembre, le sel et le poivre pendant 30 minutes.",
                "Rincer le riz à l'eau froide pour enlever l'excès d'amidon."
            ],
            "cuisson": [
                "Faire revenir l'oignon émincé dans une grande marmite avec un peu d'huile jusqu'à ce qu'il soit translucide.",
                "Ajouter les morceaux de poulet marinés et les faire dorer légèrement.",
                "Incorporer les carottes, le riz et le bouillon de volaille. Porter à ébullition.",
                "Réduire le feu et laisser mijoter pendant 30 minutes, en remuant de temps en temps.",
                "Ajouter le persil ou la coriandre hachée en fin de cuisson avant de servir."
            ],
            "cooking_methods": {
                "best": "marmite",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Cette soupe peut être servie seule ou avec une tranche de pain frais.",
            "shortStory": "La **Canja de Galinha** est une soupe réconfortante typique du Cap-Vert, parfaite pour les jours frais ou comme plat revigorant.",
            "detailURL": "/fiche-recette/158/gingembre-riz/canja-de-galinha.html",
            "time_prepare": "20mn",
            "time_maceration": "30mn",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Economique et nourrissant."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Saveurs douces et parfumées."
            },
            "calories": "280 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Nécessite un peu d'attention pour obtenir une soupe bien équilibrée."
            },
            "equipment_needed": [
                "marmite",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 168,
            "categorie": "europe",
            "country": "Chypre",
            "flag": "CY",
            "title": "Poulet Souvlaki",
            "subTitle": "Poulet mariné avec citron, origan et huile d’olive, grillé sur brochettes.",
            "chicken_piece": [
                "morceaux",
                "dés"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de poulet coupé en morceaux",
                "2 cuillères à soupe de jus de citron",
                "1 cuillère à soupe d’origan séché",
                "2 cuillères à soupe d’huile d’olive",
                "3 gousses d’ail émincées",
                "sel et poivre",
                "brochettes en bois (trempées dans l'eau)"
            ],
            "ingredient_liste": [
                "poulet",
                "citron",
                "origan",
                "huile-d'olive",
                "ail",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le jus de citron, l’origan, l’huile d’olive, l’ail, le sel et le poivre.",
                "Ajouter les morceaux de poulet dans la marinade et bien les enrober. Laisser reposer au réfrigérateur pendant 1h.",
                "Tremper les brochettes en bois dans l'eau pour éviter qu'elles ne brûlent pendant la cuisson.",
                "Monter les morceaux de poulet sur les brochettes en bois."
            ],
            "cuisson": [
                "Préchauffer le barbecue ou une poêle-grill à feu moyen.",
                "Cuire les brochettes environ 12mn en les retournant régulièrement, jusqu'à ce que le poulet soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "barbecue": true,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": true
            },
            "mode_cuisson": [
                "barbecue",
                "poêle",
                "plancha"
            ],
            "accompagnement": "Servir avec du tzatziki, une salade grecque, des légumes grillés et du pain pita.",
            "shortStory": "**Poulet Souvlaki**, un plat emblématique de la cuisine chypriote, est apprécié pour ses saveurs méditerranéennes simples et son caractère convivial. Ce plat est idéal pour un barbecue ou un repas d'été.",
            "detailURL": "/fiche-recette/168/origan-citron/poulet-souvlaki.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "12mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Recette économique avec des ingrédients simples et accessibles."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Non épicé, avec des saveurs herbacées dominantes."
            },
            "calories": "260 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Facile à réaliser, parfait pour les débutants en cuisine."
            },
            "equipment_needed": [
                "barbecue ou poêle-grill",
                "brochettes en bois",
                "bol"
            ]
        },
        {
            "id": 169,
            "categorie": "europe",
            "country": "Chypre",
            "flag": "CY",
            "title": "Poulet au Halloumi",
            "subTitle": "Poulet mariné avec ail, herbes et huile d’olive, accompagné de fromage halloumi.",
            "chicken_piece": [
                "morceaux",
                "entier"
            ],
            "piece": {
                "best": "blanc",
                "entier": true,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "4 morceaux de poulet (blancs ou cuisses)",
                "200g de halloumi, tranché",
                "2 cuillères à soupe d’huile d’olive",
                "2 gousses d’ail écrasées",
                "1 cuillère à soupe d’herbes de Provence",
                "1 citron (zeste et jus)",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "halloumi",
                "huile-d'olive",
                "ail",
                "herbes-de-provence",
                "citron",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger l’huile d’olive, l’ail, les herbes de Provence, le zeste et le jus de citron, le sel et le poivre.",
                "Badigeonner les morceaux de poulet avec cette marinade. Couvrir et laisser reposer 1h au réfrigérateur.",
                "Trancher le halloumi en morceaux épais pour éviter qu'il ne fonde pendant la cuisson."
            ],
            "cuisson": [
                "Préchauffer une poêle-grill ou un barbecue à feu moyen.",
                "Cuire les morceaux de poulet pendant 15-20mn, en les retournant régulièrement pour une cuisson uniforme.",
                "Ajouter les tranches de halloumi sur le grill pendant les 3 dernières minutes de cuisson, en les retournant une fois pour qu’elles soient dorées des deux côtés."
            ],
            "cooking_methods": {
                "best": "plancha",
                "barbecue": true,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": true
            },
            "mode_cuisson": [
                "plancha",
                "barbecue",
                "poêle"
            ],
            "accompagnement": "Servir avec une salade de tomates et concombres, des olives noires et du pain pita chaud.",
            "shortStory": "**Poulet au Halloumi**, un **délice chypriote**, marie la tendreté du poulet aux saveurs salées et grillées du fromage halloumi, pour une expérience méditerranéenne inoubliable.",
            "detailURL": "/fiche-recette/169/halloumi-herbes/poulet-au-halloumi.html",
            "time_prepare": "20mn",
            "time_maceration": "1h",
            "time_cooking": "20mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le halloumi ajoute une touche gourmande, légèrement plus coûteuse."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Saveurs herbacées sans épices fortes."
            },
            "calories": "320 kcal",
            "allergens": [
                "lait"
            ],
            "difficulty": {
                "level": 2,
                "description": "Demande une attention particulière pour la cuisson parfaite du halloumi."
            },
            "equipment_needed": [
                "barbecue ou poêle-grill",
                "couteau",
                "planche à découper",
                "bol"
            ]
        },
        {
            "id": 110,
            "categorie": "europe",
            "country": "Allemagne",
            "flag": "DE",
            "title": "Poulet mariné à la moutarde et aux herbes",
            "subTitle": "Poulet mariné dans de la moutarde, vin blanc et herbes",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "6 cuisses de poulet (ou pilons)",
                "3 cuillères à soupe de moutarde (de Dijon ou à l’ancienne)",
                "150 ml de vin blanc sec",
                "2 cuillères à soupe d’huile d’olive",
                "1 gousse d’ail hachée",
                "1 cuillère à soupe de thym frais",
                "1 cuillère à soupe de romarin frais",
                "1 cuillère à café de miel",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "moutarde",
                "vin-blanc",
                "herbes",
                "ail",
                "miel",
                "huile-d'olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger la moutarde, le vin blanc, l’ail, le miel, l’huile d’olive, le thym et le romarin.",
                "Saler et poivrer selon le goût.",
                "Badigeonner les morceaux de poulet avec cette marinade, en les enrobant bien.",
                "Couvrir et laisser mariner au réfrigérateur pendant 3 heures minimum, idéalement toute une nuit."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C.",
                "Disposer les morceaux de poulet marinés dans un plat de cuisson.",
                "Verser le reste de la marinade par-dessus.",
                "Enfourner et cuire pendant environ 40 à 45 minutes, en arrosant régulièrement avec le jus de cuisson, jusqu’à ce que le poulet soit bien doré."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four",
                "grill",
                "barbecue"
            ],
            "accompagnement": "Servir avec une purée de pommes de terre ou des légumes verts vapeur.",
            "shortStory": "Le **Poulet mariné à la moutarde et aux herbes** est une recette classique d’Allemagne, où les saveurs piquantes de la moutarde se marient harmonieusement avec les notes fraîches des herbes aromatiques. Le vin blanc ajoute une touche de raffinement au plat. Traditionnellement servi lors de repas familiaux, ce plat reflète l’amour de la cuisine simple mais savoureuse, typique de la gastronomie allemande.",
            "detailURL": "/fiche-recette/110/poulet-moutarde-vin-herbes/poulet-marine-moutarde-herbes.html",
            "time_prepare": "15mn",
            "time_maceration": "3h",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), la moutarde et les herbes étant abordables, mais le vin blanc peut légèrement influencer le budget."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Ce plat n'est pas épicé, mais offre une saveur piquante grâce à la moutarde et une douceur subtile apportée par le miel."
            },
            "calories": "340 kcal",
            "allergens": [
                "moutarde"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette accessible avec une marinade simple, mais nécessitant une surveillance pendant la cuisson pour garantir une belle caramélisation."
            },
            "equipment_needed": [
                "four",
                "plat-de-cuisson",
                "bol"
            ]
        },
        {
            "id": 111,
            "categorie": "europe",
            "country": "Allemagne",
            "flag": "DE",
            "title": "Poulet Bière Bavarois",
            "subTitle": "Poulet mariné à la bière avec ail et oignons",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier ou 6 morceaux (cuisses et pilons)",
                "500 ml de bière blonde",
                "2 oignons émincés",
                "3 gousses d'ail écrasées",
                "2 cuillères à soupe de moutarde douce",
                "1 cuillère à soupe de miel",
                "1 cuillère à soupe de paprika doux",
                "2 cuillères à soupe d’huile d’olive",
                "Sel et poivre",
                "2 branches de thym frais"
            ],
            "ingredient_liste": [
                "poulet",
                "bière",
                "oignons",
                "ail",
                "moutarde",
                "miel",
                "paprika",
                "huile-d'olive",
                "sel",
                "poivre",
                "thym"
            ],
            "preparation": [
                "Dans un grand bol, mélanger la bière, la moutarde, le miel, l'ail, le paprika, l'huile d'olive, le sel, le poivre et le thym.",
                "Ajouter les morceaux de poulet et les enrober généreusement de la marinade.",
                "Couvrir et laisser mariner au réfrigérateur pendant 4 heures minimum, idéalement toute une nuit.",
                "Préchauffer le four à 200°C."
            ],
            "cuisson": [
                "Disposer les morceaux de poulet dans un plat allant au four avec les oignons émincés.",
                "Arroser avec la marinade restante.",
                "Cuire au four pendant 50 à 60 minutes, en arrosant régulièrement avec le jus de cuisson, jusqu'à ce que le poulet soit doré et bien cuit."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four",
                "barbecue"
            ],
            "accompagnement": "Servir avec une salade de pommes de terre à la bavaroise ou du pain frais.",
            "shortStory": "Le **Poulet Bière Bavarois** est un plat emblématique des régions allemandes, mettant en valeur la richesse des saveurs de la bière. Inspiré par les traditions culinaires de Bavière, où la bière est un ingrédient central, ce plat est souvent préparé lors des festivals et fêtes locales. Il évoque l’ambiance conviviale des brasseries et l’amour des Allemands pour une cuisine généreuse et authentique.",
            "detailURL": "/fiche-recette/111/poulet-biere-oignons-ail/poulet-biere-bavarois.html",
            "time_prepare": "15mn",
            "time_maceration": "4h",
            "time_cooking": "60mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), en raison de l’utilisation de bière et d’ingrédients simples, mais de qualité."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Ce plat n'est pas épicé, mais offre des saveurs riches et réconfortantes grâce à la bière et aux oignons caramélisés."
            },
            "calories": "350 kcal",
            "allergens": [
                "bière",
                "moutarde"
            ],
            "difficulty": {
                "level": 2,
                "description": "Une recette simple mais nécessitant une marinade prolongée et une cuisson attentive pour bien caraméliser les saveurs."
            },
            "equipment_needed": [
                "four",
                "plat-de-cuisson",
                "bol"
            ]
        },
        {
            "id": 147,
            "categorie": "europe",
            "country": "Allemagne",
            "flag": "DE",
            "title": "Poulet au miel et aux épices de Noël",
            "special_marker": {
                "type": "noel",
                "icon": "pere-noel",
                "months": ["01", "12"],
                "coordinates": {
                    "latitude": 0,
                    "longitude": -30
                }
            },
            "subTitle": "Marinade douce et épicée pour un poulet festif.",
            "chicken_piece": [
                "entier",
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": true
            },
            "ingredient": [
                "1 poulet entier ou 1 kg de morceaux de poulet",
                "3 cuillères à soupe de miel",
                "2 cuillères à soupe de moutarde",
                "2 gousses d'ail hachées",
                "1 cuillère à café de cannelle",
                "1 pincée de muscade",
                "1 pincée de gingembre en poudre",
                "2 cuillères à soupe de jus d'orange",
                "sel et poivre",
                "3 cuillères à soupe d'huile d'olive"
            ],
            "ingredient_liste": [
                "poulet",
                "miel",
                "moutarde",
                "ail",
                "cannelle",
                "muscade",
                "gingembre",
                "jus-d-orange",
                "huile",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger tous les ingrédients pour former une marinade homogène.",
                "Badigeonner le poulet avec la marinade, couvrir et laisser mariner au réfrigérateur pendant 2 heures ou toute une nuit."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C.",
                "Placer le poulet dans un plat à rôtir, cuire pendant 45 à 60 minutes en l'arrosant régulièrement avec son jus."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": false,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec des pommes de terre rôties et des légumes racines.",
            "shortStory": "Une **recette festive inspirée des marchés de Noël**, évoquant l'ambiance chaleureuse des fêtes avec des épices parfumées comme la **cannelle** et la **muscade**. Ce poulet, délicatement badigeonné de miel et de jus d'orange, est **parfait pour réunir la famille autour d'un repas convivial et mémorable**.",
            "detailURL": "/fiche-recette/147/miel-epices-noel/poulet-au-miel-epices.html",
            "time_prepare": "20mn",
            "time_maceration": "120mn",
            "time_cooking": "60mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat légèrement élaboré pour une occasion spéciale."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Saveurs chaudes mais pas épicées."
            },
            "calories": "400 kcal",
            "allergens": [
                "moutarde",
                "miel"
            ],
            "difficulty": {
                "level": 2,
                "description": "Nécessite une cuisson au four avec surveillance."
            },
            "equipment_needed": [
                "plat à rôtir",
                "pinceau de cuisine",
                "four"
            ]
        },
        {
            "id": 11,
            "categorie": "afrique",
            "country": "Djibouti",
            "flag": "DJ",
            "title": "Poulet au Yoghourt Épicé",
            "subTitle": "Poulet mariné avec yaourt, ail, gingembre et curcuma, grillé ou rôti.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (cuisses ou blancs)",
                "150g de yaourt nature",
                "2 gousses d'ail écrasées",
                "1 cuillère à soupe de gingembre frais râpé",
                "1 cuillère à café de curcuma en poudre",
                "1 cuillère à café de paprika doux",
                "1 cuillère à soupe d'huile d'olive",
                "sel et poivre",
                "le jus d'1 citron"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "yaourt",
                "ail",
                "gingembre",
                "curcuma",
                "paprika",
                "huile-d-olive",
                "sel",
                "poivre",
                "citron"
            ],
            "preparation": [
                "Dans un bol, mélanger le yaourt, l'ail écrasé, le gingembre râpé, le curcuma, le paprika, le jus de citron, l'huile d'olive, le sel et le poivre.",
                "Enrober les morceaux de poulet avec cette marinade et laisser reposer au réfrigérateur pendant au moins 1 heure."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C ou préparer un grill.",
                "Déposer les morceaux de poulet sur une plaque recouverte de papier cuisson ou sur une grille.",
                "Cuire au four pendant 30 minutes ou griller jusqu'à ce que le poulet soit doré et bien cuit à cœur.",
                "Retourner les morceaux à mi-cuisson pour une cuisson uniforme."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "barbecue": true,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "grill",
                "four"
            ],
            "accompagnement": "Accompagner de riz basmati ou d’une salade fraîche à base de concombre et de menthe.",
            "shortStory": "**Poulet au Yoghourt Épicé** est une recette djiboutienne parfumée où le yaourt nature et les épices comme le curcuma et le gingembre apportent une tendreté et une saveur incomparable au poulet.",
            "detailURL": "/fiche-recette/11/yaourt-curcuma-gingembre/poulet-au-yoghourt-epice.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Une recette simple avec des ingrédients abordables."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Légèrement relevé grâce au gingembre et au paprika."
            },
            "calories": "280 kcal",
            "allergens": [
                "yaourt"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, convient même aux débutants."
            },
            "equipment_needed": [
                "four ou grill",
                "bol",
                "cuillère",
                "plaque de cuisson",
                "papier cuisson"
            ]
        },
        {
            "id": 18,
            "categorie": "afrique-est",
            "country": "Djibouti",
            "flag": "DJ",
            "title": "Poulet Maraq",
            "subTitle": "Poulet mariné avec cardamome, cannelle, oignons et tomates, mijoté.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (cuisses ou pilons)",
                "2 oignons émincés",
                "2 tomates coupées en dés",
                "3 gousses d'ail écrasées",
                "1 cuillère à café de cardamome moulue",
                "1 bâton de cannelle",
                "1 cuillère à café de cumin en poudre",
                "1 cuillère à café de curcuma",
                "3 cuillères à soupe d'huile d'olive",
                "500ml de bouillon de poulet",
                "sel et poivre",
                "coriandre fraîche pour garnir"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "oignons",
                "tomates",
                "ail",
                "cardamome",
                "cannelle",
                "cumin",
                "curcuma",
                "huile-d-olive",
                "bouillon-de-poulet",
                "sel",
                "poivre",
                "coriandre"
            ],
            "preparation": [
                "Dans un bol, mélanger les morceaux de poulet avec l'ail écrasé, la cardamome, le cumin, le curcuma, le sel et le poivre.",
                "Laisser mariner pendant 30 minutes pour bien imprégner les saveurs.",
                "Faire chauffer l'huile d'olive dans une cocotte et faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter les tomates et le bâton de cannelle, puis laisser cuire pendant 5 minutes en remuant régulièrement."
            ],
            "cuisson": [
                "Incorporer les morceaux de poulet marinés dans la cocotte et les faire dorer légèrement.",
                "Ajouter le bouillon de poulet, couvrir et laisser mijoter à feu doux pendant environ 45 minutes, jusqu'à ce que le poulet soit tendre.",
                "Rectifier l'assaisonnement si nécessaire et garnir de coriandre fraîche avant de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz basmati, du pain plat ou une salade simple pour un repas complet.",
            "shortStory": "**Poulet Maraq** est un plat mijoté traditionnel de Djibouti où le poulet est parfumé avec des épices chaudes comme la **cardamome**, la **cannelle** et le **cumin**, offrant une explosion de saveurs riches et réconfortantes.",
            "detailURL": "/fiche-recette/18/cardamome-cannelle-poulet/poulet-maraq.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Une recette économique avec des épices simples et accessibles."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Légèrement relevé grâce aux épices douces comme la cardamome et le cumin."
            },
            "calories": "320 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite un mijotage doux pour des saveurs bien développées."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper",
                "spatule",
                "bol"
            ]
        },
        {
            "id": 9,
            "categorie": "europe",
            "country": "Danemark",
            "flag": "DK",
            "title": "Poulet à la Moutarde Danoise",
            "subTitle": "Poulet mariné avec moutarde douce, miel et herbes comme l’aneth.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (cuisses ou blancs)",
                "3 cuillères à soupe de moutarde douce",
                "2 cuillères à soupe de miel",
                "1 bouquet d'aneth frais haché",
                "1 gousse d'ail écrasée",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "moutarde-douce",
                "miel",
                "aneth",
                "ail",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger la moutarde douce, le miel, l'ail écrasé, l'aneth haché, l'huile d'olive, le sel et le poivre.",
                "Enrober les morceaux de poulet avec cette marinade et laisser reposer au réfrigérateur pendant 1 heure."
            ],
            "cuisson": [
                "Préchauffer le four à 180°C.",
                "Déposer les morceaux de poulet marinés sur une plaque recouverte de papier cuisson.",
                "Cuire pendant 35 minutes jusqu'à ce que le poulet soit doré et cuit à cœur.",
                "Arroser les morceaux avec leur jus de cuisson pour plus de tendreté."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": false,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec des pommes de terre vapeur ou une salade d’aneth frais.",
            "shortStory": "**Poulet à la Moutarde Danoise** est une recette nordique savoureuse où la moutarde douce et le miel créent une sauce délicatement parfumée, relevée par l’aneth frais.",
            "detailURL": "/fiche-recette/9/moutarde-miel-aneth/poulet-a-la-moutarde-danoise.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "35mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Un plat accessible avec des ingrédients simples mais raffinés."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Pas épicé, saveur douce et équilibrée."
            },
            "calories": "320 kcal",
            "allergens": [
                "moutarde",
                "miel"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite peu de préparation."
            },
            "equipment_needed": [
                "four",
                "bol",
                "cuillère",
                "plaque de cuisson",
                "papier cuisson"
            ]
        },
        {
            "id": 10,
            "categorie": "europe",
            "country": "Danemark",
            "flag": "DK",
            "title": "Poulet Rôti au Beurre et Herbes",
            "subTitle": "Poulet mariné avec du beurre, ail, citron et persil, rôti lentement.",
            "chicken_piece": [
                "entier",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": true,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier (environ 1,5 kg)",
                "100g de beurre doux à température ambiante",
                "3 gousses d'ail écrasées",
                "1 citron (jus et zeste)",
                "1 bouquet de persil frais haché",
                "sel et poivre",
                "1 cuillère à soupe d’huile d’olive"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "beurre",
                "ail",
                "citron",
                "persil",
                "sel",
                "poivre",
                "huile-d-olive"
            ],
            "preparation": [
                "Préchauffer le four à 160°C.",
                "Dans un bol, mélanger le beurre mou, l'ail écrasé, le jus et le zeste de citron, le persil haché, le sel et le poivre.",
                "Badigeonner généreusement le poulet avec le mélange beurre-herbes, en le massant pour bien l’enrober.",
                "Laisser mariner pendant 30 minutes pour imprégner les saveurs."
            ],
            "cuisson": [
                "Placer le poulet dans un plat à rôtir, ajouter un filet d’huile d’olive et couvrir légèrement avec du papier aluminium.",
                "Rôtir au four pendant 1h30 à 160°C.",
                "Retirer le papier aluminium, monter la température à 200°C et laisser dorer pendant 15 minutes.",
                "Laisser reposer 10 minutes avant de découper."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": false,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Accompagner de légumes rôtis et de pommes de terre fondantes.",
            "shortStory": "**Poulet Rôti au Beurre et Herbes** est une spécialité danoise où le beurre, l’ail et le citron s’allient pour créer un poulet juteux, tendre et parfumé.",
            "detailURL": "/fiche-recette/10/beurre-ail-citron/poulet-roti-au-beurre-et-herbes.html",
            "time_prepare": "20mn",
            "time_maceration": "30mn",
            "time_cooking": "1h45",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Une recette abordable pour un plat généreux et familial."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Pas épicé, parfait pour toute la famille."
            },
            "calories": "450 kcal",
            "allergens": [
                "beurre",
                "citron"
            ],
            "difficulty": {
                "level": 2,
                "description": "Niveau intermédiaire, nécessite une cuisson précise pour un résultat parfait."
            },
            "equipment_needed": [
                "four",
                "plat à rôtir",
                "bol",
                "cuillère",
                "papier aluminium"
            ]
        },
        {
            "id": 28,
            "categorie": "caraibes",
            "country": "Dominique",
            "flag": "DM",
            "title": "Poulet Créole",
            "subTitle": "Poulet mariné avec citron, ail, thym et piment, mijoté avec des légumes.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (cuisses ou pilons)",
                "1 citron (jus uniquement)",
                "3 gousses d'ail écrasées",
                "1 branche de thym",
                "1 piment rouge (facultatif)",
                "2 carottes coupées en rondelles",
                "1 oignon émincé",
                "2 pommes de terre coupées en cubes",
                "500ml de bouillon de poulet",
                "3 cuillères à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "citron",
                "ail",
                "thym",
                "piment",
                "carottes",
                "oignon",
                "pommes-de-terre",
                "bouillon-de-poulet",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger le jus de citron, l'ail écrasé, le thym, le piment, le sel et le poivre dans un bol.",
                "Ajouter les morceaux de poulet et les enrober de cette marinade. Laisser mariner pendant 30 minutes.",
                "Couper les carottes, les pommes de terre et émincer l'oignon."
            ],
            "cuisson": [
                "Chauffer l'huile d'olive dans une cocotte et faire dorer les morceaux de poulet sur toutes les faces.",
                "Ajouter l'oignon émincé et faire revenir jusqu'à ce qu'il devienne translucide.",
                "Ajouter les carottes et les pommes de terre, puis verser le bouillon de poulet.",
                "Couvrir et laisser mijoter à feu doux pendant 45 minutes, jusqu'à ce que le poulet soit cuit et les légumes tendres.",
                "Rectifier l'assaisonnement si nécessaire et servir chaud."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz créole ou du pain maison pour un repas complet.",
            "shortStory": "**Poulet Créole** est un plat savoureux typique des Caraïbes, où le poulet est mariné avec du citron, de l'ail, du thym et du piment, puis mijoté avec des légumes pour un résultat riche en saveurs et en couleurs.",
            "detailURL": "/fiche-recette/28/citron-ail-thym/poulet-creole.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat simple et rapide à réaliser avec des ingrédients accessibles."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Légèrement épicé grâce au piment, mais pas trop fort."
            },
            "calories": "350 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite une cuisson lente pour bien marier les saveurs."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper",
                "spatule",
                "bol"
            ]
        },
        {
            "id": 39,
            "categorie": "caraibes",
            "country": "Dominique",
            "flag": "DM",
            "title": "Poulet Jerk (variante dominicaine)",
            "subTitle": "Variante dominicaine du poulet jerk caribéen, mariné avec ail, cannelle, piment et noix de muscade.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (cuisses ou pilons)",
                "4 gousses d'ail écrasées",
                "1 cuillère à café de cannelle en poudre",
                "1 cuillère à café de noix de muscade râpée",
                "2 piments rouges (facultatif)",
                "1 citron (jus uniquement)",
                "3 cuillères à soupe de sauce soja",
                "1 cuillère à soupe de sucre brun",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "ail",
                "cannelle",
                "noix-de-muscade",
                "piment",
                "citron",
                "sauce-soja",
                "sucre-brun",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger l'ail écrasé, la cannelle, la noix de muscade, le piment, le jus de citron, la sauce soja, le sucre brun, l'huile d'olive, le sel et le poivre.",
                "Ajouter les morceaux de poulet dans cette marinade et bien les enrober.",
                "Laisser mariner pendant au moins 1 heure, de préférence toute une nuit pour que les saveurs pénètrent bien."
            ],
            "cuisson": [
                "Chauffer une poêle ou un gril à feu moyen-élevé.",
                "Faire cuire les morceaux de poulet pendant 10-12 minutes de chaque côté, ou jusqu'à ce qu'ils soient bien dorés et cuits à cœur.",
                "Vérifier la cuisson du poulet à l'aide d'un thermomètre de cuisine (la température interne doit atteindre 75°C).",
                "Servir chaud, accompagné de riz ou de légumes grillés."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "barbecue": true,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "barbecue",
                "poele"
            ],
            "accompagnement": "Servir avec du riz pilaf, des légumes grillés ou une salade fraîche.",
            "shortStory": "**Poulet Jerk** dominicain est une variante savoureuse et épicée du poulet jerk, où le poulet est mariné dans un mélange d'ail, de cannelle, de piment et de noix de muscade, puis grillé ou poêlé pour un goût caribéen authentique et piquant.",
            "detailURL": "/fiche-recette/39/ail-cannelle-piment/dominique-poulet-jerk.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "25mn",
            "crispys": true,
            "cost": {
                "level": 1,
                "description": "Plat simple à réaliser avec des ingrédients épicés et savoureux."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Assez épicé grâce au piment et aux épices comme la cannelle et la muscade."
            },
            "calories": "350 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite une marinade et une cuisson à température contrôlée."
            },
            "equipment_needed": [
                "grill",
                "poele",
                "couteau",
                "planche à découper",
                "spatule",
                "bol"
            ]
        },
        {
            "id": 108,
            "categorie": "afrique",
            "country": "Algérie",
            "flag": "DZ",
            "title": "Poulet à la Charmoula",
            "subTitle": "Poulet mariné avec coriandre, ail, paprika et citron",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon",
                "blanc"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": true,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier ou 6 morceaux (cuisse, pilon, blanc)",
                "1 bouquet de coriandre fraîche",
                "3 gousses d'ail hachées",
                "1 citron (jus et zeste)",
                "2 cuillères à café de paprika",
                "1 cuillère à café de cumin",
                "1/2 cuillère à café de piment doux",
                "2 cuillères à soupe d'huile d'olive",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "coriandre",
                "ail",
                "citron",
                "paprika",
                "cumin",
                "piment-doux",
                "huile-d'olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger la coriandre hachée, l'ail, le jus et le zeste de citron, le paprika, le cumin, le piment doux, l'huile d'olive, le sel et le poivre.",
                "Badigeonner les morceaux de poulet avec la marinade, en veillant à bien les enrober.",
                "Laisser mariner au réfrigérateur pendant au moins 3 heures, idéalement toute une nuit."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C.",
                "Placer les morceaux de poulet dans un plat de cuisson et verser le reste de la marinade dessus.",
                "Rôtir au four pendant 45 à 50 minutes, en retournant le poulet à mi-cuisson pour qu’il soit uniformément doré."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four",
                "barbecue"
            ],
            "accompagnement": "Servir avec du couscous ou une salade de légumes grillés.",
            "shortStory": "Le **Poulet à la Charmoula** est une recette traditionnelle du Maghreb, et notamment d'Algérie. La charmoula, une marinade à base de coriandre, ail, citron et épices, est utilisée pour parfumer le poulet et le rendre tendre. C'est un plat emblématique des repas familiaux, idéalement accompagné de couscous ou de légumes grillés. La richesse et la simplicité de cette recette témoignent de l’authenticité de la cuisine algérienne.",
            "detailURL": "/fiche-recette/108/poulet-coriandre-ail-citron/poulet-charmoula.html",
            "time_prepare": "20mn",
            "time_maceration": "3h",
            "time_cooking": "50mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), avec des ingrédients simples comme le poulet, les épices et la coriandre, mais qui apportent un goût inimitable au plat."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Le plat est légèrement épicé grâce au paprika et au cumin, avec une touche de fraîcheur apportée par le citron et la coriandre."
            },
            "calories": "380 kcal",
            "allergens": [
                "coriandre",
                "citron"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette relativement facile, nécessitant une bonne préparation de la marinade et une attention à la cuisson pour un poulet tendre et savoureux."
            },
            "equipment_needed": [
                "four",
                "plat-de-cuisson",
                "bol"
            ]
        },
        {
            "id": 109,
            "categorie": "afrique",
            "country": "Algérie",
            "flag": "DZ",
            "title": "Tajine Zitoune",
            "subTitle": "Poulet mariné dans une sauce au citron et olives vertes",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon",
                "blanc"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": true,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier ou 6 morceaux (cuisse, pilon, blanc)",
                "200 g d'olives vertes dénoyautées",
                "2 citrons (jus + zestes)",
                "1 oignon râpé",
                "3 gousses d'ail hachées",
                "1 cuillère à café de curcuma",
                "1/2 cuillère à café de cannelle",
                "2 cuillères à soupe d'huile d'olive",
                "1 cuillère à soupe de persil haché",
                "Sel et poivre",
                "500 ml d'eau ou de bouillon de volaille"
            ],
            "ingredient_liste": [
                "poulet",
                "olives-vertes",
                "citron",
                "oignon",
                "ail",
                "curcuma",
                "cannelle",
                "huile-d'olive",
                "persil",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un grand bol, mélanger l'ail, le jus de citron, le curcuma, la cannelle, le sel, le poivre et l'huile d'olive pour former une marinade.",
                "Enduire les morceaux de poulet avec cette marinade et laisser reposer au réfrigérateur pendant au moins 2 heures.",
                "Faire revenir l'oignon râpé dans un tajine ou une cocotte jusqu'à ce qu'il soit translucide.",
                "Ajouter les morceaux de poulet marinés et les faire dorer légèrement."
            ],
            "cuisson": [
                "Ajouter le bouillon de volaille ou l'eau dans le tajine/cocotte et porter à ébullition.",
                "Réduire le feu et laisser mijoter pendant 30 à 40 minutes, jusqu'à ce que le poulet soit tendre.",
                "Pendant ce temps, faire bouillir les olives dans de l'eau pendant 5 minutes pour enlever l'excès de sel.",
                "Ajouter les olives et les zestes de citron dans le tajine et poursuivre la cuisson 10 minutes supplémentaires.",
                "Parsemer de persil haché avant de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du pain maison ou du couscous pour accompagner la sauce.",
            "shortStory": "Le **Tajine Zitoune** est un classique algérien, souvent servi lors de grandes occasions ou de repas familiaux. Ce plat associe la douceur des épices, l’acidité du **citron** et la richesse des **olives**, créant un équilibre parfait. Il trouve ses origines dans la tradition culinaire du Maghreb, où chaque famille possède sa propre variante. Ce tajine rappelle les saveurs chaleureuses et authentiques de l'Afrique du Nord.",
            "detailURL": "/fiche-recette/109/poulet-olives-vertes-citron/tajine-zitoune.html",
            "time_prepare": "20mn",
            "time_maceration": "2h",
            "time_cooking": "50mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), en raison des olives vertes et des épices. Cependant, ces ingrédients sont courants et abordables dans la cuisine maghrébine."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Ce plat n'est pas épicé, mais équilibré avec des saveurs citronnées et épicées douces."
            },
            "calories": "320 kcal",
            "allergens": [
                "olives",
                "citron"
            ],
            "difficulty": {
                "level": 2,
                "description": "Ce plat est d'une difficulté modérée, nécessitant une gestion attentive des étapes pour conserver l'équilibre des saveurs."
            },
            "equipment_needed": [
                "tajine",
                "cocotte",
                "bol"
            ]
        },
        {
            "id": 47,
            "categorie": "amerique-sud",
            "country": "Équateur",
            "flag": "EC",
            "title": "Seco de Pollo",
            "subTitle": "Poulet mariné avec bière, coriandre, ail et tomate, mijoté.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (cuisses ou blancs)",
                "1 bouteille de bière blonde",
                "1 bouquet de coriandre fraîche hachée",
                "4 gousses d'ail écrasées",
                "2 tomates coupées en dés",
                "1 oignon émincé",
                "1 poivron vert coupé en morceaux",
                "1 cuillère à soupe de cumin",
                "1 cuillère à soupe de paprika",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "bière",
                "coriandre",
                "ail",
                "tomate",
                "oignon",
                "poivron",
                "cumin",
                "paprika",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un grand bol, mélanger la bière, l'ail écrasé, la coriandre, le cumin et le paprika.",
                "Ajouter les morceaux de poulet et laisser mariner pendant 30 minutes à 1 heure.",
                "Pendant ce temps, hacher l'oignon, les tomates et couper le poivron."
            ],
            "cuisson": [
                "Dans une grande casserole, chauffer l'huile d'olive et faire revenir l'oignon jusqu'à ce qu'il devienne translucide.",
                "Ajouter les tomates et le poivron, puis laisser mijoter pendant 5 minutes.",
                "Ajouter le poulet mariné avec la bière et cuire à feu moyen pendant environ 40 minutes, ou jusqu'à ce que le poulet soit bien cuit et tendre.",
                "Rectifier l'assaisonnement avec du sel et du poivre selon votre goût.",
                "Servir chaud avec du riz ou des pommes de terre bouillies."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "mijote",
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc, des pommes de terre bouillies ou des légumes grillés.",
            "shortStory": "**Seco de Pollo** est un plat traditionnel de l'Équateur, où le poulet est mariné dans une sauce à base de bière, coriandre, ail et tomate avant d'être mijoté pour un goût riche et parfumé.",
            "detailURL": "/fiche-recette/47/biere-coriandre-ail/seco-de-pollo.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat nécessitant un peu de préparation et une cuisson longue."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Légèrement épicé, principalement grâce au cumin et au paprika."
            },
            "calories": "350 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Plat de difficulté modérée avec un temps de cuisson prolongé."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper",
                "bol"
            ]
        },
        {
            "id": 55,
            "categorie": "amerique-sud",
            "country": "Équateur",
            "flag": "EC",
            "title": "Pollo Asado Équatorien",
            "subTitle": "Poulet mariné avec citron, ail, cumin et achiote, rôti ou grillé.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier (ou morceaux de poulet)",
                "2 citrons (jus et zeste)",
                "4 gousses d'ail écrasées",
                "1 cuillère à soupe de cumin en poudre",
                "1 cuillère à soupe d'achiote (ou substitut de paprika si l'achiote n'est pas disponible)",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre",
                "1 bouquet de coriandre fraîche"
            ],
            "ingredient_liste": [
                "poulet",
                "citron",
                "ail",
                "cumin",
                "achiote",
                "huile-d-olive",
                "sel",
                "poivre",
                "coriandre"
            ],
            "preparation": [
                "Presser le jus des citrons et râper leur zeste.",
                "Dans un bol, mélanger le jus de citron, le zeste, l'ail écrasé, le cumin, l'achiote, l'huile d'olive, le sel et le poivre.",
                "Ajouter les morceaux de poulet dans la marinade et bien les enrober.",
                "Laisser mariner le poulet au réfrigérateur pendant au moins 1 heure (idéalement 4 à 6 heures pour plus de saveur)."
            ],
            "cuisson": [
                "Préchauffer le four à 180°C ou préparer un barbecue pour la cuisson.",
                "Placer le poulet mariné sur une grille ou une plaque de cuisson, puis cuire au four ou griller sur le barbecue pendant environ 45 minutes à 1 heure, en arrosant régulièrement avec la marinade pour garder la viande tendre.",
                "Le poulet est prêt lorsqu'il est bien doré et que la chair est bien cuite.",
                "Servir avec des légumes grillés ou du riz."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": true,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "rôti",
                "barbecue"
            ],
            "accompagnement": "Servir avec des légumes grillés, du riz ou une salade verte.",
            "shortStory": "**Pollo Asado Équatorien** est un poulet mariné avec des arômes d'ail, de citron, de cumin et d'achiote, **rôti ou grillé** pour un goût parfumé et savoureux qui reflète les saveurs de l'Équateur.",
            "detailURL": "/fiche-recette/55/citron-ail-cumin/pollo-asado-equatorien.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "45mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Plat avec une marinade savoureuse et une cuisson au four ou barbecue."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Légèrement épicé grâce au cumin et à l'achiote."
            },
            "calories": "300 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Plat modéré en difficulté avec un temps de marinade et de cuisson assez long."
            },
            "equipment_needed": [
                "four",
                "grille ou plaque de cuisson",
                "couteau",
                "planche à découper",
                "bol"
            ]
        },
        {
            "id": 67,
            "categorie": "europe",
            "country": "Estonie",
            "flag": "EE",
            "title": "Poulet à la Moutarde et au Miel",
            "subTitle": "Poulet mariné avec miel, moutarde et ail, cuit au four.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de poulet (cuisses ou morceaux)",
                "2 cuillères à soupe de moutarde",
                "2 cuillères à soupe de miel",
                "3 gousses d'ail écrasées",
                "1 cuillère à soupe d'huile d'olive",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "moutarde",
                "miel",
                "ail",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger la moutarde, le miel, l'ail écrasé, l'huile d'olive, le sel et le poivre.",
                "Enrober les morceaux de poulet de cette marinade et laisser reposer pendant 30 minutes."
            ],
            "cuisson": [
                "Préchauffer le four à 180°C.",
                "Disposer les morceaux de poulet sur une plaque de cuisson et cuire au four pendant 40 minutes, en les retournant à mi-cuisson."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": false,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec des légumes rôtis ou une purée de pommes de terre.",
            "shortStory": "**Poulet à la Moutarde et au Miel** est un plat estonien où le poulet est mariné dans un mélange sucré-salé de moutarde et de miel, puis rôti au four pour un goût délicieux et caramélisé.",
            "detailURL": "/fiche-recette/67/moutarde-miel/poulet-a-la-moutarde-et-au-miel.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "40mn",
            "crispys": true,
            "cost": {
                "level": 1,
                "description": "Plat facile à préparer avec des ingrédients simples."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Non épicé."
            },
            "calories": "280 kcal",
            "allergens": [
                "moutarde"
            ],
            "difficulty": {
                "level": 0,
                "description": "Facile, idéal pour un repas rapide."
            },
            "equipment_needed": [
                "four",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 68,
            "categorie": "europe",
            "country": "Estonie",
            "flag": "EE",
            "title": "Poulet aux Herbes Estoniennes",
            "subTitle": "Poulet mariné avec aneth, persil, ail et crème fraîche, rôti.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de poulet (cuisses ou morceaux, blanc)",
                "1 bouquet de persil haché",
                "1 bouquet d'aneth haché",
                "3 gousses d'ail écrasées",
                "200ml de crème fraîche",
                "1 cuillère à soupe d'huile d'olive",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "persil",
                "aneth",
                "ail",
                "creme-fraiche",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger l'aneth, le persil, l'ail écrasé, la crème fraîche, l'huile d'olive, le sel et le poivre.",
                "Enrober les morceaux de poulet de cette marinade et laisser mariner pendant 30 minutes."
            ],
            "cuisson": [
                "Préchauffer le four à 180°C.",
                "Disposer les morceaux de poulet sur une plaque de cuisson et cuire pendant 40 minutes, en les retournant à mi-cuisson."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": false,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec des pommes de terre sautées ou une salade verte.",
            "shortStory": "**Poulet aux Herbes Estoniennes** est un plat simple et délicieux où le poulet est mariné avec des herbes fraîches comme l'**aneth et le persil**, puis rôti au four dans une crème fraîche onctueuse.",
            "detailURL": "/fiche-recette/68/herbes-estoniennes/poulet-aux-herbes-estoniennes.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat simple et rapide à réaliser."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Non épicé."
            },
            "calories": "320 kcal",
            "allergens": [
                "crème fraîche"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, rapide et savoureux."
            },
            "equipment_needed": [
                "four",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 40,
            "categorie": "afrique",
            "country": "Egypte",
            "flag": "EG",
            "title": "Feteer au Poulet Épicé",
            "subTitle": "Poulet mariné avec ail, citron, cumin et coriandre, grillé.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (cuisses ou blancs)",
                "4 gousses d'ail écrasées",
                "1 citron (jus uniquement)",
                "1 cuillère à café de cumin",
                "1 cuillère à café de coriandre en poudre",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "ail",
                "citron",
                "cumin",
                "coriandre",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger l'ail écrasé, le jus de citron, le cumin, la coriandre, l'huile d'olive, le sel et le poivre.",
                "Ajouter les morceaux de poulet et bien les enrober de marinade.",
                "Laisser mariner pendant au moins 30 minutes."
            ],
            "cuisson": [
                "Préchauffer le gril ou la poêle à feu moyen-élevé.",
                "Griller ou poêler les morceaux de poulet pendant environ 10-12 minutes de chaque côté jusqu'à ce qu'ils soient bien dorés et cuits à cœur.",
                "Servir avec des légumes grillés ou du riz."
            ],
            "cooking_methods": {
                "best": "grill",
                "barbecue": true,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "grill",
                "poele"
            ],
            "accompagnement": "Servir avec des légumes grillés ou du riz basmati.",
            "shortStory": "**Feteer au Poulet Épicé** est un plat égyptien où le poulet est mariné avec des épices comme le cumin et la coriandre, puis grillé pour un résultat savoureux et parfumé.",
            "detailURL": "/fiche-recette/40/ail-cumin-coriandre/feteer-au-poulet-epice.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "20mn",
            "crispys": true,
            "cost": {
                "level": 1,
                "description": "Plat simple et rapide à réaliser."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Légèrement épicé grâce au cumin et à la coriandre."
            },
            "calories": "300 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite une marinade et une cuisson au gril ou à la poêle."
            },
            "equipment_needed": [
                "grill",
                "poele",
                "couteau",
                "planche à découper",
                "bol"
            ]
        },
        {
            "id": 41,
            "categorie": "afrique",
            "country": "Egypte",
            "flag": "EG",
            "title": "Poulet Fakhfakhina",
            "subTitle": "Poulet mariné dans un mélange d'épices (paprika, ail, gingembre), mijoté avec légumes et sauce tomate.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (cuisses ou blancs)",
                "4 gousses d'ail écrasées",
                "1 cuillère à soupe de paprika",
                "1 cuillère à soupe de gingembre frais râpé",
                "1 oignon émincé",
                "3 tomates pelées et concassées",
                "1 cuillère à soupe de pâte de tomate",
                "1 cuillère à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "ail",
                "paprika",
                "gingembre",
                "oignon",
                "tomates",
                "pate-de-tomate",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger l'ail écrasé, le paprika, le gingembre, l'huile d'olive, le sel et le poivre.",
                "Ajouter les morceaux de poulet et bien les enrober de marinade.",
                "Laisser mariner pendant au moins 1 heure."
            ],
            "cuisson": [
                "Dans une grande poêle, faire chauffer un peu d'huile et faire revenir l'oignon jusqu'à ce qu'il soit translucide.",
                "Ajouter les morceaux de poulet et les faire dorer sur toutes les faces.",
                "Ajouter les tomates concassées, la pâte de tomate et un peu d'eau pour couvrir le poulet.",
                "Laisser mijoter pendant 25-30 minutes jusqu'à ce que le poulet soit bien cuit et la sauce épaissie.",
                "Servir chaud avec du riz ou du pain égyptien."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "mijote",
                "poele"
            ],
            "accompagnement": "Servir avec du riz basmati ou du pain pita pour un repas complet.",
            "shortStory": "**Poulet Fakhfakhina** est un plat **égyptien mijoté** où le poulet est mariné avec un mélange d'épices et cuisiné avec des tomates, des oignons et une sauce tomate riche pour un résultat savoureux et épicé.",
            "detailURL": "/fiche-recette/41/paprika-gingembre-tomates/poulet-fakhfakhina.html",
            "time_prepare": "20mn",
            "time_maceration": "1h",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat de difficulté modérée, nécessite du temps de cuisson."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Modérément épicé grâce au paprika et au gingembre."
            },
            "calories": "400 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Modéré, avec des étapes de mijotage pour une cuisson lente et savoureuse."
            },
            "equipment_needed": [
                "poele",
                "cocotte",
                "couteau",
                "planche à découper",
                "bol"
            ]
        },
        {
            "id": 54,
            "categorie": "moyen-orient",
            "country": "Egypte",
            "flag": "EG",
            "title": "Poulet au Citron et aux Épices",
            "subTitle": "Poulet tendre et juteux avec une touche de citron",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "1 citron",
                "2 cuillères à soupe de mélange d'épices (cumin, coriandre, paprika)",
                "2 cuillères à soupe d'huile d'olive",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "citron",
                "mélange-d'épices",
                "huile-d'olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Presser le citron et mélanger le jus avec les épices et l'huile d'olive.",
                "Badigeonner les cuisses de poulet avec la marinade.",
                "Laisser mariner pendant au moins 1 heure."
            ],
            "cuisson": [
                "Griller ou cuire à la plancha jusqu'à ce que le poulet soit bien cuit."
            ],
            "cooking_methods": {
                "best": "grill",
                "plancha": true,
                "friture": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "grill",
                "plancha"
            ],
            "accompagnement": "Servir avec du riz basmati.",
            "shortStory": "Le **Poulet au Citron** et aux **Épices** est un plat savoureux et rafraîchissant, mettant en valeur des saveurs vives grâce au citron et aux épices. Ce plat est parfait pour une soirée estivale et accompagne merveilleusement le riz basmati.",
            "detailURL": "/fiche-recette/54/poulet-citron-epices/poulet-citron-epices.html",
            "time_prepare": "10mn",
            "time_maceration": "1h",
            "time_cooking": "25mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est moyen (niveau 2) en raison des ingrédients utilisés, principalement les cuisses de poulet, le citron et l'huile d'olive. Bien que les épices soient courantes, l'utilisation de produits de qualité et frais peut légèrement augmenter le coût par rapport à d'autres plats simples."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette présente des saveurs vives et rafraîchissantes, idéales pour l'été, tout en étant relativement facile à préparer, mais avec une marinade qui nécessite un peu de patience pour en maximiser la saveur."
            },
            "calories": "292 kcal",
            "allergens": [
                "gluten (éventuellement dans le mélange d'épices)"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté modérée, nécessitant une gestion correcte de la marinade et de la cuisson pour obtenir un poulet tendre et bien cuit."
            },
            "equipment_needed": [
                "poêle",
                "grill",
                "bol"
            ]
        },
        {
            "id": 57,
            "categorie": "afrique",
            "country": "Érythrée",
            "flag": "ER",
            "title": "Zigni Doro",
            "subTitle": "Poulet mariné avec berbere maison, ail et citron, mijoté dans une sauce tomate épicée.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de poulet (cuisses ou morceaux)",
                "2 cuillères à soupe de berbere maison (voir recette ci-dessous)",
                "4 gousses d'ail écrasées",
                "1 citron (jus et zeste)",
                "2 tomates coupées en dés",
                "1 oignon émincé",
                "1 cuillère à soupe d'huile d'olive",
                "1 cuillère à soupe de concentré de tomate",
                "sel et poivre",
                "1 bouquet de coriandre fraîche (facultatif)"
            ],
            "ingredient_liste": [
                "poulet",
                "berbere",
                "ail",
                "citron",
                "tomate",
                "oignon",
                "huile-d-olive",
                "concentre-de-tomate",
                "sel",
                "poivre",
                "coriandre"
            ],
            "preparation": [
                "Préparer le mélange d'épices **berbere** en combinant les ingrédients suivants :",
                " - 1 cuillère à soupe de paprika",
                " - 1 cuillère à soupe de piment en poudre",
                " - 1 cuillère à café de cumin moulu",
                " - 1 cuillère à café de coriandre moulue",
                " - 1 cuillère à café de curcuma",
                " - 1 cuillère à café de cannelle moulue",
                " - 1 cuillère à café de gingembre moulu",
                " - 1/2 cuillère à café de clous de girofle moulus",
                " - 1/2 cuillère à café de cardamome moulue",
                "Dans un bol, mélanger le jus et le zeste de citron avec l'ail écrasé et 2 cuillères à soupe de berbere.",
                "Ajouter les morceaux de poulet dans la marinade et bien les enrober.",
                "Laisser mariner le poulet au réfrigérateur pendant au moins 1 heure.",
                "Pendant ce temps, hacher l'oignon et les tomates."
            ],
            "cuisson": [
                "Dans une grande casserole, chauffer l'huile d'olive et faire revenir l'oignon jusqu'à ce qu'il devienne translucide.",
                "Ajouter les tomates coupées en dés et le concentré de tomate, et laisser mijoter pendant 10 minutes.",
                "Ajouter les morceaux de poulet marinés dans la sauce et cuire à feu moyen pendant environ 40 minutes, ou jusqu'à ce que le poulet soit bien cuit.",
                "Ajouter du sel et du poivre au goût.",
                "Si vous le souhaitez, garnir de coriandre fraîche avant de servir.",
                "Servir avec du riz ou du pain injera."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "mijote",
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc ou du pain injera pour un repas traditionnel érythréen.",
            "shortStory": "**Zigni Doro** est un plat traditionnel érythréen où le poulet est mariné dans un mélange d'épices **berbere** maison, de **citron** et d'**ail**, puis mijoté dans une sauce tomate épicée pour un goût riche et savoureux.",
            "detailURL": "/fiche-recette/57/berbere-ail-citron/zigni-doro.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat épicé et savoureux, un peu de temps est nécessaire pour la préparation et la cuisson."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Plat épicé grâce au berbere, avec une chaleur modérée à élevée."
            },
            "calories": "400 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Plat modéré en difficulté, nécessite une cuisson lente pour obtenir des saveurs profondes."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper",
                "bol"
            ]
        },
        {
            "id": 63,
            "categorie": "afrique",
            "country": "Érythrée",
            "flag": "ER",
            "title": "Poulet au Lait de Coco Épicé",
            "subTitle": "Poulet mariné avec ail, gingembre, citron et piment, mijoté au lait de coco.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de poulet (cuisses ou blancs)",
                "4 gousses d'ail écrasées",
                "1 morceau de gingembre frais (environ 2 cm), râpé",
                "1 citron (jus et zeste)",
                "1 à 2 piments frais (selon le niveau de piquant désiré)",
                "1 boîte de lait de coco (400 ml)",
                "2 cuillères à soupe d'huile d'olive",
                "1 oignon émincé",
                "1 tomate coupée en dés",
                "1 cuillère à soupe de concentré de tomate",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "ail",
                "gingembre",
                "citron",
                "piment",
                "lait-de-coco",
                "huile-d-olive",
                "oignon",
                "tomate",
                "concentre-de-tomate",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le jus et le zeste de citron avec l'ail écrasé, le gingembre râpé et les piments hachés.",
                "Ajouter les morceaux de poulet et bien les enrober de cette marinade.",
                "Laisser mariner au réfrigérateur pendant au moins 1 heure.",
                "Pendant ce temps, émincer l'oignon et couper la tomate en dés."
            ],
            "cuisson": [
                "Dans une grande casserole, chauffer l'huile d'olive et faire revenir l'oignon jusqu'à ce qu'il devienne translucide.",
                "Ajouter la tomate coupée en dés et le concentré de tomate, et laisser mijoter pendant 5 minutes.",
                "Ajouter les morceaux de poulet marinés dans la casserole et les faire dorer légèrement.",
                "Verser le lait de coco dans la casserole, saler et poivrer au goût.",
                "Laisser mijoter à feu doux pendant 30 à 40 minutes, jusqu'à ce que le poulet soit tendre et que la sauce épaississe légèrement.",
                "Rectifier l'assaisonnement si nécessaire avant de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "mijote",
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz basmati ou du pain traditionnel pour accompagner cette sauce riche et crémeuse.",
            "shortStory": "**Poulet au Lait de Coco Épicé** est un plat érythréen où le poulet est mariné avec **ail**, **gingembre**, **citron** et **piment**, puis mijoté dans une sauce crémeuse au **lait de coco**, offrant une richesse de saveurs et une chaleur modérée.",
            "detailURL": "/fiche-recette/63/ail-gingembre-citron/poulet-au-lait-de-coco-epice.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat savoureux et épicé, nécessite un peu de préparation et de cuisson lente."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Épicé grâce aux piments et au gingembre, avec une chaleur modérée."
            },
            "calories": "350 kcal",
            "allergens": [
                "coco"
            ],
            "difficulty": {
                "level": 2,
                "description": "Plat modéré en difficulté, demande un peu de temps pour la marinade et la cuisson."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper",
                "bol"
            ]
        },
        {
            "id": 50,
            "categorie": "europe",
            "country": "Espagne",
            "flag": "ES",
            "title": "Poulet à la Plancha aux Épices",
            "subTitle": "Poulet épicé cuit à la plancha",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "2 cuillères à café de paprika",
                "1 cuillère à café de cumin",
                "Sel et poivre",
                "2 cuillères à soupe d'huile d'olive"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "paprika",
                "cumin",
                "sel-et-poivre",
                "huile-d-olive"
            ],
            "preparation": [
                "Mélanger les épices avec l'huile d'olive.",
                "Badigeonner les blancs de poulet avec le mélange d'épices."
            ],
            "cuisson": [
                "Cuire à la plancha jusqu'à ce que les blancs de poulet soient dorés et bien cuits."
            ],
            "cooking_methods": {
                "best": "plancha",
                "grill": false,
                "friture": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "plancha"
            ],
            "accompagnement": "Servir avec une salsa de tomates.",
            "shortStory": "Ce **poulet à la plancha** aux épices est un plat **savoureux d'Espagne**, parfait pour les journées ensoleillées.",
            "detailURL": "/fiche-recette/50/poulet-sspagne/poulet-espagne.html",
            "time_prepare": "10mn",
            "time_maceration": "0mn",
            "time_cooking": "15mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Recette économique grâce à des ingrédients simples comme le poulet et les épices."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Plat doux avec une légère touche d'épices."
            },
            "calories": "250 Kcal",
            "allergens": [
                "huile-d-olive"
            ],
            "difficulty": {
                "level": 1,
                "description": "Recette facile et rapide, adaptée aux débutants."
            },
            "equipment_needed": [
                "plancha",
                "pinceau de cuisine"
            ]
        },
        {
            "id": 64,
            "categorie": "europe",
            "country": "Espagne",
            "flag": "ES",
            "title": "Poulet à l'Ail (Pollo al Ajillo)",
            "subTitle": "Poulet mariné avec ail, vin blanc, huile d'olive et herbes, mijoté.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de poulet (cuisses ou morceaux)",
                "6 gousses d'ail émincées",
                "150 ml de vin blanc",
                "3 cuillères à soupe d'huile d'olive",
                "1 branche de thym",
                "1 feuille de laurier",
                "1 cuillère à soupe de paprika doux",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "ail",
                "vin-blanc",
                "huile-d-olive",
                "thym",
                "laurier",
                "paprika",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le vin blanc, l'ail émincé, le paprika, le thym et la feuille de laurier.",
                "Ajouter les morceaux de poulet dans la marinade et bien les enrober.",
                "Laisser mariner au réfrigérateur pendant au moins 30 minutes."
            ],
            "cuisson": [
                "Dans une grande poêle, chauffer l'huile d'olive à feu moyen.",
                "Ajouter les morceaux de poulet et les faire dorer de chaque côté pendant 5 à 7 minutes.",
                "Verser la marinade dans la poêle et ajouter le sel et le poivre.",
                "Laisser mijoter à feu doux pendant 30 minutes, jusqu'à ce que le poulet soit bien cuit et tendre.",
                "Retirer les morceaux de poulet et laisser réduire la sauce pendant 5 minutes avant de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "mijote",
                "poele"
            ],
            "accompagnement": "Servir avec des pommes de terre rôties, du riz ou une salade verte.",
            "shortStory": "**Poulet à l'Ail (Pollo al Ajillo)** est un classique de la cuisine espagnole où le poulet est mariné avec **ail**, **vin blanc**, **huile d'olive** et des herbes comme le **thym** et le **laurier**, puis mijoté lentement pour obtenir un plat savoureux et parfumé.",
            "detailURL": "/fiche-recette/64/ail-vin-blanc-herbes/poulet-a-l-ail-pollo-al-ajillo.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat simple à préparer avec des ingrédients courants."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Légèrement épicé grâce au paprika."
            },
            "calories": "300 kcal",
            "allergens": [
                "gluten"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile à réaliser, nécessite une cuisson douce pour tendre le poulet."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 66,
            "categorie": "europe",
            "country": "Espagne",
            "flag": "ES",
            "title": "Poulet à la Catalane",
            "subTitle": "Poulet mariné avec tomate, ail, amandes et cannelle, mijoté.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de poulet (cuisses ou morceaux)",
                "2 tomates mûres, coupées en dés",
                "4 gousses d'ail écrasées",
                "50g d'amandes émondées",
                "1 bâton de cannelle",
                "2 cuillères à soupe d'huile d'olive",
                "1 oignon émincé",
                "1 cuillère à soupe de concentré de tomate",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "tomate",
                "ail",
                "amandes",
                "cannelle",
                "huile-d-olive",
                "oignon",
                "concentre-de-tomate",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger les tomates coupées en dés, l'ail écrasé, les amandes concassées, et la cannelle.",
                "Ajouter les morceaux de poulet et bien les enrober de la marinade.",
                "Laisser mariner pendant au moins 30 minutes au réfrigérateur."
            ],
            "cuisson": [
                "Dans une grande poêle ou une cocotte, chauffer l'huile d'olive à feu moyen.",
                "Ajouter l'oignon émincé et le faire revenir jusqu'à ce qu'il devienne translucide.",
                "Ajouter les morceaux de poulet marinés et les faire dorer de chaque côté.",
                "Incorporer le concentré de tomate, puis verser la marinade avec les tomates, l'ail, les amandes et la cannelle.",
                "Couvrir et laisser mijoter pendant 40 minutes à feu doux, jusqu'à ce que le poulet soit tendre et bien cuit.",
                "Retirer le bâton de cannelle avant de servir."
            ],
            "cooking_methods": {
                "best": "mijote",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "mijote",
                "cocotte",
                "poele"
            ],
            "accompagnement": "Servir avec du riz basmati, des pommes de terre rôties, ou une salade verte pour un repas complet.",
            "shortStory": "**Poulet à la Catalane** est un plat traditionnel de la cuisine espagnole où le poulet est mariné avec **tomate**, **ail**, **amandes**, et une touche de **cannelle** avant d'être mijoté lentement, offrant une explosion de saveurs sucrées et salées.",
            "detailURL": "/fiche-recette/66/tomate-ail-amandes/poulet-a-la-catalane.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat savoureux et légèrement sophistiqué, demande un peu de préparation et de temps de cuisson."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Peu épicé, les saveurs viennent principalement de l'ail et de la cannelle."
            },
            "calories": "350 kcal",
            "allergens": [
                "amandes"
            ],
            "difficulty": {
                "level": 2,
                "description": "Plat modéré en difficulté, nécessite un peu de préparation et de mijotage."
            },
            "equipment_needed": [
                "poêle",
                "cocotte",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 71,
            "categorie": "afrique",
            "country": "Éthiopie",
            "flag": "ET",
            "title": "Doro Wat",
            "subTitle": "Poulet mariné avec citron, ail, gingembre et berbere, mijoté dans une sauce épicée.",
            "chicken_piece": [
                "morceaux",
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet",
                "2 cuillères à soupe de berbere",
                "1 citron (jus uniquement)",
                "4 gousses d'ail écrasées",
                "1 morceau de gingembre frais râpé",
                "1 oignon haché",
                "2 tomates hachées",
                "1 cuillère à soupe d'huile",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "berbere",
                "citron",
                "ail",
                "gingembre",
                "oignon",
                "tomate",
                "huile",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mariner le poulet avec le jus de citron, l'ail, le gingembre et le berbere pendant 1 heure.",
                "Faire revenir l'oignon haché dans l'huile chaude jusqu'à ce qu'il devienne translucide.",
                "Ajouter les tomates et cuire jusqu'à ce qu'elles ramollissent."
            ],
            "cuisson": [
                "Ajouter le poulet mariné à la sauce et cuire à feu doux pendant 45 minutes jusqu'à ce que le poulet soit bien cuit.",
                "Ajouter de l'eau si nécessaire pour obtenir une sauce épaisse."
            ],
            "cooking_methods": {
                "best": "mijoté",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "mijoté"
            ],
            "accompagnement": "Servir avec du riz blanc ou un pain éthiopien traditionnel.",
            "shortStory": "**Doro Wat** est un plat éthiopien épicé où le **poulet est mijoté** dans une sauce riche au berbere, aux tomates et aux épices, offrant une expérience savoureuse et chaude.",
            "detailURL": "/fiche-recette/71/berbere-citron-gingembre/doro-wat-poulet-ethiopien.html",
            "time_prepare": "30mn",
            "time_maceration": "1h",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat moyennement complexe nécessitant une préparation soignée."
            },
            "images": [],
            "hot": {
                "level": 4,
                "description": "Fortement épicé avec du berbere et du gingembre."
            },
            "calories": "350 kcal",
            "allergens": [
                "gingembre",
                "tomate"
            ],
            "difficulty": {
                "level": 2,
                "description": "Modéré, nécessite de bien gérer la cuisson du poulet et des épices."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper",
                "cuillère en bois"
            ]
        },
        {
            "id": 72,
            "categorie": "afrique",
            "country": "Éthiopie",
            "flag": "ET",
            "title": "Poulet Tibs",
            "subTitle": "Poulet mariné avec ail, gingembre, piment et épices locales, sauté.",
            "chicken_piece": [
                "morceaux",
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet",
                "2 gousses d'ail écrasées",
                "1 morceau de gingembre frais râpé",
                "1 piment rouge",
                "1 cuillère à soupe de paprika",
                "2 cuillères à soupe d'huile",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "ail",
                "gingembre",
                "piment",
                "paprika",
                "huile",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mariner le poulet avec l'ail, le gingembre, le piment et le paprika pendant 30 minutes.",
                "Chauffer l'huile dans une poêle à feu moyen."
            ],
            "cuisson": [
                "Ajouter le poulet mariné à la poêle et cuire à feu moyen pendant 20 minutes, en remuant régulièrement jusqu'à ce que le poulet soit doré et cuit."
            ],
            "cooking_methods": {
                "best": "sauté",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "sauté"
            ],
            "accompagnement": "Servir avec du riz ou des légumes sautés.",
            "shortStory": "**Poulet Tibs** est un plat éthiopien où le poulet est sauté avec des épices locales, de l'ail et du gingembre, pour un goût savoureux et épicé.",
            "detailURL": "/fiche-recette/72/gingembre-paprika-piment/poulet-tibs-ethiopie.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "20mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat simple et rapide à préparer."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Épicé grâce au piment et au paprika."
            },
            "calories": "300 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite seulement de bien gérer la cuisson du poulet."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 62,
            "categorie": "oceanie",
            "country": "Fidji",
            "flag": "FJ",
            "title": "Poulet à l'Ananas",
            "subTitle": "Poulet mariné dans une sauce sucrée-salée à l'ananas",
            "chicken_piece": [
                "entier",
                "manchon"
            ],
            "piece": {
                "best": "manchon",
                "entier": true,
                "aile": true,
                "blanc": true,
                "pilon": true,
                "manchon": true,
                "cuisse": true
            },
            "ingredient": [
                "6 manchons de poulet",
                "150 g d'ananas frais ou en conserve",
                "2 cuillères à soupe de sauce soja",
                "1 cuillère à soupe de sucre brun",
                "1 gousse d'ail émincée",
                "1 cuillère à soupe de vinaigre de riz",
                "Sel, poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "ananas",
                "sauce-soja",
                "sucre-brun",
                "ail",
                "vinaigre-riz",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélangez l'ananas, la sauce soja, le sucre brun, l'ail et le vinaigre dans un bol.",
                "Ajoutez les manchons de poulet et laissez mariner pendant au moins 2 heures."
            ],
            "cuisson": [
                "Faites griller ou cuire au four jusqu'à ce que le poulet soit doré et caramélisé."
            ],
            "cooking_methods": {
                "best": "grill",
                "plancha": true,
                "four": true,
                "friture": false,
                "grill": true
            },
            "mode_cuisson": [
                "grill",
                "four"
            ],
            "accompagnement": "Servez avec du riz basmati ou des légumes sautés.",
            "shortStory": "Cette **recette fidjienne** combine des **saveurs sucrées et salées** avec une marinade à l'ananas, créant une explosion tropicale dans chaque bouchée.",
            "detailURL": "/fiche-recette/62/ananas-sucre-brun-sauce-soja-vinaigre-riz/Poulet-Ananas.html",
            "time_prepare": "15mn",
            "time_maceration": "3h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette a un coût modéré (niveau 2), grâce à l'utilisation d'ingrédients frais comme l'ananas et les épices."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Plat doux avec une touche sucrée-salée, sans épices fortes."
            },
            "calories": "320 Kcal",
            "allergens": [
                "sauce-soja"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté modérée, idéale pour les cuisiniers intermédiaires."
            },
            "equipment_needed": [
                "grill",
                "plat allant au four"
            ]
        },
        {
            "id": 76,
            "categorie": "oceanie",
            "country": "Fidji",
            "flag": "FJ",
            "title": "Poulet Lovo",
            "subTitle": "Poulet mariné avec citron, ail et herbes, cuit dans un four traditionnel souterrain (lovo).",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet",
                "1 citron (jus)",
                "2 gousses d'ail écrasées",
                "1 bouquet de thym",
                "1 bouquet de romarin",
                "2 cuillères à soupe d'huile d'olive",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "citron",
                "ail",
                "thym",
                "romarin",
                "huile d'olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Presser le citron et mélanger le jus avec l'ail écrasé, l'huile d'olive, le thym et le romarin.",
                "Placer les morceaux de poulet dans un grand plat et les badigeonner avec cette marinade.",
                "Couvrir et laisser mariner pendant 1 heure."
            ],
            "cuisson": [
                "Faire chauffer un four traditionnel souterrain (lovo) ou un four à température modérée (200°C).",
                "Placer les morceaux de poulet dans le four et cuire pendant 40-45 minutes, jusqu'à ce qu'ils soient tendres et dorés.",
                "Servir avec des légumes grillés ou du riz."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "accompagnement": "Accompagner de riz, de légumes grillés ou d'une salade fraîche pour un plat complet.",
            "shortStory": "**Poulet Lovo** est un plat fidjien traditionnel où le poulet est mariné au citron, à l'ail et aux herbes, puis cuit lentement dans un four souterrain, offrant une texture tendre et des arômes intenses.",
            "detailURL": "/fiche-recette/76/citron-romarin-thym-ail-huile-d-olive/poulet-lovo.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "45mn",
            "cost": {
                "level": 2,
                "description": "Plat nécessitant un peu plus de préparation et de temps de cuisson."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Pas épicé, un plat aux saveurs naturelles."
            },
            "calories": "300 kcal",
            "allergens": [
                "huile d'olive"
            ],
            "difficulty": {
                "level": 2,
                "description": "Modéré, avec un temps de cuisson long mais simple à préparer."
            },
            "equipment_needed": "four traditionnel, couteau, planche à découper, pinceau de cuisine"
        },
        {
            "id": 73,
            "categorie": "europe",
            "country": "Îles Féroé",
            "flag": "FO",
            "title": "Poulet au Fromage et au Mouton",
            "subTitle": "Poulet mariné avec herbes locales et lait fermenté, cuit avec du fromage.",
            "chicken_piece": [
                "morceaux",
                "cuisse"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet",
                "200g de fromage (de type cheddar)",
                "100g de lait fermenté",
                "Herbes locales (thym, romarin)",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "fromage",
                "lait fermenté",
                "herbes",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mariner le poulet avec le lait fermenté, les herbes, le sel et le poivre pendant 30 minutes.",
                "Râper le fromage."
            ],
            "cuisson": [
                "Placer le poulet mariné dans une poêle et cuire à feu moyen pendant 25 minutes.",
                "Ajouter le fromage râpé sur le poulet et cuire encore 10 minutes jusqu'à ce que le fromage soit fondu."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec une purée de pommes de terre ou des légumes grillés.",
            "shortStory": "**Poulet au Fromage et au Mouton** est un plat des Îles Féroé où le poulet est cuit avec du fromage fondant et accompagné de lait fermenté, pour une expérience unique.",
            "detailURL": "/fiche-recette/73/lait-fermente-herbes/poulet-au-fromage-mouton-iles-feroe.html",
            "time_prepare": "30mn",
            "time_maceration": "30mn",
            "time_cooking": "35mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat modéré avec une cuisson simple."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Non épicé."
            },
            "calories": "450 kcal",
            "allergens": [
                "lactose"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite seulement une cuisson soigneuse du fromage."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 74,
            "categorie": "europe",
            "country": "Îles Féroé",
            "flag": "FO",
            "title": "Poulet au Carvi",
            "subTitle": "Poulet mariné dans une sauce à base de graines de carvi, citron et huile.",
            "chicken_piece": [
                "morceaux",
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet",
                "1 cuillère à soupe de graines de carvi",
                "1 citron (jus)",
                "3 cuillères à soupe d'huile",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "carvi",
                "citron",
                "huile",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mariner le poulet avec les graines de carvi, le jus de citron, l'huile, le sel et le poivre pendant 30 minutes."
            ],
            "cuisson": [
                "Faire chauffer une poêle à feu moyen et cuire le poulet mariné pendant 25 minutes jusqu'à ce qu'il soit bien doré."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec des légumes grillés ou un riz basmati.",
            "shortStory": "**Poulet au Carvi** est un plat simple des Îles Féroé où le poulet est mariné dans une sauce parfumée au carvi et au citron.",
            "detailURL": "/fiche-recette/74/carvi-citron/poulet-carvi-iles-feroe.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "25mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat simple et rapide à préparer."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Non épicé."
            },
            "calories": "320 kcal",
            "allergens": [],
            "difficulty": {
                "level": 0,
                "description": "Facile, rapide et savoureux."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 4,
            "categorie": "europe",
            "country": "France",
            "flag": "FR",
            "title": "Coq au Vin",
            "subTitle": "Poulet mijoté au vin rouge",
            "chicken_piece": [
                "entier"
            ],
            "piece": {
                "best": "entier",
                "poulet_entier": true,
                "blanc": false,
                "cuisse": false,
                "aile": false,
                "pilon": false
            },
            "ingredient": [
                "1 poulet entier, coupé en morceaux",
                "150g de lardons fumés",
                "200g de champignons de Paris, tranchés",
                "2 oignons, émincés",
                "4 gousses d'ail, émincées",
                "2 carottes, coupées en rondelles",
                "2 branches de céleri, coupées en dés",
                "1 bouteille de vin rouge",
                "500ml de bouillon de poulet",
                "2 cuillères à soupe de farine",
                "2 cuillères à soupe de beurre",
                "2 cuillères à soupe d'huile d'olive",
                "1 bouquet garni (thym, laurier, persil)",
                "Sel et poivre, au goût"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "lardons-fumes",
                "champignons-de-paris",
                "oignons",
                "ail",
                "carottes",
                "celeri",
                "vin-rouge",
                "bouillon-de-poulet",
                "farine",
                "beurre",
                "huile-dolive",
                "thym",
                "laurier",
                "persil",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans une grande cocotte, faire chauffer l'huile d'olive et le beurre à feu moyen-élevé. Ajouter les morceaux de poulet et les lardons, et les faire dorer de tous les côtés.",
                "Retirer le poulet et les lardons de la cocotte et réserver. Ajouter les oignons, l'ail, les carottes et le céleri dans la cocotte, et les faire revenir jusqu'à ce qu'ils soient tendres.",
                "Remettre le poulet et les lardons dans la cocotte. Saupoudrer de farine et bien mélanger.",
                "Verser le vin rouge dans la cocotte et porter à ébullition. Ajouter le bouillon de poulet et le bouquet garni. Assaisonner avec du sel et du poivre, au goût.",
                "Couvrir la cocotte et laisser mijoter à feu doux pendant environ 1h30 à 2 heures, ou jusqu'à ce que le poulet soit tendre et le liquide réduit.",
                "Environ 30 minutes avant la fin de la cuisson, ajouter les champignons tranchés dans la cocotte.",
                "Servir le coq au vin chaud, accompagné de pommes de terre vapeur ou de purée de pommes de terre."
            ],
            "cuisson": [
                "Faire mijoter le coq au vin à feu doux dans une cocotte pendant environ 1h30 à 2 heures."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "plancha": false,
                "four": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Le coq au vin est traditionnellement servi avec des pommes de terre vapeur, de la purée de pommes de terre ou des pâtes fraîches, ainsi qu'une salade verte.",
            "shortStory": "Le **coq au vin** est un plat classique de la cuisine française, apprécié pour sa **viande tendre** et son délicieux sauce au vin rouge. Cette recette traditionnelle est parfaite pour les repas en famille ou les occasions spéciales.",
            "detailURL": "/fiche-recette/4/coq-au-vin-poulet-lardons-champignons-vin-rouge/coq-au-vin.html",
            "time_prepare": "30mn",
            "time_cooking": "2h",
            "time_maceration": "0mn",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Le coût de cette recette est relativement élevé (niveau 3) en raison de l'utilisation de vin rouge et de lardons fumés. Cependant, c'est une recette idéale pour les repas de fête ou les occasions spéciales."
            },
            "hot": {
                "level": 1,
                "description": "Le coq au vin est une recette à faible épice, le vin rouge et les herbes du bouquet garni apportent une saveur riche et parfumée sans piquant."
            },
            "allergens": [
                "lardons",
                "alcool"
            ],
            "storage_instructions": "Conserver dans un récipient hermétique au réfrigérateur pendant 2 à 3 jours. Peut être réchauffé à feu doux ou au micro-ondes.",
            "difficulty": {
                "level": 3,
                "description": "Recette de difficulté élevée (niveau 4), nécessitant une attention particulière lors de la cuisson pour éviter que le vin ne brûle et pour que la viande soit parfaitement cuite."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper",
                "loupe"
            ],
            "images": [],
            "calories": "450 kcal"
        },
        {
            "id": 8,
            "categorie": "europe",
            "country": "France",
            "flag": "FR",
            "title": "Poulet à la Normande",
            "subTitle": "Poulet mijoté dans une sauce crémeuse aux pommes et au cidre",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 pommes, pelées, épépinées et coupées en quartiers",
                "1 oignon, émincé",
                "2 gousses d'ail, émincées",
                "1 tasse de cidre brut",
                "1/2 tasse de crème fraîche",
                "2 cuillères à soupe de beurre",
                "2 cuillères à soupe de farine tout usage",
                "1 cuillère à soupe de persil frais haché",
                "Sel et poivre, au goût"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "pommes",
                "oignon",
                "ail",
                "cidre-brut",
                "creme-fraiche",
                "beurre",
                "farine-tout-usage",
                "persil-frais",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans une grande poêle ou une cocotte, faire fondre le beurre à feu moyen-élevé. Ajouter les cuisses de poulet et les faire dorer de chaque côté pendant quelques minutes. Retirer le poulet de la poêle et réserver.",
                "Dans la même poêle, ajouter l'oignon émincé et les quartiers de pommes. Faire sauter pendant quelques minutes jusqu'à ce qu'ils soient légèrement dorés.",
                "Ajouter l'ail émincé et faire cuire pendant une minute de plus.",
                "Saupoudrer de farine tout usage sur les pommes et les oignons, et remuer pour bien enrober.",
                "Verser le cidre brut dans la poêle et gratter le fond pour décoller les morceaux dorés. Laisser mijoter pendant quelques minutes jusqu'à ce que le cidre réduise de moitié.",
                "Remettre les cuisses de poulet dans la poêle et ajouter la crème fraîche.",
                "Assaisonner avec du sel et du poivre selon votre goût."
            ],
            "cuisson": [
                "Laisser mijoter à feu doux pendant environ 20 à 25 minutes, ou jusqu'à ce que le poulet soit bien cuit et que la sauce soit onctueuse."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "plancha": false,
                "four": false,
                "grill": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Saupoudrer de persil frais haché avant de servir. Le poulet à la Normande est délicieux accompagné de pommes de terre rôties, de riz ou de purée de pommes de terre. Une salade verte fraîche complète parfaitement ce plat réconfortant.",
            "shortStory": "Le **poulet à la Normande** est une recette traditionnelle de la cuisine française, originaire de la région de Normandie. Ce plat réconfortant marie le poulet tendre et juteux avec une sauce crémeuse aux pommes et au cidre, pour une explosion de saveurs délicieusement riches et réconfortantes.",
            "detailURL": "/fiche-recette/8/poulet-normande-pommes-cidre/poulet-normande.html",
            "time_prepare": "20mn",
            "time_maceration": "0mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est moyen (niveau 2), en raison du cidre et de la crème fraîche qui sont un peu plus chers que les ingrédients de base."
            },
            "hot": {
                "level": 0,
                "description": "Ce plat est doux et savoureux, sans épices fortes, idéal pour ceux qui préfèrent des plats crémeux et légèrement sucrés."
            },
            "nutrition_info": {
                "calories": "350 kcal",
                "proteins": "30g",
                "carbs": "18g",
                "fats": "20g"
            },
            "allergens": [
                "lait",
                "gluten"
            ],
            "storage_instructions": "Conservez les restes dans un récipient hermétique au réfrigérateur pendant 2 à 3 jours. Réchauffez à feu doux dans une poêle ou au micro-ondes.",
            "difficulty": {
                "level": 2,
                "description": "Cette recette a une difficulté moyenne (niveau 2), car elle nécessite un peu de préparation et de surveillance pendant la cuisson."
            },
            "equipment_needed": [
                "poêle",
                "cocotte",
                "bol"
            ],
            "images": [],
            "calories": "350 kcal"
        },
        {
            "id": 22,
            "categorie": "europe",
            "country": "France",
            "flag": "FR",
            "title": "Poulet Provençal Mijoté",
            "subTitle": "Poulet mijoté aux saveurs méditerranéennes",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 cuillères à soupe d'huile d'olive",
                "1 oignon, haché",
                "2 gousses d'ail, émincées",
                "2 poivrons rouges, coupés en lanières",
                "2 tomates, coupées en dés",
                "1 bouquet garni (thym, romarin, laurier)",
                "Sel et poivre, au goût"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "huile-d'olive",
                "oignon",
                "ail",
                "poivrons-rouges",
                "tomates",
                "bouquet-garni",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans une grande poêle ou une cocotte, chauffer l'huile d'olive à feu moyen.",
                "Ajouter les cuisses de poulet et les faire dorer de chaque côté, environ 5 minutes par côté. Retirer et réserver.",
                "Dans la même poêle, ajouter l'oignon haché et l'ail émincé. Faire revenir jusqu'à ce qu'ils soient tendres et dorés.",
                "Ajouter les poivrons rouges coupés en lanières et faire sauter pendant quelques minutes.",
                "Remettre les cuisses de poulet dans la poêle, ajouter les tomates coupées en dés et le bouquet garni. Assaisonner avec du sel et du poivre, au goût.",
                "Couvrir et laisser mijoter à feu doux pendant environ 25 à 30 minutes, ou jusqu'à ce que le poulet soit bien cuit et que les saveurs se soient bien mélangées.",
                "Servir le poulet à la provençale chaud, accompagné de riz, de pommes de terre sautées ou de légumes grillés."
            ],
            "cuisson": [
                "Faire dorer les cuisses de poulet dans une poêle.",
                "Mijoter avec les légumes jusqu'à ce que le poulet soit bien cuit et les saveurs se soient bien mélangées."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "plancha": false,
                "four": false,
                "grill": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Le poulet à la provençale est délicieux accompagné d'un verre de vin rouge, de pain frais et d'une salade verte.",
            "shortStory": "Le **poulet à la provençale** est un plat français classique inspiré des saveurs méditerranéennes de la région de Provence. Ce plat réconfortant se compose de cuisses de poulet mijotées dans une **sauce tomate aux herbes aromatiques**, aux poivrons et aux oignons, offrant une explosion de saveurs en bouche.",
            "detailURL": "/fiche-recette/22/poivrons-rouges-tomates-oignon/poulet-provencale.html",
            "time_prepare": "15mn",
            "time_maceration": "0mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Le coût de cette recette est relativement abordable (niveau 1), grâce à des ingrédients simples et accessibles comme les poivrons, tomates et cuisses de poulet."
            },
            "hot": {
                "level": 0,
                "description": "Ce plat est doux et savoureux, sans épices fortes, idéal pour ceux qui préfèrent des plats à base de légumes frais et d'herbes."
            },
            "calories": "380 kcal",
            "allergens": [
                "gluten"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté modérée (niveau 2), nécessitant une attention au mijotage des saveurs et à la cuisson des cuisses de poulet."
            },
            "equipment_needed": [
                "poêle",
                "cocotte",
                "couteau",
                "planche à découper"
            ],
            "images": []
        },
        {
            "id": 26,
            "categorie": "europe",
            "country": "France",
            "flag": "FR",
            "title": "Poulet Méditerranéen au Four",
            "subTitle": "Plat traditionnel français",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet avec peau",
                "2 tomates, coupées en dés",
                "1 oignon, émincé",
                "2 gousses d'ail, émincées",
                "1 poivron rouge, coupé en lanières",
                "1 poivron jaune, coupé en lanières",
                "2 cuillères à soupe d'huile d'olive",
                "1 cuillère à café d'herbes de Provence",
                "Sel et poivre, au goût",
                "Quelques feuilles de basilic frais, pour garnir"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "tomates",
                "oignon",
                "ail",
                "poivron-rouge",
                "poivron-jaune",
                "huile-d'olive",
                "herbes-de-provence",
                "sel",
                "poivre",
                "feuilles-de-basilic-frais"
            ],
            "preparation": [
                "Préchauffer le four à 180°C (350°F).",
                "Dans une grande poêle allant au four, chauffer l'huile d'olive à feu moyen.",
                "Ajouter les cuisses de poulet dans la poêle et les faire dorer de chaque côté pendant environ 5 minutes.",
                "Retirer le poulet de la poêle et réserver.",
                "Dans la même poêle, ajouter l'oignon et l'ail, et faire revenir pendant 2 minutes.",
                "Ajouter les poivrons coupés en lanières dans la poêle et faire sauter pendant environ 5 minutes, jusqu'à ce qu'ils soient tendres.",
                "Remettre les cuisses de poulet dans la poêle, puis ajouter les dés de tomates et les herbes de Provence.",
                "Assaisonner avec du sel et du poivre selon votre goût."
            ],
            "cuisson": [
                "Faire dorer les cuisses de poulet dans une poêle, puis les cuire au four environ 25 à 30 minutes avec les légumes et les herbes de Provence."
            ],
            "cooking_methods": {
                "best": "four",
                "plancha": false,
                "four": true,
                "grill": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir le poulet à la provençale chaud, garni de feuilles de basilic frais. Le poulet à la provençale est délicieux servi avec des pommes de terre rôties, des pâtes fraîches ou du riz basmati.",
            "shortStory": "Le poulet à la provençale est un plat traditionnel français, originaire de la région de Provence. Il est préparé avec des **cuisses de poulet dorées**, des légumes frais comme les poivrons et les tomates, et parfumé avec des herbes de Provence pour une saveur méditerranéenne authentique.",
            "detailURL": "/fiche-recette/26/herbes-de-provence-oignon/poulet-a-la-provencale.html",
            "time_prepare": "15mn",
            "time_maceration": "0mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 29,
            "categorie": "europe",
            "country": "France",
            "flag": "FR",
            "title": "Poulet à la moutarde et au romarin",
            "subTitle": "Poulet rôti à la moutarde de Dijon",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "blanc": true,
                "cuisse": true,
                "aile": false,
                "pilon": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "2 cuillères à soupe de moutarde de Dijon",
                "2 cuillères à soupe de miel",
                "2 branches de romarin frais",
                "3 gousses d'ail émincées",
                "1 cuillère à soupe d'huile d'olive",
                "Pincée de sel et poivre"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "moutarde-de-dijon",
                "miel",
                "romarin",
                "ail-eminces",
                "huile-d'olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger la moutarde, le miel, l'ail, l'huile d'olive, le sel et le poivre pour faire la marinade.",
                "Placer les blancs de poulet dans la marinade, bien enrober. Ajouter les branches de romarin. Couvrir et laisser mariner pendant 1 heure.",
                "Sortir le poulet de la marinade avant la cuisson."
            ],
            "cuisson": [
                "Préchauffer le four à 180°C. Disposer les blancs de poulet dans un plat, arroser avec la marinade restante et enfourner pendant 25 à 30 minutes.",
                "Servir chaud avec des pommes de terre rôties et des légumes de saison."
            ],
            "cooking_methods": {
                "best": "four",
                "plancha": false,
                "four": true,
                "friture": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Accompagner ce poulet de pommes de terre rôties, de légumes de saison ou d'une salade pour un repas savoureux.",
            "shortStory": "Le **poulet à la moutarde** est une recette traditionnelle française, célèbre pour sa **simplicité** et son goût exquis. L'association de la **moutarde, du miel et du romarin** apporte une touche à la fois rustique et raffinée à ce plat.",
            "detailURL": "/fiche-recette/29/poulet-moutarde-romarin/poulet-moutarde-romarin.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 38,
            "categorie": "europe",
            "country": "France",
            "flag": "FR",
            "title": "Poulet à la Provençale",
            "subTitle": "Poulet mijoté aux herbes de Provence",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 tomates",
                "1 oignon",
                "1 gousse d'ail",
                "herbes de Provence",
                "huile d'olive"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "tomates",
                "oignon",
                "ail",
                "herbes-de-provence",
                "huile-d-olive"
            ],
            "preparation": [
                "Dans une cocotte, faire revenir l'oignon et l'ail dans l'huile d'olive.",
                "Ajouter les cuisses de poulet et faire dorer.",
                "Ajouter les tomates et les herbes, couvrir et mijoter pendant 40 minutes."
            ],
            "cuisson": [
                "Mijoter jusqu'à ce que le poulet soit tendre."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "plancha": false,
                "barbecue": false,
                "four": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec des pâtes ou du pain.",
            "shortStory": "Un plat classique français aux saveurs méditerranéennes.",
            "detailURL": "/fiche-recette/38/poulet-provencal/poulet-provencal.html",
            "time_prepare": "15mn",
            "time_cooking": "40mn",
            "time_maceration": "0mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 46,
            "categorie": "europe",
            "country": "France",
            "flag": "FR",
            "title": "Poulet aux Amandes",
            "subTitle": "Un poulet tendre avec une croûte d'amandes",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "100g d'amandes effilées",
                "2 œufs",
                "2 cuillères à soupe de farine",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "amandes-effilees",
                "oeufs",
                "farine",
                "sel-et-poivre"
            ],
            "preparation": [
                "Battre les œufs et assaisonner.",
                "Tremper les blancs de poulet dans la farine, puis dans les œufs et enfin les amandes.",
                "Faire cuire à la poêle jusqu'à ce qu'ils soient dorés des deux côtés."
            ],
            "cuisson": [
                "Faire cuire à la poêle jusqu'à ce qu'ils soient dorés des deux côtés."
            ],
            "cooking_methods": {
                "best": "poêle",
                "plancha": false,
                "friture": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec une salade verte.",
            "detailURL": "/fiche-recette/46/poulet-amandes/poulet-amandes.html",
            "time_prepare": "10mn",
            "time_cooking": "20mn",
            "shortStory": "Le **poulet aux amandes** est un plat classique français, apprécié pour sa **texture croustillante** et son goût délicat.",
            "time_maceration": "0mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 148,
            "categorie": "europe",
            "country": "France",
            "flag": "FR",
            "special_marker": {
                "type": "noel",
                "icon": "pere-noel",
                "months": ["01", "12"],
                "coordinates": {
                    "latitude": 30,
                    "longitude": -200
                }
            },
            "title": "Poulet mariné aux herbes et au vin blanc",
            "subTitle": "Une marinade parfumée pour un repas raffiné et festif.",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "1 kg de cuisses de poulet",
                "250 ml de vin blanc sec",
                "3 gousses d'ail écrasées",
                "1 bouquet de thym frais",
                "2 branches de romarin",
                "1 cuillère à soupe de moutarde à l'ancienne",
                "3 cuillères à soupe d'huile d'olive",
                "1 citron (jus et zeste)",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "vin-blanc",
                "ail",
                "thym",
                "romarin",
                "moutarde",
                "huile",
                "citron",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un grand saladier, mélanger le vin blanc, l'ail, le thym, le romarin, la moutarde, l'huile d'olive, le jus et le zeste de citron, le sel et le poivre.",
                "Ajouter les cuisses de poulet et bien les enrober de marinade. Couvrir et laisser mariner pendant au moins 3 heures ou toute une nuit."
            ],
            "cuisson": [
                "Préchauffer le four à 180°C.",
                "Disposer les cuisses de poulet dans un plat allant au four, verser un peu de marinade par-dessus et cuire pendant 40 à 50 minutes en les retournant à mi-cuisson."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": true,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": true
            },
            "mode_cuisson": [
                "four",
                "barbecue",
                "plancha"
            ],
            "accompagnement": "Idéal avec un gratin dauphinois ou des haricots verts sautés.",
            "shortStory": "Ce plat élégant, infusé de saveurs herbacées et de vin blanc, est **parfait pour les dîners de Noël**. L'arôme du romarin et du thym se marie parfaitement avec la tendreté du poulet, offrant une **expérience culinaire inoubliable** pour les fêtes.",
            "detailURL": "/fiche-recette/148/herbes-vin-blanc/poulet-marine-herbes-vin-blanc.html",
            "time_prepare": "15mn",
            "time_maceration": "180mn",
            "time_cooking": "50mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Recette sophistiquée mais accessible pour des occasions spéciales."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Parfumé sans piquant."
            },
            "calories": "320 kcal",
            "allergens": [
                "moutarde"
            ],
            "difficulty": {
                "level": 2,
                "description": "Une recette facile à maîtriser avec un goût raffiné."
            },
            "equipment_needed": [
                "saladier",
                "plat allant au four",
                "pinceau de cuisine",
                "four"
            ]
        },

        {
            "id": 118,
            "categorie": "caraibes",
            "country": "Guyana",
            "flag": "GY",
            "title": "Poulet Pepperpot",
            "subTitle": "Poulet mijoté dans une sauce riche et épicée à base de cassareep",
            "chicken_piece": [
                "entier",
                "pilon",
                "cuisse"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier (ou 6 morceaux au choix)",
                "2 cuillères à soupe de cassareep (sauce à base de manioc, typique du Guyana)",
                "1 oignon haché",
                "3 gousses d’ail émincées",
                "1 cuillère à soupe de gingembre râpé",
                "2 piments Scotch Bonnet (ou piments forts au choix)",
                "1 cuillère à café de cannelle en poudre",
                "1 cuillère à café de clou de girofle moulu",
                "2 cuillères à soupe de sucre brun",
                "1 cuillère à soupe de vinaigre blanc",
                "2 cuillères à soupe d’huile végétale",
                "400 ml de bouillon de poulet",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "cassareep",
                "oignon",
                "ail",
                "gingembre",
                "piment",
                "cannelle",
                "clou-de-girofle",
                "sucre",
                "vinaigre",
                "huile-végétale",
                "bouillon-de-poulet",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Découper le poulet en morceaux si nécessaire.",
                "Dans un bol, mélanger le cassareep, l’oignon, l’ail, le gingembre, les piments, la cannelle, le clou de girofle, le sucre, le vinaigre, l’huile, le sel et le poivre.",
                "Badigeonner les morceaux de poulet avec cette marinade et laisser reposer au moins 2 heures au réfrigérateur.",
                "Faire chauffer une cocotte avec un peu d’huile et saisir les morceaux de poulet jusqu’à ce qu’ils soient dorés.",
                "Ajouter le reste de la marinade et le bouillon de poulet dans la cocotte.",
                "Couvrir et laisser mijoter à feu doux pendant environ 1h30, en remuant de temps en temps, jusqu’à ce que la sauce soit épaisse et le poulet tendre."
            ],
            "cuisson": [
                "Saisir les morceaux de poulet dans une cocotte, puis les mijoter à feu doux avec la sauce jusqu’à ce qu’ils soient tendres et parfumés."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc, des bananes plantains frites ou du pain.",
            "shortStory": "Le **poulet Pepperpot** est une version au poulet du fameux ragoût national du Guyana, habituellement préparé avec du bœuf. Ce plat tire sa **saveur unique du cassareep**, une sauce sombre et savoureuse à base de manioc, typique de la région.",
            "detailURL": "/fiche-recette/118/clou-girofle-gingembre-cassareep-cannelle/guyana-poulet-pepperpot.html",
            "time_prepare": "25mn",
            "time_maceration": "2h",
            "time_cooking": "1h30",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2) car certains ingrédients comme le cassareep ou les piments spécifiques peuvent être difficiles à trouver, mais les morceaux de poulet restent abordables."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Cette recette est moyennement épicée, grâce aux piments Scotch Bonnet et au cassareep, mais les saveurs sont équilibrées."
            },
            "calories": "390 kcal",
            "allergens": [
                "piment",
                "cassareep (manioc)"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté modérée, nécessitant du temps pour mariner et mijoter, ainsi qu’une attention particulière à la réduction de la sauce."
            },
            "equipment_needed": [
                "cocotte",
                "bol",
                "cuillère en bois"
            ]
        },
        {
            "id": 185,
            "categorie": "europe",
            "country": "Croatie",
            "flag": "HR",
            "title": "Poulet Grillé à la Croate",
            "subTitle": "Poulet mariné avec paprika, ail, citron et huile d'olive, grillé.",
            "chicken_piece": [
                "blanc",
                "cuisses"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier (ou morceaux de poulet)",
                "1 cuillère à soupe de paprika",
                "4 gousses d'ail écrasées",
                "1 citron (jus)",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "paprika",
                "ail",
                "citron",
                "huile-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger le paprika, l'ail écrasé, le jus de citron, l'huile d'olive, le sel et le poivre.",
                "Enduire les morceaux de poulet de cette marinade et laisser reposer pendant 30 minutes."
            ],
            "cuisson": [
                "Préchauffer le grill à feu moyen.",
                "Griller le poulet pendant environ 30 à 40 minutes, en le retournant régulièrement, jusqu'à ce qu'il soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "grillade",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "grillade"
            ],
            "accompagnement": "Servir avec des légumes grillés ou une salade fraîche.",
            "shortStory": "**Poulet Grillé à la Croate** est une recette simple et savoureuse où le poulet est mariné avec du paprika, de l'ail, du citron et de l'huile d'olive, puis grillé pour un goût légèrement fumé.",
            "detailURL": "/fiche-recette/185/paprika-ail-citron-huile-olive/poulet-grille-a-la-croate.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "30mn",
            "crispys": true,
            "cost": {
                "level": 1,
                "description": "Plat facile à réaliser avec des ingrédients simples."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Non épicé."
            },
            "calories": "250 kcal",
            "allergens": [
                "huile d'olive"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite uniquement une cuisson au grill."
            },
            "equipment_needed": [
                "grill",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 188,
            "categorie": "europe",
            "country": "Croatie",
            "flag": "HR",
            "title": "Poulet Peka",
            "subTitle": "Poulet mariné avec ail, huile d’olive et herbes méditerranéennes, cuit sous une cloche en fer.",
            "chicken_piece": [
                "blanc",
                "cuisses",
                "pilon"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier (ou morceaux de poulet)",
                "4 gousses d'ail écrasées",
                "2 cuillères à soupe d'huile d'olive",
                "1 cuillère à soupe de romarin frais",
                "1 cuillère à soupe de thym frais",
                "1 citron (jus)",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "ail",
                "huile-olive",
                "romarin",
                "thym",
                "citron",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail écrasé, l'huile d'olive, le romarin, le thym, le jus de citron, le sel et le poivre dans un bol.",
                "Enduire les morceaux de poulet de cette marinade et laisser reposer pendant au moins 1 heure, idéalement toute la nuit."
            ],
            "cuisson": [
                "Préchauffer le four ou un grill à 180°C.",
                "Placer le poulet sous une cloche en fer (ou une cocotte en fonte avec un couvercle) et cuire pendant 1 heure 30 minutes à 2 heures, en fonction de la taille du poulet, jusqu'à ce qu'il soit doré et tendre."
            ],
            "cooking_methods": {
                "best": "four",
                "barbecue": false,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "cuit sous cloche"
            ],
            "accompagnement": "Servir avec des légumes grillés ou des pommes de terre rôties.",
            "shortStory": "**Poulet Peka** est un plat traditionnel croate où le poulet est mariné dans une délicieuse combinaison d'ail, d'herbes méditerranéennes et d'huile d'olive, puis cuit lentement sous une cloche en fer, garantissant **une viande tendre et parfumée**.",
            "detailURL": "/fiche-recette/188/romarin-thym-citron-ail/poulet-peka-croatie.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "1h30mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat facile mais nécessitant un peu de temps de cuisson."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Non épicé."
            },
            "calories": "350 kcal",
            "allergens": [
                "huile d'olive"
            ],
            "difficulty": {
                "level": 2,
                "description": "Moyenne, nécessite une cuisson sous cloche ou dans une cocotte."
            },
            "equipment_needed": [
                "four",
                "cloche en fer ou cocotte",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 23,
            "categorie": "asiatique",
            "country": "Inde",
            "flag": "IN",
            "title": "Poulet Tandoori",
            "subTitle": "Poulet épicé cuit au four",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "1 tasse de yaourt nature",
                "2 cuillères à soupe de jus de citron",
                "2 cuillères à soupe d'huile d'olive",
                "2 gousses d'ail, émincées",
                "1 cuillère à café de gingembre frais, râpé",
                "2 cuillères à soupe de pâte de tandoori",
                "1 cuillère à café de curcuma",
                "1 cuillère à café de paprika",
                "1 cuillère à café de cumin",
                "1 cuillère à café de coriandre moulue",
                "Sel et poivre, au goût",
                "Quelques tranches de citron, pour garnir",
                "Coriandre fraîche, pour garnir"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "yaourt-nature",
                "jus-de-citron",
                "huile-d'olive",
                "ail",
                "gingembre-frais",
                "pate-de-tandoori",
                "curcuma",
                "paprika",
                "cumin",
                "coriandre-moulue",
                "sel",
                "poivre",
                "tranches-de-citron",
                "coriandre-fraiche"
            ],
            "preparation": [
                "Dans un grand bol, mélanger le yaourt, le jus de citron, l'huile d'olive, l'ail, le gingembre, la pâte de tandoori, le curcuma, le paprika, le cumin, la coriandre moulue, le sel et le poivre.",
                "Ajouter les blancs de poulet dans le mélange et bien les enrober de marinade. Couvrir et laisser mariner au réfrigérateur pendant au moins 2 heures, de préférence toute la nuit."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C.",
                "Transférer les blancs de poulet marinés dans un plat allant au four et cuire pendant environ 25 à 30 minutes, ou jusqu'à ce que le poulet soit bien cuit et légèrement doré."
            ],
            "cooking_methods": {
                "best": "four",
                "plancha": false,
                "four": true,
                "grill": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir le poulet tandoori chaud, garni de tranches de citron et de feuilles de coriandre fraîche. Le poulet tandoori est délicieux servi avec du riz basmati, du pain naan chaud et une sauce au yaourt et à la menthe.",
            "shortStory": "Le **poulet tandoori** est un plat emblématique de la cuisine indienne, caractérisé par ses épices aromatiques et sa couleur rouge vif. Ce poulet mariné est cuit au four, ce qui lui confère une **texture tendre et juteuse** avec une saveur épicée et délicieuse.",
            "detailURL": "/fiche-recette/23/yaourt-nature-coriandre-moulue-paprika-cumin/poulet-tandoori.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 149,
            "categorie": "asiatique",
            "country": "Inde",
            "flag": "IN",
            "special_marker": {
                "type": "noel",
                "icon": "pere-noel",
                "months": ["01", "12"],
                "coordinates": {
                    "latitude": 200,
                    "longitude": -30
                }
            },
            "title": "Poulet mariné crémeux au yaourt et aux épices",
            "subTitle": "Une marinade exotique et festive pour un poulet tendre et parfumé.",
            "chicken_piece": [
                "entier",
                "blanc",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": true,
                "manchon": false
            },
            "ingredient": [
                "1 kg de morceaux de poulet (cuisses ou blancs)",
                "250 g de yaourt nature",
                "2 gousses d'ail hachées",
                "1 morceau de gingembre frais râpé (2 cm)",
                "1 cuillère à soupe de jus de citron",
                "1 cuillère à café de curcuma",
                "1 cuillère à café de cumin moulu",
                "1 cuillère à café de paprika doux",
                "1/2 cuillère à café de piment (optionnel)",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "yaourt",
                "ail",
                "gingembre",
                "citron",
                "curcuma",
                "cumin",
                "paprika",
                "piment",
                "huile",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un grand saladier, mélanger le yaourt, l'ail, le gingembre, le jus de citron, le curcuma, le cumin, le paprika, le piment (si utilisé), l'huile d'olive, le sel et le poivre.",
                "Ajouter les morceaux de poulet et bien les enrober de marinade. Couvrir et laisser mariner pendant au moins 2 heures ou toute une nuit."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C ou préparer un barbecue.",
                "Cuire le poulet au four pendant 40 minutes ou le griller au barbecue jusqu'à ce qu'il soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "barbecue": true,
                "friture": false,
                "four": true,
                "poele": false,
                "cocotte": false,
                "plancha": true
            },
            "mode_cuisson": [
                "four",
                "barbecue",
                "plancha"
            ],
            "accompagnement": "Servir avec du riz basmati ou un naan maison pour une touche indienne authentique.",
            "shortStory": "Inspirée des saveurs indiennes, cette marinade associe la **douceur du yaourt** à des **épices chaudes et parfumées**. Un plat parfait pour apporter **une touche exotique et festive à vos célébrations de Noël**.",
            "detailURL": "/fiche-recette/149/yaourt-epices/poulet-marine-cremeux.html",
            "time_prepare": "15mn",
            "time_maceration": "120mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Un plat savoureux et accessible avec une touche exotique."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Légèrement épicé, parfait pour les amateurs de saveurs relevées."
            },
            "calories": "350 kcal",
            "allergens": [
                "yaourt"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile à préparer avec un minimum d'effort."
            },
            "equipment_needed": [
                "saladier",
                "four ou barbecue",
                "pinceau de cuisine"
            ]
        },
        {
            "id": 17,
            "categorie": "europe",
            "country": "Italie",
            "flag": "IT",
            "title": "Poulet Parmesan à l'Italienne",
            "subTitle": "Escalopes de poulet panées et gratinées au parmesan",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 escalopes de poulet",
                "Sel et poivre, au goût",
                "100 g de chapelure",
                "50 g de parmesan râpé",
                "1 cuillère à café d’ail en poudre (optionnel)",
                "2 œufs battus",
                "200 g de mozzarella râpée (ou en tranches)",
                "400 ml de sauce tomate (ou sauce marinara)",
                "1 cuillère à café de basilic séché",
                "1 cuillère à soupe d’huile d'olive",
                "Persil frais ou basilic frais pour la garniture"
            ],
            "ingredient_liste": [
                "blanc-de-poulet",
                "chapelure",
                "parmesan-rape",
                "oeufs",
                "sauce-tomate",
                "mozzarella-rapee",
                "basilic-frais",
                "huile-d'olive",
                "sel",
                "poivre",
                "ail-en-poudre"
            ],
            "preparation": [
                "Préchauffer le four à 200°C (400°F).",
                "Si les escalopes de poulet sont épaisses, aplatissez-les légèrement pour qu’elles cuisent uniformément. Assaisonnez-les avec du sel et du poivre.",
                "Dans un bol peu profond, mélanger la chapelure et le parmesan râpé, l'ail en poudre (si désiré). Assaisonner également avec du sel et du poivre selon votre goût.",
                "Tremper chaque escalope de poulet dans les œufs battus, puis les enrober du mélange de chapelure et de parmesan.",
                "Bien appuyer pour faire adhérer la panure."
            ],
            "cuisson": [
                "Chauffer un peu d'huile d'olive dans une grande poêle à feu moyen.",
                "Faites cuire les escalopes panées environ 3 minutes de chaque côté, jusqu'à ce qu’elles soient dorées (pas besoin de les cuire complètement, elles finiront de cuire au four).",
                "Transférer les escalopes dorées dans un plat de cuisson allant au four.",
                "Répartir la sauce tomate sur le dessus de chaque escalope, puis saupoudrer de mozzarella râpée.",
                "Enfournez pour 15 à 20 minutes, ou jusqu’à ce que le fromage soit fondu et doré, et que le poulet soit bien cuit.",
                "Servir chaud, garni de basilic frais haché."
            ],
            "cooking_methods": {
                "best": "poêle",
                "plancha": false,
                "barbecue": false,
                "grill": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir chaud décoré avec du persil ou du basilic frais. Les escalopes de poulet Parmesan sont délicieuses servies avec des pâtes cuites al dente et une salade verte croquante ou du pain croustillant.",
            "shortStory": "Le **poulet Parmesan**, ou Pollo alla Parmigiana en italien, est un plat classique de la cuisine italienne, apprécié pour sa simplicité et ses saveurs réconfortantes. Dans cette recette, des **escalopes de poulet panées** sont gratinées avec de la sauce tomate, du fromage mozzarella et du parmesan râpé, pour une expérience gustative délicieusement italienne.",
            "detailURL": "/fiche-recette/17/poulet-parmesan-blanc-chapelure-parmesan-sauce-tomate/poulet-parmesan.html",
            "time_prepare": "15mn",
            "time_cooking": "20mn",
            "time_maceration": "0mn",
            "crispys": true,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 9002,
            "categorie": "fusion",
            "country": "Italie",
            "flag": "IT",
            "title": "Tenders à l’Italienne",
            "subTitle": "Poulet croustillant aux saveurs méditerranéennes",
            "chicken_piece": [
                "filet"
            ],
            "piece": {
                "best": "filet",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "500 g de filets de poulet",
                "150 g de farine",
                "2 œufs",
                "150 g de chapelure",
                "50 g de parmesan râpé",
                "1 cuillère à café d’origan séché",
                "1 cuillère à café de basilic séché",
                "1 cuillère à café de sel",
                "1/2 cuillère à café de poivre",
                "Huile pour friture"
            ],
            "ingredient_liste": [
                "filets-de-poulet",
                "farine",
                "œufs",
                "chapelure",
                "parmesan",
                "origan",
                "basilic",
                "sel",
                "poivre",
                "huile"
            ],
            "preparation": [
                "Coupez les filets de poulet en bandes régulières.",
                "Dans un bol, mélangez la farine avec le sel et le poivre.",
                "Dans un autre bol, battez les œufs.",
                "Dans un troisième bol, mélangez la chapelure avec le parmesan râpé, l’origan et le basilic.",
                "Trempez chaque morceau de poulet dans la farine, puis dans les œufs, et enfin dans le mélange chapelure-parmesan."
            ],
            "cuisson": [
                "Chauffez l'huile dans une poêle ou une friteuse à 180°C.",
                "Faites frire les tenders pendant 3-4 minutes de chaque côté jusqu'à ce qu'ils soient dorés.",
                "Égouttez sur du papier absorbant."
            ],
            "cooking_methods": {
                "best": "friture",
                "plancha": false,
                "friture": true,
                "barbecue": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir avec une sauce marinara ou du pesto.",
            "detailURL": "/fiche-recette/9002/basilic-origan-parmesan/tenders-italiens.html",
            "time_prepare": "20mn",
            "time_cooking": "10mn",
            "shortStory": "Les tenders à l’italienne associent la saveur intense du parmesan et le parfum des herbes méditerranéennes pour un plat gourmand et authentique. Parfait avec une sauce tomate ou du pesto.",
            "time_maceration": "0mn",
            "crispys": true,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 6,
            "categorie": "asiatique",
            "country": "Japon",
            "flag": "JP",
            "title": "Yakitori de poulet",
            "subTitle": "Brochettes de poulet japonaises",
            "chicken_piece": [
                "aile"
            ],
            "piece": {
                "best": "aile",
                "entier": false,
                "aile": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "500g d'ailes de poulet",
                "6 cuillères à soupe de sauce soja",
                "4 cuillères à soupe de mirin (ou 2 cuillères à soupe de vinaigre de riz et 2 cuillères à soupe de sucre)",
                "2 cuillères à soupe de saké (ou 1 cuillère à soupe de vinaigre de riz)",
                "2 gousses d'ail, émincées",
                "1 cuillère à café de gingembre frais râpé",
                "2 cuillères à soupe de miel (facultatif)",
                "6 à 8 brochettes en bois (trempées dans l'eau)"
            ],
            "ingredient_liste": [
                "ailes-de-poulet",
                "sauce-soja",
                "mirin",
                "sake",
                "ail",
                "gingembre-frais",
                "miel",
                "brochettes-en-bois"
            ],
            "preparation": [
                "Dans un bol, mélanger la sauce soja, le mirin, le saké, l'ail, le gingembre et éventuellement le miel pour faire la marinade.",
                "Ajouter les ailes de poulet dans le bol et bien les enrober de marinade. Couvrir et laisser mariner au réfrigérateur pendant au moins 1 heure, idéalement toute une nuit.",
                "Enfiler les ailes de poulet marinées sur les brochettes en bois préalablement trempées dans l'eau."
            ],
            "cuisson": [
                "Faire griller les brochettes de poulet sur un barbecue ou sous le gril du four pendant environ 10 à 15 minutes de chaque côté, ou jusqu'à ce qu'elles soient bien cuites et caramélisées."
            ],
            "cooking_methods": {
                "best": "four",
                "plancha": false,
                "four": true,
                "grill": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Les yakitoris sont délicieux servis avec du riz blanc ou des nouilles soba, et arrosés de sauce teriyaki ou de sauce yakitori.",
            "shortStory": "Les yakitoris sont des brochettes de poulet japonaises savoureuses et tendres, marinées dans une sauce sucrée-salée et grillées pour une texture caramélisée irrésistible. Ce plat populaire est idéal pour une soirée barbecue ou un repas décontracté entre amis.",
            "detailURL": "/fiche-recette/6/yakitoris-poulet-sauce-soja-mirin-sake/yakitori.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "20mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), grâce à des ingrédients abordables tels que les ailes de poulet, la sauce soja, et le mirin."
            },
            "hot": {
                "level": 1,
                "description": "Cette recette est légèrement épicée grâce au gingembre et à la sauce soja, mais elle n'est pas particulièrement piquante."
            },
            "nutrition_info": {
                "calories": "250 kcal",
                "proteins": "20g",
                "carbs": "10g",
                "fats": "15g"
            },
            "allergens": [
                "soja"
            ],
            "storage_instructions": "Conserver les brochettes de yakitori dans un récipient hermétique au réfrigérateur pendant 2 à 3 jours. Vous pouvez également les réchauffer sous le gril ou au barbecue.",
            "difficulty": {
                "level": 1,
                "description": "Cette recette est facile à réaliser (niveau 1), idéale pour les repas en famille ou entre amis."
            },
            "equipment_needed": [
                "grill",
                "brochettes-en-bois",
                "bol",
                "cuillère"
            ],
            "images": [],
            "calories": null
        },
        {
            "id": 21,
            "categorie": "asiatique",
            "country": "Japon",
            "flag": "JP",
            "title": "Poulet Teriyaki",
            "subTitle": "Poulet glacé à la sauce teriyaki",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 filets de poulet",
                "1/2 tasse de sauce soja",
                "1/4 tasse de mirin",
                "2 cuillères à soupe de vinaigre de riz",
                "2 cuillères à soupe de miel",
                "2 gousses d'ail, émincées",
                "1 cuillère à café de gingembre frais râpé",
                "2 cuillères à soupe d'huile végétale",
                "Graines de sésame et oignons verts, pour garnir"
            ],
            "ingredient_liste": [
                "filets-de-poulet",
                "sauce-soja",
                "mirin",
                "vinaigre-de-riz",
                "miel",
                "ail",
                "gingembre-frais",
                "huile-vegetale",
                "graines-de-sesame",
                "oignons-verts"
            ],
            "preparation": [
                "Dans un bol, mélanger la sauce soja, le mirin, le vinaigre de riz, le miel, l'ail émincé et le gingembre râpé pour préparer la marinade.",
                "Ajouter les filets de poulet dans le bol et bien les enrober de marinade.",
                "Couvrir et laisser mariner au réfrigérateur pendant au moins 1 heure, de préférence toute la nuit."
            ],
            "cuisson": [
                "Chauffer l'huile végétale dans une poêle à feu moyen-élevé.",
                "Retirer les filets de poulet de la marinade et les faire dorer des deux côtés dans la poêle chaude.",
                "Verser la marinade réservée dans la poêle et laisser mijoter jusqu'à ce que la sauce épaississe et enrobe le poulet, environ 5 minutes."
            ],
            "cooking_methods": {
                "best": "poêle",
                "plancha": false,
                "four": false,
                "grill": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Le poulet teriyaki est délicieux servi avec du riz cuit à la vapeur, des nouilles soba ou des légumes sautés à l'ail et au gingembre.",
            "shortStory": "Le poulet teriyaki est un plat japonais populaire de poulet mariné et glacé dans une sauce teriyaki sucrée et salée. Dans cette recette, les filets de poulet sont marinés dans une sauce teriyaki maison, puis dorés à la poêle jusqu'à ce qu'ils soient tendres et juteux, avec une belle couleur caramélisée.",
            "detailURL": "/fiche-recette/21/sauce-soja-mirin-gingembre-frais/poulet-teriyaki.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "20mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 36,
            "categorie": "asiatique",
            "country": "Japon",
            "flag": "JP",
            "title": "Poulet au Sésame",
            "subTitle": "Poulet frit au sésame croquant",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 filets de poulet",
                "1/2 tasse de graines de sésame",
                "2 œufs",
                "1 tasse de farine"
            ],
            "ingredient_liste": [
                "filets-de-poulet",
                "graines-de-sesame",
                "oeufs",
                "farine"
            ],
            "preparation": [
                "Tremper les filets de poulet dans les œufs battus, puis les enrober de farine et de graines de sésame.",
                "Faire chauffer l'huile dans une poêle et cuire les filets jusqu'à ce qu'ils soient dorés."
            ],
            "cuisson": [
                "Cuire à la friture jusqu'à ce qu'ils soient croustillants."
            ],
            "cooking_methods": {
                "best": "friture",
                "plancha": false,
                "barbecue": false,
                "four": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir avec du riz et des légumes.",
            "shortStory": "Un plat savoureux et croustillant, idéal pour les amateurs de sésame.",
            "detailURL": "/fiche-recette/36/poulet-sesame/poulet-sesame.html",
            "time_prepare": "15mn",
            "time_cooking": "10mn",
            "time_maceration": "0mn",
            "crispys": true,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 37,
            "categorie": "asiatique",
            "country": "Japon",
            "flag": "JP",
            "title": "Poulet Katsu",
            "subTitle": "Escalopes de poulet panées à la japonaise",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "1 tasse de panko",
                "2 œufs",
                "1/2 tasse de farine"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "panko",
                "oeufs",
                "farine"
            ],
            "preparation": [
                "Tremper les blancs de poulet dans la farine, les œufs et les enrober de panko.",
                "Faire chauffer l'huile dans une poêle et cuire jusqu'à ce qu'ils soient dorés."
            ],
            "cuisson": [
                "Cuire à la friture jusqu'à ce qu'ils soient croustillants."
            ],
            "cooking_methods": {
                "best": "friture",
                "plancha": false,
                "barbecue": false,
                "four": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir avec du riz et de la sauce tonkatsu.",
            "shortStory": "Un plat japonais classique, croustillant et délicieux.",
            "detailURL": "/fiche-recette/37/poulet-katsu/poulet-katsu.html",
            "time_prepare": "15mn",
            "time_cooking": "10mn",
            "time_maceration": "0mn",
            "crispys": true,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 105,
            "categorie": "fusion",
            "country": "Japon",
            "flag": "JP",
            "title": "Tenders à la Japonaise (Karaage Style)",
            "subTitle": "Poulet mariné et croustillant façon Karaage",
            "chicken_piece": [
                "filet"
            ],
            "piece": {
                "best": "filet",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "500 g de filets de poulet",
                "3 cuillères à soupe de sauce soja",
                "2 cuillères à soupe de saké ou de mirin",
                "1 cuillère à café de gingembre frais râpé",
                "2 gousses d'ail râpées",
                "100 g de fécule de pomme de terre ou de maïs",
                "Huile pour friture"
            ],
            "ingredient_liste": [
                "filets-de-poulet",
                "sauce-soja",
                "sake",
                "gingembre",
                "ail",
                "fecule",
                "huile"
            ],
            "preparation": [
                "Coupez les filets de poulet en morceaux de taille moyenne.",
                "Dans un bol, mélangez la sauce soja, le saké, le gingembre et l’ail.",
                "Ajoutez les morceaux de poulet dans la marinade et laissez reposer au réfrigérateur pendant 30 minutes.",
                "Égouttez légèrement le poulet et enrobez chaque morceau de fécule."
            ],
            "cuisson": [
                "Chauffez l'huile dans une poêle ou une friteuse à 180°C.",
                "Faites frire les tenders en deux étapes : 2 minutes pour cuire l’intérieur, égouttez, puis refaites frire 1-2 minutes pour un extérieur croustillant.",
                "Égouttez sur du papier absorbant."
            ],
            "cooking_methods": {
                "best": "friture",
                "plancha": false,
                "friture": true,
                "barbecue": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir avec une sauce ponzu ou mayonnaise japonaise.",
            "detailURL": "/fiche-recette/105/sauce-soja-gingembre-sake/tenders-japonais-karaage.html",
            "time_prepare": "20mn",
            "time_cooking": "10mn",
            "shortStory": "Inspirés du célèbre Karaage japonais, ces tenders marinés sont à la fois tendres et croustillants. Le gingembre et la sauce soja apportent une explosion de saveurs typiques du Japon.",
            "time_maceration": "30mn",
            "crispys": true,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 159,
            "categorie": "asiatique",
            "country": "Cambodge",
            "flag": "KH",
            "title": "Poulet Amok",
            "subTitle": "Poulet mariné avec citronnelle, curcuma, galanga et lait de coco, cuit à la vapeur.",
            "chicken_piece": [
                "morceaux",
                "blanc"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": false,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (blancs)",
                "1 tige de citronnelle, émincée",
                "1 cuillère à café de curcuma en poudre",
                "1 cuillère à soupe de galanga râpé",
                "200ml de lait de coco",
                "2 feuilles de citron kaffir (hachées)",
                "1 cuillère à soupe de pâte de curry rouge",
                "2 cuillères à soupe de sauce de poisson",
                "1 cuillère à café de sucre de palme",
                "sel au goût"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "citronnelle",
                "curcuma",
                "galanga",
                "lait-de-coco",
                "citron-kaffir",
                "curry-rouge",
                "sauce-de-poisson",
                "sucre-de-palme",
                "sel"
            ],
            "preparation": [
                "Dans un bol, mélanger le poulet avec la citronnelle, le curcuma, le galanga, la pâte de curry rouge, la sauce de poisson et le sucre de palme. Laisser mariner pendant 30 minutes.",
                "Ajouter le lait de coco et bien mélanger."
            ],
            "cuisson": [
                "Placer la préparation dans des feuilles de bananier ou un plat résistant à la vapeur.",
                "Faire cuire à la vapeur pendant 30 à 40 minutes, jusqu'à ce que le poulet soit bien tendre.",
                "Servir chaud, garni de feuilles de citron kaffir hachées."
            ],
            "cooking_methods": {
                "best": "vapeur",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "vapeur"
            ],
            "accompagnement": "Servir avec du riz jasmin ou une salade fraîche.",
            "shortStory": "Le **Poulet Amok** est un plat emblématique cambodgien, combinant la douceur du **lait de coco** et des arômes parfumés pour une expérience culinaire unique.",
            "detailURL": "/fiche-recette/159/citronnelle-curry/poulet-amok.html",
            "time_prepare": "20mn",
            "time_maceration": "30mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Ingrédients spécifiques mais accessibles dans les épiceries asiatiques."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Épicé mais équilibré grâce au lait de coco."
            },
            "calories": "320 kcal",
            "allergens": [
                "poisson"
            ],
            "difficulty": {
                "level": 3,
                "description": "Nécessite des ingrédients spécifiques et une cuisson soignée à la vapeur."
            },
            "equipment_needed": [
                "cuiseur vapeur",
                "bol",
                "couteau"
            ]
        },
        {
            "id": 160,
            "categorie": "asiatique",
            "country": "Cambodge",
            "flag": "KH",
            "title": "Poulet Lok Lak (simple)",
            "subTitle": "Poulet mariné avec de la sauce soja, ail, citron et poivre Kampot.",
            "chicken_piece": [
                "morceaux",
                "blanc"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": false,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de morceaux de poulet (blancs)",
                "3 gousses d'ail, écrasées",
                "3 cuillères à soupe de sauce soja",
                "1 cuillère à soupe de jus de citron",
                "1 cuillère à café de poivre Kampot moulu",
                "1 cuillère à soupe de sucre",
                "2 cuillères à soupe d'huile de tournesol"
            ],
            "ingredient_liste": [
                "morceaux-de-poulet",
                "ail",
                "sauce-soja",
                "citron",
                "poivre-kampot",
                "sucre",
                "huile-de-tournesol"
            ],
            "preparation": [
                "Dans un bol, mélanger l'ail écrasé, la sauce soja, le jus de citron, le poivre Kampot et le sucre.",
                "Ajouter les morceaux de poulet et bien enrober. Laisser mariner pendant 1 heure."
            ],
            "cuisson": [
                "Chauffer une poêle ou un wok avec l'huile de tournesol à feu moyen-élevé.",
                "Faire revenir les morceaux de poulet marinés jusqu'à ce qu'ils soient dorés et bien cuits, environ 10-15 minutes.",
                "Servir chaud, accompagné de salade et de riz blanc."
            ],
            "cooking_methods": {
                "best": "poele",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "poele"
            ],
            "accompagnement": "Accompagner d'une sauce au poivre Kampot et d'une salade verte.",
            "shortStory": "Le **Poulet Lok Lak** est un plat cambodgien raffiné, mettant en avant le **célèbre poivre Kampot** pour un goût épicé et parfumé.",
            "detailURL": "/fiche-recette/160/soja-poivre/poulet-lok-lak.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "15mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Ingrédients courants et préparation rapide."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Un piquant subtil avec le poivre Kampot."
            },
            "calories": "280 kcal",
            "allergens": [
                "soja"
            ],
            "difficulty": {
                "level": 1,
                "description": "Simple et rapide à préparer."
            },
            "equipment_needed": [
                "wok",
                "bol",
                "couteau"
            ]
        },
    {
        "id": 1161,
        "categorie": "asiatique",
        "country": "Cambodge",
        "flag": "KH",
        "title": "Poulet Lok Lak (traditionnel)",
        "subTitle": "Poulet mariné avec sauce de poisson, sauce soja, ail, citron et poivre Kampot.",
        "chicken_piece": [
            "morceaux",
            "blanc"
        ],
        "piece": {
            "best": "morceaux",
            "entier": false,
            "aile": false,
            "blanc": true,
            "cuisse": false,
            "pilon": false,
            "manchon": false
        },
        "ingredient": [
            "500g de morceaux de poulet (blancs)",
            "3 gousses d'ail, écrasées",
            "2 cuillères à soupe de sauce soja",
            "1 cuillère à soupe de sauce de poisson (nuoc-mâm)",
            "1 cuillère à soupe de jus de citron",
            "1 cuillère à café de poivre Kampot moulu",
            "1 cuillère à soupe de sucre de palme",
            "1 cuillère à soupe de fécule de maïs",
            "2 cuillères à soupe d'huile d'arachide"
        ],
        "ingredient_liste": [
            "morceaux-de-poulet",
            "ail",
            "sauce-soja",
            "sauce-de-poisson",
            "citron",
            "poivre-kampot",
            "sucre-de-palme",
            "fécule-de-maïs",
            "huile-d-arachide"
        ],
        "preparation": [
            "Dans un bol, mélanger l'ail écrasé, la sauce soja, la sauce de poisson, le jus de citron, le poivre Kampot, le sucre de palme et la fécule de maïs.",
            "Ajouter les morceaux de poulet et bien enrober. Laisser mariner pendant 2 heures."
        ],
        "cuisson": [
            "Chauffer une poêle ou un wok avec l'huile d'arachide à feu moyen-élevé.",
            "Faire revenir les morceaux de poulet marinés jusqu'à ce qu'ils soient dorés et bien cuits, environ 10-15 minutes.",
            "Servir chaud, accompagné de salade, de riz blanc et d'un œuf au plat."
        ],
        "cooking_methods": {
            "best": "poele",
            "barbecue": false,
            "friture": false,
            "four": false,
            "poele": true,
            "cocotte": false,
            "plancha": false
        },
        "mode_cuisson": [
            "poele"
        ],
        "accompagnement": "Pour la Sauce au poivre Kampot :\n" +
            "\n" +
            "Mélanger 2 cuillères à soupe de poivre de Kampot fraîchement moulu\n" +
            "Ajouter le jus d'un citron vert\n" +
            "Incorporer 1 cuillère à café de sel\n" +
            "Ajouter 1-2 cuillères à café de sucre selon le goût désiré\n" +
            "Diluer avec 3-4 cuillères à soupe d'eau tiède\n" +
            "Cette sauce se sert dans un petit bol à part pour y tremper les morceaux de poulet\n" +
            "\n" +
            "Pour la Salade verte cambodgienne :\n" +
            "\n" +
            "Laitue ou salade locale finement coupée\n" +
            "Concombre en fines tranches\n" +
            "Tomates coupées en quartiers\n" +
            "Oignons rouges émincés\n" +
            "Assaisonnement léger avec du jus de citron vert, une pincée de sel et un peu de sucre\n" +
            "\n" +
            "Pour Œuf au plat :\n" +
            "\n" +
            "Traditionnellement frit avec un jaune coulant\n" +
            "Servir sur le dessus du riz ou à côté\n" +
            "L'œuf est censé être mélangé avec le poulet et la sauce durant le repas\n" +
            "\n" +
            "Pour Riz :\n" +
            "\n" +
            "Du riz jasmin blanc fraîchement cuit\n" +
            "Servi séparément ou comme base du plat",
        "shortStory": "**Le Poulet Lok Lak** représente l'âme de la cuisine cambodgienne avec son histoire remontant à l'époque coloniale française. Cette préparation, dont le nom 'Lok Lak' évoque le bruit du poulet sautillant dans le wok brûlant, est devenue un incontournable des tables cambodgiennes. Ce qui distingue véritablement ce plat est l'utilisation du précieux poivre de Kampot, cultivé dans la province éponyme du sud du Cambodge, dont les notes florales et fruitées sont mondialement reconnues. La combinaison du nuoc-mâm umami, du sucre de palme caramélisé et de l'acidité du citron crée une symphonie gustative typiquement khmère. Chaque bouchée raconte l'histoire d'un pays où les influences culinaires chinoises, vietnamiennes et françaises se sont harmonieusement mêlées aux traditions locales. La présentation avec riz, salade croquante et œuf au plat n'est pas qu'esthétique - elle reflète la philosophie alimentaire cambodgienne cherchant l'équilibre parfait entre textures et saveurs.",
        "detailURL": "/fiche-recette/1161/soja-poivre/poulet-lok-lak-traditionnel.html",
        "time_prepare": "15mn",
        "time_maceration": "2h",
        "time_cooking": "15mn",
        "crispys": false,
        "cost": {
            "level": 1,
            "description": "Ingrédients accessibles, préparation authentique."
        },
        "images": [],
        "hot": {
            "level": 2,
            "description": "Un piquant subtil avec le poivre Kampot."
        },
        "calories": "290 kcal",
        "allergens": [
            "soja",
            "poisson"
        ],
        "difficulty": {
            "level": 1,
            "description": "Simple mais authentique."
        },
        "equipment_needed": [
            "wok",
            "bol",
            "couteau"
        ]
    }
,
    {
            "id": 172,
            "categorie": "afrique",
            "country": "Comores",
            "flag": "KM",
            "title": "Poulet au Coco",
            "subTitle": "Poulet mariné avec gingembre, citron, ail et lait de coco.",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de blanc ou cuisse de poulet",
                "1 morceau de gingembre frais râpé",
                "1 citron (jus uniquement)",
                "4 gousses d'ail écrasées",
                "400ml de lait de coco",
                "1 cuillère à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "blanc-de-poulet",
                "gingembre",
                "citron",
                "ail",
                "lait-de-coco",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Râper le gingembre et mélanger avec le jus de citron, l'ail écrasé, le sel et le poivre.",
                "Mariner les morceaux de poulet dans cette préparation pendant 30 minutes."
            ],
            "cuisson": [
                "Faire chauffer l'huile d'olive dans une poêle à feu moyen.",
                "Faire dorer les morceaux de poulet sur toutes les faces.",
                "Ajouter le lait de coco et laisser mijoter pendant 20 minutes jusqu'à ce que la viande soit tendre et la sauce épaissie.",
                "Rectifier l'assaisonnement avec du sel et du poivre si nécessaire."
            ],
            "cooking_methods": {
                "best": "poêle",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec du riz basmati ou du riz blanc, accompagné d'une salade de légumes frais.",
            "shortStory": "**Poulet au Coco** est un plat savoureux et parfumé des Comores, où le poulet est mariné avec du gingembre, du citron et de l'ail avant d'être mijoté dans un lait de coco crémeux et délicat.",
            "detailURL": "/fiche-recette/172/gingembre-citron-ail/poulet-au-coco.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "20mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Plat simple et rapide à réaliser."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Légèrement épicé grâce au gingembre."
            },
            "calories": "300 kcal",
            "allergens": [
                "coco"
            ],
            "difficulty": {
                "level": 1,
                "description": "Facile, nécessite une cuisson à la poêle."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 173,
            "categorie": "afrique",
            "country": "Comores",
            "flag": "KM",
            "title": "Poulet Pilao",
            "subTitle": "Poulet mariné dans des épices locales (cannelle, clou de girofle), intégré dans un plat de riz.",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de blanc ou cuisse de poulet",
                "1 cuillère à soupe de cannelle en poudre",
                "4 clous de girofle",
                "2 tasses de riz basmati",
                "1 oignon émincé",
                "2 tomates pelées et coupées en dés",
                "2 cuillères à soupe d'huile d'olive",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "blanc-de-poulet",
                "cannelle",
                "clou-de-girofle",
                "riz-basmati",
                "oignon",
                "tomates",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger la cannelle, les clous de girofle, le sel et le poivre.",
                "Mariner les morceaux de poulet avec cette préparation pendant 30 minutes.",
                "Rincer le riz basmati à l'eau froide."
            ],
            "cuisson": [
                "Faire chauffer l'huile d'olive dans une grande poêle ou une cocotte.",
                "Faire dorer les morceaux de poulet sur toutes les faces.",
                "Ajouter l'oignon émincé et les tomates, puis faire revenir jusqu'à ce que les légumes soient tendres.",
                "Incorporer le riz, mélanger et ajouter de l'eau jusqu'à ce que le riz soit couvert.",
                "Couvrir et cuire à feu doux pendant 20 à 25 minutes, jusqu'à ce que le riz soit cuit et l'eau absorbée."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec des légumes sautés ou une simple salade verte pour un repas complet.",
            "shortStory": "**Poulet Pilao** est un plat parfumé des Comores, où le poulet est mariné avec des épices locales comme la cannelle et les clous de girofle, puis cuit avec du riz pour un repas complet et savoureux.",
            "detailURL": "/fiche-recette/173/cannelle-clou-de-girofle/poulet-pilao.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "25mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat plus élaboré, mais reste abordable."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Non épicé, juste des épices douces."
            },
            "calories": "350 kcal",
            "allergens": [
                "gluten"
            ],
            "difficulty": {
                "level": 2,
                "description": "Modéré, nécessite une cuisson complète du riz et du poulet."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 178,
            "categorie": "asie",
            "country": "Corée du Nord",
            "flag": "KP",
            "title": "Samgyetang (poulet mariné avec ginseng)",
            "subTitle": "Poulet mariné avec ginseng, ail et gingembre, cuit dans une soupe.",
            "chicken_piece": [
                "blanc",
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "cuisse": false,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "1 poulet entier",
                "10g de ginseng frais",
                "4 gousses d'ail",
                "1 morceau de gingembre frais",
                "100g de riz gluant",
                "2 cuillères à soupe de sauce soja",
                "1 litre d'eau",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "ginseng",
                "ail",
                "gingembre",
                "riz-gluant",
                "sauce-soja",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Vider et nettoyer le poulet, puis farcir l'intérieur avec le riz gluant, l'ail, et le gingembre.",
                "Couper le ginseng en morceaux et l'ajouter dans le poulet farci.",
                "Assaisonner le poulet avec un peu de sel et de poivre, puis le placer dans une grande casserole."
            ],
            "cuisson": [
                "Ajouter 1 litre d'eau et la sauce soja dans la casserole.",
                "Porter à ébullition, puis réduire le feu et laisser mijoter pendant 1h30 à 2h, jusqu'à ce que le poulet soit tendre et la soupe bien parfumée.",
                "Servir chaud, avec un peu de riz à côté."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec un bol de riz blanc et une petite portion de kimchi pour une touche traditionnelle.",
            "shortStory": "**Samgyetang** est un plat de la Corée du Nord, où le poulet entier est **cuit dans une soupe nourrissante à base de ginseng**, d'ail et de gingembre, apportant chaleur et énergie, traditionnellement consommé pendant les mois d'été.",
            "detailURL": "/fiche-recette/178/ginseng-ail-gingembre/samgyetang.html",
            "time_prepare": "20mn",
            "time_maceration": "0mn",
            "time_cooking": "2h",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Plat coûteux à réaliser, en raison du ginseng et du poulet entier."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Non épicé."
            },
            "calories": "450 kcal",
            "allergens": [
                "soja"
            ],
            "difficulty": {
                "level": 2,
                "description": "Modéré, nécessite une cuisson longue et des ingrédients spécifiques."
            },
            "equipment_needed": [
                "grande casserole",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 65,
            "categorie": "asiatique",
            "country": "Corée du Sud",
            "flag": "KR",
            "title": "Dak Galbi",
            "subTitle": "Poulet épicé à la coréenne avec légumes",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "500g de cuisses de poulet désossées et coupées en morceaux",
                "1/4 tasse de pâte de piment coréenne (gochujang)",
                "2 cuillères à soupe de sauce soja",
                "2 cuillères à soupe de sucre",
                "1 cuillère à soupe de vinaigre de riz",
                "1 cuillère à soupe d'huile de sésame",
                "2 gousses d'ail, émincées",
                "1 cuillère à café de gingembre râpé",
                "1 oignon, émincé",
                "1/2 chou, coupé en morceaux",
                "1 patate douce, pelée et tranchée finement",
                "2 oignons verts, émincés",
                "Graines de sésame pour garnir"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "gochujang",
                "sauce-soja",
                "sucre",
                "vinaigre-de-riz",
                "huile-de-sesame",
                "ail",
                "gingembre"
            ],
            "preparation": [
                "Dans un grand bol, mélanger la pâte de piment, la sauce soja, le sucre, le vinaigre de riz, l'huile de sésame, l'ail émincé et le gingembre râpé pour préparer la marinade.",
                "Ajouter les morceaux de poulet dans le bol et bien les enrober de marinade.",
                "Couvrir et laisser mariner au réfrigérateur pendant au moins 30 minutes, ou jusqu'à 2 heures pour plus de saveur."
            ],
            "cuisson": [
                "Dans une grande poêle ou un wok, chauffer un peu d'huile végétale à feu moyen-élevé.",
                "Ajouter les morceaux de poulet marinés et faire sauter jusqu'à ce qu'ils commencent à dorer.",
                "Ajouter l'oignon, le chou, et la patate douce et continuer à faire sauter jusqu'à ce que les légumes soient tendres et le poulet bien cuit, environ 10-15 minutes.",
                "Garnir d'oignons verts émincés et de graines de sésame avant de servir."
            ],
            "cooking_methods": {
                "best": "poêle",
                "plancha": false,
                "four": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "poêle",
                "wok"
            ],
            "accompagnement": "Le Dak Galbi est souvent servi avec du riz à la vapeur et des feuilles de laitue pour envelopper le poulet et les légumes. Vous pouvez aussi l'accompagner de kimchi pour plus de saveur.",
            "shortStory": "Le Dak Galbi est un plat populaire en Corée du Sud, surtout apprécié dans les régions montagneuses. Ce plat de poulet mariné épicé est sauté avec des légumes, apportant un mélange unique de saveurs relevées et légèrement sucrées, typiques de la cuisine coréenne.",
            "detailURL": "/fiche-recette/65/gochujang-patate-douce-oignons-verts/dak-galbi.html",
            "time_prepare": "20mn",
            "time_maceration": "30mn",
            "time_cooking": "15mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 179,
            "categorie": "asiatique",
            "country": "Corée du Sud",
            "flag": "KR",
            "title": "Yangnyeom Chicken",
            "subTitle": "Poulet mariné dans une sauce épicée-sucrée (gochujang, ail, miel), frit.",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de blanc ou cuisse de poulet",
                "4 gousses d'ail écrasées",
                "2 cuillères à soupe de gochujang (pâte de piment coréenne)",
                "2 cuillères à soupe de miel",
                "1 cuillère à soupe de sauce soja",
                "1 cuillère à soupe d'huile de sésame",
                "huile pour friture",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "blanc-de-poulet",
                "ail",
                "gochujang",
                "miel",
                "sauce-soja",
                "huile-de-sesame",
                "huile-de-friture",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail écrasé, le gochujang, le miel, la sauce soja et l'huile de sésame pour faire la marinade.",
                "Couper le poulet en morceaux et mariner pendant 30 minutes.",
                "Préparer l'huile pour la friture dans une friteuse ou une poêle."
            ],
            "cuisson": [
                "Chauffer l'huile à 180°C.",
                "Enrober les morceaux de poulet de farine et les plonger dans l'huile chaude.",
                "Faire frire jusqu'à ce qu'ils soient dorés et croustillants.",
                "Égoutter sur du papier absorbant, puis napper de la sauce épicée-sucrée."
            ],
            "cooking_methods": {
                "best": "friture",
                "barbecue": false,
                "friture": true,
                "four": false,
                "poele": false,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir avec du riz ou des légumes sautés.",
            "shortStory": "**Yangnyeom Chicken** est un plat populaire en Corée du Sud, où le poulet est mariné dans une sauce épicée-sucrée à base de gochujang, puis frit pour un résultat croustillant et savoureux.",
            "detailURL": "/fiche-recette/179/gochujang-miel-sauce-soja-miel/yangnyeom-chicken.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "20mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Plat à coût modéré, avec des ingrédients comme la pâte de piment gochujang et le poulet."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Légèrement épicé grâce au gochujang."
            },
            "calories": "500 kcal",
            "allergens": [
                "gluten",
                "soja"
            ],
            "difficulty": {
                "level": 2,
                "description": "Modéré, nécessite de la friture."
            },
            "equipment_needed": [
                "friteuse ou casserole profonde",
                "couteau",
                "planche à découper",
                "papier absorbant"
            ]
        },
        {
            "id": 180,
            "categorie": "asiatique",
            "country": "Corée du Sud",
            "flag": "KR",
            "title": "Dak Bulgogi",
            "subTitle": "Poulet mariné avec sauce soja, ail, gingembre et sésame, grillé.",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de blanc ou cuisse de poulet",
                "3 gousses d'ail écrasées",
                "2 cuillères à soupe de sauce soja",
                "1 cuillère à soupe d'huile de sésame",
                "1 cuillère à soupe de gingembre frais râpé",
                "1 cuillère à soupe de miel",
                "1 cuillère à soupe de sucre",
                "1 cuillère à soupe de graines de sésame",
                "sel et poivre"
            ],
            "ingredient_liste": [
                "blanc-de-poulet",
                "ail",
                "sauce-soja",
                "huile-de-sesame",
                "gingembre",
                "miel",
                "sucre",
                "graines-de-sesame",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger l'ail écrasé, la sauce soja, l'huile de sésame, le gingembre râpé, le miel, le sucre, et les graines de sésame pour faire la marinade.",
                "Couper le poulet en morceaux et le mariner dans la sauce pendant 30 minutes à 1 heure."
            ],
            "cuisson": [
                "Préchauffer un grill ou une poêle à feu moyen-élevé.",
                "Griller les morceaux de poulet pendant 5 à 7 minutes de chaque côté, jusqu'à ce qu'ils soient bien cuits et légèrement caramélisés.",
                "Égoutter l'excédent de marinade avant de servir."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "barbecue": true,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Servir avec du riz cuit à la vapeur, des légumes sautés ou une salade de chou pour un repas complet.",
            "shortStory": "**Dak Bulgogi** est un plat coréen délicieux où le poulet mariné dans une **sauce soja parfumée avec de l'ail**, du **gingembre** et du **sésame** est grillé pour obtenir une texture tendre et savoureuse.",
            "detailURL": "/fiche-recette/180/sauce-soja-gingembre-poulet/dak-bulgogi.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "15mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Plat à coût modéré avec des ingrédients comme la sauce soja et l'huile de sésame."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Non épicé."
            },
            "calories": "350 kcal",
            "allergens": [
                "soja",
                "sésame"
            ],
            "difficulty": {
                "level": 2,
                "description": "Modéré, nécessite de la marinade et du grill."
            },
            "equipment_needed": [
                "grill ou poêle",
                "couteau",
                "planche à découper",
                "bol pour marinade"
            ]
        },
        {
            "id": 25,
            "categorie": "moyen-orient",
            "country": "Liban",
            "flag": "LB",
            "title": "Chich Taouk",
            "subTitle": "Brochettes de poulet libanaises",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "pilon": false,
                "manchon": false,
                "blanc": true,
                "cuisse": false
            },
            "ingredient": [
                "600g de blancs de poulet, coupés en cubes",
                "1/4 tasse de yaourt grec",
                "3 cuillères à soupe de jus de citron",
                "2 cuillères à soupe d'huile d'olive",
                "2 gousses d'ail émincées",
                "1 cuillère à café de paprika",
                "1 cuillère à café de cumin",
                "1/2 cuillère à café de curcuma",
                "1/4 cuillère à café de cannelle",
                "Sel et poivre, au goût",
                "Brochettes en bois (trempées dans l'eau)"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "yaourt-grec",
                "jus-de-citron",
                "huile-d'olive",
                "ail",
                "paprika",
                "cumin",
                "curcuma",
                "cannelle",
                "sel",
                "poivre",
                "brochettes-en-bois"
            ],
            "preparation": [
                "Dans un bol, mélanger le yaourt grec, le jus de citron, l'huile d'olive, l'ail émincé, le paprika, le cumin, le curcuma, la cannelle, le sel et le poivre pour préparer la marinade.",
                "Ajouter les cubes de blancs de poulet dans la marinade et bien mélanger pour les enrober. Couvrir et laisser mariner au réfrigérateur pendant au moins 2 heures, de préférence toute la nuit.",
                "Enfiler les cubes de poulet marinés sur les brochettes en bois préalablement trempées dans l'eau."
            ],
            "cuisson": [
                "Faire griller les brochettes de poulet sur un barbecue ou sous le gril du four pendant environ 10 à 15 minutes de chaque côté, ou jusqu'à ce qu'elles soient bien cuits et légèrement dorés."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "plancha": false,
                "four": false,
                "grill": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Les chich taouk sont délicieux accompagnés de riz pilaf, de légumes grillés, de pain pita chaud et de sauce à l'ail et au citron.",
            "shortStory": "Les chich taouk sont des brochettes de poulet libanaises marinées dans un mélange d'herbes et d'épices, puis grillées pour une saveur délicieusement parfumée. Cette recette traditionnelle est populaire dans toute la région du Moyen-Orient et est souvent servie lors de fêtes et de rassemblements familiaux.",
            "detailURL": "/fiche-recette/25/blancs-poulet-yaourt-citron/chich-taouk.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "20mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 48,
            "categorie": "moyen-orient",
            "country": "Liban",
            "flag": "LB",
            "title": "Poulet au Tahini",
            "subTitle": "Poulet tendre nappé d'une sauce au tahini",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "100g de tahini",
                "1 citron",
                "2 gousses d'ail",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "tahini",
                "citron",
                "gousses-d'ail",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger le tahini, le jus de citron, l'ail écrasé, le sel et le poivre.",
                "Badigeonner les blancs de poulet avec le mélange.",
                "Laisser mariner pendant 30 minutes."
            ],
            "cuisson": [
                "Griller ou cuire au four jusqu'à ce que le poulet soit bien cuit."
            ],
            "cooking_methods": {
                "best": "grill",
                "plancha": true,
                "friture": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "grill",
                "plancha"
            ],
            "accompagnement": "Servir avec du pain pita et une salade.",
            "shortStory": "Le Poulet au Tahini est un plat savoureux et crémeux, parfait pour ceux qui aiment les saveurs méditerranéennes. Le tahini, avec sa texture lisse et son goût de sésame, sublime le poulet, le rendant à la fois tendre et juteux.",
            "detailURL": "/fiche-recette/48/poulet-tahini/poulet-tahini.html",
            "time_prepare": "10mn",
            "time_maceration": "30mn",
            "time_cooking": "20mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 51,
            "categorie": "moyen-orient",
            "country": "Liban",
            "flag": "LB",
            "title": "Poulet à la Coriandre",
            "subTitle": "Poulet mariné à la coriandre et aux épices",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "1 bouquet de coriandre fraîche",
                "2 gousses d'ail",
                "2 cuillères à soupe d'huile d'olive",
                "Le jus d'un citron"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "coriandre-fraiche",
                "gousses-d'ail",
                "huile-d'olive",
                "jus-de-citron"
            ],
            "preparation": [
                "Mixer la coriandre, l'ail, l'huile d'olive et le jus de citron.",
                "Badigeonner les blancs de poulet avec la marinade et laisser mariner pendant 1 heure."
            ],
            "cuisson": [
                "Griller ou cuire à la plancha jusqu'à ce que le poulet soit bien cuit."
            ],
            "cooking_methods": {
                "best": "grill",
                "plancha": true,
                "friture": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "grill",
                "plancha"
            ],
            "accompagnement": "Servir avec un taboulé frais.",
            "shortStory": "Le Poulet à la Coriandre est une recette fraîche et parfumée, idéale pour les amateurs de saveurs herbacées. Marinée dans une sauce à base de coriandre, d'ail et de citron, cette préparation offre une explosion de saveurs lors de chaque bouchée.",
            "detailURL": "/fiche-recette/51/poulet-coriandre/poulet-coriandre.html",
            "time_prepare": "10mn",
            "time_maceration": "1h",
            "time_cooking": "20mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 45,
            "categorie": "afrique",
            "country": "Maroc",
            "flag": "MA",
            "title": "Poulet à la Marocaine",
            "subTitle": "Poulet aux saveurs orientales",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "1 oignon",
                "2 cuillères à soupe de ras el hanout",
                "200g de pois chiches",
                "1 citron confit"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "oignon",
                "ras-el-hanout",
                "pois-chiches",
                "citron-confit"
            ],
            "preparation": [
                "Faire revenir l'oignon dans une cocotte.",
                "Ajouter les cuisses de poulet et les épices.",
                "Incorporer les pois chiches et le citron confit.",
                "Mouiller avec de l'eau et mijoter jusqu'à cuisson complète."
            ],
            "cuisson": [
                "Faire revenir l'oignon dans une cocotte. Ajouter les cuisses de poulet et les épices. Incorporer les pois chiches et le citron confit. Mouiller avec de l'eau et mijoter jusqu'à cuisson complète."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "plancha": false,
                "friture": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du couscous.",
            "detailURL": "/fiche-recette/45/poulet-marocain/poulet-marocain.html",
            "time_prepare": "15mn",
            "time_cooking": "45mn",
            "shortStory": "Le poulet à la marocaine est un plat riche en saveurs, mettant en avant des épices traditionnelles et des ingrédients frais, créant un mélange parfait qui évoque la cuisine marocaine.",
            "time_maceration": "0mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 53,
            "categorie": "moyen-orient",
            "country": "Maroc",
            "flag": "MA",
            "title": "Brochettes de Poulet aux Épices",
            "subTitle": "Délicieuses brochettes marinées aux épices",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "500g de blancs de poulet",
                "2 cuillères à soupe de cumin",
                "1 cuillère à soupe de paprika",
                "1 cuillère à café de cannelle",
                "2 cuillères à soupe d'huile d'olive"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "cumin",
                "paprika",
                "cannelle",
                "huile-d'olive"
            ],
            "preparation": [
                "Couper le poulet en cubes.",
                "Mélanger les épices et l'huile d'olive.",
                "Enrober les cubes de poulet avec le mélange et laisser mariner pendant 1 heure.",
                "Enfiler sur des brochettes et griller ou cuire à la plancha."
            ],
            "cuisson": [
                "Griller les brochettes sur un barbecue ou cuire à la plancha jusqu'à ce qu'elles soient bien dorées."
            ],
            "cooking_methods": {
                "best": "grill",
                "plancha": true,
                "friture": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "grill",
                "plancha"
            ],
            "accompagnement": "Servir avec une sauce au yaourt et du pain pita.",
            "detailURL": "/fiche-recette/53/brochettes-poulet-epices/brochettes-poulet-epices.html",
            "time_prepare": "20mn",
            "time_cooking": "15mn",
            "shortStory": "Ces brochettes de poulet aux épices sont un plat savoureux et parfumé, idéal pour un barbecue ou un repas convivial. La marinade aux épices rehausse le goût du poulet et lui donne une belle couleur dorée.",
            "time_maceration": "1h",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 135,
            "categorie": "asiatique",
            "country": "Birmanie (Myanmar)",
            "flag": "MM",
            "title": "Poulet Curry Birman",
            "subTitle": "Poulet mariné avec curcuma, gingembre, ail et oignons, mijoté.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 blancs de poulet",
                "1 cuillère à café de curcuma",
                "1 morceau de gingembre frais râpé",
                "3 gousses d'ail hachées",
                "2 oignons finement tranchés",
                "1 tomate pelée et coupée",
                "200 ml de lait de coco",
                "1 cuillère à soupe d'huile",
                "Sel et poivre au goût"
            ],
            "ingredient_liste": [
                "curcuma",
                "gingembre",
                "ail",
                "oignon",
                "tomate",
                "lait-de-coco",
                "huile",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger le curcuma, le gingembre et l'ail pour mariner le poulet pendant 30 minutes.",
                "Faire revenir les oignons dans l'huile jusqu'à ce qu'ils soient dorés."
            ],
            "cuisson": [
                "Ajouter le poulet mariné aux oignons et cuire jusqu'à ce qu'il soit doré.",
                "Incorporer la tomate et le lait de coco, puis laisser mijoter pendant 30 minutes.",
                "Assaisonner avec du sel et du poivre."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": false,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz basmati ou du naan.",
            "shortStory": "Le Poulet Curry Birman est un plat réconfortant, riche en saveurs exotiques, grâce à l'utilisation du curcuma, du gingembre et du lait de coco.",
            "detailURL": "/fiche-recette/135/curcuma-gingembre-ail-oignon/poulet-curry-birman.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Assez abordable, avec quelques ingrédients spécifiques."
            },
            "images": [],
            "hot": 2,
            "hot_description": "Épicé mais équilibré avec le lait de coco.",
            "calories": "450 kcal",
            "allergens": [
                "lait de coco"
            ],
            "difficulty": {
                "level": 2,
                "description": "Nécessite un mijotage précis."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "cuillère en bois"
            ]
        },
        {
            "id": 136,
            "categorie": "asiatique",
            "country": "Birmanie (Myanmar)",
            "flag": "MM",
            "title": "Poulet à la Citronnelle",
            "subTitle": "Poulet mariné dans de la citronnelle, ail et sauce soja.",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 blancs de poulet",
                "2 tiges de citronnelle hachées",
                "3 gousses d'ail hachées",
                "2 cuillères à soupe de sauce soja",
                "1 cuillère à soupe d'huile",
                "Sel et poivre au goût"
            ],
            "ingredient_liste": [
                "citronnelle",
                "ail",
                "sauce-soja",
                "huile",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger la citronnelle, l'ail, la sauce soja et l'huile pour mariner le poulet pendant 1 heure."
            ],
            "cuisson": [
                "Faire griller le poulet dans une poêle ou sur une plancha jusqu'à ce qu'il soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "plancha",
                "barbecue": true,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": true
            },
            "mode_cuisson": [
                "plancha",
                "barbecue",
                "poêle"
            ],
            "accompagnement": "Servir avec une salade fraîche ou des légumes sautés.",
            "shortStory": "Le Poulet à la Citronnelle est un plat simple et parfumé, mettant en valeur l'arôme unique de la citronnelle combiné à la sauce soja.",
            "detailURL": "/fiche-recette/136/citronnelle-ail-sauce-soja/poulet-a-la-citronnelle.html",
            "time_prepare": "10mn",
            "time_maceration": "1h",
            "time_cooking": "20mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Recette très économique avec des ingrédients faciles à trouver."
            },
            "images": [],
            "hot": 1,
            "hot_description": "Léger piquant selon le goût de la citronnelle.",
            "calories": "320 kcal",
            "allergens": [
                "sauce soja"
            ],
            "difficulty": {
                "level": 1,
                "description": "Très facile à préparer et cuire."
            },
            "equipment_needed": [
                "plancha",
                "couteau",
                "bol pour marinade"
            ]
        },
        {
            "id": 104,
            "categorie": "fusion",
            "country": "Mexique",
            "flag": "MX",
            "title": "Tenders à la Mexicaine",
            "subTitle": "Poulet croustillant aux saveurs épicées mexicaines",
            "chicken_piece": [
                "filet"
            ],
            "piece": {
                "best": "filet",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "500 g de filets de poulet",
                "150 g de farine",
                "2 œufs",
                "150 g de chapelure",
                "1 cuillère à café de cumin",
                "1 cuillère à café de paprika fumé",
                "1 cuillère à café de piment en poudre",
                "1/2 cuillère à café d’origan séché",
                "1 cuillère à café de sel",
                "1/2 cuillère à café de poivre",
                "Huile pour friture"
            ],
            "ingredient_liste": [
                "filets-de-poulet",
                "farine",
                "œufs",
                "chapelure",
                "cumin",
                "paprika-fumé",
                "piment-en-poudre",
                "origan",
                "sel",
                "poivre",
                "huile"
            ],
            "preparation": [
                "Coupez les filets de poulet en bandes régulières.",
                "Dans un bol, mélangez la farine avec le cumin, le paprika fumé, le piment en poudre, l’origan, le sel et le poivre.",
                "Dans un autre bol, battez les œufs.",
                "Dans un troisième bol, versez la chapelure.",
                "Trempez chaque morceau de poulet dans la farine, puis dans les œufs, et enfin dans la chapelure."
            ],
            "cuisson": [
                "Chauffez l'huile dans une poêle ou une friteuse à 180°C.",
                "Faites frire les tenders pendant 3-4 minutes de chaque côté jusqu'à ce qu'ils soient dorés.",
                "Égouttez sur du papier absorbant."
            ],
            "cooking_methods": {
                "best": "friture",
                "plancha": false,
                "friture": true,
                "barbecue": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir avec une salsa maison ou un guacamole.",
            "detailURL": "/fiche-recette/104/piment-origan-cumin/tenders-mexicains.html",
            "time_prepare": "20mn",
            "time_cooking": "10mn",
            "shortStory": "Les tenders à la mexicaine sont parfaits pour les amateurs de saveurs épicées. Inspirés des épices mexicaines, ces morceaux croustillants de poulet sont un régal, surtout accompagnés de guacamole ou de salsa.",
            "time_maceration": "0mn",
            "crispys": true,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 61,
            "categorie": "oceanie",
            "country": "Nouvelle-Zélande",
            "flag": "NZ",
            "title": "Poulet Hangi",
            "subTitle": "Poulet mariné aux herbes locales et cuit à la vapeur sous terre",
            "chicken_piece": [
                "entier",
                "pilon"
            ],
            "piece": {
                "best": "pilon",
                "entier": true,
                "aile": true,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 pilons de poulet ou un poulet entier",
                "2 cuillères à soupe de miel",
                "1 cuillère à soupe de sauce soja",
                "1 cuillère à soupe d'huile d'olive",
                "1 gousse d'ail émincée",
                "1 cuillère à café de thym",
                "1 cuillère à café de romarin",
                "Sel, poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "miel",
                "sauce-soja",
                "huile-olive",
                "ail",
                "thym",
                "romarin",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélangez le miel, la sauce soja, l'huile d'olive, l'ail, le thym et le romarin dans un bol.",
                "Ajoutez les morceaux de poulet et laissez mariner pendant 3 heures."
            ],
            "cuisson": [
                "Faites cuire le poulet mariné dans un four ou, pour une expérience authentique, dans un Hangi (four traditionnel souterrain)."
            ],
            "cooking_methods": {
                "best": "four",
                "plancha": false,
                "four": true,
                "friture": false,
                "grill": false
            },
            "mode_cuisson": [
                "four",
                "hangi"
            ],
            "accompagnement": "Servez avec des légumes racines cuits à la vapeur ou grillés.",
            "shortStory": "Le Hangi est une méthode de cuisson traditionnelle maorie qui consiste à cuire les aliments dans un four souterrain. Cette recette apporte des saveurs riches et terreuses au poulet grâce à sa marinade aux herbes.",
            "time_prepare": "20mn",
            "time_cooking": "1h30",
            "time_maceration": "3h",
            "detailURL": "/fiche-recette/61/miel-sauce-soja-ail-romarin/Poulet-Hangi.html",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 5,
            "categorie": "amerique-sud",
            "country": "Pérou",
            "flag": "PE",
            "title": "Poulet à la Péruvienne",
            "subTitle": "Poulet mariné aux épices péruviennes",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "cuisse": true,
                "blanc": false,
                "aile": false,
                "pilon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "4 cuillères à soupe de sauce soja",
                "2 cuillères à soupe de vinaigre de cidre",
                "2 cuillères à soupe de jus de citron vert",
                "2 cuillères à soupe d'huile d'olive",
                "2 cuillères à soupe de paprika",
                "1 cuillère à soupe d'origan séché",
                "1 cuillère à soupe de cumin moulu",
                "1 cuillère à café de piment de Cayenne",
                "4 gousses d'ail, émincées",
                "Sel et poivre, au goût"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "sauce-soja",
                "vinaigre-de-cidre",
                "jus-de-citron-vert",
                "huile-dolive",
                "paprika",
                "origan-seche",
                "cumin-moulu",
                "piment-de-cayenne",
                "ail",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger la sauce soja, le vinaigre de cidre, le jus de citron vert, l'huile d'olive, le paprika, l'origan, le cumin, le piment de Cayenne, l'ail émincé, le sel et le poivre pour faire la marinade.",
                "Ajouter les cuisses de poulet dans le bol et bien les enrober de marinade. Couvrir et laisser mariner au réfrigérateur pendant au moins 2 heures, idéalement toute une nuit."
            ],
            "cuisson": [
                "Préchauffer le barbecue à feu moyen-élevé.",
                "Retirer les cuisses de poulet de la marinade et les disposer sur la grille du barbecue.",
                "Faire griller les cuisses de poulet feu moyen-élevé pendant environ 20 à 25 minutes de chaque côté, ou jusqu'à ce qu'elles soient bien cuites et dorées."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "plancha": false,
                "four": false,
                "grill": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Servir le poulet à la péruvienne chaud, accompagné de riz blanc, de pommes de terre rôties ou de légumes grillés, et accompagné d'une sauce piquante péruvienne.",
            "shortStory": "Le poulet à la péruvienne est un plat péruvien populaire, réputé pour sa marinade aux épices exotiques et son grillé savoureux. Cette recette simple et savoureuse vous transporte au cœur de la cuisine péruvienne, avec ses saveurs riches et épicées.",
            "detailURL": "/fiche-recette/5/poulet-peruvienne-sauce-soja-vinaigre-cidre-jus-citron-vert/poulet-peruvienne.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2) en raison des ingrédients abordables comme les épices, la sauce soja et les cuisses de poulet."
            },
            "hot": {
                "level": 2,
                "description": "Le poulet à la péruvienne a un goût épicé mais pas trop fort, grâce au piment de Cayenne et au paprika, apportant une chaleur agréable sans être écrasante."
            },
            "nutrition_info": {
                "calories": "350 kcal",
                "proteins": "25g",
                "carbs": "5g",
                "fats": "25g"
            },
            "allergens": [
                "soja"
            ],
            "storage_instructions": "Conserver dans un récipient hermétique au réfrigérateur pendant 2 à 3 jours. Peut être réchauffé au barbecue ou au micro-ondes.",
            "difficulty": {
                "level": 2,
                "description": "Cette recette est relativement facile à réaliser (niveau 2), idéale pour un repas rapide et savoureux, à condition de maîtriser la cuisson sur le barbecue."
            },
            "equipment_needed": [
                "barbecue",
                "bol",
                "cuillère",
                "grille"
            ],
            "images": [],
            "calories": null
        },
        {
            "id": 31,
            "categorie": "amerique-sud",
            "country": "Pérou",
            "flag": "PE",
            "title": "Poulet à la Chicha",
            "subTitle": "Poulet mariné à la bière",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "1 tasse de chicha",
                "2 cuillères à soupe de cumin",
                "2 gousses d'ail",
                "1 citron vert",
                "sel",
                "poivre"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "chicha",
                "cumin",
                "ail",
                "citron-vert",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger la chicha, le cumin, l'ail, le sel et le poivre.",
                "Mariner les blancs de poulet pendant 2 heures.",
                "Préchauffer le grill et cuire les blancs de poulet jusqu'à ce qu'ils soient dorés."
            ],
            "cuisson": [
                "Cuire sur le grill jusqu'à ce que le poulet soit bien cuit."
            ],
            "cooking_methods": {
                "best": "grill",
                "plancha": false,
                "barbecue": false,
                "friture": false
            },
            "mode_cuisson": [
                "grill"
            ],
            "accompagnement": "Servir avec une salade fraîche.",
            "shortStory": "Un plat traditionnel péruvien, plein de saveurs locales.",
            "detailURL": "/fiche-recette/31/poulet-chicha/poulet-chicha.html",
            "time_prepare": "10mn",
            "time_maceration": "2h",
            "time_cooking": "20mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 60,
            "categorie": "oceanie",
            "country": "Polynésie française",
            "flag": "PF",
            "title": "Poulet Fa'a'apapa",
            "subTitle": "Poulet mariné dans une sauce au lait de coco et citron vert",
            "chicken_piece": [
                "entier",
                "blanc"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": true,
                "blanc": true,
                "pilon": true,
                "manchon": true,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier découpé en morceaux ou 4 blancs de poulet",
                "200 ml de lait de coco",
                "2 cuillères à soupe de jus de citron vert",
                "2 gousses d'ail émincées",
                "1 cuillère à soupe de sauce soja",
                "1 morceau de gingembre râpé (environ 1 cm)",
                "Sel, poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "lait-coco",
                "citron-vert",
                "ail",
                "sauce-soja",
                "gingembre",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélangez le lait de coco, le jus de citron vert, l'ail, le gingembre et la sauce soja dans un grand bol.",
                "Ajoutez les morceaux de poulet et laissez mariner au réfrigérateur pendant au moins 2 heures."
            ],
            "cuisson": [
                "Faites griller le poulet mariné à feu moyen sur un barbecue ou au four jusqu'à ce qu'il soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "grill",
                "plancha": true,
                "four": true,
                "friture": false,
                "grill": true
            },
            "mode_cuisson": [
                "barbecue",
                "four"
            ],
            "accompagnement": "Servez avec du riz ou des légumes grillés.",
            "shortStory": "Le Fa'a'apapa est un plat traditionnel polynésien. Le poulet est mariné dans une sauce parfumée au lait de coco et citron vert, apportant des saveurs tropicales délicates.",
            "detailURL": "/fiche-recette/60/poulet-lait-coco-citron-vert-gingembre/Faaapapa.html",
            "time_prepare": "15mn",
            "time_cooking": "30mn",
            "time_maceration": "2h",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 120,
            "categorie": "moyen-orient",
            "country": "Arabie Saoudite",
            "flag": "SA",
            "title": "Poulet Al-Mandi",
            "subTitle": "Poulet mariné avec des épices riches, cuit sur un lit de riz parfumé",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier découpé en morceaux",
                "2 cuillères à soupe de cumin moulu",
                "1 cuillère à soupe de clou de girofle moulu",
                "1 cuillère à soupe de curcuma",
                "2 oignons hachés",
                "4 gousses d’ail écrasées",
                "1 cuillère à soupe de gingembre râpé",
                "4 tasses de riz basmati",
                "1 bâton de cannelle",
                "6 gousses de cardamome",
                "2 feuilles de laurier",
                "4 cuillères à soupe d’huile végétale",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "cumin",
                "clou-de-girofle",
                "curcuma",
                "oignon",
                "ail",
                "gingembre",
                "riz-basmati",
                "cannelle",
                "cardamome",
                "feuilles-de-laurier",
                "huile-végétale",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le cumin, le clou de girofle, le curcuma, le sel et le poivre avec un peu d’huile.",
                "Badigeonner le poulet avec ce mélange et laisser mariner au moins 1 heure.",
                "Faire chauffer une grande cocotte avec un peu d’huile et faire revenir les oignons, l’ail et le gingembre.",
                "Ajouter le bâton de cannelle, les gousses de cardamome et les feuilles de laurier, puis le riz.",
                "Placer les morceaux de poulet sur le riz et ajouter 6 tasses d’eau chaude.",
                "Couvrir et cuire à feu doux jusqu’à ce que le riz soit tendre et le poulet cuit à cœur (environ 40 minutes)."
            ],
            "cuisson": [
                "Cuire à l'étouffée dans une cocotte pour que le riz absorbe les saveurs et que le poulet soit tendre."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec une sauce tomate épicée ou une salade verte.",
            "shortStory": "L’**Al-Mandi** est un plat emblématique d’Arabie Saoudite, caractérisé par ses épices riches et son mode de cuisson traditionnel sur du riz parfumé. Ce plat, inspiré des traditions bédouines, est souvent servi lors des grandes occasions et des rassemblements familiaux.",
            "detailURL": "/fiche-recette/120/poulet-entier-cumin-curcuma/poulet-al-mandi.html",
            "time_prepare": "30mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), principalement en raison des épices utilisées et du riz basmati de qualité nécessaire pour ce plat."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Les épices ajoutent une chaleur douce et aromatique au plat, sans le rendre trop épicé."
            },
            "calories": "480 kcal",
            "allergens": [
                "cardamome"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté modérée, nécessitant un bon équilibre entre la cuisson du riz et celle du poulet."
            },
            "equipment_needed": [
                "cocotte",
                "bol",
                "cuillère en bois"
            ]
        },
        {
            "id": 121,
            "categorie": "moyen-orient",
            "country": "Arabie Saoudite",
            "flag": "SA",
            "title": "Poulet Dajaj Mashwi",
            "subTitle": "Poulet mariné au yaourt, citron et zaatar, grillé pour des saveurs authentiques",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon",
                "aile"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": true,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier découpé ou 8 morceaux (cuisse, pilon, aile)",
                "200 g de yaourt nature",
                "2 cuillères à soupe de zaatar",
                "1 citron (jus et zeste)",
                "3 gousses d’ail écrasées",
                "2 cuillères à soupe d’huile d’olive",
                "1 cuillère à café de paprika",
                "1 cuillère à café de cumin moulu",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "yaourt",
                "zaatar",
                "citron",
                "ail",
                "huile-d'olive",
                "paprika",
                "cumin",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le yaourt, le zaatar, le jus et le zeste de citron, l’ail, l’huile d’olive, le paprika, le cumin, le sel et le poivre.",
                "Badigeonner généreusement les morceaux de poulet avec la marinade et laisser reposer au réfrigérateur pendant au moins 2 heures.",
                "Préchauffer un grill ou un barbecue.",
                "Faire griller les morceaux de poulet à feu moyen jusqu’à ce qu’ils soient bien dorés et cuits à cœur, en les retournant régulièrement (environ 25 minutes)."
            ],
            "cuisson": [
                "Faire griller au barbecue ou au grill pour des saveurs authentiques et une texture légèrement croustillante."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": false,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "barbecue",
                "grill"
            ],
            "accompagnement": "Servir avec une salade fattouche, du pain pita ou du riz au safran.",
            "shortStory": "Le **Dajaj Mashwi** est un classique des grillades en Arabie Saoudite. Ce plat marie le crémeux du yaourt, l’acidité du citron et les arômes puissants du zaatar, pour une expérience culinaire authentique et savoureuse.",
            "detailURL": "/fiche-recette/121/cumin-citron-cumin-paprika-yaourt-zaatar/poulet-dajaj-mashwi.html",
            "time_prepare": "20mn",
            "time_maceration": "2h",
            "time_cooking": "25mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Le coût est modéré (niveau 2), principalement en raison du zaatar et des morceaux de poulet de qualité nécessaires pour le grill."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Ce plat est doux et légèrement acidulé grâce au citron et au yaourt, avec un parfum intense du zaatar."
            },
            "calories": "410 kcal",
            "allergens": [
                "lait (yaourt)"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette simple mais nécessitant une cuisson attentive pour éviter de dessécher le poulet."
            },
            "equipment_needed": [
                "grill",
                "bol",
                "pince de cuisine"
            ]
        },
        {
            "id": 3,
            "categorie": "afrique",
            "country": "Sénégal",
            "flag": "SN",
            "title": "Poulet Yassa",
            "subTitle": "Poulet mariné à l'oignon et au citron",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "blanc": false,
                "cuisse": true,
                "aile": false,
                "pilon": false
            },
            "ingredient": [
                "4 cuisses de poulet",
                "6 oignons moyens, tranchés en rondelles",
                "4 citrons, jus",
                "4 gousses d'ail, écrasées",
                "4 cuillères à soupe d'huile d'olive",
                "2 cuillères à soupe de moutarde de Dijon",
                "2 cuillères à soupe de vinaigre blanc",
                "2 cuillères à soupe de sauce soja",
                "2 cuillères à soupe de persil frais, haché",
                "1 cuillère à café de poivre noir",
                "Sel, au goût"
            ],
            "ingredient_liste": [
                "cuisse-de-poulet",
                "oignons",
                "citrons",
                "ail",
                "huile-dolive",
                "moutarde-de-dijon",
                "vinaigre-blanc",
                "sauce-soja",
                "persil-frais",
                "poivre-noir",
                "sel"
            ],
            "preparation": [
                "Dans un grand bol, mélanger le jus de citron, l'ail écrasé, l'huile d'olive, la moutarde de Dijon, le vinaigre blanc, la sauce soja, le persil haché, le poivre noir et le sel.",
                "Ajouter les cuisses de poulet dans le mélange de marinade, en veillant à bien les enrober. Couvrir et laisser mariner au réfrigérateur pendant au moins 2 heures, de préférence toute la nuit."
            ],
            "cuisson": [
                "Dans une grande poêle, chauffer un peu d'huile d'olive à feu moyen-élevé.",
                "Ajouter les oignons tranchés et les faire revenir jusqu'à ce qu'ils soient tendres et légèrement caramélisés.",
                "Ajouter les cuisses de poulet marinées dans la poêle avec les oignons et faire cuire à feu moyen pendant environ 30 à 40 minutes, en retournant les cuisses de temps en temps, jusqu'à ce qu'elles soient bien cuites et dorées.",
                "Servir le poulet Yassa chaud, garni d'oignons caramélisés."
            ],
            "cooking_methods": {
                "best": "poêle",
                "plancha": false,
                "four": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Le poulet Yassa est traditionnellement servi avec du riz blanc ou du couscous, ainsi que des légumes sautés ou une salade verte.",
            "shortStory": "Le poulet Yassa est un plat traditionnel sénégalais, réputé pour sa marinade à base d'oignon, de citron et d'épices. Cette recette délicieusement parfumée est facile à préparer et constitue un repas copieux et réconfortant pour toute la famille.",
            "detailURL": "/fiche-recette/3/poulet-yassa-cuisse-poulet-oignons-citron-marinade/senegale-poulet-yassa.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": 2,
            "cost_description": "Le coût de cette recette est moyen (niveau 2), en raison des ingrédients tels que les cuisses de poulet, les oignons et le citron, qui sont généralement accessibles mais peuvent légèrement varier selon la saison et la qualité des produits utilisés.",
            "hot": {
                "level": 1,
                "description": "Le poulet Yassa a une touche de piquant léger apportée par la moutarde de Dijon et la sauce soja, mais il est principalement dominé par les saveurs acidulées du citron et la douceur des oignons caramélisés."
            },
            "nutrition_info": {
                "calories": "400 kcal",
                "proteins": "28g",
                "carbs": "12g",
                "fats": "28g"
            },
            "allergens": [
                "moutarde",
                "soja"
            ],
            "storage_instructions": "Conserver dans un récipient hermétique au réfrigérateur pendant 2 à 3 jours. Peut être réchauffé à la poêle ou au micro-ondes.",
            "difficulty": {
                "level": 3,
                "description": "Recette de difficulté moyenne, avec une marinade longue et une cuisson à la poêle nécessitant une attention particulière pour éviter de brûler les oignons tout en assurant que le poulet soit bien cuit."
            },
            "equipment_needed": [
                "poêle",
                "bol",
                "couteau",
                "planche à découper"
            ],
            "images": [],
            "calories": null
        },
        {
            "id": 44,
            "categorie": "afrique",
            "country": "Sénégal",
            "flag": "SN",
            "title": "Poulet aux épices africaines",
            "subTitle": "Poulet rôti aux saveurs épicées",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 cuillères à soupe de mélange d'épices africaines",
                "1 oignon",
                "4 gousses d'ail",
                "1 citron",
                "huile d'olive",
                "sel",
                "poivre"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "epices-africaines",
                "oignon",
                "ail",
                "citron",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mélanger les épices, l'huile d'olive, le sel et le poivre.",
                "Enrober les cuisses de poulet avec la marinade.",
                "Ajouter l'oignon et l'ail dans le plat.",
                "Laisser mariner pendant 1 heure."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C.",
                "Cuire pendant 40 minutes.",
                "Jusqu'à ce que le poulet soit bien doré."
            ],
            "cooking_methods": {
                "best": "four",
                "plancha": false,
                "barbecue": false,
                "grill": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec du riz ou des légumes grillés.",
            "shortStory": "Ce plat est un hommage aux saveurs vibrantes de l'Afrique.",
            "detailURL": "/fiche-recette/44/poulet-epices-africaines/poulet-epices-africaines.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 59,
            "categorie": "afrique",
            "country": "Sénégal",
            "flag": "SN",
            "title": "Mafé de Poulet",
            "subTitle": "Poulet mijoté dans une sauce crémeuse à l'arachide",
            "chicken_piece": [
                "entier",
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier découpé en morceaux (ou 4 cuisses de poulet)",
                "3 cuillères à soupe de pâte d'arachide (beurre de cacahuète non sucré)",
                "3 tomates fraîches ou 200g de tomates concassées",
                "2 oignons",
                "2 gousses d'ail",
                "1 carotte (facultatif)",
                "2 pommes de terre (facultatif)",
                "1 cube de bouillon de volaille ou de légumes",
                "2 cuillères à soupe d'huile d'arachide ou végétale",
                "1 morceau de piment (frais ou sec, selon goût)",
                "1 bouquet de persil frais",
                "Sel, poivre",
                "500 ml d'eau"
            ],
            "ingredient_liste": [
                "poulet",
                "pate-arachide",
                "tomates",
                "oignons",
                "ail",
                "carotte",
                "pommes-de-terre",
                "bouillon-volaille",
                "huile-arachide",
                "piment",
                "persil",
                "sel",
                "poivre",
                "eau"
            ],
            "preparation": [
                "Émincez les oignons et l'ail. Hachez les tomates si elles sont fraîches.",
                "Épluchez et coupez les pommes de terre et la carotte en gros morceaux (facultatif).",
                "Découpez et nettoyez le poulet en morceaux."
            ],
            "cuisson": [
                "Dans une cocotte, faites chauffer l'huile à feu moyen.",
                "Ajoutez les morceaux de poulet et faites-les dorer sur toutes les faces.",
                "Retirez le poulet et réservez. Dans la même cocotte, faites revenir les oignons et l'ail jusqu'à ce qu'ils soient dorés.",
                "Ajoutez les tomates hachées, laissez cuire quelques minutes, puis ajoutez la pâte d'arachide. Mélangez bien.",
                "Ajoutez le bouillon, le persil et le piment (ne pas retirer la queue).",
                "Versez l'eau et laissez mijoter 5 minutes.",
                "Remettez le poulet dans la cocotte, ajoutez les pommes de terre et la carotte.",
                "Laissez mijoter à feu doux pendant environ 45 minutes, jusqu'à ce que le poulet soit tendre et la sauce onctueuse."
            ],
            "cooking_methods": {
                "best": "mijoter",
                "plancha": false,
                "four": false,
                "friture": false,
                "grill": false
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servez le Mafé de Poulet avec du riz blanc.",
            "shortStory": "Le Mafé de Poulet est un plat traditionnel sénégalais à base de poulet mijoté dans une sauce crémeuse à l'arachide. Cette recette, riche en saveurs, est un classique de la cuisine africaine qui met en valeur des ingrédients simples mais savoureux.",
            "detailURL": "/fiche-recette/59/mafe-poulet/mafe-poulet.html",
            "time_prepare": "15mn",
            "time_cooking": "45mn",
            "crispys": false,
            "time_maceration": null,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 49,
            "categorie": "moyen-orient",
            "country": "Syrie",
            "flag": "SY",
            "title": "Poulet Shawarma",
            "subTitle": "Poulet mariné aux épices et rôti",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 cuillères à soupe de cumin",
                "2 cuillères à soupe de paprika",
                "1 yaourt nature",
                "1 citron"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "cumin",
                "paprika",
                "yaourt-nature",
                "citron"
            ],
            "preparation": [
                "Mélanger les épices avec le yaourt et le jus de citron.",
                "Mariner les cuisses de poulet pendant 1 heure."
            ],
            "cuisson": [
                "Griller les cuisses jusqu'à ce qu'elles soient dorées et bien cuites."
            ],
            "cooking_methods": {
                "best": "grill",
                "plancha": true,
                "friture": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "grill",
                "plancha"
            ],
            "accompagnement": "Servir avec du pain pita et une sauce au yaourt.",
            "detailURL": "/fiche-recette/49/poulet-shawarma/poulet-shawarma.html",
            "time_prepare": "15mn",
            "time_cooking": "30mn",
            "shortStory": "Le Poulet Shawarma est un plat emblématique du Moyen-Orient, connu pour sa marinade savoureuse et son goût juteux. Il est souvent servi dans du pain pita avec des légumes frais et une sauce au yaourt, parfait pour un repas convivial.",
            "time_maceration": "1h",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 69,
            "categorie": "afrique",
            "country": "Eswatini",
            "flag": "SZ",
            "title": "Poulet au Paprika et Gingembre",
            "subTitle": "Poulet mariné avec gingembre, ail et paprika, grillé ou braisé.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de poulet (cuisses ou morceaux)",
                "2 cuillères à soupe de paprika",
                "1 cuillère à soupe de gingembre frais râpé",
                "3 gousses d'ail écrasées",
                "1 cuillère à soupe d'huile d'olive",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "paprika",
                "gingembre",
                "ail",
                "huile-d-olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le paprika, le gingembre, l'ail écrasé, l'huile d'olive, le sel et le poivre.",
                "Enrober les morceaux de poulet de cette marinade et laisser mariner pendant 30 minutes."
            ],
            "cuisson": [
                "Préchauffer le grill ou la poêle.",
                "Griller ou braiser les morceaux de poulet pendant environ 20-25 minutes, en les retournant régulièrement pour une cuisson uniforme."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": false,
                "plancha": false
            },
            "mode_cuisson": [
                "barbecue",
                "braisé"
            ],
            "accompagnement": "Servir avec du riz pilaf ou une salade fraîche.",
            "shortStory": "**Poulet au Paprika et Gingembre** est un plat épicé et savoureux où le poulet est mariné avec un mélange de gingembre, d'ail et de paprika avant d'être grillé ou braisé.",
            "detailURL": "/fiche-recette/69/paprika-gingembre/poulet-au-paprika-et-gingembre.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "25mn",
            "crispys": true,
            "cost": {
                "level": 1,
                "description": "Plat simple et rapide à réaliser."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Légèrement épicé grâce au paprika et gingembre."
            },
            "calories": "290 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Facile, rapide et épicé."
            },
            "equipment_needed": [
                "grill",
                "poele",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 70,
            "categorie": "afrique",
            "country": "Eswatini",
            "flag": "SZ",
            "title": "Poulet à la Sauce d'Arachides",
            "subTitle": "Poulet mariné dans du citron et épices, mijoté avec une sauce aux arachides.",
            "chicken_piece": [
                "morceaux",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "morceaux",
                "entier": false,
                "aile": false,
                "blanc": true,
                "cuisse": true,
                "pilon": false,
                "manchon": false
            },
            "ingredient": [
                "500g de poulet (cuisses ou morceaux)",
                "2 cuillères à soupe de beurre de cacahuète",
                "1 oignon émincé",
                "2 gousses d'ail écrasées",
                "1 cuillère à soupe de cumin",
                "1 cuillère à soupe de paprika",
                "1 cuillère à soupe de jus de citron",
                "200ml de lait de coco",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "beurre-de-cacahuete",
                "oignon",
                "ail",
                "cumin",
                "paprika",
                "jus-de-citron",
                "lait-de-coco",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le beurre de cacahuète, l'ail, le cumin, le paprika, le jus de citron, le sel et le poivre.",
                "Enrober les morceaux de poulet de cette marinade et laisser reposer pendant 30 minutes."
            ],
            "cuisson": [
                "Dans une poêle, faire revenir l'oignon émincé et l'ail dans un peu d'huile.",
                "Ajouter les morceaux de poulet et cuire jusqu'à ce qu'ils soient dorés.",
                "Verser le lait de coco et laisser mijoter pendant 30 minutes."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "barbecue": false,
                "friture": false,
                "four": false,
                "poele": true,
                "cocotte": true,
                "plancha": false
            },
            "mode_cuisson": [
                "mijote",
                "poele"
            ],
            "accompagnement": "Servir avec du riz basmati ou des légumes grillés.",
            "shortStory": "**Poulet à la Sauce d'Arachides** est un plat savoureux où le poulet est mariné dans des épices et du citron, puis mijoté dans une sauce onctueuse à base de beurre de cacahuète et lait de coco.",
            "detailURL": "/fiche-recette/70/sauce-arachides/poulet-a-la-sauce-d-arachides.html",
            "time_prepare": "15mn",
            "time_maceration": "30mn",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Plat simple mais riche en saveurs et épices."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Légèrement épicé avec du cumin et du paprika."
            },
            "calories": "350 kcal",
            "allergens": [
                "arachides"
            ],
            "difficulty": {
                "level": 2,
                "description": "Plat modéré en difficulté, demande de la patience pour mijoter."
            },
            "equipment_needed": [
                "poele",
                "couteau",
                "planche à découper"
            ]
        },
        {
            "id": 16,
            "categorie": "asiatique",
            "country": "Thaïlande",
            "flag": "TH",
            "title": "Poulet au Curry Rouge Thaï",
            "subTitle": "Poulet tendre dans une sauce au curry épicée",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "2 cuillères à soupe de pâte de curry rouge thaï",
                "1 boîte (400 ml) de lait de coco",
                "1 poivron rouge, coupé en lanières",
                "1 oignon, coupé en quartiers",
                "2 cuillères à soupe de sauce de poisson",
                "1 cuillère à soupe de sucre de palme ou de sucre brun",
                "Feuilles de basilic frais pour garnir",
                "Riz cuit pour servir"
            ],
            "ingredient_liste": [
                "blanc-de-poulet",
                "pate-de-curry-rouge-thai",
                "lait-de-coco",
                "poivron-rouge",
                "oignon",
                "sauce-de-poisson",
                "sucre-de-palme",
                "feuilles-de-basilic",
                "riz-cuit"
            ],
            "cuisson": [
                "Dans une grande poêle, chauffer un peu d'huile à feu moyen.",
                "Ajouter la pâte de curry rouge et cuire pendant environ 1 minute pour libérer les arômes.",
                "Ajouter les blancs de poulet dans la poêle et faire revenir jusqu'à ce qu'ils soient dorés de chaque côté.",
                "Ajouter le lait de coco, le poivron rouge, l'oignon, la sauce de poisson et le sucre de palme dans la poêle. Bien mélanger pour combiner tous les ingrédients.",
                "Laisser mijoter à feu doux pendant environ 20-25 minutes, ou jusqu'à ce que le poulet soit cuit et que la sauce ait épaissi.",
                "Servir chaud sur du riz cuit et garnir de feuilles de basilic frais."
            ],
            "preparation": [
                "Couper les blancs de poulet en morceaux de taille uniforme.",
                "Couper le poivron rouge en lanières et l'oignon en quartiers.",
                "Préparer tous les ingrédients avant de commencer la cuisson pour une préparation plus fluide."
            ],
            "cooking_methods": {
                "best": "poêle",
                "plancha": false,
                "barbecue": false,
                "grill": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Ce poulet au curry rouge thaï est délicieux servi avec du riz cuit à la vapeur pour absorber la sauce épicée.",
            "shortStory": "Le poulet au curry rouge thaï est un plat classique de la cuisine thaïlandaise, apprécié pour sa saveur riche et épicée. Dans cette recette, des blancs de poulet tendres sont mijotés dans une sauce crémeuse au curry rouge, avec des poivrons rouges et des oignons pour une explosion de saveurs exotiques.",
            "detailURL": "/fiche-recette/16/poulet-curry-rouge-thai-blanc-lait-coco/poulet-curry-rouge-thai.html",
            "time_prepare": "5mn",
            "time_cooking": "25mn",
            "time_maceration": "0mn",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Le coût de cette recette est relativement élevé (niveau 3) à cause du lait de coco et de la pâte de curry rouge, mais reste abordable."
            },
            "hot": {
                "level": 3,
                "description": "Le curry rouge thaï est légèrement épicé mais offre un bon équilibre entre chaleur et douceur."
            },
            "nutrition_info": {
                "calories": "300 kcal",
                "proteins": "30g",
                "carbs": "12g",
                "fats": "18g"
            },
            "allergens": [
                "lactose"
            ],
            "storage_instructions": "Conservez les restes dans un récipient hermétique au réfrigérateur pendant 2 à 3 jours. Réchauffez au micro-ondes ou à feu doux.",
            "difficulty": {
                "level": 2,
                "description": "Cette recette est de difficulté moyenne (niveau 2) en raison de la préparation des ingrédients et du mijotage."
            },
            "equipment_needed": [
                "poêle",
                "couteau",
                "planche à découper"
            ],
            "images": [],
            "calories": null
        },
        {
            "id": 24,
            "categorie": "asiatique",
            "country": "Thaïlande",
            "flag": "TH",
            "title": "Poulet au Curry Vert",
            "subTitle": "Curry thaïlandais épicé",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet désossées et sans peau",
                "2 cuillères à soupe d'huile végétale",
                "4 cuillères à soupe de pâte de curry vert thaïlandais",
                "1 boîte de lait de coco (400 ml)",
                "1 poivron rouge, coupé en lanières",
                "1 aubergine, coupée en dés",
                "1 courgette, coupée en rondelles",
                "1 carotte, coupée en rondelles",
                "1 oignon, émincé",
                "2 gousses d'ail, émincées",
                "1 cuillère à soupe de sauce de poisson",
                "1 cuillère à soupe de sucre de palme ou de sucre de canne",
                "Feuilles de basilic thaïlandais, pour garnir"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "huile-vegetale",
                "pate-de-curry-vert",
                "lait-de-coco",
                "poivron-rouge",
                "aubergine",
                "courgette",
                "carotte",
                "oignon",
                "ail",
                "sauce-poisson",
                "sucre-palme",
                "feuilles-basilic-thailandais"
            ],
            "preparation": [
                "Dans une grande poêle ou un wok, chauffer l'huile végétale à feu moyen.",
                "Ajouter la pâte de curry vert et cuire pendant environ 1 à 2 minutes, en remuant, jusqu'à ce qu'elle devienne aromatique.",
                "Ajouter les cuisses de poulet dans la poêle et cuire pendant environ 5 minutes de chaque côté, jusqu'à ce qu'elles soient dorées.",
                "Ajouter l'oignon et l'ail dans la poêle et cuire pendant 2 à 3 minutes, jusqu'à ce qu'ils soient ramollis.",
                "Incorporer le lait de coco, la sauce de poisson et le sucre de palme dans la poêle. Porter à ébullition, puis réduire le feu et laisser mijoter pendant environ 10 minutes.",
                "Ajouter le poivron rouge, l'aubergine, la courgette et la carotte dans la poêle. Couvrir et laisser mijoter pendant encore 10 minutes, ou jusqu'à ce que les légumes soient tendres et le poulet soit bien cuit."
            ],
            "cuisson": [
                "Cuire les cuisses de poulet dans une poêle jusqu'à ce qu'elles soient dorées, puis mijoter avec les légumes dans une sauce au curry vert."
            ],
            "cooking_methods": {
                "best": "poêle",
                "plancha": false,
                "four": false,
                "grill": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Le poulet au curry vert thaïlandais est traditionnellement servi avec du riz jasmin cuit à la vapeur, mais vous pouvez également le déguster avec des nouilles de riz ou des naans. Servir le poulet au curry vert chaud, garni de feuilles de basilic thaïlandais, avec du riz parfumé.",
            "shortStory": "Le poulet au curry vert est un plat thaïlandais classique, apprécié pour sa saveur épicée et aromatique. Dans cette recette, les cuisses de poulet sont mijotées dans une sauce onctueuse au curry vert, avec un mélange de légumes colorés pour un plat délicieusement parfumé et réconfortant.",
            "detailURL": "/fiche-recette/24/poulet-au-curry-vert-thailandais/poulet-au-curry-vert.html",
            "time_prepare": "20mn",
            "time_maceration": "0mn",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 52,
            "categorie": "moyen-orient",
            "country": "Tunisie",
            "flag": "TN",
            "title": "Poulet à la Harissa",
            "subTitle": "Poulet épicé à la harissa et aux légumes",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 cuillères à soupe de harissa",
                "1 poivron rouge",
                "1 oignon",
                "2 cuillères à soupe d'huile d'olive"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "harissa",
                "poivron-rouge",
                "oignon",
                "huile-d'olive"
            ],
            "preparation": [
                "Mélanger la harissa avec l'huile d'olive.",
                "Badigeonner les cuisses de poulet avec le mélange.",
                "Ajouter les légumes coupés autour du poulet."
            ],
            "cuisson": [
                "Cuire à la plancha ou au grill jusqu'à ce que le poulet soit bien cuit."
            ],
            "cooking_methods": {
                "best": "grill",
                "plancha": true,
                "friture": false,
                "barbecue": false
            },
            "mode_cuisson": [
                "grill",
                "plancha"
            ],
            "accompagnement": "Servir avec du couscous.",
            "detailURL": "/fiche-recette/52/poulet-harissa/poulet-harissa.html",
            "time_prepare": "15mn",
            "time_cooking": "30mn",
            "shortStory": "Le Poulet à la Harissa est un plat emblématique de la cuisine tunisienne, connu pour ses saveurs épicées et sa simplicité. Ce plat réconfortant est parfait pour accompagner un bon couscous, offrant une explosion de saveurs en bouche.",
            "time_maceration": "0mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 112,
            "categorie": "asiatique",
            "country": "Turquie",
            "flag": "TR",
            "title": "Poulet à la cannelle et au citron",
            "subTitle": "Poulet mariné dans une sauce à la cannelle et citron",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet (ou 6 pilons)",
                "2 cuillères à soupe de cannelle moulue",
                "1 citron (jus et zeste)",
                "3 cuillères à soupe d'huile d'olive",
                "2 gousses d'ail hachées",
                "1 cuillère à soupe de miel",
                "Sel et poivre",
                "1 cuillère à soupe de paprika doux",
                "1 branche de thym frais"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "cannelle",
                "citron",
                "huile-d'olive",
                "ail",
                "miel",
                "paprika",
                "sel",
                "poivre",
                "thym"
            ],
            "preparation": [
                "Dans un bol, mélanger la cannelle, le jus et le zeste de citron, l'ail, le miel, le paprika, l'huile d'olive, le sel et le poivre.",
                "Badigeonner les morceaux de poulet avec cette marinade.",
                "Couvrir et laisser mariner au réfrigérateur pendant 2 heures, idéalement toute une nuit pour plus de saveurs."
            ],
            "cuisson": [
                "Préchauffer le four à 180°C.",
                "Placer les morceaux de poulet dans un plat allant au four.",
                "Arroser avec la marinade restante et ajouter le thym.",
                "Cuire pendant 45 à 50 minutes, jusqu'à ce que le poulet soit bien doré et cuit à cœur."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec du riz basmati ou une salade verte.",
            "shortStory": "Le **Poulet à la cannelle** est un plat parfumé aux saveurs sucrées et épicées, typique de la cuisine turque. La combinaison de la cannelle et du citron donne au poulet une touche à la fois chaleureuse et rafraîchissante. Ce plat est parfait pour un repas familial ou un dîner d’occasion, et il évoque les influences orientales sur la gastronomie turque.",
            "detailURL": "/fiche-recette/112/poulet-cannelle-citron/turquie-poulet-cannelle.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "50mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est modéré (niveau 2), avec des ingrédients simples mais parfumés tels que la cannelle et le miel."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette n'est pas épicée, mais offre un arôme chaleureux de cannelle et de citron."
            },
            "calories": "310 kcal",
            "allergens": [
                "miel",
                "cannelle"
            ],
            "difficulty": {
                "level": 2,
                "description": "Une recette facile à préparer, nécessitant un peu de temps pour la marinade afin de maximiser les saveurs."
            },
            "equipment_needed": [
                "four",
                "plat-de-cuisson",
                "bol"
            ]
        },
        {
            "id": 7,
            "categorie": "amerique-nord",
            "country": "États-Unis",
            "flag": "US",
            "title": "Poulet frit croustillant",
            "subTitle": "Poulet pané et frit à la perfection",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 tasses de babeurre",
                "2 cuillères à soupe de sauce piquante (facultatif)",
                "2 tasses de farine tout usage",
                "1 cuillère à soupe de paprika",
                "1 cuillère à soupe de sel",
                "1 cuillère à soupe de poivre noir",
                "1 cuillère à café de poudre d'ail",
                "1 cuillère à café de poudre d'oignon",
                "Huile végétale pour la friture"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "babeurre",
                "sauce-piquante",
                "farine-tout-usage",
                "paprika",
                "sel",
                "poivre-noir",
                "poudre-ail",
                "poudre-oignon",
                "huile-vegetale"
            ],
            "preparation": [
                "Dans un grand bol, mélanger le babeurre et la sauce piquante (si désiré). Ajouter les cuisses de poulet dans le mélange et laisser mariner pendant au moins 1 heure au réfrigérateur.",
                "Dans un autre bol, mélanger la farine, le paprika, le sel, le poivre, la poudre d'ail et la poudre d'oignon."
            ],
            "cuisson": [
                "Préchauffer l'huile végétale dans une grande poêle à feu moyen-élevé.",
                "Retirer les cuisses de poulet de la marinade et les enrober uniformément du mélange de farine.",
                "Faire frire les cuisses de poulet dans l'huile chaude pendant environ 15 à 20 minutes de chaque côté, ou jusqu'à ce qu'elles soient dorées et cuites à cœur.",
                "Égoutter le poulet frit sur du papier absorbant pour éliminer l'excès d'huile."
            ],
            "cooking_methods": {
                "best": "friture",
                "plancha": false,
                "four": false,
                "grill": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Le poulet frit croustillant est délicieux avec des frites maison, de la salade de chou, des biscuits chauds ou du maïs grillé.",
            "shortStory": "Le poulet frit croustillant est un plat classique de la cuisine américaine, apprécié pour sa croûte croustillante et sa viande tendre et juteuse à l'intérieur. Cette recette simple et réconfortante est parfaite pour un repas en famille ou une soirée entre amis.",
            "detailURL": "/fiche-recette/7/poulet-frit-croustillant-cuisses-babeurre/frit-croustillant.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "40mn",
            "crispys": true,
            "cost": {
                "level": 1,
                "description": "Le coût de cette recette est relativement bas (niveau 1), principalement composé d'ingrédients comme les cuisses de poulet, le babeurre et la farine."
            },
            "hot": {
                "level": 0,
                "description": "Cette recette est douce et n'est pas épicée, même si la sauce piquante peut être ajoutée selon le goût."
            },
            "nutrition_info": {
                "calories": "400 kcal",
                "proteins": "25g",
                "carbs": "30g",
                "fats": "20g"
            },
            "allergens": [
                "gluten",
                "lait",
                "soja"
            ],
            "storage_instructions": "Conservez le poulet frit dans un récipient hermétique au réfrigérateur pendant 2 à 3 jours. Pour une texture optimale, réchauffez-le dans une poêle ou au four.",
            "difficulty": {
                "level": 2,
                "description": "Cette recette a une difficulté moyenne (niveau 2), car elle nécessite une bonne maîtrise de la friture."
            },
            "equipment_needed": [
                "poêle",
                "bol",
                "papier-absorbant"
            ],
            "images": [],
            "calories": null
        },
        {
            "id": 13,
            "categorie": "amerique-nord",
            "country": "États-Unis",
            "flag": "US",
            "title": "Poulet BBQ du Sud",
            "subTitle": "Marinade maison style barbecue du Sud des États-Unis",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false,
                "poitrine": true
            },
            "ingredient": [
                "4 poitrines de poulet désossées et sans peau",
                "1 tasse de sauce barbecue",
                "1/4 tasse de vinaigre de cidre de pomme",
                "2 cuillères à soupe de miel",
                "2 gousses d'ail émincées",
                "1 cuillère à café de paprika fumé",
                "1 cuillère à café de poudre d'oignon",
                "1/2 cuillère à café de poivre noir",
                "1/2 cuillère à café de sel"
            ],
            "ingredient_liste": [
                "poitrine-de-poulet",
                "sauce-barbecue",
                "vinaigre-de-cidre-de-pomme",
                "miel",
                "ail-eminces",
                "paprika-fume",
                "poudre-d'oignon",
                "poivre-noir",
                "sel"
            ],
            "preparation": [
                "Dans un bol, mélanger la sauce barbecue, le vinaigre de cidre de pomme, le miel, l'ail émincé, le paprika fumé, la poudre d'oignon, le poivre noir et le sel pour faire la marinade.",
                "Ajouter les poitrines de poulet dans le bol et bien les enrober de marinade.",
                "Couvrir le bol et laisser mariner au réfrigérateur pendant au moins 4 heures, de préférence toute une nuit."
            ],
            "cuisson": [
                "Préchauffer le grill à feu moyen-élevé.",
                "Retirer les poitrines de poulet de la marinade et jeter l'excès de marinade.",
                "Griller les poitrines de poulet sur le barbecue pendant environ 6 à 8 minutes de chaque côté, jusqu'à ce qu'elles soient bien cuites et que la marinade forme une croûte caramélisée.",
                "Laisser reposer les poitrines de poulet grillées pendant quelques minutes avant de les servir.",
                "Garnir éventuellement de coriandre fraîche hachée pour plus de saveur."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "plancha": false,
                "four": false,
                "grill": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Le poulet BBQ du Sud est délicieux servi avec des épis de maïs grillés, des haricots verts sautés et une salade de chou crémeuse.",
            "shortStory": "Le poulet BBQ du Sud est une spécialité emblématique des États-Unis, particulièrement appréciée dans le Sud. Cette marinade maison, riche en saveurs fumées et sucrées, est parfaite pour donner aux poitrines de poulet une tendreté et une saveur incomparables. Idéal pour les grillades en plein air et les rassemblements entre amis !",
            "detailURL": "/fiche-recette/13/poulet-bbq-sud-poitrine-sauce-barbecue-miel/poulet-bbq-sud.html",
            "time_prepare": "15mn",
            "time_maceration": "4h",
            "time_cooking": "15mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est moyen (niveau 2), en raison de la sauce barbecue et du miel qui sont des ingrédients un peu plus chers."
            },
            "hot": {
                "level": 0,
                "description": "Le poulet BBQ du Sud est doux et légèrement sucré, sans épices fortes. Le paprika fumé ajoute de la profondeur à la marinade sans ajouter de chaleur intense."
            },
            "nutrition_info": {
                "calories": "280 kcal",
                "proteins": "30g",
                "carbs": "18g",
                "fats": "8g"
            },
            "allergens": [
                "miel",
                "gluten"
            ],
            "storage_instructions": "Conservez les restes dans un récipient hermétique au réfrigérateur pendant 2 à 3 jours. Réchauffez à feu doux ou au micro-ondes.",
            "difficulty": {
                "level": 2,
                "description": "Cette recette est de difficulté moyenne (niveau 2), car elle nécessite une marinade de longue durée, mais la cuisson est simple à gérer."
            },
            "equipment_needed": [
                "bol",
                "grill",
                "pince"
            ],
            "images": [],
            "calories": null
        },
        {
            "id": 15,
            "categorie": "amerique-nord",
            "country": "États-Unis",
            "flag": "US",
            "title": "Ailes de Poulet Buffalo",
            "subTitle": "Ailes de poulet épicées et croustillantes",
            "chicken_piece": [
                "aile"
            ],
            "piece": {
                "best": "aile",
                "entier": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "12 ailes de poulet",
                "1/2 tasse de sauce piquante style Buffalo",
                "1/4 tasse de beurre fondu",
                "1 cuillère à soupe de vinaigre blanc",
                "1/2 cuillère à café de poudre d'ail",
                "1/2 cuillère à café de paprika",
                "Sel et poivre noir, au goût",
                "Bâtonnets de céleri et trempette au fromage bleu, pour servir (facultatif)"
            ],
            "ingredient_liste": [
                "aile-de-poulet",
                "sauce-piquante",
                "beurre-fondu",
                "vinaigre-blanc",
                "poudre-d'ail",
                "paprika",
                "sel",
                "poivre-noir",
                "batonnets-de-celeri",
                "trempette-fromage-bleu"
            ],
            "preparation": [
                "Dans un grand bol, mélanger la sauce piquante, le beurre fondu, le vinaigre blanc, la poudre d'ail, le paprika, le sel et le poivre noir.",
                "Ajouter les ailes de poulet au mélange de sauce et bien enrober."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C (400°F).",
                "Disposer les ailes de poulet en une seule couche sur une plaque de cuisson tapissée de papier sulfurisé.",
                "Cuire au four préchauffé pendant 45 à 50 minutes, en retournant les ailes à mi-cuisson, jusqu'à ce qu'elles soient cuites et croustillantes."
            ],
            "cooking_methods": {
                "best": "four",
                "plancha": false,
                "barbecue": false,
                "grill": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Les ailes de poulet Buffalo sont traditionnellement servies avec des bâtonnets de céleri croquants et une trempette au fromage bleu crémeuse.",
            "shortStory": "Les ailes de poulet Buffalo sont un plat emblématique de la cuisine américaine, apprécié pour son équilibre parfait entre épices et saveurs. Enrobées d'une sauce piquante au beurre, ces ailes de poulet cuites au four sont croustillantes à l'extérieur et tendres à l'intérieur, offrant une explosion de saveurs à chaque bouchée.",
            "detailURL": "/fiche-recette/15/ailes-poulet-buffalo-sauce-piquante-beurre/ailes-poulet-buffalo.html",
            "time_prepare": "10mn",
            "time_cooking": "50mn",
            "time_maceration": "0mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Le coût de cette recette est moyen (niveau 2) en raison de l'utilisation de sauce piquante et de beurre, mais reste abordable."
            },
            "hot": {
                "level": 3,
                "description": "Les ailes de poulet Buffalo sont épicées, avec un bon équilibre de chaleur provenant de la sauce piquante."
            },
            "nutrition_info": {
                "calories": "250 kcal",
                "proteins": "18g",
                "carbs": "3g",
                "fats": "20g"
            },
            "allergens": [
                "lactose",
                "gluten"
            ],
            "storage_instructions": "Conservez les restes dans un récipient hermétique au réfrigérateur pendant 2 à 3 jours. Réchauffez au four ou au micro-ondes.",
            "difficulty": {
                "level": 2,
                "description": "Cette recette est de difficulté moyenne (niveau 2) en raison de la préparation des ailes et de la cuisson au four."
            },
            "equipment_needed": [
                "bol",
                "plaque de cuisson",
                "papier sulfurisé"
            ],
            "images": [],
            "calories": null
        },
        {
            "id": 20,
            "categorie": "amerique-nord",
            "country": "États-Unis",
            "flag": "US",
            "title": "Ailes de poulet barbecue croustillantes",
            "subTitle": "Un classique américain",
            "chicken_piece": [
                "aile"
            ],
            "piece": {
                "best": "aile",
                "entier": false,
                "aile": true,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "2 kg d'ailes de poulet",
                "2 tasses de sauce barbecue",
                "1/4 tasse de miel",
                "2 cuillères à soupe de vinaigre de cidre",
                "2 gousses d'ail, émincées",
                "1 cuillère à café de paprika",
                "Sel et poivre, au goût"
            ],
            "ingredient_liste": [
                "ailes-de-poulet",
                "sauce-barbecue",
                "miel",
                "vinaigre-de-cidre",
                "ail",
                "paprika",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un grand bol, mélanger la sauce barbecue, le miel, le vinaigre de cidre, l'ail émincé, le paprika, le sel et le poivre pour préparer la marinade.",
                "Ajouter les ailes de poulet dans le bol et bien les enrober de marinade.",
                "Couvrir et laisser mariner au réfrigérateur pendant au moins 2 heures, de préférence toute la nuit."
            ],
            "cuisson": [
                "Préchauffer le barbecue à feu moyen-élevé.",
                "Retirer les ailes de poulet de la marinade et les égoutter légèrement.",
                "Griller les ailes de poulet sur le barbecue pendant environ 20 à 25 minutes, en les retournant et en les badigeonnant de marinade de temps en temps, jusqu'à ce qu'elles soient bien cuites et caramélisées."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "plancha": false,
                "four": false,
                "grill": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Les ailes de poulet barbecue sont délicieuses accompagnées de frites croustillantes et d'une sauce ranch maison.",
            "shortStory": "Les ailes de poulet barbecue sont un plat emblématique de la cuisine américaine, apprécié pour son mélange parfait de saveurs sucrées et épicées. Dans cette recette, les ailes de poulet sont marinées dans une sauce barbecue maison, puis grillées jusqu'à ce qu'elles soient dorées et croustillantes à l'extérieur, tendres et juteuses à l'intérieur.",
            "detailURL": "/fiche-recette/20/sauce-barbecue-miel-vinaigre-de-cidre-paprika/ailes-poulet-barbecue.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "25mn",
            "crispys": true,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 27,
            "categorie": "amerique-nord",
            "country": "États-Unis",
            "flag": "US",
            "title": "Poulet au Barbecue tendre",
            "subTitle": "Ailes de poulet grillées sauce barbecue",
            "chicken_piece": [
                "aile"
            ],
            "piece": {
                "best": "aile",
                "entier": false,
                "aile": true,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "1 kg d'ailes de poulet",
                "1 tasse de sauce barbecue",
                "2 cuillères à soupe de miel",
                "2 cuillères à soupe de vinaigre de cidre",
                "1 cuillère à café de paprika",
                "1 cuillère à café de poudre d'ail",
                "Sel et poivre, au goût"
            ],
            "ingredient_liste": [
                "ailes-de-poulet",
                "sauce-barbecue",
                "miel",
                "vinaigre-de-cidre",
                "paprika",
                "ail",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Préchauffer le barbecue à feu moyen.",
                "Dans un bol, mélanger la sauce barbecue, le miel, le vinaigre de cidre, le paprika, la poudre d'ail, le sel et le poivre pour préparer la marinade.",
                "Ajouter les ailes de poulet dans le bol et bien les enrober de marinade. Laisser mariner au réfrigérateur pendant au moins 1 heure."
            ],
            "cuisson": [
                "Griller les ailes de poulet marinées sur le barbecue préchauffé pendant environ 20 à 25 minutes, en les retournant régulièrement et en les badigeonnant de marinade supplémentaire.",
                "Servir chaud et déguster avec des crudités ou des frites."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "plancha": false,
                "four": false,
                "grill": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Ces ailes de poulet au barbecue sont délicieuses servies avec une salade fraîche, des légumes grillés ou des frites croustillantes.",
            "shortStory": "Le poulet au barbecue est un classique américain apprécié pour sa saveur fumée et sa tendreté. Cette recette d'ailes de poulet marinées dans une sauce barbecue sucrée et épicée est parfaite pour un barbecue en plein air entre amis ou en famille.",
            "detailURL": "/fiche-recette/27/paprika-ail-miel/ailes-poulet-barbecue-sauce-barbecue.html",
            "time_prepare": "15mn",
            "time_maceration": "1h",
            "time_cooking": "25mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 32,
            "categorie": "amerique-nord",
            "country": "États-Unis",
            "flag": "US",
            "title": "Poulet Buffalo au four",
            "subTitle": "Ailes de poulet à la sauce piquante",
            "chicken_piece": [
                "aile"
            ],
            "piece": {
                "best": "aile",
                "entier": false,
                "aile": true,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "1 kg d'ailes de poulet",
                "1/2 tasse de sauce Buffalo",
                "1 cuillère à soupe de beurre",
                "1 cuillère à café de paprika"
            ],
            "ingredient_liste": [
                "ailes-de-poulet",
                "sauce-buffalo",
                "beurre",
                "paprika"
            ],
            "preparation": [
                "Préchauffer le four à 220°C.",
                "Mélanger les ailes de poulet avec la sauce Buffalo, le beurre et le paprika.",
                "Disposer les ailes sur une plaque et cuire au four pendant 30 minutes."
            ],
            "cuisson": [
                "Cuire au four jusqu'à ce qu'elles soient croustillantes."
            ],
            "cooking_methods": {
                "best": "four",
                "plancha": false,
                "barbecue": false,
                "friture": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec du céleri et de la sauce ranch.",
            "shortStory": "Un plat américain classique, parfait pour les soirées.",
            "detailURL": "/fiche-recette/32/poulet-buffalo/poulet-buffalo.html",
            "time_prepare": "10mn",
            "time_cooking": "30mn",
            "time_maceration": "0mn",
            "crispys": true,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 33,
            "categorie": "amerique-nord",
            "country": "États-Unis",
            "flag": "US",
            "title": "Poulet Cajun",
            "subTitle": "Poulet épicé à la mode Cajun",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "2 cuillères à soupe d'épices Cajun",
                "1 oignon",
                "2 poivrons",
                "1/4 tasse d'huile d'olive"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "epices-cajun",
                "oignon",
                "poivrons",
                "huile-d-olive"
            ],
            "preparation": [
                "Assaisonner les cuisses de poulet avec les épices Cajun.",
                "Dans une poêle, faire chauffer l'huile et cuire les cuisses jusqu'à ce qu'elles soient dorées.",
                "Ajouter l'oignon et les poivrons, cuire jusqu'à tendreté."
            ],
            "cuisson": [
                "Cuire jusqu'à ce que le poulet soit bien cuit."
            ],
            "cooking_methods": {
                "best": "poêle",
                "plancha": false,
                "barbecue": false,
                "friture": false
            },
            "mode_cuisson": [
                "poêle"
            ],
            "accompagnement": "Servir avec du riz ou des haricots.",
            "shortStory": "Un plat plein de saveurs du sud des États-Unis.",
            "detailURL": "/fiche-recette/33/poulet-cajun/poulet-cajun.html",
            "time_prepare": "15mn",
            "time_cooking": "25mn",
            "time_maceration": "0mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 34,
            "categorie": "amerique-nord",
            "country": "États-Unis",
            "flag": "US",
            "title": "Poulet BBQ à l'ananas",
            "subTitle": "Poulet mariné à l'ananas grillé",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 blancs de poulet",
                "1 tasse de jus d'ananas",
                "1/4 tasse de sauce soja",
                "2 cuillères à soupe de miel"
            ],
            "ingredient_liste": [
                "blancs-de-poulet",
                "jus-d-ananas",
                "sauce-soja",
                "miel"
            ],
            "preparation": [
                "Mélanger le jus d'ananas, la sauce soja et le miel.",
                "Mariner les blancs de poulet pendant 1 heure.",
                "Préchauffer le barbecue et cuire les blancs jusqu'à ce qu'ils soient cuits."
            ],
            "cuisson": [
                "Cuire sur le barbecue jusqu'à ce qu'ils soient bien cuits."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "plancha": false,
                "friture": false,
                "four": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Servir avec une salade de chou.",
            "shortStory": "Un mélange parfait entre le sucré et le salé.",
            "detailURL": "/fiche-recette/34/poulet-bbq-ananas/poulet-bbq-ananas.html",
            "time_prepare": "10mn",
            "time_maceration": "1h",
            "time_cooking": "20mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 101,
            "categorie": "fast-food",
            "country": "USA",
            "flag": "US",
            "title": "Tenders de Poulet",
            "subTitle": "Poulet pané croustillant",
            "chicken_piece": [
                "filet"
            ],
            "piece": {
                "best": "filet",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "500 g de filets de poulet",
                "200 g de farine",
                "2 œufs",
                "200 g de chapelure",
                "1 cuillère à café de paprika",
                "1 cuillère à café de poudre d'ail",
                "1 cuillère à café de sel",
                "1/2 cuillère à café de poivre",
                "Huile pour friture"
            ],
            "ingredient_liste": [
                "filets-de-poulet",
                "farine",
                "œufs",
                "chapelure",
                "paprika",
                "poudre-d'ail",
                "sel",
                "poivre",
                "huile"
            ],
            "preparation": [
                "Coupez les filets de poulet en bandes régulières.",
                "Dans un bol, mélangez la farine, le paprika, la poudre d'ail, le sel et le poivre.",
                "Dans un autre bol, battez les œufs.",
                "Dans un troisième bol, versez la chapelure.",
                "Trempez chaque morceau de poulet dans la farine, puis dans les œufs, et enfin dans la chapelure."
            ],
            "cuisson": [
                "Chauffez l'huile dans une poêle ou une friteuse à 180°C.",
                "Faites frire les tenders pendant 3-4 minutes de chaque côté jusqu'à ce qu'ils soient dorés.",
                "Égouttez sur du papier absorbant."
            ],
            "cooking_methods": {
                "best": "friture",
                "plancha": false,
                "friture": true,
                "barbecue": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir avec des frites et une sauce barbecue.",
            "detailURL": "/fiche-recette/101/chapelure-poudre-ail-paprika/tenders-poulet.html",
            "time_prepare": "15mn",
            "time_cooking": "10mn",
            "shortStory": "Les tenders de poulet, originaires des États-Unis, sont un incontournable de la cuisine fast-food. Ils allient tendreté et croustillant pour un plaisir gourmand simple et rapide à préparer.",
            "time_maceration": "0mn",
            "crispys": true,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 102,
            "categorie": "fusion",
            "country": "USA-Asie",
            "flag": "US",
            "title": "Tenders Coco-Curry",
            "subTitle": "Poulet pané à la noix de coco et épices au curry",
            "chicken_piece": [
                "filet"
            ],
            "piece": {
                "best": "filet",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "500 g de filets de poulet",
                "150 g de farine",
                "2 œufs",
                "150 g de chapelure",
                "50 g de noix de coco râpée",
                "1 cuillère à café de curry en poudre",
                "1 cuillère à café de sel",
                "1/2 cuillère à café de poivre",
                "Huile pour friture"
            ],
            "ingredient_liste": [
                "filets-de-poulet",
                "farine",
                "œufs",
                "chapelure",
                "noix-de-coco",
                "curry",
                "sel",
                "poivre",
                "huile"
            ],
            "preparation": [
                "Coupez les filets de poulet en bandes régulières.",
                "Dans un bol, mélangez la farine avec le curry, le sel et le poivre.",
                "Dans un autre bol, battez les œufs.",
                "Dans un troisième bol, mélangez la chapelure avec la noix de coco râpée.",
                "Trempez chaque morceau de poulet dans la farine, puis dans les œufs, et enfin dans le mélange chapelure-noix de coco."
            ],
            "cuisson": [
                "Chauffez l'huile dans une poêle ou une friteuse à 180°C.",
                "Faites frire les tenders pendant 3-4 minutes de chaque côté jusqu'à ce qu'ils soient dorés.",
                "Égouttez sur du papier absorbant."
            ],
            "cooking_methods": {
                "best": "friture",
                "plancha": false,
                "friture": true,
                "barbecue": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir avec une sauce au yaourt et mangue ou du riz basmati.",
            "detailURL": "/fiche-recette/102/tenders-coco-curry/tenders-coco-curry.html",
            "time_prepare": "20mn",
            "time_cooking": "10mn",
            "shortStory": "Les tenders coco-curry sont une délicieuse fusion des saveurs asiatiques et occidentales. La noix de coco apporte une douceur exotique, tandis que le curry relève subtilement le goût, offrant une expérience gustative originale.",
            "time_maceration": "0mn",
            "crispys": true,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 1596,
            "categorie": "fusion",
            "country": "USA",
            "flag": "US",
            "title": "Tenders Asiatiques au Sésame",
            "subTitle": "Poulet croustillant aux graines de sésame et touche asiatique",
            "chicken_piece": [
                "filet"
            ],
            "piece": {
                "best": "filet",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "500 g de filets de poulet",
                "150 g de farine",
                "2 œufs",
                "150 g de chapelure",
                "50 g de graines de sésame",
                "1 cuillère à café de gingembre en poudre",
                "1 cuillère à café de sel",
                "1/2 cuillère à café de poivre",
                "Huile pour friture"
            ],
            "ingredient_liste": [
                "filets-de-poulet",
                "farine",
                "œufs",
                "chapelure",
                "graines-de-sésame",
                "gingembre",
                "sel",
                "poivre",
                "huile"
            ],
            "preparation": [
                "Coupez les filets de poulet en bandes régulières.",
                "Dans un bol, mélangez la farine avec le gingembre en poudre, le sel et le poivre.",
                "Dans un autre bol, battez les œufs.",
                "Dans un troisième bol, mélangez la chapelure avec les graines de sésame.",
                "Trempez chaque morceau de poulet dans la farine, puis dans les œufs, et enfin dans le mélange chapelure-sésame."
            ],
            "cuisson": [
                "Chauffez l'huile dans une poêle ou une friteuse à 180°C.",
                "Faites frire les tenders pendant 3-4 minutes de chaque côté jusqu'à ce qu'ils soient dorés.",
                "Égouttez sur du papier absorbant."
            ],
            "cooking_methods": {
                "best": "friture",
                "plancha": false,
                "friture": true,
                "barbecue": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir avec une sauce soja sucrée ou une mayonnaise au wasabi.",
            "detailURL": "/fiche-recette/1596/tenders-asiatiques-sesame/tenders-asiatiques-sesame.html",
            "time_prepare": "20mn",
            "time_cooking": "10mn",
            "shortStory": "Les tenders asiatiques au sésame allient le croustillant de la chapelure et le goût unique des graines de sésame. Avec des épices comme le gingembre, ces tenders offrent une touche délicate et parfumée inspirée de la cuisine asiatique.",
            "time_maceration": "0mn",
            "crispys": true,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 19,
            "categorie": "asiatique",
            "country": "Vietnam",
            "flag": "VN",
            "title": "Poulet grillé au citron vert",
            "subTitle": "Saveurs fraîches et acidulées",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 cuisses de poulet",
                "4 gousses d'ail, émincées",
                "2 citrons verts, jus et zeste",
                "2 cuillères à soupe de sauce soja",
                "2 cuillères à soupe de miel",
                "1 cuillère à soupe d'huile d'olive",
                "1 cuillère à café de gingembre frais, râpé",
                "Sel et poivre, au goût"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "ail",
                "citrons-verts",
                "sauce-soja",
                "miel",
                "huile-d'olive",
                "gingembre-frais",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger l'ail émincé, le jus et le zeste des citrons verts, la sauce soja, le miel, l'huile d'olive, le gingembre râpé, le sel et le poivre pour préparer la marinade.",
                "Ajouter les cuisses de poulet dans le bol et bien les enrober de marinade.",
                "Couvrir et laisser mariner au réfrigérateur pendant au moins 2 heures, de préférence toute la nuit."
            ],
            "cuisson": [
                "Préchauffer le barbecue à feu moyen-élevé.",
                "Retirer les cuisses de poulet de la marinade et les égoutter légèrement.",
                "Griller les cuisses de poulet sur le barbecue pendant environ 25 à 30 minutes, en les retournant de temps en temps, jusqu'à ce qu'elles soient bien cuites et légèrement caramélisées."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "plancha": false,
                "four": false,
                "grill": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Ce poulet grillé au citron vert est délicieux servi avec du riz basmati, des légumes grillés ou une salade fraîche.",
            "shortStory": "Le poulet grillé au citron vert est un plat vietnamien classique, apprécié pour ses saveurs fraîches et acidulées. Marinées dans une sauce à base de citron vert, de miel et de sauce soja, les cuisses de poulet sont grillées jusqu'à ce qu'elles soient tendres et juteuses, offrant une expérience gustative délicieusement parfumée.",
            "detailURL": "/fiche-recette/19/cuisses-poulet-citron-vert/poulet-grill-citron-vert.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": null,
                "description": null
            },
            "images": [],
            "hot": {
                "level": null,
                "description": null
            },
            "calories": null,
            "allergens": [],
            "difficulty": {
                "level": null,
                "description": null
            },
            "equipment_needed": []
        },
        {
            "id": 5428,
            "categorie": "afrique",
            "country": "Afrique du Sud",
            "flag": "ZA",
            "title": "Poulet Peri-Peri",
            "subTitle": "Poulet mariné dans une sauce au piment, citron et ail, grillé",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon",
                "aile"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": true,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux ou 6 morceaux (cuisse, pilon, aile)",
                "4 piments rouges frais",
                "4 gousses d'ail",
                "1 citron (jus et zeste)",
                "2 cuillères à soupe de vinaigre blanc",
                "3 cuillères à soupe d'huile d'olive",
                "1 cuillère à café de paprika fumé",
                "1/2 cuillère à café de poivre de Cayenne",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "piments",
                "ail",
                "citron",
                "vinaigre-blanc",
                "huile-d'olive",
                "paprika",
                "poivre-de-cayenne",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Mixer les piments, l'ail, le jus et le zeste de citron, le vinaigre, l'huile d'olive, le paprika fumé, le poivre de Cayenne, le sel et le poivre pour obtenir une marinade.",
                "Badigeonner généreusement les morceaux de poulet avec la marinade.",
                "Laisser mariner au réfrigérateur pendant au moins 2 heures, de préférence toute une nuit."
            ],
            "cuisson": [
                "Préchauffer le grill ou le barbecue à feu moyen.",
                "Griller les morceaux de poulet pendant 30 à 40 minutes, en les retournant régulièrement et en badigeonnant avec le reste de la marinade.",
                "Servir chaud avec des quartiers de citron."
            ],
            "cooking_methods": {
                "best": "grill",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": false,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "grill",
                "barbecue"
            ],
            "accompagnement": "Servir avec des frites, une salade verte ou du pain croustillant.",
            "shortStory": "Le Chicken Peri-Peri, aussi connu sous le nom de Poulet Piri-Piri, est un plat emblématique de l'Afrique du Sud et du Mozambique. Introduit par les colons portugais qui ont apporté le piment 'Peri-Peri' d'Afrique, ce plat célèbre marie les saveurs piquantes, acidulées et fumées. Il est devenu un incontournable des grillades sud-africaines, incarnant la richesse des influences multiculturelles de la région.",
            "detailURL": "/fiche-recette/5428/poulet-piments-citron-ail/chicken-peri-peri.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Ce plat est abordable, avec des ingrédients simples comme le poulet, les piments et les épices, mais la qualité des produits influence grandement le résultat."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Ce plat est très épicé, parfait pour les amateurs de piquant. La chaleur provient principalement des piments et du poivre de Cayenne."
            },
            "calories": "360 kcal",
            "allergens": [
                "piments"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette assez simple, mais nécessitant une surveillance attentive lors de la cuisson au grill ou barbecue pour éviter que le poulet ne brûle."
            },
            "equipment_needed": [
                "grill",
                "mixeur",
                "bol",
                "pinceau de cuisine"
            ]
        },
        {
            "id": 7966,
            "categorie": "afrique",
            "country": "Afrique du Sud",
            "flag": "ZA",
            "title": "Poulet Braai",
            "subTitle": "Poulet mariné dans une sauce barbecue sud-africaine épicée, cuit au feu de bois",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon",
                "aile"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": true,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux ou 6 morceaux (cuisse, pilon, aile)",
                "1/2 tasse de ketchup",
                "1/4 tasse de vinaigre de cidre",
                "2 cuillères à soupe de miel",
                "1 cuillère à soupe de moutarde de Dijon",
                "2 gousses d'ail hachées",
                "1 cuillère à soupe de sauce Worcestershire",
                "1 cuillère à café de paprika fumé",
                "1/2 cuillère à café de piment en poudre",
                "2 cuillères à soupe d'huile d'olive",
                "Sel et poivre"
            ],
            "ingredient_liste": [
                "poulet",
                "ketchup",
                "vinaigre-de-cidre",
                "miel",
                "moutarde",
                "ail",
                "sauce-worcestershire",
                "paprika",
                "piment",
                "huile-d'olive",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un bol, mélanger le ketchup, le vinaigre de cidre, le miel, la moutarde, l'ail, la sauce Worcestershire, le paprika fumé, le piment, l'huile d'olive, le sel et le poivre.",
                "Badigeonner généreusement les morceaux de poulet avec cette marinade.",
                "Laisser mariner au réfrigérateur pendant au moins 2 heures, idéalement toute une nuit."
            ],
            "cuisson": [
                "Préparer un feu de bois ou un barbecue avec des braises bien chaudes.",
                "Griller les morceaux de poulet à feu moyen, en les retournant régulièrement et en les badigeonnant avec le reste de la marinade.",
                "Cuire pendant 30 à 40 minutes jusqu'à ce que le poulet soit bien cuit et légèrement caramélisé."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": false,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "barbecue"
            ],
            "accompagnement": "Servir avec des pommes de terre en robe des champs ou une salade coleslaw.",
            "shortStory": "Le **Poulet Braai** est une pièce centrale de la culture du braai, une tradition sud-africaine de barbecue au feu de bois qui va au-delà de la cuisine pour devenir un moment de partage et de convivialité. Inspirée des saveurs locales et enrichie par des influences globales, cette recette marie parfaitement le sucré, l’épicé et le fumé pour un poulet savoureux et juteux.",
            "detailURL": "/fiche-recette/7966/poulet-ketchup-vinaigre-miel/braai-chicken.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Ce plat est économique grâce à l'utilisation d'ingrédients accessibles comme le ketchup et les épices, mais le coût peut varier en fonction de la qualité du poulet et du bois utilisé."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Ce plat a une chaleur modérée, grâce à l'ajout de piment et de paprika fumé, parfait pour rehausser les saveurs sans être trop intense."
            },
            "calories": "375 kcal",
            "allergens": [
                "moutarde",
                "sauce Worcestershire (peut contenir des traces de poisson)"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette simple, mais nécessitant une bonne maîtrise de la cuisson au feu de bois pour éviter que le poulet ne soit trop cuit ou brûlé."
            },
            "equipment_needed": [
                "barbecue",
                "bol",
                "pinceau de cuisine"
            ]
        },
        {
            "id": 122,
            "categorie": "europe",
            "country": "Grèce",
            "flag": "GR",
            "title": "Poulet Kotopoulo Lemonato",
            "subTitle": "Poulet rôti au citron, à l'ail et aux herbes méditerranéennes",
            "chicken_piece": [
                "entier",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier d'environ 1,5 kg (ou 4 cuisses et 2 blancs)",
                "4 citrons (jus et zeste de 2 citrons, 2 citrons coupés en quartiers)",
                "6 gousses d'ail écrasées",
                "1/4 tasse d'huile d'olive extra vierge",
                "2 cuillères à soupe d'origan frais (ou 1 cuillère à soupe séché)",
                "1 cuillère à soupe de thym frais (ou 1 cuillère à café séché)",
                "1 cuillère à soupe de romarin frais (ou 1 cuillère à café séché)",
                "1 kg de pommes de terre, pelées et coupées en gros morceaux",
                "1 oignon moyen coupé en quartiers",
                "200 ml de bouillon de poulet",
                "100 ml de vin blanc sec",
                "Sel et poivre noir",
                "1 cuillère à café de paprika doux"
            ],
            "ingredient_liste": [
                "poulet",
                "citron",
                "ail",
                "huile-olive",
                "origan",
                "thym",
                "romarin",
                "pommes-de-terre",
                "oignon",
                "bouillon-poulet",
                "vin-blanc",
                "sel",
                "poivre",
                "paprika"
            ],
            "preparation": [
                "Préchauffer le four à 180°C.",
                "Dans un bol, mélanger le jus et le zeste de 2 citrons, l'huile d'olive, l'ail écrasé, l'origan, le thym, le romarin, le sel, le poivre et le paprika.",
                "Si vous utilisez un poulet entier, le laver et le sécher, puis le placer dans un grand plat allant au four.",
                "Frotter le poulet avec la moitié du mélange d'huile et de citron, y compris sous la peau.",
                "Disposer les pommes de terre et les oignons autour du poulet.",
                "Verser le reste du mélange d'huile et de citron sur les légumes, puis ajouter le bouillon et le vin blanc.",
                "Placer les quartiers de citron restants dans la cavité du poulet (si entier) ou entre les morceaux."
            ],
            "cuisson": [
                "Cuire au four pendant 30 minutes à 180°C.",
                "Retirer le plat du four, retourner les pommes de terre et arroser le poulet avec le jus de cuisson.",
                "Augmenter la température du four à 200°C et poursuivre la cuisson pendant 30-40 minutes supplémentaires, jusqu'à ce que le poulet soit doré et que les pommes de terre soient tendres.",
                "Vérifier la cuisson en insérant un couteau dans la partie la plus épaisse de la cuisse - le jus doit être clair, non rosé.",
                "Laisser reposer 10 minutes avant de servir pour que les jus se redistribuent."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec une salade grecque traditionnelle. Pour la salade: mélanger 3 tomates coupées en quartiers, 1 concombre en dés, 1 poivron vert émincé, 1 oignon rouge en fines lamelles, 200g de feta émiettée et 10 olives noires. Assaisonner avec 3 cuillères à soupe d'huile d'olive, 1 cuillère à soupe de vinaigre de vin rouge, 1 cuillère à café d'origan séché, du sel et du poivre.",
            "shortStory": "Le **Kotopoulo Lemonato** (poulet au citron) est un plat familial grec traditionnel, souvent préparé le dimanche lorsque toute la famille se réunit autour de la table. La combinaison du citron, de l'ail et des herbes méditerranéennes crée un profil de saveur classique de la cuisine grecque. Dans les foyers grecs, ce plat est symbole de convivialité et de chaleur familiale. L'utilisation généreuse d'huile d'olive extra vierge et d'herbes fraîches reflète l'essence de la cuisine méditerranéenne, connue pour sa simplicité et sa fraîcheur. Les pommes de terre rôties qui accompagnent le poulet absorbent les jus de cuisson parfumés, créant un accompagnement savoureux qui complète parfaitement le plat principal.",
            "detailURL": "/fiche-recette/122/poulet-citron-origan-ail/poulet-kotopoulo-lemonato.html",
            "time_prepare": "20mn",
            "time_maceration": "0h",
            "time_cooking": "70mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût modéré (niveau 2), utilisant des ingrédients méditerranéens courants et accessibles."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette n'est pas épicée, mais plutôt parfumée grâce aux herbes et au citron, offrant une saveur vive et aromatique."
            },
            "calories": "390 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Une recette facile à préparer, qui demande principalement du temps de cuisson au four avec peu d'intervention active."
            },
            "equipment_needed": [
                "four",
                "plat-a-rotir",
                "bol"
            ]
        },
        {
            "id": 123,
            "categorie": "europe",
            "country": "Grèce",
            "flag": "GR",
            "title": "Poulet Souvlaki",
            "subTitle": "Brochettes de poulet marinées aux herbes et au citron",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "750g de blanc de poulet (ou de cuisse désossée), coupés en cubes de 3 cm",
                "3 cuillères à soupe d'huile d'olive extra vierge",
                "2 citrons (jus et zeste d'un citron)",
                "3 gousses d'ail émincées",
                "2 cuillères à soupe d'origan séché",
                "1 cuillère à café de thym séché",
                "1 cuillère à café de paprika doux",
                "1 cuillère à soupe de yaourt grec",
                "1 cuillère à café de miel",
                "Sel et poivre noir",
                "1 poivron rouge coupé en morceaux (pour les brochettes)",
                "1 poivron vert coupé en morceaux (pour les brochettes)",
                "1 oignon rouge coupé en quartiers (pour les brochettes)"
            ],
            "ingredient_liste": [
                "blanc-de-poulet",
                "cuisse-de-poulet",
                "huile-olive",
                "citron",
                "ail",
                "origan",
                "thym",
                "paprika",
                "yaourt-grec",
                "miel",
                "sel",
                "poivre",
                "poivron-rouge",
                "poivron-vert",
                "oignon-rouge"
            ],
            "preparation": [
                "Dans un grand bol, mélanger l'huile d'olive, le jus et le zeste de citron, l'ail émincé, l'origan, le thym, le paprika, le yaourt grec, le miel, le sel et le poivre.",
                "Ajouter les cubes de poulet et bien les enrober de marinade.",
                "Couvrir et réfrigérer pendant au moins 2 heures, idéalement toute la nuit.",
                "Si vous utilisez des brochettes en bois, les faire tremper dans l'eau pendant 30 minutes avant utilisation pour éviter qu'elles ne brûlent."
            ],
            "cuisson": [
                "Préchauffer le grill, le barbecue ou le four (mode grill) à température élevée.",
                "Retirer le poulet de la marinade et le monter sur des brochettes en alternant avec les morceaux de poivron rouge, poivron vert et oignon rouge.",
                "Griller les brochettes pendant 10-12 minutes, en les retournant régulièrement, jusqu'à ce que le poulet soit bien cuit et légèrement caramélisé sur les bords.",
                "Si vous utilisez le four, placer les brochettes sur une plaque de cuisson recouverte de papier aluminium et cuire sous le grill pendant 15-20 minutes, en les retournant à mi-cuisson."
            ],
            "cooking_methods": {
                "best": "grill",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "grill",
                "barbecue",
                "four"
            ],
            "accompagnement": "Servir avec du pain pita chaud, du tzatziki et une salade. Pour le tzatziki: râper 1 concombre et presser pour éliminer l'excès d'eau, mélanger avec 300g de yaourt grec, 2 gousses d'ail émincées, 1 cuillère à soupe d'huile d'olive, 1 cuillère à soupe de menthe fraîche hachée (ou d'aneth), du sel et du poivre.",
            "shortStory": "Le **Poulet Souvlaki** est l'un des plats les plus emblématiques de la cuisine grecque, populaire tant dans les tavernes locales que comme street food. Le terme 'souvlaki' vient du mot grec 'souvla' qui signifie 'broche' ou 'brochette'. Traditionnellement, ce plat était préparé avec du porc, mais la version au poulet est devenue très appréciée pour sa légèreté. En Grèce, le souvlaki est souvent servi dans un pain pita avec de la tomate, de l'oignon et une généreuse portion de tzatziki, créant ainsi un repas complet et savoureux. Ce plat représente parfaitement l'art grec de la cuisine simple mais délicieuse, où quelques ingrédients frais et de qualité suffisent à créer des saveurs mémorables.",
            "detailURL": "/fiche-recette/123/poulet-brochette-citron-herbes/poulet-souvlaki.html",
            "time_prepare": "20mn",
            "time_maceration": "2h",
            "time_cooking": "15mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Cette recette est économique (niveau 1), utilisant des ingrédients de base et accessibles qui peuvent facilement être trouvés dans la plupart des supermarchés."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette n'est pas épicée, mais plutôt aromatique grâce aux herbes méditerranéennes et au citron qui donnent une saveur fraîche et vive."
            },
            "calories": "290 kcal",
            "allergens": [
                "lait"
            ],
            "difficulty": {
                "level": 1,
                "description": "Une recette facile à préparer, la principale étape étant la marinade qui demande simplement du temps mais peu d'effort actif."
            },
            "equipment_needed": [
                "brochettes",
                "grill",
                "barbecue",
                "bol"
            ]
        },
        {
            "id": 103,
            "categorie": "europe",
            "country": "Géorgie",
            "flag": "GE",
            "title": "Chkmeruli",
            "subTitle": "Poulet mariné à l'ail et au lait fermenté",
            "chicken_piece": [
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "1 poulet entier (environ 1,5 kg), coupé en morceaux",
                "8 gousses d'ail émincées",
                "250ml de lait fermenté ou de yaourt grec",
                "2 cuillères à soupe de beurre",
                "1 cuillère à soupe d'huile végétale",
                "1 cuillère à soupe de coriandre moulue",
                "1 cuillère à café de sel",
                "1/2 cuillère à café de poivre noir",
                "1 cuillère à café de fenugrec moulu (optionnel)",
                "1 cuillère à soupe de coriandre fraîche hachée"
            ],
            "ingredient_liste": [
                "poulet-entier",
                "ail",
                "lait-fermenté",
                "yaourt-grec",
                "beurre",
                "huile-végétale",
                "coriandre-moulue",
                "sel",
                "poivre",
                "fenugrec",
                "coriandre-fraîche"
            ],
            "preparation": [
                "Frotter le poulet avec la coriandre moulue, le sel et le poivre.",
                "Dans un bol, mélanger la moitié de l'ail émincé avec l'huile végétale.",
                "Badigeonner le poulet avec ce mélange et laisser mariner au réfrigérateur pendant 1 heure.",
                "Dans un autre bol, mélanger le lait fermenté, le reste de l'ail, le fenugrec (si utilisé) et la coriandre fraîche."
            ],
            "cuisson": [
                "Préchauffer le four à 200°C.",
                "Dans une grande poêle allant au four, faire fondre le beurre à feu moyen-vif.",
                "Saisir les morceaux de poulet jusqu'à ce qu'ils soient dorés des deux côtés.",
                "Verser la sauce au lait fermenté sur le poulet.",
                "Enfourner et cuire pendant 30-35 minutes, jusqu'à ce que le poulet soit bien cuit et que la sauce ait légèrement réduit."
            ],
            "cooking_methods": {
                "best": "four",
                "plancha": false,
                "barbecue": false,
                "friture": false,
                "four": true,
                "poêle": true,
                "cocotte": true
            },
            "mode_cuisson": [
                "four",
                "poêle"
            ],
            "accompagnement": "Servir avec du pain géorgien (shoti) ou des pommes de terre rôties.",
            "shortStory": "Le Chkmeruli est un plat traditionnel géorgien originaire de la région de Racha. Cette recette de poulet nappé d'une sauce crémeuse à l'ail est un classique de la cuisine géorgienne, pays réputé pour ses plats savoureux et son utilisation généreuse d'herbes et d'épices.",
            "detailURL": "/fiche-recette/103/chkmeruli/chkmeruli.html",
            "time_prepare": "20mn",
            "time_cooking": "40mn",
            "time_maceration": "60mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Moyen"
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Doux"
            },
            "calories": 480,
            "allergens": [
                "Produits laitiers"
            ],
            "difficulty": {
                "level": 2,
                "description": "Intermédiaire"
            },
            "equipment_needed": [
                "Poêle compatible four",
                "Four"
            ]
        },
        {
            "id": 118,
            "categorie": "europe",
            "country": "Géorgie",
            "flag": "GE",
            "title": "Poulet Satsivi",
            "subTitle": "Poulet poché dans une sauce crémeuse aux noix et épices",
            "chicken_piece": [
                "entier",
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier d'environ 1,5 kg (ou 4 blancs et 4 cuisses)",
                "300g de noix de Grenoble concassées",
                "4 oignons moyens",
                "5 gousses d'ail",
                "2 cuillères à café de graines de fenugrec moulues",
                "1 cuillère à café de coriandre moulue",
                "1 cuillère à café de curcuma",
                "1/2 cuillère à café de cannelle moulue",
                "1/4 cuillère à café de clou de girofle moulu",
                "1/2 cuillère à café de piment rouge (selon goût)",
                "75ml de vinaigre de vin blanc",
                "500ml de bouillon de poulet",
                "Sel et poivre noir",
                "2 cuillères à soupe de persil frais haché",
                "2 cuillères à soupe de coriandre fraîche hachée"
            ],
            "ingredient_liste": [
                "poulet",
                "noix-de-grenoble",
                "oignon",
                "ail",
                "fenugrec",
                "coriandre",
                "curcuma",
                "cannelle",
                "girofle",
                "piment",
                "vinaigre",
                "bouillon-poulet",
                "sel",
                "poivre",
                "persil",
                "coriandre-fraiche"
            ],
            "preparation": [
                "Rincer et sécher le poulet, puis le couper en morceaux.",
                "Dans une grande marmite, placer le poulet et couvrir d'eau froide.",
                "Ajouter 2 oignons coupés en quartiers et 2 gousses d'ail écrasées.",
                "Porter à ébullition, puis baisser le feu et laisser frémir pendant 40-45 minutes jusqu'à ce que le poulet soit tendre.",
                "Pendant ce temps, faire griller les noix à sec dans une poêle pendant 2 minutes pour intensifier leur saveur.",
                "Dans un mixeur, réduire les noix en pâte fine en ajoutant progressivement un peu de bouillon de poulet."
            ],
            "cuisson": [
                "Retirer le poulet du bouillon et laisser refroidir.",
                "Filtrer 500ml du bouillon de cuisson et réserver.",
                "Dans une poêle, faire revenir les 2 oignons restants finement émincés jusqu'à ce qu'ils soient translucides.",
                "Ajouter les 3 gousses d'ail restantes hachées, puis toutes les épices (fenugrec, coriandre, curcuma, cannelle, clou de girofle, piment).",
                "Faire revenir pendant 1 minute pour libérer les arômes.",
                "Ajouter le vinaigre et laisser réduire de moitié.",
                "Incorporer la pâte de noix et mélanger.",
                "Verser progressivement le bouillon réservé en remuant constamment jusqu'à obtenir une sauce lisse et crémeuse.",
                "Laisser mijoter à feu doux pendant 10 minutes.",
                "Retirer la peau du poulet et disposer les morceaux dans un plat de service.",
                "Verser la sauce aux noix sur le poulet et servir à température ambiante, traditionnellement."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": true,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte",
                "poele"
            ],
            "accompagnement": "Servir avec du pain géorgien (shotis puri) ou du riz pilaf. Pour le riz pilaf géorgien: faire revenir 300g de riz avec 1 oignon émincé dans du beurre, ajouter une pincée de safran et 600ml de bouillon, cuire à couvert pendant 15-20 minutes jusqu'à absorption.",
            "shortStory": "Le **Poulet Satsivi** est un plat emblématique de la cuisine géorgienne, traditionnellement servi froid lors des fêtes de fin d'année et des célébrations. La sauce aux noix, riche et onctueuse, est parfumée avec un mélange d'épices typiquement géorgiennes. Le terme 'satsivi' vient du mot géorgien pour 'froid', car ce plat est généralement préparé à l'avance et servi à température ambiante, permettant aux saveurs de se développer pleinement. Cette recette est un parfait exemple de l'utilisation généreuse des noix et des épices qui caractérise la cuisine du Caucase.",
            "detailURL": "/fiche-recette/118/poulet-noix-epices/poulet-satsivi.html",
            "time_prepare": "30mn",
            "time_maceration": "0h",
            "time_cooking": "60mn",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Cette recette est d'un coût moyen à élevé (niveau 3) en raison de la quantité importante de noix et du mélange d'épices variées nécessaires."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette est légèrement épicée, avec une chaleur douce provenant des différentes épices. Le niveau d'épice peut être ajusté selon les préférences."
            },
            "calories": "480 kcal",
            "allergens": [
                "noix"
            ],
            "difficulty": {
                "level": 3,
                "description": "Une recette de difficulté moyenne à élevée, nécessitant plusieurs étapes de préparation et une attention particulière à la consistance de la sauce aux noix."
            },
            "equipment_needed": [
                "marmite",
                "poele",
                "mixeur",
                "passoire"
            ]
        },
        {
            "id": 119,
            "categorie": "europe",
            "country": "Géorgie",
            "flag": "GE",
            "title": "Poulet Chakhokhbili",
            "subTitle": "Ragoût de poulet aux herbes fraîches et tomates",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux (ou 6 cuisses/pilons)",
                "4 oignons moyens émincés",
                "6 tomates mûres concassées",
                "1 poivron rouge émincé",
                "1 poivron vert émincé",
                "4 gousses d'ail hachées",
                "1 bouquet de coriandre fraîche",
                "1 bouquet de persil frais",
                "1 bouquet de basilic frais",
                "1 bouquet d'aneth frais",
                "1 cuillère à café de graines de fenugrec",
                "2 cuillères à café de paprika doux",
                "1 cuillère à café de piment d'Espelette",
                "3 cuillères à soupe d'huile d'olive",
                "1 cuillère à soupe de concentré de tomate",
                "Sel et poivre noir"
            ],
            "ingredient_liste": [
                "poulet",
                "oignon",
                "tomate",
                "poivron-rouge",
                "poivron-vert",
                "ail",
                "coriandre",
                "persil",
                "basilic",
                "aneth",
                "fenugrec",
                "paprika",
                "piment-espelette",
                "huile-olive",
                "concentre-tomate",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Laver et sécher les morceaux de poulet, puis les assaisonner généreusement avec du sel et du poivre.",
                "Émincer finement les oignons et les poivrons.",
                "Concasser les tomates fraîches.",
                "Hacher finement les herbes fraîches (coriandre, persil, basilic, aneth), en réservant quelques brins entiers pour la décoration."
            ],
            "cuisson": [
                "Dans une grande cocotte, chauffer l'huile d'olive à feu moyen-vif.",
                "Faire dorer les morceaux de poulet de tous côtés pendant environ 7-8 minutes, puis les réserver.",
                "Dans la même cocotte, faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter l'ail haché et les poivrons, puis faire revenir pendant 2-3 minutes.",
                "Incorporer les graines de fenugrec, le paprika et le piment d'Espelette, puis mélanger pendant 30 secondes.",
                "Ajouter les tomates concassées et le concentré de tomate, puis laisser mijoter 5 minutes.",
                "Remettre les morceaux de poulet dans la cocotte et couvrir à moitié avec de l'eau si nécessaire.",
                "Couvrir et laisser mijoter à feu doux pendant 35-40 minutes, jusqu'à ce que le poulet soit tendre.",
                "5 minutes avant la fin de la cuisson, ajouter la moitié des herbes fraîches hachées et mélanger.",
                "Avant de servir, parsemer du reste des herbes fraîches hachées."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du ghomi (polenta géorgienne). Pour le ghomi: porter à ébullition 1L d'eau salée, ajouter 250g de semoule de maïs en pluie en remuant, cuire à feu doux pendant 20-25 minutes en remuant régulièrement, puis incorporer 100g de fromage sulguni (ou mozzarella) râpé.",
            "shortStory": "Le **Poulet Chakhokhbili** est un plat rustique géorgien dont le nom vient du mot 'khokhobi', qui signifie 'faisan' en géorgien, car la recette était traditionnellement préparée avec cet oiseau. Aujourd'hui, le poulet est plus couramment utilisé. Ce ragoût riche en tomates et herbes fraîches illustre parfaitement l'importance des herbes aromatiques dans la cuisine géorgienne. Servi traditionnellement dans un plat en terre cuite, le Chakhokhbili est un repas familial convivial qui exprime la générosité de la table géorgienne et sa passion pour les saveurs fraîches et riches.",
            "detailURL": "/fiche-recette/119/poulet-tomate-herbes/poulet-chakhokhbili.html",
            "time_prepare": "25mn",
            "time_maceration": "0h",
            "time_cooking": "50mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût modéré (niveau 2), utilisant principalement des ingrédients de saison, bien que la variété d'herbes fraîches puisse légèrement augmenter le coût."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette est légèrement épicée grâce au piment d'Espelette, offrant une chaleur douce qui ne domine pas les autres saveurs."
            },
            "calories": "360 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Une recette de difficulté moyenne qui requiert principalement du temps pour la préparation des ingrédients et la surveillance du mijotage."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche-a-decouper"
            ]
        },
        {
            "id": 126,
            "categorie": "amerique-centrale",
            "country": "Guatemala",
            "flag": "GT",
            "title": "Poulet Pepián",
            "subTitle": "Ragoût traditionnel de poulet aux graines de citrouille et piments",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux (ou 6 cuisses/pilons)",
                "100g de graines de citrouille (pepitoria)",
                "3 tomates moyennes",
                "2 tomatillos (tomates vertes, facultatif)",
                "2 piments guajillo séchés, épépinés",
                "1 piment pasilla séché, épépiné",
                "1 piment chipotle séché (facultatif pour plus de chaleur)",
                "2 oignons moyens, coupés en quartiers",
                "4 gousses d'ail",
                "1/2 tasse de sésame",
                "1 cuillère à café de graines de coriandre",
                "1 bâton de cannelle",
                "3 clous de girofle",
                "1 cuillère à café de piment doux",
                "2 litres de bouillon de poulet",
                "2 cuillères à soupe d'huile végétale",
                "Sel et poivre",
                "1 bouquet de coriandre fraîche pour garnir"
            ],
            "ingredient_liste": [
                "poulet",
                "graines-de-citrouille",
                "tomate",
                "tomatillo",
                "piment-guajillo",
                "piment-pasilla",
                "piment-chipotle",
                "oignon",
                "ail",
                "sesame",
                "coriandre-graine",
                "cannelle",
                "girofle",
                "piment-doux",
                "bouillon-poulet",
                "huile-vegetale",
                "sel",
                "poivre",
                "coriandre-fraiche"
            ],
            "preparation": [
                "Laver et sécher les morceaux de poulet, puis les assaisonner avec du sel et du poivre.",
                "Dans une poêle sèche à feu moyen, faire griller les graines de citrouille jusqu'à ce qu'elles commencent à gonfler et à dorer, environ 3-4 minutes. Réserver.",
                "Dans la même poêle, faire griller les graines de sésame jusqu'à ce qu'elles soient dorées, environ 2 minutes. Réserver.",
                "Faire rôtir les tomates, les tomatillos, les oignons et l'ail dans une poêle sèche ou sur un gril jusqu'à ce qu'ils soient légèrement carbonisés.",
                "Faire griller brièvement les piments séchés (10-15 secondes de chaque côté), puis les faire tremper dans de l'eau chaude pendant 20 minutes pour les réhydrater."
            ],
            "cuisson": [
                "Dans une grande marmite, faire chauffer l'huile à feu moyen-vif.",
                "Faire dorer les morceaux de poulet de tous côtés, environ 5 minutes, puis réserver.",
                "Dans un mixeur ou un robot culinaire, combiner les graines de citrouille grillées, les graines de sésame, les tomates et tomatillos rôtis, les oignons, l'ail, les piments réhydratés (égouttés), les graines de coriandre, la cannelle, les clous de girofle et le piment doux.",
                "Ajouter 1 tasse de bouillon et mixer jusqu'à obtenir une pâte lisse.",
                "Remettre les morceaux de poulet dans la marmite et ajouter la pâte d'épices.",
                "Verser le reste du bouillon et porter à ébullition.",
                "Réduire le feu et laisser mijoter pendant 45-50 minutes, jusqu'à ce que le poulet soit tendre et que la sauce ait épaissi.",
                "Goûter et ajuster l'assaisonnement avec du sel et du poivre."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": true,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte",
                "poele"
            ],
            "accompagnement": "Servir avec du riz blanc et des tortillas de maïs. Pour le riz: faire revenir 300g de riz rincé dans 2 cuillères à soupe d'huile avec 1/2 oignon émincé jusqu'à ce qu'il devienne translucide, ajouter 600ml d'eau, 1 cuillère à café de sel et 1 feuille de laurier, couvrir et cuire à feu doux pendant 15-20 minutes.",
            "shortStory": "Le **Poulet Pepián** est considéré comme l'un des plats nationaux du Guatemala, avec des racines qui remontent à l'époque précolombienne. Cette sauce complexe mélange des ingrédients indigènes, comme les graines de citrouille, avec des influences espagnoles comme la cannelle et les clous de girofle. Le terme 'pepián' vient de 'pepitoria', faisant référence aux graines de citrouille qui donnent à ce plat sa texture crémeuse et son goût unique. Traditionnellement servi lors des fêtes et célébrations, ce ragoût représente le mélange des cultures maya et espagnole qui caractérise la cuisine guatémaltèque. Chaque région du pays a sa propre variation, mais le pepián de la région d'Antigua est particulièrement renommé.",
            "detailURL": "/fiche-recette/126/poulet-graines-citrouille-piments/poulet-pepian.html",
            "time_prepare": "40mn",
            "time_maceration": "0h",
            "time_cooking": "60mn",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Cette recette est d'un coût moyen à élevé (niveau 3) en raison des nombreuses épices et ingrédients spécifiques comme les différents types de piments séchés qui peuvent être difficiles à trouver dans certaines régions."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette est modérément épicée, avec une chaleur complexe provenant des différents piments. L'intensité peut être ajustée selon les préférences."
            },
            "calories": "410 kcal",
            "allergens": [
                "sesame"
            ],
            "difficulty": {
                "level": 3,
                "description": "Une recette de difficulté moyenne à élevée, nécessitant plusieurs étapes de préparation et une attention particulière à la torréfaction des graines et à la préparation de la sauce."
            },
            "equipment_needed": [
                "marmite",
                "poele",
                "mixeur",
                "grill"
            ]
        },
        {
            "id": 127,
            "categorie": "amerique-centrale",
            "country": "Guatemala",
            "flag": "GT",
            "title": "Poulet Jocon",
            "subTitle": "Poulet mijoté dans une sauce verte aux tomatillos et aux herbes fraîches",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux (ou 6 cuisses/pilons)",
                "500g de tomatillos (ou tomates vertes)",
                "1 bouquet de coriandre fraîche (environ 1 tasse)",
                "1 bouquet de persil frais (environ 1 tasse)",
                "3 piments jalapeños verts (facultatif, selon le goût)",
                "2 oignons moyens, coupés en quartiers",
                "6 gousses d'ail",
                "2 cuillères à soupe de graines de citrouille (pepitoria) moulues",
                "1 cuillère à soupe de graines de sésame moulues",
                "1 pain dur de maïs (tortilla) grillé et émietté",
                "500ml de bouillon de poulet",
                "2 cuillères à soupe d'huile végétale",
                "1 cuillère à café de cumin moulu",
                "Sel et poivre",
                "2 feuilles de laurier"
            ],
            "ingredient_liste": [
                "poulet",
                "tomatillo",
                "coriandre",
                "persil",
                "piment-jalapeno",
                "oignon",
                "ail",
                "graines-de-citrouille",
                "sesame",
                "tortilla-mais",
                "bouillon-poulet",
                "huile-vegetale",
                "cumin",
                "sel",
                "poivre",
                "laurier"
            ],
            "preparation": [
                "Laver et sécher les morceaux de poulet, puis les assaisonner avec du sel, du poivre et du cumin.",
                "Retirer les pelures des tomatillos, les laver (ils sont collants) et les couper en quartiers.",
                "Laver et hacher grossièrement la coriandre et le persil, en réservant quelques feuilles pour la garniture.",
                "Épépiner les jalapeños si vous préférez moins de piquant.",
                "Faire griller la tortilla sur une poêle sèche jusqu'à ce qu'elle soit croustillante, puis l'émietter."
            ],
            "cuisson": [
                "Dans une grande marmite, faire chauffer l'huile à feu moyen-vif.",
                "Faire dorer les morceaux de poulet de tous côtés, environ 5 minutes, puis réserver.",
                "Dans la même marmite, faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter l'ail et faire revenir pendant 1 minute supplémentaire.",
                "Dans un mixeur, combiner les tomatillos, la coriandre, le persil, les jalapeños, les graines de citrouille moulues, les graines de sésame et la tortilla émiettée.",
                "Ajouter environ 1 tasse de bouillon et mixer jusqu'à obtenir une sauce lisse et verte.",
                "Verser cette sauce dans la marmite avec les oignons et l'ail, et remuer.",
                "Remettre les morceaux de poulet dans la marmite, ajouter les feuilles de laurier et le reste du bouillon si nécessaire pour couvrir le poulet.",
                "Porter à ébullition, puis réduire le feu et laisser mijoter à couvert pendant 35-40 minutes, jusqu'à ce que le poulet soit tendre.",
                "Si la sauce est trop liquide, laisser mijoter à découvert pendant quelques minutes pour qu'elle épaississe."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc et des tamales. Pour les tamales: mélanger 500g de masa harina (farine de maïs nixtamalisé) avec 350ml d'eau tiède et 150g de saindoux fondu, une pincée de sel et une cuillère à café de levure chimique. Étaler la pâte sur des feuilles de maïs, garnir d'un morceau de poulet et d'un peu de sauce, replier et cuire à la vapeur pendant 1 heure.",
            "shortStory": "Le **Poulet Jocon** est un plat emblématique de la région de Huehuetenango au Guatemala, reflétant l'héritage maya de la cuisine guatémaltèque. Le nom 'jocon' viendrait d'un mot maya-quiché désignant une préparation à base d'herbes. Cette sauce verte, dense et parfumée, est caractéristique des hautes terres du Guatemala où les herbes fraîches et les tomatillos poussent en abondance. Traditionnellement préparé pour les occasions spéciales comme les mariages et les baptêmes, le jocon est devenu un plat populaire dans tout le pays. Sa belle couleur verte et son goût frais et herbacé en font un plat distinctif qui célèbre les saveurs autochtones de cette région d'Amérique centrale.",
            "detailURL": "/fiche-recette/127/poulet-sauce-verte-tomatillo-herbes/poulet-jocon.html",
            "time_prepare": "30mn",
            "time_maceration": "0h",
            "time_cooking": "50mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût modéré (niveau 2), bien que certains ingrédients comme les tomatillos puissent être remplacés par des tomates vertes si nécessaire."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette est modérément épicée grâce aux jalapeños. Le niveau d'épice peut être facilement ajusté en augmentant ou en diminuant la quantité de piments utilisée."
            },
            "calories": "380 kcal",
            "allergens": [
                "sesame"
            ],
            "difficulty": {
                "level": 2,
                "description": "Une recette de difficulté moyenne qui demande principalement du temps pour la préparation des ingrédients et la surveillance du mijotage."
            },
            "equipment_needed": [
                "marmite",
                "mixeur",
                "poele"
            ]
        },
        {
            "id": 107,
            "categorie": "afrique",
            "country": "Ghana",
            "flag": "GH",
            "title": "Poulet Yassa",
            "subTitle": "Poulet mariné aux oignons et au citron",
            "chicken_piece": [
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "8 morceaux de poulet (cuisses et pilons)",
                "6 oignons jaunes émincés",
                "4 citrons (jus)",
                "2 cuillères à soupe de moutarde de Dijon",
                "4 gousses d'ail émincées",
                "2 cuillères à soupe d'huile d'arachide",
                "2 feuilles de laurier",
                "1 piment scotch bonnet (ou habanero), épépiné et émincé",
                "1 cuillère à café de sel",
                "1/2 cuillère à café de poivre noir",
                "1 cube de bouillon de poulet",
                "2 carottes coupées en rondelles (facultatif)"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "pilons-de-poulet",
                "oignon",
                "citron",
                "moutarde",
                "ail",
                "huile-d-arachide",
                "laurier",
                "piment-scotch-bonnet",
                "sel",
                "poivre",
                "bouillon-de-poulet",
                "carotte"
            ],
            "preparation": [
                "Dans un grand bol, mélanger le jus de citron, la moutarde, l'ail émincé, le piment, le sel et le poivre.",
                "Ajouter les morceaux de poulet et les oignons émincés, bien mélanger pour enrober tous les ingrédients.",
                "Couvrir et laisser mariner au réfrigérateur pendant au moins 4 heures, idéalement toute la nuit.",
                "Retirer les morceaux de poulet de la marinade et réserver les oignons et le liquide."
            ],
            "cuisson": [
                "Dans une grande poêle, chauffer l'huile d'arachide à feu moyen-vif.",
                "Faire dorer les morceaux de poulet de tous côtés, puis les retirer et réserver.",
                "Dans la même poêle, faire revenir les oignons marinés jusqu'à ce qu'ils soient dorés et caramélisés.",
                "Ajouter le liquide de marinade, les feuilles de laurier, le cube de bouillon émietté et les carottes si utilisées.",
                "Remettre le poulet dans la poêle, couvrir et laisser mijoter à feu doux pendant 30-35 minutes, jusqu'à ce que le poulet soit tendre et que la sauce ait légèrement réduit."
            ],
            "cooking_methods": {
                "best": "poêle",
                "plancha": false,
                "barbecue": true,
                "friture": false,
                "four": false,
                "poêle": true,
                "cocotte": true
            },
            "mode_cuisson": [
                "poêle",
                "cocotte",
                "barbecue"
            ],
            "accompagnement": "Servir avec du riz blanc et des bananes plantains frites.",
            "shortStory": "Bien que le poulet Yassa soit originaire du Sénégal, il est devenu très populaire au Ghana où il a été adapté avec quelques variations. Ce plat savoureux et acidulé est très apprécié dans toute l'Afrique de l'Ouest. La combinaison du citron, des oignons caramélisés et du piment crée un profil de saveur unique qui caractérise cette recette emblématique.",
            "detailURL": "/fiche-recette/107/oignon-citron-moutarde/poulet-yassa-Ghana.html",
            "time_prepare": "25mn",
            "time_cooking": "45mn",
            "time_maceration": "240mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Moyen"
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Moyennement épicé"
            },
            "calories": 410,
            "allergens": [
                "Arachide",
                "Moutarde"
            ],
            "difficulty": {
                "level": 2,
                "description": "Intermédiaire"
            },
            "equipment_needed": [
                "Grande poêle avec couvercle",
                "Bol pour mariner"
            ]
        },
        {
            "id": 120,
            "categorie": "afrique",
            "country": "Ghana",
            "flag": "GH",
            "title": "Poulet Jollof",
            "subTitle": "Poulet mariné et riz épicé cuits ensemble dans une sauce tomate parfumée",
            "chicken_piece": [
                "cuisse",
                "pilon",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "6 morceaux de poulet (cuisses ou pilons)",
                "2 tasses de riz à long grain",
                "4 tomates mûres",
                "2 poivrons rouges",
                "2 piments scotch bonnet (ajuster selon préférence)",
                "2 oignons moyens",
                "4 gousses d'ail",
                "2 cuillères à soupe de gingembre frais râpé",
                "2 cuillères à soupe de concentré de tomate",
                "1 cuillère à café de curry en poudre",
                "1 cuillère à café de thym séché",
                "2 feuilles de laurier",
                "2 cubes de bouillon de poulet",
                "1/2 tasse d'huile végétale",
                "Sel et poivre",
                "3 tasses de bouillon de poulet"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "pilons-de-poulet",
                "riz",
                "tomate",
                "poivron",
                "piment",
                "oignon",
                "ail",
                "gingembre",
                "concentre-tomate",
                "curry",
                "thym",
                "laurier",
                "bouillon-poulet",
                "huile-vegetale",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Laver et sécher le poulet, puis l'assaisonner avec du sel, du poivre et une cuillère à café de thym.",
                "Dans un mixeur, réduire en purée les tomates, les poivrons, les piments, 1 oignon et l'ail.",
                "Rincer le riz plusieurs fois jusqu'à ce que l'eau soit claire, puis laisser tremper pendant 15 minutes et égoutter.",
                "Émincer le deuxième oignon."
            ],
            "cuisson": [
                "Dans une grande marmite, chauffer 3 cuillères à soupe d'huile à feu moyen-vif.",
                "Faire dorer les morceaux de poulet de tous côtés pendant environ 5 minutes, puis les réserver.",
                "Dans la même marmite, ajouter le reste d'huile et faire revenir l'oignon émincé jusqu'à ce qu'il soit translucide.",
                "Incorporer le gingembre râpé et faire revenir pendant 1 minute.",
                "Ajouter le concentré de tomate et cuire pendant 2 minutes en remuant.",
                "Verser le mélange de tomates mixées et laisser mijoter à feu moyen pendant 10-15 minutes, jusqu'à ce que l'huile remonte à la surface.",
                "Ajouter la poudre de curry, les cubes de bouillon émiettés et les feuilles de laurier.",
                "Incorporer le riz égoutté et bien mélanger pour enrober les grains de sauce.",
                "Verser le bouillon de poulet, remuer, puis disposer les morceaux de poulet sur le dessus.",
                "Couvrir hermétiquement avec du papier aluminium puis le couvercle pour piéger la vapeur.",
                "Réduire le feu à doux et laisser cuire pendant 25-30 minutes, jusqu'à ce que le riz soit tendre et ait absorbé le liquide.",
                "Laisser reposer 10 minutes avant de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec une salade de concombre et tomate. Pour la salade: mélanger 1 concombre et 2 tomates coupés en dés avec 1 oignon rouge émincé finement, assaisonner avec le jus d'un citron, 2 cuillères à soupe d'huile d'olive, du sel, du poivre et un peu de persil frais haché.",
            "shortStory": "Le **Poulet Jollof** est considéré comme l'un des plats nationaux du Ghana, bien qu'il soit également populaire dans d'autres pays d'Afrique de l'Ouest, chaque région ayant sa propre variation. Ce plat convivial est au cœur de nombreuses célébrations et rassemblements familiaux. Son nom vient du peuple Wolof du Sénégal, mais la version ghanéenne est connue pour sa couleur orange-rouge vif et son goût légèrement fumé. Le secret d'un bon Jollof réside dans la préparation de la base de tomate et la cuisson du riz, qui doit absorber toutes les saveurs de la sauce tout en restant bien séparé.",
            "detailURL": "/fiche-recette/120/poulet-riz-tomate-epices/poulet-jollof.html",
            "time_prepare": "25mn",
            "time_maceration": "15mn",
            "time_cooking": "60mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût modéré (niveau 2), utilisant des ingrédients courants et abordables, bien que les piments scotch bonnet puissent être plus difficiles à trouver dans certaines régions."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Cette recette est moyennement à fortement épicée, en particulier avec l'utilisation des piments scotch bonnet. Le niveau d'épice peut être facilement ajusté en modifiant la quantité de piment."
            },
            "calories": "450 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Une recette de difficulté moyenne, la principale technique étant de bien gérer la cuisson du riz pour qu'il soit parfaitement cuit sans être pâteux."
            },
            "equipment_needed": [
                "marmite",
                "mixeur",
                "papier-aluminium"
            ]
        },
        {
            "id": 121,
            "categorie": "afrique",
            "country": "Ghana",
            "flag": "GH",
            "title": "Poulet Kenkey",
            "subTitle": "Poulet grillé mariné aux épices, servi avec une sauce piquante aux tomates",
            "chicken_piece": [
                "cuisse",
                "pilon",
                "aile"
            ],
            "piece": {
                "best": "pilon",
                "entier": false,
                "aile": true,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "8 morceaux de poulet (pilons, cuisses ou ailes)",
                "3 gousses d'ail écrasées",
                "2 cuillères à café de gingembre frais râpé",
                "1 oignon rouge émincé",
                "2 cuillères à soupe d'huile de palme (ou huile végétale)",
                "1 cuillère à café de poudre de piment",
                "1 cuillère à café de poivre noir",
                "1 cuillère à soupe de paprika fumé",
                "1 cuillère à café de cumin moulu",
                "1 cuillère à café de bouillon de poulet en poudre",
                "1 citron (jus)",
                "Sel"
            ],
            "ingredient_liste": [
                "pilons-de-poulet",
                "cuisses-de-poulet",
                "ailes-de-poulet",
                "ail",
                "gingembre",
                "oignon-rouge",
                "huile-de-palme",
                "piment",
                "poivre",
                "paprika",
                "cumin",
                "bouillon-poulet",
                "citron",
                "sel"
            ],
            "preparation": [
                "Dans un grand bol, mélanger l'ail écrasé, le gingembre râpé, l'oignon émincé, l'huile, le piment, le poivre, le paprika, le cumin, le bouillon en poudre, le jus de citron et le sel.",
                "Ajouter les morceaux de poulet et bien les enrober de la marinade.",
                "Couvrir et laisser mariner au réfrigérateur pendant au moins 3 heures, idéalement toute une nuit."
            ],
            "cuisson": [
                "Préchauffer le grill ou le barbecue à feu moyen-vif.",
                "Retirer le poulet de la marinade, en secouant légèrement pour éliminer l'excès (conserver la marinade).",
                "Griller les morceaux de poulet pendant environ 15-20 minutes, en les retournant régulièrement, jusqu'à ce qu'ils soient bien dorés et que le jus qui s'en écoule soit clair.",
                "Badigeonner occasionnellement avec la marinade restante pendant la cuisson pour plus de saveur et de jutosité."
            ],
            "cooking_methods": {
                "best": "grill",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": false,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "grill",
                "barbecue"
            ],
            "accompagnement": "Servir avec du kenkey (pâte fermentée de maïs) et une sauce piquante aux tomates (shito). Pour la sauce shito: faire revenir 3 oignons émincés, 5 gousses d'ail hachées et 3 piments dans 4 cuillères à soupe d'huile, ajouter 2 cuillères à soupe de pâte de crevettes, 1 cuillère à soupe de pâte de gingembre, 400g de tomates concassées et laisser mijoter 20 minutes jusqu'à épaississement.",
            "shortStory": "Le **Poulet Kenkey** est une interprétation contemporaine de la cuisine de rue ghanéenne, associant le poulet grillé épicé au kenkey, un aliment de base traditionnel fait de pâte de maïs fermentée. Ce plat est particulièrement populaire dans les zones urbaines d'Accra, où il est souvent vendu par des vendeurs ambulants. La clé de ce plat réside dans sa marinade riche et épicée qui imprègne le poulet de saveurs profondes avant la cuisson au grill, méthode qui rappelle le 'chichinga', les brochettes de viande grillées populaires au Ghana. L'association du poulet grillé avec la sauce shito épicée crée un contraste savoureux qui est très apprécié localement.",
            "detailURL": "/fiche-recette/121/poulet-grill-epices-piment/poulet-kenkey.html",
            "time_prepare": "15mn",
            "time_maceration": "3h",
            "time_cooking": "20mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût modéré (niveau 2), utilisant des ingrédients relativement accessibles, bien que l'huile de palme puisse être plus difficile à trouver selon les régions."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Cette recette est significativement épicée, reflétant l'amour des Ghanéens pour les saveurs piquantes, en particulier dans la sauce shito qui accompagne le plat."
            },
            "calories": "320 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Une recette de difficulté moyenne, la principale technique étant de bien contrôler la cuisson au grill pour que le poulet soit parfaitement cuit mais encore juteux."
            },
            "equipment_needed": [
                "grill",
                "barbecue",
                "bol-marinade"
            ]
        },
        {
            "id": 128,
            "categorie": "afrique",
            "country": "Guinée",
            "flag": "GN",
            "title": "Poulet Yassa à la Guinéenne",
            "subTitle": "Poulet mariné au citron et aux oignons, avec une touche guinéenne",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux (ou 6 cuisses/pilons)",
                "8 gros oignons jaunes émincés finement",
                "6 citrons (jus)",
                "4 cuillères à soupe d'huile d'arachide",
                "2 cuillères à soupe de vinaigre de cidre",
                "4 gousses d'ail écrasées",
                "2 cuillères à café de gingembre frais râpé",
                "2 piments habanero (facultatif, selon goût)",
                "2 cuillères à café de moutarde de Dijon",
                "2 feuilles de laurier",
                "1 cuillère à café de poivre noir",
                "2 cuillères à café de bouillon en poudre",
                "1 cuillère à café de piment fumé (ou paprika fumé)",
                "Sel"
            ],
            "ingredient_liste": [
                "poulet",
                "oignon",
                "citron",
                "huile-arachide",
                "vinaigre",
                "ail",
                "gingembre",
                "piment-habanero",
                "moutarde",
                "laurier",
                "poivre",
                "bouillon-en-poudre",
                "piment-fume",
                "sel"
            ],
            "preparation": [
                "Laver et sécher les morceaux de poulet.",
                "Dans un grand bol, mélanger le jus de citron, l'ail écrasé, le gingembre râpé, la moutarde, le bouillon en poudre, le piment fumé, le sel et le poivre.",
                "Ajouter les oignons émincés et les piments (si utilisés) coupés en deux et épépinés.",
                "Incorporer les morceaux de poulet et bien les enrober de la marinade.",
                "Couvrir et laisser mariner au réfrigérateur pendant au moins 4 heures, idéalement toute une nuit.",
                "Réserver les oignons et la marinade liquide séparément du poulet."
            ],
            "cuisson": [
                "Retirer les morceaux de poulet de la marinade en conservant le liquide et les oignons séparément.",
                "Dans une grande poêle ou marmite, chauffer l'huile d'arachide à feu moyen-vif.",
                "Faire griller les morceaux de poulet jusqu'à ce qu'ils soient dorés des deux côtés (environ 5 minutes par côté), puis réserver.",
                "Dans la même marmite, faire revenir les oignons marinés à feu moyen jusqu'à ce qu'ils soient tendres et légèrement caramélisés (environ 15-20 minutes).",
                "Ajouter le vinaigre et faire réduire pendant 2 minutes.",
                "Verser la marinade réservée et les feuilles de laurier, porter à ébullition puis réduire à feu doux.",
                "Remettre les morceaux de poulet dans la sauce, couvrir et laisser mijoter pendant 30-35 minutes, jusqu'à ce que le poulet soit bien cuit et tendre.",
                "Goûter et ajuster l'assaisonnement si nécessaire."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": true,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte",
                "poele"
            ],
            "accompagnement": "Servir avec du riz blanc et du fonio. Pour le fonio: rincer 300g de fonio, puis faire chauffer 2 cuillères à soupe d'huile dans une casserole, ajouter le fonio et remuer pendant 2 minutes, verser 600ml d'eau bouillante salée, couvrir et cuire à feu très doux pendant 5 minutes, puis éteindre et laisser gonfler 10 minutes avant de servir.",
            "shortStory": "Le **Poulet Yassa à la Guinéenne** est une adaptation locale du célèbre plat sénégalais, intégrant des éléments spécifiques à la cuisine guinéenne comme l'utilisation plus prononcée de gingembre et de piment fumé. Ce plat savoureux illustre les échanges culturels et culinaires entre les pays d'Afrique de l'Ouest, où les frontières n'empêchent pas le partage des traditions alimentaires. En Guinée, on préfère parfois une version légèrement plus épicée du Yassa, reflétant le goût local pour les saveurs plus prononcées. Ce plat est particulièrement apprécié pendant le Ramadan et lors des célébrations familiales importantes, où il est souvent servi avec du fonio, une céréale locale nutritive cultivée dans les hautes terres guinéennes.",
            "detailURL": "/fiche-recette/128/poulet-citron-oignon-gingembre/poulet-yassa-guineenne.html",
            "time_prepare": "25mn",
            "time_maceration": "4h",
            "time_cooking": "60mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Cette recette est économique (niveau 1), utilisant des ingrédients simples et facilement disponibles comme les oignons et les citrons."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette peut être modérément épicée si on inclut les piments habanero. L'épice est facilement ajustable selon les préférences."
            },
            "calories": "390 kcal",
            "allergens": [
                "moutarde",
                "arachide"
            ],
            "difficulty": {
                "level": 2,
                "description": "Une recette de difficulté moyenne qui demande principalement du temps pour la marinade et la caramélisation des oignons."
            },
            "equipment_needed": [
                "marmite",
                "poele",
                "bol"
            ]
        },
        {
            "id": 129,
            "categorie": "afrique",
            "country": "Guinée",
            "flag": "GN",
            "title": "Poulet Maffe",
            "subTitle": "Ragoût de poulet à la sauce arachide et aux légumes",
            "chicken_piece": [
                "cuisse",
                "pilon",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "6 morceaux de poulet (cuisses, pilons ou blancs)",
                "1 tasse de beurre d'arachide naturel (non sucré)",
                "2 oignons moyens émincés",
                "3 tomates fraîches concassées",
                "2 carottes coupées en morceaux",
                "1 aubergine coupée en dés",
                "1 patate douce coupée en morceaux",
                "1 chou vert coupé en lanières",
                "2 cuillères à soupe de concentré de tomate",
                "4 gousses d'ail émincées",
                "2 cuillères à café de gingembre frais râpé",
                "1 litre de bouillon de poulet",
                "2 cuillères à soupe d'huile d'arachide",
                "1 piment scotch bonnet (entier pour parfumer)",
                "1 cuillère à café de poivre noir",
                "2 cuillères à café de bouillon en poudre",
                "Sel"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "pilons-de-poulet",
                "blanc-de-poulet",
                "beurre-arachide",
                "oignon",
                "tomate",
                "carotte",
                "aubergine",
                "patate-douce",
                "chou-vert",
                "concentre-tomate",
                "ail",
                "gingembre",
                "bouillon-poulet",
                "huile-arachide",
                "piment-scotch-bonnet",
                "poivre",
                "bouillon-en-poudre",
                "sel"
            ],
            "preparation": [
                "Assaisonner les morceaux de poulet avec du sel, du poivre et 1 cuillère à café de bouillon en poudre.",
                "Dans un bol, délayer le beurre d'arachide avec 250ml de bouillon chaud pour obtenir une pâte lisse.",
                "Préparer tous les légumes: émincer les oignons, concasser les tomates, couper les carottes, l'aubergine, la patate douce et le chou."
            ],
            "cuisson": [
                "Dans une grande marmite, chauffer l'huile d'arachide à feu moyen-vif.",
                "Faire dorer les morceaux de poulet de tous côtés pendant environ 5 minutes, puis réserver.",
                "Dans la même marmite, faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter l'ail et le gingembre, puis faire revenir pendant 1 minute.",
                "Incorporer le concentré de tomate et faire cuire pendant 2 minutes, en remuant constamment.",
                "Ajouter les tomates concassées et cuire pendant 3-4 minutes jusqu'à ce qu'elles commencent à se défaire.",
                "Verser la pâte de beurre d'arachide dans la marmite et bien mélanger.",
                "Incorporer progressivement le reste du bouillon tout en remuant pour éviter les grumeaux.",
                "Ajouter le piment scotch bonnet entier (ne pas le percer pour contrôler la chaleur).",
                "Remettre les morceaux de poulet dans la sauce, puis ajouter les carottes et la patate douce.",
                "Couvrir et laisser mijoter à feu doux pendant 20 minutes.",
                "Ajouter l'aubergine et le chou, puis poursuivre la cuisson pendant encore 15-20 minutes, jusqu'à ce que tous les légumes soient tendres et que le poulet soit bien cuit.",
                "Ajuster l'assaisonnement avec du sel et du poivre selon votre goût."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc parfumé ou du foutou d'igname. Pour le foutou: faire bouillir 500g d'igname pelée et coupée en morceaux jusqu'à ce qu'elle soit tendre (environ 20 minutes), puis la piler dans un mortier jusqu'à obtenir une pâte élastique. Former des boules à servir avec le ragoût.",
            "shortStory": "Le **Poulet Maffe** (ou Mafé) est un plat populaire en Afrique de l'Ouest, particulièrement en Guinée où il est apprécié pour sa richesse et sa saveur unique. Originaire du Mali et largement adopté dans toute la région, ce ragoût à base d'arachide reflète l'importance culturelle et nutritionnelle de cette légumineuse dans la cuisine locale. En Guinée, on ajoute souvent une variété de légumes locaux qui enrichissent le plat et en font un repas complet. Le Maffe est traditionnellement servi lors des grandes réunions familiales et constitue un exemple parfait de la cuisine communautaire africaine, où un grand plat est partagé par tous. La sauce à l'arachide, crémeuse et riche, est parfaitement équilibrée par l'acidité des tomates et la douceur des légumes racines.",
            "detailURL": "/fiche-recette/129/poulet-arachide-legumes-ragout/poulet-maffe.html",
            "time_prepare": "25mn",
            "time_maceration": "0h",
            "time_cooking": "60mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût modéré (niveau 2), utilisant des ingrédients relativement accessibles, même si la variété de légumes peut légèrement augmenter le coût."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette est modérément épicée grâce au piment scotch bonnet entier qui infuse la sauce sans la rendre excessivement piquante."
            },
            "calories": "450 kcal",
            "allergens": [
                "arachide"
            ],
            "difficulty": {
                "level": 2,
                "description": "Une recette de difficulté moyenne, nécessitant principalement de la préparation pour les légumes et une attention à la texture de la sauce à l'arachide."
            },
            "equipment_needed": [
                "marmite",
                "bol",
                "couteau"
            ]
        },
        {
            "id": 130,
            "categorie": "afrique",
            "country": "Guinée-Bissau",
            "flag": "GW",
            "title": "Poulet au Cajú",
            "subTitle": "Poulet mijoté avec des noix de cajou et des fruits tropicaux",
            "chicken_piece": [
                "cuisse",
                "pilon",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "6 morceaux de poulet (cuisses, pilons ou blancs)",
                "200g de noix de cajou non salées",
                "2 oignons moyens émincés",
                "3 tomates mûres concassées",
                "1 mangue mûre, pelée et coupée en dés",
                "1 ananas (petit), pelé et coupé en morceaux",
                "3 gousses d'ail émincées",
                "2 cuillères à soupe de gingembre frais râpé",
                "2 piments malagueta (ou piments oiseaux), finement hachés",
                "400ml de lait de coco",
                "200ml de bouillon de poulet",
                "3 cuillères à soupe d'huile de palme (ou huile végétale)",
                "1 cuillère à café de curcuma",
                "2 feuilles de laurier",
                "Sel et poivre",
                "Jus d'un citron vert"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "pilons-de-poulet",
                "blanc-de-poulet",
                "noix-de-cajou",
                "oignon",
                "tomate",
                "mangue",
                "ananas",
                "ail",
                "gingembre",
                "piment-malagueta",
                "lait-de-coco",
                "bouillon-poulet",
                "huile-de-palme",
                "curcuma",
                "laurier",
                "sel",
                "poivre",
                "citron-vert"
            ],
            "preparation": [
                "Assaisonner les morceaux de poulet avec du sel, du poivre et du curcuma.",
                "Faire griller légèrement les noix de cajou dans une poêle sèche pendant 2-3 minutes jusqu'à ce qu'elles soient dorées. Réserver la moitié des noix pour la garniture et hacher grossièrement l'autre moitié.",
                "Préparer tous les fruits et légumes: émincer les oignons, concasser les tomates, couper la mangue et l'ananas en morceaux."
            ],
            "cuisson": [
                "Dans une grande marmite, chauffer l'huile de palme à feu moyen-vif.",
                "Faire dorer les morceaux de poulet de tous côtés pendant environ 5 minutes, puis réserver.",
                "Dans la même marmite, faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter l'ail, le gingembre et les piments hachés, puis faire revenir pendant 1 minute.",
                "Incorporer les tomates concassées et cuire pendant 3-4 minutes jusqu'à ce qu'elles commencent à se défaire.",
                "Ajouter les noix de cajou hachées et mélanger.",
                "Verser le lait de coco et le bouillon de poulet, puis ajouter les feuilles de laurier.",
                "Remettre les morceaux de poulet dans la sauce, couvrir et laisser mijoter à feu doux pendant 25 minutes.",
                "Ajouter les morceaux d'ananas et de mangue, poursuivre la cuisson pendant 10 minutes supplémentaires.",
                "Ajouter le jus de citron vert en fin de cuisson.",
                "Goûter et ajuster l'assaisonnement avec du sel et du poivre."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz à la noix de coco. Pour le riz: rincer 400g de riz, faire revenir dans 2 cuillères à soupe d'huile avec 1 oignon émincé et 1 gousse d'ail hachée, ajouter 400ml de lait de coco et 400ml d'eau, saler, couvrir et cuire à feu doux pendant 15-20 minutes jusqu'à absorption complète.",
            "shortStory": "Le **Poulet au Cajú** est un plat qui célèbre l'une des principales ressources de la Guinée-Bissau: la noix de cajou. Ce petit pays d'Afrique de l'Ouest est l'un des plus grands producteurs de noix de cajou du continent, et cette culture influence naturellement sa cuisine. Ce plat combine les saveurs locales avec des influences portugaises et ouest-africaines, créant un mélange sucré-salé caractéristique de la région. L'ajout de fruits tropicaux comme la mangue et l'ananas, qui poussent en abondance dans les forêts et jardins du pays, apporte une fraîcheur et une douceur qui contrebalancent parfaitement la richesse des noix de cajou et du lait de coco. Ce plat est souvent préparé lors des cérémonies de récolte des noix de cajou, qui sont un moment important pour l'économie et la culture locale.",
            "detailURL": "/fiche-recette/130/poulet-noix-cajou-fruits-tropicaux/poulet-caju.html",
            "time_prepare": "30mn",
            "time_maceration": "0h",
            "time_cooking": "50mn",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Cette recette est d'un coût moyen à élevé (niveau 3) en raison principalement du prix des noix de cajou et des fruits tropicaux qui peuvent varier selon les saisons et les régions."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette est modérément épicée grâce aux piments malagueta, mais l'ajout de fruits et de lait de coco adoucit le plat."
            },
            "calories": "480 kcal",
            "allergens": [
                "noix-de-cajou"
            ],
            "difficulty": {
                "level": 2,
                "description": "Une recette de difficulté moyenne, nécessitant principalement de la préparation pour les différents ingrédients et une surveillance pendant la cuisson."
            },
            "equipment_needed": [
                "marmite",
                "poele",
                "couteau"
            ]
        },
        {
            "id": 131,
            "categorie": "afrique",
            "country": "Guinée-Bissau",
            "flag": "GW",
            "title": "Poulet Caldo",
            "subTitle": "Ragoût de poulet aux légumes et épices inspiré de la cuisine portugaise",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux (ou 6 cuisses/pilons)",
                "3 pommes de terre moyennes, pelées et coupées en cubes",
                "2 patates douces, pelées et coupées en cubes",
                "2 carottes, coupées en rondelles",
                "2 oignons moyens, émincés",
                "3 tomates, concassées",
                "1 chou vert, coupé en lanières",
                "4 gousses d'ail, émincées",
                "2 cuillères à soupe de gingembre frais râpé",
                "1 poivron rouge, coupé en lanières",
                "2 cuillères à soupe de concentré de tomate",
                "2 litres de bouillon de poulet",
                "3 cuillères à soupe d'huile d'olive",
                "1 cuillère à café de paprika fumé",
                "1 cuillère à café de cumin moulu",
                "1 piment scotch bonnet (entier, pour parfumer)",
                "2 feuilles de laurier",
                "Sel et poivre",
                "Jus d'un citron",
                "Persil frais haché pour garnir"
            ],
            "ingredient_liste": [
                "poulet",
                "pomme-de-terre",
                "patate-douce",
                "carotte",
                "oignon",
                "tomate",
                "chou-vert",
                "ail",
                "gingembre",
                "poivron",
                "concentre-tomate",
                "bouillon-poulet",
                "huile-olive",
                "paprika",
                "cumin",
                "piment-scotch-bonnet",
                "laurier",
                "sel",
                "poivre",
                "citron",
                "persil"
            ],
            "preparation": [
                "Laver et sécher les morceaux de poulet, puis les assaisonner avec du sel, du poivre, du paprika et du cumin.",
                "Préparer tous les légumes: peler et couper les pommes de terre et les patates douces en cubes, émincer les oignons et l'ail, couper les carottes en rondelles, le poivron en lanières et le chou en morceaux."
            ],
            "cuisson": [
                "Dans une grande marmite, chauffer l'huile d'olive à feu moyen-vif.",
                "Faire dorer les morceaux de poulet de tous côtés pendant environ 5 minutes, puis réserver.",
                "Dans la même marmite, faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter l'ail et le gingembre, puis faire revenir pendant 1 minute.",
                "Incorporer le concentré de tomate et faire cuire pendant 2 minutes, en remuant constamment.",
                "Ajouter les tomates concassées et cuire pendant 3-4 minutes.",
                "Verser le bouillon de poulet, ajouter les feuilles de laurier et le piment scotch bonnet entier.",
                "Remettre les morceaux de poulet dans la marmite, porter à ébullition, puis réduire à feu doux et laisser mijoter pendant 20 minutes.",
                "Ajouter les pommes de terre, les patates douces, les carottes et le poivron, puis poursuivre la cuisson pendant 15 minutes.",
                "Incorporer le chou vert et cuire encore 10 minutes, jusqu'à ce que tous les légumes soient tendres.",
                "Ajouter le jus de citron en fin de cuisson.",
                "Goûter et ajuster l'assaisonnement avec du sel et du poivre.",
                "Parsemer de persil frais haché avant de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du pain grillé frotté à l'ail ou du riz blanc. Pour le pain à l'ail: couper une baguette en tranches, faire griller, frotter chaque tranche avec une gousse d'ail coupée en deux et arroser d'un filet d'huile d'olive.",
            "shortStory": "Le **Poulet Caldo** est un plat qui témoigne de l'influence portugaise sur la cuisine de la Guinée-Bissau, ancienne colonie portugaise jusqu'en 1973. Le terme 'caldo' signifie 'bouillon' en portugais, et ce ragoût copieux reflète la fusion entre les techniques culinaires européennes et les ingrédients locaux africains. Cette version du caldo incorpore des patates douces et du gingembre, donnant au plat une touche locale distinctive. En Guinée-Bissau, ce plat est souvent préparé pour les occasions spéciales et les rassemblements familiaux, symbolisant le mélange culturel unique du pays. La richesse en légumes fait de ce plat un repas complet et nutritif, particulièrement apprécié pendant la saison des pluies lorsque les familles se réunissent autour de repas réconfortants.",
            "detailURL": "/fiche-recette/131/poulet-legumes-epices-ragout/poulet-caldo.html",
            "time_prepare": "30mn",
            "time_maceration": "0h",
            "time_cooking": "70mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût modéré (niveau 2), utilisant principalement des légumes de base et des épices courantes."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette est légèrement épicée, le piment scotch bonnet entier parfumant le plat sans le rendre excessivement piquant."
            },
            "calories": "420 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Une recette de difficulté moyenne, nécessitant principalement du temps pour la préparation des légumes et la surveillance du mijotage."
            },
            "equipment_needed": [
                "marmite",
                "couteau",
                "planche-a-decouper"
            ]
        },
        {
            "id": 124,
            "categorie": "amerique-centrale",
            "country": "Grenade",
            "flag": "GD",
            "title": "Poulet Oil Down",
            "subTitle": "Ragoût de poulet au lait de coco, taro et légumes",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux (ou 6 cuisses/pilons)",
                "400ml de lait de coco",
                "500g de taro (ou igname) pelé et coupé en morceaux",
                "2 bananes plantains vertes, pelées et coupées en tronçons",
                "200g de carottes coupées en rondelles",
                "2 oignons émincés",
                "4 gousses d'ail émincées",
                "1 poivron rouge coupé en lanières",
                "2 tiges de céleri coupées en morceaux",
                "2 cuillères à soupe de curcuma",
                "1 piment scotch bonnet (entier, non percé pour moins de chaleur)",
                "2 feuilles de laurier",
                "3 brins de thym frais",
                "2 cuillères à soupe d'huile de noix de coco",
                "Sel et poivre",
                "2 oignons verts émincés pour la garniture"
            ],
            "ingredient_liste": [
                "poulet",
                "lait-de-coco",
                "taro",
                "banane-plantain",
                "carotte",
                "oignon",
                "ail",
                "poivron",
                "celeri",
                "curcuma",
                "piment",
                "laurier",
                "thym",
                "huile-de-coco",
                "sel",
                "poivre",
                "oignon-vert"
            ],
            "preparation": [
                "Laver et sécher les morceaux de poulet, puis les assaisonner avec du sel, du poivre et 1 cuillère à soupe de curcuma.",
                "Préparer tous les légumes: peler et couper le taro en gros morceaux, couper les plantains en tronçons de 5 cm, émincer les oignons et l'ail, couper les carottes, le poivron et le céleri."
            ],
            "cuisson": [
                "Dans une grande marmite, chauffer l'huile de noix de coco à feu moyen-vif.",
                "Faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter l'ail et le curcuma restant, puis faire revenir pendant 1 minute.",
                "Incorporer les morceaux de poulet et les faire dorer légèrement sur tous les côtés.",
                "Ajouter les feuilles de laurier, le thym et le piment scotch bonnet entier.",
                "Verser le lait de coco et 200ml d'eau, puis porter à ébullition.",
                "Réduire le feu et laisser mijoter pendant 15 minutes.",
                "Ajouter les morceaux de taro, de plantain, les carottes, le poivron et le céleri.",
                "Assaisonner avec du sel et du poivre selon votre goût.",
                "Couvrir partiellement et laisser mijoter à feu doux pendant 30-35 minutes, jusqu'à ce que le poulet soit tendre et que les légumes soient cuits.",
                "Le ragoût est prêt lorsque l'huile du lait de coco remonte à la surface (d'où le nom 'oil down')."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz ou du pain frit. Pour le pain frit: couper du pain en tranches épaisses, les tremper rapidement dans un mélange d'œuf battu et de lait, puis les faire frire dans de l'huile chaude jusqu'à ce qu'elles soient dorées des deux côtés.",
            "shortStory": "L'**Oil Down** est le plat national de Grenade, généralement préparé avec du porc salé, mais notre version utilise le poulet pour une alternative plus légère. Ce ragoût tire son nom de l'huile du lait de coco qui remonte à la surface pendant la cuisson. Traditionnellement préparé lors de rassemblements communautaires et familiaux, ce plat incarne l'esprit convivial des Grenadiens. L'utilisation du taro (localement appelé 'dasheen'), des plantains et du lait de coco reflète l'influence des traditions culinaires africaines et indiennes qui ont façonné la cuisine caribéenne. Chaque famille a sa propre recette d'Oil Down, transmise de génération en génération, faisant de ce plat un véritable patrimoine culinaire de l'île aux épices.",
            "detailURL": "/fiche-recette/124/poulet-coco-taro-legumes/poulet-oil-down.html",
            "time_prepare": "30mn",
            "time_maceration": "0h",
            "time_cooking": "60mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût modéré (niveau 2), le taro et les plantains pouvant être remplacés par des pommes de terre et des bananes si nécessaire."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette est moyennement épicée grâce au piment scotch bonnet entier qui parfume le plat sans le rendre excessivement piquant."
            },
            "calories": "420 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Une recette de difficulté moyenne, nécessitant principalement du temps pour la préparation des différents légumes et la surveillance du mijotage."
            },
            "equipment_needed": [
                "marmite",
                "couteau",
                "planche-a-decouper"
            ]
        },
        {
            "id": 125,
            "categorie": "amerique-centrale",
            "country": "Grenade",
            "flag": "GD",
            "title": "Poulet Jerk à la Grenadienne",
            "subTitle": "Poulet mariné aux épices des Caraïbes et à la muscade de Grenade",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux (ou 6 cuisses/pilons)",
                "4 oignons verts (ciboule) hachés",
                "4 gousses d'ail écrasées",
                "2 cuillères à soupe de gingembre frais râpé",
                "2 cuillères à soupe de thym frais haché",
                "2 cuillères à soupe de sauce soja",
                "2 cuillères à soupe d'huile d'olive",
                "1 cuillère à soupe de mélasse ou de cassonade",
                "1 cuillère à soupe de jus de citron vert",
                "1 cuillère à café de muscade fraîchement râpée",
                "1 cuillère à café de cannelle moulue",
                "1 cuillère à café de poivre de la Jamaïque (piment de la Jamaïque)",
                "1 cuillère à café de paprika fumé",
                "2 piments scotch bonnet, épépinés et hachés",
                "Sel et poivre noir",
                "2 bâtons de cannelle pour parfumer la cuisson (facultatif)"
            ],
            "ingredient_liste": [
                "poulet",
                "oignon-vert",
                "ail",
                "gingembre",
                "thym",
                "sauce-soja",
                "huile-olive",
                "melasse",
                "citron-vert",
                "muscade",
                "cannelle",
                "piment-jamaique",
                "paprika",
                "piment-scotch-bonnet",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un robot culinaire ou un mixeur, combiner les oignons verts, l'ail, le gingembre, le thym, la sauce soja, l'huile, la mélasse, le jus de citron vert, la muscade, la cannelle, le piment de la Jamaïque, le paprika, les piments scotch bonnet, le sel et le poivre.",
                "Mixer jusqu'à obtenir une pâte homogène.",
                "Faire des incisions peu profondes dans les morceaux de poulet pour permettre à la marinade de pénétrer.",
                "Frotter généreusement les morceaux de poulet avec la marinade, en vous assurant de bien couvrir toute la surface et de faire pénétrer dans les incisions.",
                "Placer dans un récipient hermétique et réfrigérer pendant au moins 4 heures, idéalement toute une nuit."
            ],
            "cuisson": [
                "Préchauffer le barbecue à feu moyen-vif ou le four à 180°C.",
                "Pour le barbecue: placer les morceaux de poulet sur la grille et cuire pendant environ 30-35 minutes, en les retournant régulièrement, jusqu'à ce qu'ils soient bien cuits et que la peau soit croustillante.",
                "Pour le four: disposer les morceaux de poulet dans un plat allant au four. Ajouter les bâtons de cannelle entre les morceaux si vous en utilisez.",
                "Cuire au four pendant 45-50 minutes, en retournant à mi-cuisson, jusqu'à ce que le poulet soit doré et que le jus qui s'en écoule soit clair.",
                "Laisser reposer 5-10 minutes avant de servir."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "barbecue",
                "grill",
                "four"
            ],
            "accompagnement": "Servir avec du riz aux pois (rice and peas) et une salade de fruits tropicaux. Pour le riz aux pois: faire revenir 1 oignon émincé et 2 gousses d'ail hachées dans 2 cuillères à soupe d'huile, ajouter 400g de riz rincé, 1 boîte de haricots rouges égouttés, 400ml de lait de coco, 200ml d'eau, 1 piment entier, 2 brins de thym et 1 cuillère à café de sel. Couvrir et cuire à feu doux pendant 20 minutes.",
            "shortStory": "Le **Poulet Jerk à la Grenadienne** est une adaptation locale du célèbre poulet jerk jamaïcain, infusé avec de la muscade fraîche, l'épice emblématique de Grenade, connue comme 'l'île aux épices'. Bien que le jerk soit originaire de Jamaïque, chaque île caribéenne a développé sa propre version, et Grenade se distingue par l'ajout de muscade et de cannelle. La technique de cuisson lente sur le barbecue remonte aux traditions des Marrons, esclaves africains fugitifs qui cuisinaient leur nourriture sur des feux de bois dans les montagnes. Ce plat allie des saveurs complexes: épicé, sucré, fumé et aromatique, créant une explosion de saveurs typique de la cuisine caribéenne.",
            "detailURL": "/fiche-recette/125/poulet-epices-muscade-cannelle/poulet-jerk-grenadienne.html",
            "time_prepare": "20mn",
            "time_maceration": "4h",
            "time_cooking": "45mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût modéré (niveau 2), certaines épices spécifiques comme le piment de la Jamaïque pouvant être un peu plus coûteuses, mais la plupart des ingrédients sont accessibles."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Cette recette est significativement épicée, principalement en raison des piments scotch bonnet. L'intensité peut être ajustée en réduisant la quantité de piment utilisée."
            },
            "calories": "350 kcal",
            "allergens": [
                "soja"
            ],
            "difficulty": {
                "level": 2,
                "description": "Une recette de difficulté moyenne, nécessitant principalement du temps pour la marinade et une attention pendant la cuisson pour éviter que les épices ne brûlent."
            },
            "equipment_needed": [
                "barbecue",
                "four",
                "mixeur",
                "recipient-hermetique"
            ]
        },
        {
            "id": 114,
            "categorie": "afrique",
            "country": "Gabon",
            "flag": "GA",
            "title": "Poulet Nyembwe",
            "subTitle": "Poulet mijoté dans une sauce à l'huile de palme et aux noix de palmier",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "6 cuisses de poulet (ou 1 poulet entier coupé en morceaux)",
                "200g de noix de palme fraîches ou en conserve",
                "100ml d'huile de palme",
                "2 oignons émincés",
                "4 gousses d'ail écrasées",
                "2 cuillères à soupe de gingembre frais râpé",
                "2 piments frais (facultatif)",
                "1 cube de bouillon de poulet",
                "Sel et poivre",
                "2 feuilles de laurier",
                "1 cuillère à café de piment en poudre"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "noix-de-palme",
                "huile-de-palme",
                "oignon",
                "ail",
                "gingembre",
                "piment",
                "bouillon-de-poulet",
                "sel",
                "poivre",
                "laurier"
            ],
            "preparation": [
                "Laver et sécher les morceaux de poulet, puis les assaisonner avec du sel et du poivre.",
                "Dans un bol, mélanger l'ail écrasé, le gingembre râpé et le piment émincé pour créer une pâte à marinade.",
                "Frotter cette pâte sur les morceaux de poulet et laisser mariner pendant au moins 2 heures au réfrigérateur.",
                "Si vous utilisez des noix de palme fraîches, les faire bouillir pendant 15 minutes, puis les piler pour extraire la pulpe."
            ],
            "cuisson": [
                "Dans une grande cocotte, chauffer l'huile de palme à feu moyen.",
                "Ajouter les oignons émincés et faire revenir jusqu'à ce qu'ils soient translucides.",
                "Ajouter les morceaux de poulet marinés et les faire dorer de tous côtés pendant environ 10 minutes.",
                "Incorporer la pulpe de noix de palme, les feuilles de laurier et le cube de bouillon émietté.",
                "Ajouter un peu d'eau si nécessaire pour obtenir une sauce onctueuse.",
                "Laisser mijoter à couvert pendant 35-40 minutes, jusqu'à ce que le poulet soit bien cuit et tendre."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc ou du foufou (pâte de manioc). Pour le foufou: faire bouillir 500g de farine de manioc dans 1L d'eau en remuant constamment jusqu'à obtenir une pâte épaisse et homogène (environ 15 minutes).",
            "shortStory": "Le **Poulet Nyembwe** est considéré comme le plat national du Gabon. Cette recette traditionnelle utilise l'huile et les noix de palme qui sont des ingrédients essentiels dans la cuisine gabonaise. Le mélange des saveurs terreuses des noix de palme avec le poulet tendre crée un plat riche et nourrissant, souvent servi lors des célébrations familiales importantes. La sauce Nyembwe, d'une couleur orangée caractéristique, est un symbole de la cuisine d'Afrique centrale.",
            "detailURL": "/fiche-recette/114/poulet-noix-palme-huile/poulet-nyembwe.html",
            "time_prepare": "30mn",
            "time_maceration": "2h",
            "time_cooking": "50mn",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Cette recette est d'un coût moyen à élevé (niveau 3) en raison des ingrédients spécifiques comme les noix de palme et l'huile de palme qui peuvent être plus difficiles à trouver selon la région."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette est modérément épicée grâce au gingembre et aux piments. Le niveau d'épice peut être ajusté selon les préférences."
            },
            "calories": "450 kcal",
            "allergens": [],
            "difficulty": {
                "level": 3,
                "description": "Cette recette demande une préparation spécifique des noix de palme et un temps de cuisson précis pour obtenir la texture et la saveur authentiques."
            },
            "equipment_needed": [
                "cocotte",
                "bol",
                "pilon-mortier"
            ]
        },
        {
            "id": 115,
            "categorie": "afrique",
            "country": "Gabon",
            "flag": "GA",
            "title": "Poulet au Citron et à l'Oseille",
            "subTitle": "Poulet mariné au citron et mijoté avec de l'oseille locale",
            "chicken_piece": [
                "entier",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux (ou 4 cuisses et 2 blancs)",
                "3 citrons verts (jus et zeste)",
                "200g de feuilles d'oseille (ou épinards si indisponible)",
                "3 oignons émincés",
                "3 tomates fraîches concassées",
                "3 gousses d'ail hachées",
                "2 cuillères à soupe d'huile végétale",
                "1 cuillère à soupe de piment frais haché",
                "1 bouquet garni (thym, laurier)",
                "Sel et poivre",
                "1 cuillère à café de poivre de Cayenne"
            ],
            "ingredient_liste": [
                "poulet",
                "citron-vert",
                "oseille",
                "oignon",
                "tomate",
                "ail",
                "huile-vegetale",
                "piment",
                "thym",
                "laurier",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Laver le poulet et le sécher avec du papier absorbant.",
                "Dans un bol, mélanger le jus et le zeste des citrons verts, l'ail haché, le sel, le poivre et le poivre de Cayenne.",
                "Badigeonner généreusement les morceaux de poulet avec cette marinade.",
                "Couvrir et laisser mariner au réfrigérateur pendant au moins 3 heures, idéalement toute une nuit.",
                "Laver et hacher grossièrement les feuilles d'oseille."
            ],
            "cuisson": [
                "Dans une grande marmite, chauffer l'huile à feu moyen-vif.",
                "Faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter les morceaux de poulet marinés et les faire dorer pendant environ 5 minutes de chaque côté.",
                "Incorporer les tomates concassées, le piment frais et le bouquet garni.",
                "Ajouter les feuilles d'oseille hachées et mélanger.",
                "Couvrir et laisser mijoter à feu doux pendant 30-35 minutes, jusqu'à ce que le poulet soit tendre.",
                "Si nécessaire, ajouter un peu d'eau pour éviter que la sauce ne soit trop épaisse."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz au gingembre. Pour le riz: faire revenir 1 cuillère à soupe de gingembre râpé dans un peu d'huile, ajouter 400g de riz rincé, puis 800ml d'eau salée. Couvrir et cuire à feu doux pendant 15-20 minutes jusqu'à absorption complète.",
            "shortStory": "Le **Poulet au Citron et à l'Oseille** est un plat rafraîchissant de la cuisine gabonaise qui met en valeur l'utilisation d'ingrédients locaux. L'oseille, appelée localement 'nkumu' ou 'ntetou', apporte une acidité naturelle qui complète parfaitement le citron vert. Ce plat représente bien la cuisine d'Afrique centrale où les saveurs acidulées sont souvent utilisées pour équilibrer les plats riches. Dans les familles gabonaises, cette recette est appréciée pendant la saison chaude pour son côté léger et rafraîchissant.",
            "detailURL": "/fiche-recette/115/poulet-citron-oseille/poulet-citron-oseille.html",
            "time_prepare": "20mn",
            "time_maceration": "3h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût modéré (niveau 2), les ingrédients étant relativement courants, bien que l'oseille fraîche puisse être remplacée par des épinards si nécessaire."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette est modérément épicée avec le piment frais et le poivre de Cayenne. L'acidité du citron et de l'oseille équilibre bien la chaleur des épices."
            },
            "calories": "320 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Une recette de difficulté moyenne, nécessitant principalement du temps pour la marinade et une surveillance pendant la cuisson pour obtenir la bonne consistance de sauce."
            },
            "equipment_needed": [
                "marmite",
                "bol",
                "couteau"
            ]
        },
        {
            "id": 116,
            "categorie": "afrique",
            "country": "Gambie",
            "flag": "GM",
            "title": "Poulet Yassa",
            "subTitle": "Poulet mariné au citron et aux oignons caramélisés",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux (ou 6 cuisses)",
                "8 oignons jaunes émincés",
                "8 citrons (jus)",
                "4 cuillères à soupe d'huile d'arachide",
                "2 gousses d'ail écrasées",
                "2 cuillères à café de gingembre râpé",
                "2 piments (scotch bonnet, facultatif)",
                "2 cuillères à café de moutarde de Dijon",
                "2 feuilles de laurier",
                "1 cuillère à café de poivre noir",
                "Sel",
                "1 cube de bouillon de poulet émietté"
            ],
            "ingredient_liste": [
                "poulet",
                "oignon",
                "citron",
                "huile-d-arachide",
                "ail",
                "gingembre",
                "piment",
                "moutarde",
                "laurier",
                "sel",
                "poivre",
                "bouillon-poulet"
            ],
            "preparation": [
                "Laver et sécher les morceaux de poulet.",
                "Dans un grand bol, mélanger le jus de citron, l'ail écrasé, le gingembre râpé, la moutarde, le cube de bouillon émietté, le sel et le poivre.",
                "Ajouter les oignons émincés et les piments (si utilisés).",
                "Incorporer les morceaux de poulet et bien les enrober de la marinade.",
                "Couvrir et laisser mariner au réfrigérateur pendant au moins 4 heures, idéalement toute une nuit.",
                "Réserver les oignons de la marinade pour la cuisson."
            ],
            "cuisson": [
                "Retirer les morceaux de poulet de la marinade, en conservant la marinade et les oignons séparément.",
                "Dans une grande poêle ou marmite, chauffer l'huile d'arachide à feu moyen-vif.",
                "Faire griller les morceaux de poulet jusqu'à ce qu'ils soient dorés des deux côtés (environ 5 minutes par côté), puis réserver.",
                "Dans la même marmite, faire revenir les oignons marinés à feu moyen jusqu'à ce qu'ils soient tendres et légèrement caramélisés (environ 15 minutes).",
                "Ajouter la marinade réservée, les feuilles de laurier et porter à ébullition.",
                "Remettre les morceaux de poulet dans la sauce, couvrir et laisser mijoter à feu doux pendant 30-40 minutes, jusqu'à ce que le poulet soit bien cuit et tendre."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": true,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte",
                "poele"
            ],
            "accompagnement": "Servir avec du riz blanc. Pour un riz parfait: rincer 400g de riz jusqu'à ce que l'eau soit claire, puis cuire dans 800ml d'eau salée à feu doux pendant 15-20 minutes jusqu'à absorption complète.",
            "shortStory": "Le **Poulet Yassa** est un plat emblématique de la cuisine ouest-africaine, particulièrement populaire en Gambie et au Sénégal. Originaire de la région de Casamance, ce plat associe harmonieusement l'acidité du citron à la douceur des oignons caramélisés. Traditionnellement préparé pour les grandes occasions et célébrations familiales, le Yassa témoigne de l'influence des saveurs acidulées dans la cuisine gambienne. La longue marinade est essentielle pour permettre aux saveurs de bien pénétrer la viande, créant ainsi un plat à la fois tendre et intensément parfumé.",
            "detailURL": "/fiche-recette/116/poulet-citron-oignon/poulet-yassa-gambien.html",
            "time_prepare": "30mn",
            "time_maceration": "4h",
            "time_cooking": "60mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Cette recette est économique (niveau 1), utilisant des ingrédients simples et largement disponibles comme les oignons et les citrons."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette peut être modérément épicée si on inclut les piments scotch bonnet. L'épice est facilement ajustable selon les préférences."
            },
            "calories": "380 kcal",
            "allergens": [
                "moutarde"
            ],
            "difficulty": {
                "level": 2,
                "description": "Une recette de difficulté moyenne qui demande principalement du temps pour la marinade et la caramélisation des oignons."
            },
            "equipment_needed": [
                "marmite",
                "poele",
                "bol"
            ]
        },
        {
            "id": 117,
            "categorie": "afrique",
            "country": "Gambie",
            "flag": "GM",
            "title": "Poulet Domoda",
            "subTitle": "Poulet mijoté dans une sauce crémeuse à l'arachide",
            "chicken_piece": [
                "cuisse",
                "pilon",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "6 cuisses de poulet (ou mélange de cuisses et blancs)",
                "1 tasse de beurre d'arachide naturel",
                "2 oignons moyens émincés",
                "3 tomates fraîches concassées",
                "2 poivrons rouges coupés en dés",
                "3 gousses d'ail émincées",
                "2 cuillères à soupe de concentré de tomate",
                "1 litre de bouillon de poulet",
                "2 cuillères à soupe d'huile végétale",
                "1 cuillère à café de gingembre frais râpé",
                "1 piment scotch bonnet (facultatif)",
                "Sel et poivre",
                "1 cuillère à café de cumin moulu"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "blanc-de-poulet",
                "beurre-d-arachide",
                "oignon",
                "tomate",
                "poivron",
                "ail",
                "concentre-tomate",
                "bouillon-poulet",
                "huile-vegetale",
                "gingembre",
                "piment",
                "sel",
                "poivre",
                "cumin"
            ],
            "preparation": [
                "Assaisonner les morceaux de poulet avec du sel, du poivre et du cumin.",
                "Dans un bol, mélanger le beurre d'arachide avec 250ml de bouillon chaud pour obtenir une pâte lisse.",
                "Hacher finement l'ail, l'oignon, les tomates et le poivron.",
                "Si vous utilisez le piment, le hacher finement en retirant les graines pour moins de chaleur."
            ],
            "cuisson": [
                "Dans une grande marmite, chauffer l'huile à feu moyen-vif.",
                "Faire dorer les morceaux de poulet de tous côtés pendant environ 5 minutes, puis réserver.",
                "Dans la même marmite, faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter l'ail et le gingembre, puis faire revenir pendant 1 minute.",
                "Incorporer les tomates, le poivron, le concentré de tomate et le piment (si utilisé).",
                "Cuire pendant 5 minutes jusqu'à ce que les légumes soient tendres.",
                "Ajouter la pâte de beurre d'arachide et mélanger bien.",
                "Verser progressivement le reste du bouillon tout en remuant pour éviter les grumeaux.",
                "Remettre les morceaux de poulet dans la sauce, couvrir et laisser mijoter à feu doux pendant 30-40 minutes, jusqu'à ce que le poulet soit tendre et la sauce épaissie."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc ou du fonio. Pour le fonio: rincer 300g de fonio, puis le cuire à la vapeur pendant 10 minutes. Le mélanger avec un peu de beurre, du sel et du poivre pour plus de saveur.",
            "shortStory": "Le **Poulet Domoda** est un plat national de la Gambie, préparé avec une riche sauce à base d'arachides qui est caractéristique de la cuisine ouest-africaine. Ce ragoût savoureux reflète l'importance des arachides dans la culture culinaire gambienne, où elles sont cultivées depuis des siècles. Traditionnellement servi lors des repas familiaux, le Domoda varie d'une famille à l'autre, certains préférant une version plus épicée ou plus crémeuse. La combinaison du beurre d'arachide avec les tomates crée une sauce veloutée et légèrement sucrée qui enrobe parfaitement le poulet tendre.",
            "detailURL": "/fiche-recette/117/poulet-arachide-tomate/poulet-domoda.html",
            "time_prepare": "20mn",
            "time_maceration": "0h",
            "time_cooking": "50mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût modéré (niveau 2), utilisant principalement des ingrédients de base avec l'ajout du beurre d'arachide qui reste accessible."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette peut être modérément épicée selon la quantité de piment utilisée. La sauce à l'arachide tempère bien la chaleur du piment."
            },
            "calories": "420 kcal",
            "allergens": [
                "arachide"
            ],
            "difficulty": {
                "level": 2,
                "description": "Une recette de difficulté moyenne, la principale technique étant de bien incorporer le beurre d'arachide au bouillon sans former de grumeaux."
            },
            "equipment_needed": [
                "marmite",
                "bol",
                "spatule"
            ]
        }, {
            "id": 132,
            "categorie": "amerique",
            "country": "Guyane",
            "flag": "GY",
            "title": "Poulet Pepperpot",
            "subTitle": "Ragoût de poulet aux épices et au cassareep",
            "chicken_piece": [
                "cuisse",
                "pilon",
                "aile"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": true,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "8 morceaux de poulet (cuisses, pilons ou ailes)",
                "4 cuillères à soupe de cassareep (ou 3 cuillères à soupe de mélasse mélangée à 1 cuillère à soupe de sauce soja)",
                "2 oignons moyens émincés",
                "4 gousses d'ail émincées",
                "2 cuillères à soupe de gingembre frais râpé",
                "2 piments scotch bonnet (ajuster selon le goût)",
                "2 bâtons de cannelle",
                "6 clous de girofle entiers",
                "1 cuillère à café de graines de poivre noir",
                "1 cuillère à soupe de thym frais (ou 1 cuillère à café séché)",
                "2 cuillères à soupe de cassonade",
                "3 cuillères à soupe d'huile végétale",
                "500ml de bouillon de poulet",
                "2 cuillères à soupe de vinaigre de cidre",
                "Sel",
                "2 pommes de terre moyennes, pelées et coupées en cubes (facultatif)",
                "Coriandre fraîche hachée pour garnir"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "pilons-de-poulet",
                "ailes-de-poulet",
                "cassareep",
                "oignon",
                "ail",
                "gingembre",
                "piment-scotch-bonnet",
                "cannelle",
                "girofle",
                "poivre-noir",
                "thym",
                "cassonade",
                "huile-vegetale",
                "bouillon-poulet",
                "vinaigre",
                "sel",
                "pomme-de-terre",
                "coriandre"
            ],
            "preparation": [
                "Assaisonner les morceaux de poulet avec du sel et laisser reposer pendant 15 minutes.",
                "Dans un petit bol, mélanger le cassareep (ou le mélange mélasse-sauce soja), la cassonade et le vinaigre.",
                "Écraser légèrement les graines de poivre noir et les clous de girofle pour libérer leurs arômes.",
                "Épépiner les piments scotch bonnet si vous préférez moins de chaleur, ou les laisser entiers pour plus de piquant."
            ],
            "cuisson": [
                "Dans une grande marmite, chauffer l'huile à feu moyen-vif.",
                "Faire dorer les morceaux de poulet de tous côtés pendant environ 5 minutes, puis réserver.",
                "Dans la même marmite, faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter l'ail, le gingembre, les bâtons de cannelle, les clous de girofle et les graines de poivre, puis faire revenir pendant 1-2 minutes.",
                "Incorporer le mélange de cassareep et bien remuer pour enrober les aromates.",
                "Remettre les morceaux de poulet dans la marmite et bien les enrober de sauce.",
                "Verser le bouillon de poulet, ajouter le thym et les piments scotch bonnet.",
                "Porter à ébullition, puis réduire à feu doux, couvrir et laisser mijoter pendant 45 minutes.",
                "Si vous utilisez des pommes de terre, les ajouter après 30 minutes de cuisson.",
                "La sauce doit épaissir et devenir foncée. Si elle est trop liquide, laisser mijoter à découvert pour qu'elle réduise.",
                "Goûter et ajuster l'assaisonnement si nécessaire.",
                "Parsemer de coriandre fraîche hachée avant de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc et des plantains frits. Pour les plantains: peler 2 plantains mûrs (à peau jaune tachetée de noir), les couper en tranches diagonales de 1 cm d'épaisseur, les faire frire dans de l'huile chaude pendant 2-3 minutes de chaque côté jusqu'à ce qu'ils soient dorés.",
            "shortStory": "Le **Poulet Pepperpot** est le plat national de la Guyane, une adaptation de la recette traditionnellement préparée avec de la viande de bœuf ou de porc. L'ingrédient clé est le cassareep, une sauce épaisse et noire faite à partir de jus de manioc fermenté et d'épices, qui donne au plat sa couleur foncée caractéristique et sa saveur unique. Ce plat tire ses origines des peuples autochtones amérindiens de la région, mais a évolué au fil des siècles avec l'influence des cuisines africaine, indienne et européenne. Traditionnellement, le pepperpot est préparé en grande quantité et réchauffé plusieurs jours de suite, ce qui permet aux saveurs de se développer davantage. C'est un plat incontournable pendant la période de Noël en Guyane, où chaque famille garde jalousement sa recette transmise de génération en génération.",
            "detailURL": "/fiche-recette/132/poulet-cassareep-epices-ragout/poulet-pepperpot.html",
            "time_prepare": "25mn",
            "time_maceration": "15mn",
            "time_cooking": "60mn",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Cette recette est d'un coût moyen à élevé (niveau 3) principalement en raison du cassareep qui peut être difficile à trouver et coûteux, bien qu'un substitut puisse être utilisé."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Cette recette est significativement épicée en raison des piments scotch bonnet et des diverses épices. L'intensité peut être ajustée selon les préférences."
            },
            "calories": "380 kcal",
            "allergens": [
                "soja"
            ],
            "difficulty": {
                "level": 2,
                "description": "Une recette de difficulté moyenne, la principale difficulté étant de trouver le cassareep ou de préparer un substitut adéquat."
            },
            "equipment_needed": [
                "marmite",
                "couteau",
                "bol"
            ]
        },
        {
            "id": 133,
            "categorie": "amerique",
            "country": "Guyane",
            "flag": "GY",
            "title": "Poulet Curry Guyanais",
            "subTitle": "Poulet mijoté aux épices indiennes avec une touche caribéenne",
            "chicken_piece": [
                "cuisse",
                "pilon",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "6 morceaux de poulet (cuisses, pilons ou blancs)",
                "3 oignons moyens finement émincés",
                "4 gousses d'ail émincées",
                "2 cuillères à soupe de gingembre frais râpé",
                "1 tomate mûre concassée",
                "2 pommes de terre moyennes, pelées et coupées en dés",
                "2 carottes, coupées en rondelles",
                "1 poivron vert, coupé en dés",
                "3 cuillères à soupe de poudre de curry",
                "1 cuillère à café de curcuma",
                "1 cuillère à café de cumin moulu",
                "1/2 cuillère à café de coriandre moulue",
                "1/2 cuillère à café de garam masala",
                "1 piment scotch bonnet, épépiné et finement haché (facultatif)",
                "400ml de lait de coco",
                "200ml de bouillon de poulet",
                "3 cuillères à soupe d'huile végétale",
                "1 cuillère à soupe de jus de citron vert",
                "Sel et poivre",
                "Coriandre fraîche hachée pour garnir"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "pilons-de-poulet",
                "blanc-de-poulet",
                "oignon",
                "ail",
                "gingembre",
                "tomate",
                "pomme-de-terre",
                "carotte",
                "poivron-vert",
                "curry",
                "curcuma",
                "cumin",
                "coriandre-moulue",
                "garam-masala",
                "piment-scotch-bonnet",
                "lait-de-coco",
                "bouillon-poulet",
                "huile-vegetale",
                "citron-vert",
                "sel",
                "poivre",
                "coriandre-fraiche"
            ],
            "preparation": [
                "Assaisonner les morceaux de poulet avec du sel, du poivre et 1 cuillère à soupe de poudre de curry. Laisser mariner pendant au moins 30 minutes.",
                "Dans un petit bol, mélanger le reste de la poudre de curry, le curcuma, le cumin, la coriandre moulue et le garam masala.",
                "Préparer tous les légumes: émincer les oignons et l'ail, râper le gingembre, concasser la tomate, couper les pommes de terre, les carottes et le poivron."
            ],
            "cuisson": [
                "Dans une grande marmite, chauffer l'huile à feu moyen-vif.",
                "Faire dorer les morceaux de poulet de tous côtés pendant environ 5 minutes, puis réserver.",
                "Dans la même marmite, faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter l'ail, le gingembre et le piment haché (si utilisé), puis faire revenir pendant 1 minute.",
                "Incorporer le mélange d'épices et faire revenir pendant 30 secondes jusqu'à ce que les épices soient parfumées.",
                "Ajouter la tomate concassée et cuire pendant 2-3 minutes.",
                "Remettre les morceaux de poulet dans la marmite et bien les enrober de sauce aux épices.",
                "Verser le lait de coco et le bouillon de poulet, puis porter à ébullition.",
                "Réduire à feu doux, couvrir et laisser mijoter pendant 15 minutes.",
                "Ajouter les pommes de terre, les carottes et le poivron, puis poursuivre la cuisson pendant 20-25 minutes, jusqu'à ce que le poulet soit tendre et que les légumes soient cuits.",
                "Ajouter le jus de citron vert en fin de cuisson.",
                "Goûter et ajuster l'assaisonnement avec du sel et du poivre.",
                "Parsemer de coriandre fraîche hachée avant de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du roti (pain plat indien) et du riz. Pour le roti: mélanger 400g de farine, 1 cuillère à café de sel et 250ml d'eau tiède pour former une pâte. Diviser en 8 boules, aplatir chacune en cercle, badigeonner d'huile et cuire dans une poêle chaude 2-3 minutes de chaque côté.",
            "shortStory": "Le **Poulet Curry Guyanais** témoigne de l'influence culturelle indienne en Guyane, apportée par les travailleurs sous contrat qui sont arrivés au 19ème siècle après l'abolition de l'esclavage. Ce curry diffère des versions indiennes traditionnelles par l'utilisation d'ingrédients locaux comme le lait de coco et les piments scotch bonnet, créant ainsi une fusion unique entre les cuisines indienne et caribéenne. En Guyane, ce plat est souvent préparé pour les occasions spéciales comme les mariages et les fêtes religieuses. Chaque famille a sa propre variation, certaines préférant un curry plus épicé, d'autres plus doux ou plus aromatique. Cette diversité de préparation reflète la richesse du patrimoine multiethnique de la Guyane, où les traditions culinaires de différentes cultures se sont mélangées harmonieusement.",
            "detailURL": "/fiche-recette/133/poulet-curry-lait-coco-epices/poulet-curry-guyanais.html",
            "time_prepare": "20mn",
            "time_maceration": "30mn",
            "time_cooking": "55mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût modéré (niveau 2), utilisant des épices qui, une fois achetées, peuvent servir pour de nombreuses préparations."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette est modérément épicée, avec une chaleur qui peut être ajustée selon les préférences en modifiant la quantité de piment utilisée."
            },
            "calories": "420 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Une recette de difficulté moyenne, nécessitant principalement de la préparation pour les légumes et une attention aux temps de cuisson pour que tout soit cuit uniformément."
            },
            "equipment_needed": [
                "marmite",
                "couteau",
                "bol"
            ]
        },
        {
            "id": 134,
            "categorie": "amerique",
            "country": "Guyana",
            "flag": "GY",
            "title": "Poulet Garlic Pork Style",
            "subTitle": "Poulet mariné à l'ail et au vinaigre, inspiré du garlic pork traditionnel",
            "chicken_piece": [
                "cuisse",
                "pilon",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1,5 kg de poulet (cuisses, pilons ou blancs), coupé en morceaux",
                "20 gousses d'ail (environ 2 têtes complètes)",
                "4 oignons verts (ciboules), hachés",
                "2 cuillères à soupe de thym frais haché",
                "4 piments wiri wiri ou scotch bonnet, épépinés et hachés",
                "2 cuillères à café de poivre noir concassé",
                "2 cuillères à café de sel",
                "500 ml de vinaigre blanc",
                "3 cuillères à soupe d'huile végétale",
                "1 cuillère à soupe de jus de citron vert",
                "1 cuillère à soupe de cassonade",
                "2 feuilles de laurier"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "pilons-de-poulet",
                "blanc-de-poulet",
                "ail",
                "oignon-vert",
                "thym",
                "piment-wiri-wiri",
                "poivre",
                "sel",
                "vinaigre",
                "huile-vegetale",
                "citron-vert",
                "cassonade",
                "laurier"
            ],
            "preparation": [
                "Piler ou mixer finement 15 gousses d'ail, réserver les 5 autres pour plus tard.",
                "Dans un grand bol, mélanger l'ail pilé, les oignons verts, le thym, les piments hachés, le poivre concassé, le sel, le vinaigre, le jus de citron vert et la cassonade.",
                "Ajouter les morceaux de poulet et bien les enrober de marinade.",
                "Couvrir et réfrigérer pendant au moins 24 heures, idéalement 48 heures, en remuant de temps en temps pour que tous les morceaux s'imprègnent bien."
            ],
            "cuisson": [
                "Retirer les morceaux de poulet de la marinade en réservant celle-ci.",
                "Émincer les 5 gousses d'ail restantes.",
                "Dans une grande poêle ou cocotte, chauffer l'huile à feu moyen-vif.",
                "Faire revenir l'ail émincé jusqu'à ce qu'il commence à dorer.",
                "Ajouter les morceaux de poulet égouttés et faire dorer de tous côtés pendant environ 5-7 minutes.",
                "Verser la marinade réservée dans la poêle, ajouter les feuilles de laurier et porter à ébullition.",
                "Réduire le feu, couvrir partiellement et laisser mijoter pendant 30-35 minutes, jusqu'à ce que le poulet soit bien cuit et tendre.",
                "Si nécessaire, retirer le couvercle et augmenter le feu pour faire réduire la sauce jusqu'à ce qu'elle épaississe légèrement.",
                "Le plat doit avoir un goût vif et acidulé, avec une forte présence d'ail."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": true,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte",
                "poele"
            ],
            "accompagnement": "Servir avec du pain plat et du riz. Pour le pain plat guyanais: mélanger 300g de farine, 1 cuillère à café de levure chimique, 1/2 cuillère à café de sel, 2 cuillères à soupe d'huile et 150ml d'eau tiède pour former une pâte. Diviser en 6 boules, les aplatir et les cuire dans une poêle sèche 2 minutes de chaque côté.",
            "shortStory": "Le **Poulet Garlic Pork Style** est une adaptation de la recette traditionnelle du 'garlic pork', un plat emblématique du Guyana habituellement préparé avec du porc et consommé pendant la période de Noël. Cette version utilise du poulet, ce qui la rend plus légère tout en conservant les saveurs caractéristiques. Le plat tire ses origines des traditions culinaires portugaises apportées par les immigrants qui se sont installés au Guyana au 19ème siècle. La longue marinade dans le vinaigre et l'ail est essentielle pour développer les arômes complexes du plat et attendrir la viande. Au Guyana, ce type de préparation est apprécié pour sa capacité à se conserver plusieurs jours sans réfrigération, un avantage précieux dans les régions tropicales avant l'ère moderne. Aujourd'hui, ce plat reste un favori lors des rassemblements familiaux et des fêtes.",
            "detailURL": "/fiche-recette/134/poulet-ail-vinaigre-marine/poulet-garlic-pork-style.html",
            "time_prepare": "20mn",
            "time_maceration": "24h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Cette recette est économique (niveau 1), utilisant des ingrédients de base comme l'ail et le vinaigre qui sont facilement disponibles et peu coûteux."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Cette recette est significativement épicée en raison des piments wiri wiri ou scotch bonnet. L'intensité peut être ajustée selon les préférences en modifiant la quantité de piments utilisée."
            },
            "calories": "320 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Une recette relativement facile à préparer, nécessitant principalement du temps de marinade plutôt que des techniques culinaires complexes."
            },
            "equipment_needed": [
                "cocotte",
                "bol-marinade",
                "pilon-mortier"
            ]
        },
        {
            "id": 135,
            "categorie": "amerique",
            "country": "Guyana",
            "flag": "GY",
            "title": "Poulet Cook-Up Rice",
            "subTitle": "Poulet mijoté avec du riz, des légumes et des haricots à la noix de coco",
            "chicken_piece": [
                "cuisse",
                "pilon",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "6 morceaux de poulet (cuisses, pilons ou blancs)",
                "2 tasses de riz à long grain, rincé",
                "1 tasse de haricots rouges cuits (ou une boîte égouttée)",
                "1 boîte (400ml) de lait de coco",
                "2 tasses de bouillon de poulet",
                "2 oignons moyens, émincés",
                "3 gousses d'ail, émincées",
                "2 cuillères à soupe de gingembre frais râpé",
                "1 poivron rouge, coupé en dés",
                "1 carotte, coupée en petits dés",
                "2 branches de céleri, émincées",
                "1 cuillère à soupe de thym frais haché",
                "2 oignons verts, émincés",
                "1 piment wiri wiri ou scotch bonnet (entier, pour parfumer)",
                "3 cuillères à soupe d'huile végétale",
                "1 cuillère à café de poivre noir",
                "1 cuillère à café de cumin moulu",
                "2 cuillères à café de sel (à ajuster)",
                "2 feuilles de laurier"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "pilons-de-poulet",
                "blanc-de-poulet",
                "riz",
                "haricots-rouges",
                "lait-de-coco",
                "bouillon-poulet",
                "oignon",
                "ail",
                "gingembre",
                "poivron",
                "carotte",
                "celeri",
                "thym",
                "oignon-vert",
                "piment-wiri-wiri",
                "huile-vegetale",
                "poivre",
                "cumin",
                "sel",
                "laurier"
            ],
            "preparation": [
                "Assaisonner les morceaux de poulet avec du sel, du poivre et du cumin.",
                "Rincer le riz à l'eau froide jusqu'à ce que l'eau soit claire, puis égoutter.",
                "Préparer tous les légumes: émincer les oignons, l'ail, les oignons verts et le céleri, couper le poivron et la carotte en dés."
            ],
            "cuisson": [
                "Dans une grande marmite ou cocotte, chauffer l'huile à feu moyen-vif.",
                "Faire dorer les morceaux de poulet de tous côtés pendant environ 5 minutes, puis réserver.",
                "Dans la même marmite, faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter l'ail, le gingembre et faire revenir pendant 1 minute.",
                "Ajouter le poivron, la carotte et le céleri, puis faire revenir pendant 3-4 minutes.",
                "Incorporer le riz et remuer pendant 2 minutes pour bien l'enrober d'huile.",
                "Remettre les morceaux de poulet dans la marmite.",
                "Ajouter les haricots rouges, le thym, les feuilles de laurier et le piment entier.",
                "Verser le lait de coco et le bouillon de poulet, puis bien mélanger.",
                "Porter à ébullition, puis réduire à feu doux, couvrir et laisser mijoter pendant environ 25-30 minutes, jusqu'à ce que le riz soit tendre et ait absorbé le liquide.",
                "Éviter de remuer pendant la cuisson pour que le riz reste aéré.",
                "Une fois cuit, retirer du feu et laisser reposer, couvert, pendant 10 minutes.",
                "Avant de servir, retirer le piment entier et les feuilles de laurier, puis parsemer d'oignons verts émincés."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Ce plat complet se suffit à lui-même, mais peut être accompagné d'une salade de concombre. Pour la salade: mélanger 1 concombre en fines tranches avec 1 oignon rouge émincé, assaisonner avec le jus d'un citron vert, 1 cuillère à soupe d'huile d'olive, du sel, du poivre et une pincée de piment en poudre.",
            "shortStory": "Le **Cook-Up Rice** est l'un des plats nationaux du Guyana, traditionnellement préparé le dernier jour du mois pour 'cuisiner tout ce qui reste' - d'où son nom. Cette version avec du poulet est particulièrement populaire, mais le plat peut être adapté avec différentes viandes ou légumineuses. C'est un excellent exemple de la cuisine créole guyanaise, qui mélange des influences africaines, indiennes, amérindiennes et européennes. Le lait de coco donne au riz une texture crémeuse et un goût riche qui s'associe parfaitement avec les épices. Au Guyana, ce plat est souvent servi lors des rassemblements familiaux et est considéré comme un 'comfort food' par excellence. La version traditionnelle inclut souvent du poisson salé ou du porc, mais notre adaptation au poulet offre une alternative plus légère tout en conservant les saveurs authentiques.",
            "detailURL": "/fiche-recette/135/poulet-riz-haricots-coco/poulet-cook-up-rice.html",
            "time_prepare": "20mn",
            "time_maceration": "0h",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût modéré (niveau 2), utilisant des ingrédients courants et économiques comme le riz et les haricots, avec l'ajout du lait de coco qui reste accessible."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette est légèrement épicée, le piment entier parfumant le plat sans le rendre excessivement piquant. L'intensité peut être ajustée selon les préférences."
            },
            "calories": "450 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Une recette de difficulté moyenne, nécessitant principalement une attention à la cuisson du riz pour qu'il soit parfaitement cuit sans être pâteux."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "passoire"
            ]
        },
        {
            "id": 136,
            "categorie": "afrique",
            "country": "Guinée équatoriale",
            "flag": "GQ",
            "title": "Poulet à la Sauce Ocro",
            "subTitle": "Poulet mijoté dans une sauce aux gombos et aux épices locales",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux (ou 6 cuisses/pilons)",
                "500g de gombos frais, lavés et coupés en rondelles",
                "2 oignons moyens, émincés",
                "3 tomates mûres, concassées",
                "3 gousses d'ail, écrasées",
                "2 cuillères à soupe de gingembre frais râpé",
                "2 piments rouges (selon goût), épépinés et hachés",
                "3 cuillères à soupe d'huile de palme (ou huile végétale)",
                "1 litre de bouillon de poulet",
                "1 cuillère à soupe de pâte d'arachide (facultatif)",
                "1 cuillère à café de poudre de crevettes séchées (facultatif)",
                "2 feuilles de laurier",
                "Sel et poivre",
                "1 cuillère à café de paprika",
                "1 bouquet d'épinards ou de feuilles de manioc (facultatif)",
                "2 cuillères à soupe de persil frais haché pour garnir"
            ],
            "ingredient_liste": [
                "poulet",
                "gombo",
                "oignon",
                "tomate",
                "ail",
                "gingembre",
                "piment",
                "huile-de-palme",
                "bouillon-poulet",
                "pate-arachide",
                "poudre-crevettes",
                "laurier",
                "sel",
                "poivre",
                "paprika",
                "epinard",
                "persil"
            ],
            "preparation": [
                "Laver et sécher les morceaux de poulet, puis les assaisonner avec du sel, du poivre et du paprika.",
                "Laver les gombos, retirer les extrémités et les couper en rondelles d'environ 1 cm.",
                "Émincer les oignons, concasser les tomates, écraser l'ail et râper le gingembre.",
                "Si vous utilisez des épinards ou des feuilles de manioc, les laver soigneusement et les hacher grossièrement."
            ],
            "cuisson": [
                "Dans une grande marmite, chauffer l'huile de palme à feu moyen-vif.",
                "Faire dorer les morceaux de poulet de tous côtés pendant environ 5 minutes, puis réserver.",
                "Dans la même marmite, faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter l'ail, le gingembre et les piments hachés, puis faire revenir pendant 1 minute.",
                "Incorporer les tomates concassées et cuire pendant 3-4 minutes, jusqu'à ce qu'elles commencent à se défaire.",
                "Si vous utilisez la pâte d'arachide et la poudre de crevettes, les ajouter maintenant et bien mélanger.",
                "Remettre les morceaux de poulet dans la marmite et verser le bouillon de poulet.",
                "Ajouter les feuilles de laurier, porter à ébullition, puis réduire à feu doux et laisser mijoter pendant 20 minutes.",
                "Ajouter les rondelles de gombo, couvrir partiellement et poursuivre la cuisson pendant 15-20 minutes supplémentaires.",
                "Si vous utilisez des épinards ou des feuilles de manioc, les ajouter dans les 5 dernières minutes de cuisson.",
                "La sauce doit épaissir naturellement grâce aux gombos qui libèrent leur mucilage.",
                "Goûter et ajuster l'assaisonnement avec du sel et du poivre.",
                "Parsemer de persil frais haché avant de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du fufu (pâte de manioc) ou du riz blanc. Pour le fufu: porter à ébullition 1 litre d'eau salée, verser progressivement 500g de farine de manioc en remuant vigoureusement avec une spatule en bois pour éviter les grumeaux. Continuer à remuer pendant 10-15 minutes jusqu'à obtenir une pâte épaisse et élastique. Former des boules à servir avec la sauce.",
            "shortStory": "Le **Poulet à la Sauce Ocro** est un plat traditionnel de Guinée équatoriale qui met en valeur le gombo, localement appelé 'ocro'. Ce légume, originaire d'Afrique, est apprécié pour sa texture légèrement visqueuse qui épaissit naturellement les sauces. Ce plat reflète l'influence de la cuisine africaine continentale sur celle de la Guinée équatoriale, tout en intégrant des éléments de la cuisine espagnole coloniale. La combinaison de l'huile de palme, du gombo et des épices crée un profil de saveur distinctif et authentique. Dans les foyers équato-guinéens, ce plat est souvent préparé pour les repas dominicaux et les occasions spéciales. La version présentée ici est typique de la région continentale du pays (Río Muni), où l'influence des cuisines camerounaise et gabonaise voisines est plus prononcée.",
            "detailURL": "/fiche-recette/136/poulet-gombo-epices-sauce/poulet-sauce-ocro.html",
            "time_prepare": "25mn",
            "time_maceration": "0h",
            "time_cooking": "60mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût modéré (niveau 2), bien que le gombo puisse être plus ou moins onéreux selon les saisons et les régions."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette est modérément épicée, avec une chaleur qui peut être ajustée selon les préférences en modifiant la quantité de piments utilisée."
            },
            "calories": "390 kcal",
            "allergens": [
                "arachide"
            ],
            "difficulty": {
                "level": 2,
                "description": "Une recette de difficulté moyenne, la principale technique étant de bien gérer la cuisson du gombo pour obtenir la texture désirée de la sauce."
            },
            "equipment_needed": [
                "marmite",
                "couteau",
                "planche-a-decouper"
            ]
        },
        {
            "id": 137,
            "categorie": "afrique",
            "country": "Guinée équatoriale",
            "flag": "GQ",
            "title": "Poulet Ndomba",
            "subTitle": "Poulet grillé mariné aux épices africaines et à l'ail",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "pilon",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux (ou 8 pilons/cuisses)",
                "8 gousses d'ail, émincées",
                "2 oignons, finement hachés",
                "3 cuillères à soupe de gingembre frais râpé",
                "2 piments africains (ou scotch bonnet), épépinés et hachés",
                "2 cuillères à soupe de paprika",
                "1 cuillère à café de cumin moulu",
                "1 cuillère à café de poivre noir",
                "1 cuillère à café de poudre d'oignon",
                "1/2 cuillère à café de piment de Cayenne",
                "1 cuillère à soupe de jus de citron",
                "3 cuillères à soupe d'huile d'arachide (ou huile végétale)",
                "2 cuillères à soupe de miel",
                "2 cuillères à café de sel",
                "1/4 tasse de bouillon de poulet",
                "2 cuillères à soupe de coriandre fraîche hachée pour garnir"
            ],
            "ingredient_liste": [
                "poulet",
                "ail",
                "oignon",
                "gingembre",
                "piment-africain",
                "paprika",
                "cumin",
                "poivre",
                "poudre-oignon",
                "piment-cayenne",
                "citron",
                "huile-arachide",
                "miel",
                "sel",
                "bouillon-poulet",
                "coriandre"
            ],
            "preparation": [
                "Laver et sécher les morceaux de poulet, puis pratiquer quelques incisions dans la chair pour permettre à la marinade de mieux pénétrer.",
                "Dans un grand bol, mélanger l'ail, les oignons, le gingembre, les piments hachés, le paprika, le cumin, le poivre, la poudre d'oignon, le piment de Cayenne, le jus de citron, l'huile, le miel, le sel et le bouillon.",
                "Frotter généreusement cette marinade sur les morceaux de poulet, en veillant à bien l'introduire dans les incisions.",
                "Couvrir et laisser mariner au réfrigérateur pendant au moins 4 heures, idéalement toute une nuit."
            ],
            "cuisson": [
                "Préchauffer le grill, le barbecue ou le four (mode grill) à température moyenne-élevée.",
                "Retirer les morceaux de poulet de la marinade en conservant celle-ci.",
                "Pour la cuisson au grill ou au barbecue: griller les morceaux de poulet pendant environ 30-35 minutes, en les retournant régulièrement et en les badigeonnant de marinade, jusqu'à ce qu'ils soient bien dorés et que la chair ne soit plus rose à l'intérieur.",
                "Pour la cuisson au four: placer les morceaux de poulet sur une grille posée sur une plaque de cuisson, enfourner et cuire à 200°C pendant environ 40-45 minutes, en les retournant et en les badigeonnant de marinade à mi-cuisson.",
                "Pendant ce temps, verser la marinade restante dans une petite casserole, porter à ébullition et laisser mijoter pendant 5 minutes pour créer une sauce d'accompagnement.",
                "Une fois le poulet cuit, le laisser reposer pendant 5 minutes avant de servir.",
                "Napper les morceaux de poulet avec un peu de sauce et parsemer de coriandre fraîche hachée."
            ],
            "cooking_methods": {
                "best": "grill",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "grill",
                "barbecue",
                "four"
            ],
            "accompagnement": "Servir avec du plantain frit et une salade d'avocat. Pour le plantain: peler 3 plantains mûrs (à peau jaune tachetée de noir), les couper en tranches diagonales de 1 cm d'épaisseur, les faire frire dans de l'huile chaude pendant 2-3 minutes de chaque côté jusqu'à ce qu'ils soient dorés. Pour la salade: mélanger 2 avocats mûrs coupés en dés avec 1 oignon rouge émincé, le jus d'un citron vert, du sel, du poivre et un filet d'huile d'olive.",
            "shortStory": "Le **Poulet Ndomba** est une spécialité de la cuisine équato-guinéenne, qui tire son inspiration des traditions culinaires bantoues. Ce plat est particulièrement populaire dans la région continentale de Río Muni, où les influences des cuisines camerounaise et gabonaise sont fortes. Le nom 'Ndomba' fait référence à une technique de préparation où la viande est marinée dans un mélange d'épices piquantes avant d'être grillée. Cette méthode de cuisson, qui remonte à l'époque précoloniale, permettait de préserver la viande tout en lui conférant une saveur intense. Traditionnellement, ce plat est préparé lors des célébrations et des fêtes de village, où un poulet entier est grillé sur un feu de bois pour nourrir toute la famille. L'association des épices locales avec le miel crée un équilibre parfait entre piquant et sucré, caractéristique de la cuisine d'Afrique centrale.",
            "detailURL": "/fiche-recette/137/poulet-grill-epices-ail/poulet-ndomba.html",
            "time_prepare": "20mn",
            "time_maceration": "4h",
            "time_cooking": "40mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût modéré (niveau 2), utilisant principalement des épices qui, une fois achetées, peuvent servir pour de nombreuses préparations."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Cette recette est significativement épicée en raison des piments africains et du piment de Cayenne. L'intensité peut être ajustée selon les préférences."
            },
            "calories": "360 kcal",
            "allergens": [
                "arachide"
            ],
            "difficulty": {
                "level": 2,
                "description": "Une recette de difficulté moyenne, nécessitant principalement du temps pour la marinade et une attention pendant la cuisson pour éviter que le poulet ne brûle en raison du miel contenu dans la marinade."
            },
            "equipment_needed": [
                "grill",
                "barbecue",
                "four",
                "bol-marinade",
                "casserole"
            ]
        },
        {
            "id": 138,
            "categorie": "europe",
            "country": "Groenland",
            "flag": "GL",
            "title": "Poulet au Lichen Arctique",
            "subTitle": "Poulet cuit lentement avec du lichen arctique et des baies nordiques",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux (ou 6 cuisses/pilons)",
                "2 cuillères à soupe de lichen arctique séché (ou thym séché comme substitut)",
                "100g d'airelles rouges fraîches ou surgelées (ou canneberges)",
                "2 oignons moyens, émincés",
                "2 carottes, coupées en rondelles",
                "2 branches de céleri, émincées",
                "3 gousses d'ail, émincées",
                "500ml de bouillon de poulet",
                "200ml de bière blonde légère",
                "2 cuillères à soupe d'huile d'olive",
                "1 cuillère à soupe de miel",
                "1 cuillère à soupe de vinaigre de cidre",
                "1 cuillère à café de graines de carvi (ou cumin)",
                "2 feuilles de laurier",
                "Sel et poivre",
                "1 bouquet d'aneth frais haché (ou persil)"
            ],
            "ingredient_liste": [
                "poulet",
                "lichen-arctique",
                "airelles",
                "oignon",
                "carotte",
                "celeri",
                "ail",
                "bouillon-poulet",
                "biere",
                "huile-olive",
                "miel",
                "vinaigre",
                "carvi",
                "laurier",
                "sel",
                "poivre",
                "aneth"
            ],
            "preparation": [
                "Si vous utilisez du lichen arctique séché, le rincer soigneusement puis le faire tremper dans de l'eau tiède pendant 20 minutes. Égoutter et réserver.",
                "Laver et sécher les morceaux de poulet, puis les assaisonner avec du sel et du poivre.",
                "Émincer les oignons, l'ail et le céleri, couper les carottes en rondelles."
            ],
            "cuisson": [
                "Dans une grande cocotte, chauffer l'huile d'olive à feu moyen-vif.",
                "Faire dorer les morceaux de poulet de tous côtés pendant environ 5 minutes, puis réserver.",
                "Dans la même cocotte, faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter l'ail et faire revenir pendant 1 minute.",
                "Incorporer les carottes et le céleri, puis faire revenir pendant 3-4 minutes.",
                "Verser la bière et gratter le fond de la cocotte pour décoller les sucs.",
                "Ajouter le bouillon de poulet, le lichen (ou le thym), les airelles, le miel, le vinaigre, les graines de carvi et les feuilles de laurier.",
                "Remettre les morceaux de poulet dans la cocotte, porter à ébullition, puis réduire à feu doux.",
                "Couvrir et laisser mijoter pendant 45-50 minutes, jusqu'à ce que le poulet soit tendre et se détache facilement de l'os.",
                "Goûter et ajuster l'assaisonnement avec du sel et du poivre.",
                "Parsemer d'aneth ou de persil frais haché avant de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec des pommes de terre bouillies à l'aneth et des légumes racines rôtis. Pour les pommes de terre: faire bouillir 800g de pommes de terre à chair ferme pendant 20 minutes, les égoutter, ajouter 2 cuillères à soupe de beurre, du sel, du poivre et 2 cuillères à soupe d'aneth frais haché.",
            "shortStory": "Le **Poulet au Lichen Arctique** est une adaptation moderne qui s'inspire des traditions culinaires du Groenland, où les ingrédients locaux comme le lichen, les baies arctiques et les herbes sauvages sont depuis longtemps utilisés pour parfumer les plats. Bien que le poulet ne soit pas un animal indigène du Groenland, cette recette illustre la fusion entre les pratiques alimentaires traditionnelles inuites et les influences culinaires danoises et internationales. Le lichen arctique, appelé 'kukujuak' en groenlandais, est riche en nutriments et était traditionnellement utilisé par les populations locales comme complément alimentaire pendant les longs hivers. Les airelles apportent une acidité qui équilibre parfaitement la richesse du plat, tandis que la bière légère, introduite par les Danois, ajoute une profondeur de saveur tout en attendrissant la viande pendant la cuisson lente.",
            "detailURL": "/fiche-recette/138/poulet-lichen-airelles-biere/poulet-lichen-arctique.html",
            "time_prepare": "30mn",
            "time_maceration": "0h",
            "time_cooking": "60mn",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Cette recette est d'un coût moyen à élevé (niveau 3) en raison de certains ingrédients spécifiques comme le lichen arctique qui peut être difficile à trouver et coûteux, bien qu'il puisse être remplacé par du thym."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette n'est pas épicée mais plutôt aromatique, mettant en valeur les saveurs subtiles des ingrédients nordiques."
            },
            "calories": "360 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Une recette de difficulté moyenne, la principale difficulté étant de trouver certains ingrédients spécifiques, mais des substituts courants peuvent être utilisés."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche-a-decouper"
            ]
        },
        {
            "id": 139,
            "categorie": "europe",
            "country": "Groenland",
            "flag": "GL",
            "title": "Poulet aux Algues et Fruits de Mer",
            "subTitle": "Poulet mijoté avec des algues marines, des crevettes arctiques et des herbes nordiques",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 blancs de poulet (ou 4 cuisses désossées)",
                "200g de crevettes nordiques décortiquées",
                "20g d'algues séchées (dulse ou nori), réhydratées",
                "2 poireaux, émincés en fines rondelles",
                "1 fenouil, émincé finement",
                "2 oignons, émincés",
                "3 gousses d'ail, émincées",
                "300ml de crème fraîche liquide",
                "200ml de bouillon de poisson ou de fumet",
                "100ml de vin blanc sec",
                "2 cuillères à soupe d'huile d'olive",
                "1 cuillère à soupe d'aneth frais haché",
                "1 cuillère à soupe de ciboulette fraîche ciselée",
                "1 cuillère à café de zeste de citron râpé",
                "1 cuillère à café de graines d'aneth",
                "Sel et poivre",
                "1 cuillère à soupe de jus de citron"
            ],
            "ingredient_liste": [
                "blanc-de-poulet",
                "cuisse-de-poulet",
                "crevettes",
                "algues",
                "poireau",
                "fenouil",
                "oignon",
                "ail",
                "creme-fraiche",
                "bouillon-poisson",
                "vin-blanc",
                "huile-olive",
                "aneth",
                "ciboulette",
                "citron",
                "graines-aneth",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Couper les blancs ou cuisses de poulet en morceaux d'environ 3 cm.",
                "Faire tremper les algues séchées dans de l'eau tiède pendant 10 minutes, puis les égoutter et les hacher grossièrement.",
                "Émincer les poireaux, le fenouil, les oignons et l'ail.",
                "Hacher finement l'aneth et la ciboulette, râper le zeste de citron."
            ],
            "cuisson": [
                "Dans une grande poêle ou cocotte, chauffer l'huile d'olive à feu moyen-vif.",
                "Faire revenir les morceaux de poulet jusqu'à ce qu'ils soient dorés de tous côtés, environ 5 minutes. Réserver.",
                "Dans la même poêle, faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter l'ail et faire revenir pendant 30 secondes.",
                "Incorporer les poireaux et le fenouil, puis faire revenir pendant 3-4 minutes jusqu'à ce qu'ils commencent à ramollir.",
                "Déglacer avec le vin blanc et laisser réduire de moitié.",
                "Ajouter les algues hachées et le bouillon de poisson, puis porter à ébullition.",
                "Remettre les morceaux de poulet dans la poêle, réduire à feu doux, couvrir et laisser mijoter pendant 15 minutes.",
                "Ajouter les crevettes et la crème fraîche, puis laisser mijoter à découvert pendant 5-7 minutes supplémentaires, jusqu'à ce que la sauce épaississe légèrement.",
                "Incorporer les graines d'aneth, le zeste de citron, l'aneth frais et la ciboulette.",
                "Ajouter le jus de citron, goûter et ajuster l'assaisonnement avec du sel et du poivre.",
                "Laisser reposer 2 minutes avant de servir pour que les saveurs se mélangent."
            ],
            "cooking_methods": {
                "best": "poele",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": true,
                "cocotte": true
            },
            "mode_cuisson": [
                "poele",
                "cocotte"
            ],
            "accompagnement": "Servir avec du pain de seigle noir et une purée de panais. Pour la purée: faire cuire 600g de panais épluchés et coupés en morceaux dans de l'eau salée jusqu'à ce qu'ils soient tendres, les égoutter puis les mixer avec 50g de beurre, 100ml de crème, du sel et du poivre jusqu'à obtenir une texture lisse.",
            "shortStory": "Le **Poulet aux Algues et Fruits de Mer** est une création culinaire qui associe les traditions maritimes du Groenland à des ingrédients plus contemporains. Cette recette reflète l'importance de la mer dans la culture alimentaire groenlandaise, où les algues et les fruits de mer constituent depuis des millénaires une part essentielle du régime local. Si le poulet n'est pas traditionnellement associé à la cuisine inuite, ce plat fusion illustre l'évolution moderne de la gastronomie groenlandaise, influencée par les cuisines scandinave et internationale. Les herbes aromatiques comme l'aneth et la ciboulette, qui poussent pendant le court été arctique, apportent une fraîcheur qui complète parfaitement la richesse de la crème et la saveur iodée des algues et des crevettes. Ce mélange terre-mer est typique de la nouvelle cuisine nordique qui valorise les produits locaux tout en les réinterprétant de façon contemporaine.",
            "detailURL": "/fiche-recette/139/poulet-algues-crevettes-creme/poulet-algues-fruits-mer.html",
            "time_prepare": "25mn",
            "time_maceration": "0h",
            "time_cooking": "35mn",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Cette recette est d'un coût moyen à élevé (niveau 3) en raison des crevettes nordiques et des algues qui peuvent être coûteuses, bien que ces dernières puissent être remplacées par des algues plus courantes comme le nori."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette n'est pas épicée mais offre un profil de saveur complexe grâce aux herbes aromatiques et aux algues marines."
            },
            "calories": "390 kcal",
            "allergens": [
                "crustaces",
                "lait"
            ],
            "difficulty": {
                "level": 2,
                "description": "Une recette de difficulté moyenne, nécessitant principalement une attention aux temps de cuisson pour que le poulet reste tendre et que les crevettes ne cuisent pas trop."
            },
            "equipment_needed": [
                "poele",
                "cocotte",
                "couteau"
            ]
        },
        {
            "id": 140,
            "categorie": "amerique",
            "country": "Guatemala",
            "flag": "GT",
            "title": "Poulet Kak'ik",
            "subTitle": "Soupe de poulet traditionnelle maya aux piments et épices",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier d'environ 1,5 kg, coupé en morceaux",
                "4 piments guajillo séchés, épépinés",
                "2 piments ancho séchés, épépinés",
                "2 tomates mûres, coupées en quartiers",
                "1 poivron rouge, épépiné et coupé en morceaux",
                "2 oignons moyens, coupés en quartiers",
                "1 tête d'ail entière, coupée en deux horizontalement",
                "1 bouquet de coriandre fraîche (tiges et feuilles séparées)",
                "3 cuillères à soupe d'achiote (ou colorant alimentaire rouge + paprika)",
                "1 cuillère à soupe de graines de coriandre",
                "1 cuillère à café de graines de cumin",
                "4 clous de girofle entiers",
                "1 bâton de cannelle",
                "1 cuillère à café d'origan séché",
                "2 feuilles de laurier",
                "2 litres de bouillon de poulet",
                "3 cuillères à soupe d'huile végétale",
                "Sel et poivre",
                "1 lime (citron vert), coupée en quartiers pour servir"
            ],
            "ingredient_liste": [
                "poulet",
                "piment-guajillo",
                "piment-ancho",
                "tomate",
                "poivron-rouge",
                "oignon",
                "ail",
                "coriandre",
                "achiote",
                "coriandre-graine",
                "cumin",
                "girofle",
                "cannelle",
                "origan",
                "laurier",
                "bouillon-poulet",
                "huile-vegetale",
                "sel",
                "poivre",
                "citron-vert"
            ],
            "preparation": [
                "Faire tremper les piments guajillo et ancho dans de l'eau chaude pendant 20 minutes jusqu'à ce qu'ils soient réhydratés, puis les égoutter.",
                "Dans une poêle sèche à feu moyen, faire griller légèrement les graines de coriandre et de cumin jusqu'à ce qu'elles soient parfumées, environ 1-2 minutes.",
                "Séparer les tiges et les feuilles de coriandre; hacher grossièrement les tiges et réserver les feuilles pour la garniture."
            ],
            "cuisson": [
                "Dans une grande marmite, chauffer l'huile à feu moyen-vif.",
                "Faire dorer les morceaux de poulet de tous côtés, par lots si nécessaire, puis réserver.",
                "Dans la même marmite, faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter la tête d'ail coupée et faire revenir pendant 2 minutes.",
                "Dans un mixeur ou un robot culinaire, combiner les piments réhydratés, les tomates, le poivron rouge, les épices grillées, l'achiote, les clous de girofle, le bâton de cannelle, l'origan et les tiges de coriandre.",
                "Ajouter environ 1 tasse de bouillon et mixer jusqu'à obtenir une pâte lisse.",
                "Verser cette pâte dans la marmite et faire revenir pendant 5 minutes, en remuant constamment.",
                "Remettre les morceaux de poulet dans la marmite et ajouter le reste du bouillon et les feuilles de laurier.",
                "Porter à ébullition, puis réduire à feu doux, couvrir partiellement et laisser mijoter pendant 45-50 minutes, jusqu'à ce que le poulet soit tendre et se détache facilement de l'os.",
                "Retirer les morceaux de poulet et les désosser si désiré, puis les remettre dans la soupe.",
                "Goûter et ajuster l'assaisonnement avec du sel et du poivre.",
                "Servir chaud, garni de feuilles de coriandre hachées et accompagné de quartiers de lime."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec des tamales blancs et du riz blanc. Pour les tamales: mélanger 500g de masa harina avec 375ml d'eau tiède, 125g de saindoux fondu et 1 cuillère à café de sel pour former une pâte. Étaler la pâte sur des feuilles de maïs, replier et cuire à la vapeur pendant 45 minutes.",
            "shortStory": "Le **Kak'ik** est une soupe traditionnelle maya originaire de la région de l'Alta Verapaz au Guatemala. Son nom vient du q'eqchi', une langue maya, où 'kak' signifie 'rouge' et 'ik' signifie 'piquant' ou 'chile'. Ce plat ancestral est considéré comme l'un des trésors culinaires du Guatemala et a été déclaré patrimoine culturel immatériel du pays en 2007. Traditionnellement préparé avec de la dinde, notre version utilise du poulet tout en restant fidèle aux saveurs authentiques. Le Kak'ik est souvent servi lors des cérémonies et célébrations importantes, symbolisant le lien profond entre la culture maya et sa gastronomie. La couleur rouge distinctive de la soupe, obtenue grâce à l'achiote et aux piments, évoque le sang et la force vitale dans la cosmologie maya, faisant de ce plat non seulement un délice culinaire mais aussi un symbole culturel puissant.",
            "detailURL": "/fiche-recette/140/poulet-soupe-piments-epices/poulet-kakik.html",
            "time_prepare": "30mn",
            "time_maceration": "0h",
            "time_cooking": "60mn",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Cette recette est d'un coût moyen à élevé (niveau 3) en raison des nombreuses épices et ingrédients spécifiques comme les piments séchés et l'achiote qui peuvent être difficiles à trouver dans certaines régions."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Cette recette est significativement épicée, avec une chaleur complexe provenant des différents piments. L'intensité peut être ajustée selon les préférences en modifiant la quantité de piments utilisée."
            },
            "calories": "340 kcal",
            "allergens": [],
            "difficulty": {
                "level": 3,
                "description": "Une recette de difficulté moyenne à élevée, nécessitant plusieurs étapes de préparation et une attention particulière à la préparation de la base de piments."
            },
            "equipment_needed": [
                "marmite",
                "mixeur",
                "poele"
            ]
        },
        {
            "id": 141,
            "categorie": "amerique",
            "country": "Guyana",
            "flag": "GY",
            "title": "Poulet Mettagee",
            "subTitle": "Ragoût de poulet aux légumes racines et aux dumplings",
            "chicken_piece": [
                "cuisse",
                "pilon",
                "aile"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": true,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "8 morceaux de poulet (cuisses, pilons ou ailes)",
                "200g de manioc, pelé et coupé en gros morceaux",
                "200g de patate douce, pelée et coupée en gros morceaux",
                "200g d'igname, pelé et coupé en gros morceaux",
                "2 bananes plantains vertes, pelées et coupées en tronçons",
                "200g de taro (ou pommes de terre), pelé et coupé en morceaux",
                "2 oignons moyens, émincés",
                "3 gousses d'ail, émincées",
                "2 cuillères à soupe de gingembre frais râpé",
                "1 piment scotch bonnet, entier (pour parfumer)",
                "2 tomates, concassées",
                "2 cuillères à soupe de concentré de tomate",
                "400ml de lait de coco",
                "500ml de bouillon de poulet",
                "3 cuillères à soupe d'huile végétale",
                "2 tiges de citronnelle, écrasées",
                "3 oignons verts, émincés",
                "1 cuillère à café de thym séché",
                "2 feuilles de laurier",
                "Sel et poivre",
                "Pour les dumplings: 200g de farine, 1/2 cuillère à café de sel, 100ml d'eau"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "pilons-de-poulet",
                "ailes-de-poulet",
                "manioc",
                "patate-douce",
                "igname",
                "banane-plantain",
                "taro",
                "oignon",
                "ail",
                "gingembre",
                "piment-scotch-bonnet",
                "tomate",
                "concentre-tomate",
                "lait-de-coco",
                "bouillon-poulet",
                "huile-vegetale",
                "citronnelle",
                "oignon-vert",
                "thym",
                "laurier",
                "sel",
                "poivre",
                "farine"
            ],
            "preparation": [
                "Assaisonner les morceaux de poulet avec du sel et du poivre.",
                "Préparer tous les légumes racines: peler et couper le manioc, la patate douce, l'igname, le taro en gros morceaux; peler et couper les plantains en tronçons.",
                "Pour les dumplings: dans un bol, mélanger la farine et le sel, puis ajouter progressivement l'eau tout en pétrissant pour former une pâte ferme. Former des petites boules ou des rouleaux allongés."
            ],
            "cuisson": [
                "Dans une grande marmite, chauffer l'huile à feu moyen-vif.",
                "Faire dorer les morceaux de poulet de tous côtés, environ 5 minutes, puis réserver.",
                "Dans la même marmite, faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter l'ail, le gingembre et faire revenir pendant 1 minute.",
                "Incorporer les tomates concassées et le concentré de tomate, puis cuire pendant 3-4 minutes.",
                "Remettre les morceaux de poulet dans la marmite et ajouter la citronnelle, le thym, les feuilles de laurier et le piment scotch bonnet entier.",
                "Verser le bouillon de poulet et le lait de coco, porter à ébullition, puis réduire à feu doux.",
                "Ajouter le manioc, l'igname et le taro, qui nécessitent un temps de cuisson plus long, et laisser mijoter pendant 15 minutes.",
                "Ajouter ensuite la patate douce et les plantains, et poursuivre la cuisson pendant 10 minutes.",
                "Déposer délicatement les dumplings sur le dessus du ragoût, couvrir et laisser mijoter pendant encore 15 minutes, jusqu'à ce que les dumplings soient cuits et que tous les légumes soient tendres.",
                "Retirer la citronnelle, les feuilles de laurier et le piment scotch bonnet.",
                "Parsemer d'oignons verts émincés avant de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Ce plat complet se suffit à lui-même, mais peut être servi avec du pain au coco. Pour le pain au coco: mélanger 400g de farine, 2 cuillères à café de levure, 1 cuillère à café de sel, 2 cuillères à soupe de sucre, 200ml de lait de coco et 2 cuillères à soupe d'huile de coco. Former une pâte, laisser lever 1 heure, puis cuire au four à 180°C pendant 30-35 minutes.",
            "shortStory": "Le **Mettagee** (parfois orthographié Metemgee ou Metagee) est un plat traditionnel du Guyana qui illustre parfaitement la nature multiculturelle de la cuisine guyanaise. Ce ragoût copieux combine diverses influences: africaine dans l'utilisation des légumes racines, indienne dans l'ajout du curry, et amérindienne dans l'emploi du manioc. Historiquement, ce plat était préparé dans un grand pot par les communautés lors des rassemblements, où chaque famille apportait un ingrédient différent. Notre version avec du poulet est une adaptation du Mettagee original, qui peut inclure diverses viandes ou poissons selon les disponibilités. Les dumplings, petites boulettes de pâte qui cuisent directement dans le ragoût, sont un élément essentiel qui ajoute de la texture et de la substance à ce plat nourrissant. En Guyana, ce plat est synonyme de convivialité et symbolise l'harmonie entre les différentes cultures qui composent ce pays.",
            "detailURL": "/fiche-recette/141/poulet-legumes-racines-dumplings/poulet-mettagee.html",
            "time_prepare": "30mn",
            "time_maceration": "0h",
            "time_cooking": "70mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût modéré (niveau 2), bien que certains légumes racines comme le taro ou l'igname puissent être plus difficiles à trouver selon les régions."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette est modérément épicée grâce au piment scotch bonnet qui parfume le plat sans le rendre excessivement piquant si on le laisse entier."
            },
            "calories": "480 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Une recette de difficulté moyenne, nécessitant principalement de la préparation pour les différents légumes racines et une attention aux temps de cuisson variés."
            },
            "equipment_needed": [
                "marmite",
                "couteau",
                "planche-a-decouper"
            ]
        },
        {
            "id": 142,
            "categorie": "afrique",
            "country": "Gabon",
            "flag": "GA",
            "title": "Poulet Mwamba",
            "subTitle": "Poulet mijoté dans une sauce crémeuse aux graines de courge",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux (ou 6 cuisses/pilons)",
                "300g de graines de courge (égusi) moulues",
                "3 oignons moyens, émincés",
                "4 gousses d'ail, émincées",
                "2 cuillères à soupe de gingembre frais râpé",
                "3 tomates mûres, concassées",
                "2 piments scotch bonnet (ajuster selon le goût), hachés",
                "500ml de bouillon de poulet",
                "3 cuillères à soupe d'huile de palme (ou huile végétale)",
                "200g d'épinards frais (ou feuilles de manioc), hachés",
                "1 bouquet de persil frais, haché",
                "1 cuillère à café de poivre noir",
                "Sel",
                "2 cubes de bouillon de poulet émiettés"
            ],
            "ingredient_liste": [
                "poulet",
                "graines-de-courge",
                "oignon",
                "ail",
                "gingembre",
                "tomate",
                "piment-scotch-bonnet",
                "bouillon-poulet",
                "huile-de-palme",
                "epinard",
                "persil",
                "poivre",
                "sel",
                "cube-bouillon"
            ],
            "preparation": [
                "Assaisonner les morceaux de poulet avec du sel, du poivre et un cube de bouillon émietté.",
                "Si les graines de courge ne sont pas déjà moulues, les réduire en poudre dans un moulin à café ou un mixeur puissant.",
                "Émincer les oignons, l'ail et hacher les piments.",
                "Laver et hacher grossièrement les épinards ou les feuilles de manioc."
            ],
            "cuisson": [
                "Dans une grande marmite, chauffer l'huile de palme à feu moyen-vif.",
                "Faire dorer les morceaux de poulet de tous côtés pendant environ 5 minutes, puis réserver.",
                "Dans la même marmite, faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter l'ail, le gingembre et les piments hachés, puis faire revenir pendant 1 minute.",
                "Incorporer les tomates concassées et cuire pendant 3-4 minutes jusqu'à ce qu'elles commencent à se défaire.",
                "Saupoudrer les graines de courge moulues dans la marmite et mélanger pour former une pâte.",
                "Verser progressivement le bouillon de poulet tout en remuant pour éviter les grumeaux.",
                "Remettre les morceaux de poulet dans la sauce, ajouter le cube de bouillon restant émietté, couvrir et laisser mijoter à feu doux pendant 30 minutes.",
                "Ajouter les épinards ou les feuilles de manioc hachées, mélanger et poursuivre la cuisson pendant 10-15 minutes supplémentaires.",
                "La sauce doit être crémeuse et légèrement épaisse. Si elle est trop épaisse, ajouter un peu de bouillon; si elle est trop liquide, laisser mijoter à découvert pour qu'elle réduise.",
                "Goûter et ajuster l'assaisonnement avec du sel et du poivre.",
                "Parsemer de persil frais haché avant de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc ou du foufou (pâte de manioc). Pour le foufou: porter à ébullition 1 litre d'eau, puis verser progressivement 500g de farine de manioc en remuant vigoureusement pour éviter les grumeaux. Continuer à remuer pendant 10-15 minutes jusqu'à obtenir une pâte épaisse et élastique.",
            "shortStory": "Le **Poulet Mwamba** est un plat traditionnel gabonais qui met en valeur les graines de courge, un ingrédient très apprécié dans la cuisine d'Afrique centrale. Ce ragoût, dont le nom signifie 'feuilles' en langue locale, combine la richesse des graines oléagineuses avec des légumes à feuilles vertes pour créer une sauce onctueuse et nourrissante. Au Gabon, ce plat est souvent préparé pour les occasions spéciales et les célébrations familiales. Les graines de courge, riches en protéines et en graisses saines, apportent une texture crémeuse et un goût de noisette à la sauce. Traditionnellement, on utilise des feuilles de manioc, mais les épinards constituent un substitut accessible qui préserve l'esprit du plat. L'huile de palme, ingrédient emblématique de la cuisine gabonaise, apporte une couleur orangée caractéristique et une saveur distinctive à ce plat savoureux.",
            "detailURL": "/fiche-recette/142/poulet-graines-courge-epinards/poulet-mwamba.html",
            "time_prepare": "25mn",
            "time_maceration": "0h",
            "time_cooking": "60mn",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Cette recette est d'un coût moyen à élevé (niveau 3) en raison des graines de courge qui peuvent être difficiles à trouver et relativement coûteuses."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Cette recette est significativement épicée en raison des piments scotch bonnet. L'intensité peut être facilement ajustée selon les préférences."
            },
            "calories": "450 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Une recette de difficulté moyenne, nécessitant principalement une attention à la texture de la sauce lors de l'ajout des graines de courge moulues."
            },
            "equipment_needed": [
                "marmite",
                "couteau",
                "moulin-a-cafe"
            ]
        },
        {
            "id": 143,
            "categorie": "europe",
            "country": "Géorgie",
            "flag": "GE",
            "title": "Poulet Tabaka",
            "subTitle": "Poulet aplati et rôti à la poêle avec des épices géorgiennes",
            "chicken_piece": [
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "1 jeune poulet entier (environ 1 kg), ouvert et aplati",
                "4 gousses d'ail, écrasées",
                "2 cuillères à soupe de beurre",
                "2 cuillères à soupe d'huile d'olive",
                "1 cuillère à café de poivre noir fraîchement moulu",
                "1 cuillère à café de paprika doux",
                "1 cuillère à café de graines de fenugrec moulues",
                "1/2 cuillère à café de graines de coriandre moulues",
                "1/2 cuillère à café de piment d'Espelette (ou piment doux)",
                "1 cuillère à café de sel",
                "1 citron, coupé en quartiers",
                "1 bouquet de coriandre fraîche, haché"
            ],
            "ingredient_liste": [
                "poulet",
                "ail",
                "beurre",
                "huile-olive",
                "poivre",
                "paprika",
                "fenugrec",
                "coriandre-graine",
                "piment-espelette",
                "sel",
                "citron",
                "coriandre-fraiche"
            ],
            "preparation": [
                "Préparer le poulet en l'ouvrant le long de la colonne vertébrale (ou demander à votre boucher de le faire), retirer la colonne et aplatir le poulet en appuyant fermement sur le sternum.",
                "Dans un petit bol, mélanger l'ail écrasé, le paprika, le fenugrec, les graines de coriandre moulues, le piment d'Espelette, le sel et le poivre noir.",
                "Frotter généreusement ce mélange d'épices sur toute la surface du poulet, y compris sous la peau lorsque c'est possible.",
                "Laisser mariner au réfrigérateur pendant au moins 2 heures, idéalement toute la nuit."
            ],
            "cuisson": [
                "Sortir le poulet du réfrigérateur environ 30 minutes avant la cuisson pour qu'il revienne à température ambiante.",
                "Dans une grande poêle lourde (de préférence en fonte), faire fondre le beurre avec l'huile d'olive à feu moyen-vif.",
                "Placer le poulet dans la poêle, côté peau vers le bas, et poser un poids dessus (comme une autre poêle lourde ou une brique enveloppée dans du papier aluminium) pour maintenir le poulet bien à plat.",
                "Faire cuire pendant environ 15 minutes, jusqu'à ce que la peau soit bien dorée et croustillante.",
                "Retourner délicatement le poulet, remettre le poids, et poursuivre la cuisson pendant 10-15 minutes supplémentaires, jusqu'à ce que le poulet soit entièrement cuit (le jus qui s'écoule doit être clair lorsqu'on pique la partie la plus épaisse de la cuisse).",
                "Si nécessaire pour une cuisson complète, réduire le feu à moyen-doux, couvrir la poêle et laisser cuire encore 5-10 minutes.",
                "Laisser reposer le poulet pendant 5 minutes avant de le découper.",
                "Arroser avec le jus de cuisson et parsemer de coriandre fraîche hachée.",
                "Servir avec des quartiers de citron pour presser sur le poulet."
            ],
            "cooking_methods": {
                "best": "poele",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": true,
                "cocotte": false
            },
            "mode_cuisson": [
                "poele"
            ],
            "accompagnement": "Servir avec une sauce Tkemali (sauce aux prunes vertes) et des pommes de terre rôties au four. Pour la sauce Tkemali: faire revenir 1 oignon émincé et 2 gousses d'ail hachées dans 2 cuillères à soupe d'huile d'olive, ajouter 500g de prunes vertes dénoyautées (ou pommes vertes acidulées), 1 cuillère à soupe de jus de citron, 1 cuillère à café de sel, 1/2 cuillère à café de piment rouge, 1 cuillère à café de coriandre moulue, 1 cuillère à café de menthe séchée, cuire 15 minutes puis mixer.",
            "shortStory": "Le **Poulet Tabaka** (ou Tspitsaki) est l'un des plats les plus emblématiques de la cuisine géorgienne. Le nom vient de 'tapa', une poêle en fonte spéciale traditionnellement utilisée pour sa préparation. Cette méthode de cuisson sous pression permet d'obtenir un poulet à la peau croustillante tout en gardant la chair juteuse et tendre. Dans les foyers géorgiens, ce plat est souvent préparé pour les occasions spéciales et les réceptions, accompagné de sauces traditionnelles comme le Tkemali (sauce aux prunes acides) ou le Satsivi (sauce aux noix). La combinaison d'épices utilisée dans cette recette, notamment le fenugrec et la coriandre, est typique de la cuisine géorgienne et reflète l'influence des traditions culinaires du Caucase. Cette technique de cuisson, qui remonte à plusieurs siècles, démontre l'ingéniosité des méthodes de préparation traditionnelles qui permettent de maximiser les saveurs avec des équipements simples.",
            "detailURL": "/fiche-recette/143/poulet-aplati-epices-georgiennes/poulet-tabaka.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "40mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût modéré (niveau 2), utilisant un poulet entier et des épices qui, bien que certaines soient un peu spécifiques, peuvent servir pour de nombreuses préparations."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette est légèrement épicée, avec une chaleur douce provenant du piment d'Espelette ou du piment doux, qui ajoute de la saveur sans dominer le plat."
            },
            "calories": "380 kcal",
            "allergens": [
                "lait"
            ],
            "difficulty": {
                "level": 2,
                "description": "Une recette de difficulté moyenne, la principale technique étant de bien aplatir le poulet et de gérer la cuisson pour obtenir une peau croustillante sans brûler les épices."
            },
            "equipment_needed": [
                "poele-fonte",
                "poids-cuisson",
                "couteau"
            ]
        },
        {
            "id": 144,
            "categorie": "afrique",
            "country": "Ghana",
            "flag": "GH",
            "title": "Poulet Waakye",
            "subTitle": "Poulet mijoté servi avec du riz et des haricots rouges cuits ensemble",
            "chicken_piece": [
                "cuisse",
                "pilon",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "6 morceaux de poulet (cuisses, pilons ou blancs)",
                "Pour le Waakye: 2 tasses de riz à long grain",
                "Pour le Waakye: 1 tasse de haricots rouges, trempés une nuit",
                "Pour le Waakye: 4-5 feuilles de sorgho (ou 1 cuillère à café de bicarbonate de soude)",
                "2 oignons moyens, émincés",
                "3 tomates mûres, concassées",
                "3 gousses d'ail, émincées",
                "2 cuillères à soupe de gingembre frais râpé",
                "2 piments scotch bonnet, épépinés et hachés (selon goût)",
                "2 cuillères à soupe d'huile de palme (ou huile végétale)",
                "2 cuillères à soupe de concentré de tomate",
                "1 cuillère à café de curry en poudre",
                "1 cuillère à café de poivre noir",
                "1 cuillère à café de poudre d'ail",
                "2 cubes de bouillon de poulet émiettés",
                "Sel",
                "2 oignons verts, émincés pour la garniture"
            ],
            "ingredient_liste": [
                "cuisses-de-poulet",
                "pilons-de-poulet",
                "blanc-de-poulet",
                "riz",
                "haricots-rouges",
                "feuilles-sorgho",
                "oignon",
                "tomate",
                "ail",
                "gingembre",
                "piment-scotch-bonnet",
                "huile-de-palme",
                "concentre-tomate",
                "curry",
                "poivre",
                "poudre-ail",
                "bouillon-poulet",
                "sel",
                "oignon-vert"
            ],
            "preparation": [
                "Assaisonner les morceaux de poulet avec du sel, du poivre, de la poudre d'ail, le curry et un cube de bouillon émietté. Laisser mariner au moins 30 minutes.",
                "Rincer plusieurs fois les haricots rouges préalablement trempés, puis les mettre dans une grande marmite avec de l'eau et les feuilles de sorgho (ou le bicarbonate).",
                "Porter à ébullition et cuire les haricots pendant environ 30-40 minutes, jusqu'à ce qu'ils soient tendres mais pas complètement cuits."
            ],
            "cuisson": [
                "Pour le poulet: Dans une poêle, chauffer l'huile à feu moyen-vif.",
                "Faire dorer les morceaux de poulet de tous côtés pendant environ 5 minutes, puis réserver.",
                "Dans la même poêle, faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter l'ail, le gingembre et les piments hachés, puis faire revenir pendant 1 minute.",
                "Incorporer le concentré de tomate et cuire pendant 2 minutes en remuant.",
                "Ajouter les tomates concassées et laisser mijoter pendant 5 minutes.",
                "Remettre les morceaux de poulet dans la sauce, ajouter un peu d'eau si nécessaire, le cube de bouillon restant émietté, couvrir et laisser mijoter à feu doux pendant 25-30 minutes, jusqu'à ce que le poulet soit bien cuit.",
                "Pour le Waakye: Lorsque les haricots sont presque cuits, ajouter le riz rincé directement dans la même marmite (sans retirer l'eau).",
                "Ajouter suffisamment d'eau pour couvrir le mélange d'environ 2 cm, ainsi qu'une pincée de sel.",
                "Porter à ébullition, puis réduire à feu doux, couvrir et laisser cuire pendant environ 20 minutes, jusqu'à ce que le riz soit tendre et ait absorbé l'eau.",
                "Le riz doit prendre une teinte rosée à rouge grâce aux feuilles de sorgho ou au bicarbonate.",
                "Éteindre le feu et laisser reposer le Waakye pendant 5 minutes avant de servir."
            ],
            "cooking_methods": {
                "best": "poele",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": true,
                "cocotte": false
            },
            "mode_cuisson": [
                "poele"
            ],
            "accompagnement": "Servir le poulet sur le Waakye, accompagné de shito (sauce pimentée ghanéenne) et de gari (farine de manioc fermentée). Pour le shito: faire revenir 3 oignons émincés et 5 gousses d'ail hachées dans 4 cuillères à soupe d'huile, ajouter 3 piments scotch bonnet hachés, 2 cuillères à soupe de pâte de crevettes, 1 cuillère à soupe de pâte de gingembre, 2 cuillères à soupe de concentré de tomate et laisser mijoter 15-20 minutes.",
            "shortStory": "Le **Waakye** (prononcé 'waa-chay') est l'un des plats de rue les plus populaires au Ghana. Son nom vient de la langue Hausa et désigne ce mélange caractéristique de riz et de haricots. Ce qui distingue le Waakye des autres plats similaires d'Afrique de l'Ouest, c'est l'utilisation de feuilles de sorgho qui donnent au riz sa couleur rouge-rosée distinctive. Traditionnellement vendu dans des feuilles de bananier, le Waakye est un aliment de base consommé à tout moment de la journée, du petit-déjeuner au dîner. Notre version associe ce plat emblématique à du poulet mijoté dans une sauce tomate épicée, créant ainsi un repas complet et équilibré. Dans les rues d'Accra, la capitale ghanéenne, les vendeurs de Waakye sont facilement reconnaissables à leurs grandes marmites fumantes et aux longues files d'attente qui se forment devant leurs étals, témoignant de la popularité immense de ce plat nourrissant et économique.",
            "detailURL": "/fiche-recette/144/poulet-riz-haricots-epices/poulet-waakye.html",
            "time_prepare": "30mn",
            "time_maceration": "30mn",
            "time_cooking": "90mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût modéré (niveau 2), utilisant des ingrédients de base comme le riz et les haricots, bien que les feuilles de sorgho puissent être plus difficiles à trouver et remplacées par du bicarbonate de soude."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Cette recette est significativement épicée en raison des piments scotch bonnet. L'intensité peut être ajustée selon les préférences."
            },
            "calories": "480 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Une recette de difficulté moyenne, nécessitant plusieurs étapes de préparation et une attention aux temps de cuisson pour que le riz et les haricots soient parfaitement cuits."
            },
            "equipment_needed": [
                "poele",
                "marmite",
                "couteau"
            ]
        },
        {
            "id": 143,
            "categorie": "europe",
            "country": "Finlande",
            "flag": "FI",
            "title": "Poulet Kana Kastike",
            "subTitle": "Poulet mijoté aux airelles et aux herbes sauvages finlandaises",
            "chicken_piece": [
                "entier",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux (ou 4 cuisses et 2 blancs)",
                "200g d'airelles fraîches ou surgelées",
                "3 cuillères à soupe de miel de forêt finlandais",
                "4 cuillères à soupe d'aneth frais haché",
                "2 cuillères à soupe de baies de genièvre écrasées",
                "3 oignons moyens, émincés",
                "3 gousses d'ail, émincées",
                "250ml de bouillon de poulet",
                "100ml de crème fraîche épaisse",
                "2 cuillères à soupe d'huile de colza",
                "2 cuillères à café de vinaigre de cidre",
                "1 cuillère à café de graines de carvi (cumin des prés)",
                "1 bouquet de persil frais",
                "Sel et poivre noir"
            ],
            "ingredient_liste": [
                "poulet",
                "airelles",
                "miel",
                "aneth",
                "baies-de-genievre",
                "oignon",
                "ail",
                "bouillon-poulet",
                "creme-fraiche",
                "huile-colza",
                "vinaigre-cidre",
                "carvi",
                "persil",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Assaisonner les morceaux de poulet avec du sel, du poivre et les baies de genièvre écrasées.",
                "Dans un bol, mélanger le miel, le vinaigre de cidre, l'aneth haché (gardez-en un peu pour la décoration) et les graines de carvi.",
                "Badigeonner les morceaux de poulet avec ce mélange et laisser mariner au réfrigérateur pendant au moins 2 heures, idéalement toute une nuit.",
                "Émincer les oignons et l'ail finement."
            ],
            "cuisson": [
                "Dans une grande cocotte, chauffer l'huile de colza à feu moyen-vif.",
                "Faire dorer les morceaux de poulet de tous côtés pendant environ 5-7 minutes.",
                "Ajouter les oignons émincés et faire revenir jusqu'à ce qu'ils soient translucides.",
                "Ajouter l'ail émincé et faire revenir pendant 1 minute supplémentaire.",
                "Verser le bouillon de poulet, ajouter les airelles et porter à ébullition.",
                "Réduire le feu, couvrir et laisser mijoter pendant environ 30-35 minutes jusqu'à ce que le poulet soit bien cuit et tendre.",
                "Retirer les morceaux de poulet et les réserver au chaud.",
                "Augmenter le feu et laisser réduire la sauce de moitié, pendant environ 5-7 minutes.",
                "Baisser le feu et incorporer la crème fraîche en remuant constamment.",
                "Remettre les morceaux de poulet dans la sauce, poursuivre la cuisson pendant 3-4 minutes pour réchauffer le tout.",
                "Goûter et ajuster l'assaisonnement si nécessaire.",
                "Parsemer d'aneth frais avant de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte",
                "four"
            ],
            "accompagnement": "Servir avec des pommes de terre nouvelles bouillies saupoudrées d'aneth frais et des légumes racines rôtis. Pour les légumes racines: peler et couper en morceaux 3 carottes, 2 panais et 1 rutabaga. Mélanger avec 2 cuillères à soupe d'huile, du sel et du poivre. Enfourner à 200°C pendant 25-30 minutes jusqu'à ce qu'ils soient tendres et légèrement caramélisés.",
            "shortStory": "Le **Poulet Kana Kastike** est un plat traditionnel finlandais qui célèbre les saveurs nordiques. Les **airelles** sauvages, fruit emblématique des forêts finlandaises, apportent une acidité qui équilibre parfaitement la douceur du miel local. En Finlande, cette recette est souvent préparée en automne, pendant la saison de cueillette des baies. L'utilisation des **herbes sauvages** comme l'aneth et des **épices nordiques** comme le carvi (cumin des prés) et les baies de genièvre reflète l'importance de la cueillette dans la gastronomie finlandaise. Cette recette rustique mais raffinée réchauffe les longues soirées d'hiver finlandaises et est souvent servie lors des célébrations familiales, notamment pendant les fêtes de Noël ou à la Saint-Jean (Juhannus), la grande fête du solstice d'été.",
            "detailURL": "/fiche-recette/143/poulet-airelles-aneth/poulet-kana-kastike.html",
            "time_prepare": "20mn",
            "time_maceration": "2h",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2) avec comme ingrédient coûteux principalement les airelles qui peuvent être remplacées par des canneberges en dehors de la saison."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette n'est pas épicée et convient à tous les palais, y compris ceux qui sont sensibles aux épices."
            },
            "calories": "420 kcal",
            "allergens": [
                "produits-laitiers"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne, nécessitant principalement une attention à la cuisson du poulet et à l'équilibre entre l'acidité des airelles et la douceur du miel."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche-a-decouper"
            ]
        },
        {
            "id": 144,
            "categorie": "europe",
            "country": "Finlande",
            "flag": "FI",
            "title": "Poulet Savustettu",
            "subTitle": "Poulet fumé au bois de bouleau et mariné aux agrumes nordiques",
            "chicken_piece": [
                "entier",
                "cuisse"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier (environ 1,5 kg) ou 6 cuisses",
                "2 citrons bio, zeste et jus",
                "1 orange sanguine, zeste et jus",
                "4 cuillères à soupe de sirop de bouleau",
                "4 cuillères à soupe d'huile de colza",
                "2 branches de romarin frais",
                "4 brins de thym frais",
                "4 gousses d'ail, écrasées avec la peau",
                "2 cuillères à café de graines de fenouil sauvage écrasées",
                "1 cuillère à café de sel fumé",
                "1 cuillère à café de poivre noir concassé",
                "Copeaux de bois de bouleau pour le fumage (ou autre bois disponible)"
            ],
            "ingredient_liste": [
                "poulet",
                "citron",
                "orange-sanguine",
                "sirop-bouleau",
                "huile-colza",
                "romarin",
                "thym",
                "ail",
                "fenouil-sauvage",
                "sel-fume",
                "poivre",
                "copeaux-bouleau"
            ],
            "preparation": [
                "Si vous utilisez un poulet entier, le couper en deux le long de la colonne vertébrale (à plat) pour faciliter la cuisson.",
                "Dans un grand bol, mélanger le jus et les zestes de citron et d'orange, le sirop de bouleau, l'huile de colza, le romarin et le thym hachés, l'ail écrasé, les graines de fenouil, le sel fumé et le poivre noir.",
                "Placer le poulet dans un grand sac de congélation ou un plat profond et verser la marinade dessus. Bien masser la marinade dans le poulet.",
                "Fermer le sac ou couvrir le plat et laisser mariner au réfrigérateur pendant au moins 6 heures, idéalement toute une nuit, en retournant occasionnellement."
            ],
            "cuisson": [
                "Sortir le poulet du réfrigérateur environ 30 minutes avant la cuisson pour qu'il revienne à température ambiante.",
                "Préparer le fumoir ou le barbecue pour un fumage à chaud indirect à environ 120-150°C. Si vous utilisez un barbecue, placez les charbons d'un seul côté.",
                "Faire tremper les copeaux de bois de bouleau dans l'eau pendant 30 minutes, puis les égoutter.",
                "Lorsque le fumoir ou le barbecue est prêt, ajouter les copeaux de bois trempés sur les charbons ou dans la boîte à fumée.",
                "Retirer le poulet de la marinade en conservant celle-ci pour le badigeonnage.",
                "Placer le poulet côté peau vers le haut sur la grille, loin de la chaleur directe.",
                "Fermer le couvercle et maintenir une température constante, en ajoutant des copeaux de bois toutes les 30 minutes pour maintenir la fumée.",
                "Badigeonner le poulet avec la marinade restante toutes les 30 minutes.",
                "Pour un poulet entier à plat, fumer pendant environ 1h30-2h. Pour les cuisses, environ 1h-1h15.",
                "Le poulet est cuit lorsque la température interne atteint 75°C à l'endroit le plus épais de la cuisse.",
                "Laisser reposer le poulet, couvert de papier aluminium, pendant 10-15 minutes avant de servir."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "barbecue",
                "four"
            ],
            "accompagnement": "Servir avec une salade tiède de betteraves rôties et pommes de terre nouvelles. Pour la préparation: couper 4 betteraves moyennes en quartiers et 500g de pommes de terre nouvelles en deux. Les mélanger avec 3 cuillères à soupe d'huile d'olive, du sel et du poivre. Rôtir au four à 200°C pendant 30-35 minutes. Laisser refroidir légèrement, puis ajouter 100g de fromage finlandais Leipäjuusto (ou à défaut, halloumi) coupé en dés et 2 cuillères à soupe d'aneth frais haché. Arroser d'une vinaigrette faite avec 2 cuillères à soupe d'huile, 1 cuillère à soupe de vinaigre de cidre et 1 cuillère à café de moutarde à l'aneth.",
            "shortStory": "Le **Poulet Savustettu** (poulet fumé) est un hommage aux traditions culinaires finlandaises où le **fumage** des viandes et des poissons est une technique de conservation ancestrale. En Finlande, le **bois de bouleau** est traditionnellement utilisé pour son arôme délicat qui se marie parfaitement avec la volaille. Les **agrumes** apportent une fraîcheur qui contraste avec les notes fumées profondes, tandis que le **sirop de bouleau**, édulcorant naturel récolté au printemps, ajoute une douceur subtile caractéristique de la cuisine nordique. Historiquement, les techniques de fumage permettaient de conserver les aliments pendant les longs hivers finlandais. Aujourd'hui, ce plat est souvent préparé lors des rassemblements estivaux dans les chalets au bord des lacs (mökkis), où les familles finlandaises se retrouvent pour profiter de la nature et des nuits blanches du solstice d'été.",
            "detailURL": "/fiche-recette/144/poulet-fume-agrumes-bouleau/poulet-savustettu.html",
            "time_prepare": "30mn",
            "time_maceration": "6h",
            "time_cooking": "120mn",
            "crispys": true,
            "cost": {
                "level": 3,
                "description": "Cette recette est d'un coût moyen à élevé (niveau 3) en raison principalement du sirop de bouleau et du sel fumé qui peuvent être difficiles à trouver hors de Scandinavie."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette n'est pas épicée mais plutôt aromatique avec des notes fumées et d'agrumes."
            },
            "calories": "380 kcal",
            "allergens": [],
            "difficulty": {
                "level": 3,
                "description": "Recette de difficulté élevée nécessitant une technique de fumage et un contrôle précis de la température pour obtenir un poulet juteux et bien fumé."
            },
            "equipment_needed": [
                "barbecue-fumoir",
                "thermometre-cuisine",
                "copeaux-bois"
            ]
        }, {
            "id": 145,
            "categorie": "europe",
            "country": "France",
            "flag": "FR",
            "title": "Poulet à l'Estragon",
            "subTitle": "Poulet mijoté dans une sauce crémeuse parfumée à l'estragon frais",
            "chicken_piece": [
                "entier",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet fermier de 1,5 kg découpé en morceaux (ou 4 cuisses et 2 blancs)",
                "50g de beurre",
                "2 échalotes, finement émincées",
                "2 gousses d'ail, écrasées",
                "100ml de vin blanc sec (Chablis ou Sancerre)",
                "200ml de crème fraîche épaisse",
                "1 bouquet d'estragon frais (environ 30g)",
                "1 cuillère à soupe de moutarde de Dijon",
                "1 cuillère à soupe de jus de citron frais",
                "250ml de bouillon de volaille",
                "2 cuillères à soupe d'huile d'olive",
                "Sel et poivre du moulin",
                "1 feuille de laurier"
            ],
            "ingredient_liste": [
                "poulet",
                "beurre",
                "echalote",
                "ail",
                "vin-blanc",
                "creme-fraiche",
                "estragon",
                "moutarde-dijon",
                "citron",
                "bouillon-volaille",
                "huile-olive",
                "sel",
                "poivre",
                "laurier"
            ],
            "preparation": [
                "Assaisonner les morceaux de poulet avec du sel et du poivre fraîchement moulu.",
                "Hacher grossièrement la moitié de l'estragon et réserver l'autre moitié pour la décoration.",
                "Émincer finement les échalotes et écraser l'ail."
            ],
            "cuisson": [
                "Dans une cocotte ou une sauteuse à fond épais, faire chauffer l'huile d'olive et la moitié du beurre à feu moyen-vif.",
                "Faire dorer les morceaux de poulet sur toutes les faces pendant environ 5-7 minutes, jusqu'à ce qu'ils soient bien dorés.",
                "Retirer les morceaux de poulet et les réserver.",
                "Dans la même cocotte, faire fondre le reste du beurre et faire revenir les échalotes jusqu'à ce qu'elles soient translucides.",
                "Ajouter l'ail écrasé et cuire pendant 30 secondes supplémentaires.",
                "Déglacer avec le vin blanc et gratter les sucs de cuisson avec une spatule en bois.",
                "Laisser réduire le vin de moitié, puis ajouter le bouillon de volaille et la feuille de laurier.",
                "Remettre les morceaux de poulet dans la cocotte, couvrir et laisser mijoter à feu doux pendant 25-30 minutes pour les cuisses, 15-20 minutes pour les blancs.",
                "Retirer les morceaux de poulet et les maintenir au chaud.",
                "Enlever la feuille de laurier et augmenter le feu pour réduire la sauce d'un tiers.",
                "Baisser le feu, incorporer la moutarde de Dijon en fouettant, puis ajouter la crème fraîche et l'estragon haché.",
                "Laisser mijoter doucement pendant 2-3 minutes sans faire bouillir pour que la sauce épaississe légèrement.",
                "Ajouter le jus de citron, goûter et ajuster l'assaisonnement.",
                "Remettre les morceaux de poulet dans la sauce pour les réchauffer pendant 2-3 minutes.",
                "Servir parsemé du reste d'estragon frais."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": true,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte",
                "poele"
            ],
            "accompagnement": "Servir avec un riz pilaf ou des pommes de terre nouvelles à la vapeur. Pour le riz pilaf: faire revenir 1 échalote finement émincée dans 30g de beurre jusqu'à ce qu'elle soit translucide. Ajouter 300g de riz (type basmati ou arborio), remuer pendant 1-2 minutes pour enrober les grains. Verser 600ml de bouillon de volaille chaud, saler légèrement, couvrir et cuire à feu doux pendant 18 minutes. Laisser reposer 5 minutes hors du feu, puis égrainer à la fourchette et incorporer 10g de beurre frais.",
            "shortStory": "Le **Poulet à l'Estragon** est un grand classique de la cuisine française, particulièrement apprécié dans la région bourguignonne. Cette recette met en valeur l'**estragon**, une herbe aromatique au goût anisé subtil qui se marie parfaitement avec la volaille. Au 18ème siècle, cette herbe était déjà cultivée dans les potagers des monastères français et très prisée par les cuisiniers royaux. La **sauce crémeuse** enrichie de vin blanc et de moutarde de Dijon représente l'élégance de la cuisine française classique, où l'harmonie des saveurs prime sur la complexité. Ce plat incarne l'essence même de la cuisine bourgeoise française, celle des repas dominicaux en famille, où la **cocotte en fonte** mijote lentement, libérant ses arômes réconfortants. Il illustre parfaitement l'art français de la sauce, où le jus de cuisson est précieusement récupéré et transformé en accompagnement onctueux qui sublime la viande.",
            "detailURL": "/fiche-recette/145/poulet-estragon-creme/poulet-a-l-estragon.html",
            "time_prepare": "15mn",
            "time_maceration": "0h",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2), avec comme ingrédients plus onéreux le poulet fermier de qualité et l'estragon frais (qui peut être remplacé par de l'estragon séché hors saison, en quantité réduite)."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette n'est pas épicée et met plutôt en valeur les arômes délicats de l'estragon."
            },
            "calories": "480 kcal",
            "allergens": [
                "produits-laitiers"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne, nécessitant principalement une attention à la cuisson du poulet et à l'émulsion de la sauce crémeuse."
            },
            "equipment_needed": [
                "cocotte",
                "spatule-bois",
                "couteau"
            ]
        }, {
            "id": 146,
            "categorie": "europe",
            "country": "France",
            "flag": "FR",
            "title": "Poulet Basquaise",
            "subTitle": "Poulet mijoté aux poivrons, tomates et piment d'Espelette",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet fermier coupé en morceaux (ou 6 cuisses/pilons)",
                "3 poivrons (1 rouge, 1 vert, 1 jaune), épépinés et coupés en lanières",
                "4 tomates mûres, pelées et concassées (ou 400g de tomates concassées en conserve)",
                "2 oignons, émincés",
                "4 gousses d'ail, émincées",
                "1 bouquet de persil plat frais, ciselé",
                "150ml de vin blanc sec (irouléguy ou autre vin du Sud-Ouest)",
                "2 cuillères à soupe d'huile d'olive",
                "1 cuillère à café de piment d'Espelette (ajuster selon le goût)",
                "1 cuillère à café de thym séché",
                "2 feuilles de laurier",
                "100g de jambon de Bayonne (ou autre jambon cru), coupé en lanières",
                "Sel et poivre du moulin",
                "1 cuillère à soupe de concentré de tomate"
            ],
            "ingredient_liste": [
                "poulet",
                "poivron",
                "tomate",
                "oignon",
                "ail",
                "persil",
                "vin-blanc",
                "huile-olive",
                "piment-espelette",
                "thym",
                "laurier",
                "jambon-bayonne",
                "sel",
                "poivre",
                "concentre-tomate"
            ],
            "preparation": [
                "Assaisonner les morceaux de poulet avec du sel, du poivre et la moitié du piment d'Espelette.",
                "Épépiner les poivrons et les couper en lanières.",
                "Si vous utilisez des tomates fraîches, les peler en les plongeant 30 secondes dans de l'eau bouillante, puis les concasser.",
                "Émincer les oignons et l'ail, ciseler le persil (réserver une partie pour la décoration).",
                "Couper le jambon de Bayonne en lanières."
            ],
            "cuisson": [
                "Dans une grande cocotte ou une sauteuse à fond épais, chauffer l'huile d'olive à feu moyen-vif.",
                "Faire dorer les morceaux de poulet sur toutes les faces pendant 5-7 minutes, puis les réserver.",
                "Dans la même cocotte, faire revenir les oignons émincés jusqu'à ce qu'ils soient translucides.",
                "Ajouter l'ail et les lanières de jambon de Bayonne, faire revenir pendant 2 minutes.",
                "Ajouter les poivrons et faire sauter pendant 5 minutes jusqu'à ce qu'ils commencent à ramollir.",
                "Incorporer le concentré de tomate et faire revenir 1 minute en remuant.",
                "Ajouter les tomates concassées, le thym, le laurier et le reste du piment d'Espelette.",
                "Verser le vin blanc et laisser réduire pendant 3-4 minutes en grattant les sucs de cuisson.",
                "Remettre les morceaux de poulet dans la cocotte, couvrir et laisser mijoter à feu doux pendant 35-40 minutes, en remuant occasionnellement.",
                "Si la sauce est trop liquide, retirer le couvercle et laisser réduire pendant 5-10 minutes supplémentaires.",
                "Incorporer la moitié du persil ciselé, goûter et ajuster l'assaisonnement si nécessaire.",
                "Servir parsemé du reste de persil frais."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc ou des pommes de terre vapeur. Pour un accompagnement plus traditionnel du Pays Basque, préparer du riz pilaf basquaise: faire revenir 1 petit poivron rouge émincé et 1 petit oignon dans 2 cuillères à soupe d'huile d'olive. Ajouter 300g de riz et remuer pendant 1-2 minutes. Verser 600ml de bouillon de volaille, une pincée de piment d'Espelette, couvrir et cuire à feu doux pendant 18 minutes. Laisser reposer 5 minutes hors du feu, puis ajouter 2 cuillères à soupe de persil frais ciselé.",
            "shortStory": "Le **Poulet Basquaise** est une recette emblématique du Pays **Basque**, région située à cheval entre la France et l'Espagne, célèbre pour sa cuisine riche et colorée. Ce plat traditionnel met à l'honneur les produits du terroir basque, notamment le **piment d'Espelette**, épice douce-piquante AOP cultivée dans dix communes du Pays Basque français. Les poivrons, ingrédient phare de la cuisine basque, apportent couleur et saveur à ce plat familial. Historiquement, cette recette était préparée pour les grandes occasions et les fêtes de village (fêtes de Bayonne). Le **jambon de Bayonne**, autre spécialité régionale, apporte une note salée qui relève l'ensemble. Cette recette incarne l'esprit convivial de la cuisine pyrénéenne, où les plats mijotés sont partagés en famille autour de grandes tablées, souvent accompagnés des vins locaux comme l'Irouléguy.",
            "detailURL": "/fiche-recette/146/poulet-poivrons-espelette/poulet-basquaise.html",
            "time_prepare": "25mn",
            "time_maceration": "0h",
            "time_cooking": "50mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2), avec comme ingrédients plus onéreux le piment d'Espelette et le jambon de Bayonne qui peuvent être remplacés par du paprika doux et du jambon cru standard."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette est légèrement épicée grâce au piment d'Espelette, mais l'intensité peut être facilement ajustée selon les préférences."
            },
            "calories": "410 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne, nécessitant principalement une attention à la cuisson des légumes et du poulet pour obtenir un plat savoureux et équilibré."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche-a-decouper"
            ]
        }, {
            "id": 147,
            "categorie": "oceanie",
            "country": "Fidji",
            "flag": "FJ",
            "title": "Poulet Kokoda",
            "subTitle": "Poulet mariné au lait de coco, citron vert et épices du Pacifique",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 blancs de poulet (ou 6 cuisses désossées)",
                "400ml de lait de coco",
                "4 citrons verts (jus et zeste)",
                "1 piment rouge (bird's eye chili), épépiné et finement haché",
                "3 oignons nouveaux, finement émincés",
                "2 cuillères à soupe de gingembre frais râpé",
                "3 gousses d'ail, émincées",
                "2 cuillères à soupe de sauce soja",
                "1 cuillère à soupe de miel",
                "2 tomates, épépinées et coupées en dés",
                "1 concombre, épépiné et coupé en dés",
                "1 poivron rouge, épépiné et coupé en dés",
                "1 bouquet de coriandre fraîche, ciselée",
                "2 cuillères à soupe d'huile de noix de coco",
                "Sel et poivre noir",
                "1 cuillère à café de curcuma moulu"
            ],
            "ingredient_liste": [
                "poulet",
                "lait-coco",
                "citron-vert",
                "piment",
                "oignon-nouveau",
                "gingembre",
                "ail",
                "sauce-soja",
                "miel",
                "tomate",
                "concombre",
                "poivron",
                "coriandre",
                "huile-coco",
                "sel",
                "poivre",
                "curcuma"
            ],
            "preparation": [
                "Couper le poulet en cubes de 2-3 cm.",
                "Dans un bol, mélanger la moitié du lait de coco, le jus et le zeste de 3 citrons verts, la moitié du piment haché, la moitié des oignons nouveaux, le gingembre, l'ail, la sauce soja, le miel et le curcuma.",
                "Ajouter les morceaux de poulet, bien les enrober de marinade, couvrir et réfrigérer pendant 3 à 4 heures (idéalement toute une nuit).",
                "Préparer la garniture en mélangeant les tomates, le concombre et le poivron rouge coupés en dés. Réserver au frais."
            ],
            "cuisson": [
                "Retirer le poulet de la marinade et égoutter (conserver la marinade).",
                "Dans une poêle ou un wok, chauffer l'huile de noix de coco à feu moyen-vif.",
                "Faire sauter les morceaux de poulet par petites quantités pour ne pas surcharger la poêle, environ 5-6 minutes jusqu'à ce qu'ils soient bien dorés et cuits à cœur.",
                "Retirer le poulet et le réserver au chaud.",
                "Dans la même poêle, verser la marinade réservée et porter à ébullition pendant 5 minutes pour la réduire légèrement et s'assurer qu'elle est bien cuite.",
                "Ajouter le reste du lait de coco, remuer et laisser mijoter pendant 3-4 minutes jusqu'à ce que la sauce épaississe légèrement.",
                "Remettre les morceaux de poulet dans la sauce pour les réchauffer pendant 2 minutes.",
                "Juste avant de servir, ajouter le mélange de légumes préparé, le reste des oignons nouveaux et le piment haché, remuer délicatement et réchauffer 1 minute.",
                "Parsemer de coriandre fraîche ciselée et arroser du jus du citron vert restant."
            ],
            "cooking_methods": {
                "best": "poele",
                "grill": false,
                "plancha": true,
                "friture": false,
                "barbecue": true,
                "four": false,
                "poele": true,
                "cocotte": false
            },
            "mode_cuisson": [
                "poele",
                "plancha",
                "barbecue"
            ],
            "accompagnement": "Servir avec du riz au lait de coco. Pour préparer ce riz: rincer 300g de riz basmati jusqu'à ce que l'eau soit claire. Dans une casserole, verser 300ml de lait de coco et 300ml d'eau. Porter à ébullition, ajouter le riz, une pincée de sel et 2 feuilles de pandanus (ou 1/4 cuillère à café d'extrait de vanille). Réduire le feu, couvrir et laisser cuire à feu doux pendant 15 minutes. Laisser reposer 5 minutes avant de servir.",
            "shortStory": "Le **Poulet Kokoda** est une adaptation terrestre du célèbre plat fidjien kokoda, traditionnellement préparé avec du poisson cru mariné dans du **lait de coco** et du citron vert. Cette version utilise du poulet pour créer un plat qui célèbre les saveurs du Pacifique Sud. Les Fidji, avec leur riche mélange d'influences culinaires indigènes, indiennes et chinoises, sont connues pour leur cuisine côtière fraîche et parfumée. Le lait de coco, extrait des nombreuses **noix de coco** qui poussent sur les îles, est un ingrédient de base de la cuisine fidjienne. Ce plat est souvent préparé pour les 'lovo', des festins traditionnels où les aliments sont cuits dans un four creusé dans le sol. Les épices comme le **curcuma** et le gingembre témoignent de l'influence indienne sur la cuisine fidjienne, introduite par les travailleurs sous contrat amenés par les Britanniques au XIXe siècle. Cette recette reflète parfaitement l'atmosphère détendue et conviviale des îles fidjiennes.",
            "detailURL": "/fiche-recette/147/poulet-coco-citron-vert/poulet-kokoda.html",
            "time_prepare": "30mn",
            "time_maceration": "4h",
            "time_cooking": "20mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2), avec comme ingrédients plus coûteux les citrons verts et les épices fraîches qui peuvent être remplacés partiellement par des versions séchées hors saison."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette est modérément épicée grâce au piment, mais l'intensité peut être facilement ajustée selon les préférences en réduisant ou augmentant la quantité de piment."
            },
            "calories": "390 kcal",
            "allergens": [
                "soja"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne, nécessitant principalement une attention à la cuisson du poulet pour qu'il reste tendre et juteux."
            },
            "equipment_needed": [
                "wok",
                "couteau",
                "planche-a-decouper"
            ]
        }, {
            "id": 148,
            "categorie": "oceanie",
            "country": "Fidji",
            "flag": "FJ",
            "title": "Poulet Vakalolo",
            "subTitle": "Poulet mijoté au lait de coco, taro et épices des îles",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux (ou 6 cuisses/pilons)",
                "500ml de lait de coco épais",
                "250ml de bouillon de poulet",
                "300g de taro frais, pelé et coupé en cubes (ou patate douce)",
                "2 oignons, émincés",
                "4 gousses d'ail, écrasées",
                "2 cuillères à soupe de gingembre frais râpé",
                "2 feuilles de pandanus (optionnel)",
                "2 feuilles de citronnier ou zeste d'un citron",
                "2 cuillères à soupe d'huile de noix de coco",
                "2 piments rouges doux, épépinés et émincés",
                "3 tomates, coupées en quartiers",
                "1 bouquet de coriandre fraîche, ciselée",
                "Sel et poivre noir",
                "1 cuillère à café de curcuma moulu",
                "Jus d'un citron vert"
            ],
            "ingredient_liste": [
                "poulet",
                "lait-coco",
                "bouillon-poulet",
                "taro",
                "oignon",
                "ail",
                "gingembre",
                "feuille-pandanus",
                "feuille-citronnier",
                "huile-coco",
                "piment",
                "tomate",
                "coriandre",
                "sel",
                "poivre",
                "curcuma",
                "citron-vert"
            ],
            "preparation": [
                "Assaisonner les morceaux de poulet avec du sel, du poivre et du curcuma.",
                "Si vous utilisez du taro frais, le peler et le couper en cubes de 2 cm.",
                "Émincer les oignons, écraser l'ail, râper le gingembre et émincer les piments."
            ],
            "cuisson": [
                "Dans une grande marmite ou une cocotte, chauffer l'huile de noix de coco à feu moyen-vif.",
                "Faire dorer les morceaux de poulet sur toutes les faces pendant environ 5 minutes, puis réserver.",
                "Dans la même marmite, faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter l'ail, le gingembre et les piments, puis faire revenir pendant 1-2 minutes jusqu'à ce que les arômes se dégagent.",
                "Remettre les morceaux de poulet dans la marmite et ajouter le bouillon de poulet.",
                "Nouer les feuilles de pandanus et de citronnier (si utilisées) et les ajouter au mélange.",
                "Couvrir et laisser mijoter à feu doux pendant 20 minutes.",
                "Ajouter les cubes de taro et le lait de coco, remuer délicatement.",
                "Continuer la cuisson à découvert pendant environ 20-25 minutes supplémentaires, jusqu'à ce que le poulet soit bien cuit et que le taro soit tendre.",
                "Ajouter les tomates coupées en quartiers dans les 5 dernières minutes de cuisson.",
                "Goûter et ajuster l'assaisonnement avec du sel et du poivre si nécessaire.",
                "Retirer les feuilles de pandanus et de citronnier avant de servir.",
                "Arroser de jus de citron vert frais et parsemer de coriandre ciselée."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc ou du rourou (feuilles de taro cuites). Pour préparer le rourou: laver et hacher 500g de jeunes feuilles de taro (ou à défaut, des épinards). Faire revenir 1 oignon émincé dans 2 cuillères à soupe d'huile de coco. Ajouter les feuilles hachées et faire revenir 2 minutes. Verser 200ml de lait de coco, saler, poivrer et laisser mijoter 15-20 minutes à couvert jusqu'à ce que les feuilles soient tendres.",
            "shortStory": "Le **Poulet Vakalolo** est un plat traditionnel fidjien qui reflète parfaitement l'essence de la cuisine du Pacifique Sud. Le terme 'vakalolo' signifie littéralement 'avec du lait de coco', ingrédient fondamental dans la cuisine des îles. Ce ragoût généreux met en valeur le **taro**, tubercule sacré dans la culture fidjienne, symbole d'abondance et de prospérité. Dans les villages fidjiens, ce plat est souvent préparé pour les occasions spéciales et les cérémonies traditionnelles comme le 'sevusevu', rituel d'accueil. Les **feuilles de pandanus** et de citronnier ajoutent des arômes distinctifs qui transportent immédiatement aux paysages luxuriants des îles du Pacifique. Historiquement, ce type de plat était préparé dans des marmites en terre cuite placées sur des pierres chaudes ou dans un four traditionnel 'lovo'. Cette recette incarne la simplicité et la générosité de la cuisine fidjienne, où les ingrédients frais et locaux sont mis à l'honneur dans des plats réconfortants partagés en communauté.",
            "detailURL": "/fiche-recette/148/poulet-coco-taro/poulet-vakalolo.html",
            "time_prepare": "20mn",
            "time_maceration": "0h",
            "time_cooking": "65mn",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Cette recette est d'un coût moyen à élevé (niveau 3) en raison principalement du taro frais et des feuilles de pandanus qui peuvent être difficiles à trouver. La patate douce est une alternative plus accessible au taro."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette est modérément épicée, mais l'intensité peut être facilement ajustée selon les préférences en modifiant la quantité de piments."
            },
            "calories": "460 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne, nécessitant principalement une attention à la cuisson du taro qui doit être tendre mais pas trop mou."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche-a-decouper"
            ]
        }, {
            "id": 149,
            "categorie": "amerique",
            "country": "Haïti",
            "flag": "HT",
            "title": "Poulet Créole",
            "subTitle": "Poulet mariné aux agrumes et épices créoles, mijoté avec poivrons et tomates",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux (ou 6 cuisses/pilons)",
                "3 citrons verts, jus et zeste",
                "1 orange, jus et zeste",
                "4 gousses d'ail, émincées",
                "2 oignons moyens, émincés",
                "2 poivrons (1 rouge, 1 vert), épépinés et coupés en lanières",
                "3 tomates mûres, concassées",
                "2 cuillères à soupe de pâte de tomate",
                "1 cuillère à soupe de thym frais (ou 1 cuillère à café séché)",
                "3 clous de girofle entiers",
                "1 cuillère à café de piment de Cayenne (ajuster selon le goût)",
                "3 cuillères à soupe d'huile végétale",
                "1 cuillère à soupe de vinaigre de cidre",
                "1 bouquet de persil frais, ciselé",
                "1 cuillère à café de paprika fumé",
                "2 cuillères à soupe d'épices créoles (mélange de paprika, thym, origan, ail en poudre, oignon en poudre, poivre de Cayenne)",
                "Sel et poivre noir"
            ],
            "ingredient_liste": [
                "poulet",
                "citron-vert",
                "orange",
                "ail",
                "oignon",
                "poivron",
                "tomate",
                "pate-tomate",
                "thym",
                "clou-girofle",
                "piment-cayenne",
                "huile-vegetale",
                "vinaigre-cidre",
                "persil",
                "paprika-fume",
                "epices-creoles",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Laver le poulet avec de l'eau et du jus de citron vert.",
                "Dans un grand bol, préparer la marinade en mélangeant le jus et le zeste des citrons verts et de l'orange, l'ail émincé, 1 oignon émincé, le thym, les épices créoles, le paprika fumé, le piment de Cayenne, le sel et le poivre.",
                "Ajouter les morceaux de poulet, bien les enrober de marinade, couvrir et réfrigérer pendant au moins 4 heures (idéalement toute une nuit).",
                "Sortir le poulet de la marinade et réserver cette dernière."
            ],
            "cuisson": [
                "Dans une grande cocotte, chauffer l'huile à feu moyen-vif.",
                "Faire dorer les morceaux de poulet sur tous les côtés pendant environ 5 minutes, puis réserver.",
                "Dans la même cocotte, faire revenir l'oignon restant jusqu'à ce qu'il soit translucide.",
                "Ajouter les poivrons et faire sauter pendant 3-4 minutes.",
                "Incorporer les tomates concassées, la pâte de tomate, la marinade réservée, les clous de girofle et le vinaigre de cidre.",
                "Remettre les morceaux de poulet dans la cocotte, couvrir et laisser mijoter à feu doux pendant 30-35 minutes jusqu'à ce que le poulet soit bien cuit et tendre.",
                "Si la sauce est trop liquide, retirer le couvercle pendant les 10 dernières minutes pour la faire réduire.",
                "Goûter et ajuster l'assaisonnement si nécessaire.",
                "Retirer les clous de girofle avant de servir.",
                "Parsemer de persil frais ciselé."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": true,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte",
                "poele"
            ],
            "accompagnement": "Servir avec du riz blanc et des haricots rouges. Pour les haricots rouges à l'haïtienne: faire tremper 250g de haricots rouges toute une nuit. Égoutter et rincer. Dans une marmite, faire revenir 1 oignon émincé, 2 gousses d'ail hachées et 1 poivron vert en dés dans 2 cuillères à soupe d'huile. Ajouter les haricots, 1 litre d'eau, 1 cuillère à café de thym séché, 2 clous de girofle, 1 feuille de laurier et 1 cuillère à café de sel. Porter à ébullition, puis réduire le feu et laisser mijoter couvert environ 1h30 à 2h jusqu'à ce que les haricots soient tendres. Écraser légèrement quelques haricots contre les parois de la marmite pour épaissir la sauce.",
            "shortStory": "Le **Poulet Créole** est l'un des plats emblématiques de la cuisine haïtienne, reflet du métissage culturel de cette île des Caraïbes. Cette recette puise ses racines dans l'héritage africain, français et autochtone qui forme la riche mosaïque culinaire d'Haïti. Les **agrumes** utilisés dans la marinade sont caractéristiques de la cuisine haïtienne, où l'on utilise fréquemment le citron vert pour attendrir et parfumer les viandes. Les **épices créoles**, mélange savoureux développé au fil des siècles, témoignent de l'histoire complexe de l'île, première république noire indépendante des Amériques. Ce plat fait partie des **diri ak sos** (riz avec sauce) que l'on retrouve sur les tables haïtiennes lors des célébrations familiales et des fêtes nationales comme l'Indépendance (1er janvier) ou le Carnaval. Dans les campagnes haïtiennes, il est traditionnellement cuit dans des marmites en fonte sur des feux de bois, ce qui lui confère une saveur fumée inimitable.",
            "detailURL": "/fiche-recette/149/poulet-agrumes-epices-creoles/poulet-creole.html",
            "time_prepare": "25mn",
            "time_maceration": "4h",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2), avec comme ingrédients plus onéreux principalement les agrumes frais et les épices variées, mais qui peuvent être remplacés par des alternatives plus accessibles."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Cette recette est moyennement épicée en raison du piment de Cayenne, mais l'intensité peut être facilement ajustée selon les préférences."
            },
            "calories": "420 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne, nécessitant principalement une attention à la marinade et au temps de cuisson du poulet."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche-a-decouper"
            ]
        }, {
            "id": 150,
            "categorie": "amerique",
            "country": "Haïti",
            "flag": "HT",
            "title": "Poulet Griyo",
            "subTitle": "Poulet mariné aux agrumes et épices, puis frit jusqu'à obtenir une peau croustillante",
            "chicken_piece": [
                "cuisse",
                "pilon",
                "aile"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": true,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "8 morceaux de poulet (cuisses, pilons ou ailes)",
                "4 citrons verts, jus et zeste",
                "1 orange amère (ou orange normale), jus et zeste",
                "6 gousses d'ail, écrasées",
                "1 oignon moyen, haché finement",
                "3 échalotes, hachées finement",
                "2 cuillères à soupe de thym frais haché",
                "3 cuillères à soupe de persil frais haché",
                "2 cuillères à café de piment Scotch Bonnet haché (ou piment habanero)",
                "1 cuillère à café de poivre noir fraîchement moulu",
                "1 cuillère à café de paprika",
                "1/2 cuillère à café de muscade râpée",
                "1 cuillère à café de sel",
                "2 cuillères à soupe de vinaigre blanc",
                "2 cuillères à soupe d'huile végétale",
                "Huile pour friture",
                "1 bouquet de ciboulette fraîche, ciselée pour la garniture"
            ],
            "ingredient_liste": [
                "poulet",
                "citron-vert",
                "orange",
                "ail",
                "oignon",
                "echalote",
                "thym",
                "persil",
                "piment-scotch-bonnet",
                "poivre",
                "paprika",
                "muscade",
                "sel",
                "vinaigre-blanc",
                "huile-vegetale",
                "huile-friture",
                "ciboulette"
            ],
            "preparation": [
                "Préparer la marinade : dans un grand bol, mélanger le jus et le zeste des citrons verts et de l'orange, l'ail écrasé, l'oignon et les échalotes hachés, le thym, le persil, le piment haché, le poivre noir, le paprika, la muscade, le sel, le vinaigre blanc et l'huile végétale.",
                "Nettoyer les morceaux de poulet avec de l'eau et du jus de citron vert, puis les éponger avec du papier absorbant.",
                "Placer les morceaux de poulet dans un grand sac de congélation ou un plat profond, verser la marinade dessus et bien masser pour que tous les morceaux soient enrobés.",
                "Fermer le sac ou couvrir le plat et réfrigérer pendant au moins 6 heures, idéalement toute une nuit.",
                "Sortir le poulet du réfrigérateur environ 30 minutes avant la cuisson."
            ],
            "cuisson": [
                "Retirer les morceaux de poulet de la marinade et les éponger délicatement avec du papier absorbant (ne pas les rincer).",
                "Dans une grande casserole ou une marmite profonde, chauffer l'huile de friture à 175°C.",
                "Pendant ce temps, dans une poêle séparée, faire chauffer 2 cuillères à soupe d'huile végétale à feu moyen-vif.",
                "Faire dorer les morceaux de poulet dans la poêle pendant environ 3-4 minutes de chaque côté pour sceller les jus.",
                "Transférer les morceaux de poulet dans l'huile chaude et les faire frire pendant environ 8-10 minutes, jusqu'à ce qu'ils soient dorés et croustillants. Ne pas surcharger la friteuse, procéder par lots si nécessaire.",
                "Retirer le poulet à l'aide d'une écumoire et le déposer sur du papier absorbant pour égoutter l'excès d'huile.",
                "Pour une cuisson alternative au four: préchauffer le four à 200°C. Après avoir fait dorer le poulet dans la poêle, le disposer sur une plaque de cuisson et enfourner pendant 25-30 minutes, jusqu'à ce qu'il soit bien cuit et que la peau soit croustillante.",
                "Servir chaud, garni de ciboulette fraîche ciselée."
            ],
            "cooking_methods": {
                "best": "friture",
                "grill": false,
                "plancha": false,
                "friture": true,
                "barbecue": false,
                "four": true,
                "poele": true,
                "cocotte": false
            },
            "mode_cuisson": [
                "friture",
                "poele",
                "four"
            ],
            "accompagnement": "Servir avec du riz collé et du pikliz (condiment épicé haïtien). Pour le pikliz: dans un bocal, mélanger 2 tasses de chou blanc râpé, 1 carotte râpée, 1 oignon émincé finement, 1 poivron rouge en julienne, 2 piments Scotch Bonnet émincés (avec les graines pour plus de piquant), 4 clous de girofle, 1 cuillère à café de sel, le jus de 2 citrons verts et 1 tasse de vinaigre blanc. Fermer hermétiquement et réfrigérer au moins 24h avant de servir (se conserve plusieurs semaines au réfrigérateur).",
            "shortStory": "Le **Poulet Griyo** (ou griot) est un plat emblématique de la street food haïtienne, véritable fierté nationale que l'on retrouve des marchés populaires aux tables des restaurants les plus raffinés. Cette méthode de cuisson, qui consiste à faire mariner la viande puis à la frire jusqu'à obtenir une texture à la fois croustillante et tendre, est un héritage des techniques culinaires africaines apportées par les esclaves. Le **citrus** (mélange d'agrumes) utilisé dans la marinade est caractéristique de la cuisine caribéenne. Le **piment Scotch Bonnet**, variété cultivée dans toutes les Caraïbes, apporte une chaleur distinctive à ce plat. La préparation du griyo est souvent au cœur des festivités populaires comme le Rara (carnaval rural) ou les célébrations du Jour du Drapeau (18 mai). Traditionnellement servi avec du pikliz, condiment épicé à base de chou fermenté, ce plat représente l'âme de la cuisine haïtienne, à la fois simple dans ses ingrédients et complexe dans ses saveurs.",
            "detailURL": "/fiche-recette/150/poulet-frit-epice-citron/poulet-griyo.html",
            "time_prepare": "30mn",
            "time_maceration": "6h",
            "time_cooking": "25mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2), avec comme ingrédients plus onéreux principalement les agrumes frais et les piments scotch bonnet qui peuvent être remplacés par des alternatives plus accessibles."
            },
            "images": [],
            "hot": {
                "level": 4,
                "description": "Cette recette est significativement épicée en raison du piment Scotch Bonnet, mais l'intensité peut être ajustée en réduisant la quantité de piment ou en retirant les graines."
            },
            "calories": "460 kcal",
            "allergens": [],
            "difficulty": {
                "level": 3,
                "description": "Recette de difficulté moyenne à élevée, nécessitant une technique de friture précise pour obtenir un poulet croustillant à l'extérieur et juteux à l'intérieur."
            },
            "equipment_needed": [
                "friteuse",
                "poele",
                "thermometre-cuisine",
                "papier-absorbant"
            ]
        }, {
            "id": 151,
            "categorie": "amerique",
            "country": "Honduras",
            "flag": "HN",
            "title": "Poulet Tapado",
            "subTitle": "Poulet mijoté dans une sauce à la noix de coco avec fruits de mer et bananes plantain",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux (ou 6 cuisses/pilons)",
                "200g de crevettes décortiquées",
                "200g de poisson blanc (comme le tilapia), coupé en morceaux",
                "400ml de lait de coco",
                "2 bananes plantain mûres, coupées en rondelles épaisses",
                "2 oignons moyens, émincés",
                "3 gousses d'ail, émincées",
                "2 poivrons (1 rouge, 1 vert), épépinés et coupés en dés",
                "2 tomates mûres, concassées",
                "1 piment jalapeño, épépiné et émincé (facultatif)",
                "2 cuillères à soupe de coriandre fraîche, ciselée",
                "1 cuillère à soupe de pâte de tomate",
                "2 cuillères à café de cumin moulu",
                "1 cuillère à café d'origan séché",
                "1 cuillère à café de paprika",
                "2 feuilles de laurier",
                "3 cuillères à soupe d'huile végétale",
                "500ml de bouillon de poulet",
                "Sel et poivre noir",
                "Jus d'un citron vert"
            ],
            "ingredient_liste": [
                "poulet",
                "crevette",
                "poisson-blanc",
                "lait-coco",
                "banane-plantain",
                "oignon",
                "ail",
                "poivron",
                "tomate",
                "piment-jalapeno",
                "coriandre",
                "pate-tomate",
                "cumin",
                "origan",
                "paprika",
                "laurier",
                "huile-vegetale",
                "bouillon-poulet",
                "sel",
                "poivre",
                "citron-vert"
            ],
            "preparation": [
                "Assaisonner les morceaux de poulet avec du sel, du poivre, la moitié du cumin et du paprika.",
                "Peler les bananes plantain et les couper en rondelles de 2 cm d'épaisseur.",
                "Émincer les oignons, l'ail, les poivrons et le jalapeño.",
                "Nettoyer les crevettes et couper le poisson en morceaux de taille égale."
            ],
            "cuisson": [
                "Dans une grande cocotte ou marmite, chauffer l'huile à feu moyen-vif.",
                "Faire dorer les morceaux de poulet sur tous les côtés pendant environ 5 minutes, puis les réserver.",
                "Dans la même cocotte, faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter l'ail, les poivrons et le jalapeño, faire revenir pendant 2-3 minutes.",
                "Incorporer la pâte de tomate, les tomates concassées, le reste du cumin, l'origan et le paprika, cuire pendant 2 minutes en remuant.",
                "Verser le bouillon de poulet, ajouter les feuilles de laurier et remettre les morceaux de poulet dans la cocotte.",
                "Couvrir et laisser mijoter à feu doux pendant 20-25 minutes jusqu'à ce que le poulet soit presque cuit.",
                "Ajouter les rondelles de banane plantain et continuer la cuisson pendant 10 minutes.",
                "Verser le lait de coco, ajouter les morceaux de poisson et les crevettes, poursuivre la cuisson à feu doux pendant 5-7 minutes supplémentaires, jusqu'à ce que les fruits de mer soient cuits.",
                "Goûter et ajuster l'assaisonnement avec du sel et du poivre.",
                "Retirer du feu, arroser de jus de citron vert et parsemer de coriandre ciselée."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc et des tortillas de maïs chaudes. Pour les tortillas maison: dans un saladier, mélanger 300g de masa harina (farine de maïs nixtamalisé) avec une pincée de sel. Ajouter progressivement 300ml d'eau tiède en pétrissant jusqu'à obtenir une pâte souple et non collante. Couvrir et laisser reposer 30 minutes. Former des boules de la taille d'une balle de golf, les aplatir entre deux feuilles de plastique avec un rouleau ou une presse à tortillas. Cuire chaque tortilla sur une poêle chaude et sèche environ 1 minute de chaque côté. Garder au chaud dans un torchon propre.",
            "shortStory": "Le **Poulet Tapado** est un plat emblématique de la côte nord du Honduras, particulièrement populaire dans les communautés garifunas, descendants d'Africains et d'indigènes caraïbes. Ce ragoût riche, également appelé **'Tapou'** ou **'Tapao'**, illustre parfaitement la fusion des influences africaines, indigènes et européennes qui caractérisent la cuisine hondurienne côtière. Le lait de coco, ingrédient essentiel, témoigne de l'héritage africain, tandis que l'association des fruits de mer et du poulet reflète l'abondance des ressources naturelles de la côte caraïbe. Les **bananes plantain**, cultivées abondamment au Honduras, ajoutent une douceur et une texture caractéristiques qui équilibrent les saveurs de la mer. Traditionnellement préparé lors des celebrations garifunas comme le 'Yurumein' (arrivée des ancêtres), ce plat est souvent accompagné de musique punta et de danses traditionnelles. Sa préparation, qui réunit divers ingrédients dans une même marmite, symbolise l'harmonie et le métissage culturel qui définissent l'identité hondurienne.",
            "detailURL": "/fiche-recette/151/poulet-fruits-mer-coco-plantain/poulet-tapado.html",
            "time_prepare": "30mn",
            "time_maceration": "0h",
            "time_cooking": "55mn",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Cette recette est d'un coût moyen à élevé (niveau 3) en raison principalement des fruits de mer qui peuvent être onéreux. Une version plus économique peut être réalisée en augmentant la quantité de poulet et en réduisant ou omettant les fruits de mer."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette est légèrement épicée grâce au piment jalapeño, mais l'intensité peut être facilement ajustée selon les préférences ou omise pour une version plus douce."
            },
            "calories": "490 kcal",
            "allergens": [
                "crustaces",
                "poisson"
            ],
            "difficulty": {
                "level": 3,
                "description": "Recette de difficulté moyenne à élevée, nécessitant une attention particulière aux temps de cuisson des différents ingrédients pour éviter que les fruits de mer ne deviennent caoutchouteux."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche-a-decouper"
            ]
        }, {
            "id": 152,
            "categorie": "amerique",
            "country": "Honduras",
            "flag": "HN",
            "title": "Poulet Chuco",
            "subTitle": "Poulet grillé mariné aux agrumes et épices honduriennes",
            "chicken_piece": [
                "entier",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux (ou 4 cuisses et 2 blancs)",
                "4 oranges amères (ou oranges normales), jus et zeste",
                "3 citrons verts, jus et zeste",
                "6 gousses d'ail, émincées",
                "2 oignons rouges, émincés",
                "2 cuillères à soupe d'origan frais (ou 2 cuillères à café séché)",
                "1 cuillère à soupe de cumin moulu",
                "1 cuillère à soupe de graines de coriandre, écrasées",
                "2 cuillères à café d'achiote (annatto) en poudre",
                "2 cuillères à soupe de sauce Worcestershire",
                "1 cuillère à café de poivre noir moulu",
                "1 cuillère à café de cannelle moulue",
                "1/2 cuillère à café de muscade râpée",
                "2 piments habanero, épépinés et finement hachés",
                "3 cuillères à soupe d'huile d'olive",
                "1 bouquet de coriandre fraîche, ciselée",
                "Sel"
            ],
            "ingredient_liste": [
                "poulet",
                "orange-amere",
                "citron-vert",
                "ail",
                "oignon-rouge",
                "origan",
                "cumin",
                "graine-coriandre",
                "achiote",
                "sauce-worcestershire",
                "poivre",
                "cannelle",
                "muscade",
                "piment-habanero",
                "huile-olive",
                "coriandre",
                "sel"
            ],
            "preparation": [
                "Dans un grand bol, mélanger le jus et le zeste des oranges et des citrons verts.",
                "Ajouter l'ail et les oignons émincés, l'origan, le cumin, les graines de coriandre écrasées, l'achiote, la sauce Worcestershire, le poivre, la cannelle, la muscade, les piments habanero hachés, l'huile d'olive et 1 cuillère à café de sel.",
                "Placer les morceaux de poulet dans un grand sac de congélation ou un plat profond, verser la marinade dessus et bien masser pour enrober tous les morceaux.",
                "Fermer le sac ou couvrir le plat et réfrigérer pendant au moins 6 heures, idéalement toute une nuit, en retournant occasionnellement.",
                "Sortir le poulet du réfrigérateur environ 30 minutes avant la cuisson pour qu'il revienne à température ambiante."
            ],
            "cuisson": [
                "Préchauffer le grill ou le barbecue à feu moyen-vif (environ 180-200°C).",
                "Retirer les morceaux de poulet de la marinade, en conservant celle-ci pour le badigeonnage.",
                "Huiler légèrement la grille pour éviter que le poulet n'y adhère.",
                "Placer les morceaux de poulet sur la grille, côté peau vers le bas.",
                "Griller le poulet en le retournant toutes les 10-15 minutes et en le badigeonnant régulièrement avec la marinade réservée.",
                "Pour les cuisses, compter environ 35-40 minutes de cuisson totale, pour les blancs environ 25-30 minutes.",
                "Le poulet est cuit lorsque le jus qui s'en écoule est clair et que la température interne atteint 75°C.",
                "Pour une cuisson au four: préchauffer le four à 190°C, disposer les morceaux de poulet sur une plaque de cuisson et enfourner pendant 35-45 minutes (selon les morceaux), en les retournant et en les badigeonnant à mi-cuisson.",
                "Laisser reposer le poulet 5-10 minutes avant de servir.",
                "Parsemer de coriandre fraîche ciselée."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "barbecue",
                "grill",
                "four"
            ],
            "accompagnement": "Servir avec des tortillas de maïs chaudes, du chimol (sauce tomate hondurienne) et des frijoles refritos (haricots frits). Pour le chimol: mélanger 3 tomates coupées en dés, 1 oignon rouge haché finement, 1/2 poivron vert haché, 1 petit piment jalapeño épépiné et haché, le jus d'un citron vert, 2 cuillères à soupe de coriandre ciselée, 1 cuillère à café d'origan séché, 2 cuillères à soupe d'huile d'olive, sel et poivre. Laisser reposer au réfrigérateur au moins 30 minutes avant de servir pour que les saveurs se développent.",
            "shortStory": "Le **Poulet Chuco** tire son nom de l'argot hondurien où 'chuco' signifie 'délicieux' ou 'savoureux'. Cette recette est particulièrement populaire dans la région centrale du Honduras, où les fermes avicoles sont nombreuses. La marinade aux **agrumes** est caractéristique de la cuisine d'Amérique centrale, l'acidité attendrissant naturellement la viande tout en lui conférant une saveur vive et fraîche. L'**achiote** (annatto), épice précolombienne issue des graines d'un arbuste tropical, apporte une couleur orangée distinctive et un arôme légèrement poivré et terreux au plat. Ce type de préparation grillée est souvent associé aux festivités comme la Semana Santa (Semaine Sainte) ou les fiestas patronales des villages honduriens. Traditionnellement, le poulet est cuit sur un **pollo asado**, gril improvisé fait de briques et de grilles métalliques, ce qui lui confère une saveur fumée caractéristique. Le plat est emblématique de la cuisine de rue hondurienne, vendu par des marchands ambulants dans les places des villages lors des jours de marché.",
            "detailURL": "/fiche-recette/152/poulet-grille-achiote-agrumes/poulet-chuco.html",
            "time_prepare": "25mn",
            "time_maceration": "6h",
            "time_cooking": "40mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2), avec comme ingrédient plus onéreux principalement l'achiote qui peut être difficile à trouver dans certaines régions mais qui peut être remplacé par un mélange de paprika et de curcuma."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Cette recette est moyennement épicée en raison des piments habanero, mais l'intensité peut être facilement ajustée selon les préférences."
            },
            "calories": "430 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne, nécessitant principalement une attention au temps de cuisson sur le grill pour éviter que le poulet ne dessèche ou ne brûle à cause des sucres présents dans la marinade."
            },
            "equipment_needed": [
                "barbecue",
                "pinceau-cuisine",
                "thermometre-cuisine"
            ]
        },


        {
            "id": 153,
            "categorie": "europe",
            "country": "Hongrie",
            "flag": "HU",
            "title": "Poulet Paprikás",
            "subTitle": "Poulet mijoté dans une sauce crémeuse au paprika hongrois",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier d'environ 1,5 kg, coupé en morceaux (ou 6 cuisses/pilons)",
                "3 cuillères à soupe de paprika hongrois doux (édesnemes)",
                "1 cuillère à café de paprika hongrois fort (csípős) (facultatif)",
                "3 gros oignons, finement émincés",
                "3 gousses d'ail, émincées",
                "2 poivrons rouges, épépinés et coupés en lanières",
                "2 tomates mûres, pelées et concassées",
                "250ml de bouillon de poulet",
                "200ml de crème fraîche épaisse (tejföl)",
                "3 cuillères à soupe de saindoux (ou huile végétale)",
                "2 cuillères à soupe de farine",
                "1 cuillère à café de carvi moulu (optionnel)",
                "1 bouquet de persil frais, ciselé",
                "Sel et poivre noir",
                "Quelques gouttes de jus de citron"
            ],
            "ingredient_liste": [
                "poulet",
                "paprika-doux",
                "paprika-fort",
                "oignon",
                "ail",
                "poivron-rouge",
                "tomate",
                "bouillon-poulet",
                "creme-fraiche",
                "saindoux",
                "farine",
                "carvi",
                "persil",
                "sel",
                "poivre",
                "citron"
            ],
            "preparation": [
                "Assaisonner les morceaux de poulet avec du sel et du poivre des deux côtés.",
                "Émincer finement les oignons et l'ail, couper les poivrons en lanières.",
                "Si vous utilisez des tomates fraîches, les peler en les plongeant 30 secondes dans de l'eau bouillante, puis les concasser."
            ],
            "cuisson": [
                "Dans une grande cocotte, faire fondre le saindoux (ou chauffer l'huile) à feu moyen.",
                "Faire dorer les morceaux de poulet sur tous les côtés pendant environ 5 minutes, puis les réserver.",
                "Dans la même cocotte, faire revenir les oignons émincés jusqu'à ce qu'ils soient translucides, environ 8-10 minutes.",
                "Ajouter l'ail et cuire pendant 1 minute supplémentaire.",
                "Retirer la cocotte du feu et ajouter le paprika (doux et fort), remuer immédiatement pour éviter que le paprika ne brûle.",
                "Remettre la cocotte sur feu doux et ajouter les poivrons, les tomates concassées et le carvi si utilisé.",
                "Remettre les morceaux de poulet dans la cocotte, verser le bouillon de poulet et porter doucement à ébullition.",
                "Réduire le feu au minimum, couvrir et laisser mijoter pendant environ 35-40 minutes, jusqu'à ce que le poulet soit tendre et se détache facilement de l'os.",
                "Retirer les morceaux de poulet et les réserver au chaud.",
                "Dans un bol, mélanger la crème fraîche avec la farine jusqu'à obtenir une consistance lisse.",
                "Incorporer progressivement quelques cuillères de sauce chaude dans le mélange de crème pour le tempérer, puis verser le tout dans la cocotte en remuant constamment.",
                "Laisser épaissir la sauce à feu doux pendant 5 minutes, en remuant régulièrement.",
                "Goûter et ajuster l'assaisonnement avec du sel, du poivre et quelques gouttes de jus de citron si nécessaire.",
                "Remettre les morceaux de poulet dans la sauce pour les réchauffer.",
                "Parsemer de persil frais ciselé avant de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec des nouilles hongroises (nokedli). Pour les nokedli: mélanger 300g de farine, 3 œufs, 1/2 cuillère à café de sel et 100ml d'eau pour former une pâte pas trop liquide. Laisser reposer 15 minutes. Porter à ébullition une grande casserole d'eau salée. À l'aide d'une planche à découper humide et d'un couteau, découper de petits morceaux de pâte directement dans l'eau bouillante (ou utiliser une râpe à spätzle). Quand les nouilles remontent à la surface (après 2-3 minutes), les égoutter. Les mélanger avec un peu de beurre fondu pour qu'elles ne collent pas.",
            "shortStory": "Le **Poulet Paprikás** (Paprikás Csirke) est l'un des plats les plus emblématiques de la cuisine hongroise, célèbre pour son utilisation généreuse du **paprika**, l'épice nationale introduite en Hongrie par les Turcs au XVIe siècle. Cette poudre rouge, obtenue à partir de poivrons séchés puis moulus, existe en plusieurs variétés, de la plus douce à la plus forte, et confère au plat sa couleur éclatante et son arôme caractéristique. La **crème fraîche épaisse** (tejföl), autre ingrédient phare de la cuisine hongroise, apporte onctuosité et équilibre aux notes épicées. Traditionnellement préparé dans les campagnes hongroises de la Grande Plaine (Alföld), ce plat était autrefois cuisiné dans des chaudrons en cuivre suspendus au-dessus d'un feu de bois par les bergers (gulyás). Aujourd'hui, il reste un mets de choix lors des repas familiaux du dimanche et des célébrations comme le jour de Saint-Étienne (20 août), fête nationale hongroise, servi accompagné de nouilles fraîches (nokedli) et de concombres en saumure.",
            "detailURL": "/fiche-recette/153/poulet-paprika-creme/poulet-paprikas.html",
            "time_prepare": "20mn",
            "time_maceration": "0h",
            "time_cooking": "60mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2), avec comme ingrédient plus onéreux le paprika hongrois authentique, qui peut être remplacé par du paprika ordinaire si nécessaire."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette peut être légèrement épicée selon la quantité de paprika fort utilisée, mais reste généralement douce et savoureuse plutôt que piquante."
            },
            "calories": "470 kcal",
            "allergens": [
                "produits-laitiers"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne, nécessitant principalement une attention à l'ajout de la crème fraîche pour éviter qu'elle ne caille."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche-a-decouper"
            ]
        },
        {
            "id": 154,
            "categorie": "europe",
            "country": "Hongrie",
            "flag": "HU",
            "title": "Poulet Töltött Csirke",
            "subTitle": "Poulet farci aux champignons, lardons et paprika, cuit au four",
            "chicken_piece": [
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "1 poulet entier d'environ 1,5 kg",
                "150g de champignons de Paris, finement hachés",
                "100g de lardons fumés",
                "1 oignon moyen, finement haché",
                "2 gousses d'ail, émincées",
                "3 tranches de pain de mie, émiettées",
                "100ml de bouillon de poulet",
                "2 œufs, battus",
                "2 cuillères à soupe de paprika hongrois doux",
                "1 cuillère à café de marjolaine séchée",
                "1 cuillère à café de carvi moulu",
                "1 bouquet de persil frais, ciselé",
                "50g de beurre",
                "3 cuillères à soupe d'huile végétale",
                "1 citron, coupé en quartiers",
                "Sel et poivre noir",
                "1 cuillère à café de paprika fumé"
            ],
            "ingredient_liste": [
                "poulet",
                "champignon",
                "lardon",
                "oignon",
                "ail",
                "pain-de-mie",
                "bouillon-poulet",
                "oeuf",
                "paprika-doux",
                "marjolaine",
                "carvi",
                "persil",
                "beurre",
                "huile-vegetale",
                "citron",
                "sel",
                "poivre",
                "paprika-fume"
            ],
            "preparation": [
                "Préchauffer le four à 180°C.",
                "Nettoyer le poulet, retirer les abats s'il y en a, et bien l'éponger avec du papier absorbant.",
                "Frotter l'intérieur et l'extérieur du poulet avec du sel, du poivre et 1 cuillère à soupe de paprika doux.",
                "Pour la farce, faire fondre 30g de beurre dans une poêle et y faire revenir les lardons jusqu'à ce qu'ils soient légèrement dorés.",
                "Ajouter l'oignon haché et faire revenir jusqu'à ce qu'il soit translucide.",
                "Incorporer les champignons hachés et l'ail, cuire jusqu'à ce que les champignons aient rendu leur eau et qu'elle se soit évaporée.",
                "Hors du feu, ajouter le pain émietté, le persil ciselé, la marjolaine, le carvi, le reste du paprika doux, le paprika fumé, du sel et du poivre.",
                "Verser le bouillon chaud sur le mélange et laisser refroidir légèrement.",
                "Incorporer les œufs battus à la farce."
            ],
            "cuisson": [
                "Farcir la cavité du poulet avec le mélange préparé, sans trop tasser pour que la farce reste aérée.",
                "Fermer l'ouverture avec des cure-dents ou de la ficelle de cuisine.",
                "Placer le poulet dans un plat allant au four, poitrine vers le haut.",
                "Dans un petit bol, mélanger le reste du beurre fondu avec l'huile végétale.",
                "Badigeonner généreusement le poulet avec ce mélange.",
                "Enfourner et cuire pendant environ 1h15-1h30, en arrosant le poulet toutes les 20 minutes avec le jus de cuisson.",
                "Pour vérifier la cuisson, piquer la cuisse avec la pointe d'un couteau: le jus qui s'écoule doit être clair et non rosé.",
                "Une fois cuit, sortir le poulet du four et le laisser reposer pendant 15 minutes, couvert de papier aluminium.",
                "Retirer les cure-dents ou la ficelle avant de servir.",
                "Pour une présentation traditionnelle, disposer les quartiers de citron autour du poulet et saupoudrer d'un peu de paprika pour la couleur."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec des pommes de terre rôties à la hongroise et des légumes de saison. Pour les pommes de terre: peler et couper 800g de pommes de terre en quartiers. Les placer dans un plat allant au four, arroser de 4 cuillères à soupe d'huile, saupoudrer de 2 cuillères à café de paprika, 1 cuillère à café de carvi, du sel et du poivre. Mélanger pour bien enrober. Enfourner à 180°C pendant environ 45 minutes, en remuant de temps en temps, jusqu'à ce qu'elles soient dorées et croustillantes.",
            "shortStory": "Le **Töltött Csirke** (poulet farci) est un plat festif hongrois traditionnellement préparé pour les réunions familiales et les occasions spéciales comme Noël ou Pâques. Cette recette met à l'honneur le **paprika**, épice emblématique de la Hongrie, introduite dans le pays au XVIe siècle et devenue depuis un symbole de l'identité culinaire nationale. La farce aux champignons et lardons, enrichie de **carvi** (köménymag) et de **marjolaine** (majoránna), reflète l'influence austro-allemande sur la cuisine hongroise, héritage de l'époque de l'Empire austro-hongrois. Historiquement, ce type de plat était réservé aux jours de fête dans les familles paysannes hongroises, qui élevaient leurs propres volailles. La tradition veut que ce soit la maîtresse de maison qui prépare cette recette, transmise de mère en fille. Dans certaines régions comme la Transdanubie, on ajoute parfois du foie de volaille à la farce pour plus de richesse. Ce plat convivial est généralement servi au centre de la table, découpé devant les convives, symbolisant l'abondance et la générosité.",
            "detailURL": "/fiche-recette/154/poulet-farci-champignons-paprika/poulet-toltott-csirke.html",
            "time_prepare": "35mn",
            "time_maceration": "0h",
            "time_cooking": "90mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2), avec comme ingrédients plus onéreux le paprika hongrois authentique et les lardons de qualité."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette n'est pas épicée malgré l'utilisation du paprika, qui apporte surtout de la couleur et une saveur douce et fumée."
            },
            "calories": "510 kcal",
            "allergens": [
                "oeuf",
                "gluten",
                "produits-laitiers"
            ],
            "difficulty": {
                "level": 3,
                "description": "Recette de difficulté moyenne à élevée, nécessitant une certaine technique pour farcir correctement le poulet et gérer le temps de cuisson pour qu'il reste juteux."
            },
            "equipment_needed": [
                "four",
                "plat-four",
                "ficelle-cuisine",
                "cure-dent",
                "pinceau-cuisine"
            ]
        },
        {
            "id": 155,
            "categorie": "asie",
            "country": "Hong Kong",
            "flag": "HK",
            "title": "Poulet Siu Mei",
            "subTitle": "Poulet rôti laqué au miel et aux cinq épices chinoises",
            "chicken_piece": [
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "1 poulet entier d'environ 1,5 kg",
                "4 cuillères à soupe de sauce soja claire",
                "2 cuillères à soupe de sauce soja foncée",
                "2 cuillères à soupe de vin de Shaoxing (ou xérès sec)",
                "3 cuillères à soupe de miel",
                "2 cuillères à soupe de sucre roux",
                "1 cuillère à soupe d'huile de sésame",
                "1 cuillère à café de cinq-épices chinoises",
                "1 cuillère à café de poudre d'ail",
                "1 cuillère à café de poudre de gingembre",
                "1 étoile de badiane (anis étoilé)",
                "4 gousses d'ail, écrasées",
                "3 tranches de gingembre frais",
                "2 oignons verts (ciboule), coupés en tronçons",
                "1 cuillère à café de sel",
                "1/2 cuillère à café de poivre blanc moulu",
                "2 cuillères à soupe d'huile végétale"
            ],
            "ingredient_liste": [
                "poulet",
                "sauce-soja-claire",
                "sauce-soja-foncee",
                "vin-shaoxing",
                "miel",
                "sucre-roux",
                "huile-sesame",
                "cinq-epices",
                "poudre-ail",
                "poudre-gingembre",
                "badiane",
                "ail",
                "gingembre",
                "oignon-vert",
                "sel",
                "poivre-blanc",
                "huile-vegetale"
            ],
            "preparation": [
                "Nettoyer le poulet, retirer l'excès de graisse et les abats. Sécher avec du papier absorbant.",
                "Mélanger 2 cuillères à soupe de sel avec 1 litre d'eau pour faire une saumure légère. Y plonger le poulet pendant 1 heure, puis rincer et sécher soigneusement.",
                "Dans un bol, préparer la marinade en mélangeant les sauces soja (claire et foncée), le vin de Shaoxing, 2 cuillères à soupe de miel, le sucre roux, l'huile de sésame, les cinq-épices, la poudre d'ail, la poudre de gingembre, le sel et le poivre blanc.",
                "Frotter l'intérieur de la cavité du poulet avec une partie de la marinade.",
                "Placer l'anis étoilé, l'ail écrasé, le gingembre et les oignons verts à l'intérieur de la cavité.",
                "Verser le reste de la marinade sur le poulet en le massant bien pour le couvrir entièrement.",
                "Placer le poulet dans un grand sac de congélation ou un plat couvert et réfrigérer pendant au moins 12 heures, idéalement 24 heures, en retournant le poulet plusieurs fois."
            ],
            "cuisson": [
                "Sortir le poulet du réfrigérateur environ 1 heure avant la cuisson pour qu'il revienne à température ambiante.",
                "Préchauffer le four à 180°C.",
                "Retirer le poulet de la marinade (conserver celle-ci) et le suspendre pour le sécher pendant environ 30 minutes. En l'absence de crochet, le poser sur une grille pour que l'air circule autour.",
                "Placer le poulet sur une grille au-dessus d'un plat rempli d'un peu d'eau (pour récupérer la graisse et éviter la fumée).",
                "Cuire au four pendant 20 minutes, puis réduire la température à 160°C et poursuivre la cuisson pendant 40 minutes supplémentaires.",
                "Pendant ce temps, faire chauffer la marinade réservée dans une petite casserole jusqu'à ébullition, puis la laisser mijoter 5 minutes. Ajouter le miel restant et remuer pour obtenir un glaçage.",
                "Toutes les 15 minutes, sortir le poulet et le badigeonner généreusement avec le glaçage.",
                "Dans les 10 dernières minutes de cuisson, augmenter la température à 220°C pour obtenir une peau bien croustillante et dorée.",
                "Vérifier la cuisson en insérant un couteau entre la cuisse et la poitrine : le jus qui s'écoule doit être clair, pas rosé.",
                "Laisser reposer le poulet 15 minutes avant de le découper.",
                "Pour servir à la hongkongaise, découper le poulet en morceaux avec un couperet, y compris les os."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec du riz blanc et des légumes verts sautés. Pour une sauce d'accompagnement typique de Hong Kong, mélanger 3 cuillères à soupe de sauce soja claire, 1 cuillère à soupe de sauce d'huître, 1 cuillère à café d'huile de sésame, 1 cuillère à café de sucre, 1 gousse d'ail hachée finement, 1 morceau de gingembre râpé (2 cm) et 2 oignons verts émincés. Pour les légumes, faire chauffer 2 cuillères à soupe d'huile dans un wok, ajouter 2 gousses d'ail émincées et 500g de pak choï ou de choy sum coupés en morceaux. Faire sauter à feu vif 2-3 minutes, ajouter 1 cuillère à soupe de sauce d'huître et 2 cuillères à soupe d'eau. Couvrir et cuire 1 minute supplémentaire.",
            "shortStory": "Le **Poulet Siu Mei** (en cantonais 燒味) fait partie de la tradition des viandes rôties et laquées qui sont une spécialité culinaire emblématique de Hong Kong. Dans les rues animées de cette métropole, on peut voir ces poulets laqués suspendus aux devantures des restaurants spécialisés, reconnaissables à leur peau brillante couleur ambre. Cette technique de préparation est un héritage de la cuisine cantonaise, où l'art du rôtissage est porté à son apogée. Le **laquage** au miel et aux épices, ainsi que le séchage minutieux de la peau, sont des étapes cruciales pour obtenir cette texture croustillante si caractéristique. Les **cinq épices chinoises** (étoile de badiane, clou de girofle, cannelle, fenouil et poivre du Sichuan) apportent une complexité aromatique profonde. À Hong Kong, ces poulets sont traditionnellement préparés pour le Nouvel An chinois et d'autres célébrations importantes, symbolisant la prospérité et l'abondance. Les maîtres siu mei sont des artisans respectés qui transmettent leur savoir-faire de génération en génération dans les quartiers traditionnels comme Sham Shui Po.",
            "detailURL": "/fiche-recette/155/poulet-laque-miel-epices/poulet-siu-mei.html",
            "time_prepare": "45mn",
            "time_maceration": "24h",
            "time_cooking": "75mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2), avec comme ingrédients plus onéreux principalement les sauces asiatiques spécifiques et le vin de Shaoxing qui peuvent être trouvés dans les épiceries asiatiques."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette n'est pas épicée, mettant plutôt en valeur les saveurs umami et sucrées-salées caractéristiques de la cuisine cantonaise."
            },
            "calories": "450 kcal",
            "allergens": [
                "soja",
                "sesame"
            ],
            "difficulty": {
                "level": 3,
                "description": "Recette de difficulté moyenne à élevée, nécessitant une attention particulière au processus de marinage, séchage et laquage pour obtenir la texture et l'apparence caractéristiques."
            },
            "equipment_needed": [
                "four",
                "grille-de-cuisson",
                "pinceau-cuisine",
                "couperet"
            ]
        },
        {
            "id": 156,
            "categorie": "asie",
            "country": "Hong Kong",
            "flag": "HK",
            "title": "Poulet Sanbeiji",
            "subTitle": "Poulet braisé aux trois tasses (sauce soja, vin de riz et huile de sésame)",
            "chicken_piece": [
                "cuisse",
                "aile",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": true,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "800g de morceaux de poulet (cuisses, pilons, ailes)",
                "4 cuillères à soupe d'huile de sésame",
                "8 gousses d'ail, écrasées légèrement avec la peau",
                "1 morceau de gingembre de 5 cm, tranché finement",
                "4 oignons verts, coupés en tronçons de 5 cm",
                "5 piments séchés (optionnel), coupés en deux",
                "10 feuilles de basilic thaï (ou basilic ordinaire)",
                "3 cuillères à soupe de sucre roux",
                "Pour la sauce aux trois tasses:",
                "1/3 tasse de sauce soja claire",
                "1/3 tasse de vin de riz chinois (michiu) ou xérès sec",
                "1/3 tasse d'huile de sésame",
                "1 étoile de badiane",
                "1 bâton de cannelle",
                "1 cuillère à café de grains de poivre du Sichuan",
                "2 cuillères à soupe de sauce d'huître"
            ],
            "ingredient_liste": [
                "poulet",
                "huile-sesame",
                "ail",
                "gingembre",
                "oignon-vert",
                "piment-seche",
                "basilic-thai",
                "sucre-roux",
                "sauce-soja-claire",
                "vin-riz-chinois",
                "badiane",
                "cannelle",
                "poivre-sichuan",
                "sauce-huitre"
            ],
            "preparation": [
                "Couper le poulet en morceaux de 5 cm environ si nécessaire.",
                "Préparer tous les aromates: écraser l'ail, trancher le gingembre, couper les oignons verts, couper les piments en deux (si utilisés).",
                "Dans un bol, mélanger les ingrédients de la sauce aux trois tasses: sauce soja, vin de riz et huile de sésame.",
                "Laver les feuilles de basilic et les sécher délicatement."
            ],
            "cuisson": [
                "Dans un wok ou une cocotte en terre cuite (si disponible), chauffer l'huile de sésame à feu moyen-vif.",
                "Ajouter l'ail, le gingembre, les piments séchés (si utilisés), l'étoile de badiane, le bâton de cannelle et les grains de poivre du Sichuan. Faire revenir jusqu'à ce que les arômes se dégagent, environ 1-2 minutes.",
                "Ajouter les morceaux de poulet et les faire sauter jusqu'à ce qu'ils soient légèrement dorés de tous côtés, environ 3-4 minutes.",
                "Verser le mélange de sauce aux trois tasses et la sauce d'huître, puis ajouter le sucre roux. Bien mélanger pour enrober le poulet.",
                "Réduire le feu à moyen-doux, couvrir et laisser mijoter pendant environ 15-20 minutes, en remuant occasionnellement, jusqu'à ce que le poulet soit bien cuit et que la sauce ait réduit et épaissi.",
                "Ajouter les oignons verts et poursuivre la cuisson pendant 2-3 minutes.",
                "Juste avant de servir, ajouter les feuilles de basilic et remuer délicatement jusqu'à ce qu'elles soient légèrement flétries, environ 30 secondes.",
                "La sauce doit être réduite et légèrement sirupeuse. Si elle est trop liquide, augmenter le feu et faire réduire pendant quelques minutes.",
                "Servir chaud, directement dans le récipient de cuisson si possible, pour une présentation authentique."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": true,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte",
                "poele"
            ],
            "accompagnement": "Servir avec du riz blanc nature. Pour une touche hongkongaise authentique, préparer des légumes verts sautés à l'ail: faire chauffer 2 cuillères à soupe d'huile dans un wok très chaud, ajouter 3 gousses d'ail émincées, puis 500g de kai lan (brocoli chinois) ou de gai choy (moutarde chinoise) lavés et coupés en morceaux. Faire sauter rapidement 1 minute, puis ajouter 2 cuillères à soupe d'eau et 1 cuillère à soupe de sauce d'huître. Couvrir et cuire 2 minutes supplémentaires jusqu'à ce que les légumes soient tendres mais encore croquants.",
            "shortStory": "Le **Poulet Sanbeiji**, également connu sous le nom de **poulet aux trois tasses**, est originaire de Taiwan mais est devenu immensément populaire à Hong Kong, où il a été adapté aux goûts locaux. Son nom vient des trois ingrédients principaux utilisés en quantités égales: **sauce soja**, **vin de riz** et **huile de sésame**, formant un trio harmonieux de saveurs. Cette recette aurait été créée par un cuisinier qui, fuyant la persécution pendant la dynastie Song (960-1279), n'avait que ces trois ingrédients pour préparer un repas à un célèbre général. À Hong Kong, où la cuisine taiwanaise s'est implantée avec l'arrivée de nombreux immigrants après 1949, ce plat est typiquement servi dans des cassolettes en terre cuite brûlantes, créant un effet spectaculaire lorsqu'il arrive à table. Le **basilic thaï**, ajouté en fin de cuisson, apporte une note aromatique distinctive qui équilibre la richesse de la sauce. Dans les quartiers comme Causeway Bay, on trouve de nombreux restaurants spécialisés dans cette préparation, souvent servie comme plat principal lors de dîners familiaux.",
            "detailURL": "/fiche-recette/156/poulet-braise-soja-sesame/poulet-sanbeiji.html",
            "time_prepare": "20mn",
            "time_maceration": "0h",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2), avec comme ingrédients plus onéreux l'huile de sésame de qualité et le basilic thaï qui peut être difficile à trouver hors saison."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette peut être légèrement épicée si l'on utilise des piments séchés, mais l'intensité peut être facilement ajustée selon les préférences."
            },
            "calories": "420 kcal",
            "allergens": [
                "soja",
                "sesame",
                "fruits-mer"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne, nécessitant principalement une attention au temps de réduction de la sauce pour obtenir la consistance idéale."
            },
            "equipment_needed": [
                "wok",
                "cocotte-terre-cuite"
            ]
        },
        {
            "id": 157,
            "categorie": "europe",
            "country": "Pays-Bas",
            "flag": "NL",
            "title": "Poulet Hete Kip",
            "subTitle": "Poulet mijoté aux légumes et aux épices indonésiennes",
            "chicken_piece": [
                "cuisse",
                "blanc",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "800g de morceaux de poulet (cuisses, blancs ou pilons)",
                "2 oignons, émincés",
                "3 gousses d'ail, émincées",
                "2 cm de gingembre frais, râpé",
                "2 poivrons (1 rouge, 1 vert), coupés en lanières",
                "1 carotte, coupée en julienne",
                "1 branche de céleri, émincée",
                "200g de haricots verts, coupés en tronçons",
                "1 boîte (400g) de tomates concassées",
                "250ml de bouillon de poulet",
                "3 cuillères à soupe d'huile végétale",
                "2 cuillères à soupe de sambal oelek (ou 1 piment rouge haché)",
                "2 cuillères à soupe de sauce soja",
                "1 cuillère à soupe de cassonade",
                "1 cuillère à café de cumin moulu",
                "1 cuillère à café de coriandre moulue",
                "1 cuillère à café de curcuma",
                "2 feuilles de laurier",
                "Sel et poivre",
                "Jus d'un citron vert",
                "3 cuillères à soupe de coriandre fraîche, ciselée (facultatif)"
            ],
            "ingredient_liste": [
                "poulet",
                "oignon",
                "ail",
                "gingembre",
                "poivron",
                "carotte",
                "celeri",
                "haricot-vert",
                "tomate-concassee",
                "bouillon-poulet",
                "huile-vegetale",
                "sambal-oelek",
                "sauce-soja",
                "cassonade",
                "cumin",
                "coriandre-moulue",
                "curcuma",
                "laurier",
                "sel",
                "poivre",
                "citron-vert",
                "coriandre-fraiche"
            ],
            "preparation": [
                "Couper le poulet en morceaux de taille égale si nécessaire.",
                "Assaisonner le poulet avec du sel, du poivre et 1/2 cuillère à café de curcuma.",
                "Émincer les oignons, l'ail, couper les poivrons en lanières, la carotte en julienne, le céleri en fines tranches et les haricots verts en tronçons de 4 cm.",
                "Râper le gingembre frais."
            ],
            "cuisson": [
                "Dans une grande cocotte ou un faitout, chauffer l'huile à feu moyen-vif.",
                "Faire dorer les morceaux de poulet par lots sur tous les côtés pendant environ 5 minutes, puis les réserver.",
                "Dans la même cocotte, faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter l'ail et le gingembre, faire revenir pendant 1 minute jusqu'à ce que les arômes se dégagent.",
                "Incorporer le sambal oelek (ou le piment haché), le cumin, la coriandre moulue et le reste du curcuma, cuire pendant 30 secondes en remuant constamment.",
                "Ajouter les poivrons, la carotte et le céleri, faire sauter pendant 3-4 minutes.",
                "Verser les tomates concassées et le bouillon de poulet, ajouter les feuilles de laurier, la sauce soja et la cassonade.",
                "Remettre les morceaux de poulet dans la cocotte, couvrir et laisser mijoter à feu doux pendant 25 minutes.",
                "Ajouter les haricots verts et poursuivre la cuisson pendant 10 minutes supplémentaires, jusqu'à ce que le poulet soit bien cuit et tendre et que les légumes soient al dente.",
                "Goûter et ajuster l'assaisonnement avec du sel et du poivre.",
                "Retirer les feuilles de laurier.",
                "Avant de servir, arroser de jus de citron vert et parsemer de coriandre fraîche ciselée si utilisée."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc et des atjar (condiment aigre-doux à base de légumes marinés). Pour préparer un atjar simple: dans un bol, mélanger 1 concombre coupé en fines lamelles, 1 carotte râpée, 1/2 oignon rouge émincé, 1 piment rouge épépiné et émincé. Dans une casserole, chauffer 150ml de vinaigre blanc, 100g de sucre, 1/2 cuillère à café de sel, 1 cuillère à café de graines de moutarde et 2 clous de girofle. Porter à ébullition jusqu'à dissolution du sucre. Verser sur les légumes, laisser refroidir puis réfrigérer au moins 2 heures avant de servir.",
            "shortStory": "Le **Hete Kip** (littéralement 'poulet chaud' en néerlandais) est un plat qui témoigne de l'influence coloniale indonésienne sur la cuisine néerlandaise. Suite à la présence des Pays-Bas en Indonésie pendant plus de trois siècles, de nombreuses recettes **indo-néerlandaises** se sont développées, créant une cuisine fusion unique. Le **sambal oelek**, pâte de piments originaire d'Indonésie, est devenu un condiment courant dans les foyers néerlandais. Ce plat reflète parfaitement la 'rijsttafel' (table de riz), tradition culinaire née pendant l'époque coloniale où de nombreux plats épicés étaient servis avec du riz blanc. Dans les années 1950, après l'indépendance de l'Indonésie, de nombreux Indo-Néerlandais se sont installés aux Pays-Bas, apportant avec eux leurs traditions culinaires. Aujourd'hui, ce type de cuisine est profondément ancré dans la culture néerlandaise, au point que certains considèrent les plats indonésiens adaptés comme faisant partie de leur patrimoine national. Le Hete Kip est particulièrement apprécié pendant les mois d'hiver, apportant chaleur et réconfort face au climat rigoureux des Pays-Bas.",
            "detailURL": "/fiche-recette/157/poulet-legumes-epices-indonesiennes/poulet-hete-kip.html",
            "time_prepare": "25mn",
            "time_maceration": "0h",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2), avec comme ingrédient plus onéreux principalement le sambal oelek qui peut être remplacé par du piment frais et des épices déjà présentes dans la recette."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Cette recette est moyennement épicée en raison du sambal oelek, mais l'intensité peut être facilement ajustée selon les préférences."
            },
            "calories": "380 kcal",
            "allergens": [
                "soja"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne, nécessitant principalement une attention aux temps de cuisson des différents légumes pour qu'ils restent croquants."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche-a-decouper"
            ]
        }, {
            "id": 156,
            "categorie": "europe",
            "country": "Pays-Bas",
            "flag": "NL",
            "title": "Poulet Speculaas",
            "subTitle": "Poulet mariné aux épices speculaas et cuit au four avec des pommes",
            "chicken_piece": [
                "entier",
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier d'environ 1,5 kg (ou 6 cuisses)",
                "3 cuillères à soupe de mélange d'épices speculaas (cannelle, muscade, clou de girofle, gingembre, cardamome, anis étoilé)",
                "3 pommes acidulées (Elstar ou Granny Smith), coupées en quartiers",
                "2 oignons rouges, coupés en quartiers",
                "4 échalotes, pelées et laissées entières",
                "3 cuillères à soupe de miel",
                "2 cuillères à soupe de sirop de stroopwafel (ou sirop d'érable)",
                "3 cuillères à soupe d'huile d'olive",
                "2 cuillères à soupe de beurre",
                "100ml de cidre sec",
                "1 cuillère à café de sel",
                "1/2 cuillère à café de poivre noir moulu",
                "2 brins de thym frais",
                "1 branche de romarin frais",
                "50g d'amandes effilées"
            ],
            "ingredient_liste": [
                "poulet",
                "epices-speculaas",
                "pomme",
                "oignon-rouge",
                "echalote",
                "miel",
                "sirop-stroopwafel",
                "huile-olive",
                "beurre",
                "cidre",
                "sel",
                "poivre",
                "thym",
                "romarin",
                "amande"
            ],
            "preparation": [
                "Préchauffer le four à 180°C.",
                "Dans un petit bol, mélanger les épices speculaas, le sel et le poivre.",
                "Frotter généreusement le poulet avec ce mélange d'épices, à l'intérieur et à l'extérieur.",
                "Dans un autre bol, mélanger le miel et le sirop de stroopwafel.",
                "Couper les pommes en quartiers en retirant le cœur mais en laissant la peau.",
                "Couper les oignons rouges en quartiers."
            ],
            "cuisson": [
                "Dans une grande cocotte allant au four, faire chauffer l'huile d'olive et le beurre à feu moyen-vif.",
                "Faire dorer le poulet sur tous les côtés pendant environ 5-7 minutes.",
                "Retirer le poulet et réserver.",
                "Dans la même cocotte, faire revenir les oignons et les échalotes pendant 3-4 minutes jusqu'à ce qu'ils commencent à caraméliser.",
                "Remettre le poulet dans la cocotte, ajouter les quartiers de pommes tout autour.",
                "Arroser avec le mélange de miel et de sirop de stroopwafel.",
                "Verser le cidre dans la cocotte (pas directement sur le poulet pour ne pas rincer les épices).",
                "Ajouter les brins de thym et de romarin.",
                "Couvrir et enfourner pendant 45 minutes.",
                "Retirer le couvercle, saupoudrer d'amandes effilées et poursuivre la cuisson à découvert pendant 15-20 minutes supplémentaires, jusqu'à ce que le poulet soit bien doré et que la température interne atteigne 75°C.",
                "Arroser occasionnellement le poulet avec les jus de cuisson pendant cette dernière étape.",
                "Laisser reposer le poulet 10 minutes avant de le découper et de le servir.",
                "Servir avec les pommes, les oignons et les échalotes confits, nappés de sauce de cuisson."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "four",
                "cocotte"
            ],
            "accompagnement": "Servir avec des pommes de terre au four et du chou rouge braisé aux pommes. Pour le chou rouge: émincer finement 1/2 chou rouge et 1 oignon. Dans une casserole, faire fondre 1 cuillère à soupe de beurre, ajouter l'oignon et faire revenir jusqu'à ce qu'il soit translucide. Ajouter le chou, 1 pomme pelée et coupée en dés, 3 cuillères à soupe de vinaigre de vin rouge, 2 cuillères à soupe de cassonade, 1 cuillère à café de cannelle, 2 clous de girofle, 1 feuille de laurier, 100ml d'eau, sel et poivre. Couvrir et laisser mijoter à feu doux pendant environ 45 minutes à 1 heure, en remuant occasionnellement.",
            "shortStory": "Le **Poulet Speculaas** est une création inspirée du célèbre biscuit néerlandais speculaas, dont le mélange d'**épices** caractéristique remonte à l'âge d'or néerlandais (17ème siècle), lorsque la Compagnie des Indes orientales importait des épices exotiques des colonies. Cette recette marie avec audace la tradition pâtissière néerlandaise et la cuisine de volaille, illustrant l'approche créative et pragmatique des Néerlandais en matière de gastronomie. Les **pommes**, ingrédient très cultivé aux Pays-Bas, notamment dans la province de Gelderland, apportent une acidité rafraîchissante qui équilibre parfaitement les notes chaudes et sucrées des épices. Le **sirop de stroopwafel**, dérivé du célèbre biscuit caramélisé néerlandais, ajoute une touche de douceur authentiquement hollandaise. Ce plat s'inscrit dans la tendance culinaire néerlandaise contemporaine qui réinterprète les saveurs traditionnelles dans des créations modernes, particulièrement visible dans les provinces d'Utrecht et d'Amsterdam. Il est parfait pour les festivités automnales comme **Sinterklaas** (Saint-Nicolas), lorsque les épices speculaas parfument les maisons néerlandaises.",
            "detailURL": "/fiche-recette/156/poulet-epices-speculaas-pommes/poulet-speculaas.html",
            "time_prepare": "20mn",
            "time_maceration": "0h",
            "time_cooking": "75mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2), avec comme ingrédient plus onéreux principalement le mélange d'épices speculaas que l'on peut réaliser soi-même en mélangeant les épices individuelles."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette n'est pas épicée au sens piquant du terme, mais présente des saveurs chaudes et aromatiques grâce aux épices speculaas."
            },
            "calories": "460 kcal",
            "allergens": [
                "produits-laitiers",
                "fruits-a-coque"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne, nécessitant principalement une attention au temps de cuisson pour que le poulet reste juteux et que les pommes ne se défassent pas trop."
            },
            "equipment_needed": [
                "cocotte",
                "four",
                "thermometre-cuisine"
            ]
        },
        {
            "id": 155,
            "categorie": "europe",
            "country": "Pays-Bas",
            "flag": "NL",
            "title": "Poulet Hollandse Kip",
            "subTitle": "Poulet mijoté aux légumes et moutarde à la hollandaise",
            "chicken_piece": [
                "entier",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux (ou 4 cuisses et 2 blancs)",
                "3 pommes de terre à chair ferme, coupées en cubes",
                "2 carottes, coupées en rondelles",
                "2 poireaux, nettoyés et émincés",
                "1 oignon, émincé",
                "2 gousses d'ail, émincées",
                "200g de petits pois (frais ou surgelés)",
                "3 cuillères à soupe de moutarde de Zaanse (ou à défaut moutarde de Dijon)",
                "2 feuilles de laurier",
                "1 cuillère à café de thym séché",
                "500ml de bouillon de poulet",
                "100ml de crème fraîche",
                "2 cuillères à soupe de persil frais haché",
                "1 cuillère à soupe de ciboulette fraîche ciselée",
                "2 cuillères à soupe de beurre",
                "2 cuillères à soupe d'huile végétale",
                "Sel et poivre noir",
                "1 pincée de noix de muscade râpée"
            ],
            "ingredient_liste": [
                "poulet",
                "pomme-de-terre",
                "carotte",
                "poireau",
                "oignon",
                "ail",
                "petit-pois",
                "moutarde",
                "laurier",
                "thym",
                "bouillon-poulet",
                "creme-fraiche",
                "persil",
                "ciboulette",
                "beurre",
                "huile-vegetale",
                "sel",
                "poivre",
                "muscade"
            ],
            "preparation": [
                "Assaisonner les morceaux de poulet avec du sel et du poivre.",
                "Éplucher et couper les pommes de terre en cubes de 2 cm.",
                "Éplucher et couper les carottes en rondelles d'environ 0,5 cm.",
                "Nettoyer soigneusement les poireaux, retirer la partie vert foncé et les émincer en rondelles.",
                "Émincer l'oignon et l'ail."
            ],
            "cuisson": [
                "Dans une grande cocotte, faire chauffer le beurre et l'huile à feu moyen-vif.",
                "Faire dorer les morceaux de poulet sur tous les côtés pendant environ 5 minutes, puis les réserver.",
                "Dans la même cocotte, faire revenir l'oignon jusqu'à ce qu'il soit translucide.",
                "Ajouter l'ail et faire revenir 30 secondes supplémentaires.",
                "Ajouter les carottes et les poireaux, faire revenir pendant 3-4 minutes.",
                "Remettre les morceaux de poulet dans la cocotte, ajouter les feuilles de laurier et le thym.",
                "Verser le bouillon de poulet, couvrir et laisser mijoter à feu doux pendant 15 minutes.",
                "Ajouter les pommes de terre, couvrir à nouveau et poursuivre la cuisson pendant 15-20 minutes, jusqu'à ce que les pommes de terre soient presque tendres.",
                "Incorporer les petits pois et la moutarde, bien mélanger, et laisser mijoter pendant 5 minutes supplémentaires.",
                "Retirer les morceaux de poulet et les réserver au chaud.",
                "Augmenter légèrement le feu et laisser réduire la sauce de moitié, pendant environ 5-7 minutes.",
                "Baisser le feu, incorporer la crème fraîche en remuant doucement et saupoudrer de muscade râpée.",
                "Remettre les morceaux de poulet dans la sauce, poursuivre la cuisson pendant 3-4 minutes pour réchauffer le tout.",
                "Retirer les feuilles de laurier, goûter et ajuster l'assaisonnement si nécessaire.",
                "Parsemer de persil et de ciboulette avant de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": true,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte",
                "poele"
            ],
            "accompagnement": "Servir avec du pain de seigle beurré (roggebrood) et une salade de chou rouge. Pour la salade de chou rouge: émincer finement 1/2 chou rouge et 1 pomme verte. Mélanger dans un bol avec 2 cuillères à soupe de vinaigre de vin rouge, 1 cuillère à soupe de cassonade, 1 cuillère à café de cannelle, 2 cuillères à soupe d'huile d'olive, sel et poivre. Laisser reposer au moins 30 minutes au réfrigérateur, en mélangeant de temps en temps.",
            "shortStory": "Le **Hollandse Kip** (poulet à la hollandaise) est un plat réconfortant qui reflète parfaitement la tradition culinaire des **Pays-Bas**, axée sur des ingrédients simples mais nourrissants. Cette recette incarne l'approche pragmatique de la cuisine néerlandaise, où les plats en une seule marmite (eenpansgerechten) sont particulièrement appréciés pour leur côté pratique et économique. Les **légumes-racines** utilisés sont typiques de l'agriculture locale, adaptée au climat frais et humide du pays. La moutarde de **Zaanse**, produite dans la région de Zaandam depuis le 17ème siècle, est un condiment emblématique de la cuisine hollandaise, apportant une saveur distinctive légèrement sucrée et épicée. Ce genre de plat mijoté était traditionnellement préparé dans les fermes néerlandaises, notamment dans les provinces de Hollande-Septentrionale et de Frise, où l'élevage de volailles est une activité importante. Il s'inscrit dans la tradition du **AVG** (Aardappelen, Vlees, Groente - pommes de terre, viande, légumes), la structure classique d'un repas néerlandais équilibré.",
            "detailURL": "/fiche-recette/155/poulet-legumes-moutarde/poulet-hollandse-kip.html",
            "time_prepare": "25mn",
            "time_maceration": "0h",
            "time_cooking": "55mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2), avec des ingrédients généralement accessibles et abordables."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette n'est pas épicée et convient parfaitement aux palais sensibles. La moutarde apporte une légère chaleur mais pas de piquant."
            },
            "calories": "440 kcal",
            "allergens": [
                "produits-laitiers",
                "moutarde"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne, nécessitant principalement une attention aux temps de cuisson des différents légumes pour qu'ils soient tous cuits à point."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche-a-decouper"
            ]
        },
        {
            "id": 157,
            "categorie": "amerique-centrale",
            "country": "Honduras",
            "flag": "HN",
            "title": "Poulet Catrachito",
            "subTitle": "Poulet grillé mariné au achiote et agrumes, façon hondurienne",
            "chicken_piece": [
                "entier",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux (ou 4 cuisses et 2 blancs)",
                "4 cuillères à soupe de pâte d'achiote (annatto)",
                "4 oranges amères (ou 2 oranges et 2 citrons), jus et zeste",
                "3 citrons verts, jus et zeste",
                "6 gousses d'ail, écrasées",
                "2 oignons, hachés finement",
                "2 cuillères à soupe de coriandre fraîche ciselée",
                "2 cuillères à café de cumin moulu",
                "1 cuillère à café d'origan séché",
                "1/2 cuillère à café de cannelle moulue",
                "2 piments jalapeños, épépinés et hachés (ajuster selon le goût)",
                "3 cuillères à soupe d'huile d'olive",
                "1 cuillère à soupe de vinaigre blanc",
                "1 cuillère à café de sel",
                "1/2 cuillère à café de poivre noir moulu"
            ],
            "ingredient_liste": [
                "poulet",
                "achiote",
                "orange-amere",
                "citron-vert",
                "ail",
                "oignon",
                "coriandre",
                "cumin",
                "origan",
                "cannelle",
                "piment-jalapeno",
                "huile-olive",
                "vinaigre-blanc",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un mixeur, combiner la pâte d'achiote, le jus et le zeste des agrumes, l'ail, les oignons, les piments jalapeños, la coriandre, le cumin, l'origan, la cannelle, le vinaigre, l'huile d'olive, le sel et le poivre. Mixer jusqu'à obtenir une marinade homogène.",
                "Rincer le poulet et le sécher avec du papier absorbant.",
                "Placer le poulet dans un grand sac de congélation ou un récipient profond et verser la marinade dessus.",
                "Masser la marinade sur toutes les pièces de poulet pour bien les enrober.",
                "Fermer le sac ou couvrir le récipient et réfrigérer pendant au moins 4 heures, idéalement toute une nuit, en retournant occasionnellement."
            ],
            "cuisson": [
                "Sortir le poulet du réfrigérateur environ 30 minutes avant la cuisson pour qu'il revienne à température ambiante.",
                "Préchauffer le grill ou le barbecue à feu moyen (environ 160-180°C).",
                "Retirer le poulet de la marinade, en conservant cette dernière pour le badigeonnage pendant la cuisson.",
                "Huiler légèrement la grille pour éviter que le poulet n'y adhère.",
                "Placer les morceaux de poulet sur la grille, peau vers le haut pour commencer.",
                "Griller le poulet pendant environ 35-45 minutes (selon les morceaux), en le retournant toutes les 10 minutes et en le badigeonnant régulièrement avec la marinade réservée.",
                "Pour une cuisson au four: préchauffer le four à 190°C. Placer les morceaux de poulet dans un plat allant au four, peau vers le haut. Verser un peu de marinade dans le fond du plat. Cuire pendant environ 40-45 minutes, en arrosant le poulet avec les jus de cuisson toutes les 15 minutes.",
                "Le poulet est cuit lorsque le jus qui s'en écoule est clair et que la température interne atteint 75°C.",
                "Laisser reposer le poulet pendant 5-10 minutes avant de servir.",
                "Garnir de quartiers de citron vert et de coriandre fraîche ciselée."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "barbecue",
                "grill",
                "four"
            ],
            "accompagnement": "Servir avec du riz blanc, des haricots rouges frits (frijoles fritos) et des bananes plantain frites (tajadas). Pour les frijoles fritos: faire tremper 250g de haricots rouges toute une nuit. Égoutter et rincer. Cuire dans de l'eau salée jusqu'à ce qu'ils soient tendres (environ 1h30). Égoutter en conservant un peu d'eau de cuisson. Dans une poêle, faire revenir 1 oignon haché dans 2 cuillères à soupe d'huile. Ajouter les haricots et écraser grossièrement. Ajouter un peu d'eau de cuisson si nécessaire pour obtenir une consistance crémeuse mais pas liquide. Assaisonner avec du sel et du poivre.",
            "shortStory": "Le **Poulet Catrachito** tire son nom du terme 'catracho', surnom affectueux donné aux Honduriens. Cette recette célèbre les saveurs vibrantes de la cuisine hondurienne, où les marinades à base d'**agrumes** sont particulièrement appréciées pour leur capacité à attendrir la viande tout en lui apportant une fraîcheur caractéristique. L'**achiote** (ou annatto), ingrédient phare de cette recette, est une épice précolombienne utilisée par les Mayas qui donne sa couleur rouge-orangée distinctive au plat. Dans les campagnes honduriennes, particulièrement dans les départements d'Olancho et de Santa Bárbara, ce type de poulet grillé est souvent préparé lors des fêtes patronales des villages, cuisiné sur de grands grills improvisés. La tradition veut que la viande soit marinée dans des récipients en terre cuite pour développer pleinement ses saveurs. Ce plat représente parfaitement la cuisine hondurienne, simple mais savoureuse, qui a su préserver ses racines indigènes tout en intégrant des influences espagnoles et africaines au fil des siècles.",
            "detailURL": "/fiche-recette/157/poulet-achiote-agrumes-grille/poulet-catrachito.html",
            "time_prepare": "20mn",
            "time_maceration": "4h",
            "time_cooking": "45mn",
            "crispys": true,
            "cost": {
                "level": 3,
                "description": "Cette recette est d'un coût moyen à élevé (niveau 3) en raison principalement de la pâte d'achiote qui peut être difficile à trouver. On peut la remplacer par un mélange de paprika, de curcuma et d'un peu de vinaigre pour une version plus accessible."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette est légèrement épicée grâce aux piments jalapeños, mais l'intensité peut être facilement ajustée selon les préférences."
            },
            "calories": "420 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne, nécessitant principalement une attention à la cuisson sur le grill pour éviter que le poulet ne brûle à cause des sucres présents dans la marinade."
            },
            "equipment_needed": [
                "barbecue",
                "mixeur",
                "pinceau-cuisine",
                "thermometre-cuisine"
            ]
        },
        {
            "id": 158,
            "categorie": "europe",
            "country": "Hongrie",
            "flag": "HU",
            "title": "Poulet Pörkölt",
            "subTitle": "Ragoût de poulet au paprika et aux poivrons, façon hongroise",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux (ou 6 cuisses/pilons)",
                "3 cuillères à soupe de saindoux (ou huile végétale)",
                "3 gros oignons, émincés finement",
                "3 gousses d'ail, émincées",
                "3 cuillères à soupe de paprika hongrois doux",
                "1 cuillère à café de paprika hongrois fort (facultatif)",
                "1 cuillère à café de carvi moulu",
                "2 poivrons (1 rouge, 1 jaune), épépinés et coupés en lanières",
                "2 tomates, pelées et concassées",
                "1 cuillère à soupe de pâte de tomate",
                "200ml de bouillon de poulet",
                "100ml de vin blanc sec",
                "1 bouquet de persil frais, ciselé",
                "Sel et poivre noir",
                "1 cuillère à café de marjolaine séchée"
            ],
            "ingredient_liste": [
                "poulet",
                "saindoux",
                "oignon",
                "ail",
                "paprika-doux",
                "paprika-fort",
                "carvi",
                "poivron",
                "tomate",
                "pate-tomate",
                "bouillon-poulet",
                "vin-blanc",
                "persil",
                "sel",
                "poivre",
                "marjolaine"
            ],
            "preparation": [
                "Couper le poulet en morceaux de taille régulière si ce n'est pas déjà fait.",
                "Saler et poivrer les morceaux de poulet.",
                "Émincer finement les oignons et l'ail.",
                "Épépiner les poivrons et les couper en lanières.",
                "Peler les tomates en les plongeant 30 secondes dans de l'eau bouillante, puis les concasser."
            ],
            "cuisson": [
                "Dans une grande cocotte, faire fondre le saindoux (ou chauffer l'huile) à feu moyen.",
                "Faire dorer les morceaux de poulet sur tous les côtés pendant environ 5 minutes, puis les réserver.",
                "Dans la même cocotte, faire revenir les oignons émincés à feu doux jusqu'à ce qu'ils soient translucides, environ 8-10 minutes.",
                "Ajouter l'ail et faire revenir 1 minute supplémentaire.",
                "Retirer la cocotte du feu, ajouter le paprika (doux et fort) et remuer immédiatement pour éviter que le paprika ne brûle.",
                "Remettre la cocotte sur feu doux, ajouter les poivrons et faire revenir 3-4 minutes.",
                "Incorporer la pâte de tomate, les tomates concassées, le carvi et la marjolaine.",
                "Remettre les morceaux de poulet dans la cocotte.",
                "Verser le vin blanc et laisser réduire de moitié, puis ajouter le bouillon de poulet.",
                "Saler et poivrer selon le goût.",
                "Couvrir et laisser mijoter à feu doux pendant environ 45 minutes, jusqu'à ce que le poulet soit tendre et se détache facilement de l'os.",
                "Si la sauce est trop liquide, retirer le couvercle et laisser réduire pendant 5-10 minutes supplémentaires.",
                "Goûter et ajuster l'assaisonnement si nécessaire.",
                "Parsemer de persil frais ciselé avant de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec des galuska (petites boulettes de pâte hongroises similaires aux spaetzle) et des concombres à la crème aigre. Pour les galuska: mélanger 300g de farine, 3 œufs, 1/2 cuillère à café de sel et environ 100ml d'eau pour obtenir une pâte épaisse. Porter à ébullition une grande casserole d'eau salée. À l'aide d'une planche et d'un couteau (ou une râpe à spaetzle), découper de petits morceaux de pâte et les faire tomber directement dans l'eau bouillante. Quand ils remontent à la surface (après 2-3 minutes), les égoutter. Les mélanger avec un peu de beurre fondu.",
            "shortStory": "Le **Pörkölt** est l'un des plats traditionnels les plus emblématiques de la cuisine hongroise. Son nom vient du verbe 'pörkölni' qui signifie 'rôtir' ou 'faire dorer', référence à la première étape cruciale de la préparation où la viande est dorée dans le **saindoux**, graisse animale typiquement utilisée dans la cuisine hongroise. Ce ragoût rustique trouve ses origines dans les traditions culinaires des bergers de la Grande Plaine hongroise (Alföld), qui le préparaient dans des chaudrons suspendus au-dessus d'un feu de bois. Le **paprika**, épice emblématique apportée en Hongrie par les Turcs au XVIe siècle, donne à ce plat sa couleur rouge caractéristique et son goût distinctif. Contrairement au goulash qui est plus liquide et contient des pommes de terre, le pörkölt est un ragoût plus épais. Traditionnellement, chaque région hongroise a sa propre version du pörkölt, celle de Szeged étant particulièrement réputée pour son utilisation généreuse de paprika local de haute qualité. Ce plat incarne l'âme même de la cuisine hongroise: simple dans ses ingrédients mais riche en saveurs développées par une cuisson lente et patiente.",
            "detailURL": "/fiche-recette/158/poulet-paprika-poivrons-ragout/poulet-porkolt.html",
            "time_prepare": "25mn",
            "time_maceration": "0h",
            "time_cooking": "60mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2), avec comme ingrédient plus onéreux le paprika hongrois authentique, qui peut être remplacé par du paprika ordinaire si nécessaire."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette peut être légèrement épicée selon la quantité de paprika fort utilisée, mais reste généralement plus savoureuse que véritablement piquante."
            },
            "calories": "450 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne, nécessitant principalement une attention au moment d'ajouter le paprika pour éviter qu'il ne brûle."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche-a-decouper"
            ]
        },
        {
            "id": 159,
            "categorie": "amerique",
            "country": "Héritage Afro-Américain",
            "flag": "US",
            "title": "Poulet Frit Soul Food",
            "subTitle": "Poulet croustillant mariné au babeurre, assaisonné d'épices cajun",
            "chicken_piece": [
                "entier",
                "cuisse",
                "pilon",
                "aile"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": true,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux (ou 8 morceaux: cuisses, pilons, ailes)",
                "500ml de babeurre (ou 500ml de lait + 2 cuillères à soupe de jus de citron)",
                "3 cuillères à soupe de sauce piquante (type Tabasco ou Louisiana)",
                "300g de farine tout usage",
                "2 cuillères à soupe de paprika",
                "1 cuillère à soupe d'ail en poudre",
                "1 cuillère à soupe d'oignon en poudre",
                "1 cuillère à café de thym séché",
                "1 cuillère à café d'origan séché",
                "1 cuillère à café de poivre de Cayenne (ajuster selon le goût)",
                "1 cuillère à café de poivre noir fraîchement moulu",
                "2 cuillères à café de sel",
                "1/2 cuillère à café de muscade moulue",
                "1 litre d'huile végétale pour la friture",
                "2 œufs, battus",
                "1 cuillère à soupe de miel"
            ],
            "ingredient_liste": [
                "poulet",
                "babeurre",
                "sauce-piquante",
                "farine",
                "paprika",
                "ail-poudre",
                "oignon-poudre",
                "thym",
                "origan",
                "poivre-cayenne",
                "poivre",
                "sel",
                "muscade",
                "huile-vegetale",
                "oeuf",
                "miel"
            ],
            "preparation": [
                "Dans un grand bol, mélanger le babeurre, la sauce piquante et le miel. Si vous n'avez pas de babeurre, mélanger le lait avec le jus de citron et laisser reposer 5 minutes.",
                "Assaisonner les morceaux de poulet avec du sel et du poivre, puis les placer dans le mélange de babeurre. Couvrir et réfrigérer pendant au moins 4 heures, idéalement toute une nuit.",
                "Dans un grand sac ou un bol, mélanger la farine, le paprika, l'ail en poudre, l'oignon en poudre, le thym, l'origan, le poivre de Cayenne, le poivre noir et la muscade.",
                "Sortir le poulet du réfrigérateur environ 30 minutes avant la cuisson pour qu'il revienne à température ambiante."
            ],
            "cuisson": [
                "Dans une grande friteuse ou une marmite profonde, chauffer l'huile à 170°C. L'huile doit être suffisamment profonde pour couvrir les morceaux de poulet.",
                "Retirer les morceaux de poulet de la marinade et les égoutter légèrement.",
                "Dans un bol, battre les œufs et y tremper chaque morceau de poulet.",
                "Ensuite, enrober chaque morceau dans le mélange de farine épicée, en appuyant pour que la panure adhère bien.",
                "Frire les morceaux de poulet par lots, sans surcharger la friteuse, pendant environ 15-20 minutes selon la taille des morceaux.",
                "Les morceaux sont cuits lorsque la panure est dorée et croustillante, et que la température interne atteint 75°C.",
                "Retirer les morceaux à l'aide d'une écumoire et les déposer sur une grille placée au-dessus d'une plaque pour égoutter l'excès d'huile. Vous pouvez également utiliser plusieurs couches de papier absorbant.",
                "Pour maintenir le poulet au chaud pendant que vous cuisez les autres lots, le placer dans un four préchauffé à 95°C.",
                "Servir chaud, éventuellement avec une sauce au miel et à la moutarde pour tremper."
            ],
            "cooking_methods": {
                "best": "friture",
                "grill": false,
                "plancha": false,
                "friture": true,
                "barbecue": false,
                "four": false,
                "poele": true,
                "cocotte": false
            },
            "mode_cuisson": [
                "friture",
                "poele"
            ],
            "accompagnement": "Servir avec des waffles (gaufres) et du sirop d'érable ou du miel, ou des accompagnements soul food classiques comme du macaroni au fromage, des haricots verts aux lardons, de la purée de pommes de terre et du pain de maïs. Pour le pain de maïs: mélanger 200g de farine de maïs, 100g de farine tout usage, 2 cuillères à café de levure chimique, 1/2 cuillère à café de bicarbonate de soude, 1 cuillère à café de sel, 2 œufs battus, 300ml de babeurre et 60g de beurre fondu. Verser dans un moule à gâteau beurré et enfourner à 200°C pendant 20-25 minutes, jusqu'à ce que le dessus soit doré et qu'un cure-dent inséré en ressorte propre.",
            "shortStory": "Le **Poulet Frit Soul Food** représente l'un des plats les plus emblématiques de la cuisine afro-américaine, dont les racines remontent à l'époque de l'esclavage dans le Sud des États-Unis. Cette recette est un témoignage de la résilience et de la créativité culinaire des esclaves africains qui transformaient des ingrédients modestes en festins savoureux lors des rares occasions de célébration. Le **babeurre**, utilisé pour attendrir la viande, était à l'origine un sous-produit de la fabrication du beurre que les esclaves pouvaient obtenir plus facilement que d'autres ingrédients. La technique de **friture profonde** a des origines ouest-africaines, notamment dans les traditions culinaires du Sénégal. Ce plat a pris une importance culturelle particulière après l'émancipation, devenant un élément central des repas dominicaux familiaux et des rassemblements communautaires dans les églises. Dans les années 1960, pendant le mouvement des droits civiques, le poulet frit est devenu un symbole de l'héritage afro-américain et de la fierté culturelle, particulièrement associé aux célébrations de **Juneteenth** (19 juin, commémoration de l'émancipation des esclaves). Cette recette traditionnelle se transmet de génération en génération, chaque famille ayant sa variation particulière du mélange d'épices, souvent gardée secrète.",
            "detailURL": "/fiche-recette/159/poulet-frit-croustillant-babeurre/poulet-frit-soul-food.html",
            "time_prepare": "20mn",
            "time_maceration": "4h",
            "time_cooking": "30mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2), avec des ingrédients généralement accessibles. Le babeurre peut être remplacé par du lait acidifié avec du citron si nécessaire."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Cette recette peut être moyennement épicée selon la quantité de poivre de Cayenne et de sauce piquante utilisée, mais l'intensité peut être facilement ajustée selon les préférences."
            },
            "calories": "520 kcal",
            "allergens": [
                "oeuf",
                "gluten",
                "produits-laitiers"
            ],
            "difficulty": {
                "level": 3,
                "description": "Recette de difficulté moyenne à élevée, nécessitant une attention particulière à la température de l'huile et au temps de friture pour obtenir un poulet à la fois croustillant à l'extérieur et bien cuit à l'intérieur."
            },
            "equipment_needed": [
                "friteuse",
                "thermometre-cuisine",
                "ecumoire",
                "grille-egouttage"
            ]
        },
        {
            "id": 160,
            "categorie": "europe",
            "country": "Pays-Bas",
            "flag": "NL",
            "title": "Poulet Stamppot",
            "subTitle": "Poulet aux épices néerlandaises, servi avec une purée de pommes de terre et légumes",
            "chicken_piece": [
                "entier",
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux (ou 4 cuisses et 2 blancs)",
                "2 cuillères à soupe de moutarde de Zaanse",
                "2 cuillères à café de sel",
                "1 cuillère à café de poivre noir moulu",
                "1 cuillère à café de noix de muscade fraîchement râpée",
                "1/2 cuillère à café de clou de girofle moulu",
                "1 cuillère à café de cannelle moulue",
                "3 cuillères à soupe de beurre",
                "2 oignons, émincés",
                "2 poireaux, lavés et émincés (parties blanches et vert clair)",
                "2 branches de céleri, émincées",
                "2 feuilles de laurier",
                "250ml de bouillon de poulet",
                "100ml de crème fraîche épaisse",
                "2 cuillères à soupe de persil frais, ciselé",
                "1 bouquet de ciboulette fraîche, ciselée"
            ],
            "ingredient_liste": [
                "poulet",
                "moutarde-zaanse",
                "sel",
                "poivre",
                "muscade",
                "clou-girofle",
                "cannelle",
                "beurre",
                "oignon",
                "poireau",
                "celeri",
                "laurier",
                "bouillon-poulet",
                "creme-fraiche",
                "persil",
                "ciboulette"
            ],
            "preparation": [
                "Dans un bol, mélanger la moutarde de Zaanse, le sel, le poivre, la muscade, le clou de girofle et la cannelle pour créer une pâte à frotter.",
                "Enduire généreusement les morceaux de poulet avec ce mélange d'épices et les laisser mariner au réfrigérateur pendant au moins 2 heures.",
                "Émincer les oignons, les poireaux et le céleri."
            ],
            "cuisson": [
                "Dans une grande cocotte, faire fondre le beurre à feu moyen.",
                "Faire dorer les morceaux de poulet sur tous les côtés pendant environ 5 minutes, puis les réserver.",
                "Dans la même cocotte, faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter les poireaux et le céleri, et faire revenir pendant 3-4 minutes supplémentaires.",
                "Remettre les morceaux de poulet dans la cocotte, ajouter les feuilles de laurier et verser le bouillon de poulet.",
                "Porter à ébullition, puis réduire le feu, couvrir et laisser mijoter pendant environ 35-40 minutes, jusqu'à ce que le poulet soit tendre.",
                "Retirer les morceaux de poulet et les maintenir au chaud.",
                "Retirer les feuilles de laurier de la cocotte.",
                "Augmenter le feu et laisser réduire le liquide de moitié, pendant environ 5-7 minutes.",
                "Baisser le feu et incorporer la crème fraîche en remuant constamment.",
                "Poursuivre la cuisson pendant 2-3 minutes pour réchauffer la sauce sans la faire bouillir.",
                "Goûter et ajuster l'assaisonnement si nécessaire.",
                "Remettre les morceaux de poulet dans la sauce pour les réchauffer.",
                "Garnir de persil et de ciboulette ciselés avant de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du stamppot, une purée de pommes de terre traditionnelle néerlandaise. Pour le stamppot: éplucher et couper en cubes 1kg de pommes de terre à chair farineuse. Faire cuire dans de l'eau salée jusqu'à ce qu'elles soient tendres. Pendant ce temps, faire cuire 500g de chou frisé émincé (ou d'épinards, ou de chou rouge) à la vapeur pendant 5-7 minutes. Égoutter les pommes de terre et les écraser grossièrement. Mélanger avec les légumes cuits, 100g de beurre, 100ml de lait chaud, du sel, du poivre et de la noix de muscade. Traditionnellement, on ajoute également quelques morceaux de lard fumé croustillant (spekjes) sur le dessus.",
            "shortStory": "Le **Stamppot** est un plat emblématique de la cuisine néerlandaise, généralement associé à la saison hivernale. Cette version avec du poulet est une adaptation moderne du plat traditionnel qui utilise habituellement du saucisson fumé (rookworst). Les **épices** utilisées dans cette recette reflètent le riche passé colonial des Pays-Bas et leur commerce d'épices florissant pendant l'Âge d'Or néerlandais (17ème siècle). La **noix de muscade** notamment, était une denrée extrêmement précieuse importée des Indes orientales (actuelle Indonésie) par la Compagnie néerlandaise des Indes orientales. La **moutarde de Zaanse**, originaire de la région de Zaandam près d'Amsterdam, est un condiment distinctif légèrement sucré qui témoigne de la tradition de production alimentaire des moulins à vent hollandais. Dans les fermettes de Frise et de Hollande-Septentrionale, ce type de plat était traditionnellement préparé dans de grandes marmites en fonte suspendues au-dessus d'un feu de tourbe. Le stamppot incarne l'approche pragmatique et conviviale de la cuisine néerlandaise, mettant l'accent sur des ingrédients nourrissants et réconfortants pour faire face au climat froid et humide du pays.",
            "detailURL": "/fiche-recette/160/poulet-epices-puree-legumes/poulet-stamppot.html",
            "time_prepare": "20mn",
            "time_maceration": "2h",
            "time_cooking": "55mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2), avec des ingrédients généralement accessibles. La moutarde de Zaanse peut être remplacée par une moutarde à l'ancienne légèrement sucrée."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette n'est pas épicée, mais plutôt aromatique avec des notes d'épices douces comme la cannelle et la muscade."
            },
            "calories": "480 kcal",
            "allergens": [
                "produits-laitiers",
                "moutarde"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne, nécessitant principalement une attention à la cuisson du poulet pour qu'il reste tendre et à l'incorporation de la crème fraîche pour éviter qu'elle ne caille."
            },
            "equipment_needed": [
                "cocotte",
                "couteau",
                "planche-a-decouper"
            ]
        },
        {
            "id": 180,
            "categorie": "asie",
            "country": "Inde",
            "flag": "IN",
            "title": "Poulet Tandoori",
            "subTitle": "Poulet mariné au yaourt et aux épices, cuit au four tandoor",
            "chicken_piece": [
                "cuisse",
                "pilon",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "8 morceaux de poulet (cuisses, pilons ou blancs)",
                "250g de yaourt grec nature",
                "4 gousses d'ail, écrasées",
                "3 cm de gingembre frais, râpé",
                "2 cuillères à soupe de jus de citron",
                "3 cuillères à soupe d'huile de moutarde (ou huile végétale)",
                "2 cuillères à soupe de poudre de tandoori (ou garam masala)",
                "1 cuillère à café de curcuma moulu",
                "1 cuillère à café de piment rouge en poudre (ajuster selon le goût)",
                "1 cuillère à café de coriandre moulue",
                "1 cuillère à café de cumin moulu",
                "1/2 cuillère à café de cardamome moulue",
                "1 pincée d'asafoetida (facultatif)",
                "1 cuillère à café de sel",
                "1/4 cuillère à café de colorant alimentaire rouge (facultatif, traditionnel)"
            ],
            "ingredient_liste": [
                "poulet",
                "yaourt",
                "ail",
                "gingembre",
                "citron",
                "huile-moutarde",
                "poudre-tandoori",
                "curcuma",
                "piment-rouge",
                "coriandre",
                "cumin",
                "cardamome",
                "asafoetida",
                "sel",
                "colorant-rouge"
            ],
            "preparation": [
                "Inciser la chair du poulet à plusieurs endroits pour permettre à la marinade de bien pénétrer.",
                "Dans un grand bol, mélanger le yaourt, l'ail écrasé, le gingembre râpé et le jus de citron.",
                "Ajouter toutes les épices (poudre de tandoori, curcuma, piment, coriandre, cumin, cardamome, asafoetida), le sel, l'huile et le colorant alimentaire si utilisé.",
                "Bien mélanger pour obtenir une marinade homogène de couleur rouge-orangée.",
                "Enrober complètement les morceaux de poulet avec la marinade, en la faisant pénétrer dans les incisions.",
                "Couvrir le bol et réfrigérer pendant au moins 4 heures, idéalement toute une nuit pour que les saveurs se développent pleinement."
            ],
            "cuisson": [
                "Sortir le poulet du réfrigérateur 30 minutes avant la cuisson pour qu'il revienne à température ambiante.",
                "Préchauffer le four à 200°C ou préparer le barbecue à feu moyen-vif.",
                "Disposer les morceaux de poulet sur une grille placée au-dessus d'un plat pour recueillir la marinade qui s'écoule.",
                "Pour une cuisson au four: cuire pendant environ 35-40 minutes, en retournant à mi-cuisson.",
                "Pour une cuisson au barbecue: griller pendant environ 25-30 minutes, en retournant régulièrement pour éviter que la marinade ne brûle.",
                "Le poulet est cuit lorsque le jus qui s'en écoule est clair et que la température interne atteint 75°C.",
                "Pour une finition authentique, badigeonner les morceaux avec un peu de beurre clarifié (ghee) dans les dernières minutes de cuisson.",
                "Laisser reposer 5 minutes avant de servir.",
                "Garnir de quartiers de citron frais et de feuilles de coriandre fraîche."
            ],
            "cooking_methods": {
                "best": "tandoor",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "tandoor",
                "four",
                "barbecue",
                "grill"
            ],
            "accompagnement": "Servir avec du riz basmati parfumé à la cardamome et des naans à l'ail. Pour les naans: mélanger 500g de farine, 1 sachet de levure, 1 yaourt, 2 cuillères à soupe d'huile, 1 cuillère à café de sel et de l'eau tiède pour obtenir une pâte souple. Laisser lever 2 heures. Diviser en boules, étaler et badigeonner d'ail écrasé mélangé avec du beurre. Cuire dans un four très chaud (250°C) sur une pierre à pizza préchauffée pendant 2-3 minutes jusqu'à ce qu'ils gonflent et dorent. Accompagner également de chutney à la menthe: mixer 1 bouquet de menthe fraîche, 1 bouquet de coriandre, 2 piments verts, 1 oignon, le jus d'1/2 citron, 1 cuillère à café de sucre et du sel.",
            "shortStory": "Le **Poulet Tandoori** est l'un des plats les plus emblématiques de la cuisine indienne, originaire du Pendjab. Son nom vient du **tandoor**, four traditionnel en argile chauffé au charbon de bois qui peut atteindre des températures très élevées (jusqu'à 480°C). Ce plat a été popularisé dans les années 1940 par Kundan Lal Gujral, propriétaire du restaurant Moti Mahal à Delhi, qui a créé cette recette après avoir fui le Pakistan lors de la partition de l'Inde. La marinade à base de **yaourt** est essentielle car les enzymes qu'il contient attendrissent la viande, tandis que les **épices** lui confèrent sa saveur distinctive. Traditionnellement, le colorant rouge était utilisé pour masquer la couleur jaunâtre du curcuma et rendre le plat plus attrayant pour les clients. Aujourd'hui, le poulet tandoori est devenu un ambassadeur mondial de la cuisine indienne, servi dans presque tous les restaurants indiens à travers le monde.",
            "detailURL": "/fiche-recette/180/poulet-yaourt-epices-tandoori/poulet-tandoori.html",
            "time_prepare": "20mn",
            "time_maceration": "4h",
            "time_cooking": "40mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2). La plupart des épices peuvent être trouvées dans les supermarchés, bien que certaines comme l'asafoetida soient plus spécifiques et se trouvent dans les épiceries indiennes."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette est moyennement épicée (niveau 2), mais l'intensité peut être facilement ajustée en réduisant ou augmentant la quantité de piment rouge en poudre."
            },
            "calories": "350 kcal",
            "allergens": [
                "produits-laitiers"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne (niveau 2), nécessitant principalement une attention à la cuisson pour éviter que les épices ne brûlent."
            },
            "equipment_needed": [
                "four",
                "barbecue",
                "thermometre-cuisine",
                "grille-cuisson"
            ]
        },
        {
            "id": 181,
            "categorie": "asie",
            "country": "Inde",
            "flag": "IN",
            "title": "Poulet Butter Chicken",
            "subTitle": "Poulet mariné au yaourt et aux épices, mijoté dans une sauce tomate crémeuse au beurre",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "800g de blancs ou cuisses de poulet, coupés en morceaux",
                "Pour la marinade:",
                "200g de yaourt grec",
                "2 cuillères à soupe de jus de citron",
                "3 gousses d'ail, écrasées",
                "2 cm de gingembre frais, râpé",
                "1 cuillère à café de garam masala",
                "1 cuillère à café de curcuma",
                "1 cuillère à café de cumin moulu",
                "1 cuillère à café de sel",
                "Pour la sauce:",
                "50g de beurre ou ghee",
                "1 gros oignon, finement haché",
                "3 gousses d'ail, hachées",
                "2 cm de gingembre frais, râpé",
                "2 cuillères à café de poudre de piment kashmiri (doux et coloré)",
                "1 cuillère à café de garam masala",
                "1/2 cuillère à café de curcuma",
                "1 cuillère à café de cumin moulu",
                "1 cuillère à café de coriandre moulue",
                "1 boîte (400g) de tomates concassées",
                "1 cuillère à soupe de concentré de tomate",
                "1 cuillère à café de sucre",
                "200ml de crème fraîche épaisse",
                "1 cuillère à café de fenugrec séché (kasoori methi)",
                "Sel selon le goût",
                "Coriandre fraîche ciselée pour garnir"
            ],
            "ingredient_liste": [
                "poulet",
                "yaourt",
                "citron",
                "ail",
                "gingembre",
                "garam-masala",
                "curcuma",
                "cumin",
                "sel",
                "beurre",
                "oignon",
                "piment-kashmiri",
                "coriandre",
                "tomate",
                "concentre-tomate",
                "sucre",
                "creme-fraiche",
                "fenugrec",
                "coriandre-fraiche"
            ],
            "preparation": [
                "Dans un bol, mélanger tous les ingrédients de la marinade.",
                "Ajouter les morceaux de poulet et bien les enrober. Couvrir et réfrigérer pendant au moins 2 heures, idéalement 4 à 6 heures.",
                "Préchauffer le four à 200°C.",
                "Disposer les morceaux de poulet marinés sur une plaque de cuisson recouverte de papier sulfurisé.",
                "Cuire au four pendant 15 minutes, jusqu'à ce que le poulet soit presque cuit (il finira de cuire dans la sauce)."
            ],
            "cuisson": [
                "Dans une grande poêle ou une casserole, faire fondre le beurre ou le ghee à feu moyen.",
                "Ajouter l'oignon haché et faire revenir jusqu'à ce qu'il devienne translucide (environ 5 minutes).",
                "Ajouter l'ail et le gingembre, faire revenir pendant 1 minute jusqu'à ce que le mélange dégage son parfum.",
                "Incorporer toutes les épices en poudre (piment kashmiri, garam masala, curcuma, cumin, coriandre) et faire revenir pendant 30 secondes pour libérer leurs arômes.",
                "Ajouter les tomates concassées, le concentré de tomate et le sucre. Laisser mijoter pendant environ 10-15 minutes jusqu'à ce que la sauce épaississe légèrement.",
                "Pendant ce temps, si vous préférez une sauce lisse, mixer le mélange à l'aide d'un mixeur plongeant ou d'un blender (facultatif mais traditionnel).",
                "Réduire le feu à doux, ajouter la crème fraîche et mélanger doucement.",
                "Ajouter le poulet précuit et le fenugrec séché. Laisser mijoter à couvert pendant 10-15 minutes.",
                "Goûter et ajuster l'assaisonnement si nécessaire.",
                "Garnir de coriandre fraîche ciselée avant de servir."
            ],
            "cooking_methods": {
                "best": "four-cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "four",
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz basmati et des naans. Pour le riz parfumé: rincer 300g de riz basmati jusqu'à ce que l'eau soit claire. Faire tremper 30 minutes. Dans une casserole, faire chauffer 1 cuillère à soupe de ghee, ajouter 4 grains de cardamome, 2 clous de girofle, 1 bâton de cannelle et 1 feuille de laurier. Ajouter le riz égoutté et faire revenir 1 minute. Verser 600ml d'eau et 1 cuillère à café de sel. Porter à ébullition, puis réduire à feu doux et couvrir. Cuire 15 minutes. Retirer du feu et laisser reposer 5 minutes avant de servir. Pour accompagner, préparer un raita (sauce au yaourt rafraîchissante): mélanger 250g de yaourt avec 1/2 concombre râpé et égoutté, 1 cuillère à café de cumin grillé et moulu, quelques feuilles de menthe ciselées, sel et une pincée de piment en poudre.",
            "shortStory": "Le **Butter Chicken** (Murgh Makhani en hindi) est un plat emblématique de la cuisine indienne du nord, créé dans les années 1950 à Delhi. La légende raconte que Kundan Lal Gujral, le même chef qui a popularisé le poulet tandoori, cherchait une solution pour utiliser les restes de poulet tandoori qui se desséchaient. Il eut l'idée de créer une sauce riche à base de **beurre**, de **tomates** et de **crème** pour réhydrater et revitaliser ces morceaux. Le **fenugrec** séché (kasoori methi) est l'ingrédient secret qui donne au butter chicken sa saveur caractéristique et légèrement amère. Ce plat, initialement considéré comme une solution économique pour éviter le gaspillage alimentaire, est devenu l'un des currys indiens les plus populaires au monde. Contrairement à de nombreux plats indiens, le butter chicken n'est pas particulièrement épicé, ce qui explique en partie son succès international et sa popularité auprès des palais occidentaux.",
            "detailURL": "/fiche-recette/181/poulet-tomate-creme-epices/poulet-butter-chicken.html",
            "time_prepare": "25mn",
            "time_maceration": "4h",
            "time_cooking": "40mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2). La plupart des ingrédients sont disponibles dans les supermarchés, bien que certaines épices comme le fenugrec séché puissent nécessiter une visite dans une épicerie indienne."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette est légèrement épicée (niveau 1), le piment kashmiri apportant davantage de couleur que de chaleur. Elle convient aux palais peu habitués aux épices."
            },
            "calories": "480 kcal",
            "allergens": [
                "produits-laitiers"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne (niveau 2), nécessitant plusieurs étapes mais sans technique particulièrement complexe."
            },
            "equipment_needed": [
                "four",
                "casserole",
                "mixeur-plongeant"
            ]
        }, {
            "id": 182,
            "categorie": "europe",
            "country": "Irlande",
            "flag": "IE",
            "title": "Poulet à la Guinness",
            "subTitle": "Poulet mijoté à la bière stout, aux champignons et aux herbes irlandaises",
            "chicken_piece": [
                "entier",
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en morceaux (ou 8 cuisses de poulet)",
                "2 cuillères à soupe de farine",
                "3 cuillères à soupe d'huile végétale",
                "2 gros oignons, émincés",
                "4 carottes, coupées en rondelles",
                "3 branches de céleri, coupées en tronçons",
                "250g de champignons de Paris, coupés en quartiers",
                "4 gousses d'ail, hachées",
                "2 cuillères à soupe de pâte de tomate",
                "330ml de Guinness (ou autre stout irlandaise)",
                "300ml de bouillon de poulet",
                "2 feuilles de laurier",
                "1 cuillère à soupe de thym frais (ou 1 cuillère à café de thym séché)",
                "1 cuillère à soupe de persil frais, plus un peu pour la garniture",
                "1 cuillère à café de sucre brun",
                "2 cuillères à soupe de moutarde de Dijon",
                "1 cuillère à café de poivre noir moulu",
                "Sel selon le goût"
            ],
            "ingredient_liste": [
                "poulet",
                "farine",
                "huile-vegetale",
                "oignon",
                "carotte",
                "celeri",
                "champignon",
                "ail",
                "pate-tomate",
                "guinness",
                "bouillon-poulet",
                "laurier",
                "thym",
                "persil",
                "sucre-brun",
                "moutarde-dijon",
                "poivre",
                "sel"
            ],
            "preparation": [
                "Saler et poivrer les morceaux de poulet, puis les enrober légèrement de farine.",
                "Dans une grande cocotte, faire chauffer l'huile à feu moyen-vif.",
                "Faire dorer les morceaux de poulet par lots, environ 5 minutes de chaque côté, jusqu'à ce qu'ils soient bien dorés. Réserver.",
                "Dans la même cocotte, ajouter les oignons, les carottes et le céleri. Faire revenir pendant 5 minutes.",
                "Ajouter les champignons et l'ail, poursuivre la cuisson pendant 3 minutes.",
                "Incorporer la pâte de tomate et cuire 1 minute en remuant."
            ],
            "cuisson": [
                "Déglacer la cocotte avec la Guinness, en grattant les sucs de cuisson au fond.",
                "Ajouter le bouillon de poulet, les feuilles de laurier, le thym, la moitié du persil, le sucre brun et la moutarde. Bien mélanger.",
                "Remettre les morceaux de poulet dans la cocotte.",
                "Porter à ébullition, puis réduire le feu, couvrir et laisser mijoter pendant environ 45 minutes à 1 heure, jusqu'à ce que le poulet soit tendre et que la sauce ait légèrement épaissi.",
                "Vérifier l'assaisonnement et ajuster si nécessaire.",
                "Retirer les feuilles de laurier avant de servir.",
                "Saupoudrer du persil frais restant."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte",
                "four"
            ],
            "accompagnement": "Servir avec des pommes de terre colcannon traditionnelles irlandaises et du pain de soude beurré. Pour le colcannon: faire cuire 1kg de pommes de terre en robe des champs. Les peler encore chaudes et les écraser. Faire revenir 200g de chou vert finement émincé et 4 oignons nouveaux hachés dans 50g de beurre pendant 5 minutes. Incorporer aux pommes de terre avec 100ml de crème chaude, sel, poivre et une généreuse pincée de noix de muscade. Servir avec un morceau de beurre qui fond au centre. Pour le pain de soude: mélanger 450g de farine complète, 1 cuillère à café de bicarbonate de soude, 1 cuillère à café de sel et 400ml de babeurre. Former une boule, inciser une croix sur le dessus et cuire 30 minutes à 200°C.",
            "shortStory": "Le **Poulet à la Guinness** est une adaptation moderne des ragoûts traditionnels irlandais, qui utilisaient historiquement plus souvent l'agneau ou le bœuf que la volaille. L'élément distinctif de cette recette est l'utilisation de la célèbre **stout** irlandaise, la Guinness, brassée pour la première fois à Dublin en 1759. La bière apporte une richesse et une profondeur remarquables à la sauce, grâce à ses notes de **malt** torréfié et sa légère amertume. Dans les pubs irlandais traditionnels, particulièrement dans les comtés de Dublin et de Cork, ce type de plat mijoté est souvent servi comme \"pub grub\" (nourriture de pub) réconfortante pendant les mois froids et humides. La tradition culinaire irlandaise met l'accent sur des ingrédients simples et nourrissants, transformés en plats réconfortants qui réchauffent le corps et l'âme. Ce plat illustre parfaitement le concept irlandais de \"**blas**\" (saveur en gaélique), qui célèbre le goût authentique et non déguisé des ingrédients locaux.",
            "detailURL": "/fiche-recette/182/poulet-guinness-champignons-legumes/poulet-a-la-guinness.html",
            "time_prepare": "20mn",
            "time_maceration": "0h",
            "time_cooking": "1h",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2). L'ingrédient le plus spécifique est la Guinness, qui reste facilement accessible dans la plupart des supermarchés."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Cette recette n'est pas épicée (niveau 0), conformément aux traditions culinaires irlandaises qui privilégient les saveurs riches plutôt que piquantes."
            },
            "calories": "420 kcal",
            "allergens": [
                "gluten",
                "moutarde"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne (niveau 2), nécessitant principalement du temps pour mijoter mais sans technique complexe."
            },
            "equipment_needed": [
                "cocotte",
                "couteau-cuisine"
            ]
        },
        {
            "id": 183,
            "categorie": "europe",
            "country": "Irlande",
            "flag": "IE",
            "title": "Poulet au Whiskey et Miel",
            "subTitle": "Poulet glacé au whiskey irlandais, miel local et moutarde à l'ancienne",
            "chicken_piece": [
                "aile",
                "entier",
                "cuisse"
            ],
            "piece": {
                "best": "aile",
                "entier": true,
                "aile": true,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1.5 kg de poulet (ailes, cuisses ou poulet entier découpé)",
                "120ml de whiskey irlandais",
                "80ml de miel irlandais (idéalement de bruyère)",
                "2 cuillères à soupe de moutarde à l'ancienne",
                "2 cuillères à soupe de vinaigre de cidre",
                "3 gousses d'ail, écrasées",
                "2 échalotes, finement hachées",
                "1 cuillère à soupe de sauce Worcestershire",
                "2 cuillères à soupe d'huile d'olive",
                "2 cuillères à café de thym frais haché",
                "1 cuillère à café de romarin frais haché",
                "Zeste et jus d'un citron",
                "1 cuillère à café de sel",
                "1/2 cuillère à café de poivre noir fraîchement moulu"
            ],
            "ingredient_liste": [
                "poulet",
                "whiskey-irlandais",
                "miel",
                "moutarde-ancienne",
                "vinaigre-cidre",
                "ail",
                "echalote",
                "sauce-worcestershire",
                "huile-olive",
                "thym",
                "romarin",
                "citron",
                "sel",
                "poivre"
            ],
            "preparation": [
                "Dans un grand bol, mélanger le whiskey, le miel, la moutarde, le vinaigre de cidre, l'ail, les échalotes, la sauce Worcestershire, l'huile d'olive, le thym, le romarin, le zeste et le jus de citron, le sel et le poivre.",
                "Réserver environ 80ml de marinade pour le glaçage final.",
                "Placer les morceaux de poulet dans un grand sac de congélation ou un récipient peu profond et verser le reste de la marinade dessus.",
                "Bien masser la marinade sur le poulet pour s'assurer qu'il soit complètement enrobé.",
                "Fermer le sac ou couvrir le récipient et réfrigérer pendant au moins 4 heures, idéalement toute une nuit, en retournant occasionnellement."
            ],
            "cuisson": [
                "Préchauffer le four à 190°C (ou préparer le barbecue à feu moyen).",
                "Retirer le poulet de la marinade et égoutter l'excédent (conserver cette marinade).",
                "Disposer les morceaux de poulet sur une plaque de cuisson tapissée de papier aluminium ou une grille de barbecue.",
                "Pour la cuisson au four: cuire pendant environ 35-45 minutes (selon les morceaux), en retournant et en badigeonnant avec la marinade conservée toutes les 15 minutes.",
                "Pour la cuisson au barbecue: griller pendant environ 25-35 minutes, en retournant et en badigeonnant régulièrement.",
                "Pendant les 5 dernières minutes de cuisson, badigeonner généreusement avec la marinade réservée pour le glaçage final.",
                "Le poulet est cuit lorsque le jus qui s'en écoule est clair et que la température interne atteint 75°C.",
                "Laisser reposer le poulet pendant 5 minutes avant de servir.",
                "Facultatif: pour une sauce d'accompagnement, faire réduire la marinade réservée dans une petite casserole jusqu'à ce qu'elle épaississe légèrement, puis servir à côté du poulet."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four",
                "barbecue",
                "grill"
            ],
            "accompagnement": "Servir avec des pommes de terre au beurre à l'aneth et des petits pois à l'irlandaise. Pour les pommes de terre: faire bouillir 1kg de pommes de terre nouvelles jusqu'à tendreté. Les égoutter et les mélanger délicatement avec 50g de beurre fondu, 1 cuillère à soupe d'aneth frais haché, du sel et du poivre. Pour les petits pois à l'irlandaise: dans une casserole, faire revenir 1 petit oignon finement haché dans 30g de beurre. Ajouter 400g de petits pois frais ou surgelés, 100g de lardons cuits, 3 cuillères à soupe de crème fraîche, 1 cuillère à soupe de menthe fraîche ciselée, du sel et du poivre. Cuire à feu doux pendant 5-7 minutes. Accompagner d'un pain de soude beurré traditionnel.",
            "shortStory": "Le **Poulet au Whiskey et Miel** s'inspire des traditions culinaires irlandaises qui utilisent fréquemment les alcools locaux dans la cuisine. Le **whiskey** irlandais (avec un 'e', contrairement au whisky écossais) se distingue par son triple processus de distillation qui lui confère une douceur caractéristique, parfaitement équilibrée par l'acidité du vinaigre de cidre et la douceur du **miel** local. Dans les campagnes irlandaises, particulièrement dans les comtés de Cork et de Kerry, l'apiculture est une tradition ancienne, et le miel de **bruyère** est particulièrement prisé pour sa saveur intense. Ce plat représente parfaitement la cuisine irlandaise contemporaine qui, tout en restant fidèle à ses racines rurales, intègre des techniques modernes et des combinaisons de saveurs plus audacieuses. Cette recette est devenue populaire dans les gastropubs irlandais qui cherchent à revisiter les plats traditionnels avec une touche de sophistication.",
            "detailURL": "/fiche-recette/183/poulet-whiskey-miel-moutarde/poulet-au-whiskey-et-miel.html",
            "time_prepare": "15mn",
            "time_maceration": "4h",
            "time_cooking": "45mn",
            "crispys": true,
            "cost": {
                "level": 3,
                "description": "Cette recette est d'un coût moyen à élevé (niveau 3), principalement en raison du whiskey irlandais de qualité qui représente l'investissement le plus important."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Cette recette n'est pas épicée (niveau 0), offrant plutôt un équilibre entre la douceur du miel, la chaleur subtile du whiskey et la légère acidité de la moutarde."
            },
            "calories": "390 kcal",
            "allergens": [
                "moutarde",
                "sulfites"
            ],
            "difficulty": {
                "level": 1,
                "description": "Recette de difficulté facile (niveau 1), nécessitant principalement une bonne gestion du temps de marinade et de la cuisson pour éviter que le glaçage ne brûle."
            },
            "equipment_needed": [
                "four",
                "barbecue",
                "thermometre-cuisine",
                "pinceau-cuisine"
            ]
        }, {
            "id": 184,
            "categorie": "europe",
            "country": "Italie",
            "flag": "IT",
            "title": "Pollo alla Cacciatora",
            "subTitle": "Poulet à la chasseuse aux herbes, tomates et olives, mijoté au vin blanc",
            "chicken_piece": [
                "entier",
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier d'environ 1,5 kg, découpé en 8 morceaux (ou 8 cuisses de poulet)",
                "3 cuillères à soupe d'huile d'olive extra vierge",
                "2 gros oignons, finement émincés",
                "3 gousses d'ail, écrasées",
                "2 branches de céleri, coupées en petits dés",
                "2 carottes, coupées en petits dés",
                "1 poivron rouge, épépiné et coupé en lanières",
                "150g de champignons de Paris, émincés",
                "2 feuilles de laurier",
                "2 branches de romarin frais",
                "1 cuillère à soupe de thym frais (ou 1 cuillère à café de thym séché)",
                "200ml de vin blanc sec italien (Pinot Grigio ou Verdicchio)",
                "400g de tomates pelées en conserve, concassées",
                "2 cuillères à soupe de concentré de tomate",
                "100g d'olives noires dénoyautées (de préférence Kalamata)",
                "2 cuillères à soupe de câpres, rincées",
                "1 pincée de flocons de piment rouge (facultatif)",
                "Sel et poivre noir fraîchement moulu",
                "2 cuillères à soupe de persil frais haché pour servir"
            ],
            "ingredient_liste": [
                "poulet",
                "huile-olive",
                "oignon",
                "ail",
                "celeri",
                "carotte",
                "poivron-rouge",
                "champignon",
                "laurier",
                "romarin",
                "thym",
                "vin-blanc",
                "tomate-pelee",
                "concentre-tomate",
                "olive-noire",
                "capre",
                "piment-rouge",
                "sel",
                "poivre",
                "persil"
            ],
            "preparation": [
                "Saler et poivrer généreusement les morceaux de poulet.",
                "Dans une grande cocotte ou sauteuse, faire chauffer l'huile d'olive à feu moyen-vif.",
                "Faire dorer les morceaux de poulet sur toutes les faces pendant environ 5 minutes par lot. Réserver.",
                "Dans la même cocotte, faire revenir les oignons à feu moyen pendant 5 minutes jusqu'à ce qu'ils soient translucides.",
                "Ajouter l'ail, le céleri, les carottes et le poivron, poursuivre la cuisson pendant 5 minutes en remuant régulièrement.",
                "Incorporer les champignons et faire revenir 3 minutes supplémentaires."
            ],
            "cuisson": [
                "Ajouter les herbes (laurier, romarin, thym) et les flocons de piment si utilisés, puis déglacer avec le vin blanc. Laisser réduire de moitié pendant environ 5 minutes.",
                "Ajouter les tomates concassées, le concentré de tomate, les olives et les câpres. Bien mélanger.",
                "Remettre les morceaux de poulet dans la cocotte, les enfoncer légèrement dans la sauce.",
                "Porter à ébullition, puis réduire le feu, couvrir et laisser mijoter doucement pendant 40-45 minutes, jusqu'à ce que le poulet soit bien tendre et que la sauce ait légèrement épaissi.",
                "Si la sauce est trop liquide, retirer le couvercle pendant les 10 dernières minutes pour la faire réduire.",
                "Goûter et ajuster l'assaisonnement en sel et poivre si nécessaire.",
                "Retirer les branches de romarin et les feuilles de laurier avant de servir.",
                "Parsemer de persil frais haché au moment de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": true,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte",
                "poele"
            ],
            "accompagnement": "Servir avec de la polenta crémeuse et des légumes verts sautés à l'italienne. Pour la polenta: porter à ébullition 1 litre d'eau salée, y verser en pluie 250g de polenta en remuant constamment. Cuire à feu doux pendant 30-40 minutes (ou 5 minutes pour la polenta instantanée), en remuant régulièrement. En fin de cuisson, incorporer 50g de beurre et 50g de parmesan râpé, sel et poivre. Pour les légumes verts à l'italienne: faire blanchir 500g de broccolini (ou brocoli) pendant 2 minutes dans de l'eau bouillante salée. Égoutter et plonger dans de l'eau glacée pour stopper la cuisson. Dans une poêle, faire revenir 2 gousses d'ail émincées et 1 piment rouge émincé dans 3 cuillères à soupe d'huile d'olive. Ajouter les légumes égouttés, faire sauter 3-4 minutes. Assaisonner de sel, poivre et un filet de jus de citron.",
            "shortStory": "Le **Pollo alla Cacciatora**, ou poulet à la chasseuse, est un plat rustique emblématique de la cuisine italienne, particulièrement populaire en Toscane et en Ombrie. Son nom \"alla cacciatora\" (à la chasseuse) vient de sa préparation traditionnelle par les chasseurs qui utilisaient des ingrédients facilement transportables comme les **herbes** sauvages, l'**ail** et le **vin**. Chaque région d'Italie a sa propre version: au nord, on utilise souvent du vin blanc et des champignons, tandis qu'au sud, on préfère le vin rouge et les **olives**. Ce plat illustre parfaitement la philosophie culinaire italienne du \"poco ma buono\" (peu mais bon), où des ingrédients simples mais de qualité sont transformés en un repas savoureux grâce à une cuisson lente qui développe les arômes. Historiquement, c'était un moyen ingénieux d'attendrir les coqs plus âgés et plus coriaces grâce à la marinade au vin et à la cuisson mijotée. Aujourd'hui, ce classique continue d'être servi dans les trattorie familiales à travers toute l'Italie.",
            "detailURL": "/fiche-recette/184/poulet-tomate-olive-herbe/pollo-alla-cacciatora.html",
            "time_prepare": "25mn",
            "time_maceration": "0h",
            "time_cooking": "1h",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2), utilisant principalement des ingrédients de base de la cuisine italienne facilement disponibles."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette est légèrement épicée (niveau 1) si on ajoute les flocons de piment rouge, mais l'épice peut être complètement omise pour une version plus douce."
            },
            "calories": "410 kcal",
            "allergens": [
                "sulfites"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne (niveau 2), nécessitant principalement du temps pour la préparation des légumes et la cuisson mijotée."
            },
            "equipment_needed": [
                "cocotte",
                "couteau-cuisine"
            ]
        },
        {
            "id": 185,
            "categorie": "europe",
            "country": "Italie",
            "flag": "IT",
            "title": "Pollo al Limone",
            "subTitle": "Poulet mariné au citron, ail et herbes italiennes, rôti avec pommes de terre et artichauts",
            "chicken_piece": [
                "entier",
                "blanc"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "1 poulet entier d'environ 1,5 kg (ou 4 blancs de poulet avec peau)",
                "4 citrons bio",
                "6 gousses d'ail, écrasées",
                "3 branches de romarin frais",
                "1 bouquet de sauge fraîche (environ 10 feuilles)",
                "1 cuillère à soupe de thym frais haché",
                "60ml d'huile d'olive extra vierge, plus un peu pour arroser",
                "2 cuillères à café de zeste de citron râpé",
                "80ml de jus de citron frais",
                "2 cuillères à café de sel",
                "1 cuillère à café de poivre noir fraîchement moulu",
                "500g de pommes de terre nouvelles, coupées en deux",
                "4 artichauts préparés et coupés en quartiers (ou 200g d'artichauts marinés en bocal, égouttés)",
                "1 oignon rouge, coupé en quartiers",
                "120ml de vin blanc sec italien (Pinot Grigio)",
                "250ml de bouillon de poulet",
                "2 cuillères à soupe de câpres, rincées (facultatif)",
                "1 pincée de flocons de piment rouge (facultatif)",
                "2 cuillères à soupe de persil frais haché pour servir"
            ],
            "ingredient_liste": [
                "poulet",
                "citron",
                "ail",
                "romarin",
                "sauge",
                "thym",
                "huile-olive",
                "zeste-citron",
                "jus-citron",
                "sel",
                "poivre",
                "pomme-de-terre",
                "artichaut",
                "oignon-rouge",
                "vin-blanc",
                "bouillon-poulet",
                "capre",
                "piment-rouge",
                "persil"
            ],
            "preparation": [
                "Pour la marinade, mélanger dans un bol le zeste et le jus de citron, 4 gousses d'ail écrasées, le thym, la moitié du romarin et de la sauge finement ciselés, 3 cuillères à soupe d'huile d'olive, 1 cuillère à café de sel et 1/2 cuillère à café de poivre.",
                "Si vous utilisez un poulet entier, le rincer à l'eau froide, le sécher avec du papier absorbant, puis détacher délicatement la peau de la chair sans la déchirer.",
                "Glisser quelques feuilles de sauge et des morceaux d'ail sous la peau du poulet.",
                "Placer le poulet dans un grand sac de congélation ou un plat creux et verser la marinade dessus. Bien masser pour répartir la marinade.",
                "Fermer le sac ou couvrir le plat et réfrigérer pendant au moins 2 heures, idéalement toute une nuit, en retournant occasionnellement."
            ],
            "cuisson": [
                "Préchauffer le four à 190°C.",
                "Dans un grand plat allant au four, disposer les pommes de terre, les artichauts et l'oignon rouge. Arroser avec le reste d'huile d'olive, saupoudrer du reste de sel et de poivre.",
                "Ajouter le reste des gousses d'ail écrasées, les branches de romarin et quelques feuilles de sauge entières.",
                "Placer le poulet sur les légumes, verser le vin blanc et le bouillon autour (pas sur le poulet pour ne pas mouiller la peau).",
                "Couper 2 citrons en quartiers et les disposer autour du poulet. Presser le jus du 3ème citron sur le poulet.",
                "Enfourner pour 70-80 minutes pour un poulet entier (ou 25-30 minutes pour des blancs), jusqu'à ce que la peau soit dorée et croustillante et que la température interne atteigne 75°C.",
                "À mi-cuisson, arroser le poulet avec les jus du plat.",
                "Si la peau dore trop vite, couvrir légèrement de papier aluminium.",
                "Une fois cuit, laisser reposer le poulet 10 minutes avant de le découper.",
                "Pour une sauce facultative: retirer le poulet et les légumes du plat, placer le plat sur le feu et faire réduire le jus de cuisson en ajoutant les câpres. Filtrer si désiré.",
                "Servir le poulet avec les légumes rôtis, arrosé de jus de cuisson et parsemé de persil frais haché."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec une focaccia aux herbes et une salade de roquette à l'italienne. Pour la focaccia: mélanger 500g de farine, 7g de levure sèche, 1 cuillère à café de sel et 320ml d'eau tiède. Pétrir jusqu'à obtenir une pâte souple. Laisser lever 1h. Étaler la pâte dans un plat huilé, creuser des trous avec les doigts, garnir de romarin frais et d'un filet généreux d'huile d'olive. Laisser lever 30 minutes puis cuire à 220°C pendant 20 minutes. Pour la salade: mélanger 100g de roquette, des copeaux de parmesan, des quartiers de tomates cerises et des pignons de pin légèrement torréfiés. Assaisonner avec une vinaigrette simple à l'huile d'olive extra vierge et vinaigre balsamique (3 cuillères à soupe d'huile pour 1 cuillère à soupe de vinaigre), sel et poivre.",
            "shortStory": "Le **Pollo al Limone** est une recette traditionnelle de la cuisine italienne, particulièrement populaire dans les régions côtières comme la Sicile et la Côte amalfitaine, où les **citronniers** abondent. Ce plat met en valeur la simplicité et la fraîcheur caractéristiques de la cuisine italienne. En Sicile, l'association du poulet avec les **agrumes** remonte à la période arabe (IXe-XIe siècles), époque à laquelle les Arabes ont introduit de nombreux fruits et techniques culinaires dans l'île. Les **herbes aromatiques** comme le romarin, la sauge et le thym sont cultivées dans presque tous les jardins italiens et constituent la base aromatique de nombreux plats. Dans la région des Pouilles, on ajoute traditionnellement des **artichauts** locaux, très prisés dans la cuisine méditerranéenne pour leur subtile amertume qui complète parfaitement l'acidité du citron. Cette recette illustre parfaitement le concept italien de \"cucina povera\" (cuisine pauvre), où des ingrédients simples mais frais sont transformés en plats savoureux grâce à des techniques de cuisson respectueuses des produits.",
            "detailURL": "/fiche-recette/185/poulet-citron-herbes-artichaut/pollo-al-limone.html",
            "time_prepare": "25mn",
            "time_maceration": "2h",
            "time_cooking": "1h20",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2), les ingrédients les plus coûteux étant les artichauts frais qui peuvent être remplacés par des artichauts en conserve pour réduire le coût."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Cette recette n'est pas épicée (niveau 0), les flocons de piment étant optionnels et utilisés avec parcimonie."
            },
            "calories": "390 kcal",
            "allergens": [
                "sulfites"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne (niveau 2), la préparation des artichauts frais pouvant être technique pour les débutants, mais le reste de la recette est assez simple."
            },
            "equipment_needed": [
                "four",
                "plat-four",
                "thermometre-cuisine"
            ]
        }, {
            "id": 186,
            "categorie": "asie",
            "country": "Indonésie",
            "flag": "ID",
            "title": "Ayam Bakar",
            "subTitle": "Poulet mariné aux épices indonésiennes et grillé, servi avec sauce kecap manis",
            "chicken_piece": [
                "entier",
                "cuisse",
                "aile"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": true,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en 8 morceaux (ou 8 cuisses ou ailes)",
                "4 échalotes, émincées",
                "5 gousses d'ail, émincées",
                "3 cm de galanga frais (ou gingembre), râpé",
                "3 cm de gingembre frais, râpé",
                "3 cm de curcuma frais (ou 1 cuillère à café de curcuma en poudre), râpé",
                "3 tiges de citronnelle, partie blanche uniquement, émincées",
                "4 feuilles de lime kaffir, nervures centrales retirées",
                "3 cuillères à soupe d'huile de coco",
                "1 cuillère à café de grains de coriandre, moulus",
                "1 cuillère à café de graines de cumin, moulues",
                "3 cuillères à soupe de sucre de palme (ou cassonade)",
                "200ml de lait de coco",
                "3 cuillères à soupe de sauce soja sucrée (kecap manis)",
                "1 cuillère à café de sel",
                "1 cuillère à café de poivre noir moulu",
                "Pour la sauce d'accompagnement:",
                "6 cuillères à soupe de kecap manis (sauce soja sucrée)",
                "1 échalote, finement émincée",
                "1 piment rouge, épépiné et finement émincé",
                "1 cuillère à soupe de jus de lime",
                "1 cuillère à café d'huile"
            ],
            "ingredient_liste": [
                "poulet",
                "echalote",
                "ail",
                "galanga",
                "gingembre",
                "curcuma",
                "citronnelle",
                "feuille-lime-kaffir",
                "huile-coco",
                "coriandre",
                "cumin",
                "sucre-palme",
                "lait-coco",
                "kecap-manis",
                "sel",
                "poivre",
                "piment-rouge",
                "lime"
            ],
            "preparation": [
                "Dans un mixeur, combiner les échalotes, l'ail, le galanga, le gingembre, le curcuma, la citronnelle, les feuilles de lime kaffir, les épices moulues (coriandre et cumin) et l'huile de coco. Mixer jusqu'à obtenir une pâte homogène.",
                "Transférer cette pâte dans une casserole et faire revenir à feu moyen pendant 5 minutes, jusqu'à ce que le mélange soit parfumé.",
                "Ajouter le sucre de palme, le lait de coco, la sauce soja sucrée, le sel et le poivre. Mélanger et laisser mijoter 10 minutes jusqu'à épaississement.",
                "Laisser refroidir la marinade.",
                "Inciser la chair du poulet à plusieurs endroits pour permettre à la marinade de bien pénétrer.",
                "Dans un grand récipient, enrober complètement les morceaux de poulet avec la marinade refroidie.",
                "Couvrir et réfrigérer pendant au moins 3 heures, idéalement toute une nuit."
            ],
            "cuisson": [
                "Sortir le poulet du réfrigérateur 30 minutes avant la cuisson.",
                "Préchauffer le grill, le barbecue ou le four à 180°C.",
                "Pour la cuisson au barbecue ou au grill: cuire les morceaux de poulet pendant environ 30-40 minutes, en les retournant régulièrement et en les badigeonnant avec la marinade restante.",
                "Pour la cuisson au four: disposer les morceaux de poulet sur une grille placée au-dessus d'un plat pour recueillir la marinade. Cuire pendant 40-45 minutes en retournant et en badigeonnant à mi-cuisson.",
                "Le poulet est cuit lorsque le jus qui s'en écoule est clair et que la température interne atteint 75°C.",
                "Pendant les 5 dernières minutes de cuisson, badigeonner généreusement les morceaux avec un peu de kecap manis pour obtenir une belle caramélisation.",
                "Pendant ce temps, préparer la sauce en mélangeant tous les ingrédients de la sauce dans un petit bol.",
                "Servir le poulet chaud avec la sauce kecap manis préparée à part."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "barbecue",
                "grill",
                "four"
            ],
            "accompagnement": "Servir avec du riz blanc parfumé et des légumes sautés sambal. Pour le riz: rincer 400g de riz jasmin jusqu'à ce que l'eau soit claire. Cuire avec 600ml d'eau, une pincée de sel et 2 feuilles de pandanus (si disponible) pendant 12-15 minutes, puis laisser reposer 10 minutes. Pour les légumes sambal: faire revenir 1 oignon émincé, 2 gousses d'ail hachées et 2 piments rouges émincés dans 2 cuillères à soupe d'huile. Ajouter 1 cuillère à soupe de pâte de crevettes (belacan), faire revenir 1 minute. Ajouter 300g de légumes verts mélangés (kangkung/épinards d'eau, haricots verts, carottes en julienne) et faire sauter 3-4 minutes. Ajouter 1 cuillère à soupe de jus de lime, 1 cuillère à café de sucre et du sel. Compléter avec des crackers aux crevettes (kerupuk) pour une touche traditionnelle.",
            "shortStory": "L'**Ayam Bakar**, littéralement \"poulet grillé\" en indonésien, est un plat emblématique présent dans tout l'archipel, des warungs (stands de rue) jusqu'aux tables familiales. Chaque région possède sa propre variation: à Java, la marinade est souvent sucrée avec du **kecap manis** (sauce soja sucrée), tandis qu'à Bali, on utilise davantage de **piments**. L'utilisation d'épices comme le **galanga**, la **citronnelle** et les feuilles de **lime kaffir** reflète l'importance historique de l'Indonésie dans le commerce des épices, notamment à l'époque des Indes orientales néerlandaises. Ces aromates sont pillés dans un mortier traditionnel appelé \"ulekan\" pour créer un \"bumbu\" (mélange d'épices) qui constitue la base de nombreux plats indonésiens. À Java occidental, l'Ayam Bakar est souvent servi avec du sambal terasi (pâte de piments fermentée), ajoutant une dimension supplémentaire de saveur umami au plat. Cette recette témoigne de la richesse culinaire indonésienne, fusion de techniques autochtones et d'influences chinoises, arabes et coloniales.",
            "detailURL": "/fiche-recette/186/poulet-epices-grille-kecap/ayam-bakar.html",
            "time_prepare": "30mn",
            "time_maceration": "3h",
            "time_cooking": "40mn",
            "crispys": true,
            "cost": {
                "level": 3,
                "description": "Cette recette est d'un coût moyen à élevé (niveau 3) principalement en raison des épices spécifiques indonésiennes qui peuvent nécessiter une visite dans une épicerie asiatique spécialisée."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette est moyennement épicée (niveau 2), mais l'intensité peut être ajustée en modifiant la quantité de piments dans la sauce d'accompagnement."
            },
            "calories": "410 kcal",
            "allergens": [
                "soja"
            ],
            "difficulty": {
                "level": 3,
                "description": "Recette de difficulté assez élevée (niveau 3) en raison du nombre d'ingrédients et de la préparation minutieuse de la pâte d'épices, bien que la technique de cuisson reste simple."
            },
            "equipment_needed": [
                "mixeur",
                "barbecue",
                "four",
                "thermometre-cuisine",
                "pinceau-cuisine"
            ]
        }, {
            "id": 187,
            "categorie": "asie",
            "country": "Indonésie",
            "flag": "ID",
            "title": "Ayam Goreng Bumbu Kuning",
            "subTitle": "Poulet mariné aux épices jaunes, frit jusqu'à obtenir une peau croustillante dorée",
            "chicken_piece": [
                "entier",
                "cuisse",
                "aile"
            ],
            "piece": {
                "best": "aile",
                "entier": true,
                "aile": true,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en 8 morceaux (ou 8 cuisses ou ailes)",
                "5 échalotes",
                "4 gousses d'ail",
                "3 cm de gingembre frais",
                "3 cm de curcuma frais (ou 1 cuillère à soupe de curcuma en poudre)",
                "3 noix de candlenut (ou noix de macadamia comme substitut)",
                "2 tiges de citronnelle, partie blanche uniquement, émincées",
                "2 feuilles de lime kaffir",
                "3 feuilles de laurier indonésien (daun salam) ou laurier ordinaire",
                "1 cuillère à café de grains de coriandre moulus",
                "1 cuillère à café de sel",
                "1/2 cuillère à café de poivre blanc moulu",
                "1 cuillère à café de sucre de palme (ou cassonade)",
                "400ml de lait de coco",
                "Huile végétale pour friture (environ 750ml)",
                "Pour le condiment croustillant (serundeng):",
                "100g de noix de coco râpée non sucrée",
                "1 gousse d'ail, émincée",
                "1 échalote, émincée",
                "1 feuille de lime kaffir, finement ciselée",
                "1 cm de galanga, râpé",
                "1 tige de citronnelle, émincée",
                "1 cuillère à café de sucre de palme",
                "1/2 cuillère à café de sel"
            ],
            "ingredient_liste": [
                "poulet",
                "echalote",
                "ail",
                "gingembre",
                "curcuma",
                "candlenut",
                "citronnelle",
                "feuille-lime-kaffir",
                "feuille-laurier",
                "coriandre",
                "sel",
                "poivre-blanc",
                "sucre-palme",
                "lait-coco",
                "huile-vegetale",
                "noix-coco",
                "galanga"
            ],
            "preparation": [
                "Dans un mixeur, combiner les échalotes, l'ail, le gingembre, le curcuma, les noix de candlenut, la citronnelle, les grains de coriandre moulus, le sel, le poivre blanc et le sucre de palme. Mixer jusqu'à obtenir une pâte homogène.",
                "Transférer cette pâte dans une grande casserole, ajouter le lait de coco, les feuilles de lime kaffir et les feuilles de laurier. Porter à ébullition.",
                "Ajouter les morceaux de poulet, réduire à feu doux, couvrir et laisser mijoter pendant 30-35 minutes, jusqu'à ce que le poulet soit tendre et que le liquide ait réduit environ de moitié.",
                "Pendant ce temps, préparer le serundeng: dans une poêle sèche, faire griller la noix de coco râpée à feu moyen-doux jusqu'à ce qu'elle soit dorée et parfumée.",
                "Ajouter l'ail, l'échalote, la feuille de lime kaffir, le galanga, la citronnelle, le sucre de palme et le sel. Poursuivre la cuisson en remuant constamment jusqu'à ce que le mélange soit bien doré et croustillant (environ 10 minutes). Réserver.",
                "Une fois le poulet cuit, le retirer de la marinade et le laisser refroidir sur une grille (conserver la marinade).",
                "Laisser sécher le poulet à l'air pendant environ 30 minutes pour obtenir une meilleure texture lors de la friture."
            ],
            "cuisson": [
                "Dans une grande casserole ou un wok, faire chauffer l'huile à 175°C.",
                "Faire frire les morceaux de poulet par lots pendant 3-4 minutes, jusqu'à ce qu'ils soient dorés et croustillants à l'extérieur. (Le poulet étant déjà cuit, cette étape sert uniquement à créer une texture croustillante).",
                "Égoutter sur du papier absorbant.",
                "Facultatif: faire bouillir la marinade restante jusqu'à ce qu'elle épaississe, puis la filtrer pour servir comme sauce.",
                "Servir le poulet frit chaud, saupoudré du serundeng croustillant, avec la sauce à part si désirée."
            ],
            "cooking_methods": {
                "best": "friture",
                "grill": false,
                "plancha": false,
                "friture": true,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "friture",
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz blanc, du sambal terasi et des légumes frais. Pour le sambal terasi: dans un mortier, écraser 10 piments rouges (ajuster selon le goût), 4 échalotes émincées, 2 gousses d'ail, 1 tomate coupée en dés et 1 cuillère à café de pâte de crevettes grillée (terasi/belacan). Une fois la pâte homogène, chauffer 2 cuillères à soupe d'huile dans une poêle et faire revenir le mélange pendant 5 minutes. Ajouter 1 cuillère à café de sucre, 1/2 cuillère à café de sel et 1 cuillère à soupe de jus de lime. Pour les légumes, proposer des tranches de concombre frais, des feuilles de laitue, des tomates en quartiers et des herbes fraîches comme la menthe et la coriandre. Ajouter des kerupuk (crackers aux crevettes) pour compléter le repas de façon traditionnelle.",
            "shortStory": "L'**Ayam Goreng Bumbu Kuning** (poulet frit aux épices jaunes) est un plat populaire originaire de Java, en Indonésie. Le terme \"bumbu kuning\" fait référence au mélange d'épices à base de **curcuma** qui donne sa couleur jaune caractéristique au plat. Cette préparation reflète l'influence des techniques culinaires des marchands arabes et indiens qui ont introduit de nombreuses épices dans l'archipel indonésien dès le VIIIe siècle. La double cuisson (mijotée puis frite) est une méthode traditionnelle qui permet d'infuser les saveurs tout en créant une texture croustillante très appréciée. Le **serundeng**, garniture à base de noix de coco grillée, est un élément typique de la cuisine javanaise qui ajoute une dimension de texture et de saveur. Les **candlenuts** (kemiri) sont utilisées comme épaississant naturel dans de nombreuses préparations indonésiennes. Ce plat est souvent préparé pour les célébrations familiales comme les mariages et autres événements festifs. Il représente parfaitement l'équilibre des saveurs recherché dans la cuisine indonésienne: épicé, sucré, salé et umami.",
            "detailURL": "/fiche-recette/187/poulet-epices-jaunes-frit/ayam-goreng-bumbu-kuning.html",
            "time_prepare": "30mn",
            "time_maceration": "0h",
            "time_cooking": "1h15",
            "crispys": true,
            "cost": {
                "level": 3,
                "description": "Cette recette est d'un coût moyen à élevé (niveau 3) en raison des nombreuses épices spécifiques indonésiennes qui peuvent nécessiter une visite dans une épicerie asiatique spécialisée."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette est légèrement épicée (niveau 1) dans sa version de base, mais peut devenir plus épicée selon la quantité de sambal servie en accompagnement."
            },
            "calories": "450 kcal",
            "allergens": [
                "fruits-a-coque"
            ],
            "difficulty": {
                "level": 3,
                "description": "Recette de difficulté assez élevée (niveau 3) en raison du nombre d'étapes, de la préparation des épices et de la technique de friture qui demande de l'attention."
            },
            "equipment_needed": [
                "mixeur",
                "casserole",
                "wok",
                "thermometre-cuisine"
            ]
        }, {
            "id": 188,
            "categorie": "europe",
            "country": "Islande",
            "flag": "IS",
            "title": "Poulet au Skyr et Herbes Nordiques",
            "subTitle": "Poulet mariné au skyr islandais, aromatisé aux herbes sauvages et baies nordiques",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "4 blancs de poulet (ou 6 cuisses désossées)",
                "250g de skyr nature (ou yaourt grec épais)",
                "2 cuillères à soupe d'huile de colza",
                "3 gousses d'ail, finement hachées",
                "2 cuillères à soupe d'aneth frais, haché",
                "1 cuillère à soupe de thym frais (ou 1 cuillère à café de thym séché)",
                "1 cuillère à café de genièvre concassé (facultatif)",
                "2 cuillères à soupe de baies de myrtilles sauvages (fraîches ou surgelées)",
                "2 cuillères à soupe de baies d'airelles rouges (ou canneberges)",
                "Zeste et jus d'un citron",
                "1 cuillère à soupe de miel",
                "2 cuillères à café de sel",
                "1 cuillère à café de poivre noir fraîchement moulu",
                "1 pincée de piment de Cayenne (facultatif)",
                "Pour la sauce:",
                "Le reste de la marinade",
                "100ml de bouillon de poulet",
                "2 cuillères à soupe de crème fraîche épaisse",
                "1 cuillère à café de moutarde de Dijon",
                "1 cuillère à soupe d'aneth frais supplémentaire pour garnir"
            ],
            "ingredient_liste": [
                "poulet",
                "skyr",
                "huile-colza",
                "ail",
                "aneth",
                "thym",
                "genievre",
                "myrtille",
                "airelle",
                "citron",
                "miel",
                "sel",
                "poivre",
                "piment-cayenne",
                "bouillon-poulet",
                "creme-fraiche",
                "moutarde-dijon"
            ],
            "preparation": [
                "Dans un bol, mélanger le skyr, l'huile de colza, l'ail, l'aneth, le thym, le genièvre (si utilisé), le zeste et le jus de citron, le miel, le sel, le poivre et le piment de Cayenne (si utilisé).",
                "Écraser légèrement la moitié des myrtilles et des airelles et les incorporer à la marinade. Réserver l'autre moitié des baies pour plus tard.",
                "Placer les morceaux de poulet dans un sac de congélation ou un récipient peu profond et verser la marinade dessus.",
                "Bien masser la marinade sur le poulet pour l'enrober complètement.",
                "Fermer le sac ou couvrir le récipient et réfrigérer pendant au moins 4 heures, idéalement toute une nuit."
            ],
            "cuisson": [
                "Préchauffer le four à 180°C.",
                "Retirer le poulet de la marinade, en conservant cette dernière pour la sauce.",
                "Placer les morceaux de poulet dans un plat allant au four.",
                "Cuire pendant 25-30 minutes (pour les blancs) ou 35-40 minutes (pour les cuisses), jusqu'à ce que le poulet soit bien cuit et que le jus qui s'en écoule soit clair.",
                "Pendant ce temps, préparer la sauce: filtrer la marinade réservée dans une petite casserole.",
                "Ajouter le bouillon de poulet et porter à ébullition. Réduire le feu et laisser mijoter pendant 5 minutes.",
                "Incorporer la crème fraîche et la moutarde, puis ajouter le reste des baies. Poursuivre la cuisson à feu doux pendant 3-4 minutes, jusqu'à ce que la sauce épaississe légèrement.",
                "Servir le poulet chaud, nappé de sauce et garni d'aneth frais haché."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": true,
                "cocotte": false
            },
            "mode_cuisson": [
                "four",
                "poele"
            ],
            "accompagnement": "Servir avec des pommes de terre rôties à l'aneth et une salade de chou rouge typiquement nordique. Pour les pommes de terre: préchauffer le four à 200°C. Couper 800g de pommes de terre nouvelles en deux, les mélanger avec 3 cuillères à soupe d'huile de colza, 1 cuillère à soupe d'aneth haché, 1 cuillère à café de sel et du poivre. Disposer sur une plaque de cuisson et rôtir pendant 30-35 minutes jusqu'à ce qu'elles soient dorées et tendres. Pour la salade de chou rouge: mélanger 250g de chou rouge finement émincé avec 1 pomme coupée en julienne, 50g de canneberges séchées et 2 cuillères à soupe de graines de carvi légèrement grillées. Préparer une vinaigrette avec 3 cuillères à soupe d'huile de colza, 1 cuillère à soupe de vinaigre de cidre, 1 cuillère à café de miel, du sel et du poivre. Mélanger et laisser mariner au moins 30 minutes avant de servir.",
            "shortStory": "Le **Poulet au Skyr et Herbes Nordiques** s'inspire des traditions culinaires islandaises qui mettent l'accent sur les produits locaux et les techniques de conservation. Le **skyr**, produit laitier traditionnel islandais similaire au yaourt mais techniquement un fromage frais, est consommé depuis l'époque des premiers colons vikings au IXe siècle. Il est particulièrement riche en protéines et pauvre en matières grasses. Les **baies nordiques** comme les myrtilles et les airelles sont cueillies dans les landes islandaises pendant les courts mois d'été et traditionnellement conservées pour l'hiver. L'**aneth** est l'une des rares herbes qui poussent facilement dans le climat rigoureux de l'Islande et est donc très présente dans la cuisine locale. Ce plat moderne s'inspire des méthodes traditionnelles de conservation des aliments tout en les adaptant aux goûts contemporains. Il reflète la tendance actuelle de la cuisine nordique à redécouvrir les ingrédients autochtones et les saveurs emblématiques de la région, tout en les présentant de manière innovante.",
            "detailURL": "/fiche-recette/188/poulet-skyr-herbes-baies/poulet-au-skyr-et-herbes-nordiques.html",
            "time_prepare": "20mn",
            "time_maceration": "4h",
            "time_cooking": "30mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2). Le skyr peut être remplacé par du yaourt grec, plus facile à trouver, et les baies sauvages par des variétés cultivées plus accessibles."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Cette recette n'est pas épicée (niveau 0), conformément aux traditions culinaires nordiques qui privilégient les saveurs douces et herbacées plutôt que piquantes."
            },
            "calories": "320 kcal",
            "allergens": [
                "produits-laitiers",
                "moutarde"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne (niveau 2), nécessitant principalement du temps pour la marinade mais avec des techniques de cuisson simples."
            },
            "equipment_needed": [
                "four",
                "casserole"
            ]
        },
        {
            "id": 189,
            "categorie": "europe",
            "country": "Islande",
            "flag": "IS",
            "title": "Kjúklingur með Bjór og Reykt Salt",
            "subTitle": "Poulet à la bière islandaise et sel fumé, inspiré des traditions vikings",
            "chicken_piece": [
                "entier",
                "cuisse"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier d'environ 1,5 kg (ou 8 cuisses)",
                "330ml de bière blonde islandaise (comme la Gull ou Einstök, ou toute bière blonde légère)",
                "2 cuillères à soupe de miel",
                "3 cuillères à soupe d'huile de colza",
                "4 gousses d'ail, écrasées",
                "2 échalotes, finement hachées",
                "1 cuillère à soupe de graines de carvi, légèrement écrasées",
                "1 cuillère à café de genièvre, écrasé (facultatif)",
                "1 cuillère à soupe de thym frais (ou 1 cuillère à café de thym séché)",
                "1 cuillère à soupe d'aneth frais, haché",
                "Zeste et jus d'un citron",
                "2 cuillères à café de sel fumé islandais (ou sel fumé ordinaire)",
                "1 cuillère à café de poivre noir fraîchement moulu",
                "2 branches de céleri, coupées en morceaux",
                "2 carottes, coupées en gros morceaux",
                "1 oignon, coupé en quartiers"
            ],
            "ingredient_liste": [
                "poulet",
                "biere-blonde",
                "miel",
                "huile-colza",
                "ail",
                "echalote",
                "carvi",
                "genievre",
                "thym",
                "aneth",
                "citron",
                "sel-fume",
                "poivre",
                "celeri",
                "carotte",
                "oignon"
            ],
            "preparation": [
                "Dans un bol, mélanger la bière, le miel, l'huile de colza, l'ail, les échalotes, les graines de carvi, le genièvre (si utilisé), le thym, l'aneth, le zeste et le jus de citron, la moitié du sel fumé et le poivre.",
                "Si vous utilisez un poulet entier, frotter l'intérieur de la cavité avec un peu de sel fumé. Si vous utilisez des cuisses, les frotter légèrement avec du sel.",
                "Placer le poulet dans un grand sac de congélation ou un récipient peu profond et verser la marinade dessus.",
                "Bien masser la marinade sur toute la surface du poulet.",
                "Fermer le sac ou couvrir le récipient et réfrigérer pendant au moins 3 heures, idéalement toute une nuit, en retournant occasionnellement."
            ],
            "cuisson": [
                "Préchauffer le four à 190°C.",
                "Dans un grand plat allant au four, disposer le céleri, les carottes et l'oignon pour former un lit.",
                "Retirer le poulet de la marinade (conserver cette dernière) et le placer sur le lit de légumes.",
                "Saupoudrer le poulet avec le reste du sel fumé.",
                "Verser environ la moitié de la marinade dans le plat.",
                "Pour un poulet entier: cuire au four pendant environ 1h15-1h30, en arrosant toutes les 20 minutes avec le jus de cuisson, jusqu'à ce que le poulet soit bien doré et que sa température interne atteigne 75°C.",
                "Pour des cuisses: cuire au four pendant environ 45-50 minutes, en arrosant toutes les 15 minutes.",
                "Si le poulet dore trop rapidement, le couvrir légèrement de papier aluminium.",
                "Pendant ce temps, faire réduire le reste de la marinade dans une petite casserole à feu moyen pendant environ 10 minutes, jusqu'à ce qu'elle épaississe légèrement. Cette sauce servira d'accompagnement.",
                "Une fois cuit, laisser reposer le poulet pendant 10 minutes avant de le découper.",
                "Servir le poulet avec les légumes rôtis et la sauce à part."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "four",
                "cocotte"
            ],
            "accompagnement": "Servir avec des pommes de terre rugbrauð et des légumes racines rôtis à l'islandaise. Pour les pommes de terre rugbrauð: préchauffer le four à 200°C. Cuire 800g de pommes de terre en robe des champs pendant 20 minutes jusqu'à ce qu'elles soient presque tendres. Les écraser légèrement avec le dos d'une cuillère en bois. Les mélanger avec 2 cuillères à soupe d'huile de colza, 1 cuillère à café de sel fumé, 1 cuillère à café de graines de carvi et du poivre. Cuire au four pendant 20 minutes jusqu'à ce qu'elles soient croustillantes. Pour les légumes racines: mélanger 300g de panais, 300g de rutabagas et 200g de betteraves (tous coupés en cubes) avec 3 cuillères à soupe d'huile de colza, 1 cuillère à soupe de miel, 1 cuillère à café de thym séché, du sel et du poivre. Rôtir au four à 190°C pendant 40 minutes, en remuant à mi-cuisson.",
            "shortStory": "Le **Kjúklingur með Bjór og Reykt Salt** (poulet à la bière et sel fumé) s'inspire des traditions culinaires vikings qui ont façonné la cuisine islandaise. Le **sel fumé** islandais, obtenu par évaporation de l'eau de mer sur des feux de bois de bouleau, était essentiel à la conservation des aliments dans ce pays au climat rigoureux. La **bière**, interdite en Islande de 1915 à 1989, connaît aujourd'hui un véritable renouveau avec de nombreuses microbrasseries locales qui réinterprètent les recettes traditionnelles. Les **graines de carvi** sont l'une des rares épices qui poussent naturellement en Islande et sont utilisées depuis l'époque des premiers colons. Cette recette rend hommage à la méthode de cuisson traditionnelle appelée \"**hverabrauð**\" qui utilisait la chaleur géothermique naturelle d'Islande pour cuire lentement les aliments. Dans les fermes isolées du nord de l'Islande, particulièrement dans la région de Mývatn, cette technique de cuisson lente combinée à l'utilisation d'ingrédients fumés permettait de développer des saveurs riches et complexes malgré la simplicité des ingrédients disponibles.",
            "detailURL": "/fiche-recette/189/poulet-biere-sel-fume-carvi/kjuklingur-med-bjor-og-reykt-salt.html",
            "time_prepare": "20mn",
            "time_maceration": "3h",
            "time_cooking": "1h30",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2), le sel fumé étant l'ingrédient le plus spécifique mais pouvant être remplacé par du sel ordinaire avec une goutte de fumée liquide."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Cette recette n'est pas épicée (niveau 0), reflétant les préférences gustatives traditionnelles islandaises qui privilégient les saveurs fumées et herbacées."
            },
            "calories": "380 kcal",
            "allergens": [
                "gluten"
            ],
            "difficulty": {
                "level": 1,
                "description": "Recette de difficulté facile (niveau 1), ne nécessitant pas de techniques particulières mais simplement une bonne gestion du temps de marinade et de cuisson."
            },
            "equipment_needed": [
                "four",
                "plat-four",
                "thermometre-cuisine"
            ]
        },
        {
            "id": 190,
            "categorie": "moyen-orient",
            "country": "Israël",
            "flag": "IL",
            "title": "Poulet Shawarma",
            "subTitle": "Poulet mariné aux épices moyen-orientales, servi en sandwich ou sur assiette",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 kg de cuisses de poulet désossées (ou blancs de poulet)",
                "3 cuillères à soupe d'huile d'olive",
                "3 gousses d'ail, finement hachées",
                "1 cuillère à soupe de jus de citron frais",
                "1 cuillère à soupe de cumin moulu",
                "1 cuillère à soupe de paprika",
                "1 cuillère à café de coriandre moulue",
                "1 cuillère à café de cardamome moulue",
                "1 cuillère à café de curcuma",
                "1/2 cuillère à café de cannelle moulue",
                "1/2 cuillère à café de piment d'Alep (ou piment rouge doux)",
                "1/4 cuillère à café de clou de girofle moulu",
                "1/4 cuillère à café de noix de muscade râpée",
                "1 cuillère à café de sel",
                "1/2 cuillère à café de poivre noir moulu",
                "1 cuillère à café de sucre (pour favoriser la caramélisation)",
                "1 oignon, émincé",
                "2 cuillères à soupe de persil frais haché pour garnir",
                "Quartiers de citron pour servir"
            ],
            "ingredient_liste": [
                "poulet",
                "huile-olive",
                "ail",
                "citron",
                "cumin",
                "paprika",
                "coriandre",
                "cardamome",
                "curcuma",
                "cannelle",
                "piment-alep",
                "clou-girofle",
                "muscade",
                "sel",
                "poivre",
                "sucre",
                "oignon",
                "persil"
            ],
            "preparation": [
                "Couper le poulet en lanières d'environ 2 cm d'épaisseur.",
                "Dans un grand bol, mélanger l'huile d'olive, l'ail, le jus de citron et toutes les épices (cumin, paprika, coriandre, cardamome, curcuma, cannelle, piment, clou de girofle, muscade), ainsi que le sel, le poivre et le sucre.",
                "Ajouter les lanières de poulet et bien les enrober de marinade.",
                "Couvrir le bol et réfrigérer pendant au moins 3 heures, idéalement toute une nuit, pour que les saveurs se développent pleinement."
            ],
            "cuisson": [
                "Préchauffer le four à 220°C (ou préparer un grill ou une plancha à feu vif).",
                "Étaler les oignons émincés sur une plaque de cuisson recouverte de papier sulfurisé.",
                "Disposer les lanières de poulet marinées sur les oignons en une seule couche.",
                "Pour la cuisson au four: cuire pendant 25-30 minutes, en retournant à mi-cuisson, jusqu'à ce que le poulet soit bien doré et légèrement croustillant sur les bords.",
                "Pour la cuisson au grill ou à la plancha: faire griller 3-4 minutes de chaque côté, jusqu'à ce que le poulet soit bien cuit et légèrement caramélisé.",
                "Laisser reposer le poulet pendant 5 minutes avant de le découper en morceaux plus petits si nécessaire.",
                "Garnir de persil frais haché et servir avec des quartiers de citron."
            ],
            "cooking_methods": {
                "best": "plancha",
                "grill": true,
                "plancha": true,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "plancha",
                "grill",
                "four"
            ],
            "accompagnement": "Servir avec du pain pita, du tahini et des légumes frais pour en faire un sandwich complet, ou avec du riz et une salade israélienne pour un repas sur assiette. Pour le tahini: mélanger 100g de tahini (purée de sésame) avec le jus d'un citron, 1 gousse d'ail écrasée, 2-3 cuillères à soupe d'eau froide, du sel et du poivre jusqu'à obtenir une consistance lisse et crémeuse. Pour la salade israélienne: couper en petits dés 4 tomates, 2 concombres, 1 poivron vert et 1 oignon rouge. Ajouter 1/4 tasse de persil frais haché et 1/4 tasse de menthe fraîche ciselée. Assaisonner avec 3 cuillères à soupe d'huile d'olive, le jus d'un citron, du sel, du poivre et une pincée de sumac. En accompagnement traditionnel, préparer également des cornichons à l'aneth, des olives, du houmous et du labneh (yaourt égoutté assaisonné d'huile d'olive, de zaatar et d'ail).",
            "shortStory": "Le **Poulet Shawarma** est un plat emblématique de la cuisine israélienne moderne, bien qu'il trouve ses origines dans la cuisine ottomane et levantine. Son nom dérive du turc \"çevirme\" qui signifie \"tourner\", faisant référence à la méthode traditionnelle de cuisson sur une broche verticale où la viande est découpée en fines tranches au fur et à mesure qu'elle rôtit. Introduit par les communautés arabes et juives orientales (mizrahim), le shawarma est devenu un incontournable des rues de Tel-Aviv et Jérusalem. Le mélange d'**épices** utilisé reflète le carrefour culturel qu'est Israël, avec des influences arabes, persanes, nord-africaines et méditerranéennes. Traditionnellement servi dans un **pain pita** avec des condiments comme le tahini, les cornichons et les légumes frais, il représente parfaitement la cuisine de rue israélienne. Dans les années 1960, alors que l'État d'Israël cherchait à forger une identité nationale, ces plats de rue cosmopolites sont devenus un symbole de la nouvelle cuisine israélienne, fusion de traditions séfarades, ashkénazes et arabes.",
            "detailURL": "/fiche-recette/190/poulet-epices-moyen-orient-pita/poulet-shawarma.html",
            "time_prepare": "15mn",
            "time_maceration": "3h",
            "time_cooking": "30mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2), la plupart des épices étant désormais couramment disponibles dans les supermarchés."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette est légèrement épicée (niveau 1) avec le piment d'Alep qui apporte davantage de saveur que de chaleur. L'intensité peut être ajustée selon les préférences."
            },
            "calories": "360 kcal",
            "allergens": [],
            "difficulty": {
                "level": 1,
                "description": "Recette de difficulté facile (niveau 1), ne nécessitant que la préparation d'une marinade simple et une cuisson directe."
            },
            "equipment_needed": [
                "four",
                "plancha",
                "couteau-cuisine"
            ]
        },
        {
            "id": 191,
            "categorie": "moyen-orient",
            "country": "Israël",
            "flag": "IL",
            "title": "Poulet Mafrum aux Pruneaux",
            "subTitle": "Poulet mijoté dans une sauce aux pruneaux, abricots secs et épices judéo-berbères",
            "chicken_piece": [
                "entier",
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier coupé en 8 morceaux (ou 8 cuisses de poulet)",
                "3 cuillères à soupe d'huile d'olive",
                "2 gros oignons, émincés",
                "4 gousses d'ail, hachées",
                "2 bâtons de cannelle",
                "4 clous de girofle entiers",
                "1 cuillère à café de cumin moulu",
                "1 cuillère à café de coriandre moulue",
                "1/2 cuillère à café de curcuma",
                "1/2 cuillère à café de gingembre moulu",
                "1/4 cuillère à café de noix de muscade râpée",
                "1 pincée de safran (facultatif)",
                "150g de pruneaux dénoyautés",
                "100g d'abricots secs, coupés en deux",
                "50g d'amandes mondées",
                "2 cuillères à soupe de miel",
                "Le jus et le zeste d'une orange",
                "Le jus d'un demi-citron",
                "500ml de bouillon de poulet",
                "1 cuillère à café de sel",
                "1/2 cuillère à café de poivre noir moulu",
                "2 cuillères à soupe de coriandre fraîche hachée pour garnir",
                "2 cuillères à soupe d'amandes effilées grillées pour garnir"
            ],
            "ingredient_liste": [
                "poulet",
                "huile-olive",
                "oignon",
                "ail",
                "cannelle",
                "clou-girofle",
                "cumin",
                "coriandre",
                "curcuma",
                "gingembre",
                "muscade",
                "safran",
                "pruneau",
                "abricot-sec",
                "amande",
                "miel",
                "orange",
                "citron",
                "bouillon-poulet",
                "sel",
                "poivre",
                "coriandre-fraiche"
            ],
            "preparation": [
                "Saler et poivrer les morceaux de poulet.",
                "Dans une grande cocotte, faire chauffer l'huile d'olive à feu moyen-vif.",
                "Faire dorer les morceaux de poulet par lots, environ 3-4 minutes de chaque côté, jusqu'à ce qu'ils soient bien dorés. Réserver.",
                "Dans la même cocotte, faire revenir les oignons à feu moyen jusqu'à ce qu'ils soient translucides (environ 5 minutes).",
                "Ajouter l'ail et faire revenir 1 minute supplémentaire.",
                "Incorporer toutes les épices (cannelle, clous de girofle, cumin, coriandre, curcuma, gingembre, muscade, safran) et faire revenir 1 minute jusqu'à ce qu'elles soient parfumées."
            ],
            "cuisson": [
                "Ajouter les pruneaux, les abricots secs et les amandes mondées. Mélanger pour les enrober d'épices.",
                "Verser le miel, le jus et le zeste d'orange, le jus de citron et le bouillon de poulet. Bien mélanger.",
                "Remettre les morceaux de poulet dans la cocotte, les enfoncer légèrement dans la sauce.",
                "Porter à ébullition, puis réduire le feu, couvrir et laisser mijoter doucement pendant 45-50 minutes, jusqu'à ce que le poulet soit tendre et que la sauce ait légèrement épaissi.",
                "Si la sauce est trop liquide, retirer le couvercle pendant les 10 dernières minutes pour la faire réduire.",
                "Goûter et ajuster l'assaisonnement si nécessaire.",
                "Servir le poulet nappé de sauce, garni de coriandre fraîche hachée et d'amandes effilées grillées."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du couscous aux pois chiches et un mélange de légumes rôtis à l'israélienne. Pour le couscous: préparer 400g de couscous selon les instructions du paquet. Mélanger avec 1 boîte de pois chiches égouttés et rincés, 2 cuillères à soupe d'huile d'olive, 2 cuillères à soupe de persil haché, 1 cuillère à soupe de menthe hachée, le jus d'un demi-citron, du sel et du poivre. Pour les légumes rôtis: préchauffer le four à 200°C. Mélanger 2 aubergines coupées en cubes, 2 courgettes coupées en rondelles épaisses, 2 poivrons coupés en lanières et 1 oignon rouge coupé en quartiers avec 3 cuillères à soupe d'huile d'olive, 2 gousses d'ail écrasées, 1 cuillère à café de zaatar, du sel et du poivre. Étaler sur une plaque de cuisson et rôtir pendant 25-30 minutes jusqu'à ce que les légumes soient tendres et légèrement caramélisés.",
            "shortStory": "Le **Poulet Mafrum aux Pruneaux** est inspiré de la cuisine juive nord-africaine, particulièrement des traditions culinaires des juifs tunisiens et libyens qui ont émigré en Israël après la création de l'État en 1948. Le terme \"mafrum\" désigne traditionnellement un plat de légumes farcis, mais s'est étendu à diverses préparations mijotées aux **fruits secs** caractéristiques de la cuisine séfarade nord-africaine. L'association de viande et de fruits séchés comme les **pruneaux** et les **abricots** est typique de la cuisine juive festive, notamment pour Rosh Hashana (le Nouvel An juif) où les aliments sucrés symbolisent le souhait d'une année douce et prospère. Les épices utilisées reflètent l'héritage berbère et l'influence des marchands d'épices qui parcouraient la route de la soie. Dans les années 1950, alors que l'État d'Israël accueillait des immigrants juifs du monde entier, ces recettes familiales sont devenues partie intégrante du patrimoine culinaire israélien. Ce plat est particulièrement populaire dans les foyers juifs séfarades pendant Shabbat, car il peut être préparé à l'avance et se bonifie en reposant.",
            "detailURL": "/fiche-recette/191/poulet-pruneaux-abricots-epices/poulet-mafrum-aux-pruneaux.html",
            "time_prepare": "20mn",
            "time_maceration": "0h",
            "time_cooking": "1h",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2). Les fruits secs représentent l'investissement le plus important, mais les quantités utilisées restent raisonnables."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Cette recette n'est pas épicée (niveau 0), offrant plutôt un équilibre entre les saveurs douces des fruits et l'arôme des épices."
            },
            "calories": "420 kcal",
            "allergens": [
                "fruits-a-coque"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne (niveau 2), nécessitant principalement de la patience pour le mijotage mais sans technique particulièrement complexe."
            },
            "equipment_needed": [
                "cocotte",
                "couteau-cuisine"
            ]
        }, {
            "id": 192,
            "categorie": "moyen-orient",
            "country": "Iraq",
            "flag": "IQ",
            "title": "Djaj Bil Timen",
            "subTitle": "Poulet irakien aux épices et riz ambré, enrichi de fruits secs et noix",
            "chicken_piece": [
                "entier",
                "cuisse"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier d'environ 1,5 kg (ou 8 cuisses de poulet)",
                "4 cuillères à soupe d'huile d'olive",
                "2 oignons, finement émincés",
                "4 gousses d'ail, écrasées",
                "1 cuillère à soupe de pâte de tomate",
                "1 cuillère à café de curcuma moulu",
                "1 cuillère à café de cannelle moulue",
                "1 cuillère à café de cardamome moulue",
                "1 cuillère à café de coriandre moulue",
                "1/2 cuillère à café de clou de girofle moulu",
                "1/4 cuillère à café de poivre de Cayenne",
                "1 litre de bouillon de poulet",
                "500g de riz basmati, rincé et trempé pendant 30 minutes",
                "100g de raisins secs dorés",
                "100g d'amandes mondées, légèrement grillées",
                "50g de pistaches non salées, décortiquées",
                "50g de pignons de pin, légèrement grillés",
                "1 bâton de cannelle entier",
                "4 cardamomes vertes entières, légèrement écrasées",
                "4 clous de girofle entiers",
                "2 feuilles de laurier",
                "1 cuillère à café de sel (plus si nécessaire)",
                "1/2 cuillère à café de poivre noir moulu",
                "1 pincée de safran infusé dans 2 cuillères à soupe d'eau chaude (ou colorant alimentaire jaune)",
                "2 cuillères à soupe de persil frais haché pour garnir"
            ],
            "ingredient_liste": [
                "poulet",
                "huile-olive",
                "oignon",
                "ail",
                "pate-tomate",
                "curcuma",
                "cannelle",
                "cardamome",
                "coriandre",
                "clou-girofle",
                "poivre-cayenne",
                "bouillon-poulet",
                "riz-basmati",
                "raisin-sec",
                "amande",
                "pistache",
                "pignon-pin",
                "baton-cannelle",
                "cardamome-verte",
                "laurier",
                "sel",
                "poivre",
                "safran",
                "persil"
            ],
            "preparation": [
                "Si vous utilisez un poulet entier, le couper en 8 morceaux et retirer la peau si désiré.",
                "Saler et poivrer les morceaux de poulet.",
                "Dans une grande cocotte, faire chauffer 2 cuillères à soupe d'huile d'olive à feu moyen-vif.",
                "Faire dorer les morceaux de poulet sur toutes les faces, environ 3-4 minutes par côté. Réserver.",
                "Dans la même cocotte, ajouter le reste de l'huile et faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter l'ail et faire revenir 1 minute supplémentaire.",
                "Incorporer la pâte de tomate et toutes les épices moulues (curcuma, cannelle, cardamome, coriandre, clou de girofle, poivre de Cayenne). Faire revenir 1 minute."
            ],
            "cuisson": [
                "Ajouter le bouillon de poulet et porter à ébullition.",
                "Remettre les morceaux de poulet dans la cocotte, réduire le feu, couvrir et laisser mijoter pendant 30 minutes, jusqu'à ce que le poulet soit presque cuit.",
                "Retirer les morceaux de poulet et les réserver au chaud.",
                "Égoutter le riz préalablement trempé et l'ajouter dans la cocotte avec le bâton de cannelle, les cardamomes vertes, les clous de girofle entiers et les feuilles de laurier.",
                "Porter à ébullition, puis réduire le feu, couvrir et laisser cuire à feu doux pendant 15 minutes.",
                "Ajouter la moitié des raisins secs, des amandes, des pistaches et des pignons de pin au riz. Mélanger délicatement.",
                "Disposer les morceaux de poulet sur le riz, verser le safran infusé sur le dessus.",
                "Couvrir à nouveau et poursuivre la cuisson pendant 10 minutes, jusqu'à ce que le riz soit tendre et ait absorbé tout le liquide.",
                "Laisser reposer hors du feu pendant 10 minutes, toujours couvert.",
                "Servir dans un grand plat, en disposant le poulet sur le riz, et en garnissant avec le reste des fruits secs et des noix, ainsi que le persil haché."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec un yogourt à la menthe (laban bil na'na) et une salade de tomates et concombres. Pour le yogourt à la menthe: mélanger 500g de yaourt grec avec 3 cuillères à soupe de menthe fraîche ciselée, 1 gousse d'ail écrasée, 1 pincée de sel et 1 filet d'huile d'olive. Pour la salade: couper 3 tomates et 2 concombres en dés, ajouter 1/2 oignon rouge finement émincé, 1/4 tasse de persil frais haché, 2 cuillères à soupe d'huile d'olive, le jus d'un citron, du sel et du poivre. Accompagner également de pain plat irakien (samoon) ou de pain pita légèrement grillé.",
            "shortStory": "Le **Djaj Bil Timen** (poulet au riz) est un plat festif emblématique de la cuisine irakienne, servi lors des célébrations familiales et des occasions spéciales. Cette recette reflète l'histoire ancienne de la Mésopotamie, berceau de la civilisation où la culture du **riz** était déjà établie il y a plus de 5000 ans. L'utilisation généreuse d'**épices** comme la cardamome, la cannelle et le safran témoigne de l'importance historique de Bagdad comme carrefour commercial sur la route de la soie. Les **fruits secs** et les **noix** ajoutés au plat sont caractéristiques de la cuisine du Moyen-Orient et rappellent l'influence persane sur la gastronomie irakienne. Dans les familles traditionnelles de Bagdad et de Bassora, ce plat est souvent préparé collectivement, chaque membre ayant un rôle spécifique dans sa préparation. La technique de cuisson en une seule marmite (appelée \"tanjiya\" en arabe) est typique de la cuisine irakienne qui privilégie les plats mijotés où les saveurs se mélangent harmonieusement. Ce plat représente parfaitement la richesse et la diversité de la cuisine mésopotamienne, qui a influencé les traditions culinaires de tout le Moyen-Orient.",
            "detailURL": "/fiche-recette/192/poulet-riz-epices-fruits-secs/djaj-bil-timen.html",
            "time_prepare": "30mn",
            "time_maceration": "0h",
            "time_cooking": "1h15",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Cette recette est d'un coût moyen à élevé (niveau 3), principalement en raison de la variété de fruits secs et de noix utilisés, ainsi que du safran qui est optionnel mais traditionnel."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette est légèrement épicée (niveau 1), le poivre de Cayenne apportant une chaleur modérée qui peut être ajustée selon les préférences."
            },
            "calories": "490 kcal",
            "allergens": [
                "fruits-a-coque"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne (niveau 2), nécessitant plusieurs étapes mais sans technique particulièrement complexe, principalement de la patience pour le mijotage."
            },
            "equipment_needed": [
                "cocotte"
            ]
        },
        {
            "id": 193,
            "categorie": "moyen-orient",
            "country": "Iraq",
            "flag": "IQ",
            "title": "Tashreeb Djaj",
            "subTitle": "Poulet mijoté aux épices irakiennes, servi sur du pain plat imbibé de sauce parfumée",
            "chicken_piece": [
                "entier",
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier d'environ 1,5 kg (ou 8 cuisses de poulet)",
                "3 cuillères à soupe d'huile d'olive",
                "2 oignons, émincés",
                "5 gousses d'ail, émincées",
                "2 tomates mûres, coupées en dés",
                "1 poivron vert, épépiné et coupé en dés",
                "2 cuillères à soupe de concentré de tomate",
                "1 cuillère à café de curcuma moulu",
                "1 cuillère à café de coriandre moulue",
                "1 cuillère à café de cumin moulu",
                "1/2 cuillère à café de cannelle moulue",
                "1/2 cuillère à café de piment doux (ou paprika)",
                "1/4 cuillère à café de cardamome moulue",
                "1 pincée de noix de muscade râpée",
                "2 feuilles de laurier",
                "2 bâtons de cannelle",
                "4 clous de girofle entiers",
                "4 cardamomes vertes, légèrement écrasées",
                "750ml de bouillon de poulet",
                "Jus d'un demi-citron",
                "1 1/2 cuillère à café de sel (ou selon le goût)",
                "1/2 cuillère à café de poivre noir fraîchement moulu",
                "4 grands pains plats irakiens (samoon) ou pains pita",
                "Pour garnir:",
                "3 cuillères à soupe de persil frais haché",
                "2 cuillères à soupe de coriandre fraîche hachée",
                "2 cuillères à soupe d'amandes effilées grillées (facultatif)"
            ],
            "ingredient_liste": [
                "poulet",
                "huile-olive",
                "oignon",
                "ail",
                "tomate",
                "poivron-vert",
                "concentre-tomate",
                "curcuma",
                "coriandre",
                "cumin",
                "cannelle",
                "piment-doux",
                "cardamome",
                "muscade",
                "laurier",
                "baton-cannelle",
                "clou-girofle",
                "cardamome-verte",
                "bouillon-poulet",
                "citron",
                "sel",
                "poivre",
                "pain-plat",
                "persil",
                "coriandre-fraiche",
                "amande"
            ],
            "preparation": [
                "Si vous utilisez un poulet entier, le couper en 8 morceaux.",
                "Saler et poivrer les morceaux de poulet.",
                "Dans une grande cocotte, faire chauffer l'huile d'olive à feu moyen-vif.",
                "Faire dorer les morceaux de poulet sur toutes les faces, environ 3-4 minutes par côté. Réserver.",
                "Dans la même cocotte, faire revenir les oignons à feu moyen jusqu'à ce qu'ils soient translucides.",
                "Ajouter l'ail et faire revenir 1 minute supplémentaire.",
                "Incorporer les tomates, le poivron vert et le concentré de tomate. Cuire pendant 5 minutes, jusqu'à ce que les légumes commencent à ramollir."
            ],
            "cuisson": [
                "Ajouter toutes les épices moulues (curcuma, coriandre, cumin, cannelle, piment doux, cardamome, noix de muscade) et faire revenir 1 minute pour libérer leurs arômes.",
                "Ajouter les épices entières (feuilles de laurier, bâtons de cannelle, clous de girofle, cardamomes vertes) et mélanger.",
                "Remettre les morceaux de poulet dans la cocotte, verser le bouillon de poulet et porter à ébullition.",
                "Réduire le feu, couvrir et laisser mijoter pendant 45-50 minutes, jusqu'à ce que le poulet soit tendre et se détache facilement de l'os.",
                "Ajouter le jus de citron, goûter et ajuster l'assaisonnement si nécessaire.",
                "Retirer les morceaux de poulet et les réserver au chaud.",
                "Augmenter légèrement le feu et laisser la sauce réduire pendant 5-10 minutes, jusqu'à ce qu'elle épaississe légèrement.",
                "Préchauffer le four à 180°C.",
                "Déchirer grossièrement les pains plats et les disposer au fond d'un grand plat allant au four.",
                "Verser environ la moitié de la sauce chaude sur le pain pour qu'il s'en imprègne.",
                "Disposer les morceaux de poulet sur le pain imbibé.",
                "Verser le reste de la sauce sur le poulet et autour.",
                "Enfourner pendant 5-10 minutes, juste pour réchauffer l'ensemble.",
                "Garnir de persil frais, de coriandre fraîche et d'amandes effilées grillées si désiré."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte",
                "four"
            ],
            "accompagnement": "Servir avec du torshi (pickles de légumes irakiens) et un yaourt nature légèrement salé. Pour le torshi: dans une casserole, porter à ébullition 500ml de vinaigre blanc avec 200ml d'eau, 2 cuillères à soupe de sel et 1 cuillère à soupe de sucre. Préparer un mélange de légumes coupés en morceaux: 2 carottes, 2 concombres, 1 poivron, 1 chou-fleur et 5 gousses d'ail. Ajouter 1 cuillère à café de graines de moutarde, 1 cuillère à café de graines de fenouil et 1 piment rouge entier. Verser le vinaigre bouillant sur les légumes dans un bocal stérilisé. Fermer et laisser mariner au moins 24h avant de servir. Proposer également des olives marinées aux agrumes et un bol de sumac pour saupoudrer selon le goût.",
            "shortStory": "Le **Tashreeb Djaj** (littéralement \"pain imbibé au poulet\") est un plat traditionnel irakien qui incarne l'hospitalité et la générosité de la cuisine mésopotamienne. Cette recette remonte à l'époque abbasside (VIIIe-XIIIe siècles), lorsque Bagdad était le centre culturel et intellectuel du monde islamique. Le terme \"tashreeb\" vient de l'arabe \"sharaba\" (boire) et fait référence à la technique consistant à faire absorber le **bouillon** parfumé par le **pain**, créant ainsi une texture à la fois moelleuse et savoureuse. Dans les foyers irakiens, particulièrement à Mossoul et à Bagdad, ce plat est souvent servi lors des petits déjeuners du week-end ou des repas familiaux importants. Le mélange d'**épices** utilisé est typiquement mésopotamien, reflétant les influences à la fois arabes, persanes et turques qui ont façonné la cuisine irakienne au fil des siècles. Traditionnellement, le pain utilisé est le \"samoon\", un pain plat légèrement feuilleté en forme de losange, spécifique à l'Iraq. Ce plat représente parfaitement la philosophie culinaire irakienne qui privilégie les plats consistants, savoureux et conviviaux, servis au centre de la table pour être partagés entre tous les convives.",
            "detailURL": "/fiche-recette/193/poulet-epices-pain-sauce/tashreeb-djaj.html",
            "time_prepare": "30mn",
            "time_maceration": "0h",
            "time_cooking": "1h15",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2), les épices représentant l'investissement le plus important mais pouvant être réutilisées pour d'autres plats."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette est légèrement épicée (niveau 1), offrant plus de saveurs aromatiques que de chaleur piquante."
            },
            "calories": "430 kcal",
            "allergens": [
                "gluten",
                "fruits-a-coque"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne (niveau 2), nécessitant plusieurs étapes mais sans technique particulièrement complexe, principalement de la patience pour le mijotage."
            },
            "equipment_needed": [
                "cocotte",
                "four",
                "plat-four"
            ]
        }, {
            "id": 194,
            "categorie": "moyen-orient",
            "country": "Iran",
            "flag": "IR",
            "title": "Joojeh Kabab",
            "subTitle": "Brochettes de poulet marinées au safran, citron et yaourt, grillées à la perfection",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 kg de blancs de poulet (ou cuisses désossées), coupés en cubes de 3-4 cm",
                "1 gros oignon, râpé",
                "3 gousses d'ail, écrasées",
                "Le jus de 2 citrons",
                "Le zeste d'un citron",
                "3 cuillères à soupe d'huile d'olive",
                "200g de yaourt nature grec",
                "1 cuillère à café de safran en filaments, infusé dans 2 cuillères à soupe d'eau chaude",
                "1 cuillère à soupe de sumac",
                "1 cuillère à café de curcuma moulu",
                "1 cuillère à café de poivre noir fraîchement moulu",
                "1 1/2 cuillère à café de sel",
                "Pour servir:",
                "1 citron, coupé en quartiers",
                "2 tomates, coupées en quartiers",
                "1 oignon rouge, tranché finement",
                "2 cuillères à soupe de persil frais haché",
                "2 cuillères à soupe de menthe fraîche hachée",
                "2 cuillères à soupe de beurre fondu (facultatif, pour badigeonner en fin de cuisson)"
            ],
            "ingredient_liste": [
                "poulet",
                "oignon",
                "ail",
                "citron",
                "huile-olive",
                "yaourt",
                "safran",
                "sumac",
                "curcuma",
                "poivre",
                "sel",
                "tomate",
                "oignon-rouge",
                "persil",
                "menthe",
                "beurre"
            ],
            "preparation": [
                "Dans un grand bol, mélanger l'oignon râpé, l'ail écrasé, le jus et le zeste de citron, l'huile d'olive, le yaourt, le safran infusé, le sumac, le curcuma, le poivre et le sel.",
                "Ajouter les cubes de poulet et bien les enrober de marinade.",
                "Couvrir le bol et réfrigérer pendant au moins 4 heures, idéalement toute une nuit, en remuant occasionnellement.",
                "30 minutes avant la cuisson, sortir le poulet du réfrigérateur pour qu'il revienne à température ambiante.",
                "Si vous utilisez des brochettes en bois, les faire tremper dans l'eau pendant 30 minutes pour éviter qu'elles ne brûlent."
            ],
            "cuisson": [
                "Préchauffer le grill, le barbecue ou la plancha à feu moyen-vif.",
                "Égoutter le poulet de l'excès de marinade (mais ne pas l'essuyer complètement).",
                "Enfiler les morceaux de poulet sur des brochettes, en laissant un petit espace entre chaque morceau pour une cuisson uniforme.",
                "Faire griller les brochettes pendant environ 8-10 minutes, en les retournant régulièrement, jusqu'à ce que le poulet soit bien cuit et légèrement caramélisé sur les bords.",
                "Dans les dernières minutes de cuisson, badigeonner légèrement les brochettes avec du beurre fondu si désiré, pour une saveur plus riche.",
                "Disposer les brochettes sur un plat de service avec les quartiers de citron, les tomates et l'oignon tranché.",
                "Saupoudrer de persil et de menthe fraîchement hachés avant de servir."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "grill": true,
                "plancha": true,
                "friture": false,
                "barbecue": true,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "barbecue",
                "grill",
                "plancha",
                "four"
            ],
            "accompagnement": "Servir avec du riz persan au safran (chelow) et un mélange d'herbes fraîches (sabzi khordan). Pour le chelow: rincer 500g de riz basmati jusqu'à ce que l'eau soit claire. Faire tremper 1h dans de l'eau salée. Cuire à grande eau pendant 7 minutes jusqu'à ce que le riz soit presque cuit mais encore ferme. Égoutter. Dans une casserole, mélanger 3 cuillères à soupe d'huile et 3 cuillères à soupe d'eau. Disposer le riz en forme de pyramide, creuser quelques trous avec le manche d'une cuillère en bois. Mélanger 1/4 du riz avec 1/2 cuillère à café de safran infusé. Couvrir d'un torchon propre puis d'un couvercle. Cuire à feu très doux 45 minutes. Servir en démoulant (la croûte croustillante du fond, tahdig, est très appréciée). Pour le sabzi khordan: proposer un assortiment d'herbes fraîches (persil, menthe, ciboulette, aneth, coriandre), des radis, des oignons nouveaux et du fromage feta. Accompagner également de mast-o-khiar (yaourt au concombre et menthe).",
            "shortStory": "Le **Joojeh Kabab** est l'un des plats nationaux iraniens, reflétant l'art ancestral de la cuisine au feu de bois qui remonte à l'Empire perse. \"Joojeh\" signifie \"petit poulet\" en persan, faisant référence aux morceaux tendres utilisés traditionnellement. L'utilisation du **safran**, épice la plus chère au monde dont l'Iran est l'un des principaux producteurs, témoigne de l'importance de ce plat dans la culture culinaire persane. Le **yaourt** dans la marinade est un héritage de l'influence des peuples nomades d'Asie centrale sur la cuisine iranienne. Ces brochettes sont particulièrement populaires lors des pique-niques familiaux du Sizdah Bedar, le treizième jour du Nouvel An persan (Norouz), où la tradition veut que l'on passe la journée dans la nature. Dans les restaurants traditionnels de Téhéran et d'Ispahan, le Joojeh Kabab est souvent servi avec du riz au safran (chelow) et un assortiment d'herbes fraîches (sabzi khordan), représentant parfaitement l'équilibre entre les protéines, les féculents et les herbes aromatiques qui caractérise la cuisine persane.",
            "detailURL": "/fiche-recette/194/poulet-safran-citron-yaourt/joojeh-kabab.html",
            "time_prepare": "20mn",
            "time_maceration": "4h",
            "time_cooking": "15mn",
            "crispys": true,
            "cost": {
                "level": 3,
                "description": "Cette recette est d'un coût moyen à élevé (niveau 3), principalement en raison du safran qui est un ingrédient traditionnel indispensable mais coûteux."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Cette recette n'est pas épicée (niveau 0), offrant plutôt des saveurs aromatiques et acidulées caractéristiques de la cuisine persane."
            },
            "calories": "330 kcal",
            "allergens": [
                "produits-laitiers"
            ],
            "difficulty": {
                "level": 1,
                "description": "Recette de difficulté facile (niveau 1), nécessitant principalement du temps pour la marinade mais avec une technique de cuisson simple."
            },
            "equipment_needed": [
                "barbecue",
                "brochettes-metal",
                "plat-service"
            ]
        },
        {
            "id": 195,
            "categorie": "moyen-orient",
            "country": "Iran",
            "flag": "IR",
            "title": "Zereshk Polo ba Morgh",
            "subTitle": "Poulet mijoté aux épices persanes, servi avec du riz aux baies de berbéris et pistaches",
            "chicken_piece": [
                "cuisse",
                "entier"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier d'environ 1,5 kg, coupé en morceaux (ou 8 cuisses de poulet)",
                "3 cuillères à soupe d'huile végétale",
                "2 oignons, finement émincés",
                "3 gousses d'ail, écrasées",
                "1 cuillère à café de curcuma moulu",
                "1/2 cuillère à café de cannelle moulue",
                "1/2 cuillère à café de cardamome moulue",
                "1/4 cuillère à café de poivre noir moulu",
                "1 pincée de safran infusé dans 3 cuillères à soupe d'eau chaude",
                "1 cuillère à café de sel",
                "1 1/2 tasse de bouillon de poulet",
                "3 cuillères à soupe de concentré de tomate",
                "Le jus d'un demi-citron",
                "1 cuillère à soupe de sucre",
                "Pour le riz:",
                "500g de riz basmati",
                "2 cuillères à soupe de sel (pour l'eau de cuisson)",
                "3 cuillères à soupe d'huile végétale",
                "1/4 cuillère à café de safran infusé dans 2 cuillères à soupe d'eau chaude",
                "100g de baies de berbéris (zereshk)",
                "50g de pistaches non salées, décortiquées",
                "2 cuillères à soupe de sucre",
                "2 cuillères à soupe de beurre"
            ],
            "ingredient_liste": [
                "poulet",
                "huile-vegetale",
                "oignon",
                "ail",
                "curcuma",
                "cannelle",
                "cardamome",
                "poivre",
                "safran",
                "sel",
                "bouillon-poulet",
                "concentre-tomate",
                "citron",
                "sucre",
                "riz-basmati",
                "baie-berberis",
                "pistache",
                "beurre"
            ],
            "preparation": [
                "Rincer le riz basmati plusieurs fois jusqu'à ce que l'eau soit claire. Le faire tremper dans de l'eau froide salée pendant au moins 1 heure.",
                "Dans une grande cocotte, faire chauffer 2 cuillères à soupe d'huile à feu moyen-vif.",
                "Saler et poivrer les morceaux de poulet. Les faire dorer sur toutes les faces, environ 3-4 minutes par côté. Réserver.",
                "Dans la même cocotte, ajouter 1 cuillère à soupe d'huile et faire revenir les oignons jusqu'à ce qu'ils soient translucides.",
                "Ajouter l'ail et faire revenir 1 minute supplémentaire.",
                "Incorporer le curcuma, la cannelle, la cardamome et le poivre noir. Faire revenir 30 secondes."
            ],
            "cuisson": [
                "Ajouter le concentré de tomate, remuer et cuire 1 minute.",
                "Remettre les morceaux de poulet dans la cocotte. Verser le bouillon de poulet, ajouter la moitié du safran infusé, le sel, le jus de citron et le sucre.",
                "Porter à ébullition, puis réduire le feu, couvrir et laisser mijoter pendant environ 45 minutes, jusqu'à ce que le poulet soit tendre.",
                "Pendant ce temps, préparer le riz: porter une grande casserole d'eau salée à ébullition. Égoutter le riz trempé et le verser dans l'eau bouillante.",
                "Faire cuire le riz pendant environ 7 minutes, jusqu'à ce qu'il soit presque cuit mais encore un peu ferme. Égoutter.",
                "Dans une poêle, faire fondre 1 cuillère à soupe de beurre, ajouter les baies de berbéris et le sucre. Faire revenir à feu doux pendant 1-2 minutes, en veillant à ne pas brûler les baies. Réserver.",
                "Dans une grande casserole à fond épais, faire chauffer 3 cuillères à soupe d'huile. Ajouter un peu de riz pour couvrir le fond.",
                "Verser le reste du riz par-dessus en formant une pyramide. Creuser quelques trous dans le riz avec le manche d'une cuillère en bois.",
                "Mélanger le reste du safran infusé avec 3 cuillères à soupe d'eau chaude. Verser sur une partie du riz pour créer un contraste de couleur.",
                "Envelopper le couvercle de la casserole avec un torchon propre (pour absorber la condensation). Couvrir la casserole et cuire à feu très doux pendant 30-40 minutes.",
                "Faire griller légèrement les pistaches dans une poêle sèche et les réserver.",
                "Pour servir: disposer le riz sur un plat, en réservant la croûte croustillante du fond (tahdig). Parsemer de baies de berbéris et de pistaches grillées.",
                "Disposer les morceaux de poulet autour ou à côté, napper de sauce et garnir avec le reste du safran infusé."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du mast-o-khiar (yaourt au concombre et à la menthe) et un assortiment d'herbes fraîches (sabzi khordan). Pour le mast-o-khiar: mélanger 500g de yaourt grec avec 2 concombres râpés et pressés pour éliminer l'excès d'eau, 2 cuillères à soupe de menthe séchée, 2 gousses d'ail écrasées, 1 cuillère à café de sel et une pincée de poivre. Pour le sabzi khordan: proposer un assortiment d'herbes fraîches (persil, menthe, ciboulette, aneth, coriandre), des radis, des oignons nouveaux et des tranches de concombre. Servir également du torshi (pickles de légumes iraniens) et du pain lavash pour compléter le repas de façon traditionnelle.",
            "shortStory": "Le **Zereshk Polo ba Morgh** (riz aux baies de berbéris avec poulet) est un plat festif emblématique de la cuisine persane, souvent servi lors des mariages et des célébrations importantes. Les **baies de berbéris** (zereshk), au goût légèrement acidulé, sont un ingrédient caractéristique de la cuisine iranienne et étaient utilisées dans la médecine traditionnelle persane pour leurs vertus médicinales. Le contraste entre l'acidité des baies, la douceur du sucre et la richesse aromatique du **safran** est typique de l'équilibre des saveurs recherché dans la cuisine iranienne. La technique de cuisson du riz persan, qui crée une croûte dorée et croustillante appelée **tahdig** au fond de la casserole, est considérée comme un art culinaire exigeant une grande maîtrise. Dans les familles iraniennes, particulièrement à Chiraz et Ispahan, ce plat est souvent préparé pour honorer les invités de marque. L'utilisation des **pistaches** comme garniture est un rappel de la riche tradition des fruits secs dans la cuisine persane, héritage des anciennes routes commerciales qui traversaient l'Iran. Ce plat incarne parfaitement la sophistication et le raffinement de la cuisine iranienne, l'une des plus anciennes et des plus influentes du Moyen-Orient.",
            "detailURL": "/fiche-recette/195/poulet-safran-riz-berberis/zereshk-polo-ba-morgh.html",
            "time_prepare": "30mn",
            "time_maceration": "1h",
            "time_cooking": "1h30",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Cette recette est d'un coût moyen à élevé (niveau 3) en raison du safran et des baies de berbéris qui sont des ingrédients spécifiques pouvant nécessiter une visite dans une épicerie spécialisée."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Cette recette n'est pas épicée (niveau 0), offrant plutôt un subtil mélange de saveurs aromatiques, sucrées et acidulées."
            },
            "calories": "480 kcal",
            "allergens": [
                "fruits-a-coque",
                "produits-laitiers"
            ],
            "difficulty": {
                "level": 3,
                "description": "Recette de difficulté assez élevée (niveau 3), principalement en raison de la technique de cuisson du riz persan qui demande de la pratique pour obtenir le tahdig parfaitement croustillant."
            },
            "equipment_needed": [
                "cocotte",
                "casserole-fond-epais",
                "torchon-propre"
            ]
        },


        {
            "id": 196,
            "categorie": "europe",
            "country": "Italie",
            "flag": "IT",
            "title": "Pollo alla Diavola",
            "subTitle": "Poulet aplati et grillé à l'italienne, mariné au citron et aux herbes, relevé de piment",
            "chicken_piece": [
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "1 poulet entier de 1,5 kg",
                "4 cuillères à soupe d'huile d'olive extra vierge",
                "4 gousses d'ail, émincées",
                "Le jus et le zeste de 2 citrons",
                "2 cuillères à soupe de romarin frais haché",
                "2 cuillères à soupe de thym frais haché",
                "1 cuillère à soupe de sauge fraîche hachée",
                "1 cuillère à café de piment rouge concassé (peperoncino)",
                "1 cuillère à café de poivre noir fraîchement moulu",
                "1 cuillère à café de sel",
                "1/2 cuillère à café de paprika fumé (facultatif)",
                "Pour servir:",
                "2 citrons, coupés en quartiers",
                "2 branches de romarin frais",
                "1 cuillère à soupe d'huile d'olive extra vierge"
            ],
            "ingredient_liste": [
                "poulet",
                "huile-olive",
                "ail",
                "citron",
                "romarin",
                "thym",
                "sauge",
                "piment-rouge",
                "poivre",
                "sel",
                "paprika-fume"
            ],
            "preparation": [
                "Placer le poulet sur une planche à découper, poitrine vers le bas. À l'aide de ciseaux de cuisine, couper de chaque côté de la colonne vertébrale pour la retirer complètement.",
                "Retourner le poulet et l'aplatir en appuyant fermement sur le sternum avec la paume de la main jusqu'à entendre un craquement. Cette technique s'appelle 'spatchcock' ou 'butterfly'.",
                "Dans un bol, préparer la marinade en mélangeant l'huile d'olive, l'ail, le jus et le zeste de citron, le romarin, le thym, la sauge, le piment rouge concassé, le poivre, le sel et le paprika fumé si utilisé.",
                "Placer le poulet dans un grand plat ou un sac de congélation et verser la marinade dessus, en massant bien pour l'enrober complètement, y compris sous la peau.",
                "Couvrir (ou fermer le sac) et réfrigérer pendant au moins 2 heures, idéalement toute une nuit."
            ],
            "cuisson": [
                "Sortir le poulet du réfrigérateur 30 minutes avant la cuisson pour qu'il revienne à température ambiante.",
                "Préchauffer le grill, le barbecue ou le four à 200°C.",
                "Pour la cuisson au grill ou au barbecue: disposer le poulet côté peau vers le haut sur la grille légèrement huilée. Faire griller pendant environ 15 minutes, puis retourner et poursuivre la cuisson pendant 15-20 minutes supplémentaires, jusqu'à ce que la peau soit croustillante et que la température interne atteigne 75°C dans la partie la plus épaisse.",
                "Pour la cuisson au four: placer le poulet côté peau vers le haut sur une grille posée au-dessus d'une lèchefrite. Cuire pendant environ 45-50 minutes, jusqu'à ce que la peau soit croustillante et que la température interne atteigne 75°C.",
                "Laisser reposer le poulet pendant 10 minutes avant de le découper.",
                "Arroser d'un filet d'huile d'olive extra vierge, garnir de quartiers de citron et de branches de romarin frais.",
                "Découper le poulet en portions et servir chaud."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "barbecue",
                "grill",
                "four"
            ],
            "accompagnement": "Servir avec des pommes de terre au romarin et une salade de roquette à la toscane. Pour les pommes de terre: préchauffer le four à 200°C. Couper 1kg de pommes de terre nouvelles en deux, les mélanger avec 3 cuillères à soupe d'huile d'olive, 3 branches de romarin effeuillées, 4 gousses d'ail écrasées, du sel et du poivre. Disposer sur une plaque de cuisson et rôtir pendant 35-40 minutes jusqu'à ce qu'elles soient croustillantes à l'extérieur et tendres à l'intérieur. Pour la salade: mélanger 150g de roquette avec 50g de copeaux de parmesan, 12 tomates cerises coupées en deux et quelques feuilles de basilic. Préparer une vinaigrette avec 3 cuillères à soupe d'huile d'olive extra vierge, 1 cuillère à soupe de vinaigre balsamique, 1 petite gousse d'ail écrasée, du sel et du poivre. Servir également des tranches de pain ciabatta grillées et frottées à l'ail.",
            "shortStory": "Le **Pollo alla Diavola** (poulet à la diable) est un plat traditionnel de la cuisine toscane qui tire son nom de sa saveur légèrement **piquante** et de sa méthode de cuisson qui fait penser à une torture diabolique - le poulet étant aplati et grillé sur un feu vif. Cette technique remonte au Moyen Âge florentin, où l'on utilisait des broches en métal pour maintenir le poulet à plat pendant la cuisson sur des braises de bois. L'association d'**herbes fraîches** méditerranéennes (romarin, thym, sauge) avec le piment et le **citron** est caractéristique de la cuisine toscane, qui met l'accent sur des saveurs franches et des ingrédients simples mais de qualité. Dans les fermes de la campagne toscane, particulièrement autour de Sienne et d'Arezzo, ce plat était traditionnellement préparé pour célébrer la fin des vendanges. Le gril utilisé, appelé \"graticola\" en italien, était souvent un simple treillis métallique placé au-dessus de sarments de vigne en feu, apportant une légère note fumée au poulet. Ce plat représente parfaitement la philosophie culinaire italienne du \"poco ma buono\" (peu mais bon), où la qualité des ingrédients et la simplicité des techniques de cuisson sont privilégiées.",
            "detailURL": "/fiche-recette/196/poulet-citron-herbes-piment/pollo-alla-diavola.html",
            "time_prepare": "20mn",
            "time_maceration": "2h",
            "time_cooking": "50mn",
            "crispys": true,
            "cost": {
                "level": 1,
                "description": "Cette recette est d'un coût relativement bas (niveau 1), les ingrédients étant des produits de base facilement accessibles."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette est moyennement épicée (niveau 2), avec un piquant notable dû au piment rouge qui peut être ajusté selon les préférences."
            },
            "calories": "390 kcal",
            "allergens": [],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne (niveau 2), la technique d'aplatissement du poulet pouvant demander un peu de pratique mais reste accessible aux débutants motivés."
            },
            "equipment_needed": [
                "barbecue",
                "ciseaux-cuisine",
                "thermometre-cuisine",
                "grille-cuisson"
            ]
        },
        {
            "id": 197,
            "categorie": "europe",
            "country": "Irlande",
            "flag": "IE",
            "title": "Poulet Farci au Black Pudding",
            "subTitle": "Blancs de poulet farcis au boudin noir irlandais, pommes et sauge, enrobés de bacon",
            "chicken_piece": [
                "blanc"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "4 gros blancs de poulet",
                "200g de black pudding irlandais (boudin noir), émietté",
                "1 pomme Bramley (ou autre pomme à cuire), pelée et coupée en petits dés",
                "1 oignon, finement haché",
                "2 gousses d'ail, émincées",
                "8 tranches de bacon irlandais (rashers)",
                "2 cuillères à soupe d'huile d'olive",
                "2 cuillères à soupe de beurre",
                "2 cuillères à soupe de sauge fraîche, ciselée",
                "1 cuillère à café de thym frais, haché",
                "2 cuillères à soupe de whiskey irlandais (facultatif)",
                "50g de chapelure de pain complet",
                "Sel et poivre noir, à votre goût",
                "Pour la sauce:",
                "250ml de bouillon de poulet",
                "100ml de crème fraîche épaisse",
                "1 cuillère à soupe de moutarde de Dijon",
                "1 cuillère à soupe de cidre irlandais",
                "1 cuillère à café de thym frais haché"
            ],
            "ingredient_liste": [
                "poulet",
                "black-pudding",
                "pomme",
                "oignon",
                "ail",
                "bacon",
                "huile-olive",
                "beurre",
                "sauge",
                "thym",
                "whiskey-irlandais",
                "chapelure",
                "sel",
                "poivre",
                "bouillon-poulet",
                "creme-fraiche",
                "moutarde-dijon",
                "cidre-irlandais"
            ],
            "preparation": [
                "Préchauffer le four à 180°C.",
                "Dans une poêle, faire fondre 1 cuillère à soupe de beurre à feu moyen. Ajouter l'oignon et faire revenir jusqu'à ce qu'il soit translucide (environ 5 minutes).",
                "Ajouter l'ail et faire revenir 1 minute supplémentaire.",
                "Incorporer le black pudding émietté et faire revenir 2-3 minutes.",
                "Ajouter les pommes, la sauge (en gardant un peu pour la garniture) et le thym. Faire revenir 2 minutes.",
                "Si vous utilisez le whiskey, l'ajouter et laisser réduire presque complètement.",
                "Retirer du feu, incorporer la chapelure et laisser refroidir légèrement.",
                "Avec un couteau bien aiguisé, pratiquer une incision sur le côté de chaque blanc de poulet pour créer une poche, sans couper complètement à travers.",
                "Remplir généreusement chaque blanc avec la farce au black pudding refroidie.",
                "Assaisonner les blancs farcis avec du sel et du poivre.",
                "Enrouler chaque blanc de poulet avec 2 tranches de bacon, en s'assurant que les extrémités se chevauchent pour sceller la farce à l'intérieur."
            ],
            "cuisson": [
                "Dans une grande poêle allant au four, faire chauffer l'huile d'olive et le reste du beurre à feu moyen-vif.",
                "Saisir les blancs de poulet enrobés de bacon pendant 2-3 minutes de chaque côté, jusqu'à ce que le bacon commence à dorer.",
                "Transférer la poêle au four préchauffé et cuire pendant environ 20-25 minutes, jusqu'à ce que le poulet soit bien cuit (température interne de 75°C).",
                "Retirer les blancs de poulet de la poêle et les laisser reposer sur une assiette, couverts de papier aluminium.",
                "Pendant ce temps, préparer la sauce: dans la même poêle, ajouter le bouillon de poulet et le cidre, en grattant les sucs de cuisson.",
                "Porter à ébullition et laisser réduire de moitié.",
                "Réduire le feu et ajouter la crème fraîche, la moutarde et le thym. Laisser mijoter pendant 3-4 minutes jusqu'à épaississement. Goûter et ajuster l'assaisonnement.",
                "Trancher délicatement les blancs de poulet en diagonale et servir nappés de sauce, garnis de sauge fraîche."
            ],
            "cooking_methods": {
                "best": "four-poele",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": true,
                "cocotte": false
            },
            "mode_cuisson": [
                "four",
                "poele"
            ],
            "accompagnement": "Servir avec une purée de pommes de terre et choux de Bruxelles sautés à l'irlandaise. Pour la purée: faire bouillir 800g de pommes de terre en morceaux jusqu'à tendreté. Les égoutter, puis les écraser avec 100ml de lait chaud, 75g de beurre, du sel et du poivre. Incorporer 2 cuillères à soupe de ciboulette fraîche ciselée. Pour les choux de Bruxelles: faire revenir 500g de choux de Bruxelles coupés en deux avec 100g de lardons dans 2 cuillères à soupe de beurre jusqu'à ce qu'ils soient légèrement dorés. Ajouter 1 cuillère à soupe de miel et 2 cuillères à soupe de cidre, puis cuire encore 2-3 minutes jusqu'à ce que les choux soient tendres mais encore croquants. Assaisonner avec du sel, du poivre et une pincée de muscade râpée. Servir également avec un pot de chutney de pommes maison.",
            "shortStory": "Le **Poulet Farci au Black Pudding** est une recette contemporaine qui s'inspire de la riche tradition culinaire irlandaise. Le **black pudding** (boudin noir) est un élément emblématique du petit-déjeuner irlandais traditionnel, mais son utilisation comme farce est une innovation plus moderne qui a gagné en popularité dans les gastropubs irlandais. Ce mariage de saveurs reflète l'évolution de la cuisine irlandaise, qui tout en restant ancrée dans ses traditions, s'ouvre à de nouvelles techniques et associations. La combinaison du boudin épicé avec la douceur acidulée des **pommes** Bramley (variété typiquement irlandaise) représente parfaitement l'équilibre entre salé et sucré recherché dans la cuisine irlandaise contemporaine. L'enrobage de **bacon** irlandais (plus épais et moins fumé que son équivalent américain) ajoute une protection naturelle contre le dessèchement et une saveur supplémentaire. Dans les comtés de Cork et de Clonakilty, réputés pour la qualité de leur boudin noir, ce plat est particulièrement apprécié, notamment lorsqu'il est accompagné de cidre ou de stout locaux. Il illustre la philosophie culinaire irlandaise moderne qui privilégie les produits du terroir préparés avec créativité.",
            "detailURL": "/fiche-recette/197/poulet-boudin-noir-pomme-bacon/poulet-farci-au-black-pudding.html",
            "time_prepare": "30mn",
            "time_maceration": "0h",
            "time_cooking": "35mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2), le black pudding pouvant être trouvé dans les épiceries spécialisées ou remplacé par du boudin noir standard pour réduire le coût."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Cette recette n'est pas épicée (niveau 0), offrant plutôt des saveurs riches et complexes grâce au mélange de boudin noir, de pommes et d'herbes."
            },
            "calories": "520 kcal",
            "allergens": [
                "gluten",
                "produits-laitiers",
                "moutarde"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne (niveau 2), la technique de farce et d'enrobage demandant un peu de dextérité mais restant accessible avec de la pratique."
            },
            "equipment_needed": [
                "poele-allant-au-four",
                "thermometre-cuisine"
            ]
        },
        {
            "id": 198,
            "categorie": "asie",
            "country": "Inde",
            "flag": "IN",
            "title": "Chicken Tikka Masala",
            "subTitle": "Morceaux de poulet marinés au yaourt et épices, grillés puis mijotés dans une sauce tomate crémeuse",
            "chicken_piece": [
                "blanc",
                "cuisse"
            ],
            "piece": {
                "best": "blanc",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "800g de blancs de poulet (ou cuisses désossées), coupés en cubes de 4 cm",
                "Pour la marinade:",
                "200g de yaourt nature grec",
                "2 cuillères à soupe de jus de citron",
                "4 gousses d'ail, écrasées",
                "3 cm de gingembre frais, râpé",
                "2 cuillères à café de garam masala",
                "1 cuillère à café de curcuma moulu",
                "1 cuillère à café de cumin moulu",
                "1 cuillère à café de coriandre moulue",
                "1 cuillère à café de piment de Kashmiri (ou paprika doux)",
                "1/2 cuillère à café de piment rouge moulu (ajuster selon le goût)",
                "1 cuillère à café de sel",
                "Pour la sauce:",
                "3 cuillères à soupe d'huile ou de ghee",
                "1 gros oignon, finement haché",
                "4 gousses d'ail, hachées finement",
                "3 cm de gingembre, râpé",
                "2 piments verts, épépinés et finement hachés (facultatif)",
                "2 cuillères à café de garam masala",
                "1 cuillère à café de curcuma moulu",
                "1 cuillère à café de cumin moulu",
                "1 cuillère à café de coriandre moulue",
                "1 cuillère à café de piment de Kashmiri",
                "400g de tomates concassées en boîte",
                "2 cuillères à soupe de purée de tomate",
                "1 cuillère à café de sucre brun",
                "250ml de crème fraîche épaisse",
                "1 cuillère à café de fenugrec séché (kasoori methi)",
                "Sel selon le goût",
                "Coriandre fraîche ciselée pour garnir"
            ],
            "ingredient_liste": [
                "poulet",
                "yaourt",
                "citron",
                "ail",
                "gingembre",
                "garam-masala",
                "curcuma",
                "cumin",
                "coriandre",
                "piment-kashmiri",
                "piment-rouge",
                "sel",
                "huile",
                "ghee",
                "oignon",
                "piment-vert",
                "tomate",
                "puree-tomate",
                "sucre-brun",
                "creme-fraiche",
                "fenugrec-seche",
                "coriandre-fraiche"
            ],
            "preparation": [
                "Dans un grand bol, mélanger tous les ingrédients de la marinade.",
                "Ajouter les morceaux de poulet et bien les enrober. Couvrir et réfrigérer pendant au moins 4 heures, idéalement toute une nuit.",
                "Une fois le temps de marinade écoulé, préchauffer le grill ou le four en mode grill à haute température.",
                "Enfiler les morceaux de poulet sur des brochettes (si elles sont en bois, les faire tremper au préalable dans l'eau pendant 30 minutes).",
                "Placer les brochettes sur une grille au-dessus d'une lèchefrite et griller pendant 10-12 minutes, en les retournant à mi-cuisson, jusqu'à ce que le poulet soit bien doré sur les bords mais pas complètement cuit (il finira de cuire dans la sauce)."
            ],
            "cuisson": [
                "Pendant que le poulet grille, préparer la sauce: dans une grande casserole ou un wok, faire chauffer l'huile ou le ghee à feu moyen.",
                "Ajouter l'oignon et faire revenir jusqu'à ce qu'il soit doré (environ 8 minutes).",
                "Incorporer l'ail, le gingembre et les piments verts (si utilisés). Faire revenir 2 minutes supplémentaires.",
                "Ajouter toutes les épices en poudre et faire revenir pendant 30 secondes en remuant constamment.",
                "Verser les tomates concassées, la purée de tomate et le sucre. Bien mélanger et laisser mijoter pendant 15 minutes jusqu'à ce que la sauce épaississe et que l'huile commence à se séparer sur les bords.",
                "Retirer les morceaux de poulet des brochettes et les ajouter à la sauce. Bien mélanger pour les enrober.",
                "Réduire le feu et ajouter la crème fraîche et le fenugrec séché. Remuer délicatement.",
                "Laisser mijoter à couvert pendant 8-10 minutes, jusqu'à ce que le poulet soit complètement cuit.",
                "Goûter et ajuster l'assaisonnement si nécessaire.",
                "Garnir de coriandre fraîche ciselée avant de servir."
            ],
            "cooking_methods": {
                "best": "grill-cocotte",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "grill",
                "four",
                "cocotte"
            ],
            "accompagnement": "Servir avec du naan à l'ail et du riz basmati parfumé. Pour le naan à l'ail: dans un grand bol, mélanger 500g de farine, 2 cuillères à café de levure sèche, 1 cuillère à café de sucre, 1 cuillère à café de sel et 150ml de yaourt. Ajouter progressivement 150ml d'eau tiède pour former une pâte souple. Pétrir pendant 5 minutes, puis laisser lever couvert pendant 2 heures. Diviser en 6 boules, les étaler en ovales. Préparer un mélange de 100g de beurre fondu avec 4 gousses d'ail émincées et 2 cuillères à soupe de coriandre hachée. Badigeonner les naans avec ce mélange. Cuire dans un four très chaud (250°C) ou sur une poêle en fonte préchauffée pendant 2-3 minutes de chaque côté. Pour le riz: rincer 400g de riz basmati jusqu'à ce que l'eau soit claire. Faire tremper 30 minutes. Dans une casserole, faire revenir 4 gousses de cardamome, 4 clous de girofle et 1 bâton de cannelle dans 2 cuillères à soupe de ghee. Ajouter le riz égoutté et faire revenir 1 minute. Verser 600ml d'eau et 1 cuillère à café de sel. Porter à ébullition, puis couvrir et cuire à feu doux 15 minutes. Laisser reposer 5 minutes avant de servir.",
            "shortStory": "Le **Chicken Tikka Masala** est l'un des plats les plus populaires de la cuisine indo-britannique, considéré par beaucoup comme le plat national non officiel du Royaume-Uni. Son origine fait l'objet de débats passionnés: certains affirment qu'il a été créé dans les années 1970 à Glasgow par un chef bangladais qui, face à un client se plaignant que son poulet tikka était trop sec, improvisa une sauce à base de **tomate** et de **crème**. D'autres soutiennent qu'il s'agit d'une adaptation d'un plat du Pendjab, le butter chicken. Quelle que soit son origine, le plat illustre parfaitement la fusion des saveurs indiennes et des goûts occidentaux. Sa préparation en deux étapes - d'abord la marinade et la cuisson au **tandoor** (ou au grill) des morceaux de poulet, puis leur immersion dans une sauce riche et parfumée - est caractéristique de nombreux plats du nord de l'Inde. Le mélange d'**épices** utilisé, dominé par le garam masala, crée un profil de saveur complexe mais accessible, ce qui explique en partie son immense popularité mondiale. Dans les restaurants indiens authentiques, ce plat est souvent préparé avec un mélange d'épices spécifique à chaque établissement, gardé secret et transmis de génération en génération.",
            "detailURL": "/fiche-recette/198/poulet-tomate-creme-epices/chicken-tikka-masala.html",
            "time_prepare": "25mn",
            "time_maceration": "4h",
            "time_cooking": "45mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2). La plupart des épices peuvent être trouvées dans les supermarchés, bien que certaines comme le fenugrec séché puissent nécessiter une visite dans une épicerie indienne."
            },
            "images": [],
            "hot": {
                "level": 2,
                "description": "Cette recette est moyennement épicée (niveau 2), mais l'intensité peut être facilement ajustée en modifiant la quantité de piment rouge en poudre."
            },
            "calories": "450 kcal",
            "allergens": [
                "produits-laitiers"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne (niveau 2), nécessitant plusieurs étapes mais sans technique particulièrement complexe."
            },
            "equipment_needed": [
                "four",
                "brochettes",
                "casserole",
                "mixeur-plongeant"
            ]
        },
        {
            "id": 199,
            "categorie": "europe",
            "country": "Islande",
            "flag": "IS",
            "title": "Poulet aux Algues et Sel Volcanique",
            "subTitle": "Poulet rôti aux algues nordiques, sel noir de lave et herbes sauvages islandaises",
            "chicken_piece": [
                "entier"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": false
            },
            "ingredient": [
                "1 poulet entier d'environ 1,5 kg",
                "3 cuillères à soupe d'huile de colza",
                "2 cuillères à café de sel noir volcanique islandais (ou sel de mer fumé)",
                "1 cuillère à café de poivre noir moulu",
                "2 cuillères à soupe d'algues séchées islandaises (dulse ou nori), finement hachées",
                "2 cuillères à soupe d'aneth frais, haché",
                "1 cuillère à soupe de thym frais, haché",
                "1 cuillère à café de baies de genièvre, écrasées",
                "Zeste d'un citron",
                "3 gousses d'ail, écrasées",
                "1 oignon, coupé en quartiers",
                "1 citron, coupé en quartiers",
                "200ml de bière blonde islandaise (comme la Gull ou Einstök)",
                "50g de beurre non salé, ramolli",
                "1 bouquet d'herbes mixtes (aneth, thym, persil)",
                "500g de pommes de terre nouvelles, coupées en deux",
                "3 carottes, coupées en bâtonnets",
                "2 panais, coupés en bâtonnets"
            ],
            "ingredient_liste": [
                "poulet",
                "huile-colza",
                "sel-volcanique",
                "poivre",
                "algue-sechee",
                "aneth",
                "thym",
                "baie-genievre",
                "citron",
                "ail",
                "oignon",
                "biere-blonde",
                "beurre",
                "persil",
                "pomme-de-terre",
                "carotte",
                "panais"
            ],
            "preparation": [
                "Préchauffer le four à 190°C.",
                "Rincer le poulet à l'intérieur et à l'extérieur, puis le sécher soigneusement avec du papier absorbant.",
                "Dans un petit bol, mélanger le beurre ramolli avec les algues hachées, l'aneth, le thym, les baies de genièvre écrasées, le zeste de citron, l'ail écrasé, 1 cuillère à café de sel volcanique et le poivre noir.",
                "Délicatement, glisser vos doigts entre la peau et la chair du poulet pour créer des poches, en particulier au niveau de la poitrine et des cuisses.",
                "Introduire le mélange de beurre aux herbes dans ces poches, puis masser l'extérieur du poulet pour répartir uniformément.",
                "Frotter l'extérieur du poulet avec l'huile de colza et saupoudrer du reste de sel volcanique.",
                "Remplir la cavité du poulet avec les quartiers d'oignon, les quartiers de citron et le bouquet d'herbes."
            ],
            "cuisson": [
                "Dans un grand plat allant au four, disposer les pommes de terre, les carottes et les panais.",
                "Placer le poulet sur les légumes, poitrine vers le haut.",
                "Verser la bière islandaise autour (pas sur) le poulet.",
                "Enfourner pour 1h15-1h30, jusqu'à ce que le poulet soit bien doré et que son jus soit clair lorsqu'on pique la cuisse (la température interne doit atteindre 75°C).",
                "À mi-cuisson, arroser le poulet avec les jus du plat et remuer légèrement les légumes.",
                "Une fois cuit, sortir le poulet du four et le laisser reposer pendant 15 minutes sous une feuille d'aluminium avant de le découper.",
                "Pendant ce temps, si les légumes ne sont pas assez dorés, les laisser dans le four à température plus élevée (220°C) pendant 10-15 minutes supplémentaires.",
                "Servir le poulet découpé avec les légumes rôtis, nappé des jus de cuisson filtrés."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec une sauce au yaourt à l'aneth et une salade fraîche de chou et pommes à l'islandaise. Pour la sauce au yaourt: mélanger 300g de skyr (ou yaourt grec épais) avec 3 cuillères à soupe d'aneth frais haché, 1 gousse d'ail écrasée, 1 cuillère à café de zeste de citron, le jus d'un demi-citron, du sel et du poivre. Pour la salade fraîche: finement émincer 1/4 de chou blanc et 1 pomme verte. Les mélanger avec 2 cuillères à soupe de vinaigre de cidre, 1 cuillère à soupe de miel, 2 cuillères à soupe d'huile de colza, 1 cuillère à café de graines de carvi légèrement grillées, du sel et du poivre. Laisser mariner 30 minutes avant de servir. Accompagner également de pain de seigle islandais (rúgbrauð): mélanger 500g de farine de seigle, 250g de farine complète, 2 cuillères à café de sel, 2 cuillères à soupe de sucre brun, 500ml de babeurre et 1 cuillère à café de bicarbonate de soude. Verser dans un moule à cake beurré, couvrir de papier aluminium et cuire à 120°C pendant 8 heures.",
            "shortStory": "Le **Poulet aux Algues et Sel Volcanique** est une création contemporaine qui s'inspire profondément des traditions culinaires islandaises et des ingrédients autochtones de cette île nordique. L'utilisation d'**algues** séchées reflète l'importance historique des ressources marines dans l'alimentation islandaise, particulièrement durant les longs mois d'hiver où les produits frais étaient rares. Le **sel volcanique** noir, récolté sur les côtes islandaises où l'eau de mer s'évapore sur la lave refroidie, apporte non seulement une saveur distinctive mais aussi une connexion symbolique avec le paysage volcanique de l'île. Les **baies de genièvre** et les herbes nordiques étaient traditionnellement cueillies dans les landes islandaises pendant les courts mois d'été et utilisées pour parfumer les viandes. La **bière** islandaise, dont la prohibition n'a été levée qu'en 1989, connaît aujourd'hui un véritable renouveau artisanal, avec des brasseries qui utilisent souvent l'eau pure des glaciers. Ce plat représente la nouvelle cuisine nordique, un mouvement qui valorise les ingrédients locaux et les techniques traditionnelles tout en les réinterprétant dans un contexte contemporain. Dans les restaurants de Reykjavik, on retrouve souvent ce type de préparation qui célèbre le terroir islandais unique, à la jonction des influences scandinaves et des ressources naturelles spécifiques à cette terre de feu et de glace.",
            "detailURL": "/fiche-recette/199/poulet-algue-sel-volcanique-herbes/poulet-aux-algues-et-sel-volcanique.html",
            "time_prepare": "30mn",
            "time_maceration": "0h",
            "time_cooking": "1h30",
            "crispys": true,
            "cost": {
                "level": 3,
                "description": "Cette recette est d'un coût moyen à élevé (niveau 3) en raison des ingrédients spécifiques comme le sel volcanique islandais et les algues séchées qui peuvent nécessiter une commande en ligne, mais des substituts plus accessibles peuvent être utilisés."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Cette recette n'est pas épicée (niveau 0), offrant plutôt des saveurs iodées, herbacées et légèrement fumées caractéristiques de la cuisine nordique."
            },
            "calories": "410 kcal",
            "allergens": [
                "produits-laitiers"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne (niveau 2), la technique du beurre aux herbes sous la peau demandant un peu de dextérité mais restant accessible."
            },
            "equipment_needed": [
                "four",
                "plat-four",
                "thermometre-cuisine"
            ]
        },
        {
            "id": 200,
            "categorie": "asie",
            "country": "Japon",
            "flag": "JP",
            "title": "Karaage",
            "subTitle": "Poulet frit à la japonaise mariné au gingembre, sauce soja et saké",
            "chicken_piece": [
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "800g de cuisses de poulet désossées, avec la peau",
                "2 cuillères à soupe de sauce soja",
                "2 cuillères à soupe de saké (ou de mirin)",
                "1 cuillère à soupe de gingembre frais, râpé",
                "2 gousses d'ail, écrasées",
                "1 cuillère à café d'huile de sésame",
                "1/2 cuillère à café de sucre",
                "Une pincée de poivre blanc moulu",
                "100g de fécule de pomme de terre (ou fécule de maïs)",
                "Huile végétale pour friture",
                "Pour servir:",
                "1 citron, coupé en quartiers",
                "Mayonnaise japonaise (Kewpie)",
                "Quelques feuilles de shiso ou de persil plat (facultatif)",
                "1 cuillère à café de piment japonais togarashi (facultatif)"
            ],
            "ingredient_liste": [
                "poulet",
                "sauce-soja",
                "sake",
                "gingembre",
                "ail",
                "huile-sesame",
                "sucre",
                "poivre-blanc",
                "fecule-pomme-terre",
                "huile-vegetale",
                "citron",
                "mayonnaise-japonaise",
                "shiso",
                "togarashi"
            ],
            "preparation": [
                "Couper les cuisses de poulet en morceaux de 4-5 cm, en laissant la peau.",
                "Dans un bol, mélanger la sauce soja, le saké, le gingembre râpé, l'ail écrasé, l'huile de sésame, le sucre et le poivre blanc.",
                "Ajouter les morceaux de poulet et bien les enrober de marinade.",
                "Couvrir et réfrigérer pendant au moins 30 minutes, idéalement 2 à 3 heures.",
                "Sortir le poulet du réfrigérateur 15 minutes avant la cuisson pour qu'il revienne à température ambiante.",
                "Égoutter les morceaux de poulet, en réservant la marinade pour une utilisation ultérieure si désiré.",
                "Placer la fécule de pomme de terre dans un sac en plastique ou un grand bol."
            ],
            "cuisson": [
                "Dans une casserole profonde ou un wok, chauffer l'huile à 170°C.",
                "Ajouter les morceaux de poulet dans la fécule, par lots, et secouer pour les enrober uniformément. Tapoter pour éliminer l'excès de fécule.",
                "Faire frire les morceaux de poulet par lots pendant 3-4 minutes, jusqu'à ce qu'ils soient dorés et croustillants.",
                "Augmenter la température de l'huile à 180°C et frire une seconde fois chaque lot pendant 1 minute supplémentaire pour un maximum de croustillant.",
                "Égoutter sur du papier absorbant.",
                "Servir immédiatement, garni de quartiers de citron, avec de la mayonnaise japonaise pour tremper, et saupoudré de togarashi si désiré."
            ],
            "cooking_methods": {
                "best": "friture",
                "grill": false,
                "plancha": false,
                "friture": true,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "friture"
            ],
            "accompagnement": "Servir avec du riz blanc japonais et une salade de chou à la japonaise. Pour le riz: rincer 400g de riz japonais à grain court jusqu'à ce que l'eau soit claire. Cuire selon les instructions (généralement avec un ratio de 1:1.1 riz/eau) dans un cuiseur à riz ou une casserole. Pour la salade de chou: finement émincer 1/4 de chou blanc et le plonger dans un bol d'eau glacée pendant 5 minutes pour le rendre plus croquant. Égoutter et sécher soigneusement. Préparer une vinaigrette en mélangeant 3 cuillères à soupe de mayonnaise japonaise, 1 cuillère à soupe de vinaigre de riz, 1 cuillère à café de sauce soja, 1/2 cuillère à café de sucre et une pincée de sel. Mélanger avec le chou juste avant de servir. Accompagner également de tsukemono (légumes marinés japonais) et de miso-shiru (soupe miso): dans 500ml de dashi (bouillon japonais), ajouter 3 cuillères à soupe de pâte miso, 100g de tofu coupé en dés et 2 oignons verts émincés. Chauffer sans faire bouillir et servir immédiatement.",
            "shortStory": "Le **Karaage** est l'un des plats de poulet les plus populaires du Japon, présent aussi bien dans les izakayas (bars à tapas japonais) que dans les bentō (boîtes-repas) et la cuisine familiale. Cette technique de friture, dont le nom signifie littéralement \"friture chinoise\", aurait été introduite au Japon dans les années 1920, mais a été perfectionnée et adaptée aux goûts japonais. La marinade à base de **sauce soja**, de **saké** et de **gingembre** est essentielle pour imprégner la viande de saveurs umami caractéristiques de la cuisine japonaise. Contrairement au poulet frit occidental, le karaage utilise traditionnellement de la **fécule de pomme de terre** plutôt que de la farine de blé, ce qui donne une texture particulièrement légère et croustillante. Dans la région de Oita, sur l'île de Kyushu, considérée comme le berceau du karaage moderne, on trouve de nombreux établissements spécialisés uniquement dans ce plat, chacun avec sa recette secrète. Le karaage est si apprécié au Japon qu'il existe même une Japan Karaage Association qui organise des compétitions nationales pour élire le meilleur poulet frit du pays. Ce plat représente parfaitement l'art japonais d'adopter des influences étrangères tout en les transformant en quelque chose d'uniquement japonais.",
            "detailURL": "/fiche-recette/200/poulet-frit-soja-gingembre/karaage.html",
            "time_prepare": "20mn",
            "time_maceration": "2h",
            "time_cooking": "15mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2), les ingrédients spécifiques comme le saké et la mayonnaise japonaise pouvant être trouvés dans les épiceries asiatiques ou remplacés par des alternatives plus courantes."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Cette recette n'est pas épicée (niveau 0) dans sa version classique, mais peut devenir légèrement piquante (niveau 1) si on ajoute du togarashi comme condiment final."
            },
            "calories": "380 kcal",
            "allergens": [
                "soja",
                "oeuf"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne (niveau 2), la technique de double friture demandant un peu d'attention pour obtenir la texture parfaite."
            },
            "equipment_needed": [
                "thermometre-cuisine",
                "wok",
                "papier-absorbant"
            ]
        },
        {
            "id": 202,
            "categorie": "caraibes",
            "country": "Jamaïque",
            "flag": "JM",
            "title": "Brown Stew Chicken",
            "subTitle": "Ragoût de poulet caramélisé aux saveurs des Caraïbes",
            "chicken_piece": [
                "cuisse",
                "pilon"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 kg de cuisses et pilons de poulet",
                "2 cuillères à soupe de sauce soja foncée",
                "2 cuillères à soupe de cassonade",
                "2 cuillères à soupe de ketchup",
                "1 cuillère à soupe de sauce Worcestershire",
                "2 cuillères à soupe d'huile végétale",
                "1 gros oignon, coupé en dés",
                "2 poivrons (1 vert, 1 rouge), coupés en dés",
                "4 gousses d'ail, finement hachées",
                "1 cuillère à soupe de gingembre frais, râpé",
                "2 tiges de thym frais",
                "2 oignons verts, émincés",
                "1 piment scotch bonnet (ou habanero), épépiné et haché finement",
                "2 tomates moyennes, coupées en dés",
                "1 cuillère à café de paprika fumé",
                "1/2 cuillère à café de cannelle moulue",
                "1/2 cuillère à café de muscade fraîchement râpée",
                "1/4 cuillère à café de piment de la Jamaïque moulu",
                "400 ml de bouillon de poulet",
                "1 cuillère à café de concentré de tomate",
                "Sel et poivre noir fraîchement moulu",
                "2 cuillères à soupe de persil frais, haché (pour garnir)"
            ],
            "ingredient_liste": [
                "poulet",
                "sauce-soja",
                "cassonade",
                "ketchup",
                "sauce-worcestershire",
                "huile-vegetale",
                "oignon",
                "poivron",
                "ail",
                "gingembre",
                "thym",
                "oignon-vert",
                "piment-scotch-bonnet",
                "tomate",
                "paprika-fume",
                "cannelle",
                "muscade",
                "piment-jamaique",
                "bouillon-poulet",
                "concentre-tomate",
                "sel",
                "poivre-noir",
                "persil"
            ],
            "preparation": [
                "Dans un grand bol, mélanger le poulet avec la sauce soja, la moitié de la cassonade, 1 cuillère à soupe de ketchup et la sauce Worcestershire. Bien masser la viande avec cette marinade.",
                "Couvrir et laisser mariner au réfrigérateur pendant au moins 2 heures, idéalement toute la nuit.",
                "Sortir le poulet de la marinade en le secouant légèrement pour éliminer l'excès de liquide (réserver la marinade).",
                "Dans une grande cocotte ou un faitout à fond épais, chauffer l'huile à feu moyen-vif.",
                "Ajouter le reste de la cassonade et laisser caraméliser légèrement (environ 1 minute) en remuant constamment pour éviter qu'elle ne brûle."
            ],
            "cuisson": [
                "Ajouter les morceaux de poulet dans la cassonade caramélisée et faire dorer sur tous les côtés pendant environ 5-7 minutes, jusqu'à ce qu'ils prennent une belle couleur brune.",
                "Réduire le feu à moyen et ajouter l'oignon, les poivrons, l'ail, le gingembre et les tiges de thym. Faire revenir pendant 3-4 minutes jusqu'à ce que les légumes commencent à ramollir.",
                "Incorporer les oignons verts, le piment scotch bonnet, les tomates, le paprika fumé, la cannelle, la muscade et le piment de la Jamaïque. Mélanger pour bien enrober le poulet et les légumes.",
                "Verser le bouillon de poulet, la marinade réservée, le reste du ketchup et le concentré de tomate. Mélanger délicatement.",
                "Porter à ébullition, puis réduire le feu, couvrir et laisser mijoter doucement pendant 40-45 minutes, en remuant occasionnellement, jusqu'à ce que le poulet soit tendre et la sauce légèrement épaissie.",
                "Retirer les tiges de thym, ajuster l'assaisonnement avec du sel et du poivre selon votre goût.",
                "Garnir de persil frais haché avant de servir."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": true,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte",
                "poele"
            ],
            "accompagnement": "Servir avec du riz aux pois et des plantains frits. Pour le riz aux pois: rincer 400g de riz long grain et égoutter. Dans une casserole, faire chauffer 2 cuillères à soupe d'huile de coco et faire revenir 1 oignon haché et 2 gousses d'ail émincées jusqu'à ce qu'ils soient translucides. Ajouter 1 boîte (400g) de haricots rouges égouttés (ou de pois à œil noir traditionnels), 1 cuillère à café de thym séché, 1 piment scotch bonnet entier (non coupé), 400ml de lait de coco et 200ml d'eau. Porter à ébullition, puis ajouter le riz. Réduire le feu, couvrir et laisser mijoter pendant 20 minutes ou jusqu'à ce que le riz soit tendre et que tout le liquide soit absorbé. Retirer le piment entier avant de servir. Pour les plantains frits: éplucher 2 plantains mûrs (à peau noire tachetée) et les couper en tranches diagonales d'environ 1 cm d'épaisseur. Dans une poêle, chauffer 4 cuillères à soupe d'huile végétale à feu moyen-vif. Faire frire les tranches de plantain pendant 2-3 minutes de chaque côté, jusqu'à ce qu'elles soient dorées et caramélisées. Égoutter sur du papier absorbant et saupoudrer légèrement de sel.",
            "shortStory": "Le **Brown Stew Chicken** est un plat réconfortant et emblématique de la cuisine **jamaïcaine**, présent sur toutes les tables familiales de l'île. Cette recette tire son nom de la technique de caramélisation du sucre qui donne au poulet sa couleur brune caractéristique et sa saveur profonde. Ce procédé, appelé localement \"browning\", est une technique de cuisine africaine qui a été préservée dans les Caraïbes. Le ragoût combine des influences culinaires africaines, européennes et indiennes, reflétant l'histoire multiculturelle de la **Jamaïque**. Le mariage des **épices locales** comme le **piment de la Jamaïque** avec la douceur de la cassonade et le piquant du **scotch bonnet** crée un équilibre parfait entre saveurs sucrées, salées et épicées. Dans les foyers jamaïcains, chaque famille a sa propre version du brown stew chicken, transmise de génération en génération, avec des variations subtiles dans les épices utilisées ou la durée de caramélisation. Traditionnellement servi le dimanche pour le repas familial, ce plat s'accompagne presque toujours de riz aux pois, autre pilier de la cuisine jamaïcaine, formant un repas complet qui symbolise l'hospitalité et la convivialité caractéristiques de l'île.",
            "detailURL": "/fiche-recette/202/poulet-caramelise-ragout/brown-stew-chicken.html",
            "time_prepare": "20mn",
            "time_maceration": "2h",
            "time_cooking": "1h",
            "crispys": false,
            "cost": {
                "level": 1,
                "description": "Cette recette est économique (niveau 1), utilisant principalement des ingrédients de base et nécessitant peu d'épices coûteuses. Le piment scotch bonnet peut être remplacé par un piment plus commun si nécessaire."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Cette recette est assez épicée (niveau 3) en raison de l'utilisation du piment scotch bonnet. L'intensité peut être ajustée en réduisant la quantité de piment ou en le retirant complètement pour une version plus douce."
            },
            "calories": "420 kcal",
            "allergens": [
                "soja"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne (niveau 2), la principale technique à maîtriser étant la caramélisation du sucre sans le brûler."
            },
            "equipment_needed": [
                "cocotte",
                "couteau"
            ]
        },
        {
            "id": 201,
            "categorie": "caraibes",
            "country": "Jamaïque",
            "flag": "JM",
            "title": "Jerk Chicken",
            "subTitle": "Poulet mariné aux épices jamaïcaines relevées, grillé au feu de bois",
            "chicken_piece": [
                "entier",
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier d'environ 1,5 kg, coupé en morceaux (ou 8 cuisses de poulet)",
                "Pour la marinade jerk:",
                "6 oignons verts (ciboule), grossièrement hachés",
                "4 gousses d'ail, écrasées",
                "2 piments scotch bonnet, épépinés (ou habanero)",
                "3 cm de gingembre frais, râpé",
                "3 cuillères à soupe de sauce soja",
                "2 cuillères à soupe d'huile végétale",
                "2 cuillères à soupe de vinaigre de cidre",
                "2 cuillères à soupe de jus de lime",
                "2 cuillères à soupe de cassonade",
                "1 cuillère à soupe de thym frais (ou 1 cuillère à café de thym séché)",
                "1 cuillère à café de piment de la Jamaïque moulu (allspice)",
                "1 cuillère à café de cannelle moulue",
                "1 cuillère à café de muscade râpée",
                "1/2 cuillère à café de poivre noir moulu",
                "1/2 cuillère à café de cumin moulu",
                "1/2 cuillère à café de coriandre moulue",
                "1 cuillère à café de sel",
                "Pour servir:",
                "2 limes, coupées en quartiers",
                "Quelques branches de thym frais"
            ],
            "ingredient_liste": [
                "poulet",
                "oignon-vert",
                "ail",
                "piment-scotch-bonnet",
                "gingembre",
                "sauce-soja",
                "huile-vegetale",
                "vinaigre-cidre",
                "lime",
                "cassonade",
                "thym",
                "piment-jamaique",
                "cannelle",
                "muscade",
                "poivre",
                "cumin",
                "coriandre",
                "sel"
            ],
            "preparation": [
                "Dans un robot culinaire, mixer tous les ingrédients de la marinade jusqu'à obtenir une pâte homogène.",
                "Inciser la chair du poulet à plusieurs endroits pour permettre à la marinade de bien pénétrer.",
                "Dans un grand plat ou un sac de congélation, placer les morceaux de poulet et les enrober généreusement de marinade, en la faisant pénétrer dans les incisions.",
                "Couvrir (ou fermer le sac) et réfrigérer pendant au moins 6 heures, idéalement toute une nuit, en retournant occasionnellement."
            ],
            "cuisson": [
                "Sortir le poulet du réfrigérateur 30 minutes avant la cuisson pour qu'il revienne à température ambiante.",
                "Préparation traditionnelle: idéalement, utiliser un barbecue au charbon de bois avec des copeaux de bois d'allspice (piment de la Jamaïque) humidifiés pour la fumée. Cuire les morceaux de poulet à chaleur indirecte, couvercle fermé, pendant environ 45 minutes, en les retournant régulièrement.",
                "Méthode alternative au four: préchauffer le four à 180°C. Placer les morceaux de poulet sur une grille au-dessus d'une lèchefrite remplie d'eau (pour éviter la fumée). Cuire pendant environ 40-45 minutes, en retournant à mi-cuisson.",
                "Pour les deux méthodes, finir par 5 minutes de cuisson directe à feu vif pour caraméliser l'extérieur.",
                "Le poulet est cuit lorsque le jus qui s'en écoule est clair et que la température interne atteint 75°C.",
                "Laisser reposer le poulet pendant 5-10 minutes avant de servir.",
                "Garnir de quartiers de lime et de branches de thym frais."
            ],
            "cooking_methods": {
                "best": "barbecue",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": true,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "barbecue",
                "grill",
                "four"
            ],
            "accompagnement": "Servir avec du riz et pois (rice and peas) et des bananes plantain frites. Pour le rice and peas: rincer 400g de riz long jusqu'à ce que l'eau soit claire. Dans une casserole, faire revenir 1 oignon haché avec 2 gousses d'ail dans 2 cuillères à soupe d'huile. Ajouter 1 boîte de haricots rouges égouttés (ou haricots 'kidney'), 400ml de lait de coco, 600ml d'eau, 2 branches de thym, 1 piment scotch bonnet entier (pour parfumer sans trop de piquant), 2 oignons verts émincés et 1 cuillère à café de sel. Porter à ébullition, puis ajouter le riz. Couvrir et cuire à feu doux pendant 18-20 minutes. Retirer le piment et les branches de thym avant de servir. Pour les bananes plantain: choisir des bananes plantain bien mûres (peau noire). Les peler et les couper en diagonale en tranches de 1 cm d'épaisseur. Les faire frire dans de l'huile chaude pendant environ 2 minutes de chaque côté, jusqu'à ce qu'elles soient dorées. Égoutter sur du papier absorbant et saupoudrer légèrement de sel.",
            "shortStory": "Le **Jerk Chicken** est l'un des plats les plus emblématiques de la cuisine jamaïcaine, dont les origines remontent aux Marrons, esclaves africains fugitifs qui se sont réfugiés dans les montagnes de Jamaïque au 17ème siècle. La technique du \"jerk\" consiste à mariner la viande dans un mélange d'**épices** et de **piments** avant de la cuire lentement sur un feu de bois, souvent d'**allspice** (piment de la Jamaïque). Cette méthode permettait non seulement de conserver la viande mais aussi de cuisiner sans produire beaucoup de fumée visible, aidant les Marrons à éviter d'être détectés. Le piment **scotch bonnet**, l'un des plus forts au monde, est l'ingrédient signature de cette préparation, apportant à la fois chaleur et saveur fruitée distinctive. L'utilisation d'herbes comme le **thym** et d'épices comme l'allspice reflète le métissage culturel jamaïcain, mêlant influences africaines, européennes et amérindiennes. Aujourd'hui, dans toute la Jamaïque, particulièrement à Boston Bay, considéré comme le berceau du jerk moderne, on trouve des \"jerk centers\" où ce plat est préparé traditionnellement sur des grilles métalliques posées au-dessus de feux de bois fumants, offrant une expérience culinaire authentique aux saveurs complexes et intenses.",
            "detailURL": "/fiche-recette/201/poulet-epices-piment-grille/jerk-chicken.html",
            "time_prepare": "30mn",
            "time_maceration": "6h",
            "time_cooking": "45mn",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2), les piments scotch bonnet pouvant être le plus difficile à trouver mais remplaçables par des habaneros plus courants."
            },
            "images": [],
            "hot": {
                "level": 3,
                "description": "Cette recette est très épicée (niveau 3), le scotch bonnet étant l'un des piments les plus forts au monde. L'intensité peut être ajustée en réduisant la quantité de piment ou en retirant plus soigneusement les graines et membranes."
            },
            "calories": "440 kcal",
            "allergens": [
                "soja"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne (niveau 2), nécessitant principalement du temps pour la marinade et une attention à la cuisson pour éviter que les épices ne brûlent."
            },
            "equipment_needed": [
                "barbecue",
                "robot-culinaire",
                "thermometre-cuisine"
            ]
        },
        {
            "id": 202,
            "categorie": "moyen-orient",
            "country": "Jordanie",
            "flag": "JO",
            "title": "Musakhan",
            "subTitle": "Poulet rôti aux oignons caramélisés, sumac et épices, servi sur pain taboun",
            "chicken_piece": [
                "entier",
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier d'environ 1,5 kg, coupé en morceaux (ou 8 cuisses de poulet)",
                "4 gros oignons, finement émincés",
                "120ml d'huile d'olive",
                "4 cuillères à soupe de sumac, plus un peu pour garnir",
                "2 cuillères à café de cardamome moulue",
                "1 cuillère à café de cumin moulu",
                "1 cuillère à café d'allspice (piment de la Jamaïque)",
                "1/2 cuillère à café de cannelle moulue",
                "1/4 cuillère à café de muscade râpée",
                "1/2 cuillère à café de piment d'Alep (ou paprika fumé)",
                "4 gousses d'ail, écrasées",
                "Le jus d'un citron",
                "1 1/2 cuillère à café de sel",
                "1/2 cuillère à café de poivre noir fraîchement moulu",
                "4 pains plats arabes (type markook, taboun ou lavash)",
                "60g de pignons de pin, grillés",
                "2 cuillères à soupe de persil frais, haché",
                "2 cuillères à soupe d'amandes effilées, grillées (facultatif)"
            ],
            "ingredient_liste": [
                "poulet",
                "oignon",
                "huile-olive",
                "sumac",
                "cardamome",
                "cumin",
                "allspice",
                "cannelle",
                "muscade",
                "piment-alep",
                "ail",
                "citron",
                "sel",
                "poivre",
                "pain-plat",
                "pignon-pin",
                "persil",
                "amande"
            ],
            "preparation": [
                "Préchauffer le four à 190°C.",
                "Dans un grand bol, mélanger 3 cuillères à soupe d'huile d'olive, la moitié du sumac, la cardamome, le cumin, l'allspice, la cannelle, la muscade, le piment d'Alep, l'ail, le jus de citron, le sel et le poivre.",
                "Ajouter les morceaux de poulet et bien les enrober de marinade.",
                "Laisser mariner pendant au moins 1 heure, idéalement 3-4 heures au réfrigérateur.",
                "Dans une grande poêle, faire chauffer le reste de l'huile d'olive à feu moyen.",
                "Ajouter les oignons émincés et une pincée de sel. Faire revenir en remuant régulièrement pendant environ 15-20 minutes, jusqu'à ce qu'ils soient bien dorés et caramélisés.",
                "Incorporer le reste du sumac aux oignons, remuer et retirer du feu."
            ],
            "cuisson": [
                "Placer les morceaux de poulet marinés sur une plaque de cuisson, côté peau vers le haut.",
                "Rôtir au four pendant environ 35-40 minutes, jusqu'à ce que le poulet soit bien doré et cuit (température interne de 75°C), en arrosant occasionnellement avec les jus de cuisson.",
                "Pendant ce temps, tapisser le fond d'un grand plat allant au four avec les pains plats, en les superposant légèrement.",
                "Une fois le poulet cuit, récupérer les jus de cuisson et les mélanger aux oignons caramélisés.",
                "Étaler le mélange d'oignons sur les pains plats.",
                "Réduire la température du four à 150°C.",
                "Placer le plat de pain et d'oignons au four pendant environ 5-7 minutes, juste pour réchauffer le pain et lui permettre d'absorber les saveurs.",
                "Disposer les morceaux de poulet sur le lit d'oignons et de pain.",
                "Parsemer de pignons de pin grillés, d'amandes effilées si utilisées, de persil haché et d'un peu de sumac supplémentaire."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": true,
                "cocotte": false
            },
            "mode_cuisson": [
                "four",
                "poele"
            ],
            "accompagnement": "Servir avec une salade de concombre au yaourt (khyar bil laban) et des pickles de légumes. Pour la salade au yaourt: mélanger 2 concombres râpés et pressés pour éliminer l'excès d'eau avec 500g de yaourt grec, 2 gousses d'ail écrasées, 2 cuillères à soupe de menthe fraîche ciselée, 1 cuillère à soupe d'huile d'olive, le jus d'un demi-citron, du sel et du poivre. Pour les pickles: dans une casserole, porter à ébullition 500ml de vinaigre blanc avec 250ml d'eau, 2 cuillères à soupe de sel et 1 cuillère à soupe de sucre. Préparer un mélange de légumes coupés en morceaux: 2 carottes, 2 concombres, 1 poivron, 1 chou-fleur et 5 gousses d'ail. Ajouter 1 cuillère à café de graines de moutarde et 1 cuillère à café de graines de coriandre. Verser le vinaigre bouillant sur les légumes dans un bocal stérilisé. Fermer et laisser mariner au moins 24h avant de servir. Accompagner également d'olives marinées et de quartiers de citron.",
            "shortStory": "Le **Musakhan** est considéré comme le plat national palestinien, très populaire également en Jordanie et dans tout le Levant. Son nom dérive de l'arabe \"sakhana\" qui signifie \"chauffé\" ou \"réchauffé\". Ce plat rustique et savoureux est emblématique de la cuisine paysanne de la région, particulièrement des villages autour de Jénine et de Tulkarem. L'ingrédient star de cette recette est le **sumac**, une épice rouge-violacée au goût acidulé, récoltée dans les collines du Levant depuis des millénaires. Les **oignons** caramélisés, riches en saveur umami, constituent la base aromatique du plat. Traditionnellement, le Musakhan était cuit dans un **taboun**, four à pain en argile alimenté au feu de bois, donnant au pain et au poulet une saveur légèrement fumée caractéristique. Ce plat est profondément ancré dans la culture locale et est souvent préparé pour les grandes réunions familiales ou lors des récoltes d'olives, l'**huile d'olive** fraîchement pressée étant un élément essentiel de la recette. Dans les familles jordaniennes et palestiniennes, le Musakhan est généralement servi sur une grande assiette commune, symbolisant le partage et la convivialité si importants dans la culture arabe.",
            "detailURL": "/fiche-recette/202/poulet-sumac-oignons-pain/musakhan.html",
            "time_prepare": "30mn",
            "time_maceration": "1h",
            "time_cooking": "1h",
            "crispys": true,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2), le sumac étant l'ingrédient le plus spécifique mais désormais disponible dans de nombreuses épiceries orientales ou en ligne."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Cette recette n'est pas épicée (niveau 0), offrant plutôt des saveurs aromatiques complexes avec une légère acidité due au sumac."
            },
            "calories": "520 kcal",
            "allergens": [
                "fruits-a-coque",
                "gluten"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne (niveau 2), nécessitant plusieurs étapes mais sans technique particulièrement complexe."
            },
            "equipment_needed": [
                "four",
                "plat-four",
                "poele",
                "thermometre-cuisine"
            ]
        },
        {
            "id": 203,
            "categorie": "asie",
            "country": "Kazakhstan",
            "flag": "KZ",
            "title": "Qazaq Tawigh",
            "subTitle": "Poulet kazakh aux épices d'Asie centrale et fruits secs, mijoté avec des légumes racines",
            "chicken_piece": [
                "entier",
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier d'environ 1,5 kg, coupé en morceaux (ou 8 cuisses de poulet)",
                "3 cuillères à soupe d'huile végétale",
                "2 gros oignons, émincés en demi-lunes",
                "3 carottes, coupées en bâtonnets",
                "2 navets, coupés en quartiers",
                "4 gousses d'ail, écrasées",
                "2 cm de gingembre frais, râpé",
                "1 cuillère à café de cumin moulu",
                "1 cuillère à café de coriandre moulue",
                "1 cuillère à café de paprika",
                "1/2 cuillère à café de cannelle moulue",
                "1/4 cuillère à café de cardamome moulue",
                "1/4 cuillère à café de poivre de Cayenne (ajuster selon le goût)",
                "2 feuilles de laurier",
                "3 clous de girofle entiers",
                "5 baies de genièvre (facultatif)",
                "80g d'abricots secs, coupés en deux",
                "50g de pruneaux dénoyautés, coupés en deux",
                "40g de raisins secs golden",
                "500ml de bouillon de poulet",
                "2 cuillères à soupe de concentré de tomate",
                "1 cuillère à soupe de miel",
                "1 1/2 cuillère à café de sel",
                "1/2 cuillère à café de poivre noir moulu",
                "30g d'amandes effilées, grillées",
                "2 cuillères à soupe de coriandre fraîche, hachée",
                "1 cuillère à soupe de persil frais, haché"
            ],
            "ingredient_liste": [
                "poulet",
                "huile-vegetale",
                "oignon",
                "carotte",
                "navet",
                "ail",
                "gingembre",
                "cumin",
                "coriandre",
                "paprika",
                "cannelle",
                "cardamome",
                "poivre-cayenne",
                "laurier",
                "clou-girofle",
                "baie-genievre",
                "abricot-sec",
                "pruneau",
                "raisin-sec",
                "bouillon-poulet",
                "concentre-tomate",
                "miel",
                "sel",
                "poivre",
                "amande",
                "coriandre-fraiche",
                "persil"
            ],
            "preparation": [
                "Saler et poivrer généreusement les morceaux de poulet.",
                "Dans une grande cocotte ou une marmite à fond épais, faire chauffer l'huile à feu moyen-vif.",
                "Faire dorer les morceaux de poulet sur toutes les faces, environ 3-4 minutes par côté. Procéder par lots si nécessaire. Réserver.",
                "Dans la même cocotte, faire revenir les oignons à feu moyen jusqu'à ce qu'ils commencent à dorer (environ 5 minutes).",
                "Ajouter l'ail et le gingembre, poursuivre la cuisson pendant 1 minute.",
                "Incorporer toutes les épices moulues (cumin, coriandre, paprika, cannelle, cardamome, poivre de Cayenne) et faire revenir 1 minute en remuant constamment."
            ],
            "cuisson": [
                "Ajouter les carottes et les navets, et faire revenir pendant 5 minutes.",
                "Incorporer le concentré de tomate et faire revenir 1 minute supplémentaire.",
                "Remettre les morceaux de poulet dans la cocotte, ajouter les feuilles de laurier, les clous de girofle et les baies de genièvre.",
                "Verser le bouillon de poulet, ajouter le miel et porter à ébullition.",
                "Réduire le feu, couvrir et laisser mijoter pendant 25 minutes.",
                "Ajouter tous les fruits secs (abricots, pruneaux, raisins), mélanger délicatement.",
                "Poursuivre la cuisson à couvert pendant 15-20 minutes supplémentaires, jusqu'à ce que le poulet soit tendre et bien cuit.",
                "Goûter et ajuster l'assaisonnement si nécessaire.",
                "Avant de servir, parsemer d'amandes effilées grillées, de coriandre et de persil frais hachés."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec du riz pilaf à la mode kazakhe et des légumes marinés. Pour le pilaf: dans une casserole à fond épais, faire chauffer 3 cuillères à soupe d'huile végétale. Ajouter 1 oignon émincé et faire revenir jusqu'à ce qu'il soit doré. Ajouter 2 carottes coupées en julienne et faire revenir 3 minutes. Incorporer 400g de riz à grain long rincé, 1 cuillère à café de cumin, 1/2 cuillère à café de curcuma, 1 feuille de laurier, 3 gousses d'ail entières non pelées et 50g de raisins secs. Verser 800ml de bouillon chaud, saler, porter à ébullition puis réduire le feu, couvrir et laisser cuire à feu doux pendant 18-20 minutes. Laisser reposer 10 minutes avant de servir. Pour les légumes marinés: émincez finement 1/2 chou blanc, 1 carotte et 1 concombre. Dans un bol, mélanger 3 cuillères à soupe de vinaigre de cidre, 2 cuillères à soupe d'huile de tournesol, 1 cuillère à café de sucre, 1 cuillère à café de sel et 1 cuillère à café de graines de carvi. Verser sur les légumes, bien mélanger et laisser mariner 1 heure minimum.",
            "shortStory": "Le **Qazaq Tawigh** (poulet kazakh) est une adaptation des traditions culinaires nomades des steppes d'Asie centrale, où le mouton était traditionnellement la viande la plus consommée. Avec l'établissement de communautés sédentaires au Kazakhstan, la volaille a progressivement intégré le répertoire culinaire. Ce plat reflète l'influence de la Route de la Soie qui traversait le Kazakhstan, apportant **épices** et **fruits secs** d'Orient. Les saveurs sucrées-salées sont caractéristiques de la cuisine d'Asie centrale, avec des **abricots** et des **pruneaux** qui contrebalancent les épices terreuses comme le **cumin** et la coriandre. Dans les régions méridionales du Kazakhstan, particulièrement autour d'Almaty, ce type de plat mijoté est souvent préparé pour les célébrations familiales comme Nauryz (le Nouvel An persan). Les techniques de cuisson lente permettaient traditionnellement d'économiser le combustible rare dans les steppes, tout en développant des saveurs complexes. Ce plat représente le métissage culturel kazakh, mêlant influences turciques, mongoles, russes et persanes qui ont façonné l'identité culinaire de cette région à la croisée des mondes nomade et sédentaire, oriental et occidental.",
            "detailURL": "/fiche-recette/203/poulet-epices-fruits-secs-legumes/qazaq-tawigh.html",
            "time_prepare": "30mn",
            "time_maceration": "0h",
            "time_cooking": "1h10",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2), les fruits secs représentant l'investissement le plus important mais étant disponibles dans la plupart des supermarchés."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette est légèrement épicée (niveau 1), le poivre de Cayenne apportant une chaleur modérée qui peut être ajustée selon les préférences."
            },
            "calories": "470 kcal",
            "allergens": [
                "fruits-a-coque"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne (niveau 2), nécessitant plusieurs étapes mais sans technique particulièrement complexe."
            },
            "equipment_needed": [
                "cocotte",
                "couteau-cuisine"
            ]
        }, {
            "id": 204,
            "categorie": "moyen-orient",
            "country": "Jordanie",
            "flag": "JO",
            "title": "Musakhan",
            "subTitle": "Poulet rôti aux oignons caramélisés, sumac et pain taboun",
            "chicken_piece": [
                "entier",
                "cuisse"
            ],
            "piece": {
                "best": "cuisse",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": true,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier d'environ 1,5 kg, découpé en 8 morceaux (ou 6-8 cuisses de poulet)",
                "6 gros oignons, finement émincés",
                "150 ml d'huile d'olive extra vierge (de préférence palestinienne ou jordanienne)",
                "4 cuillères à soupe de sumac, plus un peu pour servir",
                "2 cuillères à café de cardamome moulue",
                "1 cuillère à café de cannelle moulue",
                "1 cuillère à café d'allspice (ou piment de la Jamaïque)",
                "1/2 cuillère à café de poivre noir moulu",
                "1/4 cuillère à café de noix de muscade moulue",
                "4 gousses d'ail, écrasées",
                "1 cuillère à soupe de graines de pin",
                "1 cuillère à soupe d'amandes effilées",
                "4 pains plats arabes (taboun, markook ou lavash)",
                "Sel, selon votre goût",
                "1 citron, coupé en quartiers, pour servir",
                "2 cuillères à soupe de persil plat frais, haché, pour garnir",
                "Yaourt nature, pour servir (facultatif)"
            ],
            "ingredient_liste": [
                "poulet",
                "oignon",
                "huile-olive",
                "sumac",
                "cardamome",
                "cannelle",
                "allspice",
                "poivre-noir",
                "muscade",
                "ail",
                "graine-pin",
                "amande",
                "pain-arabe",
                "sel",
                "citron",
                "persil",
                "yaourt"
            ],
            "preparation": [
                "Préchauffer le four à 200°C (thermostat 6-7).",
                "Dans un grand bol, mélanger 50 ml d'huile d'olive avec 1 cuillère à soupe de sumac, la cardamome, la cannelle, l'allspice, le poivre noir, la muscade, la moitié de l'ail écrasé et du sel.",
                "Frotter ce mélange d'épices sur le poulet, en veillant à bien l'enduire sous la peau lorsque c'est possible.",
                "Laisser mariner à température ambiante pendant 30 minutes ou au réfrigérateur jusqu'à 24 heures.",
                "Pendant ce temps, dans une grande poêle, chauffer le reste de l'huile d'olive à feu moyen-doux.",
                "Ajouter les oignons émincés et une pincée de sel, puis cuire doucement pendant 25-30 minutes, en remuant de temps en temps, jusqu'à ce qu'ils soient bien caramélisés et dorés.",
                "Ajouter le reste de l'ail et cuire encore 2 minutes.",
                "Retirer du feu, ajouter le reste du sumac et bien mélanger. Réserver."
            ],
            "cuisson": [
                "Placer le poulet mariné sur une plaque de cuisson et rôtir au four pendant 35-40 minutes, ou jusqu'à ce que le poulet soit bien doré et que la température interne atteigne 75°C.",
                "Pendant les 10 dernières minutes de cuisson du poulet, faire griller les graines de pin et les amandes dans une poêle sèche à feu moyen, en remuant constamment, jusqu'à ce qu'elles soient légèrement dorées. Réserver.",
                "Réduire la température du four à 160°C.",
                "Huiler légèrement un grand plat allant au four.",
                "Étaler un pain plat dans le fond du plat, puis répartir la moitié des oignons caramélisés dessus.",
                "Placer un deuxième pain par-dessus et répartir le reste des oignons.",
                "Disposer les morceaux de poulet cuit sur les oignons.",
                "Récupérer les jus de cuisson du poulet et les verser sur l'ensemble.",
                "Couvrir avec les pains plats restants.",
                "Enfourner pendant 10 minutes pour que les pains absorbent les saveurs et les jus.",
                "Retirer du four, saupoudrer de graines de pin et d'amandes grillées, ainsi que de persil haché."
            ],
            "cooking_methods": {
                "best": "four",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": false,
                "cocotte": false
            },
            "mode_cuisson": [
                "four"
            ],
            "accompagnement": "Servir avec une salade fattoush fraîche et du yaourt à la menthe. Pour la salade fattoush: mélanger 2 tomates coupées en dés, 1 concombre épépiné et coupé en dés, 1 poivron vert coupé en dés, 4 radis émincés, 3 oignons verts hachés et 1/2 tasse de persil frais et de menthe hachés. Ajouter 1 pain pita grillé et coupé en morceaux. Pour la vinaigrette, mélanger 3 cuillères à soupe d'huile d'olive, 2 cuillères à soupe de jus de citron frais, 1 gousse d'ail écrasée, 1 cuillère à café de sumac, 1/2 cuillère à café de sel et 1/4 cuillère à café de poivre noir fraîchement moulu. Verser sur la salade et mélanger juste avant de servir. Pour le yaourt à la menthe: mélanger 400g de yaourt grec épais avec 2 cuillères à soupe de menthe fraîche finement hachée, 1 cuillère à soupe d'huile d'olive, 1 gousse d'ail finement râpée (facultatif), une pincée de sel et une pincée de sumac. Servir également des pickles d'olives et de légumes variés comme accompagnement traditionnel.",
            "shortStory": "Le **Musakhan** est considéré comme le plat national de la Palestine, mais il est tout aussi populaire en **Jordanie** où il reflète l'héritage culinaire partagé de la région du Levant. Ce plat traditionnel remonte à plusieurs siècles et était à l'origine préparé pour célébrer la récolte des olives, mettant en valeur la nouvelle **huile d'olive** pressée de la saison. Le mot \"musakhan\" signifie \"chauffé\" ou \"réchauffé\" en arabe, faisant référence à la façon dont le pain absorbe les jus du poulet et de l'huile d'olive. L'ingrédient emblématique du musakhan est le **sumac**, une épice d'un rouge profond au goût acidulé qui donne au plat sa couleur distinctive et sa saveur unique. Traditionnellement, ce plat était cuit dans des fours en terre cuite (taboun) creusés dans le sol, ce qui lui conférait une saveur fumée caractéristique. Dans les villages jordaniens et palestiniens, le musakhan reste un plat de célébration et de partage, souvent servi lors des rassemblements familiaux. La préparation varie légèrement d'une région à l'autre et d'une famille à l'autre, mais les éléments essentiels - poulet, oignons caramélisés, sumac et pain - demeurent constants, témoignant de l'importance culturelle de ce plat emblématique du **Moyen-Orient**.",
            "detailURL": "/fiche-recette/204/poulet-oignons-sumac/musakhan.html",
            "time_prepare": "45mn",
            "time_maceration": "30mn",
            "time_cooking": "1h",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2). Bien que la plupart des ingrédients soient accessibles, le sumac et une bonne huile d'olive peuvent représenter un investissement initial."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette est légèrement relevée (niveau 1) grâce au mélange d'épices, mais elle n'est pas piquante. Le sumac apporte une saveur acidulée plutôt qu'épicée."
            },
            "calories": "520 kcal",
            "allergens": [
                "fruits-a-coque",
                "gluten",
                "lait"
            ],
            "difficulty": {
                "level": 3,
                "description": "Recette de difficulté modérée (niveau 3), nécessitant plusieurs étapes de préparation et une bonne maîtrise de la caramélisation des oignons et de la cuisson du poulet."
            },
            "equipment_needed": [
                "four",
                "poele",
                "plat-four",
                "thermometre-cuisine"
            ]
        },
        {
            "id": 205,
            "categorie": "moyen-orient",
            "country": "Jordanie",
            "flag": "JO",
            "title": "Mansaf",
            "subTitle": "Poulet mariné au yaourt fermenté (jameed) sur lit de riz et de pain",
            "chicken_piece": [
                "entier",
                "cuisse"
            ],
            "piece": {
                "best": "entier",
                "entier": true,
                "aile": false,
                "blanc": false,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "1 poulet entier d'environ 1,5 kg, découpé en quartiers",
                "2 gros oignons, coupés en quartiers",
                "4 gousses d'ail, écrasées",
                "2 bâtons de cannelle",
                "4 cardamomes vertes, légèrement écrasées",
                "4 clous de girofle",
                "1 cuillère à café de curcuma moulu",
                "1 cuillère à café de coriandre moulue",
                "1 cuillère à café de poivre noir moulu",
                "1 cuillère à café d'allspice (ou piment de la Jamaïque)",
                "2 feuilles de laurier",
                "1,5 litre de bouillon de poulet",
                "250g de jameed (yaourt séché de brebis) ou 500g de yaourt grec épais mélangé à 2 cuillères à soupe de jus de citron",
                "500g de riz à grain long (de préférence basmati)",
                "100g d'amandes mondées",
                "100g de pignons de pin",
                "3 cuillères à soupe d'huile d'olive",
                "4 grands pains plats arabes (shrak ou markook)",
                "2 cuillères à soupe de persil plat frais, finement haché",
                "1 cuillère à café de paprika fumé (pour garnir)",
                "Sel, selon votre goût"
            ],
            "ingredient_liste": [
                "poulet",
                "oignon",
                "ail",
                "cannelle",
                "cardamome",
                "clou-girofle",
                "curcuma",
                "coriandre",
                "poivre-noir",
                "allspice",
                "laurier",
                "bouillon-poulet",
                "jameed",
                "riz-basmati",
                "amande",
                "pignon-pin",
                "huile-olive",
                "pain-arabe",
                "persil",
                "paprika-fume",
                "sel"
            ],
            "preparation": [
                "Si vous utilisez du jameed sec, le faire tremper dans l'eau toute une nuit, puis le battre jusqu'à obtenir une consistance lisse. Si vous utilisez du yaourt grec, le mélanger avec le jus de citron et réserver.",
                "Dans une grande marmite, faire chauffer 1 cuillère à soupe d'huile d'olive à feu moyen.",
                "Ajouter les oignons et l'ail, et faire revenir jusqu'à ce qu'ils soient translucides, environ 3-4 minutes.",
                "Ajouter le poulet et faire dorer de tous les côtés pendant environ 5 minutes.",
                "Incorporer les bâtons de cannelle, les cardamomes, les clous de girofle, le curcuma, la coriandre, le poivre noir, l'allspice, les feuilles de laurier et une cuillère à café de sel.",
                "Verser le bouillon de poulet, porter à ébullition, puis réduire le feu et laisser mijoter à couvert pendant environ 45 minutes, jusqu'à ce que le poulet soit tendre.",
                "Pendant ce temps, rincer le riz plusieurs fois à l'eau froide jusqu'à ce que l'eau soit claire. Laisser tremper dans l'eau froide pendant 20 minutes, puis égoutter."
            ],
            "cuisson": [
                "Une fois le poulet cuit, le retirer de la marmite et le mettre de côté, couvert pour le garder au chaud.",
                "Filtrer le bouillon de cuisson et le remettre dans la marmite.",
                "Dans un bol, diluer le jameed ou le yaourt avec une louche de bouillon chaud en fouettant constamment pour éviter les grumeaux.",
                "Incorporer progressivement ce mélange dans le bouillon restant en remuant constamment. Faire chauffer à feu doux sans faire bouillir, en remuant de temps en temps, jusqu'à ce que la sauce épaississe légèrement.",
                "Pendant ce temps, dans une poêle, faire chauffer les 2 cuillères à soupe d'huile d'olive restantes à feu moyen.",
                "Ajouter les amandes et les pignons, et faire dorer pendant 2-3 minutes en remuant constamment. Les retirer de la poêle et réserver.",
                "Dans une grande casserole, porter à ébullition 1 litre d'eau salée.",
                "Ajouter le riz et cuire jusqu'à ce qu'il soit presque tendre mais encore ferme, environ 12-15 minutes. Égoutter.",
                "Pour le montage, étaler les pains plats sur un grand plat de service.",
                "Répartir le riz sur le pain.",
                "Placer les morceaux de poulet sur le riz.",
                "Verser généreusement la sauce au yaourt sur l'ensemble.",
                "Garnir avec les amandes et les pignons dorés, le persil haché et saupoudrer de paprika fumé."
            ],
            "cooking_methods": {
                "best": "cocotte",
                "grill": false,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": false,
                "poele": false,
                "cocotte": true
            },
            "mode_cuisson": [
                "cocotte"
            ],
            "accompagnement": "Servir avec une salade de concombres au yaourt et des pickles. Pour la salade de concombres: éplucher et râper 2 concombres, les saler légèrement et laisser dégorger 15 minutes. Presser pour éliminer l'excès d'eau. Mélanger avec 250g de yaourt, 2 gousses d'ail écrasées, 2 cuillères à soupe de menthe fraîche hachée, 1 cuillère à soupe d'huile d'olive, le jus d'un demi-citron, du sel et du poivre. Pour les pickles traditionnels: dans une casserole, porter à ébullition 500ml de vinaigre blanc avec 2 cuillères à soupe de sel, 1 cuillère à soupe de sucre et un mélange d'épices (3 cardamomes, 1 bâton de cannelle, 5 grains de poivre noir). Verser sur un mélange de légumes coupés (carottes, concombres, choux-fleurs, oignons) placés dans des bocaux stérilisés. Fermer hermétiquement et laisser mariner au moins 3 jours avant de servir. Dans la tradition jordanienne, le mansaf se mange généralement avec la main droite, en formant des boulettes de riz imbibées de sauce au yaourt.",
            "shortStory": "Le **Mansaf** est le plat national emblématique de la **Jordanie**, symbole d'hospitalité et de générosité dans la culture bédouine. Son nom dérive du terme \"grand plateau\" en arabe, évoquant la manière traditionnelle de servir ce plat sur un large plateau où il est partagé par tous les convives. L'ingrédient distinctif du mansaf est le **jameed**, un yaourt de brebis ou de chèvre fermenté et séché sous forme de boules dures, qui donne au plat sa saveur caractéristique légèrement acidulée. Historiquement, cette méthode de conservation du lait était essentielle pour les tribus nomades. Traditionnellement préparé avec de l'agneau, le mansaf est aujourd'hui souvent réalisé avec du poulet dans les foyers modernes, tout en conservant l'essence du plat original. Dans la culture jordanienne, servir le mansaf est un grand honneur fait aux invités, et la qualité du plat reflète le respect que l'hôte leur porte. Ce plat est incontournable lors des grandes célébrations comme les mariages et les fêtes religieuses. La façon de manger le mansaf est aussi importante que sa préparation: on utilise traditionnellement la main droite pour former des boulettes de riz imbibées de sauce, démontrant l'aspect communautaire et convivial de la gastronomie du **Moyen-Orient**.",
            "detailURL": "/fiche-recette/205/poulet-yaourt-riz/mansaf.html",
            "time_prepare": "1h",
            "time_maceration": "0",
            "time_cooking": "1h30",
            "crispys": false,
            "cost": {
                "level": 3,
                "description": "Cette recette est d'un coût relativement élevé (niveau 3) en raison de l'utilisation d'ingrédients comme le jameed, les pignons de pin et les amandes. Si le jameed authentique n'est pas disponible, l'utilisation de yaourt grec réduit légèrement le coût."
            },
            "images": [],
            "hot": {
                "level": 1,
                "description": "Cette recette est légèrement relevée (niveau 1) grâce au mélange d'épices, mais elle n'est pas piquante. Le yogourt apporte une saveur acidulée qui équilibre les épices."
            },
            "calories": "650 kcal",
            "allergens": [
                "fruits-a-coque",
                "gluten",
                "lait"
            ],
            "difficulty": {
                "level": 3,
                "description": "Recette de difficulté modérée (niveau 3) en raison des multiples étapes de préparation et de la technique particulière nécessaire pour incorporer le jameed ou le yaourt sans qu'il ne caille."
            },
            "equipment_needed": [
                "cocotte",
                "casserole",
                "poele",
                "grand-plat-service"
            ]
        },
        {
            "id": 203,
            "categorie": "asie",
            "country": "Japon",
            "flag": "JP",
            "title": "Tori no Teriyaki",
            "subTitle": "Poulet glacé à la sauce teriyaki traditionnelle japonaise",
            "chicken_piece": [
                "cuisse",
                "blanc"
            ],
            "piece": {
                "best": "cuisse",
                "entier": false,
                "aile": false,
                "blanc": true,
                "pilon": false,
                "manchon": false,
                "cuisse": true
            },
            "ingredient": [
                "800g de cuisses de poulet désossées, avec la peau (ou filets de poulet)",
                "Pour la sauce teriyaki:",
                "100ml de sauce soja japonaise (shoyu)",
                "100ml de mirin (vin de riz doux)",
                "50ml de saké de cuisine",
                "2 cuillères à soupe de sucre (de préférence du sucre brun)",
                "1 cuillère à café de gingembre frais, râpé",
                "1 gousse d'ail, finement émincée",
                "Pour la garniture:",
                "1 cuillère à soupe de graines de sésame blanc, grillées",
                "2 oignons verts, finement émincés"
            ],
            "ingredient_liste": [
                "poulet",
                "sauce-soja",
                "mirin",
                "sake",
                "sucre",
                "gingembre",
                "ail",
                "sesame",
                "oignon-vert"
            ],
            "preparation": [
                "Si vous utilisez des cuisses de poulet avec la peau, les essuyer avec du papier absorbant pour éliminer l'excès d'humidité.",
                "Avec un couteau bien aiguisé, faire quelques incisions peu profondes sur la surface du poulet pour permettre à la marinade de mieux pénétrer.",
                "Dans une casserole, mélanger la sauce soja, le mirin, le saké, le sucre, le gingembre et l'ail.",
                "Porter à ébullition à feu moyen, puis réduire à feu doux et laisser mijoter pendant 5 minutes jusqu'à ce que le mélange commence à s'épaissir légèrement.",
                "Retirer du feu et laisser refroidir à température ambiante.",
                "Réserver 1/3 de la sauce pour le glaçage final.",
                "Placer le poulet dans un sac de congélation ou un récipient hermétique et verser les 2/3 restants de la sauce dessus.",
                "Masser délicatement le poulet pour bien l'enrober de marinade.",
                "Fermer le sac ou couvrir le récipient et réfrigérer pendant au moins 30 minutes, idéalement 2-4 heures."
            ],
            "cuisson": [
                "Sortir le poulet de la marinade et l'éponger légèrement avec du papier absorbant (ne pas essuyer complètement).",
                "Dans une poêle à fond épais, chauffer à feu moyen-vif. Ne pas ajouter d'huile car la peau du poulet contient suffisamment de graisse.",
                "Placer les morceaux de poulet, côté peau vers le bas, dans la poêle chaude.",
                "Faire cuire sans déplacer pendant 5-6 minutes, jusqu'à ce que la peau soit bien dorée et croustillante.",
                "Retourner et cuire l'autre côté pendant 4-5 minutes.",
                "Réduire le feu à moyen-doux et verser la sauce teriyaki réservée sur le poulet.",
                "Laisser mijoter en tournant régulièrement les morceaux pour les enrober uniformément, jusqu'à ce que la sauce épaississe et forme un glaçage brillant (environ 3-4 minutes).",
                "Vérifier la cuisson en insérant un thermomètre à viande au point le plus épais (la température interne doit atteindre 75°C).",
                "Une fois cuit, laisser reposer le poulet 2-3 minutes hors du feu.",
                "Trancher le poulet en morceaux de 1 cm d'épaisseur et disposer sur une assiette.",
                "Napper avec le reste de la sauce de la poêle et saupoudrer de graines de sésame grillées et d'oignons verts émincés."
            ],
            "cooking_methods": {
                "best": "poele",
                "grill": true,
                "plancha": false,
                "friture": false,
                "barbecue": false,
                "four": true,
                "poele": true,
                "cocotte": false
            },
            "mode_cuisson": [
                "poele",
                "grill",
                "four"
            ],
            "accompagnement": "Servir avec du riz japonais à grain court et des légumes sautés à la japonaise. Pour le riz: rincer 400g de riz japonais jusqu'à ce que l'eau soit claire. Laisser égoutter 30 minutes dans une passoire. Placer le riz dans un cuiseur à riz ou une casserole avec 480ml d'eau. Si vous utilisez une casserole, porter à ébullition, puis réduire à feu très doux et cuire à couvert pendant 15 minutes. Retirer du feu sans ouvrir le couvercle et laisser reposer 10 minutes. Pour les légumes: dans un wok, chauffer 1 cuillère à soupe d'huile de sésame à feu vif. Ajouter 1 carotte coupée en julienne, 100g de pois gourmands, 1 poivron rouge émincé et 50g de champignons shiitake émincés. Faire sauter 2-3 minutes. Ajouter 1 cuillère à soupe de sauce soja, 1 cuillère à café de mirin et une pincée de sucre. Poursuivre la cuisson 1 minute. Saupoudrer de graines de sésame avant de servir. Accompagner également d'une soupe miso: dissoudre 3 cuillères à soupe de pâte miso dans 700ml de dashi chaud. Ajouter 1 cuillère à soupe d'algue wakame réhydratée, 100g de tofu coupé en dés et 2 oignons verts émincés.",
            "shortStory": "Le **Tori no Teriyaki** (littéralement \"poulet lustré-grillé\") est l'un des plats japonais les plus connus à l'international, mais sa version authentique diffère souvent de ce qu'on trouve en Occident. La cuisine **teriyaki** tire son nom de sa technique de préparation: \"teri\" fait référence à la brillance que donne le **mirin** et le sucre, tandis que \"yaki\" signifie grillé ou saisi. Cette méthode de cuisson remonte à l'époque d'Edo (1603-1868), lorsque les techniques de glaçage des viandes se sont développées au **Japon**. Contrairement aux idées reçues, la sauce teriyaki traditionnelle japonaise ne contient pas de maïzena ou d'agent épaississant; c'est la réduction naturelle du **mirin**, du **saké** et de la **sauce soja** qui crée la consistance sirupeuse caractéristique. Dans les foyers japonais, le teriyaki est souvent préparé avec du poisson gras comme le saumon ou le maquereau, bien que la version au poulet soit également très appréciée. La version moderne du poulet teriyaki aurait été popularisée au début du XXe siècle par des immigrants japonais à Hawaii, avant de conquérir le reste du monde. Au Japon, ce plat est apprécié pour sa simplicité et l'équilibre parfait entre le salé de la sauce soja et la douceur du mirin et du sucre, créant ce qu'on appelle \"umami\", la cinquième saveur fondamentale de la cuisine japonaise.",
            "detailURL": "/fiche-recette/203/poulet-glace-sauce-soja/tori-no-teriyaki.html",
            "time_prepare": "15mn",
            "time_maceration": "2h",
            "time_cooking": "20mn",
            "crispys": false,
            "cost": {
                "level": 2,
                "description": "Cette recette est d'un coût moyen (niveau 2). Les ingrédients spécifiques comme le mirin et le saké de cuisine peuvent être trouvés dans les épiceries asiatiques, mais représentent un investissement initial si vous ne les avez pas déjà dans votre garde-manger."
            },
            "images": [],
            "hot": {
                "level": 0,
                "description": "Cette recette n'est pas épicée (niveau 0). La cuisine japonaise traditionnelle mise davantage sur l'équilibre des saveurs umami que sur le piquant."
            },
            "calories": "350 kcal",
            "allergens": [
                "soja"
            ],
            "difficulty": {
                "level": 2,
                "description": "Recette de difficulté moyenne (niveau 2), nécessitant une attention particulière lors de la réduction de la sauce pour obtenir le glaçage parfait et une bonne maîtrise de la cuisson du poulet pour qu'il reste juteux."
            },
            "equipment_needed": [
                "poele-fonte",
                "couteau",
                "thermometre-cuisine"
            ]
        }

    ]
;
export default GridSet;
